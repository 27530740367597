import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { Title } from '@angular/platform-browser';
import { CMError } from '../../../interfaces/interfaces';
import { LoginCredentials, ResetPasswordResponse } from '../../../services/interface';
import { NotificationService } from '../../../services/notification.service';
import { eNotificationTypes } from '../../../interfaces/constants';
import { FormGroup, AbstractControl, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../../classes/formValidation';
import { GoogleIdentity } from '../login/login.component';
import { environment } from '../../../../environments/environment';
import { TokenService } from '../../../services/token.service';
import { MainMenuUpdateService } from '../../main-menu/main-menu-update.service';
import { UpdateService } from '../../../services/update.service';

export type RegistrationFields = 'FirstName' | 'LastName' | 'Email' |
	'Password' | 'ConfirmPassword';

declare var google: GoogleIdentity;

@Component({
	selector: 'app-register',
	templateUrl: 'register.page.html',
	styleUrls: ['registration.scss']
})
export class RegistrationPage implements OnInit, AfterViewInit {

	public errorMessages: string[];
	public form: FormGroup;
	public isSaving: boolean;
	public passwordIsHidden: boolean;
	private _isGoogleLoaded = false;
	public loginCredentials: LoginCredentials;

	public get f(): { [key in RegistrationFields]: AbstractControl } {
		return this.form.controls as { [key in RegistrationFields]: AbstractControl };
	}

	constructor(
		private _fb: FormBuilder,
		private _loginService: LoginService,
		private _titleService: Title,
		private _ngZone: NgZone,
		private _mainMenuUpdateService: MainMenuUpdateService,
		private _notificationService: NotificationService,
		private _router: Router,
		private _updateService: UpdateService,
		private _tokenService: TokenService) {
	}

	ngOnInit(): void {
		this._titleService.setTitle('CropManage | Register');
		this._initializeForm();
		this.passwordIsHidden = true;

		this.loginCredentials = {
			username: '',
			password: '',
			GoogleJWT: null
		}
	}

	ngAfterViewInit(): void {
		window.onload = () => {
			// this._initializeGoogleIdentity();
		};

		// this._initializeGoogleIdentity();
	}

	private _initializeGoogleIdentity(): void {
		if (this._isGoogleLoaded) {
			return;
		}

		if (!google) {
			return;
		}

		this._isGoogleLoaded = true;

		google.accounts.id.initialize({
			client_id: environment.GoogleClientId,
			context: 'signup',
			callback: (response) => this._ngZone.run(() => {
				this.loginCredentials.GoogleJWT = response.credential;
				this._registerViaGoogleIdentity();
			})
		});

		google.accounts.id.prompt(); // show one-click prompt

		google.accounts.id.renderButton(
			document.getElementById('googleSignInBtn'),
			{ type: 'standard', text: 'signup_with', theme: 'filled_blue',
			size: 'large', width: '390'}
		);
	}

	private _initializeForm(): void {
		this.form = this._fb.group({
			FirstName: [null, Validators.required],
			LastName: [null, Validators.required],
			Email: [null, [Validators.required, this._emailPlusSignValidator()]],
			Password: [null],
			ConfirmPassword: [null],
		});

		this.f.Password.setValidators([Validators.required, this._mustMatchControl(this.f.ConfirmPassword),
			Validators.minLength(7),
			this._passwordValidator()]);
		this.f.ConfirmPassword.setValidators([Validators.required, this._mustMatchControl(this.f.Password),
			Validators.minLength(7), this._passwordValidator()]);
	}

	private _mustMatchControl(anotherControl: AbstractControl): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let errorName = 'valuesMustMatch';

			if (!anotherControl.touched) {
				return null;
			}

			valid = anotherControl.value === control.value ? true : false;

			if (valid) {
				FormValidation.removeErrorFromContrl(anotherControl, errorName);
			}

			return valid ? null : {'valuesMustMatch': {value: control.value}};
		};
	}

	/**
	 * Password should have 2 of the following types of characters: Lower
	 * case letters, Upper case letters, Numbers, Punctuation (special characters).
	 */
	private _passwordValidator(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let features: number;
			let patterns: {
				lowerCase: RegExp,
				upperCase: RegExp,
				number: RegExp,
				characters: RegExp
			};

			features = 0;

			patterns = {
				lowerCase: new RegExp('[a-z]'),
				upperCase: new RegExp('[A-Z]'),
				number: new RegExp('[0-9]'),
				characters: new RegExp('[^0-9a-zA-Z]')
			};

			if (patterns.lowerCase.test(control.value)) {
				features++;
			}

			if (patterns.upperCase.test(control.value)) {
				features++;
			}

			if (patterns.number.test(control.value)) {
				features++;
			}

			if (patterns.characters.test(control.value)) {
				features++;
			}

			valid = features >= 2 ? true : false;

			return valid ? null : {'invalidPassword': {value: control.value}};
		};
	}

	private _emailPlusSignValidator(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			valid = control.value && control.value.indexOf('+') !== -1 ? false : true;

			return valid ? null : {'noPlusSign': {value: control.value}};
		};
	}

	public getPasswordErrorMessage(control: AbstractControl): string {
		let result: string;

		if (!control) {
			return null;
		}

		result = control.errors.required ? 'Password is required' :
			control.errors.valuesMustMatch ? 'Password and confirm password must match' :
			control.errors.minlength ? 'Password must be at least 7 characters' :
			control.errors.invalidPassword ? `Password should have at least 2 types of characters
			(lower case, Upper case, Numbers, Punctuation)` : null;

		return result;
	}

	/**
	 * Register via Google Identity
	 */
	private _registerViaGoogleIdentity(): void {
		this.isSaving = true;

		this._loginService.login(this.loginCredentials)
			.then(response => {

				if (!response || !response.token || !response.expires) {
					this.isSaving = false;
					return;
				}

				this._tokenService.setToken(response);
				this._updateService.updateAuthToken();
				this._mainMenuUpdateService.fireAuthStatusChanged();

				this.isSaving = false;
				this._router.navigate(['MyDashboard']);
			}
		);
	}

	public register(): void {

		if (!this.form.valid) {
			return;
		}

		this.isSaving = true;

		this._loginService.register(this.f.FirstName.value,
			this.f.LastName.value, this.f.Email.value,
			this.f.Email.value, this.f.Password.value,
			this.f.ConfirmPassword.value).then((response) => {

			let error: CMError;
			let validResponse: ResetPasswordResponse;

			this.isSaving = false;
			validResponse = response as ResetPasswordResponse;
			error = response as CMError;

			if (error.message) {
				this._notificationService.generateNotifcation({
					type: eNotificationTypes.ERROR,
					message: error.message
				});

				return;
			}

			if (validResponse.ErrorMessages && validResponse.ErrorMessages.length > 0) {
				this.errorMessages = validResponse.ErrorMessages;
			} else {
				let navigationExtras: NavigationExtras = {
					queryParams: {
						'email': this.f.Email.value,
					}
				};

				this._router.navigate(['mustConfirmEmail'], navigationExtras);
			}
		});
	}
}
