<div class="bk-login__container">
	<form class="form-horizontal">
		<div class="bk-modal bk-modal--register">
			<div class="bk-modal__header">
				<h4 class="bk-modal__header-title">Reset Password</h4>
			</div>
			<div class="bk-modal__body">
				<div class="bk-login__new-form">
					<div class="bk-login__form-group">
						<label>Email</label>
						<input type="text" name="email" [(ngModel)]="email"/>
					</div>
					<div class="bk-login__form-group">
						<label>Password</label>
						<input type="password" name="password" [(ngModel)]="password"/>
					</div>
					<div class="bk-login__form-group">
						<label>Confirm Password</label>
						<input type="password" name="confirmPassword" [(ngModel)]="confirmPassword"/>
					</div>
					<div class="bk-login__form-group">
						<input type="submit"
							(click)="resetPassword()"
							class="bk-register__button bk-button--large btn btn-custom"
							value="Reset" />
					</div>
				</div>
			</div>
		</div>    
	</form>
</div>