import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
	moduleId: module.id,
	selector: 'keep-discard-dialog',
	templateUrl: 'keep-discard.dialog.html'
})

export class KeepDiscardDialog implements OnInit {

	public constructor(
		private _dialog: MatDialog,
		private _dialogRef: MatDialogRef<KeepDiscardDialog>,
	) {

	}

	ngOnInit(): void {
	}

	public keep(): void {
		this._dialogRef.close();
	}

	public discard() {
		this._dialog.closeAll();
	}
}
