import { ValidateService } from '../../services/validate.service';
import { HttpService } from '../../services/http.service';

import { IIrrigationEvent } from '../irrigation-event/interfaces';
import { IFlowMeterChartModel } from './interfaces';
import { FlowmeterService } from './service';
import { HighChartsService } from '../../classes/highCharts.service';
import { ElementRef } from '@angular/core';
import { IHighChartCustomSeries } from '../available-water-chart/interfaces';

/// <reference path="@types/jquery/index.d.ts"/>
/// <reference path="jquery.plot.d.ts"/>

export interface FlowmeterCache {
	summary: IFlowMeterChartModel[],
	details: IFlowMeterChartModel[]
};

export enum chartTypes {
	summary = 1,
	detail,
	dateSpecific
};

export enum highChartGraphTypes {
	line = 'line',
	column = 'column'
}

export class FlowMeter {

	// public isSummary = true; // true by default
	public chartType = chartTypes.summary;

	public static getFlowmeterWaterApplied(irrigationEvent: IIrrigationEvent, decimals = 2): number {
		let gallons: number;
		let area: number;

		if (!irrigationEvent || !irrigationEvent.FlowMeterGallons) {
			return;
		}

		gallons = irrigationEvent.FlowMeterGallons ? irrigationEvent.FlowMeterGallons : null;

		area = irrigationEvent.FlowMeterArea ? irrigationEvent.FlowMeterArea : null;

		if (!gallons || !area) {
			return;
		}

		return ValidateService.roundToDecimalPlaces(((gallons * 0.0000368265993) / area), decimals);
	}

	constructor(
		private _flowmeterService: FlowmeterService,
		chartType: chartTypes = chartTypes.summary) {

		this.chartType = chartType;
	}

	private _convertToGraphData(records: IFlowMeterChartModel, graphyType: highChartGraphTypes =
		highChartGraphTypes.column): Highcharts.IndividualSeriesOptions[] {

		let result: Highcharts.IndividualSeriesOptions[];

		let temp: {
			data: IHighChartCustomSeries
		};

		result = [];

		if (!records || !records.Data || records.Data.length === 0) {
			return result;
		}

		temp = {
			data: {
				name: 'GPM',
				visible: true,
				data: [],
				type: graphyType,
				tooltip: {
					valueSuffix: '',
					valueDecimals: 0
				}
			},
		}

		for (let i = 0; i < records.Data.length; i++) {
			let item: [number, number];

			item = [Date.parse(records.Data[i][0]), records.Data[i][1]];

			if (records.Data[i][1] > 0) {
				temp.data.data.push(item);
			}
		}

		result.push(temp.data);

		return result;
	}

	private _onDateReceived(series: IFlowMeterChartModel, container: ElementRef, graphyType: highChartGraphTypes): void {

		let chartData: Highcharts.IndividualSeriesOptions[];

		chartData = this._convertToGraphData(series, graphyType);

		HighChartsService.drawGraph(container, chartData,
			[{
				title: {
					text: 'gpm'
				}
			}]
		);
	}

	/**
	 * For summary, use plantingId, else use WateringId. For Detail view, startDate and EndDate are optional
	 *
	 * @param id
	 * @param $dom
	 * @param StartDate
	 * @param EndDate
	 */
	public Initialize(id: number, container: ElementRef, startDate?: Date, endDate?: Date): Promise<void> {

		switch (this.chartType) {

			case chartTypes.summary:

				return this._flowmeterService.getChart(id).then((response) => {
					this._onDateReceived(response, container, highChartGraphTypes.column);
				});

			case chartTypes.detail:

				return this._flowmeterService.getFlowWateringDetail(id).then((response) => {
					this._onDateReceived(response, container, highChartGraphTypes.line);
				});

			case chartTypes.dateSpecific:

				return this._flowmeterService.getFlowWateringRanged(id, startDate, endDate).then((response) => {
					this._onDateReceived(response, container, highChartGraphTypes.line);
				});
		}
	};
}
