/*modules*/
import { NgModule } from '@angular/core';

/*services*/
import { RanchService } from './components/ranch-settings/service';
import { PlantingService } from './components/planting-settings/service';
import { FertilizerModelService } from './components/ranch-settings/modals/fertilizer/service';
import { CommodityService } from './models/commodity-type/service';
import { PersistentDatabase } from './services/persistent-database';

@NgModule({
	imports: [
	],
	exports: [
	],
	declarations: [
	],
	providers: [ // make data in these services persistent
		PersistentDatabase,
		CommodityService,
		RanchService,
		PlantingService,
		FertilizerModelService
	],
	bootstrap: []
})
export class CoreModule { }
