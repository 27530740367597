<div class="bk-login__container">
	<div class="bk-modal bk-modal--register">
		<div class="bk-modal__header">
			<h4 class="bk-modal__header-title">Please Confirm Email</h4>
		</div>
		<div class="bk-modal__body">
			<p>
				Check your email and confirm your account, you must be confirmed before you can log in.
			</p>
			<ng-container *ngIf='email && isResendCompleted === false'>
				<p>To resend a confirmation email, <a class='bk-clickable'
					(click)='resendConfirmation()'>click here</a>
				</p>
			</ng-container>
			<ng-container *ngIf='email && isResendCompleted === true'>
				<p>The confirmation email was resent. Please check your email.</p>
			</ng-container>
		</div>
	</div>
</div>