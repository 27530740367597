import { Component, Inject, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppliedFertilizerChartDataSet } from './interfaces';
import { DateUtility } from '../../classes/dateUtility';
import { HighChartsService } from '../../classes/highCharts.service';
import { PlantingService } from '../planting-settings/service';
import { IHighChartCustomSeries } from '../available-water-chart/interfaces';

@Component({
	moduleId: module.id,
	selector: 'applied-fertilizer-chart',
	templateUrl: 'applied-fertilizer-chart.html'
})

export class AppliedFertilizerChartComponent implements OnInit, OnDestroy {

	@ViewChild('chartContainer', { static: true }) private _chartContainer: ElementRef;
	private _chartData: Highcharts.IndividualSeriesOptions[];
	public plantingName: string;
	private _subscriptions$: Subject<boolean>;

	constructor(
		private _service: PlantingService,
		private _dialogRef: MatDialogRef<AppliedFertilizerChartComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: { lotPlantingId: number, name: string },
	) {
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();
		this._configureClickOutside(this._subscriptions$, this._dialogRef);

		if (!this._data) {
			throw new Error('data is empty');
		}

		this.plantingName = this._data.name;

		this._service.getAppliedFertilizerChart(this._data.lotPlantingId).then(response => {
			this._convertToGraphData(response);
			HighChartsService.drawGraph(this._chartContainer, this._chartData, [{
				title: {
					text: 'Lbs N/acre'
				}
			}]);
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public cancel(): void {
		this._dialogRef.close();
	}

	private _configureClickOutside(s: Subject<boolean>, d: MatDialogRef<AppliedFertilizerChartComponent>): void {
		// custom click outside behavior
		d.disableClose = true;

		d.backdropClick().pipe(takeUntil(s)).subscribe(() => {
			d.close();
		});
	}

	private _convertToGraphData(records: AppliedFertilizerChartDataSet): void {

		let temp: {
			recommendations: IHighChartCustomSeries,
			AppliedAmounts: IHighChartCustomSeries,
		};

		temp = {
			recommendations: {
				name: 'Recommended',
				visible: true,
				data: [],
				tooltip: {
					valueSuffix: ' lbs N/acre'
				},
			},
			AppliedAmounts: {
				name: 'Fertilizer Applied',
				visible: true,
				data: [],
				tooltip: {
					valueSuffix: ' lbs N/acre'
				}
			}
		}

		for (let record of records.Recommendations) {
			let utc: number;

			utc = DateUtility.DateToUTC(DateUtility.DotNetToDate(record.EventDate));
			temp.recommendations.data.push([utc, record.Amount]);
		}

		for (let record of records.AppliedAmounts) {
			let utc: number;

			utc = DateUtility.DateToUTC(DateUtility.DotNetToDate(record.EventDate));
			temp.AppliedAmounts.data.push([utc, record.Amount]);
		}

		this._chartData = [];

		this._chartData.push(temp.recommendations);
		this._chartData.push(temp.AppliedAmounts);
	}
}
