<div class="bk-dialog">

	<h4 mat-dialog-title>
		{{'Delete' | translate }} {{ 'Canopy Parameters' | translate }}
	</h4>

	<div mat-dialog-content>
		<div class="bk-modal__remove-text">
			{{'Are you sure you want to delete this record?' | translate }}?
		</div>
	</div>

	<div mat-dialog-actions align="end">

		<button class="bk-button bk-button--medium bk-button--text-only"
			(click)="close()">
			{{'Cancel' | translate }}
		</button>
		<button class="bk-button bk-button--medium"
			(click)="delete()">
			{{'Delete' | translate }}
		</button>
	</div>
</div>