<div class="bk-dialog">
    <button type="button"
        class="bk-button bk-dialog__close"
        (click)="close()">
        <span>&times;</span>
    </button>

    <h4 mat-dialog-title>{{ title }} (#{{number}}) {{ 'Usage Details' | translate}}</h4>

    <div mat-dialog-content>
        <div class="bk-weather-station-stats__summary" *ngIf="summary">
            <div class="bk-weather-station-stats__summary--column">
                <div class="bk-weather-station-stats__summary--label">{{ 'Name' | translate}}</div>
                <div class="bk-weather-station-stats__summary--value">{{ summary.Name }}</div>
            </div>

            <div class="bk-weather-station-stats__summary--column">
                <div class="bk-weather-station-stats__summary--label">{{ 'Nearest City' | translate}}</div>
                <div class="bk-weather-station-stats__summary--value">{{ summary.NearestCity }}</div>
            </div>

            <div class="bk-weather-station-stats__summary--column">
                <div class="bk-weather-station-stats__summary--label">{{ 'County' | translate}}</div>
                <div class="bk-weather-station-stats__summary--value">{{ summary.County }}</div>
            </div>

            <div class="bk-weather-station-stats__summary--column">
                <div class="bk-weather-station-stats__summary--label">{{ 'Region' | translate}}</div>
                <div class="bk-weather-station-stats__summary--value">{{ summary.RegionName }}</div>
            </div>

            <div class="bk-weather-station-stats__summary--column">
                <div class="bk-weather-station-stats__summary--label">{{ 'Station' | translate}} #</div>
                <div class="bk-weather-station-stats__summary--value">{{ summary.ExternalId }}</div>
            </div>

            <div class="bk-weather-station-stats__summary--column">
                <div class="bk-weather-station-stats__summary--label">{{ 'Added a Total of' | translate}}</div>
                <div class="bk-weather-station-stats__summary--value">{{ summary.RainfallCount }} {{ 'Rainfall Events' | translate }}</div>
            </div>
        </div>

        <div class="bk-weather-station-stats__date-filter">
            <div class="bk-weather-station-stats__date-filter--range">
                <my-date-picker #startDatePicker [(ngModel)]="startDateFilter" [options]="startDateFilterOptions" (dateChanged)="startDateFilterChanged($event)"
                                [ngClass]="{'bk-input-invalid--datepicker' : !isDateRangeValid.startDate}"></my-date-picker>
                <div class="bk-weather-station-stats__date-filter--range-text">{{ 'to' | translate}}</div>
                <my-date-picker #endDatePicker [(ngModel)]="endDateFilter" [options]="endDateFilterOptions" (dateChanged)="endDateFilterChanged($event)"
                                [ngClass]="{'bk-input-invalid--datepicker' : !isDateRangeValid.endDate}"></my-date-picker>
            </div>
            <div class="bk-weather-station-stats__date-filter--totals" *ngIf="summary">
                <div class="bk-weather-station-stats__date-filter--totals-text">
                    {{summary.RanchCount}} {{ 'Ranch' | plural: summary.RanchCount | translate}}
                </div>
                <div class="bk-weather-station-stats__date-filter--totals-text">
                    {{summary.RecommendationCount}} {{ 'Recommendation' | plural: summary.RecommendationCount | translate}}
                </div>
            </div>
        </div>

        <div class="bk-weather-station-stats__usage">
            <div class="bk-weather-station-stats__usage--header" *ngIf="summary && summary.RanchCount > 0 && summary.RecommendationCount > 0">
                {{ 'Usage Statistics' | translate}}
            </div>

            <div class="bk-weather-station-stats__chart" [hidden]="summary && (summary.RanchCount == 0 || summary.RecommendationCount == 0)">
                <div class="bk-weather-station-stats__chart-container" #chartContainer></div>
            </div>

            <div class="bk-weather-station-stats__usage--header" *ngIf="summary && summary.RanchCount > 0 && summary.RecommendationCount > 0">
                {{ 'Recommendations Distribution' | translate}}
            </div>

            <div class="bk-weather-station-stats__distribution" *ngIf="summary && summary.RanchCount > 0 && summary.RecommendationCount > 0">
                <div class="bk-weather-station-stats__distribution-tabs">
                    <div class="bk-weather-station-stats__distribution-tab" [class.bk-weather-station-stats__distribution-tab--selected]="viewByRanch"
                         (click)="viewByRanch = true">
                        {{ 'View by Ranch' | translate }}
                    </div>
                    <div class="bk-weather-station-stats__distribution-tab" [class.bk-weather-station-stats__distribution-tab--selected]="!viewByRanch"
                         (click)="viewByRanch = false">
                        {{ 'View by Commodity' | translate }}
                    </div>
                </div>

                <div class="bk-weather-station-stats__distribution-content">
                    <table class="bk-weather-station-stats__distribution-table" *ngIf="distributionByRanchRows" [hidden]="!viewByRanch">
                        <thead>
							<tr>
								<th width="10%">{{ 'Id' | translate }}</th>
								<th width="20%">{{ 'Ranch' | translate }}</th>
								<th width="30%">{{ 'Used in' | translate }}</th>
								<th width="40%">{{ 'Commodity' | translate }}</th>
							</tr>
                        </thead>
                        <tbody>
							<tr *ngFor="let row of distributionByRanchRows">
								<td>{{ row.Id }}</td>
								<td>{{ row.RanchName }}</td>
								<td>{{ row.RecommendationCount }} {{ 'Recommendation' | plural: row.RecommendationCount | translate }}</td>
								<td>{{ row.CommodityNames | stringifyList }}</td>
							</tr>
                        </tbody>
                    </table>

                    <table class="bk-weather-station-stats__distribution-table" *ngIf="distributionByCommodityRows" [hidden]="viewByRanch">
                        <thead>
                            <tr>
                                <th class="bk-weather-station-stats__distribution-table--col-1">{{ 'Commodity' | translate }}</th>
                                <th class="bk-weather-station-stats__distribution-table--col-2">{{ 'Used in' | translate }}</th>
                                <th class="bk-weather-station-stats__distribution-table--col-3">{{ 'Ranch' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of distributionByCommodityRows">
                                <td>{{ row.CommodityName }}</td>
                                <td>{{ row.RecommendationCount }} {{ 'Recommendation' | plural: row.RecommendationCount | translate }}</td>
                                <td>{{ row.RanchNames | stringifyList }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

             <div class="bk-weather-station-stats__usage--header" *ngIf="summary && (summary.RanchCount == 0 || summary.RecommendationCount == 0)">
                {{ 'There is no Data for this Weather Station in this Date Range' | translate}}
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="close()">
            {{ 'Done' | translate }}
        </button>
    </div>
</div>
