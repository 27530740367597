import * as moment from 'moment';

import { CalculateService } from '../../services/calculate.service';
import { ValidateService } from '../../services/validate.service';
import { FlowMeter } from '../../components/flowmeter-graph/flowMeter';

import { IEventGroup, IRawEvent } from './interfaces';
import { IFertilizationEvent } from '../../components/fertilization-event/interfaces';
import { IIrrigationEvent, IIrrigationRecommendationSummaryModel } from '../../components/irrigation-event/interfaces';
import { ISoilSampleEvent } from '../../components/soil-sample-event/interfaces';
import { ICutEvent } from '../../components/cut-event/cut-event.interfaces';

import {
	EventsView, EventsType, EventTypeNames, EventContext,
	irrigationMethodNames,
	eIrrigationMethods
} from '../../interfaces/constants';
import { INutrient } from '../nutrient/interfaces';
import { FertilizationEvent } from '../../components/fertilization-event/fertilizationEvent';
import { IrrigationEvent } from '../../components/irrigation-event/irrigationEvent';

export class EventUtility {

	public static getSoilSampleValue(event: IEventGroup, past: boolean): number {
		if (!event || event == null || !event.SoilSamples || !event.SoilSamples[0] || event.SoilSamples[0] == null) {
			return;
		}

		if (!past) {
			return event.SoilSamples[0].Depth;
		}

		if (this.soilSampleApplied(event, past)) {
			return event.SoilSamples[0].SoilNitrateNPPM;
		}

		return event.SoilSamples[0].Depth;
	}

	public static getSoilSampleValueFromIndex(event: IEventGroup, past: boolean, index: number): number {
		if (!event || event == null || !event.SoilSamples || !event.SoilSamples[index]
			|| event.SoilSamples[index] == null) {

			return;
		}

		if (!past) { // scheduled event, so no applied value should exist
			return event.SoilSamples[index].Depth;
		}

		if (this.isSoilSampleApplied(event, past, index)) {
			return event.SoilSamples[index].SoilNitrateNPPM;
		}

		return event.SoilSamples[index].Depth;
	}

	/**
	 *
	 * @param event
	 * @param past
	 * @returns true if the event is a past event and applied field is null
	 */
	public static shouldDisplayFertilizationWarning(event: IFertilizationEvent, past: boolean): boolean {
		if (!event) {
			return false;
		}

		if (!past) {
			return false;
		}

		if (event.FertilizerApplied === null || event.NApplied === null) {
			return true;
		} else {
			return false;
		}
	}

	public static soilSampleApplied(event: IEventGroup, past: boolean, isNDependent = true): boolean {
		if (!event || !event.SoilSamples || !event.SoilSamples[0]) {
			return true;
		}

		if (!past) {
			return true;
		}

		if (!isNDependent) {
			for (let nutrient of event.SoilSamples[0].Nutrients) {
				if (nutrient.Value > 0) {
					return true;
				}
			}
		}

		return event.SoilSamples[0].SoilNitrateNPPM > 0;
	}

	/**
     * Determines if a soil sample can be considered to have an Applied value.
     * Used in event snapshot, for example, to decide whether to show in upcoming
     * or scheduled
     *
     * @param event
     * @param past
     * @param index
     * @param isNDependent
     */
	public static isSoilSampleApplied(event: IEventGroup, past: boolean, index: number, isNDependent = true): boolean {
		if (!event || !event.SoilSamples || !event.SoilSamples[index]) {
			return true;
		}

		if (!past) {
			return true;
		}

		if (!isNDependent) {
			for (let nutrient of event.SoilSamples[index].Nutrients) {
				if (nutrient.Value > 0) {
					return true;
				}
			}
		}

		return event.SoilSamples[index].SoilNitrateNPPM > 0;
	}

	public static isCuttingApplied(event: IEventGroup, past: boolean): boolean {
		if (!event || !event.CutEvent) {
			return true;
		}

		if (!past) {
			return true;
		}

		// yield of 0 means not applied
		return event.CutEvent.Yield > 0
	}

	/**
	 * Checks whether the displayed number is an applied amount or not
	 * @param event
	 * @param context
	 * @returns true if the number displayed to user is a CropManage recommendation
	 * false if the number displayed is an applied value
	 */
	public static isCropManageRecommendationIrrigation(event: IEventGroup, context: EventContext): boolean {
		let irrigationEvent: IIrrigationEvent;

		if (!event || event == null || !event.Irrigation || event.Irrigation == null) {
			return;
		}

		irrigationEvent = event.Irrigation;

		if (irrigationEvent.IrrigationMethodId === eIrrigationMethods.RAINFALL) {
			return true;
		}

		// if irrigation event has flowmeter data, and water applied doesn't look touched, assume cropmanage generated value
		// checking two parameters here because data stream is not consistent with interface definition
		if (irrigationEvent.HasFlowMeterData || irrigationEvent.HasFlowmeterData) {
			let flowMeterAppled = FlowMeter.getFlowmeterWaterApplied(irrigationEvent);

			if (flowMeterAppled === ValidateService.roundToDecimalPlaces(irrigationEvent.WaterApplied, 2)) {
				return true;
			}
		}

		if (context !== EventContext.TABLE && irrigationEvent.WaterApplied !== null) {
			return false;
		}

		if (irrigationEvent.ManagerAmountRecommendation != null) {
			return false;
		}

		return true;
	}

	public static isCropManageRecommendationFertilization(event: IEventGroup, context: EventContext): boolean {
		if (!event || event == null || !event.Fertilization || event.Fertilization.length === 0 ||
			event.Fertilization[0].Recommendation == null) {

			return;
		}

		if (context !== EventContext.TABLE) {
			if (event.Fertilization[0].FertilizerApplied != null || event.Fertilization[0].NApplied != null) {
				return false;
			}
		}

		if (event.Fertilization[0].Recommendation.manager != null) {
			return false;
		}

		// commenting this out since soil sample events are optional
		if (!event.Fertilization[0].SoilSampleEventDate) { // CropManage recommendation can't exist if there are no associated soil sample events
			// return false;
		}

		return true;
	}

	public static isCropManageRecommendationSoilSample(event: IEventGroup): boolean {
		if (!event || event == null || !event.SoilSamples || !event.SoilSamples[0] || event.SoilSamples[0] == null) {
			return;
		}

		return event.SoilSamples[0].SoilNitrateNPPM > 0 ? false : true;
	}

	public static isCropManageRecommendationSoilSampleFromIndex(event: IEventGroup, index: number): boolean {
		if (!event || event == null || !event.SoilSamples || !event.SoilSamples[index] || event.SoilSamples[index] == null) {
			return;
		}

		return event.SoilSamples[index].SoilNitrateNPPM > 0 ? false : true;
	}

	/**
     * This method is used to decide in event snapshot whether the event (with today's date on it)
     * should be displayed in the past tab vs Scheduled tab
     *
     * @param event
     */
	public static isIrrigationTodayPast(event: IEventGroup): boolean {
		if (!event || event == null || !event.Irrigation || event.Irrigation == null) {
			return;
		}

		if (event.Irrigation.IrrigationMethodId === eIrrigationMethods.RAINFALL) {
			return true;
		}

		return event.Irrigation.WaterApplied != null;
	}

	public static isFertilizationTodayPast(event: IEventGroup): boolean {
		if (!event || event == null || !event.Fertilization || event.Fertilization.length === 0) {
			return;
		}

		return event.Fertilization[0].FertilizerApplied != null;
	}

	/*
     * Note: eventType can be 'Water', 'Fertilizer', or 'SoilSamples'
     */
	public static getSubEvent(event: IEventGroup, eventType: string, sampleIndex?: number):
		IIrrigationEvent | IFertilizationEvent | ISoilSampleEvent | ICutEvent {

		switch (eventType) {
			case ('SoilSamples'): {
				if (!event.SoilSamples) {
					return;
				}

				return sampleIndex && event.SoilSamples[sampleIndex] ? event.SoilSamples[sampleIndex] : event.SoilSamples[0];
			}

			case ('Water'): { return event.Irrigation; }

			case ('Fertilizer'): { return event.Fertilization[0]; }

			case ('Cutting'): { return event.CutEvent }

			default: { return }
		}
	}

	public static getMetaInfo(event: IEventGroup, eventType: string, index?: number): string {

		if (!event) {
			return null;
		}

		switch (eventType) {
			case EventTypeNames.WATER:
				if (!event.Irrigation) {
					return null;
				}

				return event.Irrigation.Metainfo;
			case EventTypeNames.FERTILIZER:
				if (!event.Fertilization || event.Fertilization.length === 0) {
					return null;
				}

				if (!index) {
					index = 0;
				}

				if (event.Fertilization[index]) {
					return event.Fertilization[index].Metainfo;
				} else {
					return null;
				}
			case EventTypeNames.SOILSAMPLES:

				if (!event.SoilSamples) {
					return null;
				}

				if (!index) { // includes 0
					index = 0;
				}

				if (event.SoilSamples[index]) {
					return event.SoilSamples[index].Metainfo;
				} else {
					return null;
				}
			case EventTypeNames.TISSUE_SAMPLE:

				if (!event.TissueSamples) {
					return null;
				}

				if (!index) { // includes 0
					index = 0;
				}

				if (event.TissueSamples[index]) {
					return event.TissueSamples[index].Metainfo;
				} else {
					return null;
				}
			case EventTypeNames.CUTTING:
				if (!event.CutEvent) {
					return null;
				}

				return event.CutEvent.Metainfo;
		}
	}

	public static shouldShowLeafIconWater(m: IrrigationEvent): boolean {
		if (!m) {
			return false;
		}

		if (m.ManagerAmountRecommendation !== null ||
			m.WaterApplied !== null) {
			return false;
		} else if (m.IrrigationMethodId === eIrrigationMethods.RAINFALL) {
			return false;
		} else {
			return true;
		}
	}

	public static shouldShowLeafIconFertilization(m: FertilizationEvent): boolean {
		if (!m) {
			return false;
		}

		if ((m.Recommendation !== null &&
			m.Recommendation.manager !== null)  ||
			m.NApplied !== null) {

			return false;
		} else {
			return true;
		}
	}

	public static shouldShowLeafIcon(event: IEventGroup, eventType: string): boolean {

		if (!event) {
			return false;
		}

		switch (eventType) {
			case EventTypeNames.WATER:
				if (!event.Irrigation) {
					return false;
				}

				if (event.Irrigation.ManagerAmountRecommendation !== null ||
					event.Irrigation.WaterApplied !== null) {
					return false;
				} else if (event.Irrigation.IrrigationMethodId === eIrrigationMethods.RAINFALL) {
					return false;
				} else {
					return true;
				}

			case EventTypeNames.FERTILIZER:
				let f: IFertilizationEvent;

				if (!event.Fertilization || event.Fertilization.length === 0) {
					return false;
				}

				f = event.Fertilization[0];

				if (f.FertilizerApplied !== null) {
					return false;
				} else if (f.Recommendation !== null && f.Recommendation.manager) {
					return false;
				} else if (f.Recommendation.cropManage) {
					return true;
				} else {
					return true;
				}
			default:
				// cut events and soil sample events don't have recommendations
				return false;
		}
	}

	public static hasNoEvents(event: IEventGroup | IRawEvent): boolean {
		return !event.Irrigation && (!event.Fertilization || event.Fertilization.length === 0)
			&& (!event.SoilSamples || event.SoilSamples.length === 0) && !event.CutEvent
			&& (!event.TissueSamples || event.TissueSamples.length === 0);
	}

	public static getLeechingFactor(recommendationSummary: IIrrigationRecommendationSummaryModel, asPercent: boolean) {
		let decimal: number;

		if (!recommendationSummary || !recommendationSummary.LeechingFactor) {
			return 0;
		}

		if (asPercent) {
			return recommendationSummary.LeechingFactor;
		}

		decimal = CalculateService.percentToDecimal(recommendationSummary.LeechingFactor);

		return decimal ? decimal : 0;
	}

	public static getStringFromEventsView(view: EventsView): string {
		if (!view) {
			return null;
		}

		if (view === EventsView.TOTALS) {
			return 'Totals';
		}

		return view === EventsView.LIST ? 'List' : 'Table';
	}

	public static getStringFromEventsType(type: EventsType): string {
		switch (type) {
			case EventsType.WATER:
				return 'Water';
			case EventsType.FERTILIZER:
				return 'Fertilization';
			case EventsType.CUTTING:
				return 'Cutting';
			case EventsType.SOILSAMPLES:
				return 'Soil Samples';
			case EventsType.TISSUE_SAMPLE:
				return 'Tissue Samples';
			default:
				return 'All';
		}
	}

	public static getEventsTypeFromString(type: string): EventsType {
		if (type === 'Water') {
			return EventsType.WATER;
		} else if (type === 'Fertilizer') {
			return EventsType.FERTILIZER;
		} else if (type === 'Cutting') {
			return EventsType.CUTTING;
		}

		return type === 'SoilSamples' ? EventsType.SOILSAMPLES : EventsType.ALL;
	}

	/**
	 * Sort soil sample and tissue sample event nutrients by value and name.
	 * Value is used - if nutrient value is the same, then we sort by nutrient name
	 *
	 * @param nutrients
	 */
	public static sortNutrients(nutrients: INutrient[]): INutrient[] {
		if (!nutrients || !nutrients.length) {
			return new Array();
		}

		return nutrients.sort((nutrientA: INutrient, nutrientB: INutrient) => {

			if (nutrientA.Value > nutrientB.Value) {
				return -1
			} else if (nutrientA.Value < nutrientB.Value) {
				return 1;
			} else if (nutrientA.Nutrient > nutrientB.Nutrient) {
				return -1;
			} else if (nutrientA.Nutrient < nutrientB.Nutrient) {
				return 1;
			} else {
				return 0;
			}
		});
	}
}

