import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';

@Component({
	selector: 'app-forget-password-confirmation',
	templateUrl: 'forget-password-confirmation.page.html'
})
export class ForgetPasswordConfirmationPage implements OnInit {

	constructor(
		private _router: Router) { }

	ngOnInit(): void {
	}
}
