import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { httpStatusCodes, CMError } from '../../../interfaces/interfaces';
import { IRecaptchaResponse } from '../../../services/interface';

@Component({
	selector: 'app-forget-password',
	templateUrl: 'forget-password.page.html'
})
export class ForgetPasswordPage implements OnInit {

	public email: string;
	public errorMessage = '';
	public recaptchaKey: string;
	public isCaptchaValid: boolean;

	constructor(
		private _loginService: LoginService,
		private _changeDetectionRef: ChangeDetectorRef,
		private _router: Router) { }

	ngOnInit(): void {
		this.isCaptchaValid = false;
	}

	public submit() {
		this._loginService.forgetPassword(this.email).then((response) => {
			if (!response) {
				return;
			}

			if (response.code === httpStatusCodes.success) {
				this._router.navigate(['forgetPasswordConfirmation']);
			} else {
				this.errorMessage = response.message;
			}
		});
	}

	public onCaptchaResponse(response: IRecaptchaResponse): void {
		if (!response) {
			this.isCaptchaValid = false;
			return;
		} else if (response.success) {
			this.isCaptchaValid = true;
		} else {
			this.isCaptchaValid = false;
		}

		this._changeDetectionRef.detectChanges();
		// this method is somehow not automatically triggering change detection

	}
}
