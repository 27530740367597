import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpService, HttpServiceGetOptions } from '../../services/http.service';
import { ValidateService } from '../../services/validate.service';
import { ISoilProperty,
	ISoilTypeService, ISoilWebProperties, CombinedSoilProperties } from './interfaces';
import { CMCoordinates } from '../../interfaces/views/map';
import { CMError } from '../../interfaces/interfaces';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class SoilTypeService implements ISoilTypeService {

	private urls = {
		getSoilWebRecord: '/v2/soil-web-record.json',
		get: (lotId: number) => `/v0/lots/${lotId}/soil-properties.json`
	};

	public readonly soilProperties: ISoilProperty[] = [
		{
			key: 'Silt',
			display: 'Silt',
			unit: '%'
		},
		{
			key: 'Sand',
			display: 'Sand',
			unit: '%'
		},
		// we don't display clay
		{
			key: 'Organic',
			display: 'Organic Matter',
			unit: '%'
		},
		{
			key: 'Density',
			display: 'Soil Bulk Density',
			unit: 'g/cc'
		},
		{
			key: 'Tension',
			display: 'Saturated Soil Tension',
			unit: 'cbar'
		},
		/* Custom logic
        {
            key: 'Mineralization',
            display: 'Soil Mineralization Rate',
            unit: 'lb/acre/day'
        },
        */
	];

	constructor(private httpService: HttpService) { }

	/* new calls for soil properties table */

	public getSoilWebRecord(coords: string): Promise<ISoilWebProperties | CMError> {
		let coordinates: CMCoordinates;
		let options: HttpServiceGetOptions;
		let params: HttpParams;

		if (!ValidateService.areCoordinatesValid(coords)) {
			return of(null).toPromise();
		}

		coordinates = ValidateService.getCoordinatesFromString(coords);

		params = new HttpParams().set('lat', coordinates.lat.toString())
			.set('lng', coordinates.lng.toString());

		options = {
			url: this.urls.getSoilWebRecord,
			searchParams: params,
			isWebAPI: true,
			shouldBypassServerErrorAlert: true,

			callback: (data: ISoilWebProperties | CMError) => {

				if (!data) {
					throw new Error(`Received no response from server when
						fetching soil data. Please try again.`);
				}

				return data;
			}
		}

		return this.httpService.get(options);
	}

	public getSoilPropertiesByLot(lotId: number): Promise<CombinedSoilProperties> {

		return this.httpService.get({
			url: this.urls.get(lotId),
			isWebAPI: true,

			callback: (response: CombinedSoilProperties) => {
				return response;
			}
		});
	}
}
