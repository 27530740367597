import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../../../services/notification.service';
import { UserService } from '../../../../models/user/service';
import { eNotificationTypes } from '../../../../interfaces/constants';

@Component({
	moduleId: module.id,
	selector: 'email-ranch-owner',
	templateUrl: 'email.html'
})

export class EmailRanchOwnerDialog implements OnInit {

	public email: {
		to: string,
		recipientName: string,
		subject: string,
		body: string
	};

	public recipientName: string;

	constructor(
		public dialogRef: MatDialogRef<EmailRanchOwnerDialog>,
		public notificationService: NotificationService,
		@Inject(MAT_DIALOG_DATA) public data: { to: string, recipientName: string },
		public service: UserService

	) {
		// disable click outside
		this.dialogRef.disableClose = true;

		if (!data) {
			this.email = {
				to: '',
				recipientName: '',
				subject: '',
				body: ''
			}
		} else {
			this.email = {
				to: data.to,
				recipientName: data.recipientName,
				subject: '',
				body: ''
			};
		}
	}

	ngOnInit(): void {
	}

	public isValid(): boolean {
		if (!this.email || !this.email.to || !this.email.subject || !this.email.body) {
			return false;
		} else {
			return true;
		}
	}

	public send(): void {
		this.service.emailRanchOwner(this.email.to, this.email.subject, this.email.body)
		.then(() => {
			this.dialogRef.close();

			this.notificationService.generateNotifcation({
				type: eNotificationTypes.UPDATE,
				message: 'Your message was sent successfully.'
			});
		});
	}

	public cancel(): void {
		this.dialogRef.close();
	}
}
