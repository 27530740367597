import { Injectable } from '@angular/core';
import { Token, ITokenService } from './interface';
import { SessionStorageWebAPIKey } from './constants';

@Injectable()
export class TokenService implements ITokenService {

	private token: Token = {
		token: null,
		expires: null
	};

	public setToken(token: Token): void {
		this.token = token;
		localStorage.setItem(SessionStorageWebAPIKey, JSON.stringify(token));
	}

	public getToken(): Token {
		let cache: Token;

		cache = JSON.parse(localStorage.getItem(SessionStorageWebAPIKey));

		if (cache) {
			this.token = cache;
		}

		return this.token;
	}

	public isAuthenticated(): boolean {
		let now: Date;

		now = new Date();

		if (!this.token || !this.token.token) {
			this.getToken();
		}

		if (this.token && this.token.token !== null &&
			new Date(this.token.expires) >= now) {

			return true;
		} else {
			return false;
		}
	}

	public logOff(): void {
		this.token = {
			token: null,
			expires: null
		};

		localStorage.removeItem(SessionStorageWebAPIKey);
	}
}
