import { Component, Inject, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMyDpOptions, IMyDateModel, MyDatePicker } from 'mydatepicker';

import { WeatherStationsService } from '../../models/weather-station-stats/service';
import { WeatherStationStatsCharts } from './weatherStationStatsCharts';
import { DateUtility } from '../../classes/dateUtility';
import { UpdateService } from '../../services/update.service';

import {
	IWeatherStationStatsDetails, IWeatherStationStats, IWeatherStationStatsGraphData,
	IWeatherStationGraphRecord, IWeatherStationDistributionByRanch, IWeatherStationDistributionByCommodity
} from './interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	moduleId: module.id,
	selector: 'weather-station-stats-dialog',
	templateUrl: 'weather-station-stats-dialog.html'
})

export class WeatherStationStatsDialog implements OnInit, OnDestroy {
	@ViewChild('chartContainer', { static: true }) chartContainer: ElementRef;

	public startDateFilter: { jsdate: Date } = { jsdate: new Date() };
	public endDateFilter: { jsdate: Date } = { jsdate: new Date() };
	public startDateFilterOptions: IMyDpOptions = {
		showClearDateBtn: false
	};
	public endDateFilterOptions: IMyDpOptions = {
		showClearDateBtn: false
	};
	public isDateRangeValid = {
		startDate: true as boolean,
		endDate: true as boolean
	}

	public title: string;
	public number: string;
	public summary: IWeatherStationStats;
	public distributionByRanchRows: IWeatherStationDistributionByRanch[];
	public distributionByCommodityRows: IWeatherStationDistributionByCommodity[];
	public viewByRanch  = true;

	private _subscriptions$: Subject<boolean>;
	private weatherStationId: number;

	constructor(public dialogRef: MatDialogRef<WeatherStationStatsDialog>,
		@Inject(MAT_DIALOG_DATA) public data: { id: number, startDate: Date, endDate: Date },
		private weatherStationsService: WeatherStationsService,
		private updateService: UpdateService) {
		let maxDate: Date = DateUtility.addDays(new Date(), 1);

		this.weatherStationId = data.id;
		this.startDateFilter.jsdate = data.startDate;
		this.endDateFilter.jsdate = data.endDate;

		this.startDateFilterOptions.disableSince = {
			year: maxDate.getFullYear(),
			month: maxDate.getMonth() + 1,
			day: maxDate.getDate()
		};
		this.endDateFilterOptions.disableSince = {
			year: maxDate.getFullYear(),
			month: maxDate.getMonth() + 1,
			day: maxDate.getDate()
		};
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();

		this.getWeatherStationStatsDetails(this.data.startDate, this.data.endDate);
		this.updateService.closeModalSubscription.pipe(takeUntil(this._subscriptions$)).subscribe(event => this.dialogRef.close());
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public getWeatherStationStatsDetails(startDate: Date, endDate: Date): void {
		let daily = false;

		this.weatherStationsService.getStats(this.weatherStationId, startDate, endDate)
			.then(response => {
				if (!response) {
					return;
				}

				this.summary = response.Summary;
				this.title = this.summary.Name;
				this.number = this.summary.ExternalId;

				this.distributionByRanchRows = response.DistributionByRanch;
				this.distributionByCommodityRows = response.DistributionByCommodity;

				if (startDate.getMonth() === endDate.getMonth()) {
					daily = true;
				}

				if (this.summary.RanchCount > 0 && this.summary.RecommendationCount > 0) {
					this.generateGraph(response.Graph, daily);
				}
			});
	}

	public startDateFilterChanged(date: IMyDateModel): void {
		let startDate: Date;
		let endDate: Date;

		// reset end date validation
		endDate = this.endDateFilter ? this.endDateFilter.jsdate : null;
		this.isDateRangeValid.endDate = endDate && endDate instanceof Date;

		if (!date || !date.jsdate) {
			this.isDateRangeValid.startDate = false;
			return;
		}

		startDate = date.jsdate;

		this.isDateRangeValid.startDate = this.isDateRangeValid.endDate ? startDate <= endDate : true;

		if (this.isDateRangeValid.startDate && this.isDateRangeValid.endDate) {
			this.getWeatherStationStatsDetails(startDate, endDate);
		}
	}

	public endDateFilterChanged(date: IMyDateModel): void {
		let startDate: Date;
		let endDate: Date;

		// reset end date validation
		startDate = this.startDateFilter ? this.startDateFilter.jsdate : null;
		this.isDateRangeValid.startDate = startDate && startDate instanceof Date;

		if (!date || !date.jsdate) {
			this.isDateRangeValid.endDate = false;
			return;
		}

		endDate = date.jsdate;

		this.isDateRangeValid.endDate = this.isDateRangeValid.startDate ? endDate >= startDate : true;

		if (this.isDateRangeValid.startDate && this.isDateRangeValid.endDate) {
			this.getWeatherStationStatsDetails(startDate, endDate);
		}
	}

	public close(): void {
		this.dialogRef.close();
	}

	private generateGraph(graphRecords: IWeatherStationGraphRecord[], daily: boolean): void {
		let graphData: IWeatherStationStatsGraphData;
		let params: { graphData: IWeatherStationStatsGraphData, daily: boolean };

		if (!graphRecords || !this.chartContainer) {
			return;
		}
		graphData = WeatherStationStatsCharts.convertData(graphRecords);

		params = { graphData: graphData, daily: daily };

		WeatherStationStatsCharts.generateHighchart(this.chartContainer.nativeElement, params);
	}
}
