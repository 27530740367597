export type PlantingTabs = 'ActivePlantings' | 'FavoritePlantings' | 'AllPlantings';

export const PlantingTabValues = {
	active: 'ActivePlantings' as PlantingTabs,
	favorites: 'FavoritePlantings' as PlantingTabs,
	all: 'AllPlantings' as PlantingTabs
}

export const PlantingTabTitles = {
	active: 'Active Plantings',
	favorites: 'Favorite Plantings',
	all: 'All Plantings'
}

export enum EventsView {
	LIST = 1,
	TABLE,
	TOTALS // REFACTOR - what is this view?
};

export enum NUTRIENT_KEYS {
	N = 0,
	P,
	K
};

export enum EventsType {
	ALL = 1,
	WATER,
	FERTILIZER,
	SOILSAMPLES,
	CUTTING,
	TISSUE_SAMPLE
}

export type EventTypeName = 'Water' | 'Fertilizer' | 'SoilSamples' | 'Cutting' | 'TissueSamples';

export const EventTypeNames = {
	WATER: 'Water' as EventTypeName,
	FERTILIZER: 'Fertilizer' as EventTypeName,
	SOILSAMPLES: 'SoilSamples' as EventTypeName,
	CUTTING: 'Cutting' as EventTypeName,
	TISSUE_SAMPLE: 'TissueSamples' as EventTypeName
}

export const ModalTypes = {
	IRRIGATION: 'IrrigationEventModal',
	FERTILIZATION: 'FertilizationEventModal',
	SOILSAMPLE: 'SoilSampleEventModal'
}

export enum EventContext {
	SNAPSHOT = 1,
	TABLE,
	LIST
};

export enum eWeatherAPIs {
	CIMIS = 1,
	WesternWX,
	Hobolink,
	Invalid,
	Azmet,
	CROPNET,
	OpenET
}

export enum eIrrigationMethods {
	SPRINKLER = 1,
	RAINFALL = 2,
	DRIP = 3,
	GERMINATION_SPRINKLER = 5,
	FLOOD = 6,
	SOIL_TENSION_READING = 8,
	MICRO_SPRINKLER = 7
}

export enum eSampleTypeIds {
	QUICK_NITRATE_STRIP = 1,
	LAB_TEST = 2
}

export enum eCommodityTypes {
	LETTUCE = 1,
	BROCCOLI = 2,
	SPINACH = 3,
	CELERY = 4,
	CAULIFLOWER = 5,
	STRAWBERRY = 6,
	CABBAGE = 7,
	CILANTRO = 8,
	MIZUNA = 9,
	PEPPERS = 10,
	PROCESSING_TOMATO = 11,
	CANEBERRY = 12,
	// WILL BE DIFFERENT ON PRODUCTION/OTHERS LOCAL DATABASES
	ALFALFA = 17,
	ALMOND = 18,
	BRUSSEL_SPROUTS = 19,
	PISTACHIO = 32,
	WALNUT = 33,
	PRUNE = 34,
	PEAR = 35,
	GRAPE = 39,
}

export enum eCalculators {
	LETTUCE = 1, // covers vegetable crops
	STRAWBERRY, // covers caneberry
	ALFALFA,
	ALMOND,
	TOMATO,
	PISTACHIO,
	WALNUT
}

export enum eIrrigationUnit {
	INCHES = 1,
	HOURS
}

export enum eSoilWebCrawErrors {
	None = 0,
	OutOfRange = 1, // coordinates outside US
	ServerFailure = 2, // SoilWeb server failed to respond
	ParseError = 3, // HTML parse error
}

export enum eSoilMoistureViews {
	GRAPH = 1,
	SETTINGS,
	CONFIRMATION
}

export enum eSoilMoistureImportErrors {
	DATE = 1,
	COLUMNS,
	NOTFOUND,
	EMPTY,
	GENERIC
}

export enum eFertilizerFormulationTypes {
	DRY = 1,
	WET
}

export enum eModalFormViews {
	FORM = 1,
	DISCARDCHANGES,
	DELETECONFIRMATION
}

export enum eNotificationTypes {
	MESSAGE = 1,
	ERROR,
	UPDATE
}

export enum eDialogFormViews {
	FORM = 1,
	DISCARDCHANGES,
	DELETECONFIRMATION
}

export type irrigationMethodName = 'Sprinkler' | 'Rainfall' | 'Drip' | 'Flow Meter' | 'Germination Sprinkler' | 'Flood';

export const irrigationMethodNames = {
	sprinker: 'Sprinkler' as irrigationMethodName,
	rainfall: 'Rainfall' as irrigationMethodName,
	drip: 'Drip' as irrigationMethodName,
	flowMeter: 'Flow Meter' as irrigationMethodName,
	germinationSprinkler: 'Germination Sprinkler' as irrigationMethodName,
	flood: 'Flood' as irrigationMethodName
}

export type waterUnit = 'hours' | 'inches';

export type fertilizerUnit = 'fertilizerUnit' | 'lbsNPerAcre';

export type soilSamplesUnit = 'lbsNPerAcre' | 'ppmNitrate-N';

export const units = {
	water: {
		hours: 'hours' as waterUnit,
		inches: 'inches' as waterUnit
	},
	fertilizer: {
		fertilizerUnit: 'fertilizerUnit' as fertilizerUnit,
		lbsNPerAcre: 'lbsNPerAcre' as fertilizerUnit
	},
	soilSamples: {
		lbsNPerAcre: 'lbsNPerAcre' as soilSamplesUnit,
		ppmNitrateN: 'ppmNitrate-N' as soilSamplesUnit
	}
}

export const IRRIGATION_RECENCY_DAYS = 60;
export const CUTTING_RECENCY_DAYS = 60;
