<div class="bk-dialog bk-dialog__event">

    <button type="button"
        class="bk-dialog__close"
        (click)="close()">

        <span>&times;</span>
    </button>

    <h4 mat-dialog-title>
        {{ 'Recommendations Changed' | translate}}
    </h4>

    <div mat-dialog-content>
        <div class="bk-modal__remove-text">
            {{'Your action changed recommendations for these irrigation event(s)' | translate }}:
        </div>
        <table class="bk-event-table__affected-events">
            <tr *ngFor="let event of events">
                <td class="bk-event-table__affected-events-cell--date">
                    {{event.EventDate | date:'mediumDate'}}
                </td>
                <td class="bk-event-table__affected-events-cell">
                    <div class="bk-planting__event-row__icon bk-button--blue-icon">
                        <svg-water></svg-water>
                    </div>
                    <span *ngIf="event && event.Irrigation">
                        {{event.Irrigation.IrrigationMethod}}
                    </span>
                </td>
            </tr>
        </table>
    </div>
    <div mat-dialog-actions>
        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary" 
            (click)="close()">
            {{'Close' | translate }}
        </button>
    </div>
</div>