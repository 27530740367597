import { Injectable } from '@angular/core';
import { IPlanting } from '../components/planting-settings/interfaces';
import { ICurrentUser } from '../models/user/interfaces';
import { IRanchPermissions } from '../components/ranch-settings/interfaces';
import { PlantingTabTitles } from '../interfaces/constants';
import * as jQuery from 'jquery';
import { CookieService } from './cookie.service';
import { ETCalculatorResultsInputParams } from '../components/public/et-calculator/et-calculator-results.component';
import { GlobalNotificationExists } from './constants';

/**
 * Used to maintain data across different views without depending on triggers
 * The data is persistent, so need to be careful what we put here, to minimize memory allcoation
 */
@Injectable()
export class PersistentDatabase {
	public planting: IPlanting;
	public wetDate: Date;
	public user: ICurrentUser;
	public permissions: IRanchPermissions;
	public isGlobalNotificationVisible: boolean;
	public selectedPlantingsTab: string;
	public globalNotificationCookieName = 'donationCookie1.1';
	public cropTypeQuery: string;
	public cropTypePageIndex: number;
	public etCalculatorParams: ETCalculatorResultsInputParams;

	public verticalSCrollPosition: {
		plantingsView: number
	} = {
			plantingsView: null
		};

	public getPlantingsViewScrollPosition() {
		let el: JQuery;

		el = jQuery(this.getScrollArea());

		if (!el) {
			return;
		}

		this.verticalSCrollPosition.plantingsView = el.scrollTop();
	}

	public GlobalNotificationIsVisible(): boolean {
		let cookie: string;

		if (!GlobalNotificationExists) {
			return false;
		}

		cookie = CookieService.get(this.globalNotificationCookieName);

		if (cookie === null) {
			return true;
		} else {
			// if cookie is set, then it was dismissed.. so its not visible
			return false;
		}
	}

	public resetPlantingsViewScrollPosition() {
		let el: JQuery;

		el = jQuery(this.getScrollArea());

		if (!el) {
			return;
		}

		if (this.verticalSCrollPosition.plantingsView) {
			el.scrollTop(this.verticalSCrollPosition.plantingsView);
		}
	}

	public getScrollArea(): Element {
		let el: JQuery;
		let index: number;

		switch (this.selectedPlantingsTab) {
			case PlantingTabTitles.active:
				index = 0;
				break;
			case PlantingTabTitles.favorites:
				index = 1;
				break;
			case PlantingTabTitles.all:
				index = 2;
				break;
			default: // if nothing is selected, assume active planting
				index = 0;
				break;
		}

		el = jQuery('.bk-flex-table__scroll-area');

		if (!el) {
			return null;
		}

		if (!el.get(index)) {
			if (index !== 2) {
				return;
			} else if (!el.get(1)) { // get(1) may be either favorite plantings or all plantings
				return;
			} else {
				index = 1;
			}
		}

		return el.get(index);
	}
}
