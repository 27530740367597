import { ILocalizatinDictionaryJSON, ITranslation } from './interfaces';

export class Translation {
	public static processTranslationsList(data: ILocalizatinDictionaryJSON[]): { list: ITranslation[], map: string[][] } {

		let list: ITranslation[];
		let entries: ILocalizatinDictionaryJSON[];

		list = new Array();
		entries = data;

		for (let entry of entries) {

			let newKeyword = true;

			for (let keyword of list) {
				if (entry.Keyword === keyword.keyword) {
					newKeyword = false;

					keyword.translations[entry.LanguageCode] = {
						keyword: entry.Keyword,
						id: entry.Id,
						languageCode: entry.LanguageCode,
						word: entry.Word
					}
					break;
				}
			}
			if (newKeyword) {
				list.push({
					keyword: entry.Keyword,
					editing: false,
					translations: {}
				});

				for (let keyword of list) {
					if (entry.Keyword === keyword.keyword) {
						keyword.translations[entry.LanguageCode] = {
							keyword: entry.Keyword,
							id: entry.Id,
							languageCode: entry.LanguageCode,
							word: entry.Word
						}
						break;
					}
				}
			}
		};

		return {
			list: list,
			map: Translation.configureMap(data)
		};
	}

	public static configureMap(translations: ILocalizatinDictionaryJSON[]): string[][] {

		let languageHashMap: string[][] = new Array();

		if (!translations || translations.length === 0) {
			return;
		}

		for (let translation of translations) {
			if (!languageHashMap[translation.LanguageCode]) {
				languageHashMap[translation.LanguageCode] = new Array();
			}

			languageHashMap[translation.LanguageCode][translation.Keyword] = translation.Word;
		}

		return languageHashMap;
	}
}
