import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FeedbackService } from './service';
import { NotificationService } from '../../services/notification.service';
import { eNotificationTypes } from '../../interfaces/constants';

@Component({
	moduleId: module.id,
	selector: 'feedback',
	templateUrl: 'feedback.html'
})

export class FeedbackComponent implements OnInit {

	public description: string;

	constructor(
		public dialogRef: MatDialogRef<FeedbackComponent>,
		public notificationService: NotificationService,
		public service: FeedbackService
	) {
		// disable click outside
		this.dialogRef.disableClose = true;
	}

	ngOnInit(): void {
	}

	public send(): void {

		// validate ET value before posting...

		if (!this.description) {
			return;
		}

		this.service.send(this.description).then(() => {
			this.dialogRef.close();

			this.notificationService.generateNotifcation({
				type: eNotificationTypes.UPDATE,
				message: 'Your feedback was sent successfully.'
			});

		});
	}

	public cancel(): void {
		this.dialogRef.close();
	}
}
