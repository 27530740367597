import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedUpdateService } from './update.service';

export interface DeleteConfirmationSettings {
	objectName: string,
	specificName: string,
	additionalMessage: string,
}

@Component({
	moduleId: module.id,
	selector: 'delete-confirmation-dialog',
	templateUrl: 'delete-confirmation.html',
	styleUrls: [ 'delete-confirmation.scss' ]
})

/**
 * Generic component used to display delete confirmation dialogs
 */
export class DeleteConfirmationComponent implements OnInit, OnDestroy {

	public additionalMessage: string;
	public isBusy: boolean;
	public objectName: string;
	public specificName: string;
	private _subscriptions$: Subject<boolean>;

	public constructor(
		private _dialogRef: MatDialogRef<DeleteConfirmationComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: DeleteConfirmationSettings,
		private _updateService: SharedUpdateService,
	) {
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();

		if (this._data) {
			this.objectName = this._data.objectName;
			this.specificName = this._data.specificName;
			this.additionalMessage = this._data.additionalMessage;
		}
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public delete(): void {
		if (this.isBusy) {
			return;
		}

		this.isBusy = true;
		this._updateService.delete();

		this._updateService.deleteComplete$.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.isBusy = false;
			this._dialogRef.close();
		})
	}

	public close() {
		this._updateService.cancel();
		this._dialogRef.close();
	}
}
