import { Component, Input, OnInit } from '@angular/core';
import { TabInterface } from '../../interfaces/tab.interface';
import { ProfileNavComponent } from './nav';

@Component({
	moduleId: module.id,
	selector: 'profile-tab',
	template: `
        <div class="bk-tab__container tab" [hidden]="!selected">
            <ng-content></ng-content>
        </div>
    `,
	styleUrls: ['../shared/tab/main.css']
})

export class ProfileTabComponent implements OnInit, TabInterface {
	@Input()
	tabTitle: string;

	selected = false;

	constructor(private profileNavComponent: ProfileNavComponent) { }

	ngOnInit() {
		this.profileNavComponent.addTab(this);
	}

}
