import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	moduleId: module.id,
	selector: 'http-error-dialog',
	templateUrl: 'http-error-dialog.html'
})

export class HttpErrorDialog {

	public message: string;

	constructor(public dialogRef: MatDialogRef<HttpErrorDialog>,
		@Inject(MAT_DIALOG_DATA) public data: { errorMessage: string }) {

		if (data) {
			this.message = data.errorMessage;
		}
	}

	public close(): void {
		this.dialogRef.close();
	}
}
