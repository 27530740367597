<div class="bk-dialog bk-dialog__event">
	
	<button type="button"
		class="bk-dialog__close"
		(click)="cancel()">
		&times;
	</button>

	<h4 mat-dialog-title>
		{{ 'ET Chart' | translate }}
	</h4>
	<h5 class='bk-modal__subTitle'>
		{{ planting.Name }}
	</h5>

	<div mat-dialog-content>
		<div *ngIf="etEvents && etEvents.length === 0">
			{{ 'There are no ET events associated with this planting.' | translate }}
		</div>
		<div *ngIf="isLoaded === false"
			class='bk-et-chart__loader bk-vertical-center'>

			<div class="bk-loader__container">
				<div class="bk-loader"></div>
			</div>
		</div>
		<table *ngIf="etEvents && etEvents.length > 0"
			class="bk-modal__table">
			<thead>
				<tr>
					<th>
						{{'Date' | translate }}
					</th>
					<th>
						{{'ET' | translate }}
					</th>
					<th>
						{{ 'Source' | translate }}
					</th>
					<th *ngFor="let tableHeader of tableHeaders">
						{{ tableHeader }}
					</th>
				</tr>
			</thead>

			<tbody>
				<ng-container *ngFor="let etEvent of etEvents">
					<tr class="bk-modal__row" >
						<td>
							{{etEvent.EventDate | date:'M/d/yyyy'}}
						</td>
						<td>
							<input class="bk-input--short"
								type="number"
								step="0.01"
								(blur)="save(etEvent)"
								[(ngModel)]="etEvent.ET" />
						</td>
						<td>
							{{ etEvent.Source }}
						</td>
						<td *ngFor="let stationET of etEvent.Stations">
							{{ stationET !== null ? stationET : ('No Data' | translate) }}
						</td>
						<td *ngIf="tableHeaders.indexOf('Spatial') > -1">
							{{ etEvent.SpatialET !== null ? etEvent.SpatialET : ('No Data' | translate ) }}
						</td>
					</tr>
				</ng-container>
			</tbody>
		</table>
	</div>

	<div mat-dialog-actions align="end">

		<button (click)="cancel()" mat-button
			class="bk-button bk-button--medium bk-dialog__button--primary">
			{{ 'Close' | translate }}
		</button>
	</div>
</div>