import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WeatherStationsService } from '../../models/weather-station-stats/service';
import { CIMISDayDataService } from './cimis-day-data.service';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import { ICIMISDayDataViewModel, IHistoricalETViewModel, IMissingStationRecord } from './interfaces';
import { DateUtility } from '../../classes/dateUtility';
import { HighChartsService } from '../../classes/highCharts.service';
import { HistoricalETService } from './historical-et.service';

@Component({
	moduleId: module.id,
	selector: 'missing-station-records',
	templateUrl: 'missing-station-records.html'
})

export class MissingStationRecordsComponent implements OnInit, OnDestroy {
	public dataSource: MatTableDataSource<IMissingStationRecord>;

	public displayedColumns: string[] = ['Date', 'Status' ];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	public stationId: number;
	public stationName: string;

	@ViewChild('chartContainer', { static: true }) private _chartContainer: ElementRef;
	private _chart: Highcharts.IndividualSeriesOptions[];
	private _subscriptions$: Subject<boolean>;

	constructor(
		private _cimisDayDataService: CIMISDayDataService,
		private _historicalETService: HistoricalETService,
		private _route: ActivatedRoute,
		private _weatherStationService: WeatherStationsService,
	) {}

	ngOnInit() {
		this._subscriptions$ = new Subject();

		this._route.queryParams.pipe(takeUntil(this._subscriptions$)).subscribe(params => {
			this.stationId = params['stationId'];
			this.stationName = params['name']

			this._loadGraph();

			this._weatherStationService.getMissingRecords(this.stationId).then((data) => {
				this._setDataSource(data);
			});
		})
	}

	ngOnDestroy() {}

	private _convertToGraphData(cimis: ICIMISDayDataViewModel[],
		historicalET?: IHistoricalETViewModel[]): void {

		let temp: {
			ET: Highcharts.IndividualSeriesOptions,
			historicalET?: Highcharts.IndividualSeriesOptions,
			Rainfall?: Highcharts.IndividualSeriesOptions
		};

		if (!cimis || cimis.length === 0) {
			return;
		}

		temp = {
			ET: {
				name: 'CIMIS ET',
				visible: true,
				data: [],
				type: 'line'
			},
			Rainfall: {
				name: 'Rainfall',
				visible: false,
				data: [],
				type: 'line'
			}
		}

		this._chart = [];

		for (let record of cimis) {
			let d: number;

			d = DateUtility.DateToUTC(record.Date);
			temp.ET.data.push([d, record.ET]);
			temp.Rainfall.data.push([d, record.Rainfall]);
		}

		this._chart.push(temp.ET);
		this._chart.push(temp.Rainfall);

		// historical ET is optional for some weather stations
		if (historicalET && historicalET.length > 0) {
			temp.historicalET = {
				name: 'Historical ET',
				visible: true,
				data: [],
				type: 'line'
			}

			for (let record of historicalET) {
				temp.historicalET.data.push([
					DateUtility.DateToUTC(record.Date), record.ET
				])
			}

			this._chart.push(temp.historicalET);
		}
	}

	private _loadGraph(): void {
		let start: moment.Moment;
		let end: moment.Moment;

		start = moment().subtract(180, 'day');
		end = moment(); // today

		let promises: {
			cimis: Promise<ICIMISDayDataViewModel[]>,
			historicalET: Promise<IHistoricalETViewModel[]>
		}

		promises = {
			cimis: this._cimisDayDataService.get(this.stationId, start.toDate(), end.toDate()),
			historicalET: this._historicalETService.get(this.stationId, start.toDate(), end.toDate())
		};

		Promise.all([promises.cimis, promises.historicalET]).then(([cimis, historicalET]) => {
			this._convertToGraphData(cimis, historicalET);
			HighChartsService.drawGraph(this._chartContainer, this._chart);
		})
	}

	private _setDataSource(data: IMissingStationRecord[]): void {
		this.dataSource = new MatTableDataSource(data);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}
}
