
<button [hidden]="isSliderVisible"
    class="bk-button bk-slider-button"
    [disabled]="sliderDisabled"
    (click)="showSlider()">
    <i class="fa fa-sort-up bk-slider-button__icon--up"></i>
    <i class="fa fa-sort-down bk-slider-button__icon--down"></i>
</button>

<div [hidden]="!isSliderVisible" class="bk-slider" (clickOutside)="hideSlider()">
    <div class="bk-slider__max">{{ sliderMax }}</div>

    <div class="bk-slider__wrapper">
        <mat-slider #mySlider
            class="bk-slider__input"
            [(ngModel)]="sliderValue"
            [vertical]="true"
            [step]="step"
            [min]="sliderMin"
            [max]="sliderMax"
            (mouseup)="handleSliderMouseUp($event)"
            (input)="sliderChanged(mySlider.value)">
        </mat-slider>
    </div>

    <div class="bk-slider__min">{{ sliderMin }}</div>
</div>