<div class="bk-dialog">
    <h4 mat-dialog-title>
        {{'Disconnect Your Account From CASP' | translate }}
    </h4>
    <div mat-dialog-content class="modal-body bk-modal__body bk-planting-modal__body bk-modal__body--white bk-modal__scroll-area">
        <p>
            Are you sure you want to disconnect your account from your CASP account?
            Your CASP account will no longer be kept up to date with CropManage, and
            reconnecting later on may be difficult. Disconnecting from CASP will not affect CropManage data that
            was imported into CASP.
        </p>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only"
            (click)="disconnect()">
            {{'Disconnect my Account from CASP' | translate }}
        </button>
        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="close()">
            {{'Nevermind' | translate }}
        </button>
    </div>
</div>

    