import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IEventGroup } from '../../models/event/interfaces';

@Component({
	moduleId: module.id,
	selector: 'soil-sample-warning',
	templateUrl: 'warning.html',
})

export class SoilSampleWarningDialog implements OnInit {

	public affectedEvents: IEventGroup[];

	constructor(
		@Inject(MAT_DIALOG_DATA) private _data: { affectedEvents: IEventGroup[] },
		private dialogRef: MatDialogRef<SoilSampleWarningDialog>,
	) {

		if (this._data) {
			this.affectedEvents = this._data.affectedEvents;
		}
	}

	ngOnInit() {
	}

	public close(): void {
		this.dialogRef.close();
	}
}
