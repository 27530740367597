import { Input, OnChanges, Directive, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

@Directive({
	selector: '[minNumber]',
	providers: [{ provide: NG_VALIDATORS, useExisting: MinNumberDirective, multi: true }]
})
export class MinNumberDirective implements Validator, OnChanges {
	@Input() minNumber: number;
	private valFn = Validators.nullValidator;

	ngOnChanges(changes: SimpleChanges): void {
		const change = changes['minNumber'];

		if (change) {
			const n: number = change.currentValue;
			this.valFn = minNumberValidator(n);
		} else {
			this.valFn = Validators.nullValidator;
		}
	}

	validate(control: AbstractControl): { [key: string]: ValidationErrors } {
		return this.valFn(control);
	}
}

/**
 *
 * @param n minNumber setting
 */
export function minNumberValidator(n: number): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } => {
		const userInput = control.value; // user input

		if (userInput === null) {
			return null; // check for required value elsewhere
		} else if (userInput >= n) {
			return null;
		} else {
			return { 'minNumber': { a: userInput } };
		}
	};
}
