import { Component, Inject, OnInit, ViewChild, OnDestroy, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Canopy } from './canopy';
import { CanopyTableComponent } from './canopy-table';

import { CanopyService } from './canopy.service';
import { KeyboardUtility } from '../../classes/keyboardUtility';

import { ICanopyData, ISimsGraphDataModel } from './interfaces';
import { Subject } from 'rxjs';
import { IHighChartCustomSeries } from '../available-water-chart/interfaces';
import highChartExportOptions from '../shared/highchartsExportOptions';
import * as Highcharts from 'highcharts';
import { PlantingService } from '../planting-settings/service';
import { UpdateService } from '../../services/update.service';
import { RanchService } from '../ranch-settings/service';
import { NotificationService } from '../../services/notification.service';
import { eCommodityTypes, eNotificationTypes } from '../../interfaces/constants';
import { FormBuilder } from '@angular/forms';
import { eCommodityTypeCalculators } from '../../models/commodity-type/interfaces';

/**
 * Refactoring Log:
 * (8/16/2019) cleaned up private/public methods naming and ordering
 */
@Component({
	moduleId: module.id,
	selector: 'canopy-dialog',
	templateUrl: 'canopy-dialog.html',
	styleUrls: ['canopy-dialog.scss']
})

export class CanopyDialog implements OnInit, OnDestroy {
	@ViewChildren('highchartsContainer') private _highchartsContainers: QueryList<ElementRef>;
	private _highchartsContainer: ElementRef;
	public canopy: Canopy;
	public hasCoordinates = true;
	public plantingId: number;
	public plantingName: string;
	public isLoaded = false;
	public isSaving: boolean;
	private _chartData: Highcharts.IndividualSeriesOptions[];
	public defaultSetting: ICanopyData;
	public COMMODITY_TYPES = eCommodityTypes;
	public CALCULATORS = eCommodityTypeCalculators;

	@ViewChild(CanopyTableComponent, { static: false }) public canopyTable: CanopyTableComponent; // child component so must be public
	public commodityTypeCalculator: string;
	public commodityTypeId: number;

	private _subscriptions$: Subject<boolean> = new Subject();

	constructor(
		private _dialogRef: MatDialogRef<CanopyDialog>,

		@Inject(MAT_DIALOG_DATA) private _data: {
			plantingId: number,
			plantingName: string,
			commodityTypeId: number,
			commodityTypeCalculator: string
		},

		private _canopyService: CanopyService,
		private _dialog: MatDialog,
		private _fb: FormBuilder,
		private _notificationService: NotificationService,
		private _plantingService: PlantingService,
		private _ranchService: RanchService,
		private _updateService: UpdateService) {

		this.plantingId = this._data.plantingId;
		this.plantingName = this._data.plantingName;

		this.commodityTypeId = this._data.commodityTypeId;
		this.commodityTypeCalculator = this._data.commodityTypeCalculator;
	}

	ngOnInit(): void {

		this.canopy = new Canopy(this.commodityTypeId, this.commodityTypeCalculator,
			this._fb);

		this.isSaving = false;

		this._canopyService.simsData(this.plantingId)
		.then((data) => {
			if (!data) {
				return;
			}

			this.defaultSetting = {
				PlantingId: null,
				ranchGuid: null,
				CanopyGMax: data.CanopyParamsDefault.CanopyGMax,
				MaxFraction: data.CanopyParamsDefault.MaxFraction,
				CanopyA: data.CanopyParamsDefault.CanopyA,
				CanopyB: data.CanopyParamsDefault.CanopyB,
				CanopyE: data.CanopyParamsDefault.CanopyE,
				CanopyF: data.CanopyParamsDefault.CanopyF,
				CanopyMin: data.CanopyParamsDefault.CanopyMin,
				HasCoordinates: null
			};

			this.canopy.setData(data);

			if (this.canopy.isInvalid()) {
				return;
			}

			this.canopyTable.loadDataCustomSettings();
			this._loadGraph();
			this.hasCoordinates = data.HasCoordinates;
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public close(): void {
		this._dialogRef.close();
	}

	/**
	 * This triggers when an entry is either added or removed from
	 * the canopy table
	 */
	public dataUpdated(): void {
		this._loadGraph();
	}

	/**
	 * Method called by this component's template to only allow numbers
	 * @param e
	 */
	public preventNonNumbers(e: KeyboardEvent): void {
		KeyboardUtility.preventNonNumbers(e, true);
	}

	public reloadData(): void {
		if (this.canopy.isInvalid()) {
			return;
		}

		this._loadGraph();
		this.canopyTable.loadDataCustomSettings();
	}

	public isRequiredField(field: string) {
		let control: AbstractControl;

		control = this.canopy.form.get(field);
		if (!control.validator) {
			return false;
		}

		const validator = control.validator({} as AbstractControl);
		return (validator && validator.required);
	}

	public reset(): void {
		this.canopy.resetForm(this.defaultSetting);
		this.reloadData();
	}

	public save(): void {
		let data: ICanopyData;

		data = this.canopy.getModelForPost();

		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this._canopyService.update(data, this.plantingId)
		.then(() => {
			this.isSaving = false;
			this._dialogRef.close();
			this._recalculatePlanting();
		});
	}

	private _recalculatePlanting(): void {
		this._plantingService.recalculatePlanting(this.plantingId, true).then((res) => {
			if (!res || !res.Events) {
				this._notificationService.generateNotifcation({
					type: eNotificationTypes.ERROR,
					message: 'The planting recalculation failed'
				});

				return;
			} else {
				this._notificationService.generateNotifcation({
					type: eNotificationTypes.UPDATE,
					message: 'The planting was successfully recalculated'
				});
			}

			this._ranchService.plantings.replacePlantingEvents(res.Events, this.plantingId);
			this._updateService.setPlantingsUpdated(this._updateService.currentRanchId, new Date()); // update views if needed
		});
	}

	private _convertToGraphData(records: ISimsGraphDataModel): void {

		let temp: {
			cropManage: Highcharts.IndividualSeriesOptions,
			SIMS: Highcharts.IndividualSeriesOptions,
			user: Highcharts.IndividualSeriesOptions
		};

		this._chartData = [];

		if (!records) {
			return;
		}

		temp = {
			cropManage: {
				name: 'CropManage Canopy',
				visible: true,
				data: JSON.parse(records.CropManageData),
				type: 'line'
			},
			SIMS: {
				name: 'SIMS Canopy',
				visible: true,
				data: JSON.parse(records.SIMSCanopyData),
				type: 'scatter'
			},
			user: {
				name: 'User Data',
				visible: true,
				data: JSON.parse(records.UserCanopyData),
				type: 'scatter'
			}
		}

		this._chartData.push(temp.cropManage);
		this._chartData.push(temp.SIMS);
		this._chartData.push(temp.user);
	}

	private _drawGraph(chart: ElementRef, data: IHighChartCustomSeries[]): void {

		let options: Highcharts.Options;

		if (!chart) {
			return;
		}

		options = {
			chart: {
			// type: 'line',
			},
			title: {
				text: null,
			},
			legend: {
				title: {
					text: null,
				}
			},
			tooltip: {
				backgroundColor: '#FFF',
				borderColor: '#FFF',
				valueDecimals: 2,
				valueSuffix: ' %',
				pointFormat: '{point.y}',
				headerFormat: '<span style="font-size: 10px">{point.x:%b %d, %Y}</span><br>'
			},
			xAxis: {
				type: 'datetime',
				gridLineWidth: 1
			},
			yAxis: [
				{
					title: {
						text: '%'
					}
				}
			],
			series: data,
			exporting: highChartExportOptions
		}

		Highcharts.chart(chart.nativeElement, options);
	}

	private async _loadGraph(): Promise<void> {

		const response = await this._canopyService.simsGraphData(this.canopy);

		this._convertToGraphData(response);
		// we're having to do this because of the ng ifs that are cluttering
		// this template
		this._highchartsContainer = this._highchartsContainers.first;
		this._drawGraph(this._highchartsContainer, this._chartData);
		this.isLoaded = true;
	}
}
