import { Injectable } from '@angular/core';

import { IPlantingAdvancedEdit } from '../../components/planting-settings/interfaces';
import { ICommodityType, ICommodityTypesRanchPostResponseViewModel, ICommodityService } from './interfaces';
import { HttpService } from '../../services/http.service';
import { of } from 'rxjs';
import { SuccessResponse } from '../../interfaces/interfaces';

@Injectable()
export class CommodityService implements ICommodityService {

	private urls = {
		commodityType: {
			list: '/v2/commodity-types.json',
		},
		commodityTypesRanch: {
			list: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/commodity-types.json`,
			listAll: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/commodity-types/list-all.json`,
			save: (ranchGuid: string) =>  `/v2/ranches/${ranchGuid}/commodity-types.json`,
			delete: (ranchGuid: string, commodityTypeId: number) => `/v2/ranches/${ranchGuid}/commodity-types/${commodityTypeId}.json`
		}
	};

	public commodityTypes: ICommodityType[] = new Array();

	/**
     * Get commodity type from planting JSON
     *
     * @param planting
     * @param commodityTypeId
     */
	public static getCommodityType(commodityTypes: ICommodityType[],
		commodityTypeId?: number): ICommodityType {

		if (!commodityTypes || commodityTypes.length === 0) {
			return null;
		}

		for (let commodityType of commodityTypes) {
			if (commodityType.Id === commodityTypeId) {
				return commodityType;
			}
		}

		return null;
	}

	constructor(
		private httpService: HttpService) {
	}

	public listByRanch(ranchGuid: string): Promise<ICommodityTypesRanchPostResponseViewModel> {

		return this.httpService.get({
			url: this.urls.commodityTypesRanch.list(ranchGuid),
			isWebAPI: true,

			callback: function (json: ICommodityTypesRanchPostResponseViewModel): ICommodityTypesRanchPostResponseViewModel {
				return json;
			}
		});
	}

	public listAllByRanch(ranchGuid: string): Promise<ICommodityType[]> {

		return this.httpService.get({
			url: this.urls.commodityTypesRanch.listAll(ranchGuid),
			isWebAPI: true,

			callback: function (json: ICommodityType[]): ICommodityType[] {
				return json;
			}
		});
	}

	public saveByRanch(ranchGuid: string, commodityTypeId: number, cropTypeIds: number[])
		: Promise<SuccessResponse> {
		let params = {
			CommodityTypeId: commodityTypeId,
			CropTypeIds: cropTypeIds
		};

		return this.httpService.post({
			url: this.urls.commodityTypesRanch.save(ranchGuid),
			body: params,
			isWebAPI: true
		});
	}

	public deleteByRanch(ranchGuid: string, commodityTypeId: number):
		Promise<ICommodityTypesRanchPostResponseViewModel> {

		return this.httpService.delete({
			url: this.urls.commodityTypesRanch.delete(ranchGuid, commodityTypeId),
			isWebAPI: true,
		});
	}

	public list(): Promise<ICommodityType[]> {

		if (this.commodityTypes && this.commodityTypes.length > 0) {
			return of(this.commodityTypes).toPromise();
		}

		return this.httpService.get({
			url: this.urls.commodityType.list,
			useLocalStorage: true,
			searchParams: null,
			callback: (json: ICommodityType[]) => {
				if (json) {
					this.commodityTypes = json;
					return json;
				} else {
					return {};
				}
			},
			isWebAPI: true
		});
	}
}
