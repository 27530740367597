<div class="bk-dialog bk-dialog__event">
	<h4 mat-dialog-title>
		{{'Delete' | translate }} {{'Watering Event' | translate }}?
	</h4>
	
	<div mat-dialog-content>
		{{'Delete Irrigation Event Text' | translate }}
	</div>

	<div mat-dialog-actions
        align="end">

		<button class="bk-button bk-button--medium bk-button--text-only"
			(click)="cancel($event)">
			{{'Cancel' | translate }}
		</button>
		<button class="bk-button bk-button--medium"
			(click)="delete()">
			{{ (isSaving ? 'Deleting' : 'Delete') | translate }}
			<img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
		</button>
    </div>
</div>