import { Injectable } from '@angular/core';

import { CMCoordinates } from '../interfaces/views/map';
import { DateUtility } from '../classes/dateUtility';
import { isNumber } from 'util';

@Injectable()
export class ValidateService {

	/**
	 * Takes a number and makes sure its not null or undefined
	 * This does not evaluate whether something is actually a number or not. It
	 * only evaluates emptiness. This method also fails for strings
	 *
	 * @param x
	 */
	public static numberIsEmpty(x: number): boolean { // REFACTOR - this isn't checking what we expect
		// REFACTOR - this is doing a negative check
		return !x && x !== 0;
	}

	public static isNumberWithXDecimalPlaces(value: string, places: number): boolean {
		let regexString: string;
		let pattern: RegExp;

		if (!value || !places) { return; }

		regexString = '^[0-9]*(\.[0-9]{0,' + places + '})?$'
		pattern = new RegExp(regexString);

		return pattern.test(value);
	}

	public static isNullOrEmpty(value: string): boolean { // REFACTOR - this is a string only check but
		// function name does not clarify that

		return value === '' || value === null;
	}

	/**
     * WARNING: Duplicate of BOKA.Number.isValid()
     * @param value
     */
	public static isValidNumber(value: number): boolean {
		return value !== null && value !== undefined && isNumber(value); // REFACTOR - why check for empty string here?
	}

	public static removeComma(val: string): string {
		if (val === undefined || val === null) {
			return '';
		}

		return val.toString().replace(',', '');
	}

	public static isEmpty(value: number): boolean {
		return value === null || value === undefined;
	}

	public static getCoordinatesFromString(coordinatesString: string): CMCoordinates {
		let coordinates: CMCoordinates;
		let coordinatesArray: string[];

		if (!coordinatesString) {
			return;
		}

		coordinatesArray = coordinatesString.split(',');

		if (coordinatesArray.length !== 2 ||
			!ValidateService.isValidNumber(parseFloat(coordinatesArray[0]))
			|| !ValidateService.isValidNumber(parseFloat(coordinatesArray[1]))) {

			return;
		}

		coordinates = {
			lat: Number(coordinatesArray[0]),
			lng: Number(coordinatesArray[1])
		}

		return coordinates;
	}

	public static areCoordinatesValid(coords: string): boolean {
		let coordinatesArray: string[];

		if (!coords) {
			return false;
		}

		coordinatesArray = coords.split(',');

		return coordinatesArray.length === 2 && ValidateService.
			isValidNumber(parseFloat(coordinatesArray[0])) &&
			ValidateService.isValidNumber(parseFloat(coordinatesArray[1]));
	}

	// alternative to js toFixed method
	public static truncateDecimals(value: number, decimalPlaces: number): number {
		let valueAsStrings: string[];
		let wholeNumber: string;
		let decimal: string;

		if (!value) {
			return null;
		}

		valueAsStrings = value.toString().split('.');

		if (valueAsStrings.length > 2) {
			return null;
		}

		wholeNumber = valueAsStrings[0];

		if (valueAsStrings.length === 1) {
			return Number(wholeNumber);
		}

		decimal = valueAsStrings[1];

		if (decimal.length <= decimalPlaces) {
			return Number(wholeNumber + '.' + decimal);
		}

		decimal = decimal.substring(0, decimalPlaces);

		return Number(wholeNumber + '.' + decimal);
	}

	/**
	 * TODO: This doesn't belong in this file
	 * @param decimalNumber
	 */
	public static roundTo2DecimalPlaces(decimalNumber: number, places?: number): number {
		let mult: number;
		let pre: number;
		let result: number;

		if (!places) {
			places = 2;
		}

		if (!decimalNumber || isNaN(decimalNumber)) {
			return 0.00;
		}

		mult = Math.pow(10, places);

		decimalNumber = parseFloat((decimalNumber * mult).toFixed(11));
		pre = (Math.round(decimalNumber) / mult);

		result = +(pre.toFixed(places));
		return result;
	}

	public static roundToDecimalPlaces(data: number, places: number): number {
		let tenFactor = 10;

		if (!data || !places) {
			return 0;
		}

		while (places > 1) {
			tenFactor = tenFactor * 10;
			places -= 1;
		}

		return Math.round(data * tenFactor + Number.EPSILON) / tenFactor;
	}

	public validateNotEmptyString(data: string): boolean {
		return data !== null && data !== '' && data !== undefined;
	}

	public validateNumber(data: number): boolean { // REFACTOR - duplicate of isValidNumber?
		return data === 0 || (data !== null && !isNaN(data));
	}

	public validateNumberInRange(data: number, min: number, max: number): boolean {
		return this.validateNumber(data) && data >= min && data <= max;
	}

	public validateCoordinatesString(data: string): boolean {
		let coordinates: string[];

		if (!data) {
			return false;
		}

		coordinates = data.split(',');

		return coordinates.length === 2 && this.validateNumber(Number(coordinates[0])) && this.validateNumber(Number(coordinates[1]));
	}

	public isStringWithinMaxCharacterLength(data: string, max: number): boolean {
		return this.validateNotEmptyString(data) && data.length <= max;
	}

	public validateAcceptableSelectOption(data: number): boolean {
		return !isNaN(data) && Number(data) > 0;
	}

	public convertToFloat(n: number, places: number): number {
		let tenFactor = 10;

		if (!n || !places) {
			return 0;
		}

		while (places > 1) {
			tenFactor = tenFactor * 10;
			places -= 1;
		}

		return Math.round(n * tenFactor + Number.EPSILON) / tenFactor;
	}

	public validateDateBeforeNetDate(date: Date, netDate: string): boolean {
		let convertedNetDate: Date;

		convertedNetDate = DateUtility.DotNetToDate(netDate);

		if (convertedNetDate) {
			return date <= convertedNetDate;
		} else {
			return false;
		}
	}

	public validateNumberOrNull(data: number): boolean {
		return this.validateNumber(data) || data == null;
	}
}
