import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IEventPostResponse, IEventPostResponseJSON } from '../../models/event/interfaces';

@Injectable()
export class IrrigationUpdateService {
	private _confirmationClosed: Subject<null> = new Subject<null>();
	public confirmationClosed$ = this._confirmationClosed.asObservable();

	private _deleted: Subject<IEventPostResponseJSON> = new Subject<IEventPostResponseJSON>();
	public deleted$ = this._deleted.asObservable();

	private _recalculateWarningClosed: Subject<MouseEvent> = new Subject<MouseEvent>();
	public recalculateWarningClosed$ = this._recalculateWarningClosed.asObservable();

	public fireConfirmationClosed() {
		this._confirmationClosed.next();
	}

	public fireDeleted(response: IEventPostResponseJSON) {
		this._deleted.next(response);
	}

	public fireRecalculateWarningClosed(e: MouseEvent) {
		this._recalculateWarningClosed.next(e);
	}
}
