import { Injectable } from '@angular/core';
import { ModelService } from '../../services/model.service';
import { ETEventViewModel, ETChartViewModel, ETChart, WeatherEventViewModelJSON } from './interface';
import { DateUtility } from '../../classes/dateUtility';
import { of } from 'rxjs';
import { IPlanting, IPlantingJSON } from '../planting-settings/interfaces';
import { Planting } from '../planting-settings/planting';

@Injectable()
export class ETWeatherEventService extends ModelService {

	private _urls = {
		list: (plantingId: number) => `/v2/plantings/${plantingId}/et-weather-events.json`,
		save: '/v2/et-weather-events.json'
	};

	public list(plantingId: number): Promise<ETChart> {

		if (!plantingId) {
			return of(null).toPromise();
		}

		return this.get(this._urls.list(plantingId),
			(response: ETChartViewModel) => {
				let etEvents: ETEventViewModel[];
				let tableHeaders: string[];
				let result: ETChart;

				tableHeaders = new Array();

				if (!response) {
					return null;
				}

				etEvents = new Array();

				// we need station/Coordinates data to be in a tabular data structure

				if (response.ETEvents && response.ETEvents.length > 0) {
					for (let etEvent of response.ETEvents) {
						etEvents.push({
							Id: etEvent.Id,
							EventDate: DateUtility.DotNetToDate(etEvent.EventDate),
							Source: etEvent.Source,
							ET: etEvent.ET
						})
					}
				}

				if (response.ETFromStations && response.ETFromStations.length > 0) {
					for (let station of response.ETFromStations) {

						tableHeaders.push(station.StationName);

						for (let etEvent of etEvents) {
							let event: WeatherEventViewModelJSON;

							if (!etEvent.Stations) {
								etEvent.Stations = new Array();
							}

							if (!station.WeatherEvents || station.WeatherEvents.length === 0) {
								etEvent.Stations.push(null);
								continue;
							}

							event = station.WeatherEvents.filter(x =>
								DateUtility.DotNetToDate(x.EventDate).getTime() === etEvent.EventDate.getTime())[0];

							if (event) {
								etEvent.Stations.push(event.ET)
							} else {
								etEvent.Stations.push(null);
							}
						}
					}
				}

				if (response.ETFromSpatial && response.ETFromSpatial.WeatherEvents !== null &&
					response.ETFromSpatial.WeatherEvents.length > 0) {

					tableHeaders.push('Spatial');

					if (response.ETFromSpatial.WeatherEvents && response.ETFromSpatial.WeatherEvents.length > 0) {
						for (let etEvent of etEvents) {
							let event = response.ETFromSpatial.WeatherEvents.filter(x => DateUtility.DotNetToDate(x.EventDate).getTime()
								=== etEvent.EventDate.getTime())[0];

							if (event) {
								etEvent.SpatialET = event.ET;
							} else {
								etEvent.SpatialET = null;
							}
						}
					}
				}

				result = {
					ETEvents: etEvents,
					Headers: tableHeaders
				};

				return result;
			});
	}

	public save(event: ETEventViewModel): Promise<IPlanting> {
		let params = {
			id: event.Id,
			ET: event.ET
		};

		return this.post(this._urls.save,
			(res: IPlantingJSON): IPlanting => {
				let result: IPlanting;

				result = Planting.convertPlantingJSON(res);
				return result;
		}, params);
	}
}
