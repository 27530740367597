import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { HttpService, HttpServicePostOptions, HttpServiceGetOptions } from '../../services/http.service';
import { UpdateService } from '../../services/update.service';

import { IWeatherStation, IWeatherStationStats, IWeatherStationAdvanced,
	IWeatherStationStatsDetails,
	IWeatherStationViewModel, IMissingStationRecord, MissingStationRecord} from '../../components/weather-stations/interfaces';

import { IWeatherStationRegion } from '../region/interfaces';

import { HttpParams } from '@angular/common/http';
import { CMError, SuccessResponse } from '../../interfaces/interfaces';
import { IWeatherStationCurrentModel, IMarkerCollection, IMarker, MarkerCollection } from '../../components/ranch-settings/interfaces';
import { IWeatherStationsService, IStationPriority } from './interfaces';
import { DateUtility } from '../../classes/dateUtility';

@Injectable()
export class WeatherStationsService implements IWeatherStationsService {

	private urls = {
		stats: {
			list: '/v2/weather-stations/stats.json',
			get: (stationId: number) => `/v2/weather-stations/${stationId}/stats.json`,
			missingRecords: (stationId: number) => `/v2/weather-stations/${stationId}/missingRecords.json`
		},
		cimisData: {
			update: (stationId: number) => `/v2/weather-stations/${stationId}/cimis.json`
		},
		regions: {
			list: '/v2/regions.json'
		},
		list: '/v2/weather-stations.json',
		update: '/v2/weather-stations.json',
		create: '/v2/weather-stations.json',
		delete: (stationId: number) => `/v2/weather-stations/${stationId}.json`,
		changeActiveStatus: (stationId: number) => `/v2/weather-stations/${stationId}/status/toggle.json`,

		ranch: { // ranch setting weather station CRUD calls
			list: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/weather-stations.json`, // get stations associated with ranch
			get: (id: number) => `/v2/weather-stations/${id}.json`,

			listAsMarkers: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/weather-stations/markers.json`,
				// returns stations assoicated with ranch as a marker collection

			addToRanch: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/weather-stations.json`,
			removeFromRanch: (ranchGuid: string, stationId: number) => `/v2/ranches/${ranchGuid}/weather-stations/${stationId}.json`,
			setStationPriorities: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/weather-stations/priorities.json`,
		},
	}

	constructor(private httpService: HttpService,
		private updateService: UpdateService) { }

	/**
	 * Add one or more stations to a ranch
	 * @param stations
	 */
	public addStationsToRanch(stations: IMarker[]): Promise<Object> {
		let ids: number[] = new Array();

		for (let station of stations) {
			ids.push(station.Id);
		}

		return this.httpService
			.post({
				url: this.urls.ranch.addToRanch(this.updateService.currentRanchId),
				body: { Ids: ids },
				isWebAPI: true
			});
	}

	/**
	 * Set weather stations priority order during CIMIS data pulls
	 * @param stations
	 */
	public setPriorityOrder(stations: IWeatherStationCurrentModel[]): Promise<void> {
		let stationPriorities: IStationPriority[] = new Array();
		let params: HttpServicePostOptions;
		let body: {
			Stations: IStationPriority[]
		};

		if (!stations || stations.length === 0) {
			return;
		}

		stations.forEach((item, index) => {
			stationPriorities.push({
				StationExtId: item.External_Id,
				Priority: index + 1
			})
		})

		body = {
			Stations: stationPriorities
		}

		params = {
			url: this.urls.ranch.setStationPriorities(this.updateService.currentRanchId),
			body: body,
			isWebAPI: true
		};

		return this.httpService.put(params);
	}

	public removeFromRanch(id: number): Promise<Object> {
		return this.httpService
			.delete({
				url: this.urls.ranch.removeFromRanch(this.updateService.currentRanchId, id),
				isWebAPI: true
			});
	}

	public listStats(startDate?: Date, endDate?: Date): Promise<IWeatherStationStats[]> {
		let params: HttpParams;

		params = new HttpParams();

		if (startDate && endDate) {
			params = params.set('startDate', startDate.toUTCString())
				.set('endDate', endDate.toUTCString());
		}

		let options: HttpServiceGetOptions = {
			url: this.urls.stats.list,
			searchParams: params,
			isWebAPI: true
		}

		return this.httpService.get(options);
	}

	public list(): Promise<IWeatherStation[]> {
		let options: HttpServiceGetOptions = {
			url: this.urls.list,
			isWebAPI: true,
			callback: (weatherStations: IWeatherStation[]) => {
				this.updateService.setWeatherStationsList(weatherStations);
				return weatherStations;
			}
		}

		return this.httpService.get(options);
	}

	/**
	 * Get weather stations associated with the current ranch
	 */
	public getRanchWeatherStations(): Promise<IWeatherStationCurrentModel[]> {

		return this.httpService.get({
			url: this.urls.ranch.list(this.updateService.currentRanchId),
			isWebAPI: true,

			callback: function (json: IWeatherStationCurrentModel[]) {
				if (json) {
					return json;
				} else {
					return new Array();
				}
			}
		});
	}

	public getDetails(id: number): Promise<MarkerCollection> {
		let params: HttpParams;

		params = new HttpParams().set('ranchGuid', this.updateService.currentRanchId);

		return this.httpService.get({
			url: this.urls.ranch.get(id),
			isWebAPI: true,
			searchParams: params,

			callback: function (json: MarkerCollection): MarkerCollection {
				return json;
			}
		});
	}

	public getMissingRecords(stationId: number): Promise<IMissingStationRecord[]> {
		return this.httpService.get({
			url: this.urls.stats.missingRecords(stationId),
			isWebAPI: true,
			callback: (json: MissingStationRecord[]): IMissingStationRecord[] => {
				let result: IMissingStationRecord[];

				result = [];

				for (let record of json) {
					result.push({
						Date: DateUtility.DotNetToDate(record.Date),
						Status: record.Status
					})
				}
				return result;
			}
		})
	}

	/**
	 * Get a list of stations associated with a ranch as Marker collection, including distance to the current ranch
	 */
	public listRanchStations(): Promise<IMarkerCollection> {

		return this.httpService.get({
			url: this.urls.ranch.listAsMarkers(this.updateService.currentRanchId),
			isWebAPI: true,

			callback: function (json: IMarkerCollection) {
				if (json) {
					return json;
				} else {
					return {};
				}
			}
		});
	}

	/**
	 * Get all weather stations
	 */
	public listAll(suppressZeroET?: boolean): Promise<IWeatherStationViewModel[]> {
		let params: HttpParams;

		params = new HttpParams().set('ranchGuid', this.updateService.currentRanchId)
			.set('activeOnly', 'true');

		if (suppressZeroET) {
			params = params.set('suppressZeroET', 'true');
		}

		return this.httpService.get({
			url: this.urls.list,
			isWebAPI: true,
			searchParams: params,

			callback: function (json: IWeatherStationViewModel[]) {
				if (json) {
					return json;
				} else {
					return {};
				}
			}
		});
	}

	public getWeatherStationAdvancedList(startDate?: Date, endDate?: Date): Promise<IWeatherStationAdvanced[]> {
		let promises: {
			stations: Promise<IWeatherStation[]>,
			stats: Promise<IWeatherStationStats[]>
		};

		promises = {
			stations: null,
			stats: null
		};

		promises.stations = this.list();

		if (startDate && endDate) {
			promises.stats = this.listStats(startDate, endDate);
		} else {
			promises.stats = this.listStats();
		}

		return Promise.all([promises.stations, promises.stats])
			.then(([stations, stats]) => {
				let weatherStationAdvancedList: IWeatherStationAdvanced[] = new Array();
				let weatherStationAdvanced: IWeatherStationAdvanced;

				if (!stations || !stats) {
					return;
				}

				for (let weatherStation of stations) {
					weatherStationAdvanced = {
						Id: weatherStation.Id,
						Name: weatherStation.Name,
						ExternalId: weatherStation.External_Id,
						API_Id: weatherStation.API_Id,
						NearestCity: weatherStation.NearestCity,
						County: weatherStation.County,
						RegionId: weatherStation.RegionId,
						RegionName: weatherStation.RegionName,
						Coordinates: weatherStation.Coordinates,
						UcIpmName: weatherStation.UcIpmName,
						WeatherAPI_Id: weatherStation.WeatherAPI_Id,
						RanchCount: 0,
						RecommendationCount: 0,
						Active: weatherStation.Active,
						AllowToDeactivate: weatherStation.AllowToDeactivate,
						MissingRecords: weatherStation.MissingRecords
					}

					for (let weatherStationStats of stats) {
						if (weatherStation.Id === weatherStationStats.StationId) {
							weatherStationAdvanced.RanchCount = weatherStationStats.RanchCount;
							weatherStationAdvanced.RecommendationCount = weatherStationStats.RecommendationCount;
							break;
						}
					}

					weatherStationAdvancedList.push(weatherStationAdvanced);
				}

				return weatherStationAdvancedList;
			});
	}

	/**
	 * Start a background process to download today's CIMIS daya for a particular station.
	 * @param id
	 */
	public updateCIMISData(id: number): Promise<SuccessResponse> {
		let options: HttpServicePostOptions;

		options = {
			url: this.urls.cimisData.update(id),
			isWebAPI: true,
			body: null,
			shouldBypassServerErrorAlert: true
		}

		return this.httpService.put(options);
	}

	public listRegions(): Promise<IWeatherStationRegion[]> {
		let options: HttpServiceGetOptions = {
			url: this.urls.regions.list,
			isWebAPI: true
		}

		return this.httpService.get(options);
	}

	public update(weatherStation: IWeatherStation): Promise<CMError | SuccessResponse> {
		let options: HttpServicePostOptions;

		if (!weatherStation) {
			return of(null).toPromise();
		}

		options = {
			body: weatherStation,
			url: this.urls.update,
			isWebAPI: true
		}

		return this.httpService.put(options);
	}

	public create(weatherStation: IWeatherStation): Promise<IWeatherStation> {
		let options: HttpServicePostOptions;

		if (!weatherStation) {
			return of(null).toPromise();
		}

		options = {
			body: weatherStation,
			url: this.urls.create,
			isWebAPI: true
		}

		return this.httpService.post(options);
	}

	public delete(id: number): Promise<CMError> {
		let options: HttpServicePostOptions;

		options = {
			url: this.urls.delete(id),
			body: null,
			isWebAPI: true
		}

		return this.httpService.delete(options);
	}

	public getStats(id: number, startDate?: Date, endDate?: Date): Promise<IWeatherStationStatsDetails> {
		let params: HttpParams;
		let options: HttpServiceGetOptions;

		params = new HttpParams().set('stationId', id.toString());

		if (startDate && endDate) {
			params = params.set('startDate', startDate.toUTCString())
				.set('endDate', endDate.toUTCString());
		}

		options = {
			url: this.urls.stats.get(id),
			searchParams: params,
			isWebAPI: true
		}

		return this.httpService.get(options);
	}

	public changeActiveStatus(id: number): Promise<SuccessResponse> {

		let options: HttpServiceGetOptions = {
			url: this.urls.changeActiveStatus(id),
			isWebAPI: true
		}

		return this.httpService.get(options);
	}

	public combineStationArrays(addData: IMarkerCollection, allStations: IWeatherStationViewModel[]): IMarkerCollection {
		let response: IMarkerCollection;

		response = {
			RanchLocation: null,
			Stations: new Array()
		};

		response.RanchLocation = addData.RanchLocation;
		let stationsFromAddData: IMarker[] = addData.Stations;

		for (let station of stationsFromAddData) {
			for (let aStation of allStations) {
				if (station.Id === aStation.Id) {
					station.Distance = aStation.Distance;
					station.NearestCity = aStation.NearestCity;
					station.County = aStation.County;
					station.Selected = false;
					response.Stations.push(station);
					break;
				}
			}
		}

		return response;
	}
}
