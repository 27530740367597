<div class="bk-dialog bk-modal__large">
        
    <button type="button"
        class="bk-button bk-dialog__close"
        (click)="close()">

        &times;
    </button>
    <h4 mat-dialog-title>
        {{'Add' | translate }} {{'Weather Stations' | translate }}
    </h4>

    <div mat-dialog-content>
        <div class="weather-station-container">
            
            <div class="left-content-container bk-modal__left bk-weather-stations-left">
                <div *ngIf="showWeatherStationsList">
                    <div class="bk-warning-text--weather_stations"
                        *ngIf="zoomOutWarning">
                        {{'Zoom Out Warning Text' | translate }}.
                    </div>
                    <label class="bk-weather-station"
                        *ngFor="let station of weatherStations">
                        <input [checked]="station.Selected || station.isCurrent"
                            class="bk-modal__checkbox--left"
                            type="checkbox"
                            (click)="selectStation(station)"
                            [disabled]="station.isCurrent"
                            [ngClass]="{'bk-modal__checkbox--disabled' : station.isCurrent}"/>

                        <span class="bk-modal__label-text">
                            <span class="bk-bold bk-weather-station__name">
                                {{station.Name}} (#{{station.External_Id}})
                            </span>

                            <span>
                                {{station.Distance}} {{'miles' | translate }} - {{station.NearestCity}}, {{station.County}}
                            </span>
                        </span>
                    </label>
                </div>
            </div>
            <div class="bk-modal__right bk-weather-stations-right">
                <div class="bk-modal__right-instructions">
                    {{'Weather Station Instruction Text' | translate }}
                </div>
                <div class="bk-modal__right-instructions">
                    <div class="bk-marker bk-marker--ranch">
                        <svg-ranch-location></svg-ranch-location>
                    </div>
                    {{'Ranch Location' | translate }} 
                    <div class="bk-marker bk-marker--unselected">
                        <svg-station-location></svg-station-location>
                    </div>
                    {{'Unselected Station' | translate }} 
                    <div class="bk-marker bk-marker--selected">
                        <svg-station-location></svg-station-location>
                    </div>
                    {{'Selected Station' | translate }}
                </div>
                <div *ngIf="!ranchLocation"
                    class="bk-modal__warning-section">

                    <mat-icon>warning</mat-icon>Ranch coordinates are missing. Edit the ranch to
                    update ranch coordinates.
                </div>
                <div class="map-container bk-modal__map-container">
                    <agm-map (boundsChange)="resetMapOnboundsChange($event)"
                        [mapTypeId]="'hybrid'"
                        [fullscreenControl]="true">

                        <agm-marker *ngFor="let station of weatherStations"
                            [iconUrl]="getStationMarker(station)"
                            [latitude]="station.Latitude"
                            [longitude]="station.Longitude"
                            [label]="getStationId(station.External_Id)"
                            (markerClick)="selectStation(station)">
                        </agm-marker>

                        <ng-container *ngIf="ranchLocation">
                            <agm-marker
                                [iconUrl]="'../assets/images/icons/map_marker-ranch.png'"
                                [latitude]="ranchLocation.Latitude"
                                [longitude]="ranchLocation.Longitude">
                            </agm-marker>
                        </ng-container>
                        <map-control></map-control>
                    </agm-map>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions align="end">
        <button class="bk-button bk-button--medium bk-button--text-only"
            (click)="close()">
            {{'Cancel' | translate }}
        </button>
        <button class="bk-button bk-button--medium"
            (click)="addSelectedStations(weatherStations)">
            {{'Add' | translate }} {{'Selected Station(s)' | translate }}
        </button>
    </div>
</div>