import { Injectable } from '@angular/core';

import { ModelService } from '../../services/model.service';

import { ISoilSampleEventForm, IFormSoilSample, ISoilSampleEventService, ISoilSampleSimple, SoilSampleJSONModel } from './interfaces';
import { IEventPostResponseJSON } from '../../models/event/interfaces';

import { SoilSampleEvent } from './soilSampleEvent';
import { SoilSampleEventDropdownModel } from '../fertilization-event/interfaces';
import { of } from 'rxjs';
import { DateUtility } from '../../classes/dateUtility';
import { CMError, httpStatusCodes } from '../../interfaces/interfaces';
import { EventResponse } from '../../models/event/eventResponse';

@Injectable()
export class SoilSampleEventService extends ModelService implements ISoilSampleEventService {

	private urls = {
		createForm: (plantingId: number) => `/v2/plantings/${plantingId}/soil-sample-events/form.json`,
		create: (plantingId: number) => `/v2/plantings/${plantingId}/soil-sample-events.json`,
		show: (id: number) => `/v2/soil-sample-events/${id}.json`,
		update: (id: number) => `/v2/soil-sample-events/${id}.json`,
		delete: (id: number) => `/v2/soil-sample-events/${id}.json`,
		getForPlanting: (plantingId: number) => `/v2/plantings/${plantingId}/soil-sample-events.json`,
	};

	public getCreateModel(plantingId: number): Promise<SoilSampleEvent> {

		if (!plantingId) {
			throw new Error(`SoilSampleEventService: getAddSoilSampleEventData()
				- plantingId is empty`);
		}

		return this.get(this.urls.createForm(plantingId), (data: SoilSampleJSONModel): SoilSampleEvent => {
			let soilSampleEvent: SoilSampleEvent = new SoilSampleEvent();

			if (!data) {
				return;
			}

			soilSampleEvent.convertSoilSampleJSONModel(data);

			return soilSampleEvent;
		});
	}

	public getEditModel(id: number): Promise<SoilSampleEvent> {

		return this.get(this.urls.show(id), (data: SoilSampleJSONModel): SoilSampleEvent => {
			let soilSampleEvent: SoilSampleEvent;

			soilSampleEvent = new SoilSampleEvent();

			if (!data) {
				return;
			}

			soilSampleEvent.convertSoilSampleJSONModel(data);

			return soilSampleEvent;
		});
	}

	/**
	 * Alternative to create() using form data
	 * @param plantingId
	 * @param form
	 */
	public createForFertilizationEvent(plantingId: number, form: ISoilSampleSimple): Promise<IEventPostResponseJSON> {
		let params: ISoilSampleEventForm;

		params = SoilSampleEvent.extractFromReactiveForm(form);
		params.EventDate = DateUtility.ConvertToUTC(params.EventDate as Date);

		return this.post(this.urls.create(plantingId), (data: IEventPostResponseJSON): IEventPostResponseJSON => {
			return data;
		}, params);
	}

	public create(plantingId: number, event: SoilSampleEvent): Promise<IEventPostResponseJSON> {
		let params: ISoilSampleEventForm;

		params =  event.getForm();

		params.EventDate = DateUtility.ConvertToUTC(params.EventDate as Date);

		return this.post(this.urls.create(plantingId), (data: IEventPostResponseJSON): IEventPostResponseJSON => {
			return data;
		}, params);
	}

	public update(id: number, sample: SoilSampleEvent): Promise<EventResponse> {
		let params: ISoilSampleEventForm = sample.getForm();

		params.EventDate = DateUtility.ConvertToUTC(params.EventDate as Date);

		return this.put(this.urls.update(id), (data: IEventPostResponseJSON | CMError): EventResponse => {
			let jsonResponse: CMError;
			let result: EventResponse;

			if (!data) {
				return null;
			}

			jsonResponse = data as CMError;
			result = new EventResponse(data as IEventPostResponseJSON);

			if (jsonResponse.code && jsonResponse.code !== httpStatusCodes.success) {
				return null;
			}

			return result;
		}, params);
	}

	public delete(id: number): Promise<IEventPostResponseJSON> {

		return this.deleteItem(this.urls.delete(id), (data: IEventPostResponseJSON): IEventPostResponseJSON => {
			return data;
		}, null);
	}

	/**
	 * REFACTOR: Belongs in soil sample service
	 * @param plantingId
	 */
	public getListForPlanting(plantingId: number): Promise<IFormSoilSample[]> { // this should not be a string

		if (!plantingId) {
			return of(null).toPromise();
		}

		return this.get(this.urls.getForPlanting(plantingId), (soilSamples: SoilSampleEventDropdownModel[]): IFormSoilSample[] => {
			if (!soilSamples || soilSamples.length === 0) {
				return new Array();
			}

			let result: IFormSoilSample[];

			result = new Array();

			for (let soilSample of soilSamples) {

				result.push({
					Id: soilSample.Id,
					Name: soilSample.Name,
					Date: DateUtility.DotNetToDate(soilSample.Date)
				});
			}

			return result;
		});
	}
}
