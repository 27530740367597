import { Component } from '@angular/core';

@Component({
	moduleId: module.id,
	selector: 'svg-station-location',
	template: `
		<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
			xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
			<defs></defs>
			<g id="Exports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<g id="icon-map_marker-filled" fill-rule="nonzero" fill="#000000">
					<g id="Icon-Map_Marker-FILLED" transform="translate(3.000000, 0.000000)">
						<path d="M9.43448276,0.00595862069 C4.05583448,0.00595862069 0,
						4.27431724 0,9.9369931 C0,17.0605241 8.77009655,
						23.4769655 9.14350345,23.7460966 C9.22990345,
						23.8096552 9.33318621,23.8404414 9.43448276,23.8404414 C9.53577931,
						23.8404414 9.63806897,23.8096552 9.72546207,23.7460966 C10.0978759,
						23.4769655 18.8689655,17.0605241 18.8689655,9.9369931 C18.8689655,
						4.27531034 14.813131,0.00595862069 9.43448276,0.00595862069 Z M9.43448276,
						14.9025103 C6.4224,14.9025103 3.97241379,12.451531 3.97241379,
						9.44044138 C3.97241379,6.42835862 6.4224,3.97837241 9.43448276,
						3.97837241 C12.4455724,3.97837241 14.8965517,6.42835862 14.8965517,
						9.44044138 C14.8965517,12.4525241 12.4455724,14.9025103 9.43448276,
						14.9025103 Z" id="Shape"></path>
					</g>
				</g>
			</g>
		</svg>
    `,
})

export class SVGStationLocationComponent { }
