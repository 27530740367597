import { AbstractControl, Validator, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { eCalculators } from '../interfaces/constants';
import { DateUtility } from './dateUtility';
import { BOKA } from './number';

export class FormValidation {
	public static formArrayMustNotBeEmpty(): ValidatorFn {
		return (formArray: FormArray): {[key: string]: any} | null => {
			let valid: boolean;
			let count: number;

			count = (formArray && formArray.controls) ? formArray.controls.length : 0;
			valid = count > 0 ? true : false;

			return valid ? null : {formArrayMustNotBeEmpty: {value: count}};
		};
	}

	public static removeErrorFromContrl(control: AbstractControl, fieldname: string): void {
		if (control.errors && control.errors[fieldname]) {
			delete control.errors[fieldname];

			if (Object.keys(control.errors).length === 0) {
				control.setErrors(null);
			}
		}
	}

	public static checkGeolocationCoordinates(lat: number, lng: number): boolean {
		return (lat >= -90 && lat <= 90) && (lng >= -180 && lng <= 180);
	}

	public static requiredIfTree(isTreeControl: AbstractControl): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let errorString = 'requiredIfTree';

			if (isTreeControl.value) {
				valid = (control.value !== null && control.value !== undefined)
					? true : false;
			} else {
				FormValidation.removeErrorFromContrl(control, errorString);
				valid = true;
			}

			return valid ? null : {requiredIfTree: {value: control.value}};
		};
	}

	public static requiredIfStressable(formGroup: FormGroup): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let isTree: boolean;
			let commodityTypeId: number;
			let errorString = 'requiredIfStressable';

			isTree = formGroup.get('IsTree').value;
			commodityTypeId = formGroup.get('CalculatorId').value;

			if (isTree || commodityTypeId === eCalculators.TOMATO) {
				valid = (control.value !== null && control.value !== undefined)
					? true : false;
			} else {
				FormValidation.removeErrorFromContrl(control, errorString);
				valid = true;
			}

			return valid ? null : {requiredIfStressable: {value: control.value}};
		};
	}

	public static requiredIfNDependent(nDependentControl: AbstractControl): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let errorString = 'requiredIfStressable';

			if (nDependentControl.value) {
				valid = (control.value !== null && control.value !== undefined)
					? true : false;
			} else {
				FormValidation.removeErrorFromContrl(control, errorString);
				valid = true;
			}

			return valid ? null : {requiredIfStressable: {value: control.value}};
		};
	}

	/**
	 * A field is required if N dependent (e.g. not alfalfa), and not tree
	 * (e.g. vegetables)
	 * @param nDependentControl
	 * @param isTreeControl
	 * @returns
	 */
	public static requiredIfNDependentAndNotTree(nDependentControl: AbstractControl,
		isTreeControl: AbstractControl): ValidatorFn {

		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let errorString = 'requiredIFNDependentNotTree';

			if (nDependentControl.value && !isTreeControl.value) {
				valid = (control.value !== null && control.value !== undefined)
					? true : false;
			} else {
				FormValidation.removeErrorFromContrl(control, errorString);
				valid = true;
			}

			return valid ? null : {requiredIFNDependentNotTree: {value: control.value}};
		};
	}

	/**
	 * Required if tomato, strawberry, or caneberry which require additional
	 * N factor fields
	 * @param nFactorsControl
	 * @returns
	 */
	public static requiredIfNFactors(nFactorsControl: AbstractControl): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let errorString = 'requiredIfNFactors';

			if (nFactorsControl.value === false) {
				valid = (control.value !== null && control.value !== undefined)
					? true : false;
			} else {
				FormValidation.removeErrorFromContrl(control, errorString);
				valid = true;
			}

			return valid ? null : {requiredIfNFactors: {value: control.value}};
		};
	}

	public static CoordinateStringIsValid(): ValidatorFn {

		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let coordinates: number[];

			if (!control || !control.value) {
				valid = false;
			} else {
				coordinates = (control.value as string).split(',').map(x => Number(x));

				valid = coordinates.length === 2 && coordinates[0] &&
					coordinates[1] && !isNaN(Number(coordinates[0]))
					&& !isNaN(Number(coordinates[1]));

				if (!valid) {
					return {'coordinatesInvalid': {value: control.value}};
				}

				valid = (BOKA.NumberUtil.truncateDecimal(coordinates[0]) === coordinates[0]) &&
					(BOKA.NumberUtil.truncateDecimal(coordinates[1]) === coordinates[1]);

				if (!valid) {
					return {'coordinatesTooLong': {value: control.value}};
				}

				valid = FormValidation.checkGeolocationCoordinates(coordinates[0], coordinates[1]);

				if (!valid) {
					return {'coordinatesInvalid': {value: control.value}};
				}
			}

			return valid ? null : {'coordinatesInvalid': {value: control.value}};
		};
	}

	public static integerValidator(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			valid = control.value % 1 === 0 ? true : false;

			return valid ? null : {'valueNotAnInteger': {value: control.value}};
		};
	}
	/**
	 *
	 * @param maxDate
	 * @param validOn if true, date is valid if its on the target date or after.
	 * @returns
	 */
	public static maxDateValidator(maxDate: Date, validOn?: boolean): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			if (maxDate) {
				if (validOn) {
					valid = control.value ? moment(maxDate).isSameOrAfter(control.value) : true;
				} else {
					valid = control.value ? moment(maxDate).isAfter(control.value) : true;
				}

			} else {
				valid = true;
			}

			return valid ? null : {'endDateOnOrAfterTargetDate': {value: control.value}};
		};
	}

	public static maxDateValidatorWithControl(maxControl: AbstractControl, invalidOn?: boolean): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			if (maxControl.value) {
				if (invalidOn) {
					valid = control.value ? moment(maxControl.value).isSameOrAfter(control.value) : true;
				} else {
					valid = control.value ? moment(maxControl.value).isAfter(control.value) : true;
				}

			} else {
				valid = true;
			}

			return valid ? null : {'endDateOnOrAfterTargetDate': {value: control.value}};
		};
	}

	/**
	 * Similar to minVDateValidator, except it uses an AbstractControl as input
	 * @param minControl control that can be monitored
	 * @param invalidOn if true, date cannot be on the minimum date
	 * @returns
	 */
	public static minDateValidatorWithControl(minControl: AbstractControl, invalidOn?: boolean): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			if (minControl.value) {
				if (invalidOn) {
					valid = control.value ? moment(minControl.value).isSameOrBefore(control.value) : true;
				} else {
					valid = control.value ? moment(minControl.value).isBefore(control.value) : true;
				}
			} else {
				valid = true;
			}

			return valid ? null : {'dateBeforeMinDate': {value: control.value}};
		}
	}

	public static mustEqualValidator(target: number): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			valid = control.value === target ? true : false;

			return valid ? null : {'mustEqualNumber': {value: control.value}};
		}
	}

	public static minDateValidator(minDate: Date, validOn?: boolean): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			if (minDate) {
				if (validOn) {
					valid = control.value ? moment(minDate).isSameOrBefore(control.value) : true;
				} else {
					valid = control.value ? moment(minDate).isBefore(control.value) : true;
				}
			} else {
				valid = true;
			}

			return valid ? null : {'dateBeforeMinDate': {value: control.value}};
		}
	}

	/**
	 * Same as the greaterThanValidator, except the input is an AbstractControl
	 * instead of a static value
	 * @param target
	 * @returns
	 */
	public static greaterThanValidatorControl(target: AbstractControl): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			if (control.value === null) {
				valid = true; // we don't evaluate if the value isn't filled, since
				// the field may not be required.
			} else if (control.value > target.value) {
				valid = true;
			} else {
				valid = false;
			}

			return valid ? null : {'numberGreaterThan': {value: control.value,
				target: target.value}};
		}
	}

	public static greaterThanValidator(target: number): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			if (control.value === null) {
				valid = true; // we don't evaluate if the value isn't filled, since
				// the field may not be required.
			} else if (control.value > target) {
				valid = true;
			} else {
				valid = false;
			}

			return valid ? null : {'numberGreaterThan': {value: control.value,
				target: target}};
		}
	}

	public static isDate(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			valid = DateUtility.IsDate(control.value) ? true : false;

			return valid ? null : {'invalidDate': {value: control.value}};
		}
	}

	public static lessThanValidatorControl(target: AbstractControl): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			valid = !control.value || control.value < target.value ? true : false;

			return valid ? null : {'numberLessThan': {value: control.value}};
		}
	}

	public static mustBeBeforeDate(dateControl: AbstractControl): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			if (!dateControl.value) {
				valid = true;
			} else {
				valid = moment(control.value).isBefore(dateControl.value);
			}

			return valid ? null : {'notBeforeDate': {value: control.value}};
		};
	}

	public static mustBeOnOrBeforeDate(dateControl: AbstractControl): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			if (!dateControl.value) {
				valid = true;
			} else {
				valid = moment(control.value).isSameOrBefore(dateControl.value);
			}

			return valid ? null : {'mustBeOnOrBeforeDate': {value: control.value}};
		};
	}

	public static mustBeAfterDate(dateControl: AbstractControl): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			if (!dateControl.value) {
				valid = true;
			} else {
				valid = moment(dateControl.value).isBefore(control.value);
			}

			return valid ? null : {'mustBeAfterWetDate': {value: control.value}};
		};
	}

	public static mustBeOnOrAfterDate(dateControl: AbstractControl): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			if (!dateControl.value) {
				valid = true;
			} else {
				valid = moment(dateControl.value).isSameOrBefore(control.value);
			}

			return valid ? null : {'mustBeOnOrAfterDate': {value: control.value}};
		};
	}

	public static mustBeOnSameYearAsDate(dateControl: AbstractControl): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			if (!dateControl.value) {
				valid = true;
			} else {
				valid = DateUtility.areSameYear(dateControl.value,
					control.value);
			}

			return valid ? null : {'wetDateNotSameYearAsHarvestDate': {value: control.value}};
		};
	}

	public static mustBeWithinDateRange(dateControl: AbstractControl, range: number): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			if (!dateControl.value || !control.value) {
				valid = true;
			} else {
				let difference: number;

				difference = DateUtility.getDifferenceInDays(dateControl.value, control.value);

				valid = difference <= range;
			}

			return valid ? null : {'dateRangeExceeded': {value: control.value}};
		};
	}

	public static lessThanValidator(target: number): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			valid = control.value < target ? true : false;

			return valid ? null : {'numberLessThan': {value: control.value}};
		}
	}

	public static setValidator(control: AbstractControl, validators: ValidatorFn[]) {
		control.setValidators(validators);
		control.updateValueAndValidity();
	}

	public static clearValidator(control: AbstractControl) {
		control.clearValidators();
		control.updateValueAndValidity();
	}

	/**
	 * method used for getting feedback dynamically about which fields are problematic
	 * @param formGroup
	 * @returns
	 */
	public static getFormIssueString(formGroup: FormGroup): string {
		for (let field in formGroup.controls) {

			if (formGroup.get(field) === null) {
				continue;
			}

			let control = formGroup.get(field);
			let group = (control as FormGroup);
			let subResult: string;

			if (group.controls && group.invalid) {
				subResult = this.getFormIssueString((control as FormGroup));

				if (subResult) {
					return subResult;
				}
			}

			if (control.invalid) {
				return field;
			}
		}
	}
}
