import { eNotificationTypes } from './constants';
import { IPlanting } from '../components/planting-settings/interfaces';
import { IRanch, IRanchLotJSONModel } from '../components/ranch-settings/interfaces';
import { ILotEdit } from '../components/ranch-settings/modals/lot/interfaces'

export interface IRanchesArray {
	myRanches: IRanch[],
	favorites: IRanch[],
	public: IRanch[],
	loaded: {
		myRanches: boolean,
		favorites: boolean,
		public: boolean
	}
}

export interface PlantingsArray {
	active: IPlanting[],
	favorites: IPlanting[],
	all: IPlanting[]
}

export interface ITextValue {
	Text: string,
	Value: string
}

/**
* Used for updating planting cards when lot name is updated in ranch settings
**/
export interface ICurrentRanchLots {
	lots: IRanchLotJSONModel[],
	lotId: number,
	lot: ILotEdit
}

/**
 * generic .NET response object
 */
export interface  CMError {
	message: string,
	code: number
}

export interface CMErrors {
	errors: CMError[]
}

export interface SuccessResponse {
	Message: string
}

export interface INotificationParams {
	message: string,
	type: eNotificationTypes
}

export enum httpStatusCodes {
	success = 200,
	continue = 100,
	angularError = 101,
	badRequest = 400,
	notfound = 404,
	forbidden = 403,
	interrupted = 103,
	UpgradeRequired = 426
}

export interface IRange {
	min: number,
	max: number
}
