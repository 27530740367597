<div class="bk-admin__page">
	<button mat-button color="primary"
		class="cm-navigation-button"
		(click)="goBack()">
		<mat-icon>arrow_backward</mat-icon>
		{{'Go Back' | translate }}
	</button>

	<H2>ET Calculation Results</H2>

	<div class="cm-summary-table--wrapper">
		<table class="cm-summary-table">
			<tbody>
				<tr>
					<td>
						<label>Start Date</label>
						{{ startDate | date }}
					</td>
					<td>
						<label>End Date</label>
						{{ endDate | date }}
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<label>Crop Type</label>
						{{ cropTypeName }}
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<label>Weather Station</label>
						{{ weatherStationName }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>

	<div class="bk-chart" #chartContainer></div>

	<table>
		<tbody>
			<tr>
				<td>
					Total Reference ET (in.)
				</td>
				<td>
					{{ totals.ETo | number:'1.2-2' }}
				</td>
			</tr>
			<tr>
				<td>
					Total Rain (in.)
				</td>
				<td>
					{{ totals.Rain | number:'1.2-2' }}
				</td>
			</tr>
			<tr>
				<td>
					Total Crop ET (in.)
				</td>
				<td>
					<strong>{{ totals.ETc | number:'1.2-2' }}</strong>
				</td>
			</tr>
		</tbody>
	</table>
</div>