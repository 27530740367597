import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { GlobalSettingsService } from './global-settings.service';
import { GlobalSettings } from './interfaces';
import { NotificationService } from '../../services/notification.service';
import { eNotificationTypes } from '../../interfaces/constants';

@Component({
	moduleId: module.id,
	selector: 'global-settings',
	templateUrl: 'global-settings.component.html',
	styleUrls: ['global-settings.scss']
})

export class GlobalSettingsComponent implements OnInit, OnDestroy {
	private _subscriptions$: Subject<boolean>;
	public form: FormGroup;

	constructor(
		private _router: Router,
		private _fb: FormBuilder,
		private _notificationService: NotificationService,
		private _service: GlobalSettingsService,
		private _tokenService: TokenService) {

		if (this._tokenService.isAuthenticated() === false) {
			this._router.navigate(['login']);
		}
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();

		this._service.get().then((response) => {
			if (!response) {
				return;
			}

			this._initializeForm(response);
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	private _initializeForm(model: GlobalSettings): void {
		this.form = this._fb.group({
			REWControl: [model.REWControl, [Validators.required, Validators.min(0), Validators.max(1)]],
			TEWControl: [model.TEWControl, [Validators.required, Validators.min(0), Validators.max(1)]],
		});
	}

	public save(): void {
		this._service.update(this.form.value as GlobalSettings).then(() => {
			this._notificationService.generateNotifcation({
				type: eNotificationTypes.UPDATE,
				message: 'Global settings were successfully saved'
			});
		});
	}
}
