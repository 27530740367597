import { eCommodityTypes } from '../../interfaces/constants';
import { ICropType } from './interfaces';
import { eCommodityTypeCalculators } from '../commodity-type/interfaces';

export class CropType {
	// Logic may change over time

	public static hasFloodEvents(commodityType: eCommodityTypes): boolean {
		return commodityType === eCommodityTypes.ALFALFA;
	}

	public static hasCutEvents(commodityType: eCommodityTypes): boolean {
		return commodityType === eCommodityTypes.ALFALFA;
	}

	/**
	 * Determine whether a crop is perennial (lasts more than one year)
	 *
	 * @param commodityTypeId
	 */
	public static isPerennialPlanting(commodityTypeId: eCommodityTypes): boolean {
		if (this.isTree(commodityTypeId) || commodityTypeId === eCommodityTypes.ALFALFA
		|| commodityTypeId === eCommodityTypes.CANEBERRY || commodityTypeId === eCommodityTypes.GRAPE
		) {
			return true;
		} else {
			return false;
		}
	}

	public static isTree(commodityTypeId: number): boolean {
		if (commodityTypeId === eCommodityTypes.ALMOND ||
			commodityTypeId === eCommodityTypes.PISTACHIO ||
			commodityTypeId === eCommodityTypes.WALNUT ||
			commodityTypeId === eCommodityTypes.PRUNE ||
			commodityTypeId === eCommodityTypes.PEAR ||
			commodityTypeId === eCommodityTypes.GRAPE) {

			return true;
		} else {
			return false;
		}
	}

	/**
	 * Associated with crop type parameters used to calculate fertilization
	 * recommendation for strawberries, tomatoes, and caneberries (which inherit
	 * from strawberries)
	 * @param calculatorInterface
	 * @returns
	 */
	public static ignoresNFactors(calculatorInterface: string): boolean {
		if (calculatorInterface === eCommodityTypeCalculators.strawberry ||
			calculatorInterface === eCommodityTypeCalculators.processingTomato ||
			calculatorInterface === eCommodityTypeCalculators.caneberry) {

			return false;
		} else {
			return true;
		}
	}

	public static isTreeByInterface(calculatorInterface: string): boolean {
		if (calculatorInterface === eCommodityTypeCalculators.almond ||
			calculatorInterface === eCommodityTypeCalculators.walnut ||
			calculatorInterface === eCommodityTypeCalculators.pistachio) {

			return true;
		} else {
			return false;
		}
	}

	public static areSpringLeafNutrientsRequired(calculatorInterface: string): boolean {
		if (calculatorInterface === eCommodityTypeCalculators.walnut ||
			calculatorInterface === eCommodityTypeCalculators.almond) {

			return true;
		} else {
			return false;
		}
	}

	public static areTreeCoefficientsRequired(calculatorInterface: string): boolean {
		if (calculatorInterface === eCommodityTypeCalculators.walnut) {
			return true;
		} else {
			return false;
		}
	}

	public static getIdsFromArray(cropTypes: ICropType[]) {
		let result: number[];

		result = new Array();

		for (let cropType of cropTypes) {
			if (cropType.IsSelected) {
				result.push(cropType.Id);
			}
		}

		return result;
	}

	/**
	 * In commodity type edit UI, if a crop type is used by at least one
	 * planting, auto select it, to prevent it from getting deselected
	 *
	 * @param cropTypes
	 */
	public static autoSelectCropTypes(cropTypes: ICropType[]): void {
		if (!cropTypes) {
			return;
		}

		for (let cropType of cropTypes) {
			if (cropType.PlantingsCount > 0) {
				cropType.IsSelected = true;
			}
		}
	}
}
