export interface ISelectOptions {
	key: number,
	value: string
}

/**
 * used for manure calculator, where there are multiple keys for the
 * same label
 */
export interface ISelectoptionsMultiple {
	keys: number[],
	label: string
}

export interface ISelectOptionBoolean {
	key: boolean,
	value: string
}

export interface IIdNamePair {
	Id: number,
	Name: string
}

export enum eDialogViews {
	CREATE = 1,
	UPDATE = 2,
	DISCARD_CHANGES,
	DELETE_CONFIRMATION,
}
