<h4 class="bk-cut-event__historical-ui--title">
    {{ 'Historical Irrigation Information Required' | translate }}
</h4>

<div lass="bk-cut-event__historical-ui--form">
    <form [formGroup]="form" class="bk-form">
        <mat-form-field appearance="fill"
            class='mat-form-field--one-line mat-form-field--short'
            hintLabel="{{'Date of Last Irrigation or Complete Saturation' | translate }}">
            <mat-label>
                Historical Irrigation Date
            </mat-label>
            <input matInput [matDatepicker]="picker"
                formControlName="EventDate"
                required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="f.EventDate.invalid && (f.EventDate.dirty || f.EventDate.touched)">
                <ng-container *ngIf="f.EventDate.errors.required">
                    {{ 'Event date is required' | translate }}
                </ng-container>
                <ng-container *ngIf="f.EventDate.errors.dateTooEarly">
                    {{ 'Date must be on or after' | translate }} {{ getMinDateBeforeIrrigationEvent() | date:'shortDate' }},
                    {{ 'which is within 60 days of irrigation date' | translate }}
                </ng-container>
                <ng-container *ngIf="f.EventDate.errors.dateTooLate">
                    {{ 'Historical event date must be before the irrigation date' | translate }} ({{ pf.EventDate.value | date:'shortDate' }})
                </ng-container>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill"
            class="mat-form-field--one-line mat-form-field--w1">
            <mat-label>
                {{ 'Irrigation Method' | translate }}
            </mat-label>
            <mat-select required
                formControlName="IrrigationMethodId">
                <mat-option value="null" disabled selected>
                    {{'Please Select' | translate }}
                </mat-option>
                <mat-option *ngFor="let method of irrigationMethods"
                    [value]="method.Id">
                    {{method.Name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="f.IrrigationMethodId.invalid && (f.IrrigationMethodId.dirty || f.IrrigationMethodId.touched)">
                {{ 'Irrigation Method is required' | translate }}
            </mat-error>
        </mat-form-field>
    </form>
</div>