<div class="bk-dialog bk-dialog__event">
    <button type="button"
        class="bk-button bk-dialog__close"
        (click)="close()">
        &times;
    </button>

    <h4 mat-dialog-title>
        <ng-container *ngIf="!eventId">
            {{'Add' | translate }} {{'Soil Sample' | translate }}
        </ng-container>

        <ng-container *ngIf="eventId">
            {{'Edit' | translate }} {{'Soil Sample' | translate }}
        </ng-container>
    </h4>

    <div mat-dialog-content
        *ngIf="isLoaded === false">

        <div class="bk-loader__container">
            <div class="bk-loader">
                {{'Loading' | translate }}...
            </div>
        </div>
    </div>

    <div mat-dialog-content
        *ngIf="isLoaded">
        <form [formGroup]="form">
            <mat-form-field appearance="fill"
                class='mat-form-field--one-line mat-form-field--short'>
                <mat-label>{{ 'Event Date' | translate }}</mat-label>
                <input matInput [matDatepicker]="picker"
                    required
                    formControlName="EventDate"
                    [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="f.EventDate.invalid && (f.EventDate.dirty ||
                    f.EventDate.touched)">
                    <div *ngIf="f.EventDate.errors.required">
                        {{ 'Event date is required' | translate }}
                    </div>
                    <div *ngIf="f.EventDate.errors.endDateOnOrAfterTargetDate">
                        {{ 'Event date must be earlier than planting end date' | translate }}
                    </div>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" *ngIf="f.isNDependent.value"
                class="mat-form-field--one-line mat-form-field--short">
                <mat-label>
                    {{'Sample Type' | translate }}
                </mat-label>
                <mat-select
                    required
                    formControlName="SampleTypeId">
                    <mat-option [value]="null" disabled selected>
                        {{'Select One' | translate }}
                    </mat-option>
                    <mat-option *ngFor="let type of sampleTypes"
                        [value]="type.key">
                        {{type.value | translate }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="f.SampleTypeId.invalid && (f.SampleTypeId.dirty ||
                    f.SampleTypeId.touched)">
                    {{'Cannot be empty. Please select an option.' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill"
                class="mat-form-field--one-line mat-form-field--short">
                <mat-label>
                    {{'Depth' | translate }}
                </mat-label>
                <mat-select
                    required
                    formControlName="SampleDepth">
                    <mat-option [value]="null" disabled selected>
                        {{'Select One' | translate }}
                    </mat-option>
                    <mat-option [value]="DEPTHS.FEET_ONE">
                        0 to 1 {{'ft' | translate }}
                    </mat-option>
                        <mat-option [value]="DEPTHS.FEET_TWO">
                        1 to 2 {{'ft' | translate }}
                    </mat-option>
                    
                </mat-select>
                <mat-error *ngIf="f.SampleDepth.invalid && (f.SampleDepth.dirty ||
                    f.SampleDepth.touched)">
                    {{'Cannot be empty. Please select an option.' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill"
                class="mat-form-field--one-line mat-form-field--short">
                <mat-label>
                    {{'Crop Stage' | translate }}
                </mat-label>
                <mat-select formControlName="CropStageId">
                    <mat-option [value]="0" selected>
                        {{'Select One' | translate }}
                    </mat-option>
                    <mat-option *ngFor="let stage of cropStages"
                        [value]="stage.Value">
                        {{stage.Text | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="!f.isFuture.value &&
                f.SampleTypeId.value === SAMPLE_TYPE_QUICK_NITRATE_STRIP">
                <label class="bk-modal__label">
                    {{'Soil Moisture' | translate }}
                </label>

                <div class="bk-modal__field bk-modal__field--anchor">
                    <mat-radio-group aria-label=""
                        name="soilMoisture"
                        formControlName="SoilMoistureId">

                        <mat-radio-button *ngFor="let type of moistureTypes"
                            [ngClass]="{ 'mat-radio-button--left-margin': type.key === 2  }"
                            [value]="type.key"
                            name="soilMoisture">
                            {{type.value}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </ng-container>

            <!--old form - crops that are n dependent-->
            <div *ngIf="!f.isFuture.value &&
                f.SampleTypeId.value > 0
                && f.isNDependent.value">
                <mat-form-field appearance="fill"
                    class='mat-form-field--one-line mat-form-field--short'>
                        <mat-label *ngIf="f.SampleTypeId.value === SAMPLE_TYPE_LAB_TEST">
                            {{ 'Nitrate-N' | translate }}
                    </mat-label>
                        <mat-label *ngIf="f.SampleTypeId.value !== SAMPLE_TYPE_LAB_TEST">
                            {{ 'Nitrate' | translate }}
                        </mat-label>
                    <input matInput 
                        type="number"
                        step="0.01"
                        required
                        formControlName="Nitrogen"/>
                    <span matSuffix class="mat-form-field-suffix--padded">
                        {{'ppm' | translate}}
                    </span>
                    <mat-error *ngIf="f.Nitrogen.invalid && (f.Nitrogen.dirty ||
                        f.Nitrogen.touched)">
            
                        <span *ngIf="f.Nitrogen.errors.min || f.Nitrogen.errors.max">
                            {{'Not a valid number' | translate }}
                        </span>
                        <span *ngIf="f.Nitrogen.errors.invalidNitrogen">
                            {{'This field is required' | translate }}
                        </span>
                    </mat-error>
                </mat-form-field>

                <ng-container *ngIf="f.OtherNutrients.controls.length > 0 &&
                    f.SampleTypeId.value === SAMPLE_TYPE_LAB_TEST">
                    <div class="bk-modal__right-two-col"
                        *ngFor="let nutrient of f.OtherNutrients.controls;
                        let i = index;">

                        <mat-form-field appearance="fill"
                            class='mat-form-field--one-line mat-form-field--short'>
                            <mat-label>
                                {{nutrient.get('Name').value}}
                            </mat-label>
                            <input matInput 
                                type="number"
                                step="0.01"
                                [formControl]="nutrient.get('value')"/>
                            <span matSuffix class="mat-form-field-suffix--padded">
                                {{nutrient.get('MeasurementUnitName').value}}
                            </span>
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>

            <div *ngIf="!f.isFuture.value && !f.isNDependent.value"
                class="bk-modal__soil-samples--dark">
                <h4 class="bk-modal__header-title">
                    {{ 'Sample Readings' | translate }}
                </h4>

                <ng-container *ngIf="f.OtherNutrients.controls.length > 0">
                    <div class="bk-modal__right-two-col"
                        *ngFor="let nutrient of f.OtherNutrients.controls;
                            let i = index;">

                        <mat-form-field appearance="fill"
                            class='mat-form-field--one-line mat-form-field--short'>
                            <mat-label>
                                {{nutrient.get('Name').value | translate}}
                            </mat-label>
                            <input matInput 
                                type="number"
                                step="0.01"
                                [formControl]="nutrient.get('value')"/>
                            <span matSuffix class="mat-form-field-suffix--padded">
                                {{nutrient.get('MeasurementUnitName').value}}
                            </span>
                        </mat-form-field>
                    </div>
                </ng-container>

                <div class="bk-modal__right-two-col">
                    <mat-form-field appearance="fill"
                        class='mat-form-field--one-line mat-form-field--short'>
                        <mat-label>
                            {{ 'Nitrate-N' | translate}}
                        </mat-label>
                        <input matInput 
                            type="number"
                            step="0.01"
                            formControlName="Nitrogen"/>
                        <span matSuffix class="mat-form-field-suffix--padded">
                            {{'ppm' | translate}}
                        </span>
                    </mat-form-field>
                </div>
            </div>

            <span *ngIf='eventId > 0'
                class='bk-modal__last-updated-meta bk-clearfix'>
                {{ 'Last updated by' | translate }} {{lastUpdatedUser}}
            </span>
        </form>
    </div>

    <div mat-dialog-actions align="end">
        <button class="bk-button__delete"
            *ngIf="eventId"
            (click)="onDeleteConfirmationOpen($event)">
            
            {{'Delete' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only"
            (click)="onClose()">
            {{'Cancel' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            *ngIf="!eventId"
            [disabled]="form && form.invalid"
            (click)="create()">
            
            {{ (isSaving ? 'Creating' : 'Create') | translate }}
            <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            *ngIf="eventId"
            [disabled]="form && form.invalid"
            (click)="update()">

            {{ (isSaving ? 'Saving' : 'Save') | translate }}
            <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
        </button>
    </div>
</div>
