<div class="bk-modal__dropdown-row">
	<span class="bk-recommendation__label bk-bold">
		{{'Average ET' | translate }}
		<info-icon matTooltip="{{'Average ET Text' | translate }}"></info-icon>
	</span>
	<span class="bk-recommendation__data">
		{{summary.AverageCropET | number: '1.2-2' | zeroIfEmpty }} {{'in' | translate }}/{{'day' | translate }}
	</span>
</div>

<div class="bk-modal__dropdown-row">
	<span class="bk-recommendation__label bk-bold">
		{{'Average Crop Coefficient' | translate }}
		<info-icon matTooltip="{{'Average Crop Coefficient Text' | translate}}"></info-icon>
	</span>
	<span class="bk-recommendation__data">
		{{summary.AverageCropCoefficient | number: '1.0-2' | zeroIfEmpty }}
	</span>
</div>

<div class="bk-modal__dropdown-row">
	<span class="bk-recommendation__label bk-bold">
		{{'Distribution Uniformity' | translate }}
		<info-icon matTooltip="{{'Distribution Uniformity Text' | translate}}"></info-icon>
	</span>
	<span class="bk-recommendation__data">
		{{summary.Distribution | number: '1.0-2' | zeroIfEmpty }}%
	</span>
</div>

<div class="bk-modal__dropdown-row">
	<span class="bk-recommendation__label bk-bold">
	{{'Days Since Last Irrigation' | translate }}
		<info-icon matTooltip="{{'Days Since Last Irrigation Text' | translate}}"></info-icon>
	</span>
	<span class="bk-recommendation__data">
		{{summary.DaysSinceLastIrrigation | number: '1.0-0' | zeroIfEmpty }} 
		{{ (summary.DaysSinceLastIrrigation > 1 ? 'days' : 'day') | translate }}
		<i *ngIf="summary.IsImpactedByMacroTunnel"
			class="bk-event-recommendation-not-applied-icon fa fa-warning"
			matTooltip="{{ 'This number is impacted by macro tunnel usage' | translate }}"></i>
	</span>
</div>

<div class="bk-modal__dropdown-row">
	<span class="bk-recommendation__label bk-bold">
		{{'Leaching Requirement' | translate }}
		<info-icon matTooltip="{{'Leaching Requirement Text' | translate }}"></info-icon>
	</span>
	<span class="bk-recommendation__data">
		{{leechingFactors.percent}}% 
		<span *ngIf='leechingFactors.percent > 0'>
			({{leechingFactors.decimal | number: '1.1-2'}})
		</span>
	</span>
</div>

<div *ngIf="summary.CropSensitivity && summary.CropSensitivity !== 1"
	class="bk-modal__dropdown-row">

	<span class="bk-recommendation__label bk-bold">
		{{'Crop Sensitivity' | translate }}
		<info-icon matTooltip="{{'Crop Sensitivity Help Text' | translate }}"></info-icon>
	</span>
	<span class="bk-recommendation__data">
		{{summary.CropSensitivity | number: '1.1-1' }}
	</span>
</div>

<div class="bk-modal__dropdown"
	[class.bk-modal__dropdow--alt]="isPlantingPerennial">
	<span class="bk-recommendation__label bk-bold">
		{{'Total Precipitation' | translate }}
		<info-icon matTooltip="{{'Total Precipitation Text' | translate}}"></info-icon>
	</span>
	<span class="bk-recommendation__data">
		{{summary.TotalPrecipitation | number: '1.0-2' | zeroIfEmpty }} {{'in' | translate }}
	</span>
</div>

<div class="bk-modal__dropdown">
	{{'Total Crop ET' | translate }} = {{'Total Crop ET Formula' | translate }}
</div>

<div class="bk-modal__dropdown bk-bold">
	<span>
		{{totalCropET | number: '1.2-2' | zeroIfEmpty}} in. =  
		{{summary.AverageCropET | number: '1.2-2' | zeroIfEmpty}} x 
		{{summary.AverageCropCoefficient | number: '1.2-2' | zeroIfEmpty}} x 
		{{summary.DaysSinceLastIrrigation | number: '1.0-0' | zeroIfEmpty}}
	</span>
</div>

<ng-container *ngIf="totalCropET > summary.TotalPrecipitation">
	<ng-container *ngIf='!summary.CropSensitivity || summary.CropSensitivity === 1'>
		<!-- CropSensitivity is a multiplier, so if its 1, it has no effect -->
		<div class="bk-modal__dropdown">
			{{'Recommended Irrigation Formula' | translate }}
		</div>

		<div class="bk-modal__dropdown bk-bold">
			{{summary.RecommendedIrrigationAmount | number: '1.2-2' | zeroIfEmpty}} in. = 
			({{totalCropET | number: '1.2-2' | zeroIfEmpty}} {{'in' | translate }}
			- {{summary.TotalPrecipitation | number: '1.2-2' | zeroIfEmpty}} {{'in' | translate }}) x 100 / (
			{{summary.Distribution | number: '1.2-2' | zeroIfEmpty}} * (1 - 
			{{leechingFactors.decimal}}))
		</div>
	</ng-container>
	<ng-container *ngIf='summary.CropSensitivity && summary.CropSensitivity !== 1'>

		<div class="bk-modal__dropdown">
			{{'Recommended Irrigation Formula 2' | translate }}
		</div>

		<div class="bk-modal__dropdown bk-bold">
			{{summary.RecommendedIrrigationAmount | number: '1.2-2' | zeroIfEmpty}} in. = 
			({{totalCropET | number: '1.2-2' | zeroIfEmpty}} {{'in' | translate }}
			- {{summary.TotalPrecipitation | number: '1.2-2' | zeroIfEmpty}} {{'in' | translate }}) x 100 / (
			{{summary.Distribution | number: '1.2-2' | zeroIfEmpty}}
			x {{summary.CropSensitivity | number: '1.1-1'}} x (1 - 
			{{leechingFactors.decimal}}))
		</div>
	</ng-container>
</ng-container>

<ng-container *ngIf="summary.TotalPrecipitation >= totalCropET">
	<div class="bk-modal__dropdown">
		{{'Rainfall' | translate}}({{summary.TotalPrecipitation | number: '1.2-2' | zeroIfEmpty }} in.)
		> {{ 'Total Crop ET' | translate}}({{totalCropET | number: '1.2-2' | zeroIfEmpty}})
	</div>
	<div class="bk-modal__dropdown bk-bold">
		Recommendation is 0 inches because rainfall is greater than ET.
	</div>
</ng-container>