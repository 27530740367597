<div class="bk-dialog">
    <div *ngIf="!loadingData">

        <button type="button"
            class="bk-dialog__close"
            (click)="close()">

            &times;
        </button>

        <h4 mat-dialog-title>
            {{'Weather Data Options' | translate }}
        </h4>
 
        <div mat-dialog-content>
            <ng-container *ngIf="SpatialETAvailable">
                <h4>
                    {{'Weather Data Source' | translate }}
                </h4>

                <p>{{'ET Option Intro' | translate}}</p>
                <!-- CropManage retrieves ET data from either weather stations or 
                spatial CIMIS (using coordinates associated with a planting). -->
                    
                <div class="bk-modal__radio-buttons bk-modal__margin-correction">
                    <mat-radio-group aria-label=""
                        name="UseSpatialCIMIS"
                        [(ngModel)]="cimisOptions.UseSpatialCIMIS">
                        
                        <mat-radio-button [value]="false"
                            name="UseSpatialCIMIS">
                            <strong>{{'Weather Station' | translate}}</strong>
                        </mat-radio-button>
                        <!-- Weather Station: get ET data using weather stations.-->
                        <p class="mat-radio-label-paragraph">
                            {{'CIMIS ET Options Text 2' | translate}}
                        </p>

                        <mat-radio-button [value]="true" name="UseSpatialCIMIS">
                            <strong>{{'Spatial Model' | translate}}</strong>
                        </mat-radio-button>
                        <!-- Spatial Model: Get ET data using spatial model. Recommended if weather stations are more than 25 miles away. Note
                        that currently this method can be significantly slower than getting data using
                        weather stations. -->
                        <p class="mat-radio-label-paragraph">
                            {{'CIMIS ET Options Text 1' | translate}}
                        </p>
                    </mat-radio-group>
                </div>
            </ng-container>

            <h4>
                {{'Weather Stations Setting' | translate }}
            </h4>

            <mat-radio-group aria-label=""
                name="UseCIMISAverageMode"
                [(ngModel)]="cimisOptions.UseCIMISAverageMode">
                
                <mat-radio-button [value]="false"
                    name="UseCIMISAverageMode">
                    <strong>{{ 'Sequential Mode' | translate}}</strong>
                </mat-radio-button>

                <p class="mat-radio-label-paragraph">
                    {{(cimisOptions.UseSpatialCIMIS ?
                        'CIMIS Data Mode Text 1' : 'CIMIS Data Mode Text 1a') | translate}}
                </p>
                    <!-- Sequential Mode: Use ET and rainfall data from the first active
                weather station on my list. Additional weather stations serve as
                backup if data is not available from the primary weather station.-->

                <mat-radio-button class="bk-clearfix"
                    [value]="true"
                    name="UseCIMISAverageMode">
                    <strong>{{ 'Average Mode' | translate}}</strong>
                </mat-radio-button>

                <p class="mat-radio-label-paragraph">
                    {{(cimisOptions.UseSpatialCIMIS ?
                        'CIMIS Data Mode Text 2' : 'CIMIS Data Mode Text 2a') | translate}}
                </p>
                <!-- Average Mode: Average ET and rainfall data from all
                weather stations on my list.-->
            </mat-radio-group>
        </div>
        <div mat-dialog-actions align="end">
            <button class="bk-button bk-button--medium bk-button--text-only"
                (click)="close()">
                {{'Cancel' | translate }}
            </button>
            <button class="bk-button bk-button--medium"
                (click)="saveCimisOptions()">
                {{'Save' | translate }}
            </button>
        </div>
    </div>
</div>