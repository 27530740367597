import { ICanopyData, SimsCanopyDatamodel, SimsDataModel } from './interfaces';
import { eCommodityTypes } from '../../interfaces/constants';
import { eCommodityTypeCalculators } from '../../models/commodity-type/interfaces';
import { CropType } from '../../models/crop-type/cropType';
import { FormBuilder, Validators } from '@angular/forms';
import { FormValidation } from '../../classes/formValidation';

export type CanopyCurveFields = 'CanopyGMax' | 'MaxFraction' | 'CanopyA' |
	'CanopyB' | 'CanopyE' | 'CanopyF' | 'CanopyMin';

export class Canopy {

	public form: FormGroup;
	readonly graphContainerElement: string = '#flot-canopy-chart';

	// values from the backend
	public PlantingId = 0;
	public ranchGuid = '';
	public HasCoordinates: boolean = null;

	public get f(): { [key in CanopyCurveFields]: AbstractControl } {
		return this.form.controls as { [key in CanopyCurveFields]: AbstractControl };
	}

	constructor(
		private _commodityTypeId: number,
		private _commodityTypeCalculator: string,
		private _fb: FormBuilder
	) {
	}

	private _initializeForm(model: SimsCanopyDatamodel): void {

		this.form = this._fb.group({
			CanopyGMax: [model.CanopyGMax, [Validators.required, Validators.min(0), Validators.max(100)]],
			MaxFraction: [model.MaxFraction, [Validators.required, Validators.min(0.01), Validators.max(1)]],
			CanopyMin: [model.CanopyMin, [Validators.required, Validators.min(0), Validators.max(100)]],
			CanopyA: [model.CanopyA, [Validators.required, Validators.min(1.5), Validators.max(10)]],
			CanopyB: [model.CanopyB, [Validators.required, Validators.min(-20), Validators.max(-4)]],
			CanopyE: [model.CanopyE, [Validators.required, Validators.min(-2.5), Validators.max(0.2)]],
			CanopyF: [model.CanopyF, [Validators.required, Validators.min(0.4), Validators.max(3)]]
		});

		if (this._commodityTypeId === eCommodityTypes.ALFALFA) {
			FormValidation.clearValidator(this.f.MaxFraction);
		} else {
			FormValidation.clearValidator(this.f.CanopyMin);
		}

		if (this._commodityTypeCalculator !== eCommodityTypeCalculators.processingTomato
			&& !CropType.isTree(this._commodityTypeId)) {

			FormValidation.clearValidator(this.f.CanopyE);
			FormValidation.clearValidator(this.f.CanopyF);
		}
	}

	public setData(canopyData: SimsDataModel): void {
		if (!canopyData) {
			return;
		}

		this._initializeForm(canopyData.CanopyParams)
		this.PlantingId = canopyData.PlantingId;
		this.ranchGuid = canopyData.RanchGuid;
	}

	public dataHasChanged(): boolean {
		if (this.form && this.form.touched) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * A method used to create a model to post to C# with
	 * @returns
	 */
	public getModelForPost(): ICanopyData {
		let canopyData: ICanopyData;

		canopyData = {
			PlantingId: this.PlantingId,
			ranchGuid: this.ranchGuid,
			CanopyGMax: this.f.CanopyGMax.value,
			MaxFraction: this.f.MaxFraction.value,
			CanopyA: this.f.CanopyA.value,
			CanopyB: this.f.CanopyB.value,
			CanopyE: this.f.CanopyE.value,
			CanopyF: this.f.CanopyF.value,
			CanopyMin: this.f.CanopyMin.value,
			HasCoordinates: this.HasCoordinates
		}

		return canopyData;
	}

	public isInvalid(): boolean {
		if (!this.form || this.form.invalid) {
			return true;
		} else {
			return false;
		}
	}

	public resetForm(defaultValues: ICanopyData): void {
		if (!this.form) {
			return;
		}

		this.form.reset(defaultValues);
		this.form.markAllAsTouched();
	}
}
