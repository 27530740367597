import { DateUtility } from '../../classes/dateUtility';
import { ICutEventJSON } from './cut-event.interfaces';

export enum eCutEventViews {
	FORM = 1,
	HISTORICAL_CUTTING_INFORMATION,
	DISCARD_CHANGES,
	DELETE_CONFIRMATION,
	SUCCEEDING_EVENTS
}

export interface ICutEventRecency {
	HasCutRecently: boolean,
	IsNearGerminationDate: boolean
}

export enum eCutEventValidationStates {
	VALID = 1,
	EMPTY,
	EARLIER_THAN_GERMINATION,
	OUT_OF_RANGE, // historical only
	PAST_CUT_EVENT, // historical only
	HAS_FUTURE_YIELD
}

export class CutEvent {
	public Id: number;
	public PlantingId: number;
	public EventDate: Date;
	public MaturityId: number;
	public Yield: number;
	public IsBookmark: boolean;
	public CutInterval: number;
	public LastUpdatedUser: string;

	private readonly _maxDaysBetweenCutEvents: number = 60;

	constructor(plantingId?: number) {
		this.Id = null;
		this.PlantingId = plantingId ? plantingId : null;
		this.EventDate = null;
		this.MaturityId = null;
		this.Yield = null;
		this.IsBookmark = false; // default to false
	}

	/**
     * Takes JSON data and copies into a class object
     * @param json
     */
	public transmuteJSON(json: ICutEventJSON) {
		this.Id = json.Id;
		this.PlantingId = json.PlantingId;
		this.EventDate = DateUtility.DotNetToDate(json.EventDate);
		this.MaturityId = json.MaturityId;
		this.Yield = json.Yield;
		this.IsBookmark = json.IsBookmark;
		this.CutInterval = json.CutInterval;
		this.LastUpdatedUser = json.LastUpdatedUser;
	}

	/**
     * Takes an empty class object and copy to another class object
     */
	public copy(cutEvent: CutEvent): void {
		this.Id = cutEvent.Id;
		this.PlantingId = cutEvent.Id;
		this.EventDate = cutEvent.EventDate;
		this.MaturityId = cutEvent.MaturityId;
		this.Yield = cutEvent.Yield;
		this.IsBookmark = cutEvent.IsBookmark;
	}

	public clear(): void {
		this.EventDate = null;
		this.MaturityId = null;
		this.Yield = null;
		this.IsBookmark = false;
	}

	public isDifferent(event: CutEvent): boolean {

		if (!event) {
			return true;
		}

		if (this.EventDate !== event.EventDate ||
			this.MaturityId !== event.MaturityId ||
			this.Yield !== event.Yield ||
			this.IsBookmark !== event.IsBookmark) {

			return true;
		} else {
			return false;
		}
	}
}
