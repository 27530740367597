import { Injectable } from '@angular/core';
import { HttpServicePostOptions } from '../../services/http.service';

import { ModelService } from '../../services/model.service';

import { IWell } from '../planting-well/well.interface';
import { CMError } from '../../interfaces/interfaces';
import { IFertilizationEventWellService } from './interfaces';

@Injectable()
export class FertilizationEventWellService extends ModelService implements IFertilizationEventWellService {

	private urls = {
		listByPlantingId: (plantingId: number) => `/v2/plantings/${plantingId}/fertilization-events/wells.json`,
		list: (eventId: number) => `/v2/fertilization-events/${eventId}/wells.json`,
		saveAll: (eventId: number) => `/v2/fertilization-events/${eventId}/wells/batch.json`,
		Sync: (eventId: number) => `/v2/fertilization-events/${eventId}/wells/sync.json`,
	};

	/**
	 * Fetch wells for a fertilization event based on planting ID
	 * @param plantingId
	 */
	public listByPlantingId(plantingId: number): Promise<IWell[]> {
		let options: HttpServicePostOptions;

		options = {
			body: null,
			url: this.urls.listByPlantingId(plantingId),
			isWebAPI: true,

			callback: (response: IWell[]) => {
				if (!response) {
					return null;
				} else {
					return response;
				}
			}
		}

		return this.httpService.get(options);
	};

	/**
	 * Call to sync fertilization event wells against lotPlanting wells or ranch wells..
	 * might be needed when past event is scheduled to future after a well has been added
	 * @param fertilizationEventId
	 */
	public syncFertilizationEventWells(fertilizationEventId: number): void {
		let options: HttpServicePostOptions;

		if (fertilizationEventId == null) {
			return;
		}

		options = {
			body: null,
			url: this.urls.Sync(fertilizationEventId),
			isWebAPI: true,

			callback: (response: CMError) => {
				if (!response) {
					return null;
				} else {
					return response;
				}
			}
		}

		this.httpService.post(options);
	}

	/**
	 * Get wells associated with a fertilization event
	 * @param eventId
	 */
	public getFertilizationEventWells(eventId: number): Promise<IWell[]> {

		return this.get(this.urls.list(eventId), (response: IWell[]) => {
			if (!response) {
				return null;
			} else {
				return response;
			}
		});
	}

	/**
	 *
	 * @param wells
	 * @returns
	 */
	public saveAllFertilizationWells(wells: IWell[], eventId: number): Promise<CMError> {
		let options: HttpServicePostOptions;
		let body: { Wells: IWell[] };

		body = {
			Wells: wells
		};

		options = {
			body: body,
			url: this.urls.saveAll(eventId),
			isWebAPI: true,

			callback: (response: CMError) => {
				if (!response) {
					return null;
				} else {
					return response;
				}
			}
		}

		return this.httpService.post(options);
	}
}
