import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { CommodityService } from '../../../models/commodity-type/service';
import { ICommodityType } from '../../../models/commodity-type/interfaces';
import { FormBuilder, Validators } from '@angular/forms';
import { FormValidation } from '../../../classes/formValidation';
import { Coordinates } from '../../../classes/Coordinates';
import { CropTypeService } from '../../../models/crop-type/service';
import { takeUntil } from 'rxjs/operators';
import { ICropTypeListItem } from '../../../models/crop-type/interfaces';
import { DateUtility } from '../../../classes/dateUtility';
import { AgmMap } from '@agm/core';
import { MapControlDirective } from '../../../directives/map-control.directive';
import { IAgmMapClick } from '../../shared/interfaces';
import { GeoLocation } from '../../../classes/geolocation.class';
import { UpdateService } from '../../../services/update.service';
import { NotificationService } from '../../../services/notification.service';
import { eNotificationTypes } from '../../../interfaces/constants';
import { WeatherStationsService } from '../../../models/weather-station-stats/service';
import { IMapIcon, IMapIconSize } from '../../ranch-settings/modals/weather-station/interfaces';
import { IWeatherStationViewModel } from '../../weather-stations/interfaces';
import { CropType } from '../../../models/crop-type/cropType';
import { ISelectOptions } from '../../../interfaces/abstract';
import { PlantingAdvancedEdit } from '../../planting-settings/plantingAdvancedEdit';
import { TranslateService } from '../../localization/service';
import { Router } from '@angular/router';
import { PersistentDatabase } from '../../../services/persistent-database';
import { ETCalculatorResultsInputParams } from './et-calculator-results.component';

export type ETCalculatorFields = 'AgeOfCrop' | 'CommodityTypeId' | 'CropTypeId' | 'WetDate'
	| 'HarvestDate' | 'Latitude' | 'Longitude' | 'WeatherStationId';

@Component({
	selector: 'et-calculator',
	templateUrl: 'et-calculator.component.html',
	styleUrls: [ 'et-calculator.component.scss']
})

export class ETCalculatorComponent implements OnInit, OnDestroy {

	@ViewChild(AgmMap, { static: false }) public map: AgmMap;
	@ViewChild(MapControlDirective, { static: false }) private mapControl: MapControlDirective;

	public commodityTypes: ICommodityType[];
	public cropTypes: ICropTypeListItem[];
	public weatherStations: IWeatherStationViewModel[];
	public coordinatesUser: Coordinates; // used to store user coodinates
	public mapZoom: number;
	public ageOfCropOptions: ISelectOptions[];
	private DEFAULT_COORDINATE = '37.020714, -119.445638';

	public icons: {
		selected: IMapIcon,
		unselected: IMapIcon
	}

	public wetDateRange: {
		minDate: Date,
		maxDate: Date
	}

	public harvestDateRange: {
		minDate: Date,
		maxDate: Date
	}

	public form: FormGroup;

	public get f(): { [key in ETCalculatorFields]: AbstractControl } {
		return this.form.controls as { [key in ETCalculatorFields]: AbstractControl };
	}

	private isTree: boolean;
	private MAX_DATE_RANGE = 365;
	private _subscriptions$: Subject<boolean>;

	constructor(
		private _commodictyService: CommodityService,
		private _cropTypeService: CropTypeService,
		private _fb: FormBuilder,
		private _notificationService: NotificationService,
		private _persistentDatabase: PersistentDatabase,
		private _router: Router,
		private _translateService: TranslateService,
		private _updateService: UpdateService,
		private _weatherStationsService: WeatherStationsService,
	) {
		let iconDimensions: IMapIconSize;

		iconDimensions = {
			height: 50,
			width: 80
		};

		this.wetDateRange = {
			minDate: null,
			maxDate: DateUtility.subtractDays(new Date(), 1)
		};

		this.harvestDateRange = {
			minDate: null,
			maxDate: null
		};

		this.icons = {
			selected: {
				url: '../assets/images/icons/map_marker-weather_station-selected.png',
				scaledSize: iconDimensions
			},
			unselected: {
				url: '../assets/images/icons/map_marker-weather_station.png',
				scaledSize: iconDimensions
			}
		}

		this.weatherStations = [];
		this.isTree = false;
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();
		this._initializeForm();

		this._getWeatherstations().then(() => {
			this._getUserLocation().then(() => {
				this._loadPersistentData();
			});
		});

		this._commodictyService.list().then((response) => {
			this.commodityTypes = response;
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public calculate() {
		this._persistentDatabase.etCalculatorParams = {
			data: this.form.value,
			CropTypeName: this.getCropTypeName(),
			WeatherStationName: this.weatherStations[0].Name,
			CommodityTypeId: this.f.CommodityTypeId.value
		};

		this._router.navigate(['et-calculator-results']);
	}

	public mapReady() {
		this._centerMap(this.coordinatesUser);
	}

	public clickMap(event: IAgmMapClick) {
		if (!this.weatherStations || this.weatherStations.length === 0) {
			this._notificationService.generateNotifcation({
				type: eNotificationTypes.ERROR,
				message: `Please wait until weather stations data have loaded.`
			});
		}

		this.f.Latitude.setValue(event.coords.lat);
		this.f.Longitude.setValue(event.coords.lng);

		this._sortStationsByDistance();
		this.f.WeatherStationId.setValue(this.weatherStations[0].Id);
	}

	public getCropTypeName(): string {
		if (!this.cropTypes || this.cropTypes.length === 0 || !this.f.CropTypeId.value) {
			return '';
		}

		return this.cropTypes.find(x => x.Id === this.f.CropTypeId.value).Name;
	}

	private _loadPersistentData() {
		let params: ETCalculatorResultsInputParams;

		if (!this._persistentDatabase.etCalculatorParams) {
			return;
		}

		params = this._persistentDatabase.etCalculatorParams;

		this.f.AgeOfCrop.setValue(params.data.AgeOfCrop);
		this.f.CommodityTypeId.setValue(params.CommodityTypeId);
		this.f.CropTypeId.setValue(params.data.CropTypeId);
		this.f.HarvestDate.setValue(params.data.HarvestDate);
		this.f.WetDate.setValue(params.data.WetDate);
		this.f.WeatherStationId.setValue(params.data.WeatherStationId);
		this.f.Latitude.setValue(params.data.Latitude);
		this.f.Longitude.setValue(params.data.Longitude);

		this._sortStationsByDistance();
		this.f.WeatherStationId.setValue(this.weatherStations[0].Id);

		let coords: Coordinates;

		coords = new Coordinates(this.f.Latitude.value + ',' + this.f.Longitude.value);
		this._centerMap(coords);
	}

	private _sortStationsByDistance() {
		this.weatherStations.forEach(e => {
			e.Distance = this._calculateDistance(e.Lat, e.Lng, this.f.Latitude.value, this.f.Longitude.value);
		});

		this.weatherStations.sort(function (a, b) {
			return a.Distance - b.Distance;
		});
	}

	private _getRad(x: number): number {
		return x * Math.PI / 180;
	}

	private _calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
		let result: number;

		const EARTH_RADIUS = 6371; // km
		const dLat = this._getRad(lat2 - lat1);
		const dLon = this._getRad(lon2 - lon1);
		const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
			+ Math.cos(this._getRad(lat1)) * Math.cos(this._getRad(lat2))
			* Math.sin(dLon / 2) * Math.sin(dLon / 2);

		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		result = EARTH_RADIUS * c;

		return result;
	}

	private _getWeatherstations(): Promise<void> {
		return this._weatherStationsService.listAll(true).then((stations) => {
			this.weatherStations = stations;
		});
	}

	private _getUserLocation(): Promise<void> {
		return GeoLocation.getCoordinates().then(position => {
			if (!position.coords.latitude || !position.coords.longitude) {
				return;
			}

			this._updateService.setCurrentLocation(Number(position.coords.latitude),
				Number(position.coords.longitude));

			this.coordinatesUser = new Coordinates(this._updateService.currentLocation.lat
				+ ',' + this._updateService.currentLocation.lng);

			this.mapZoom = 10;
			this._centerMap(this.coordinatesUser);
		}).catch((reason: any) => {
			this._notificationService.generateNotifcation({
				type: eNotificationTypes.MESSAGE,
				message: `Granting permission to share location with
					CropManage will center the map at your location`
			});

			this.coordinatesUser = new Coordinates(this.DEFAULT_COORDINATE);
			this.mapZoom = 10;
			this._centerMap(this.coordinatesUser);
		});
	}

	private _initializeForm() {
		this.form = this._fb.group({
			AgeOfCrop: [ null, [Validators.required]],
			CommodityTypeId: [null, [Validators.required, FormValidation.greaterThanValidator(0)]],
			CropTypeId: [null, [Validators.required, FormValidation.isDate, FormValidation.greaterThanValidator(0)]],
			WetDate: [null, [Validators.required]],
			HarvestDate: [null],
			Latitude: [null, [Validators.required]],
			Longitude: [null, [Validators.required]],
			WeatherStationId: [null, [Validators.required]]
		});

		this.f.HarvestDate.setValidators([Validators.required,
			FormValidation.isDate,
			FormValidation.mustBeAfterDate(this.f.WetDate),
			FormValidation.mustBeWithinDateRange(this.f.WetDate, this.MAX_DATE_RANGE)]);

		this.f.CommodityTypeId.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			let item: ICommodityType;

			item = this.commodityTypes.find(x => x.Id === this.f.CommodityTypeId.value);
			this.isTree = CropType.isTreeByInterface(item.CommodityTypeCalculator);

			if (this.isTree) {
				this.f.AgeOfCrop.enable();
			} else {
				this.f.AgeOfCrop.disable();
			}

			this._cropTypeService.getCropTypesAdmin(false, this.f.CommodityTypeId.value).then((response) => {
				this.cropTypes = response;
			});
		});

		this.f.CropTypeId.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			if (!this.cropTypes) {
				return;
			}

			if (this.isTree) {
				let item: ICropTypeListItem;

				item = this.cropTypes.find(x => x.Id === this.f.CropTypeId.value);

				this.ageOfCropOptions = PlantingAdvancedEdit.generateAgeOfCropDropdown(item.MaxAge,
					this._translateService);
			} else {
				this.ageOfCropOptions = [];
			}
		});

		this.f.WetDate.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.harvestDateRange.minDate = DateUtility.addDays(this.f.WetDate.value, 1); // end date should not cross over start date
			this.harvestDateRange.maxDate = this._getHarvestDateMax(this.f.WetDate.value); // end date should not be out of range
		});

		this.f.HarvestDate.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.wetDateRange.minDate = DateUtility.addDays(this.f.HarvestDate.value, -this.MAX_DATE_RANGE);
			this.wetDateRange.maxDate = DateUtility.addDays(this.f.HarvestDate.value, -1);
		});
	}

	private _getHarvestDateMax(wetDate: Date): Date {
		let maxDateInRange: Date;
		let yesterday: Date;

		yesterday = DateUtility.subtractDays(new Date(), 1);
		maxDateInRange = DateUtility.addDays(this.f.WetDate.value, this.MAX_DATE_RANGE);

		if (maxDateInRange < yesterday) {
			return maxDateInRange;
		} else {
			return yesterday;
		}
	}

	private _centerMap(coordinates: Coordinates): void {
		const ZOOM_LEVEL = 15;

		if (!this.mapControl || !this.map || !coordinates) {
			return;
		}

		this.map.triggerResize().then(() => {
			this.mapControl.centerMap(coordinates.lat, coordinates.lng);
			this.mapControl.setZoom(ZOOM_LEVEL);
		});
	}
}
