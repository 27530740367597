import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CanopyCurveFields } from './canopy';

@Component({
	moduleId: module.id,
	selector: 'canopy-slider',
	templateUrl: 'canopy-slider.html'
})

export class CanopySliderComponent implements OnInit {

	@Input()
	public controlName: string;

	@Input()
	public formGroup: FormGroup;

	@Input()
	public label: string;

	@Input()
	public min: number;

	@Input()
	public max: number;

	@Input()
	public decimals: number;

	@Input()
	public step: number;

	@Input()
	public defaultValue: number;

	@Input()
	public tooltipText: string;

	@Output()
	public reload: EventEmitter<void> = new EventEmitter<void>();

	public get f(): { [key in CanopyCurveFields]: AbstractControl } {
		return this.formGroup.controls as { [key in CanopyCurveFields]: AbstractControl };
	}

	ngOnInit(): void {
	}

	public reloadData(): void {
		this.reload.emit();
	}
}
