import { IIdNamePair } from '../../interfaces/abstract';
import { ISoilLayer, ISoilWebProperties } from '../../models/soil-type/interfaces';
import { CMError, SuccessResponse } from '../../interfaces/interfaces';
import { IPlanting, ILotPlantingFavoritesJSON } from '../planting-settings/interfaces';
import { IRanchLotModel, ILotEdit } from './modals/lot/interfaces';
import { IWell } from '../../models/planting-well/well.interface';

export interface IUserSearchResult {
	Name: string,
	UserId: string
}

export interface IRanchFeatureEmptyPermissions {
	FeatureId: number,
	FeatureName: string,
	PermissionId: number
}

export interface IRanchPermissionTypes {
	PermissionId: number,
	PermissionName: string
}

export interface IRanchUserPermissionWithWebAPIViewModel {
	Site: IRanchFeatureEmptyPermissions[],
	WebAPIAccess: boolean,
	ranchGuid: string,
}

export interface IRanch {
	Id: number,
	Name: string,
	Coordinates?: string,
	Acres?: number,

	Ranch_External_GUID?: string,

	RanchId?: string,
	CreatedByFullName?: string,
	RanchAccessState?: number,
	UserAccessState?: number,
	ActivePlantings?: number

	// virtual
	Favorite?: boolean,
	FavoriteId?: number,
	RanchOwnerFullName?: string,
	WeatherAPI_Id?: number,
}

/**
 * C# model
 */
export interface RanchUpdateParams {
	Name: string,
	Acres: string,
	Coordinates: string
}

/**
 * C# model
 */
export interface RanchCreateResponse {
	Id: number,
	Guid: string,
	WeatherAPI_Id?: number
}

/**
 * C# model
 */
export interface RanchUpdateCIMISParams {
	UseSpatialCIMIS: boolean,
	UseCIMISAverageMode: boolean
}

/**
 * C# model
 */
export interface IRanchBasicInfoViewModel {
	RanchName: string,
	Acres: number,
	Coordinates: string,
	RanchOwner: string,
	RanchOwnerFullName: string,
	RanchOwnerEmail: string,
	UseSpatialCIMIS: boolean,
	UseCIMISAverageMode: boolean,
	IsPrivate: boolean,
	SpatialETAvailable: boolean,
	WeatherAPI_Id: number
}

export interface IRanchPermissions {
	HasActivePlantings: boolean,
	RanchExternalGuid: string,
	RanchName: string,
	UserCanDataExport: boolean,
	UserCanEditFertilizationEvent: boolean,
	UserCanEditIrrigationEvent: boolean,
	UserCanEditPlanting: boolean,
	UserCanEditRanch: boolean,
	UserCanEditSoilSampleEvent: boolean,
	UserIsRanchOwner: boolean
}

export interface ISuccessResponse {
	Message: string
}

/**
 * Angular version of C# RanchUserListItem
 */
export interface IRanchUserListItem {
	Email: string,
	IsOwner: boolean,
	Role: string,
	UserId: string,
	UserFullName: string
}

export interface ICIMISOptions {
	UseSpatialCIMIS: boolean,
	UseCIMISAverageMode: boolean
}

export interface IRanchSettingsValidationStates {
	Members: boolean,
	Lots: boolean,
	Fertilizers: boolean,
	WeatherStations: boolean,
	Wells: boolean,
	CommodityTypes: boolean,
	Valid: boolean,
	MissingValues: string[]
}

export interface IRanchFavoritesPostResponse {
	Id: number,
	RanchId: number,
	UserId: string,
	Ranch: IRanch
}

export interface ISubjectFavoriteResponse {
	ranchId: number,
	favoriteId: number,
	ranch: IRanch
}

export const eRanchSettingNames = {
	users: 'users',
	lots: 'lots',
	wells: 'wells',
	fertilizers: 'fertilizers',
	weatherStations: 'weatherStations',
	commodityTypes: 'commodityTypes'
};

/**
 * Angular version of the C# RanchLotEdit interface
 * used for LotEdit call
 */
export interface IRanchLotEditJSON {
	Coordinates: string,
	Model: IRanchLotEditViewModel
	SoilWebProperties: ISoilWebProperties
}

export interface IRanchLotEditViewModel {
	RanchLotViewModel: IRanchLotViewModel,
	SoilLayers: ISoilLayer[],
}

export interface IRanchLotViewModel {
	Name: string,
	Acres: number,
	Coordinates: string,
	ObstructionDepth: number,
}

/**
 * Copy of CropManage.Common.Models.JSON.RanchLot
 */
export interface IRanchLotJSONModel {
	Id: number,
	Name: string,
	RanchId: number,
	Acres: number,
	ObstructionDepth: number,
	CreateDT: string,
	LastUpdateDT: string,
	Deleted: boolean

	// calculated

	Coordinates: string,

	// added in JS

	Selected?: boolean,
	Disabled?: boolean,
}

export interface IActiveStation {
	Id: number,
	Active: boolean
}

export interface IWeatherStationCurrentModel {
	Id: number,
	External_Id: string,
	Name: string,
	Distance: number,
	NearestCity: string,
	County: string,
	Priority: number,
	ActiveStation: IActiveStation
}

export interface IWeatherStationModel {
	Id: number,
	External_Id: string,
	Name: string,
	Lat: string,
	Lng: string,
	Distance: number,
	NearestCity: string,
	County: string
}

export interface IMarkerCollection {
	RanchLocation: IMarker,
	Stations: IMarker[]
}

export interface MarkerCollection {
	RanchLocation: Marker,
	Stations: Marker
}

export interface IMarker {
	Id: number,
	External_Id: string,
	Longitude: number,
	Latitude: number,
	Selected: boolean,
	Name: string,
	Distance: number,
	NearestCity: string,
	County: string,
	isCurrent?: boolean
}

export interface Marker {
	Id: number,
	External_Id: string,
	Longitude: number,
	Latitude: number,
	Name: string,
	Distance: number,
	NearestCity: string,
	County: string,
	Selected: boolean,
}

export interface IPermissionsService {
	getRanchPlantingsPermissions(id: string): Promise<IRanchPermissions>,
	getRanchMemberPermissions(userId: string): Promise<IRanchUserPermissionWithWebAPIViewModel | CMError>,
	editRanchMemberPermissions(userId: string, permissions: IRanchFeatureEmptyPermissions[]): Promise<ISuccessResponse>,
	getRanchPermissionTypes(): IRanchPermissionTypes[],
	getRanchFeatureDefaultPermissions(): IRanchFeatureEmptyPermissions[],
	getRanchFeatureEmptyPermissions(): IRanchFeatureEmptyPermissions[],
}

export interface IUserRanchesViewModel {
	Id: number,
	Ranch_External_GUID: string,
	Name: string,
	ActivePlantings: number,
	UserIsRanchOwner: boolean,
	UserCanEditRanch: boolean,
	UserCanEditPlanting: boolean,
	UserCanExportData: boolean,
	CreatedByUserId: string,
	CreatedByFullName: string,
	RanchOwnerId: string,
	RanchOwnerFullName: string
}

export interface IRanchFavoritesJSON {
	Id: number,
	RanchId: number,
	UserId: string,
	Ranch: IRanchJSON
}

export interface IRanchJSON {
	Id: number,
	Name: string,
	Ranch_External_GUID: string,
	RanchAccessState: number,
	UserAccessState: number,
	ActivePlantings?: number,
	CreatedByFullName: string,
	RanchOwnerId: string,
	RanchOwnerFullName: string
}

export interface RanchExistsModel {
	Name?: string,
	Exists: boolean
}

export interface IRanchService {
	currentRanchLots: IRanchLotJSONModel[],
	updateActivePlantingCount(): void,
	getMyRanches(): Promise<IRanch[]>,
	clearRanchesCache(): void,
	addRanchToFavoritesList(ranchFavorite: ISubjectFavoriteResponse): void,
	getFavoriteRanches(override: boolean): Promise<IRanch[]>,
	getMissingSettings(ranchGUID: string): Promise<IRanchSettingsValidationStates>,
	exists(ranchGUID: string): Promise<RanchExistsModel>,
	getExcelYears(ranchGUID: string): Promise<number[]>,
	addRanchToFavorites(ranchId: number): Promise<IRanchFavoritesPostResponse>,
	removeRanchFromFavorites(favoriteId: number): Promise<CMError>,
	getActivePlantings(id: string): Promise<IPlanting[]>,
	getActivePlantingsWithFavorites(id: string, override: boolean): Promise<IPlanting[]>,
	getAllPlantings(ranchGuid: string): Promise<IPlanting[]>,
	getAllPlantingsWithFavorites(id: string, override: boolean): Promise<IPlanting[]>,
	getFavoritePlantings(id: string, override?: boolean): Promise<IPlanting[]>,
	findRanchByGuid(ranches: IRanch[], guid: string): IRanch,
	addPlantingToFavorties(id: number): Promise<ILotPlantingFavoritesJSON>,
	removePlantingFromFavorites(id: number): Promise<void>,
	getCreateRanchData(): IRanchBasicInfoViewModel,
	createRanch(data: RanchUpdateParams): Promise<RanchCreateResponse>,
	deleteRanch(ranchId: string): Promise<SuccessResponse>,
	getRanchBasicInfo(): Promise<IRanchBasicInfoViewModel>,
	getRanchUsers(): Promise<IRanchUserListItem[]>,
	getRanchLots(): Promise<IRanchLotModel[]>,
	getRanchWells(): Promise<IWell[]>,
	getAddRanchMembersList(): Promise<IUserSearchResult[]>,
	addRanchMember(id: string): Promise<void>,
	deleteRanchUser(userId: string, ranchGuid?: string, shouldPermissionProtect?: boolean): Promise<ISuccessResponse | CMError>,
	getAddRanchLotData(): Promise<ILotEdit>,
	getEditRanchLotData(id: number): Promise<ILotEdit>,
	editRanchBasicInfo(basicInfo: RanchUpdateParams): Promise<IRanchJSON>,
	updateCIMISOptions(p: RanchUpdateCIMISParams): Promise<void>,
	addRanchLot(lot: ILotEdit): Promise<IRanchLotUpdateModel>,
	editRanchLot(lot: ILotEdit): Promise<IRanchLotUpdateModel>,
	deleteRanchLot(lotId: number): Promise<Object>,
	getRanchWellEditData(id: number): Promise<Object>,
	addRanchWell(well: IRanchWellViewModel): Promise<void>,
	editRanchWell(id: number, well: IRanchWellViewModel): Promise<void>,
	deleteRanchWell(id: number): Promise<void>,
	getBasicRanchLots(id: string): Promise<IRanchLotJSONModel[]>,
	getLotsMinimal(ranchGuid: string, year?: number): Promise<IIdNamePair[]>,
	isUsed(ranchLotId: number, ranchGuid: string): Promise<boolean>,
	exportDataToExcel(name: string, year?: number, lotId?: number): Promise<boolean>,
	getIsNDependent(plantingId: number): boolean,
	getCalculatorInterface(plantingId: number): string,
	getGerminationDate(plantingId: number): string | Date,
	isPlantingPerennial(plantingId: number): boolean,
	plantingHasFloodEvents(plantingId: number): boolean,
}

export interface IRanchLotUpdateModel {
	Lot: IRanchLotJSON
}

export interface IRanchLotBasic {
	Id: number,
	Name: string
}

export interface IRanchLotJSON extends IRanchLotBasic {
	RanchId: number,
	Acres: number,
	ObstructionDepth?: number,
	CreateDT: string,
	LastUpdateDT: string,
	Deleted: boolean,
	Coordinates: string
}

export interface IRanchWellViewModel {
	Id: number,
	Name: string,
	ElectricalConductivity: number,
	NitrogenPPM: number
}

/**
 * C# counterpart
 */
export interface RanchLotBase {
	Name: string,
	Acres: number,
	Coordinates: string,
	ObstructionDepth?: number
}

/**
 * C# counterpart
 */
export interface RanchLotInternalUpdateModel extends RanchLotBase {
	SoilLayers: ISoilLayer[]
}
