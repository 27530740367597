<div class="bk-dialog">

    <button type="button"
        class="bk-dialog__close"
        (click)="close()">
        <span>&times;</span>
    </button>

    <h4 mat-dialog-title>
        {{plantingName}} -
        <span *ngIf="this.flowmeter.chartType === chartTypes.summary">
            {{'Flowmeter Data' | translate }}
        </span>
        <span *ngIf="this.flowmeter.chartType === chartTypes.detail">
            {{'Flowmeter Data Details' | translate }}
        </span>
    </h4>

    <div mat-dialog-content
        class="bk-modal__flowmeter-body">

        <div class="flot-chart dashboard-chart"
            [class.bk-flowmeter__loader-container]="loadingGraph">

            <div class="bk-chart" #highchartsContainer></div>

            <div class="flot-chart-content"
                id="flot-dashboard-chart"
                #hiddenHandle
                [hidden]="loadingGraph">
            </div>

            <div class="bk-loader"
                *ngIf="loadingGraph">
                {{'Loading' | translate }}...
            </div>
        </div>

        <button class="bk-button bk-flowmeter__button"
            *ngIf="this.flowmeter.chartType === chartTypes.detail"
            (click)="goBackToSummary()">

            <i class="fa fa-arrow-left bk-flowmeter__icon"
                aria-hidden="true">
            </i>
            Back to Summary
        </button>

        <ng-container *ngIf="this.flowmeter.chartType === chartTypes.summary">
            <flowmeter-summary-table
                (detailLoaded)="loadFlowmeterDetails($event)"
                [flowmeterData]="flowmeterSummary">
            </flowmeter-summary-table>
        </ng-container>

        <ng-container *ngIf="this.flowmeter.chartType !== chartTypes.summary">
            <flowmeter-details-table
                [flowmeterData]="flowmeterData">
            </flowmeter-details-table>
        </ng-container>
    </div>
    <div mat-dialog-actions align="end">

        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="openSettings()">
            {{ 'Settings' | translate }}
        </button>
    </div>
</div>

<ng-container *ngIf="plantingId">
    <input type="hidden" id="plantingId" [(ngModel)]="plantingId" />
    <input type="hidden" id="startDate" [(ngModel)]="startDate" />
    <input type="hidden" id="endDate" [(ngModel)]="endDate" />
</ng-container>
