import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { IWeatherAPIService, WeatherAPI } from './interfaces';

@Injectable()
export class WeatherAPIService implements IWeatherAPIService {
	private urls = {
		list: () => `/v2/weatherapis.json`
	}

	public apis: WeatherAPI[];

	constructor(private httpService: HttpService) { }

	public list(): Promise<WeatherAPI[]> {
		if (this.apis && this.apis.length > 0) {
			return of(this.apis).toPromise();
		}

		return this.httpService.get({
			url: this.urls.list(),
			isWebAPI: true,

			callback: function (json: WeatherAPI[]) {
				this.apis = json;
				return json;
			}
		});
	}
}
