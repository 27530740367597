<div class="app-container">
    <!-- HEADER -->
    <global-header></global-header>
    <!-- uncomment HTML block below if we want to display a global notification -->
    <!--
    <div *ngIf="persistentDatabase.isGlobalNotificationVisible"
        class="bk-notification-global">
        <i class="fa fa-close" (click)="dismissGlobalNotification()"></i>
        <h2>Donate to Sustain CropManage</h2>
        <p>
            We need your help to maintain and improve CropManage.
            We are constantly fixing bugs and adding new features.  Hosting CropManage on a professional cloud server and
            storing user data also has fixed costs.
            Through grants, UC Cooperative Extension has paid these expenses as well as the salary of a full-time professional
            software engineer who keeps CropManage running smoothly and adds new capabilities and features.
            While we will continue to seek out new grant opportunities, your donation can help offset the costs
            of operating CropManage and to assure that this valuable online tool is sustained into the future.
        </p>
        <p>If you have found CropManage to be a valuable service for your farming operation or company, please consider donating.
            Just <a href="{{ donationLink }}" target="_blank">click here</a> to donate.</p>
    </div>
    -->

    <router-outlet></router-outlet>

    <!-- FOOTER -->

    <div *ngIf="!isAuthenticated" 
        class="bk-footer bk-footer--hidden-mobile">
        <ul class="bk-footer__links">
            <li class="bk-footer__link"><a href="http://help.cropmanage.ucanr.edu/tutorials/" target="_blank">{{'Help' | translate }}</a></li>
            <li class="bk-footer__link"><a href="http://ucanr.edu/sites/anrstaff/Diversity/Affirmative_Action/Resources/Policy-related_downloads/" target="_blank">
                {{'Disclaimer' | translate }}
            </a></li>
            <li class="bk-footer__link"><a href="http://ucanr.edu/Accessibility/" target="_blank">{{'Accessibility' | translate }}</a></li>
            <li class="bk-footer__link"><a routerLink="/privacyPolicy">{{'Privacy' | translate }}</a></li>
            <li class="bk-footer__link"><a href="{{ donationLink }}" target="_blank">{{'Donate' | translate }}</a></li>
        </ul>
        <div class="bk-footer__logo-container">
            <div class="bk-footer__logo">
                <img alt="{{'research powered by University of California' | translate }}" src="assets/images/logos/powered-by-uoc-logo.svg" />
            </div>
        </div>
    </div>
</div>
