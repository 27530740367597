<div class="bk-login__container">
	<div class="bk-login">
		<form lass="form-horizontal">
			<div class="bk-login__logo">
				<img src="/assets/images/logos/cropmanage-logo-white.png">
			</div>
			<h2>Sign In</h2>
			<p class="bk-login__new-account-link">
				Don't have an account? <a routerLink="/register">Create a New Account</a>
			</p>
			<div class="bk-login__form-group">

				<label for="Email">Email</label>
				<input [(ngModel)]="loginCredentials.username"
					name="username"
					autocomplete="current-username"
					type="text"/>
				<div class="bk-login__error-text">
					<!-- display error here -->
				</div>
			</div>
			<div class="bk-login__form-group">
				<label for="Password">Password</label>
				<input [(ngModel)]="loginCredentials.password"
					name="password"
					autocomplete="current-password"
					[type]="passwordIsHidden ? 'password' : 'text'"/>
				<button mat-icon-button matSuffix
					class="bk-login__show-password"
					(click)="passwordIsHidden = !passwordIsHidden">
					<mat-icon>
						{{passwordIsHidden ? 'visibility_off' : 'visibility'}}
					</mat-icon>
				</button>
				<div *ngIf='loginFailed' 
					class="bk-login__error-text">
					<span class="field-validation-error bk-text--error">
						The email address or password is incorrect.
					</span>
				</div>
			</div>
			<div class="bk-login__form-group">
				<div class="bk-login__checkbox">
					<input id="RememberMe" name="RememberMe" type="checkbox" value="true"/>
					<input name="RememberMe" type="hidden" value="false">
					<label for="RememberMe">Remember me?</label>
				</div>
				<div class="bk-login__forgot">
					<a routerLink="/forgetPassword">Forgot password?</a>
				</div>
			</div>
			<button type="submit"
				class="bk-button bk-login__button bk-button--large btn btn-custom"
				(click)="login()">
				{{ (isProcessing ? 'Signing In...' : 'Sign In') | translate }}
				<img class='bk-button__loader' *ngIf='isProcessing' src='/assets/images/loader-20px.gif'/>
			</button>
			<!--
			<div class="bk-identity-signin-divider">
				<hr>
				<span>Or</span>
				<hr>
			</div>
			<div id="googleSignInBtn"></div>
			-->
		</form>
	</div>
</div>
		
		
