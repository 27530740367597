import { Component, OnInit, OnChanges, DoCheck } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import * as _ from 'underscore';

import { FertilizerModelService } from './service';
import { UpdateService } from '../../../../services/update.service';
import { FertilizerBaseDialog } from './fertilizer.dialog.base';
import { IFertilizer } from './interfaces';
import { FormGroup } from '@angular/forms';
import { eFertilizerFormulationTypes } from '../../../../interfaces/constants';

@Component({
	moduleId: module.id,
	selector: 'fertilizer-create-dialog',
	templateUrl: 'fertilizer.create.dialog.html',
	styleUrls: ['fertilizer.create.dialog.scss']
})

export class FertilizerCreateDialog extends FertilizerBaseDialog implements OnInit, DoCheck {

	public addStates: { value: boolean, display: string }[];
	public chooseFromList: boolean;
	public fertilizers: IFertilizer[];
	public areFertilizersSelected: boolean;
	public areThereFertilizers: boolean;
	public isFormValid = true;
	public isSaving: boolean;
	private isTouched = false;

	constructor(
		protected _dialog: MatDialog,
		public _dialogRef: MatDialogRef<FertilizerCreateDialog>,
		private service: FertilizerModelService,
		private updateService: UpdateService,
	) {
		super(_dialog, null);
	}

	ngOnInit(): void {
		this.chooseFromList = true;
		this.areFertilizersSelected = false;
		this.isSaving = false;

		this.addStates = [
			{ value: true, display: 'Choose from List' },
			{ value: false, display: 'Create a custom fertilizer' }
		];

		this._get();
	}

	ngDoCheck(): void {
		this.areFertilizersSelected = this._areFertilizersSelected();
		this.areThereFertilizers = this._isFertilizerListEmpty();
	}

	public close(): void {

		if (!this.isTouched) {
			this._dialogRef.close();
		} else {
			this._openKeepDiscardDialog();
		}
	}

	public addSelectedFertilizers(): void {
		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this.service.addBatch(this.fertilizers.filter(fertilizer => fertilizer.checked).map(fertilizer => fertilizer.Id))
		.then(() => {
			this.isSaving = false;
			this.updateService.setRanchSettingsUpdated('fertilizers');
			this._dialogRef.close();
		});
	}

	public create(): void {
		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this.service.add(this.fertilizer)
		.then(() => {
			this.isSaving = false;
			this.updateService.setRanchSettingsUpdated('fertilizers');
			this._dialogRef.close();
		})
	}

	/**
	 * Prevent or allow user to save based on form validation.
	 * If user touched the form, ask for keep/discard instead of immediately closing the dialog
	 *
	 * duplicate code
	 */
	public updateFormState(form: FormGroup): void {
		if (!form) {
			return;
		}

		this.isTouched = form.pristine ? false : true;
		this.isFormValid = form.invalid ? false : true;
	}

	private _isFertilizerListEmpty(): boolean {

		if (!this.fertilizers || this.fertilizers.length === 0) {
			return true;
		} else {
			return false;
		}
	}

	private _get(): void {

		this.service.getCreateModel().then(data => {
			this.fertilizer = data;
			this.fertilizer.FormulationTypeId = eFertilizerFormulationTypes.DRY;
		});

		this.service.listAvailable().then((fertilizers: IFertilizer[]) => {
			this.fertilizers = fertilizers;
		});
	}

	private _areFertilizersSelected(): boolean {
		let count: number;

		if (!this.fertilizers || this.fertilizers.length === 0) {
			return false;
		}

		count = this.fertilizers.filter(x => x.checked === true).length;

		if (count > 0) {
			return true;
		} else {
			return false;
		}
	}
}
