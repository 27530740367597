import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { ErrorService } from './error.service';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { TokenService } from './token.service';
import { Token } from './interface';
import { ReleaseVersion, VersionUpgradeCookieName } from './constants';
import { CMErrors, CMError, httpStatusCodes } from '../interfaces/interfaces';
import { WebAPIErrors } from '../classes/WebAPIErrors';
import { CookieService } from './cookie.service';
import { environment } from '../../environments/environment';

export interface HttpServiceGetOptions {
	url: string,
	searchParams?: HttpParams,
	callback?: Function,
	useLocalStorage?: boolean,
	isWebAPI?: boolean,
	shouldBypassServerErrorAlert?: boolean // setting this to true will suppress the server error popul
}

export interface HttpServicePostOptions {
	url: string,
	body: any,
	params?: HttpParams,
	callback?: Function,
	isWebAPI?: boolean,
	shouldBypassServerErrorAlert?: boolean, // setting this to true will suppress the server error popul
	GoogleJWTToken?: string, // Google Identity JWT
}

@Injectable()
export class HttpService {

	constructor(
		private http: HttpClient,
		private tokenService: TokenService,
		private errorService: ErrorService) { }

	public get(params: HttpServiceGetOptions): Promise<any> {

		let headers: HttpHeaders;
		let stored: string;
		let token: Token;
		let urlAbsolute: string;

		if (!params) {
			throw new Error('params is empty');
		}

		if (params.useLocalStorage) {

			stored = sessionStorage.getItem(params.url.toLowerCase());

			if (stored) {
				if (params.callback) {
					return of(params.callback(JSON.parse(stored))).toPromise();
				} else {
					return of(JSON.parse(stored)).toPromise();
				}
			}
		}

		if (params.isWebAPI) {
			token = this.tokenService.getToken();

			if (!token) {
				throw new Error('token is empty');
			}

			headers = new HttpHeaders({ // Disable caching. Otherwise, ajax calls via Angular are cached prevents views in IE11 from refreshing
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Authorization': 'Bearer ' + token.token,
				'X-Client-Version': ReleaseVersion
			});

			urlAbsolute = environment.APIEndpoint + params.url;
		} else {
			headers = new HttpHeaders({ // Disable caching. Otherwise, ajax calls via Angular are cached prevents views in IE11 from refreshing
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'X-Client-Version': ReleaseVersion
			});

			urlAbsolute = params.url;
		}

		if (!urlAbsolute) {
			return of(null).toPromise(); // return empty promise on fail
		} else {
			urlAbsolute = urlAbsolute.toLowerCase();
				// Visual C# oddity where upper case urls sometimes generated
				// different session cookies, at least locally, which resulted in authentication fail
		}

		return this.http
			.get(urlAbsolute,
				{
					headers: headers,
					params: params.searchParams,
				})
			.toPromise()
			.then(json => {

				let errors: WebAPIErrors;

				if (json === null || json === undefined) {
					this.handleError(params.url, 'Empty JSON response');
					return;
				}

				errors = new WebAPIErrors(json as CMErrors);

				if (errors.isCMError()) {
					if (errors.isError()) {
						console.warn(errors.error.message);
						this.handleError(params.url, errors.error.message);
						return;
					} else {
						json = errors.error; // clean up return object
					}
				}

				if (params.useLocalStorage) {
					try {
						sessionStorage.setItem(params.url, JSON.stringify(json));
					} catch (error) {
						// prevent the error from disrupting this method
						// setItem may trigger an QuotaExceededError due to storage
						// space issues
					}
				}

				if (params.callback) {
					return params.callback(json);
				}

				return json;
			})
			.catch((errorResponse: HttpErrorResponse) => {
				let errors: WebAPIErrors;

				if (errorResponse) {
					errors = new WebAPIErrors(errorResponse.error);
				}

				if (this._handleUpgrade(errorResponse)) {
					return;
				}

				if (params && params.shouldBypassServerErrorAlert) {
					if (params.callback) {
						return params.callback(errors.error);
					} else {
						return errors.error;
					}
				} else {
					if (errors && errors.errors && errors.errors.length > 0) {
						this.handleError(params.url, errors.errors[0].message);
					} else {
						this.handleError(params.url, 'Something went wrong.');
					}

					console.warn(errorResponse);
					return;
				}
			});
	}

	private preparePostHeaders(params: HttpServicePostOptions | HttpServiceGetOptions): HttpHeaders {
		let token: Token;
		let headers: HttpHeaders;

		if (!params) {
			throw new Error('params is empty');
		}

		if (params.isWebAPI) {
			token = this.tokenService.getToken();

			if (!token) {
				throw new Error('token is empty!');
			}

			headers = new HttpHeaders({ // Disable caching. Otherwise, ajax calls via Angular are cached prevents views in IE11 from refreshing
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Authorization': 'Bearer ' + token.token,
				'X-Client-Version': ReleaseVersion
			});
		} else {
			headers = new HttpHeaders({ // Disable caching. Otherwise, ajax calls via Angular are cached prevents views in IE11 from refreshing
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'X-Client-Version': ReleaseVersion
			});
		}

		return headers;
	}

	private prepareUrl(url: string, isWebAPI: boolean): string {

		if (!url) {
			throw new Error('url is empty');
		}

		if (isWebAPI) {
			url = environment.APIEndpoint + url;
		}

		url = url.toLowerCase();
		return url;
	}

	public post(params: HttpServicePostOptions): Promise<any> {
		let headers: HttpHeaders;

		if (!params) {
			throw new Error('params is empty');
		}

		headers = this.preparePostHeaders(params);
		params.url = this.prepareUrl(params.url, params.isWebAPI);

		if (params.GoogleJWTToken) {
			headers = headers.append('X-Google-JWT', params.GoogleJWTToken);
		}

		if (!params.body) {
			params.body = {};
		}

		return this.http
			.post(params.url, params.body, {
				headers: headers,
			})
			.toPromise()
			.then(json => {

				let errors: WebAPIErrors;

				if (json === null || json === undefined) { // allow for boolean and other responses
					return;
				}

				errors = new WebAPIErrors(json as CMErrors);

				if (errors.isCMError()) {
					if (errors.isError()) {
						console.warn(errors.error.message);
						this.handleError(params.url, errors.error.message);
						return errors.error;
					} else {
						json = errors.error; // clean up return object
					}
				}

				if (params.callback) {
					return params.callback(json);
				}

				return json;
			})
			.catch((error: HttpErrorResponse) => {
				let response: WebAPIErrors;

				if (this._handleUpgrade(error)) {
					return;
				}

				if (!error) {
					return;
				}

				response = new WebAPIErrors(error.error as CMErrors);

				if (params.shouldBypassServerErrorAlert) {

					if (params.callback) {
						return params.callback(response.error);
					} else {
						return response.error; // return the first error
					}
				} else {
					console.warn(error);

					if (response.error) {
						this.handleError(params.url, response.error.message);
					} else {
						this.handleError(params.url, error.message);
					}

					return;
				}
			});
	}

	public put(params: HttpServicePostOptions): Promise<any> {
		let headers: HttpHeaders;
		let options: {
			headers: HttpHeaders,
			params?: HttpParams
		};

		if (!params) {
			throw new Error('params is empty');
		}

		headers = this.preparePostHeaders(params);
		params.url = this.prepareUrl(params.url, params.isWebAPI);

		if (!params.body) {
			params.body = {};
		}

		options = {
			headers: headers
		};

		if (params.params) {
			options.params = params.params;
		}

		return this.http
			.put(params.url, params.body, options)
			.toPromise()
			.then(json => {
				return this._handlePostResponse(json, params);
			})
			.catch((error: HttpErrorResponse) => {
				if (this._handleUpgrade(error)) {
					return;
				}

				return this._handleErrorResponse(error, params);
			});
	}

	public async delete(params: HttpServiceGetOptions): Promise<any> {
		let headers: HttpHeaders;
		let json: any;

		if (!params) {
			throw new Error('params is empty');
		}

		headers = this.preparePostHeaders(params);
		params.url = this.prepareUrl(params.url, params.isWebAPI);

		try {
			json = await this.http
				.delete(params.url, { headers: headers, params: params.searchParams })
				.toPromise()
				.catch((error: HttpErrorResponse) => {
					if (this._handleUpgrade(error)) {
						return;
					}

					return this._handleErrorResponse(error, params);
				});

			return this._handlePostResponse(json, params);
		} catch (error) {
			return this._handleErrorResponse(error, params);
		}
	}

	private _handleErrorResponse(error: HttpErrorResponse, params: HttpServicePostOptions | HttpServiceGetOptions): CMError {
		let response: WebAPIErrors;

		if (error) {
			if (!error.error) {
				let internalErrors: CMError[] = [{
					code: httpStatusCodes.angularError,
					message: error.message
				}];

				response = new WebAPIErrors({ errors: internalErrors });
			} else {
				response = new WebAPIErrors(error.error as CMErrors);
			}
		}

		if (params && params.shouldBypassServerErrorAlert) {
			if (response) {
				return response.error; // return the first error
			} else {
				return null;
			}
		} else {
			console.warn(error);

			if (response && response.errors && response.errors.length > 0) {
				this.handleError(params.url, response.errors[0].message);
			} else {
				this.handleError(params.url, 'Something went wrong.');
			}

			return null;
		}
	}

	private _handlePostResponse(json: any, params: HttpServicePostOptions | HttpServiceGetOptions): any {
		let errors: WebAPIErrors;

		if (json === null || json === undefined) {
			// allow zero and boolean false responses to go through
			return null;
		}

		errors = new WebAPIErrors(json as CMErrors);

		if (errors.isCMError()) {
			if (errors.isError()) {
				console.warn(errors.error.message);
				this.handleError(params.url, errors.error.message);
				return null;
			} else {
				json = errors.error; // clean up return object
			}
		}

		if (params.callback) {
			return params.callback(json);
		}

		return json;
	}

	/**
	 * This method is called by external functions, so has public scope
	 * @param url
	 * @param message
	 */
	public handleError(url: string, message: string): void {
		this.errorService.handleHttpError(url, message);
	}

	/**
	 * return boolean result to prevent further error processing
	 * if page reloads
	 * @param errorResponse
	 */
	private _handleUpgrade(errorResponse: HttpErrorResponse): boolean {
		if (errorResponse.status === httpStatusCodes.UpgradeRequired) {
			CookieService.set(VersionUpgradeCookieName, 'yes', 1000000);
			window.location.reload();
			return true;
		} else {
			return false;
		}
	}
}
