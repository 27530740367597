import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TranslateService } from './components/localization/service';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { CommodityService } from './models/commodity-type/service';
import { PersistentDatabase } from './services/persistent-database';
import { CookieService } from './services/cookie.service';
import { TokenService } from './services/token.service';
import { NotificationService } from './services/notification.service';
import { VersionUpgradeCookieName, ReleaseVersion, DONATE_URL } from './services/constants';
import { eNotificationTypes } from './interfaces/constants';
import { MainMenuUpdateService } from './components/main-menu/main-menu-update.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	moduleId: module.id,
	selector: 'my-app',
	templateUrl: `app.component.html`,
	styleUrls: ['app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
	@ViewChild(MainMenuComponent, { static: true }) public mainMenuComponent: MainMenuComponent;

	public donationLink = DONATE_URL;
	public isAuthenticated: boolean;
	private _subscriptions$: Subject<boolean>;

	constructor(
		public translateService: TranslateService,
		public persistentDatabase: PersistentDatabase,
		private _notificationService: NotificationService,
		private _tokenService: TokenService,
		private _mainMenuUpdateService: MainMenuUpdateService,
		private _commodityService: CommodityService) { }

	ngOnInit() {
		this._subscriptions$ = new Subject();
		this.isAuthenticated = this._tokenService.isAuthenticated();

		if (this.isAuthenticated === false) {
			return;
		}

		if (CookieService.get(VersionUpgradeCookieName) === 'yes') {
			this._notificationService.generateNotifcation({
				type: eNotificationTypes.UPDATE,
				message: 'CropManage has been updated to version ' + ReleaseVersion
			});

			CookieService.delete(VersionUpgradeCookieName);
		}

		this.persistentDatabase.isGlobalNotificationVisible = this.persistentDatabase.GlobalNotificationIsVisible();
		this._commodityService.list();

		this._mainMenuUpdateService.userAuthStatusChanged$
			.pipe(takeUntil(this._subscriptions$)).subscribe(() => {

			this.isAuthenticated = this._tokenService.isAuthenticated();
		})
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public dismissGlobalNotification(): void {
		const expireDays: number = 3 * 30; // 3 months

		CookieService.set(this.persistentDatabase.globalNotificationCookieName, 'globalNotification', expireDays);
		this.persistentDatabase.isGlobalNotificationVisible = false;
	}
}
