import { inherits } from 'util';
import { CMCoordinates } from '../interfaces/views/map';
import { BOKA } from './number';

export class Coordinates implements CMCoordinates {
	public lat: number;
	public lng: number;

	// This does a very loose validation - checks that the input string
	// breaks into 2 numbers
	// This function should be in ValidationService
	public static validateCoordinateString(coords: string): boolean {
		let latLng: string[];
		if (!coords) {
			return false;
		}

		latLng = coords.split(',');

		if (latLng.length === 2 && Number(latLng[0]) && Number(latLng[0])) {
			return true;
		}

		return false;
	}

	public constructor(coords: string) {
		if (!Coordinates.validateCoordinateString(coords)) {
			// in some cases, the coordinate input field may be blank
			return;
		}

		this.lat = BOKA.NumberUtil.truncateDecimal(Number(coords.split(',')[0]));
		this.lng = BOKA.NumberUtil.truncateDecimal(Number(coords.split(',')[1]));
	}

	/**
	 *
	 * @returns concatenated string
	 */
	public toString() {
		return this.lat + ',' + this.lng;
	}
}
