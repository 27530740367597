<div class="bk-dialog bk-dialog__event">
    <h4 mat-dialog-title>
        {{'Recommendations Changed' | translate}}
    </h4>

    <div mat-dialog-content>
		<div class="bk-modal__remove-text">
			{{'Action has changed the recommendation for the following
			event(s)' | translate }}:
		</div>

		<table class="bk-event-table__affected-events">
			<tr *ngFor="let affectedEvent of affectedEvents">
				<td class="bk-event-table__affected-events-cell--date">
					{{affectedEvent.EventDate | date:'mediumDate'}}
				</td>
				<td class="bk-event-table__affected-events-cell">
					<div class="bk-planting__event-row__icon bk-button--black-icon">
						<svg-fertilizer></svg-fertilizer>
					</div>
					{{affectedEvent.Fertilization[0].FertilizerName}}
				</td>
			</tr>
		</table>
		<div class="bk-modal__remove-text">
			{{'Edit an event to get a new recommendation' | translate }}.
		</div>
    </div>

    <div mat-dialog-actions align="end">
		<button mat-button
			class="bk-button bk-button--medium"
			(click)="close()">
			{{'Close Window' | translate }}
		</button>
    </div>
</div>
