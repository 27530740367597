import { Component, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ICommodityTypesRanchJSON, ICommodityTypeDialogInput } from '../../../../models/commodity-type/interfaces';
import { ICropType } from '../../../../models/crop-type/interfaces';
import { CropTypeService } from '../../../../models/crop-type/service';
import { CommodityService } from '../../../../models/commodity-type/service';
import { CropType } from '../../../../models/crop-type/cropType';
import { UpdateService } from '../../../../services/update.service';
import { eRanchSettingNames } from '../../interfaces';
import { eDialogViews } from '../../../../interfaces/abstract';
import { FormComponent } from '../../../shared/form/form';
import { ObjectUtility } from '../../../../classes/objectUtility';
import { eCommodityTypes } from '../../../../interfaces/constants';
import { CookieService } from '../../../../services/cookie.service';
import { CASPNotNowCookieName } from '../../../../services/constants';
import { CaspService } from '../../../casp-dialog/casp.service';
import { CaspDialogComponent } from '../../../casp-dialog/casp-dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	moduleId: module.id,
	selector: 'commodity-type-dialog',
	templateUrl: 'dialog.html'
})

export class CommodityTypeDialog extends FormComponent implements OnInit, OnDestroy {

	public model: ICommodityTypesRanchJSON;
	public commodityTypeId: number;
	public commodityTypeName: string;
	public cropTypes: ICropType[];
	public isSaving: boolean;

	protected ranchGuid: string;
	protected plantingsCount: number;

	public dialogViews = eDialogViews;

	public view: eDialogViews;
	public viewCache: eDialogViews;
	public isFormValid: boolean;

	public formErrors = {
		'commodityTypeId': '',
	};

	public validationMessages = {
		'commodityTypeId': {
			'required': 'commodity type is required.',
		},
	};

	protected cropTypesOriginal: ICropType[];
	protected _subscriptions$: Subject<boolean>;

	constructor(
		protected _dialog: MatDialog,
		public dialogRef: MatDialogRef<CommodityTypeDialog>,
		@Inject(MAT_DIALOG_DATA) public data: ICommodityTypeDialogInput,
		protected cropTypeService: CropTypeService,
		protected commodityService: CommodityService,
		protected updateService: UpdateService,
		protected _caspService: CaspService
	) {

		super();

		this._subscriptions$ = new Subject();

		this.ranchGuid = data.ranchGuid;
		this.commodityTypeId = data.commodityTypeId;
		this.commodityTypeName = data.commodityTypeName;
		this.plantingsCount = data.plantingsCount;
		this.isFormValid = false;

		// custom click outside behavior
		this.dialogRef.disableClose = true;

		this.dialogRef.backdropClick().pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			// Close the dialog or show keep/discard
			if (ObjectUtility.isEqual(this.cropTypes, this.cropTypesOriginal)) {
				this.dialogRef.close();
			} else {
				this.view = eDialogViews.DISCARD_CHANGES;
			}
		});
	}

	ngOnInit(): void {
		this.isSaving = false;
		this.view = eDialogViews.UPDATE;
		this.viewCache = this.view;

		this.cropTypeService.getAllByRanch(this.ranchGuid,
			this.commodityTypeId).then(data => {

				this.cropTypes = data;
				this.validateCropTypes();

				this.cropTypesOriginal = ObjectUtility.copyArray(this.cropTypes);
			});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public close(): void {
		if (ObjectUtility.isEqual(this.cropTypes, this.cropTypesOriginal)) {
			this.dialogRef.close();
		} else {
			this.view = eDialogViews.DISCARD_CHANGES;
		}
	}

	public discard(): void {
		this.dialogRef.close();
	}

	public cancelConfirmation(): void {
		this.view = this.viewCache;
	}

	public save(): void {
		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		// assume if user can click the "save" button, form is valid
		this.commodityService.saveByRanch(this.ranchGuid, this.commodityTypeId,
			CropType.getIdsFromArray(this.cropTypes)).then(() => {
				this.isSaving = false;

				this.updateService.setRanchSettingsUpdated(
					eRanchSettingNames.commodityTypes);

				this.dialogRef.close();

				this._openCaspDialog();
			});
	}

	public delete(): void {
		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this.commodityService.deleteByRanch(this.ranchGuid, this.commodityTypeId).then(() => {
			this.isSaving = false;

			this.updateService.setRanchSettingsUpdated(
				eRanchSettingNames.commodityTypes);

			this.dialogRef.close();
		});
	}

	private _openCaspDialog(): void {
		if (this.commodityTypeId !== eCommodityTypes.ALMOND) {
			return;
		}

		if (CookieService.get(CASPNotNowCookieName)) {
			return;
		}
		// check cookies

		this._caspService.shouldPrompt(this.updateService.currentRanchId).then(response => {

			if (response === false) {
				return;
			}

			this._dialog.open(CaspDialogComponent, {
				disableClose: false,
				width: '800px',
				data: {
				}
			});
		});
	}

	public onCropTypeChange(): void {
		this.validateCropTypes();
	}

	/**
     * Validate crop types.
     * 1. At least one crop type must be selected
     * 2. A crop type with planting count > 0 should not be deselected
     */
	protected validateCropTypes(cropTypeId?: number): void {
		let count: number;
		let countAssociated: number;

		count = 0;
		countAssociated = 0;

		if (!this.cropTypes || this.cropTypes.length === 0) {
			this.isFormValid = false;
			return;
		}

		for (let cropType of this.cropTypes) {
			if (cropTypeId && cropType.Id === cropTypeId && cropType.IsSelected === false) {
				count++ // (clicked) is triggered before model change happens,
				// so we catch it here. even if selected, at this point, value would be false
			} else if (cropType.IsSelected) {
				count++;
			}

			if (cropType.PlantingsCount > 0 && cropType.IsSelected === false) {
				countAssociated++;
			}
		}

		if (count === 0) {
			this.isFormValid = false;
		} else if (countAssociated > 0) {
			this.isFormValid = false;
		} else {
			this.isFormValid = true;
		}
	}
}
