import { Injectable } from '@angular/core';
import { HttpService, HttpServicePostOptions } from './http.service';
import { HttpParams } from '@angular/common/http';
import { LoginCredentials, LogInResponse, Token, ResetPasswordResponse, IRecaptchaResponse, ILoginService } from './interface';
import { CMError } from '../interfaces/interfaces';

@Injectable()
export class LoginService implements ILoginService {

	private urls = {
		login: '/Token',
		forgetPassword: '/v2/account/forget-password.json',
		resetPassword: '/v2/account/reset-password.json',
		register: '/v2/account/register.json',
		resendConfirmation: '/v2/account/resend-confirmation.json',
		confirmEmail: '/v2/account/confirm-email.json',
		validateRecaptcha: '/v2/account/validate-captcha.json',
		temporaryKey: `/v2/account/temporary-key.json`,
		addToUCCERanch: '/v2/account/ucce-ranch-member.json'
	};

	constructor(private httpService: HttpService, ) { }

	public addToUCCE(): Promise<void> {
		return this.httpService.put({
			url: this.urls.addToUCCERanch,
			body: {},
			isWebAPI: true
		});
	}

	public login(loginCredentials: LoginCredentials): Promise<Token> {
		let params: HttpParams;
		let options: HttpServicePostOptions;

		params = new HttpParams().set('username', loginCredentials.username)
			.set('password', loginCredentials.password)
			.set('grant_type', 'password');

		options = {
			url: this.urls.login,
			body: params,
			callback: this.parseTokenFromResponse,
			isWebAPI: true,
			shouldBypassServerErrorAlert: true,
			GoogleJWTToken: loginCredentials.GoogleJWT
		}

		return this.httpService.post(options);
	}

	public getTemporaryKey(): Promise<string> {
		return this.httpService.get({
			url: this.urls.temporaryKey,
			isWebAPI: true,
		});
	}

	private parseTokenFromResponse(response: LogInResponse): Token {
		let token: Token;

		if (!response || !response.access_token || !response.expires_in) {
			return;
		}

		token = {
			token: response.access_token,
			expires: new Date(Date.now() + response.expires_in * 1000)
				// expires_in is in seconds, date() takes milliseconds
		}

		return token;
	}

	public forgetPassword(email: string): Promise<CMError> {
		let params: HttpParams;
		let options: HttpServicePostOptions;

		params = new HttpParams().set('email', email);

		options = {
			url: this.urls.forgetPassword,
			body: params,
			isWebAPI: true,
			shouldBypassServerErrorAlert: true
		}

		return this.httpService.post(options);
	}

	public resetPassword(email: string, password: string, confirmPassword: string, code: string): Promise<CMError | ResetPasswordResponse> {
		let params: HttpParams;
		let options: HttpServicePostOptions;

		params = new HttpParams().set('Email', email)
			.set('Password', password)
			.set('ConfirmPassword', confirmPassword)
			.set('Code', encodeURIComponent(code));

		options = {
			url: this.urls.resetPassword,
			body: params,
			isWebAPI: true,
			shouldBypassServerErrorAlert: true
		}

		return this.httpService.post(options);
	}

	public register(firstName: string, lastName: string, email: string, confirmEmail: string,
		password: string, confirmPassword: string): Promise<CMError | ResetPasswordResponse> {

		let params: HttpParams;
		let options: HttpServicePostOptions;

		params = new HttpParams().set('FirstName', firstName)
			.set('LastName', lastName)
			.set('Email', email)
			.set('ConfirmEmail', confirmEmail)
			.set('Password', password)
			.set('ConfirmPassword', confirmPassword);

		options = {
			url: this.urls.register,
			body: params,
			isWebAPI: true,
			shouldBypassServerErrorAlert: true
		}

		return this.httpService.post(options);
	}

	public resendConfirmation(email: string): Promise<CMError | ResetPasswordResponse> {
		let params: HttpParams;
		let options: HttpServicePostOptions;

		params = new HttpParams().set('email', email);

		options = {
			url: this.urls.resendConfirmation,
			body: params,
			isWebAPI: true,
			shouldBypassServerErrorAlert: true
		}

		return this.httpService.post(options);
	}

	public confirmEmail(userId: string, code: string): Promise<CMError | ResetPasswordResponse> {
		let params: HttpParams;
		let options: HttpServicePostOptions;

		params = new HttpParams().set('userId', userId)
			.set('code', code);

		options = {
			url: this.urls.confirmEmail,
			body: params,
			isWebAPI: true,
			shouldBypassServerErrorAlert: true
		}

		return this.httpService.post(options);
	}

	public validateRecaptcha(token: string): Promise<CMError | IRecaptchaResponse> {
		let options: HttpServicePostOptions;

		options = {
			url: this.urls.validateRecaptcha,
			body: {
				Token: token
			},
			isWebAPI: true,
			shouldBypassServerErrorAlert: true
		}

		return this.httpService.post(options);
	}
}
