import { IWeatherStation, IWeatherStationAdvanced } from './interfaces';

export class WeatherStation {

	public static copy(source: IWeatherStation, target: IWeatherStation) {
		target.Name = source.Name;
		target.External_Id = source.External_Id;
		target.County = source.County;
		target.NearestCity = source.NearestCity;
		target.RegionId = source.RegionId;
		target.RegionName = source.RegionName;
		target.Coordinates = source.Coordinates;
		target.UcIpmName = source.UcIpmName;
		target.WeatherAPI_Id = source.WeatherAPI_Id;
	}

	public static copyAdvanced(source: IWeatherStation, target: IWeatherStationAdvanced) {
		target.Name = source.Name;
		target.ExternalId = source.External_Id;
		target.County = source.County;
		target.NearestCity = source.NearestCity;
		target.RegionId = source.RegionId;
		target.RegionName = source.RegionName;
		target.Coordinates = source.Coordinates;
		target.UcIpmName = source.UcIpmName;
		target.WeatherAPI_Id = source.WeatherAPI_Id;
		target.API_Id = source.API_Id;
	}
}


