import { Injectable } from '@angular/core';

import { HttpService, HttpServiceGetOptions } from '../../services/http.service';
import { ICIMISDayDataService, CIMISDayDataViewModel, ICIMISDayDataViewModel } from './interfaces';
import { HttpParams } from '@angular/common/http';
import { DateUtility } from '../../classes/dateUtility';


@Injectable()
export class CIMISDayDataService implements ICIMISDayDataService {

	private urls = {
		get: (stationId: number) => `/v2/cimis-day-data/${stationId}.json`
	}

	constructor(private httpService: HttpService,
	) { }

	public async get(stationId: number, start: Date, end: Date): Promise<ICIMISDayDataViewModel[]> {

		let options: HttpServiceGetOptions;
		let params: HttpParams;
		let result: ICIMISDayDataViewModel[];

		params = new HttpParams()
			.set('start', DateUtility.ConvertToUTC(start).toISOString())
			.set('end', DateUtility.ConvertToUTC(end).toISOString());

		options = {
			url: this.urls.get(stationId),
			searchParams: params,
			isWebAPI: true,

			callback: (response: CIMISDayDataViewModel[]): ICIMISDayDataViewModel[] => {
				let r: ICIMISDayDataViewModel[];

				r = [];

				for (let item of response) {
					r.push({
						Date: DateUtility.DotNetToDate(item.Date),
						ET: item.ET,
						Rainfall: item.Rainfall,
						Source: item.Source
					})
				}

				return r;
			}
		}

		result = await this.httpService.get(options);

		return result;
	}
}
