import { Injectable } from '@angular/core';


import { HttpServiceGetOptions, HttpServicePostOptions } from '../../services/http.service';
import { ModelService } from '../../services/model.service';

import { DateUtility } from '../../classes/dateUtility';

import { IIrrigationMethod } from '../../models/irrigation-method/interfaces';

import {
	IIrrigationRecommendedNContribution, IIrrigationRecommendationSummaryModel,
	IIrrigationEventEditResponse,
	IIrrigationRecommendationSummaryModelJSON,
	IIrrigationEventEditJSON,
	IIrrigationRecommendedNContributionJSON,
	IAdditionalWaterModel,
	IIrrigationEventService,
	IAverageDeficit,
	SimpleSingleCallIrrigationRecommendationModel,
	EvaporationRecordSimple,
	EvaporationRecordJSON
} from './interfaces';
import { IrrigationEvent } from './irrigationEvent';
import { IEventPostResponseJSON } from '../../models/event/interfaces';
import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { EventResponse } from '../../models/event/eventResponse';
import { IrrigationEventUpdateParams } from './irrigationEventUpdateParams';
import { IrrigationEventUpdateParamsInternal } from './irrigationEventUpdateParamsInternal';

@Injectable()
export class IrrigationService extends ModelService implements IIrrigationEventService {

	private urls = {
		createForm: (plantingId: number) => `/v2/plantings/${plantingId}/irrigation-events/form.json`,
		create: (plantingId: number) => `/v3/plantings/${plantingId}/irrigation-events.json`,
		delete: (id: number) => `/v2/irrigation-events/${id}.json`,
		edit: (id: number) => `/v3/irrigation-events/${id}/internal.json`,
		updateDateOnly: (id: number) => `/v3/irrigation-events/${id}/date-only.json`,
		getRecency: (plantingId: number) => `/v2/plantings/${plantingId}/irrigation-events/recency.json`,
		getAverageDeficit: (plantingId: number) => `/v0/plantings/${plantingId}/average-deficit.json`,
		countEventsOnDate: (plantingId: number) => `/v2/plantings/${plantingId}/irrigation-events/count-events-on-date.json`,
		listByPlantingId: (plantingId: number) => `/v2/plantings/${plantingId}/irrigation-events/details.json`,
		show: (eventId: number) => `/v2/irrigation-events/${eventId}.json`,
		recommendation: {
			seasonET: () => `/v2/season-et.json`,
			get: (plantingId: number) =>  `/v2/plantings/${plantingId}/irrigation-events/recommendations.json`,
			intervalSummary: (id: number) => `/v2/irrigation-events/${id}/recommendations-interval-summary.json`,
			summary: (plantingId: number) => `/v2/plantings/${plantingId}/irrigation-events/recommendation-summary.json`
		}
	}

	public irrigationMethods: IIrrigationMethod[];

	public getAverageDeficit(plantingId: number, targetDate: Date, eventId?: number): Promise<IAverageDeficit> {
		let params: HttpParams;
		let options: HttpServiceGetOptions;
		let utcDate: Date;

		utcDate = DateUtility.ConvertToUTC(targetDate);

		if (!utcDate) {
			return null;
		}

		params = new HttpParams()
			.set('targetDate', utcDate.toISOString());

		eventId = eventId ? eventId : 0;

		params = params.set('eventId', eventId.toString());

		options = {
			url: this.urls.getAverageDeficit(plantingId),
			searchParams: params,
			isWebAPI: true,
		}

		return this.httpService.get(options);
	}

	public getRecency(id: number, eventDate: Date, plantingId: number): Promise<{ RecentIrrigationEventExists: boolean }> {
		let options: HttpServiceGetOptions;
		let params: HttpParams;

		if (!eventDate || !DateUtility.ConvertToUTC(eventDate)) {
			return of(null).toPromise();
		}

		if (!id) {
			return of(null).toPromise();
		}

		params = new HttpParams()
			.set('eventId', id.toString())
			.set('eventDate', DateUtility.ConvertToUTC(eventDate).toISOString());

		options = {
			url: this.urls.getRecency(plantingId),
			searchParams: params,
			isWebAPI: true
		}

		return this.httpService.get(options);
	}

	/**
	 * Method used to prevent accidental creation of multiple irrigation events
	 * on the same date.
	 * @param plantingId
	 * @param date
	 * @returns true if there are other irrigation events on this date. False otherwise
	 */
	public countDuplicatesOnDate(plantingId: number, date: Date): Promise<number> {
		let params: HttpParams;

		params = new HttpParams()
			.set('targetDate', date.toISOString());

		return this.httpService.get({
			url: this.urls.countEventsOnDate(plantingId),
			searchParams: params,
			isWebAPI: true
		});
	}

	public getCreateModel(plantingId: number): Promise<IIrrigationEventEditResponse> {

		return this.get(this.urls.createForm(plantingId), (data: IIrrigationEventEditJSON): IIrrigationEventEditResponse => {
			if (data && data.IrrigationMethods) {
				this.irrigationMethods = data.IrrigationMethods;
			}

			return IrrigationEvent.convertEditJSONtoResponse(data);
		});
	}

	public getIrrigationIntervalSummary(id: number): Promise<IIrrigationRecommendationSummaryModel> {

		return this.get(this.urls.recommendation.intervalSummary(id), (data: IIrrigationRecommendationSummaryModelJSON) => {
			let result: IIrrigationRecommendationSummaryModel;

			result = IrrigationEvent.convertRecommendationSummaryJSON(data);

			return result;
		});
	}

	public getRecommendationSummary(plantingId: number, eventDate: Date, irrigationMethodId: number,
		eventId: number, customDeficit: number):
		Promise<IIrrigationRecommendationSummaryModel> {

		let params: HttpParams;

		if (!irrigationMethodId) {
			return of(null).toPromise();
		}

		if (!eventDate || !DateUtility.ConvertToUTC(eventDate)) {
			return of(null).toPromise();
		}

		params = new HttpParams()
			.set('eventDate', DateUtility.ConvertToUTC(eventDate).toISOString())
			.set('irrigationMethodId', irrigationMethodId.toString());

		if (eventId) {
			params = params.set('eventId', eventId.toString());
		}

		if (customDeficit !== null) {
			params = params.set('customDeficit', customDeficit.toString());
		}

		return this.get(this.urls.recommendation.summary(plantingId),
			(data: IIrrigationRecommendationSummaryModelJSON): IIrrigationRecommendationSummaryModel => {

				let result: IIrrigationRecommendationSummaryModel;

				result = IrrigationEvent.convertRecommendationSummaryJSON(data);

				return result;

			}, params
		) as Promise<IIrrigationRecommendationSummaryModel>;
	}

	public getEditModel(id: number): Promise<IIrrigationEventEditResponse> {

		return this.get(this.urls.show(id),
			(data: IIrrigationEventEditJSON): IIrrigationEventEditResponse => {

				let result: IIrrigationEventEditResponse;

				result = IrrigationEvent.convertEditJSONtoResponse(data);

				if (result && result.IrrigationMethods) {
					this.irrigationMethods = result.IrrigationMethods;
				}

				return result;

			}
		);
	}

	public getSeasonET(event: SimpleSingleCallIrrigationRecommendationModel): Promise<EvaporationRecordSimple[]> {
		return this.post(this.urls.recommendation.seasonET(),
			(data: EvaporationRecordJSON[]): EvaporationRecordSimple[] => {
				return this._convertEvaporationRecord(data);
			}, event);
	}

	private _convertEvaporationRecord(records: EvaporationRecordJSON[]): EvaporationRecordSimple[]  {
		let result: EvaporationRecordSimple[];

		if (!records || records.length === 0) {
			return [];
		}

		result = [];

		records.forEach(x => {
			result.push({
				Date: new Date(x.Date),
				ETo: x.ETo,
				ETc: x.ETo * x.Kc,
				Rain: x.Rain,
				Applied: x.Applied
			});
		});

		return result;
	}

	// change for Alfafla
	public create(plantingId: number, event: IrrigationEventUpdateParams): Promise<IEventPostResponseJSON> {

		if (!event) {
			throw new Error('IrrigationService: Create() - event is empty');
		}

		if (!event.EventDate) {
			throw new Error('IrrigationService: Create() - event.EventDate is missing');
		}

		return this.post(this.urls.create(plantingId),
			(data: IEventPostResponseJSON) => {
				return data
			}, event);
	}

	public update(event: IIrrigationEventEditResponse): Promise<EventResponse> {

		let params: IrrigationEventUpdateParamsInternal;

		params = new IrrigationEventUpdateParamsInternal(event);

		return this.put(this.urls.edit(event.Id),
			(response: IEventPostResponseJSON): EventResponse => {
				return new EventResponse(response);
			}, params);
	}

	public updateDateOnly(eventId: number, eventDate: Date): Promise<EventResponse> {
		let params: HttpParams;
		let options: HttpServicePostOptions;

		params = new HttpParams().set('eventDate', DateUtility.ConvertToUTC(eventDate).toISOString());

		options = {
			url: this.urls.updateDateOnly(eventId),
			params: params,
			body: null,
			isWebAPI: true,

			callback: (response: IEventPostResponseJSON): EventResponse => {
				return new EventResponse(response);
			}
		};

		return this.httpService.put(options);
	}

	public delete(id: number): Promise<IEventPostResponseJSON> {

		return this.deleteItem(this.urls.delete(id), (data: IEventPostResponseJSON) => {
			return data;
		}, null);
	}

	public getAdditionalWaterData(id: number): Promise<IAdditionalWaterModel[]> {

		if (!id) {
			throw new Error('IrrigationService.getAdditionalWaterData() - id is null');
		}

		return this.get(this.urls.listByPlantingId(id),
			(json: IAdditionalWaterModel[]): IAdditionalWaterModel[] => {

				if (json) {
					return json;
				} else {
					return new Array();
				}
			}
		);
	}

	/**
	 * Alternate irrigation recommendation method, for N Contribution water
	 *
	 * @param fertilizationEventId
	 * @param startDate
	 * @param irrigationMethodId
	 */
	public getNContributionRecommendation(plantingId: number, startDate: Date, irrigationMethodId: number = null,
		daysToNextFertilization: number = null, fertilizationsPerMonth: number = null): Promise<IIrrigationRecommendedNContribution> {

		let params: HttpParams;

		if (!startDate) {
			throw new Error('startDate is empty');
		}

		if (!plantingId) {
			throw new Error('plantingId is null');
		}

		params = new HttpParams()
			.set('startDate', DateUtility.ConvertToUTC(startDate).toISOString());

		if (irrigationMethodId) {
			params = params.set('irrigationMethodId', irrigationMethodId.toString());
		}

		if (daysToNextFertilization) {
			params = params.set('daysToNextFertilization', daysToNextFertilization.toString());
		}

		if (fertilizationsPerMonth) {
			params = params.set('fertilizationsPerMonth', fertilizationsPerMonth.toString());
		}

		return this.get(this.urls.recommendation.get(plantingId), (r: IIrrigationRecommendedNContributionJSON):
			IIrrigationRecommendedNContribution => {

			let result: IIrrigationRecommendedNContribution;

			if (!r) {

				return null;
			} else {
				result = {
					irrigationAmountRecommended: r.irrigationAmountRecommended,
					LastIrrigationMethod: r.LastIrrigationMethod,
					Success: r.Success,
					ratio: r.ratio,
					PreviousIrrigationEventDate: DateUtility.DotNetToDate(r.PreviousIrrigationEventDate)
				};

				return result;
			}
		}, params);
	}
}
