import { Component, OnInit, Input, EventEmitter, DoCheck, Output } from '@angular/core';
import { RanchFertilizerViewModel, NutrientTotalValidationErrors } from '../interfaces';
import { FormComponent } from '../../../../shared/form/form';
import { eFertilizerFormulationTypes } from '../../../../../interfaces/constants';
import { FormGroup } from '@angular/forms';
import { Fertilizer } from '../fertilizer.class';

@Component({
	moduleId: module.id,
	selector: 'fertilizer-edit',
	templateUrl: 'edit.html',
	styleUrls: ['edit.scss']
})

export class FertilizerEditComponent extends FormComponent implements OnInit, DoCheck {

	@Input()
	fertilizer: RanchFertilizerViewModel;

	@Output()
	form: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

	public formulationTypes = eFertilizerFormulationTypes;

	public nutrientTotalPercentage: {
		total: number,
		validationError: NutrientTotalValidationErrors
	};

	public validationErrors = NutrientTotalValidationErrors;

	constructor() {
		super();

		this.nutrientTotalPercentage = {
			total: 0,
			validationError: null
		};
	}

	ngOnInit(): void {
		this.formErrors = {
			'Name': '',
			'PoundsPerGallon': '',
		}

		this.validationMessages = {
			'Name': {
				'required': 'field is required.',
			},
			'PoundsPerGallon': {
				'required': 'lbs/gallon is required.',
				'greaterThanNum': 'lbs/gallon must be greater than zero.'
			},
		}
	}

	ngDoCheck(): void {
		if (!this.nutrientTotalPercentage) {
			return;
		}

		if (!this.f) {
			return;
		}

		this.nutrientTotalPercentage.total = Fertilizer.calculateTotalPercentage(this.fertilizer);

		this.nutrientTotalPercentage.validationError =
			Fertilizer.validateNutrientTotal(this.nutrientTotalPercentage.total);

		if (this.nutrientTotalPercentage.validationError) {
			this.f.form.setErrors({'total': false});
		}

		if (this.f && this.f.form) {
			this.form.emit(this.f.form);
		}
	}
}
