<div class="bk-dialog">

	<h4 mat-dialog-title>
		{{'Changes not saved' | translate }}
	</h4>

	<div mat-dialog-content>
		<p>
			{{'The changes you made were not saved. Discard changes?' | translate }}
		</p>
	</div>

	<div mat-dialog-actions align="end">

		<button class="bk-button bk-button--medium bk-button--text-only"
			(click)="keep()">
			{{'Keep Editing' | translate }}
		</button>
		<button class="bk-button bk-button--medium"
			(click)="discard()">
			{{'Discard' | translate }}
		</button>
	</div>
</div>