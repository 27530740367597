import { HighChartsService } from '../../classes/highCharts.service';

let highChartExportOptions: IExportingOptions;

/**
 * Extending HighCharts.ExportingOptions to add options
 * not in the type definition but is officially supported,
 * like CSV export options, which needs to be non-empty
 * for the CSV export to work
 */
export interface IExportingOptions extends Highcharts.ExportingOptions {
	csv?: {
		columnHeaderFormatter?: null,
		dateFormat?: string,
		decimalPoint?: any,
		itemDelimiter?: any,
		lineDelimiter?: string
	}
}

highChartExportOptions = {
	csv: {
		itemDelimiter: ',',
		lineDelimiter: '\n'
	},
	buttons: {
		contextButton: {
			menuItems: [
				{
					text: 'Export to PDF',
					onclick: function() {
						this.exportChart({
							width: null,
							type: 'application/pdf'
						});
					}
				},
				{
					text: 'Print your chart',
					onclick: function() {
						this.print();
					},
				},
				{
					text: 'Export to CSV',
					onclick: function() {
						this.downloadCSV();
					}
				}
			]
		}
	}
};

export default highChartExportOptions;
