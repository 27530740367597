import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { RanchService } from '../ranch-settings/service';
import { UpdateService } from '../../services/update.service';

import { EventGroup } from '../../models/event/event';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TissueSampleEvent, eEventDialogViews } from './tissueSampleEvent';
import { TissueSampleEventService } from './service';
import { CropStageService } from '../../models/crop-stage/cropStage.service';
import { IIdNamePair } from '../planting-settings/interfaces';

import { IncomingJSON, TISSUE_SAMPLE_LOCATION_OTHER, eNutrientTypes, eMeasureUnitTypes, TissueSampleEventUpdateParams } from './interfaces';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { EventsType } from '../../interfaces/constants';
import * as moment from 'moment';

export interface  INutrientSet {
	macro: IncomingJSON.TissueSampleNutrientJSON[],
	micro: IncomingJSON.TissueSampleNutrientJSON[]
}

export type TissueSampleDialogFields = 'Id' | 'EventDate' | 'CropStageId' |
	'LocationId' | 'LocationDetails' | 'Nutrients';

@Component({
	moduleId: module.id,
	selector: 'tissue-sample-event',
	templateUrl: 'tissue-sample-event.component.html',
	styleUrls: ['tissue-sample-event.component.scss']
})

export class TissueSampleEventComponent implements OnInit, OnDestroy {

	public cropStages: IIdNamePair[];
	public eventViews = eEventDialogViews;
	public form: FormGroup;
	public id: number;
	public isSaving = false; // button loadeer

	public lastUpdated: {
		user: string,
		timestamp: string
	};

	public locationOtherId: number; // external ID of "other" location field
	public locations: IncomingJSON.ITissueSampleLocation[];
	public maxDate: Date; // maximum valid date for EventDate
	public measureUnitTypes = eMeasureUnitTypes;
	public nutrients: INutrientSet;
	public panelOpenState = false;
	public view: eEventDialogViews = eEventDialogViews.FORM;

	private _commodityTypeId: number;
	private _dateDefault: Date;
	private _eventDateOriginal: Date;
	private _plantingId: number;
	private _subscriptions$: Subject<boolean>;

	public get f(): { [key in TissueSampleDialogFields]: AbstractControl } {
		return this.form.controls as { [key in TissueSampleDialogFields]: AbstractControl };
	}

	constructor(
		private _cropStageService: CropStageService,
		@Inject(MAT_DIALOG_DATA) private _data: {
			plantingId: number,
			id?: number,
			commodityTypeId: number,
			dateDefault?: Date,
			harvestDate: Date,
		},

		public _dialogRef: MatDialogRef<TissueSampleEventComponent>,
		private _fb: FormBuilder,
		private _ranchService: RanchService,
		private _service: TissueSampleEventService,
		private _updateService: UpdateService,
		) {
	}

	ngOnInit(): void {

		if (this._data) {
			this.id = this._data.id;
			this._commodityTypeId = this._data.commodityTypeId;
			this._plantingId = this._data.plantingId;
			this._dateDefault = this._data.dateDefault;
		}

		this._subscriptions$ = new Subject();
		this._initializeForm();

		this.nutrients = {
			micro: null,
			macro: null
		};

		this._service.locationList().then(response => {
			let location: IncomingJSON.ITissueSampleLocation;

			this.locations = response;

			if (!this.locations || this.locations.length === 0) {
				return;
			}

			location = this.locations.filter(x => x.ExternalID === TISSUE_SAMPLE_LOCATION_OTHER)[0];

			if (location) {
				this.locationOtherId = location.Id;
			}
		});

		if (this.id) {
			this._service.get(this.id)
				.then(response => {
					if (!response) {
						return;
					}

					this._eventDateOriginal = response.EventDate;

					this.lastUpdated = {
						user: response.LastUpdatedUser,
						timestamp: response.LastUpdatedTimestamp
					}

					this._organizeNutrients(response.TissueSampleNutrients);
					this._populateForm(response, this.nutrients);
				});
		} else {
			if (this._dateDefault) {
				this.f.EventDate.setValue(this._dateDefault);
			} else {
				this.f.EventDate.setValue(null);
			}

			this._service.nutrientList().then(response => {
				let nutrients: IncomingJSON.TissueSampleNutrientJSON[];

				nutrients = this._convertNutrients(response);

				// sending actual time messes with event list
				this._organizeNutrients(nutrients);
				this._populateForm(null, this.nutrients);
			});
		}

		this._cropStageService.list(this._commodityTypeId).then(res => {
			this.cropStages = res;
		});

		this._dialogRef.backdropClick().pipe(takeUntil(this._subscriptions$)).subscribe((e: MouseEvent) => {
			this.cancel();
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public cancel(): void {

		if (this.form.touched) {
			this.view = eEventDialogViews.DISCARD_CHANGES;
			return;
		}

		this.close();
	}

	public getNutrientUnit(nutrientControl: AbstractControl): string {
		let id: number;

		if (!nutrientControl || !nutrientControl.get('MeasurementUnitId')) {
			return null;
		}

		id = nutrientControl.get('MeasurementUnitId').value as number;

		switch (id) {
			case this.measureUnitTypes.PPM:
				return 'ppm';
			case this.measureUnitTypes.PERCENT:
				return '%';
		}
	}

	public close(): void {
		this._dialogRef.close();
		this._updateService.setEventDialogClosed();
	}

	public onDelete(): void {
		this.view = this.eventViews.DELETE_CONFIRMATION;
	}

	public delete(): void {
		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this._service.delete(this.f.Id.value, this._plantingId)
			.then(() => {
				this.isSaving = false;

				this._ranchService.plantings.deleteEventInPlantings(this.f.EventDate.value,
					this._plantingId, EventsType.TISSUE_SAMPLE, this.f.Id.value);

				this._updateService.setPlantingsUpdated(this._updateService.currentRanchId, new Date());
				this.close();
			});
	}

	public revert(): void {
		this.view = eEventDialogViews.FORM;
	}

	public save(): void {
		if (this.form.invalid) {
			return;
		}

		if (this.f.Id.value) {
			this._update();
		} else {
			this._create();
		}
	}

	private _compareNutrientNames(a: IncomingJSON.TissueSampleNutrientJSON,
		b: IncomingJSON.TissueSampleNutrientJSON): number {

		if (a.Nutrient.Name > b.Nutrient.Name) {
			return 1;
		} else if (a.Nutrient.Name < b.Nutrient.Name) {
			return -1;
		}
	}

	/***
	 * Convert form to TissueSampleEventUpdateParams
	 */
	private _convertFormToParams(): TissueSampleEventUpdateParams {
		let result: TissueSampleEventUpdateParams;
		let model: TissueSampleEvent;
		let macro: IncomingJSON.TissueSampleNutrientJSON[];
		let micro: IncomingJSON.TissueSampleNutrientJSON[];
		let nutrients: IncomingJSON.TissueSampleNutrientJSON[];

		macro = (this.f.Nutrients as FormGroup).get('macro').value;
		micro = (this.f.Nutrients as FormGroup).get('micro').value;
		nutrients = micro.concat(macro);

		model = this.form.value;

		result = {
			EventDate: model.EventDate,
			LocationId: model.LocationId,
			LocationDetails: model.LocationDetails,
			CropStageId: model.CropStageId,
			Notes: model.Notes,
			TissueSampleNutrients: []
		};

		result.TissueSampleNutrients = [];

		nutrients.forEach((x) => {
			result.TissueSampleNutrients.push({
				Id: x.Id,
				NutrientId: x.NutrientId,
				MeasurementUnitId: x.MeasurementUnitId,
				Value: x.Value
			});
		});

		return result;
	}

	private _convertNutrients(nutrients: IncomingJSON.TissueSampleNutrient[]):
		IncomingJSON.TissueSampleNutrientJSON[] {

		let results: IncomingJSON.TissueSampleNutrientJSON[];

		results = [];

		if (!nutrients || nutrients.length === 0) {
			return null;
		}

		for (let nutrient of nutrients) {
			let item: IncomingJSON.TissueSampleNutrientJSON;

			if (!nutrient.Nutrient) {
				continue;
			}

			item = {
				Id: nutrient.Id,
				EventId: nutrient.EventId,
				Nutrient: {
					Id: nutrient.Nutrient.Id,
					Name: nutrient.Nutrient.Name,
					Symbol: nutrient.Nutrient.Symbol,
					MeasureUnitTypeId: nutrient.Nutrient.MeasureUnitTypeId,
					NutrientTypeId: nutrient.Nutrient.NutrientTypeId,
					Name_Alt: null,
					MeasurementUnit: null,
					Percentage: null
				},
				NutrientId: nutrient.NutrientId,
				MeasurementUnitId: nutrient.MeasurementUnitId,
				Value: nutrient.Value
			}

			results.push(item);
		}

		return results;
	}

	private _create(): void {
		let model: TissueSampleEventUpdateParams;

		model = this._convertFormToParams();

		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this._service.create(this._plantingId, model)
		.then((response) => {

			let eventGroup: EventGroup;

			this.isSaving = false;

			if (!response || !response.Events || response.Events.length === 0) {
				return;
			}

			eventGroup = response.Events[0];
			eventGroup.eventType = EventsType.TISSUE_SAMPLE;

			this._ranchService.plantings.addEventToPlantings(eventGroup, this._plantingId);
			this._updateService.setPlantingsUpdated(this._updateService.currentRanchId, new Date());
			this.close();

			return;

		});
	}

	private _initializeForm(): void {
		this.form = this._fb.group({
			Id: [null],
			EventDate: [null, Validators.required],
			CropStageId: [null],
			LocationId: [null],
			LocationDetails: [null],
			Nutrients: this._fb.group({
				macro: this._fb.array([]),
				micro: this._fb.array([])
			})
		});

		this.maxDate = moment(this._data.harvestDate).subtract(1, 'days').toDate();
	}

	/**
	 * Organize nutrients into micro/macro nutrients, and then sort each set alphabetically
	 */
	private _organizeNutrients(nutrients: IncomingJSON.TissueSampleNutrientJSON[]): void {

		if (!nutrients || nutrients.length === 0) {
			return;
		}

		this.nutrients.micro = nutrients.filter(x => x.Nutrient &&
			x.Nutrient.NutrientTypeId === eNutrientTypes.MICRO);

		this.nutrients.macro = nutrients.filter(x => x.Nutrient &&
			x.Nutrient.NutrientTypeId !== eNutrientTypes.MICRO);

		this.nutrients.micro.sort(this._compareNutrientNames);
		this.nutrients.macro.sort(this._compareNutrientNames);
	}

	private _populateForm(model: TissueSampleEvent, nutrientSet: INutrientSet): void {
		if (!this.form) {
			return;
		}

		if (model) {
			this.f.Id.setValue(model.Id);
			this.f.EventDate.setValue(model.EventDate);
			this.f.CropStageId.setValue(model.CropStageId);
			this.f.LocationId.setValue(model.LocationId);
			this.f.LocationDetails.setValue(model.LocationDetails);
		}

		this._populateNutrients(nutrientSet.micro, 'micro');
		this._populateNutrients(nutrientSet.macro, 'macro');
	}

	private _populateNutrients(nutrients: IncomingJSON.TissueSampleNutrientJSON[], key: string): void {
		if (!nutrients || nutrients.length === 0) {
			return;
		}

		for (let nutrient of nutrients) {
			let g: FormGroup;

			g = this._fb.group({
				Id: [nutrient.Id],
				NutrientId: [nutrient.Nutrient.Id],
				EventId: [nutrient.EventId],
				Value: [nutrient.Value],
				Name: [nutrient.Nutrient.Name],
				MeasurementUnitId: [nutrient.Nutrient.MeasureUnitTypeId]
			});

			((this.f.Nutrients as FormGroup).get(key) as FormArray).push(g);
		}
	}

	private _update(): void {
		let model: TissueSampleEventUpdateParams;

		model = this._convertFormToParams();

		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this._service.update(this.f.Id.value, model, this._plantingId)
		.then((response) => {

			let eventGroup: EventGroup;

			this.isSaving = false;

			if (!response || !response.Events || response.Events.length === 0) {
				return;
			}

			eventGroup = response.Events[0];

			eventGroup.eventType = EventsType.TISSUE_SAMPLE;
			eventGroup.originalEventDate = this._eventDateOriginal;

			this._ranchService.plantings.addEventToPlantings(eventGroup, this._plantingId, this.f.Id.value);
			this._updateService.setPlantingsUpdated(this._updateService.currentRanchId, new Date());
			this.close();

			return;
		});
	}
}
