
   <svg
   version="1.1"
   id="svg46"
   width="24"
   height="24"
   viewBox="0 0 24 24">
  <g
     id="fertilizer" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path
       style="stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="M 3.8297121,0.51130121 3.8114703,2.4749241 C 1.76397,11.202565 3.1249414,
       16.215468 3.8758847,21.32146 l -0.032207,2.133568 H 19.850115 V 21.32146 c 1.440376,
       -6.005475 1.70476,-11.8067791 0,-18.8465359 l -0.0031,-1.96362289 z"
       id="path77"
       sodipodi:nodetypes="ccccccccc" />
    <path
       style="stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="M 3.8114703,2.4749241 H 19.850114"
       id="path89"
       sodipodi:nodetypes="cc" />
    <path
       style="stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="M 3.8758825,21.32146 H 19.850114"
       id="path91" />
    <ellipse
       style="stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       id="path918"
       ry="5.1076374"
       rx="5.1529775"
       cy="12.177129"
       cx="11.832805" />
    <path
       style="stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 11.902627,16.429598 -0.09663,-4.008525"
       id="path922"
       sodipodi:nodetypes="cc" />
    <path
       style="stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 11.934833,15.265833 c 0.07489,-1.436924 0.550112,-2.605951 2.415459,
       -2.84476 0.02167,1.222541 -0.402021,2.631732 -2.415459,2.84476 z"
       id="path924"
       sodipodi:nodetypes="ccc" />
    <path
       style="stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 11.806008,12.421073 c -0.883286,-1.129684 -1.278589,-2.333239 0.02054,
       -3.7932977 0.818137,0.9025078 1.411052,2.2508957 -0.02054,3.7932977 z"
       id="path926"
       sodipodi:nodetypes="ccc" />
    <path
       style="stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
       d="m 11.934833,15.265833 c -1.421878,-0.159125 -2.5543028,-0.719449 -2.6816599,
       -2.672867 1.2147599,0.04659 2.5886009,0.566896 2.6816599,2.672867 z"
       id="path928"
       sodipodi:nodetypes="ccc" />
  </g>
</svg>
    