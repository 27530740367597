import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FertilizerModelService } from './service';
import { UpdateService } from '../../../../services/update.service';
import { NotificationService } from '../../../../services/notification.service';
import { eNotificationTypes } from '../../../../interfaces/constants';

@Component({
	moduleId: module.id,
	selector: 'fertilizer-delete-dialog',
	templateUrl: 'fertilizer.delete.dialog.html'
})

export class FertilizerDeleteDialog implements OnInit {

	public fertilizer: {
		id: number,
		name: string
	};

	public isSaving: boolean;

	public constructor(
		private _dialog: MatDialog,
		private _dialogRef: MatDialogRef<FertilizerDeleteDialog>,
		@Inject(MAT_DIALOG_DATA) private _data: { id: number, name: string },
		private _notificationService: NotificationService,
		private service: FertilizerModelService,
		private updateService: UpdateService
	) {

	}

	ngOnInit(): void {
		this.isSaving = false;
		this.fertilizer = this._data;
	}

	public remove(): void {
		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this.service.delete(this.fertilizer.id)
		.then((errorMessage) => {
			this.isSaving = false;

			if (errorMessage) {
				this._notificationService.generateNotifcation({
					type: eNotificationTypes.ERROR,
					message: errorMessage
				});
			} else {
				this.updateService.setRanchSettingsUpdated('fertilizers');
			}

			this._dialog.closeAll();
		});
	}

	public close() {
		this._dialogRef.close();
	}
}
