import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EventsUpdateService {
	private _closeModal: Subject<null> = new Subject<null>();
	public closeModal$ = this._closeModal.asObservable();

	/**
	 * Used to close event table/list modal when calendar is opened
	 */
	public closeModal(): void {
		this._closeModal.next();
	}
}
