import { Component, Input, ViewChild } from '@angular/core';
import { NavInterface } from '../../interfaces/nav.interface';
import { ProfileTabComponent } from '../profile/tab';

@Component({
	moduleId: module.id,
	selector: 'profile-nav',
	templateUrl: 'nav.html'
})

export class ProfileNavComponent implements NavInterface {
	tabs: ProfileTabComponent[] = [];

	@Input()
	title: string;

	selectTab(tab: ProfileTabComponent): void {
		this.tabs.map((t) => {
			t.selected = false;
		});

		tab.selected = true;
	}

	addTab(tab: ProfileTabComponent): void {
		if (!this.tabs.length) {
			tab.selected = true;
		}
		this.tabs.push(tab);
	}
}
