import { Component, Input, Output, OnInit, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { IFlowmeterSummaryViewModel } from '../interfaces';

@Component({
	moduleId: module.id,
	selector: 'flowmeter-summary-table',
	templateUrl: 'main.html'
})

export class FlowmeterSummaryTableComponent implements OnInit {
	@Input()
	public flowmeterData: IFlowmeterSummaryViewModel[];

	@Output()
	detailLoaded: EventEmitter<number> = new EventEmitter();

	constructor() { }

	ngOnInit(): void {
	}

	public loadDetails(flowWateringId: number) {
		this.detailLoaded.emit(flowWateringId);
	}
}

