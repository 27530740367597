import { CMErrors, CMError, httpStatusCodes } from '../interfaces/interfaces';

/**
 * A wrapper for CMErrors class, which is the error class used in Web API
 * to emit errors to the Web API client
 */
export class WebAPIErrors implements CMErrors {
	public errors: CMError[];
	public error: CMError;

	constructor(errors: CMErrors) {
		if (errors === null || errors === undefined) {
			return;
		}

		this.errors = errors.errors;
		this.error = this.getError();
	}

	public getError(): CMError {
		if (!this.errors || this.errors.length === 0) {
			return null;
		} else {
			return this.errors[0];
		}
	}

	/**
	 * Tells you whether the JSON object is a CMErrors object or something else
	 */
	public isCMError(): boolean {
		if (this.error === null) {
			return false;
		} else {
			return true;
		}
	}

	public isError(): boolean {
		if (!this.isCMError()) { // implicitly checks for this.error === null
			return false;
		}

		if (this.error.code === httpStatusCodes.success || this.error.code === httpStatusCodes.continue) {
			return false;
		} else {
			return true;
		}
	}
}
