/*modules*/
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { MyDatePickerModule } from 'mydatepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatMenuModule} from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPopperModule } from 'ngx-popper';
import { DecimalPipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/*google maps module*/
import { AgmCoreModule } from '@agm/core';

/*custom routing module*/
import { AppRoutingModule, ConfirmDeactivateGuard } from './app-routing.module';

/*components*/
import { AppComponent } from './app.component';

import { MainMenuComponent } from './components/main-menu/main-menu.component';

import { AddEventsComponent } from './components/events/buttons/create-events/main';

import { AdminComponent } from './components/admin/main';

import { ProfileComponent } from './components/profile/main';
import { ProfileNavComponent } from './components/profile/nav';
import { ProfileTabComponent } from './components/profile/tab';
import { UserInformationComponent } from './components/profile/user-information';
import { ChangePasswordComponent } from './components/profile/change-password';
import { LocalizationComponent } from './components/localization/localization.component';

import { IrrigationEventComponent } from './components/irrigation-event/main';
import { FertilizationEventDialog } from './components/fertilization-event/main';

import { SoilSampleEventDialog } from './components/soil-sample-event/main';

import { AbstractModalComponent } from './components/shared/abstract-modal/main';

import { TabComponent } from './components/shared/tab/main';
import { HorizontalNavComponent } from './components/shared/horizontal-nav/main';
import { CanopyTableComponent } from './components/canopy-curve/canopy-table';
import { SliderButtonComponent } from './components/shared/slider-button/main';
import { SoilMoistureComponent } from './components/soil-moisture/soil-moisture.component';

import { WeatherStationsComponent } from './components/weather-stations/weather-stations.component';
import { IgnoreSchedulesComponent } from './components/ignore-schedules/ignore-schedules.component';
import { NotificationComponent } from './components/shared/notification/main';

/* SVG Components */

/*dialogs*/
import { HttpErrorDialog } from './components/server-error-dialog/http-error-dialog';
import { CanopyDialog } from './components/canopy-curve/canopy-dialog';
import { SoilMoistureDialog } from './components/soil-moisture/soil-moisture-dialog';

import { WeatherStationDialog } from './components/weather-stations/weather-station-dialog';
import { WeatherStationStatsDialog } from './components/weather-stations/weather-station-stats-dialog';
import { DeactivateWeatherStationDialog } from './components/weather-stations/deactivate-weather-station-dialog';
import { IgnoreScheduleDialog } from './components/ignore-schedules/ignore-schedule-dialog';

/*services*/
import { RanchService } from './components/ranch-settings/service';
import { UpdateService } from './services/update.service';
import { PermissionsService } from './components/ranch-settings/permissions.service';
import { TranslateService } from './components/localization/service';
import { CookieService } from './services/cookie.service';
import { SoilTypeService } from './models/soil-type/service';
import { UserService } from './models/user/service';
import { CalculateService } from './services/calculate.service';
import { HttpService } from './services/http.service';
import { ValidateService } from './services/validate.service';
import { ErrorService } from './services/error.service';
import { CustomErrorHandler } from './classes/custom-error-handler';
import { TooltipService } from './services/tooltip.service';
import { CommodityService } from './models/commodity-type/service';
import { CanopyService } from './components/canopy-curve/canopy.service';
import { SoilMoistureService } from './components/soil-moisture/service';
import { WeatherStationsService } from './models/weather-station-stats/service';
import { NotificationService } from './services/notification.service';
import { IgnoreSchedulesService } from './components/ignore-schedules/service';
import { CutEventService } from './components/cut-event/service';
import { IrrigationService } from './components/irrigation-event/service';
import { FertilizationEventService } from './components/fertilization-event/service';
import { SoilSampleEventService } from './components/soil-sample-event/service';
import { MicroSprinklerService } from './models/micro-sprinkler/service'
import { ModelService } from './services/model.service';
import { CropTypeService } from './models/crop-type/service';
import { FlowmeterService } from './components/flowmeter-graph/service';
import { FertilizationEventWellService } from './models/fertilization-event-well/service';
import { PlantingWellService } from './models/planting-well/service';
import { PersistentDatabase } from './services/persistent-database';

/*interfaces*/
import { NavInterface } from './interfaces/nav.interface';
import { TabInterface } from './interfaces/tab.interface';

/*pipes*/

import {
	CheckForNA, CheckForWetDate, NAifRainfall, ZeroIfEmpty,
	StringifyListPipe, YesNoPipe
} from './pipes/pipes.misc';
import { DatePipe } from '@angular/common';

/*directives*/

import { CoreModule } from './core.module';

import * as jQuery from 'jquery';

import { SVGLotComponent } from './components/events/buttons/svg/lot';
import { SVGWeatherComponent } from './components/events/buttons/svg/weather';
import { SVGWellComponent } from './components/events/buttons/svg/well';
import { SVGFertilizerNoStrokeComponent } from './components/events/buttons/svg/fertilizer-no-stroke';
import { CutEventRecalculateWarningComponent } from './components/cut-event/recalculate-warning';
import { IrrigationEventRecalculateWarningDialog } from './components/irrigation-event/recalculate-warning';
import { CommodityTypeDialog } from './components/ranch-settings/modals/commodityType/dialog';
import { SVGCommodityTypeComponent } from './components/events/buttons/svg/commodity-type';
import { CommodityTypeCreateDialog } from './components/ranch-settings/modals/commodityType/create';
import { HistoricalUIComponent } from './components/irrigation-event/historical-ui/historical-ui';

import { CropTypeListComponent } from './components/crop-types/crop-types';
import { CropTypeDetailComponent } from './components/crop-types/crop-type-detail';
import { FormInputComponent } from './components/shared/form/input';

import { TissueSampleEventComponent } from './components/tissue-sample-event/tissue-sample-event.component';
import { TissueSampleEventService } from './components/tissue-sample-event/service';
import { AbstractMatDialog } from './components/shared/abstract-modal/matDialog';
import { CropStageService } from './models/crop-stage/cropStage.service';

import { ETChartComponent } from './components/et-chart/et-chart';
import { ETWeatherEventService } from './components/et-chart/service';
import { FeedbackComponent } from './components/feedback/feedback';
import { FeedbackService } from './components/feedback/service';
import { EmailRanchOwnerDialog } from './components/ranch-settings/modals/email/email';
import { FertilizerViewDialog } from './components/ranch-settings/modals/fertilizer/fertilizer.view.dialog';
import { FertilizerDeleteDialog } from './components/ranch-settings/modals/fertilizer/fertilizer.delete.dialog';
import { FertilizerEditComponent } from './components/ranch-settings/modals/fertilizer/sections/edit';
import { FertilizerEditDialog } from './components/ranch-settings/modals/fertilizer/fertilizer.edit.dialog';

import { KeepDiscardDialog } from './components/ranch-settings/modals/fertilizer/keep-discard.dialog';
import { FertilizerCreateDialog } from './components/ranch-settings/modals/fertilizer/fertilizer.create.dialog';
import { FertilizerListComponent } from './components/ranch-settings/modals/fertilizer/sections/fertilizer-list';
import { LotSubscriptionService } from './services/subscriptions/lot.subscription';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './components/public/home/home.component';
import { LoginComponent } from './components/public/login/login.component';
import { LoginService } from './services/login.service';
import { TokenService } from './services/token.service';
import { GlobalHeaderComponent } from './components/global-header/global-header.component';
import { SharedModule } from './modules/shared/shared.module';
import { ForgetPasswordPage } from './components/public/forget-password/forget-password.page';
import { ForgetPasswordConfirmationPage } from './components/public/forget-password/forget-password-confirmation.page';
import { ResetPasswordPage } from './components/public/reset-password/reset-password-page';
import { ResetPasswordConfirmationPage } from './components/public/reset-password/reset-password-confirmation.page';
import { RegistrationPage } from './components/public/registeration/register.page';
import { MustConfirmEmailPage } from './components/public/registeration/must-confirmEmail.page';
import { ConfirmEmailPage } from './components/public/registeration/confirm-email.page';
import { RecaptchaComponent } from './components/public/recaptcha.component';
import { PrivacyPolicyComponent } from './components/public/privacy-policy/privacy-policy';
import { CaspService } from './components/casp-dialog/casp.service';
import { CaspDisconnectConfirmationDialog } from './components/profile/casp-disconnect-confirmation-dialog';
import { ProfileUpdateService } from './components/profile/profile.update.service';
import { PreviousCropDetailComponent } from './components/crop-types/previous-crop-detail';
import { CropTypeDeleteConfirmationDialog } from './components/crop-types/delete-confirmation';
import { CropTypeUpdateService } from './components/crop-types/cropType.update.service';
import { AppliedWaterChartComponent } from './components/applied-water-chart/applied-water-chart';
import { PerennialCanopyDialog } from './components/crop-types/perennial-canopy';
import { CanopyDeleteConfirmationDialog } from './components/crop-types/canopy-delete-confirmation';
import { AvailableWaterChartComponent } from './components/available-water-chart/available-water-chart';
import { IrrigationDeleteConfirmationDialog } from './components/irrigation-event/delete-confirmation';
import { IrrigationUpdateService } from './components/irrigation-event/update.service';
import { PageNotFoundComponent } from './components/public/page-not-found/page-not-found';
import { SharedKeepDiscardDialog } from './components/shared/dialogs/keep-discard-dialog';
import { SharedUpdateService } from './components/shared/dialogs/update.service';
import { IrrigationRecommendationSummaryComponent } from './components/irrigation-event/recommendation-summary/recommendation-summary';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MainMenuUpdateService } from './components/main-menu/main-menu-update.service';
import { CutEventComponent } from './components/cut-event/cut-event.component';
import { AppliedFertilizerChartComponent } from './components/applied-fertilizer-chart/applied-fertilizer-chart';
import { EventsUpdateService } from './components/events/events.update.service';
import { MissingStationRecordsComponent } from './components/weather-stations/missing-station-records';
import { CIMISDayDataService } from './components/weather-stations/cimis-day-data.service';
import { HistoricalETService } from './components/weather-stations/historical-et.service';
import { DeleteConfirmationComponent } from './components/shared/dialogs/delete-confirmation';
import { RainfallChartUpdateService } from './components/rainfall-chart/rainfall-chart.update.service';
import { FlowmeterChartUpdateService } from './components/flowmeter-graph/flowmeter-chart.update.service';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { BaseDialog } from './components/shared/dialogs/base-dialog';
import { GlobalSettingsComponent } from './components/global-settings/global-settings.component';
import { GlobalSettingsService } from './components/global-settings/global-settings.service';
import { SupportComponent } from './components/support/support';
import { WeatherAPIService } from './components/weather-stations/weather-api.service';
import { MatTabsModule } from '@angular/material/tabs';
import { SoilSampleWarningDialog } from './components/soil-sample-event/warning';
import { FertilizationSoilSampleComponent } from './components/fertilization-event/soil-sample';
import { CustomSaturationHelp } from './components/available-water-chart/custom-saturation-help';
import { PlantingRecalculatorService } from './components/planting-recalculate-modal/plantingRecalculatorService';
import { CanopySliderComponent } from './components/canopy-curve/canopy-slider';
import { ETCalculatorComponent } from './components/public/et-calculator/et-calculator.component';
import { ETCalculatorResultsComponent } from './components/public/et-calculator/et-calculator-results.component';

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule, // shared
		AppRoutingModule, // shared
		ModalModule.forRoot(),
		AgmCoreModule.forRoot({ // ranch home module
			apiKey: 'AIzaSyDCespIz8AYidWwOLwO4k3-orWCnXtvLcs'
		}),
		CoreModule,
		DragulaModule, // should be in ranch home module
		MyDatePickerModule, // shared
		MatDialogModule,
		MatButtonModule, // shared
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatTabsModule,
		BrowserAnimationsModule,
		MatTooltipModule,
		MatSnackBarModule,
		MatSliderModule,
		MatMenuModule,
		MatDatepickerModule,
		MatNativeDateModule,
		NgxPopperModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		NgbModule.forRoot(),
		MatIconModule,
		SharedModule.forRoot(),
	],
	exports: [
	],
	declarations: [
		AppComponent,
		HomeComponent,
		LoginComponent,
		ETCalculatorComponent,
		ETCalculatorResultsComponent,
		PrivacyPolicyComponent,

		// pipes

		CheckForNA,
		CheckForWetDate,
		NAifRainfall,

		ZeroIfEmpty,
		StringifyListPipe,
		YesNoPipe,

		MainMenuComponent,

		FertilizerEditComponent,

		AddEventsComponent,

		AdminComponent,
		ProfileComponent,
		ProfileNavComponent,
		ProfileTabComponent,

		// ClickOutsideDirective,

		PageNotFoundComponent,
		LocalizationComponent,
		UserInformationComponent,
		CaspDisconnectConfirmationDialog,
		CropTypeDeleteConfirmationDialog,
		PerennialCanopyDialog,
		CanopyDeleteConfirmationDialog,
		ChangePasswordComponent,
		IrrigationEventComponent,
		IrrigationRecommendationSummaryComponent,
		IrrigationDeleteConfirmationDialog,
		SharedKeepDiscardDialog,
		BaseDialog,
		DeleteConfirmationComponent,
		SoilSampleEventDialog,
		SoilSampleWarningDialog,

		FertilizerViewDialog,
		FertilizerDeleteDialog,
		FertilizerEditDialog,
		FertilizerCreateDialog,
		FertilizerListComponent,

		KeepDiscardDialog,

		AbstractModalComponent,

		GlobalHeaderComponent,

		HttpErrorDialog,
		TabComponent,
		HorizontalNavComponent,
		CanopyDialog,
		CommodityTypeDialog,
		ETChartComponent,
		AppliedWaterChartComponent,
		AppliedFertilizerChartComponent,
		AvailableWaterChartComponent,
		CustomSaturationHelp,
		FeedbackComponent,
		SupportComponent,
		EmailRanchOwnerDialog,
		CommodityTypeCreateDialog,
		CanopyTableComponent,
		SliderButtonComponent,
		SoilMoistureComponent,
		SoilMoistureDialog,
		WeatherStationsComponent,
		MissingStationRecordsComponent,
		WeatherStationDialog,
		WeatherStationStatsDialog,
		DeactivateWeatherStationDialog,
		IgnoreSchedulesComponent,
		IgnoreScheduleDialog,
		NotificationComponent,
		FertilizationEventDialog,
		FertilizationSoilSampleComponent,
		CutEventRecalculateWarningComponent,
		CanopySliderComponent,
		AbstractMatDialog,
		TissueSampleEventComponent,
		CutEventComponent,
		SVGFertilizerNoStrokeComponent,
		SVGCommodityTypeComponent,
		SVGLotComponent,
		SVGWeatherComponent,
		SVGWellComponent,
		IrrigationEventRecalculateWarningDialog,
		HistoricalUIComponent,
		CropTypeListComponent,
		GlobalSettingsComponent,
		CropTypeDetailComponent,
		PreviousCropDetailComponent,
		FormInputComponent,

		ForgetPasswordPage,
		ForgetPasswordConfirmationPage,
		ResetPasswordPage,
		ResetPasswordConfirmationPage,
		RegistrationPage,
		MustConfirmEmailPage,
		ConfirmEmailPage,
		RecaptchaComponent
	],
	providers: [
		PlantingRecalculatorService,
		UpdateService,
		IrrigationUpdateService,
		RainfallChartUpdateService,
		FlowmeterChartUpdateService,
		MainMenuUpdateService,
		SharedUpdateService,
		CropTypeUpdateService,
		LotSubscriptionService,
		PermissionsService,
		NavInterface,
		TabInterface,
		TranslateService,
		CookieService,
		SoilTypeService,
		UserService,
		CalculateService,
		ValidateService,
		ErrorService,
		HttpService,
		LoginService,
		TokenService,
		{ provide: ErrorHandler, useClass: CustomErrorHandler },
		TooltipService,
		CommodityService,
		CanopyService,
		DecimalPipe,
		SoilMoistureService,
		WeatherStationsService,
		ETWeatherEventService,
		FeedbackService,
		CropStageService,
		NotificationService,
		IgnoreSchedulesService,
		DatePipe,
		CutEventService,
		GlobalSettingsService,
		EventsUpdateService,
		TissueSampleEventService,
		IrrigationService,
		FertilizationEventService,
		SoilSampleEventService,
		MicroSprinklerService,
		ModelService,
		CropTypeService,
		FlowmeterService,
		FertilizationEventWellService,
		PlantingWellService,
		RanchService,
		PersistentDatabase,
		CaspService,
		ProfileUpdateService,
		ConfirmDeactivateGuard,
		CIMISDayDataService,
		HistoricalETService,
		WeatherAPIService,
	],
	bootstrap: [AppComponent],
	entryComponents: [
		HttpErrorDialog,
		CanopyDialog,
		CommodityTypeDialog,
		ETChartComponent,
		AppliedWaterChartComponent,
		CustomSaturationHelp,
		AppliedFertilizerChartComponent,
		AvailableWaterChartComponent,
		SupportComponent,
		FeedbackComponent,
		EmailRanchOwnerDialog,
		CommodityTypeCreateDialog,
		SoilMoistureDialog,
		BaseDialog,
		IrrigationDeleteConfirmationDialog,
		SharedKeepDiscardDialog,
		DeleteConfirmationComponent,
		FertilizerViewDialog,
		FertilizerDeleteDialog,
		IrrigationEventComponent,
		FertilizerEditDialog,
		KeepDiscardDialog,
		FertilizerCreateDialog,
		IrrigationEventRecalculateWarningDialog,
		WeatherStationDialog,
		WeatherStationStatsDialog,
		DeactivateWeatherStationDialog,
		IgnoreScheduleDialog,
		NotificationComponent,
		CutEventComponent,
		FertilizationEventDialog,
		SoilSampleEventDialog,
		SoilSampleWarningDialog,
		TissueSampleEventComponent,
		CutEventRecalculateWarningComponent,
		CaspDisconnectConfirmationDialog,
		CropTypeDeleteConfirmationDialog,
		PerennialCanopyDialog,
		CanopyDeleteConfirmationDialog,
	]
})
export class AppModule { }
