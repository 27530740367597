import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { SoilMoistureService } from './service';
import { SoilMoistureDialog } from './soil-moisture-dialog';
import { UpdateService } from '../../services/update.service';
import { DateUtility } from '../../classes/dateUtility';

import { ISoilMoistureFile, ISoilMoistureDialogParameters } from './soilMoisture.interface';
import { eSoilMoistureViews, eSoilMoistureImportErrors } from '../../interfaces/constants';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	moduleId: module.id,
	selector: 'soil-moisture',
	templateUrl: 'soil-moisture.component.html'
})

export class SoilMoistureComponent implements OnInit, OnDestroy {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public displayedColumns = ['RanchName', 'PlantingName', 'HarvestDate', 'FileName', 'LastUpdateDate', 'Action'];
	public dataSource: MatTableDataSource<ISoilMoistureFile>;
	public soilMoistureFiles: ISoilMoistureFile[] = new Array();
	public eSoilMoistureViews = eSoilMoistureViews;
	public plantingBeingUpdated: {
		id: number,
		name: string
	} = null;
	public soilMoistureUpdateError: eSoilMoistureImportErrors;
	public eSoilMoistureImportErrors = eSoilMoistureImportErrors;
	private _subscriptions$: Subject<boolean>;

	constructor(
		private _router: Router,
		private _tokenService: TokenService,
		private soilMoistureService: SoilMoistureService,
		private dialog: MatDialog,
		private updateService: UpdateService) {

		if (this._tokenService.isAuthenticated() === false) {
			this._router.navigate(['login']);
		}
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();

		this.soilMoistureService.list().then(soilMoistureFiles => {
			this.soilMoistureFiles = soilMoistureFiles;
			this.dataSource = new MatTableDataSource(this.soilMoistureFiles);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;

			this.dataSource.sortingDataAccessor = (data: ISoilMoistureFile,
				sortHeaderId: string) => {

					switch (sortHeaderId) {
						case 'RanchName':
							return data.RanchName.toLowerCase()
						case 'PlantingName':
							return data.PlantingName.toLowerCase();
						case 'PlantingId':
							return data.PlantingId;
						case 'HarvestDate':
							return DateUtility.MMDDYYYYToDate(data.HarvestDate).toDateString();
						case 'FileName':
							return data.FileName.toLowerCase();
						case 'LastUpdateDate':
							return DateUtility.MMDDYYYYToDate(data.LastUpdateDate).toDateString();
					}
				}
		});

		this.updateService.SoilMoistureFileUpdated$
			.pipe(takeUntil(this._subscriptions$)).subscribe(updatedSoilMoistureFile => {

			if (!this.soilMoistureFiles || this.soilMoistureFiles.length === 0) {
				return;
			}

			for (let file of this.soilMoistureFiles) {
				if (file.PlantingId === updatedSoilMoistureFile.plantingId) {
					file.FileName = updatedSoilMoistureFile.fileName;
					file.LastUpdateDate = DateUtility.getmmddyyyyString(new Date());
					break;
				}
			}
		})

		this.sort.sortChange.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			while (this.paginator.hasPreviousPage()) {
				this.paginator.previousPage();
			}
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public applyFilter(filterValue: string): void {
		if (!this.dataSource) {
			return;
		}

		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	}

	public openSoilMoistureDialog(view: eSoilMoistureViews, plantingId: number,
		plantingName: string, fileName: string, hasSoilMoistureFileName: boolean): void {

		let params: ISoilMoistureDialogParameters = {
			view: view,
			plantingId: plantingId,
			plantingName: plantingName
		}

		if (fileName !== null) {
			params.fileName = fileName;
		}

		if (hasSoilMoistureFileName !== null) {
			params.hasSoilMoistureFileName = hasSoilMoistureFileName;
		}

		this.dialog.open(SoilMoistureDialog, {
			width: '690px',
			data: params
		});
	}

	public updateSoilMoistureData(plantingId: number, plantingName: string, fileName: string): void {
		this.plantingBeingUpdated = {
			id: plantingId,
			name: plantingName
		};
		this.soilMoistureUpdateError = null;

		this.soilMoistureService.updateFile(plantingId, fileName)
			.then(response => {
				if (response && response.code) {
					this.plantingBeingUpdated = {
						id: plantingId,
						name: plantingName
					};

					this.soilMoistureUpdateError = response.code;
				} else {
					this.updateService.setSoilMoistureFileUpdated(plantingId, fileName);
				}
			})
	}

	public clearSoilMoistureUpdateMessage(): void {
		this.plantingBeingUpdated = null;
		this.soilMoistureUpdateError = null;
	}
}
