import { LettuceFertilizationRecommendedSummaryViewModel } from '../../../../fertilization-event/interfaces';
import { TranslateService } from '../../../../localization/service';
import { EquationGenerator } from './equationGenerator';
import { FertilizationRecommendationFields } from './strawberryEquationGenerator';

export interface IGeneratedEquation {
	equationDescriptions: string[],
	equations: string[]
}

export class LettuceEquationGenerator extends EquationGenerator {

	protected _s: LettuceFertilizationRecommendedSummaryViewModel;

	constructor(s: LettuceFertilizationRecommendedSummaryViewModel,
		translateService: TranslateService) {
		super(s, translateService);
		this._s = s;
	}

	protected _getPrimaryEquation(result: IGeneratedEquation): IGeneratedEquation {
		result.equationDescriptions[0] = `${this._ts.translate('Fertilizer N Recommendation')}
			= ${this._ts.translate('Crop N Uptake')} +
			(${this._ts.translate('Soil N Threshold')} - ${this._ts.translate('Soil N')}) - ${this._ts.translate('Total Mineralized N')}`;

		this.equationResult = this._s.NUptakeDifference + (this._s.SoilNThreshold - this._s.SoilN)
		- this._s.NMineralTotal - this._s.NAppliedFromWater;

		result.equations[0] = `${this.equationResult.toFixed(2)} =
			${this._s.NUptakeDifference.toFixed(2)} + (${this._s.SoilNThreshold.toFixed(2)}
			- ${this._s.SoilN.toFixed(2)}) - ${this._s.NMineralTotal.toFixed(2)}`;

		return result;
	}

	public getEquation(): IGeneratedEquation {
		let result: IGeneratedEquation = {
			equationDescriptions: [],
			equations: []
		};

		let f = FertilizationRecommendationFields;

		if (this.isNearHarvest(this._s)) {
			result = this._getNearHarvestEquation(result);
			this._s.NMineralTotal = null;
			this._s.SoilN = null;
			this._s.SoilNThreshold = null;
		} else {
			result = this._getPrimaryEquation(result);
		}

		if (this.equationResult < 0) {
			result = this.getNegativeMessage(result);
		} else if (this.isRecommendationClamped()) {
			result = this.getClamped(result);
		}

		result = this.addNinWater(result);

		return result;
	}

	private _getNearHarvestEquation(result: IGeneratedEquation): IGeneratedEquation {
		result.equationDescriptions[0] = `${this._ts.translate('Fertilizer N Recommendation')} = ${this._ts.translate('Crop N Uptake')}`;
		result.equations[0] = `${this._s.Recommendation.toFixed(2)} = ${this._s.NUptakeDifference.toFixed(2)}`;

		return result;
	}
}
