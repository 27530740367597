import { Injectable } from '@angular/core';
import { ModelService } from '../../services/model.service';
import { SuccessResponse } from '../../interfaces/interfaces';
import { CookieService } from '../../services/cookie.service';
import { CASPNotNowCookieName } from '../../services/constants';

@Injectable()
export class CaspService extends ModelService {

	private _urls = {
		shouldPrompt: (ranchGuid: string) => `/v2/ranch/${ranchGuid}/should-show-casp-prompt.json`,
		temporaryKey: `/v2/account/temporary-key.json`,
		isEnabled: `/v2/account/is-casp-enabled.json`,
		disconnect: `/v2/account/disable-casp.json`
	};

	/**
	 * Evaluates whether user should be prompted to connect to CASP, based on
	 * whether he is already connected to CASP and he is the owner of the current ranch
	 *
	 * @param ranchGuid
	 */
	public shouldPrompt(ranchGuid: string): Promise<boolean> {

		return this.get(this._urls.shouldPrompt(ranchGuid),
			(response: boolean): boolean => {
				return response;
			}
		);
	}

	/**
	 * Validates whether user is connect to CASP or not
	 */
	public isEnabled(): Promise<boolean> {

		return this.get(this._urls.isEnabled,
			(response: boolean): boolean => {
				return response;
			}
		);
	}

	/**
	 * Get temporary user key
	 */
	public temporaryKey(): Promise<string> {

		return this.get(this._urls.temporaryKey,
			(response: string) => {
				return response;
			}
		);
	}

	public setCookie(): void {
		const days = 7;

		CookieService.set(CASPNotNowCookieName, 'CASPNotNow', days);
	}

	/**
	 * Disconnect user from CASP
	 */
	public disable(): Promise<SuccessResponse> {
		return this.deleteItem(this._urls.disconnect,
			(response: SuccessResponse) => {
				return response;
			}
		);
	}
}
