<div class="bk-login__container">
	<form class="form-horizontal" method="post">
		<div class="bk-modal bk-modal--register">
			<div class="bk-modal__header">
				<h4 class="bk-modal__header-title">
					Reset Password
				</h4>
			</div>
			<div class="bk-modal__body">
				<div class="bk-login__new-form">
					<div *ngIf="errorMessage !== ''"
						class="validation-summary-valid bk-login__register--errors">
						<ul>
							<li>
								{{ errorMessage }}
							</li>
						</ul>
					</div>
					<div class="bk-login__form-group">
						<label for="Email">Email</label>
						<input [(ngModel)]="email"
							id="email"
							name="email"
							type="text"
							value="">
					</div>
					<app-recaptcha (recaptchaResponse)='onCaptchaResponse($event)'></app-recaptcha>
					<div class="bk-login__form-group">
						<input type="submit"
							[disabled]='isCaptchaValid === false || !email'
							class="bk-register__button bk-button--large btn btn-custom"
							value="Reset Password"
							(click)="submit()">
					</div>
				</div>
			</div>
		</div>
	</form>
</div>