<div class="bk-dialog">
    <button type="button" class="bk-dialog__close" (click)="close()">
        &times;
    </button>

    <h4 mat-dialog-title>
        {{ plantingName }} {{'Canopy Curve' | translate }}
    </h4>

    <div mat-dialog-content>
            <div class="bk-dialog__section">
                <div class="bk-dialog__canopy-graph--container">
                    <div *ngIf="!isLoaded"
                        class="bk-loader__container">
                        <div class="bk-loader"></div>
                    </div>
                    <div class="bk-chart" #highchartsContainer></div>
                </div>
            </div>

            <div class="bk-dialog_section-subtitle">
                {{'CropManage Canopy Parameters' | translate }}
                <a class="bk-dialog__link bk-dialog__link--canopy-reset"
                    *ngIf="canopy.form"
                    href="javascript:void(0);"
                    (click)="reset()">
                    {{'Reset to Default Parameters' | translate }}
                </a>
            </div>

            <form [formGroup]="canopy.form"
                *ngIf="canopy.form">
                <div class="bk-dialog__row bk-dialog__row--canopy">

                    <canopy-slider
                        [controlName]="'CanopyGMax'"
                        [label]="'Max. Canopy'"
                        [formGroup]="canopy.form"
                        [min]="0"
                        [max]="100"
                        [decimals]="1"
                        [step]="1"
                        [defaultValue]="defaultSetting.CanopyGMax"
                        [tooltipText]="'Canopy Curve CanopyGMax tooltip text'"
                        (reload)="reloadData()">
                    </canopy-slider>

                    <canopy-slider *ngIf="commodityTypeId !== COMMODITY_TYPES.ALFALFA"
                        [controlName]="'MaxFraction'"
                        [label]="'Max. Fraction'"
                        [formGroup]="canopy.form"
                        [min]="0.01"
                        [max]="1"
                        [decimals]="2"
                        [step]="0.01"
                        [defaultValue]="defaultSetting.MaxFraction"
                        [tooltipText]="'Canopy Curve MaxFraction tooltip text'"
                        (reload)="reloadData()">
                    </canopy-slider>

                    <canopy-slider *ngIf="commodityTypeId === COMMODITY_TYPES.ALFALFA"
                        [controlName]="'CanopyMin'"
                        [label]="'Minimum Canopy'"
                        [formGroup]="canopy.form"
                        [min]="0"
                        [max]="100"
                        [decimals]="1"
                        [step]="1"
                        [defaultValue]="defaultSetting.CanopyMin"
                        [tooltipText]="'Canopy Curve CanopyMin tooltip text'"
                        (reload)="reloadData()">
                    </canopy-slider>

                    <canopy-slider
                        [controlName]="'CanopyA'"
                        [label]="'Canopy A'"
                        [formGroup]="canopy.form"
                        [min]="1.5"
                        [max]="10"
                        [decimals]="2"
                        [step]="0.01"
                        [defaultValue]="defaultSetting.CanopyA"
                        [tooltipText]="'Intercept factor for canopy curve'"
                        (reload)="reloadData()">
                    </canopy-slider>

                    <canopy-slider
                        [controlName]="'CanopyB'"
                        [label]="'Canopy B'"
                        [formGroup]="canopy.form"
                        [min]="-20"
                        [max]="-4"
                        [decimals]="2"
                        [step]="0.01"
                        [defaultValue]="defaultSetting.CanopyB"
                        [tooltipText]="'Slope factor for canopy curve'"
                        (reload)="reloadData()">
                    </canopy-slider>

                    <canopy-slider *ngIf="isRequiredField('CanopyE')"
                        [controlName]="'CanopyE'"
                        [label]="'Canopy E'"
                        [formGroup]="canopy.form"
                        [min]="-2.5"
                        [max]="0.2"
                        [decimals]="2"
                        [step]="0.01"
                        [defaultValue]="defaultSetting.CanopyE"
                        [tooltipText]="'Canopy Curve CanopyE tooltip text'"
                        (reload)="reloadData()">
                    </canopy-slider>

                    <canopy-slider *ngIf="isRequiredField('CanopyF')"
                        [controlName]="'CanopyF'"
                        [label]="'Canopy F'"
                        [formGroup]="canopy.form"
                        [min]="0.4"
                        [max]="3"
                        [decimals]="2"
                        [step]="0.01"
                        [defaultValue]="defaultSetting.CanopyF"
                        [tooltipText]="'Canopy Curve CanopyF tooltip text'"
                        (reload)="reloadData()">
                    </canopy-slider>
                </div>
            </form>

            <div class="bk-dialog__canopy-warning" *ngIf="!hasCoordinates">
                <div class="bk-dialog__canopy-warning--head">
                    {{'Cannot display NASA Sims information. Planting Coordinates are missing' | translate}}
                </div>
                <div class="bk-dialog__canopy-warning--paragraph">
                    {{'Add Coordinates in the Planting Settings to fix the problem.' | translate}}
                </div>
            </div>

            <div class="bk-dialog__section">
                <canopy-table [plantingId]="plantingId" [canopy]="canopy" (onDataUpdated)="dataUpdated()"></canopy-table>
            </div>

            <a class="bk-dialog__link bk-dialog__link--canopy-sims" href="https://will-carrara.github.io/flask-sims/docs/equations.pdf"
                target="_blank">
                {{'Learn more about NASA SIMS' | translate }}
            </a>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button class="bk-button bk-button--medium bk-button--text-only bk-dialog__button--secondary"
            (click)="close()">
            {{ (canopy.dataHasChanged() ? 'Cancel' : 'Close') | translate }}
        </button>
        <button mat-button class="bk-button bk-button--medium bk-dialog__button--primary"
            [disabled]="!canopy.form || canopy.form.invalid || !canopy.dataHasChanged()"
            (click)="save()">
            {{ 'Save' | translate }}
        </button>
    </div>
</div>

