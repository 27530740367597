<div class="bk-dialog">

	<button type="button"
		class="bk-button bk-dialog__close"
		(click)="close()">

		&times;
	</button>

	<h4 mat-dialog-title
		*ngIf='fertilizer'>
		{{ fertilizer.Name }} {{ 'Details' | translate }}
	</h4>

	<div mat-dialog-content
		*ngIf="fertilizer">
		
		<div class="bk-modal__right-two-col bk-modal__right-two-col--left">
			<label class="bk-modal__label">
				{{'Fertilizer Name' | translate }}
			</label>
		
			<div class="bk-modal__fertilizer-text">
				{{fertilizer.Name}}
			</div>
		
			<label class="bk-modal__label">
				{{'Formulation' | translate }}
			</label>
		
			<div class="bk-modal__fertilizer-text"
				*ngIf='fertilizer.FormulationType'>

				{{fertilizer.FormulationType.Name | translate }}
			</div>
		
			<ng-container *ngIf="fertilizer.FormulationTypeId === formulationTypes.WET">
		
				<div class="bk-modal__fertilizer-text"
					[ngClass]="{'bk-invalid': fertilizer.PoundsPerGallon === 0}">
					{{fertilizer.PoundsPerGallon}} {{'lbs' | translate}}/{{'gal' | translate}}
				</div>

				<div *ngIf="fertilizer.PoundsPerGallon === 0"
					class="bk-invalid">
					{{'Wet fertilizer lbs/gallon must be greater than zero' | translate }}
				</div>
		
			</ng-container>
		</div>
		
		<div class="bk-modal__section">
			<h4 class="bk-modal__section-title">
				{{'Nutrient' | translate }}
			</h4>
		
			<div class="bk-modal__nutrients-group">
				<div class="bk-modal__nutrients-col"
					*ngFor="let nutrient of fertilizer.Nutrients">
		
					<label class="bk-modal__label">
						{{nutrient.Name | translate}}
					</label>
		
					<div class="bk-modal__fertilizer-text">
						{{nutrient.Percentage}} %
					</div>
				</div>
			</div>
		
			<h4 [class.bk-text--error]="totalInvalid"
				class="bk-clearfix">
				{{'Total Nutrients' | translate }}: {{nutrientTotalPercentage.total}}%
			</h4>
			<div *ngIf="nutrientTotalPercentage.validationError === validationErrors.TOTAL_TOO_BIG"
				class="bk-invalid-text--nutrient bk-invalid">
				{{'Total cannot exceed 100%' | translate }}
			</div>
			<div *ngIf="nutrientTotalPercentage.validationError === validationErrors.TOTAL_NOT_GREATER_THAN_ZERO"
				class="bk-invalid-text--nutrient bk-invalid">
				{{'Total must be greater than 0%' | translate }}
			</div>
		</div>
	</div>

	<div mat-dialog-actions align="end">

		<button *ngIf='!usage'
			class="bk-button bk-button--medium bk-dialog__button--secondary
			bk-button--text-only bk-button--float-left"
			(click)="delete()">
			{{'Remove Fertilizer' | translate }}
		</button>

		<button matt-button
			class="bk-button bk-button--medium bk-dialog__button--primary"
			(click)="close()">
			{{'Done' | translate }}
		</button>
	</div>
</div>