<div *ngIf="isAuthenticated === false" 
	class="bk-splash-header">

	<img class="bk-splash-logo"
		alt="CropManage"
		(click)="onClickHomePage()"
		src="assets/images/logos/cropmanage-logo-white.png"/>
	<a class="bk-splash-login"
		routerLink="/login">
		Login
	</a>
	<a class="bk-splash-register-button"
		routerLink="/register">
		Sign Up
	</a>
</div>

<div *ngIf="isAuthenticated"
	class="global-header bk-header">
	<div class="menu-button-container bk-header__menu-icon-container">
		<button class="bk-button bk-header__menu-icon"
			(click)="openMenu()">
			<i class="fa fa-bars" aria-hidden="true"></i>{{'Menu' | translate }}
		</button>
	</div>
	<!-- logo -->
	<div class="app-title bk-header__logo" (click)="goToDashboard()">
		<img alt="CropManage" src="assets/images/logos/cropmanage-logo-white.png" />
	</div>

	<div id="bk-menu--mobile">
		<button mat-button [matMenuTriggerFor]="matMenuMobile">
			<mat-icon aria-hidden="false" aria-label="menu">more_vert</mat-icon>
		</button>
		<mat-menu #matMenuMobile="matMenu">
			<button mat-menu-item
				[matMenuTriggerFor]="supportMobile">
				<mat-icon aria-hidden="false" aria-label="Contact Support">help</mat-icon>
				{{ 'Support' | translate }}
			</button>
			<button mat-menu-item
				[matMenuTriggerFor]="languagesMobile">
				<mat-icon aria-hidden="false" aria-label="Language">language</mat-icon>
				{{ languageName }}
			</button>
		</mat-menu>

		<mat-menu #supportMobile="matMenu">
			<button mat-menu-item
				(click)="openTechnicalSupport()">
				<mat-icon aria-hidden="false" aria-label="Contact Support">help</mat-icon>
				<span>{{ 'Technical Support' | translate }}</span>
			</button>
			<button mat-menu-item
				(click)="openFeedbackDialog()">
				<mat-icon aria-hidden="false" aria-label="Contact Support">feedback</mat-icon>
				{{ 'Send Feedback' | translate }}</button>
			<button mat-menu-item
				(click)="openHelpDocumentation()">
				<mat-icon aria-hidden="false" aria-label="Contact Support">library_books</mat-icon>
				{{ 'Help Documentation' | translate }}
			</button>
		</mat-menu>

		<mat-menu #languagesMobile="matMenu">
			<button mat-menu-item *ngFor="let languageCode of languageCodes"
				(click)="setLanguage(languageCode.Id)">
				{{languageCode.Language | translate}}
			</button>
		</mat-menu>
	</div>

	<!-- horizontal menu -->
	<div class="bk-header__name-language-menu" *ngIf="user && languageCodes">
		<div class="bk-header__name">
			<span class="bk-header__icon">
				<mat-icon aria-hidden="false" aria-label="Account">account_circle</mat-icon>
			</span>
			<span *ngIf="user">
				{{user.FirstName}} {{user.LastName}}
			</span>
		</div>
		<div class="bk-header__support">
			<button mat-button [matMenuTriggerFor]="menu">
				<mat-icon aria-hidden="false" aria-label="Help">help_outline</mat-icon>
				{{ 'Support' | translate }}
			</button>
			<mat-menu #menu="matMenu">
				<button mat-menu-item
					(click)="openTechnicalSupport()">
					<mat-icon aria-hidden="false" aria-label="Contact Support">help</mat-icon>
					<span>{{ 'Technical Support' | translate }}</span>
				</button>
				<button mat-menu-item
					(click)="openFeedbackDialog()">
					<mat-icon aria-hidden="false" aria-label="Contact Support">feedback</mat-icon>
					{{ 'Send Feedback' | translate }}</button>
				<button mat-menu-item
					(click)="openHelpDocumentation()">
					<mat-icon aria-hidden="false" aria-label="Contact Support">library_books</mat-icon>
					{{ 'Help Documentation' | translate }}
				</button>
			</mat-menu>
		</div>
		<div class="language-select bk-header__language">
			<span class="bk-header__icon">
				<mat-icon aria-hidden="false" aria-label="Language">language</mat-icon>
			</span>
			<select class="bk-select--white-text"
				id="languageSelect"
				[(ngModel)]="translateService.currentLanguageId">
				<option *ngFor="let languageCode of languageCodes"
					[ngValue]="languageCode.Id">{{languageCode.Language | translate}}
				</option>
			</select>
		</div>
	</div>
</div>
<main-menu></main-menu>