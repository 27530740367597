import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { IIrrigationRecommendationSummaryModel } from '../interfaces';
import { CalculateService } from '../../../services/calculate.service';

@Component({
	moduleId: module.id,
	selector: 'irrigation-recommendation-summary',
	templateUrl: 'recommendation-summary.html'
})

// shell dialog for irrigation event component
export class IrrigationRecommendationSummaryComponent implements OnInit, OnDestroy {
	private _subscriptions$: Subject<boolean>;

	@Input()
	summary: IIrrigationRecommendationSummaryModel;

	@Input()
	isPlantingPerennial: boolean;

	public totalCropET: number;

	public leechingFactors: {
		decimal: number,
		percent: number
	}

	constructor() {
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();

		this.leechingFactors = {
			decimal: CalculateService.percentToDecimal(this.summary.LeechingFactor),
			percent: this.summary.LeechingFactor
		}

		this.totalCropET = this.getTotalET(this.summary);
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	private getTotalET(data: IIrrigationRecommendationSummaryModel): number {
		let result: number;

		result = data.AverageCropET * data.AverageCropCoefficient * data.DaysSinceLastIrrigation;

		return result;
	}
}
