import { Pipe, PipeTransform } from '@angular/core';

import { ValidateService } from '../services/validate.service';

import { IIrrigationEvent } from '../components/irrigation-event/interfaces';
import { TranslateService } from '../components/localization/service';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 |  exponentialStrength:10}}
 *   formats to: 1024
*/
@Pipe({ name: 'checkForNA' })
export class CheckForNA implements PipeTransform {
	transform(value: string): string {
		if (parseInt(value, 10) === -1) {
			return 'N/A';
		} else {
			return value.toString();
		}
	}
}

@Pipe({ name: 'NAifRainfall' })
export class NAifRainfall implements PipeTransform {
	transform(value: string, irrigationEvent: IIrrigationEvent): string {
		if (!irrigationEvent) {
			return '';
		}

		if (irrigationEvent.IsRainfall) {
			return 'N/A';
		} else {
			return value;
		}
	}
}

@Pipe({ name: 'checkForWetDate' })
export class CheckForWetDate implements PipeTransform {
	transform(interval: string, isOnWetDate: boolean): string {

		if (parseInt(interval, 10) > 0 || isOnWetDate === false) {
			return interval;
		} else {
			return 'N/A';
		}
	}
}

// returns 0 instead of 0.00 - otherwise return in float
@Pipe({ name: 'integerIfZero' })
export class IntegerIfZero implements PipeTransform {
	transform(x: string): string {
		let val: string;

		if (x === undefined || x === null) {
			return 'N/A';
		}

		// remove comma added by Angular number pipe to numbers 4 or more digits
		val = ValidateService.removeComma(x.toString());

		if (ValidateService.isValidNumber(Number(val)) && Number(val) > 0) {
			return x;
		} else {
			return '0';
		}
	}
}

// returns 0 instead of 0.00 - otherwise return in float
@Pipe({ name: 'zeroIfEmpty' })
export class ZeroIfEmpty implements PipeTransform {
	transform(x: number): number {

		if (x === null || x === undefined || isNaN(x)) {
			return 0;
		} else {
			return x;
		}
	}
}

// returns 0 instead of 0.00 - otherwise return in float
@Pipe({ name: 'NAIfEmpty' })
export class NAIfEmpty implements PipeTransform {
	transform(x: number): String {
		let val: string;

		if (x === undefined || x === null) {
			return '';
		}

		// remove comma added by Angular number pipe to numbers 4 or more digits
		val = ValidateService.removeComma(x.toString());

		return ValidateService.isValidNumber(Number(val)) ? x.toString() : '';
	}
}

// returns 0 instead of 0.00 - otherwise return in float
@Pipe({ name: 'convertDotNetDate' })
export class ConvertDotNetDate implements PipeTransform {
	constructor(public translateService: TranslateService) { }
	transform(x: Date): string {
		let languageCode: string;

		if (!x) {
			return;
		}

		languageCode = this.translateService.getStandardLanguageCode();

		return x.getDate() + ' ' + x.toLocaleString(languageCode, { month: 'short' }) + ' ' + x.getFullYear();
	}
}

@Pipe({ name: 'localeMonth' })
export class LocaleMonth implements PipeTransform {
	constructor(public translateService: TranslateService) { }
	transform(x: Date): string {
		let languageCode: string;

		if (!x) {
			return;
		}

		languageCode = this.translateService.getStandardLanguageCode();

		return x.toLocaleString(languageCode, { month: 'short' });
	}
}

@Pipe({ name: 'stringifyList' })
export class StringifyListPipe implements PipeTransform {
	transform(values: string[]): string {
		let string: string;

		if (!values.length) {
			return '';
		}

		string = values.shift();

		for (let value of values) {
			string = string += ', ' + value;
		}

		return string;
	}
}

@Pipe({ name: 'yesNo' })
export class YesNoPipe implements PipeTransform {
	transform(value: boolean): string {
		return value ? 'Yes' : 'No';
	}
}

@Pipe({ name: 'plural' })
export class PluralPipe implements PipeTransform {
	transform(value: string, count: number): string {
		let lastCharacter: string;
		let lastTwoCharacters: string;

		if (count === 1) {
			return value;
		}

		lastCharacter = value.slice(-1);
		lastTwoCharacters = value.slice(-2);

		if (lastCharacter === 'x' || lastCharacter === 'z' || lastCharacter === 's' ||
			lastTwoCharacters === 'sh' || lastTwoCharacters === 'ch') {
			return value + 'es';
		}

		return value + 's';
	}
}
