import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ICommodityTypeDialogInput, ICommodityType } from '../../../../models/commodity-type/interfaces';
import { CropTypeService } from '../../../../models/crop-type/service';
import { CommodityService } from '../../../../models/commodity-type/service';
import { UpdateService } from '../../../../services/update.service';
import { eDialogViews } from '../../../../interfaces/abstract';
import { CommodityTypeDialog } from './dialog';
import { CommodityType } from '../../../../models/commodity-type/commodityType';
import { ObjectUtility } from '../../../../classes/objectUtility';
import { CaspService } from '../../../casp-dialog/casp.service';

@Component({
	moduleId: module.id,
	selector: 'commodity-type-create-dialog',
	templateUrl: 'create.html',
	styleUrls: ['create.scss']
})

export class CommodityTypeCreateDialog extends CommodityTypeDialog implements OnInit {

	public commodityTypes: ICommodityType[];
	public selectedCommodityType: ICommodityType;

	constructor(
		protected _dialog: MatDialog, // used for CASP dialog
		public dialogRef: MatDialogRef<CommodityTypeDialog>,
		@Inject(MAT_DIALOG_DATA) public data: ICommodityTypeDialogInput,
		protected cropTypeService: CropTypeService,
		protected commodityService: CommodityService,
		protected updateService: UpdateService,
		protected _caspService: CaspService,
	) {

		super(_dialog, dialogRef, data, cropTypeService, commodityService, updateService, _caspService);
		this.commodityTypeId = null; // on create, commodity type is not selected
	}

	ngOnInit(): void {
		this.view = eDialogViews.CREATE;
		this.viewCache = this.view;

		// get a list of commodity types here

		this.commodityService.listAllByRanch(this.ranchGuid).then(data => {
			this.commodityTypes = CommodityType.filterAvailable(data);
		});
	}

	/**
     * Get crop types when user selects a commodity type
     * @param commodityTypeId
     */
	public onCommodityTypeSelected(commodityTypeId: number) {
		if (commodityTypeId === null) {
			this.commodityTypeId = null;
			this.selectedCommodityType = null;
			return;
		}

		if (!this.commodityTypes) {
			return;
		}

		this.commodityTypeId = commodityTypeId;
		this.selectedCommodityType = this.commodityTypes.find(x => x.Id === commodityTypeId);

		this.cropTypeService.getAllByRanch(this.ranchGuid,
			this.commodityTypeId).then(data => {

				this.cropTypes = data;
				this.validateCropTypes();
				this.cropTypesOriginal = ObjectUtility.copyArray(this.cropTypes);
			});
	}
}
