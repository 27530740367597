import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, Validators, ValidatorFn, FormGroup, AsyncValidatorFn } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { FlowmeterChartUpdateService } from './flowmeter-chart.update.service';
import { FlowmeterSettings } from './interfaces';
import { FlowmeterService } from './service';
import { NotificationService } from '../../services/notification.service';
import { eIrrigationMethods, eNotificationTypes } from '../../interfaces/constants';

export type FlowmeterSettingFields = 'FlowMeterFileName' | 'FlowMeterArea' |
	'FlowMeterThresholdSprinkler' |'DripFlowMeterArea' | 'FlowMeterThresholdDrip' |
	'MicroSprinklerFlowMeterArea' | 'FlowMeterThresholdMicroSprinkler';

export interface IFlowmeterMethodSetting {
	area: AbstractControl,
	threshold: AbstractControl
};

@Component({
	moduleId: module.id,
	selector: 'flowmeter-settings',
	templateUrl: 'flowmeter-settings.html',
	animations: [
		trigger('slideInOut', [ // animation for showing/hiding Cover UI
			transition(':enter', [
				style({ 'height': '0px', opacity: 0 }),
				animate('2000ms ease-in-out', style({ 'height': '*', opacity: 1 }))
			]),
			transition(':leave', [
				animate('2000ms ease-in-out', style({ 'height': '0px', opacity: 0 }))
			])
		])
	]
})

export class FlowmeterSettingsComponent implements OnInit, OnDestroy {

	public IRRIGATION_METHODS = eIrrigationMethods;
	public isLoaded = false;
	public isSaving = false;

	public requiredMethods: {
		sprinkler: boolean,
		drip: boolean,
		microSprinkler: boolean
	};

	private _subscriptions$: Subject<boolean>;
	public form: FormGroup;
	public isTree: boolean;
	private _plantingId: number;
	private _settings: FlowmeterSettings;

	public get f(): { [key in FlowmeterSettingFields]: AbstractControl } {
		return this.form.controls as { [key in FlowmeterSettingFields]: AbstractControl };
	}

	constructor(
		private _fb: FormBuilder,
		public dialogRef: MatDialogRef<FlowmeterSettingsComponent>,
		private _flowmeterService: FlowmeterService,
		private _flowmeterChartUpdateService: FlowmeterChartUpdateService,
		private _notificationService: NotificationService,
		@Inject(MAT_DIALOG_DATA) private _data: { lotPlantingId: number },
	) {
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();
		this._plantingId = this._data.lotPlantingId;

		// custom click outside behavior
		this.dialogRef.disableClose = true;

		this.dialogRef.backdropClick().pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this._close();
		});

		this._flowmeterService.getSettings(this._plantingId).then((response) => {
			if (!response) {
				this._notificationService.generateNotifcation({
					type: eNotificationTypes.ERROR,
					message: 'Flow meter settings for this planting could not be retrieved'
				});

				throw new Error('flowmeter settings could not be retrieved');
			}

			this._settings = response;
			this.isTree = this._settings.IsTree;

			this.requiredMethods = {
				sprinkler: this._settings.CustomIrrigationMethods.find(x => x === eIrrigationMethods.SPRINKLER) ? true : false,
				drip: this._settings.CustomIrrigationMethods.find(x => x === eIrrigationMethods.DRIP) ? true : false,
				microSprinkler: this._settings.CustomIrrigationMethods.find(x => x === eIrrigationMethods.MICRO_SPRINKLER) ? true : false
			}

			this._initializeForm(this._settings);
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public cancel(): void {
		this._close();
	}

	private _close(): void {
		this.dialogRef.close();
		this._flowmeterChartUpdateService.dialogClosed();
	}

	public save(): void {
		if (this.form.invalid) {
			return;
		}

		Object.assign(this._settings, this.form.value);

		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this._flowmeterService.saveSettings(this._plantingId, this._settings).then(() => {
			this.isSaving = false;
			this._close();
		});
	}

	private _initializeForm(settings: FlowmeterSettings): void {

		let validators: {
			area: ValidatorFn[],
			threshold: ValidatorFn[]
		};

		validators = {
			threshold: [Validators.min(0.01), Validators.max(999.99)],
			area: [Validators.min(0.01), Validators.max(999.99)]
		};

		this.form = this._fb.group({
			FlowMeterFileName: [settings.FlowMeterFileName, {updateOn: 'blur'}],
			FlowMeterArea: [settings.FlowMeterArea, this.requiredMethods.sprinkler ? validators.area : null],
			FlowMeterThresholdSprinkler: [settings.FlowMeterThresholdSprinkler,
				this.requiredMethods.sprinkler ? validators.threshold : null],
			DripFlowMeterArea: [settings.DripFlowMeterArea,
				this.requiredMethods.drip ? validators.area : null],
			FlowMeterThresholdDrip: [settings.FlowMeterThresholdDrip,
				this.requiredMethods.drip ? validators.threshold : null],
			MicroSprinklerFlowMeterArea: [settings.MicroSprinklerFlowMeterArea,
				this.requiredMethods.microSprinkler ? validators.area : null],
			FlowMeterThresholdMicroSprinkler: [settings.FlowMeterThresholdMicroSprinkler,
				this.requiredMethods.microSprinkler ? validators.threshold : null],
		});

		this.f.FlowMeterFileName.setValidators(Validators.required);
		this.f.FlowMeterFileName.setAsyncValidators(this._validateFileName());
	}

	private _validateFileName(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<{[key: string]: any} | null> => {

			return from(this._flowmeterService.isFilenameValid(control.value).then((resp) => {
				return resp ? null : {'fileDoesNotExist': {value: control.value}};
			}));
		};
	}
}
