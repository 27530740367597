import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SharedUpdateService {
	private _keep: Subject<null> = new Subject<null>();
	public keep$ = this._keep.asObservable();

	private _discard: Subject<null> = new Subject<null>();
	public discard$ = this._discard.asObservable();

	private _delete: Subject<null> = new Subject<null>();
	public delete$ = this._delete.asObservable();

	private _deleteComplete: Subject<null> = new Subject<null>();
	public deleteComplete$ = this._deleteComplete.asObservable();

	private _cancel: Subject<null> = new Subject<null>();
	public cancel$ = this._cancel.asObservable();

	public keep() {
		this._keep.next();
	}

	public discard() {
		this._discard.next();
	}

	public delete() {
		this._delete.next();
	}

	public deleteComplete() {
		this._deleteComplete.next();
	}

	public cancel() {
		this._cancel.next();
	}
}
