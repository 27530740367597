import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class RainfallChartUpdateService {
	protected onClose: Subject<void> = new Subject<void>();
	public onClose$: Observable<void> = this.onClose.asObservable();

	public dialogClosed(): void {
		this.onClose.next();
	}
}
