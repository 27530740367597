import { Injectable } from '@angular/core';
import { eNotificationTypes } from '../../interfaces/constants';
import { NotificationService } from '../../services/notification.service';
import { UpdateService } from '../../services/update.service';
import { IPlanting } from '../planting-settings/interfaces';
import { PlantingService } from '../planting-settings/service';
import { RanchService } from '../ranch-settings/service';

@Injectable()
export class PlantingRecalculatorService {
	public constructor(
		private _notificationService: NotificationService,
		private _plantingService: PlantingService,
		private _ranchService: RanchService,
		private _updateService: UpdateService
		) {
	}

	public recalculate(plantingId: number, irrigationOnly = false, fertilizationOnly = false) {
		this._plantingService.recalculatePlanting(plantingId, irrigationOnly, fertilizationOnly).then((res) => {
			this.processEventStream(res, plantingId);
		});
	}

	public processEventStream(res: IPlanting, plantingId: number) {
		if (!res || !res.Events) {
			this._notificationService.generateNotifcation({
				type: eNotificationTypes.ERROR,
				message: 'The planting recalculation failed'
			});

			return;
		} else {
			this._notificationService.generateNotifcation({
				type: eNotificationTypes.UPDATE,
				message: 'The planting was successfully recalculated'
			});
		}

		this._ranchService.plantings.replacePlantingEvents(res.Events, plantingId);
		this._updateService.setPlantingsUpdated(this._updateService.currentRanchId, new Date()); // update views if needed
	}
}
