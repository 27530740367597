import { ValidatorFn } from '@angular/forms';
import { Coordinates } from '../../classes/Coordinates';
import { DateUtility } from '../../classes/dateUtility';
import { eCommodityTypes } from '../../interfaces/constants';
import * as moment from 'moment';
import { ICropType } from '../../models/crop-type/interfaces';

export class PlantingSettingsValidator {

	public static arrayMustContainItem(fr: FormArray): ValidatorFn	 {
		return (): {[key: string]: any} | null => {
			let valid: boolean;

			valid = fr.controls.length > 0;

			return valid ? null : {'arrayMustContainItem': {value: fr.controls.length }};
		};
	}

	/**
	 * Obstruction depth field is required if user enabled the UI
	 * @param fg
	 * @returns
	 */
	public static requiredIfObstructionUIEnabled(fg: FormGroup): ValidatorFn	 {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let required: boolean;

			required = fg.get('ShowObstructionDepth').value;

			valid = !required || control.value ? true : false;

			return valid ? null : {'requiredIfObstructionUIEnabled': {value: control.value}};
		};
	}

	public static mustBeAnInteger(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			valid = !control.value || Number.isInteger(control.value);

			return valid ? null : {'mustBeAnInteger': {value: control.value}};
		}
	}

	/**
	 * Field is required if perennial commodity type and not caneberry,
	 * which is year specific
	 * @param fg
	 * @returns
	 */
	public static requiredIfNonCaneberryPerennials(fg: FormGroup): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let required: boolean;
			let isPerennial: boolean;
			let commodityTypeId: number;

			isPerennial = fg.get('CropSettings').get('IsPerennial').value;
			commodityTypeId = fg.get('CommodityTypeId').value;

			required = isPerennial && commodityTypeId !== eCommodityTypes.CANEBERRY
				? true : false;

			valid = !required || control.value ? true : false;

			return valid ? null : {'requiredForPerennials': {value: control.value}};
		};
	}

	/**
	 * For trees, the wet date and harvest date must not fall on different years.
	 * Note: Add this to either the wet date or harvest date but not both
	 * @param fg
	 * @returns
	 */
	public static mustBeOnSameYearAsHarvestDate(fg: FormGroup): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let isTree: boolean;

			isTree = fg.get('CropSettings').get('IsTree').value;

			if (!isTree) {
				valid = true;
			} else {
				valid = DateUtility.areSameYear(fg.get('WetDate').value,
					fg.get('HarvestDate').value);
			}

			return valid ? null : {'wetDateNotSameYearAsHarvestDate': {value: control.value}};
		};
	}

	public static coordinatesContainLatLng(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let coordinates: Coordinates;

			coordinates = new Coordinates(control.value);

			valid = (coordinates.lat && coordinates.lng) ? true : false;

			return valid ? null : {'invalidCoordinates': {value: control.value}};
		};
	}

	/**
	 * Wetdate must be before harvest date.
	 * This validates wet date
	 * @param fg
	 * @returns
	 */

	public static mustBeBeforeHarvestDate(fg: FormGroup): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			valid = moment(fg.get('HarvestDate').value).isAfter(control.value);

			return valid ? null : {'endDateOnOrAfterHarvestDate': {value: control.value}};
		};
	}

	public static mustBeAfterWetDate(fg: FormGroup): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			valid = moment(fg.get('WetDate').value).isSameOrBefore(control.value);

			return valid ? null : {'mustBeAfterWetDate': {value: control.value}};
		};
	}

	/**
	 * Validates a crop type's defaultCropTypeId to make sure that the crop type
	 * is a valid choice in the planting settings crop type dropdown
	 * @param cropTypes
	 * @returns
	 */
	public static mustBeValidDefaultCropTypeId(cropTypes: ICropType[]): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			valid = !control.value ||
				(cropTypes && cropTypes.find(x => x.Id === control.value)) ? true : false;

			return valid ? null : {'validDefaultCropTypeId': {value: control.value}};
		};
	}
}
