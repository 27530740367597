import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ValidateService } from './validate.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ModelService {

	constructor(
		protected httpService: HttpService,
		protected validateService: ValidateService) { }

	protected get(url: string, callback: Function, params?: HttpParams): Promise<any> {

		return this.httpService.get({
			url: url.toLocaleLowerCase(),
			searchParams: params,
			callback: callback,
			isWebAPI: true
		});
	}

	protected post(url: string, callback: Function, params: Object, shouldBypassServerErrorAlert = false): Promise<any> {
		return this.httpService
			.post({
				url: url.toLocaleLowerCase(),
				body: params,
				callback: callback,
				isWebAPI: true,
				shouldBypassServerErrorAlert: shouldBypassServerErrorAlert
			});
	}

	/**
	 *
	 * @param url
	 * @param callback
	 * @param params
	 * @param shouldBypassServerErrorAlert
	 * @returns result of callback if successful. Else will return CMError object
	 */
	protected put(url: string, callback: Function, params: Object, shouldBypassServerErrorAlert = false): Promise<any> {
		return this.httpService
			.put({
				url: url.toLocaleLowerCase(),
				body: params,
				callback: callback,
				isWebAPI: true,
				shouldBypassServerErrorAlert: shouldBypassServerErrorAlert
			});
	}

	protected deleteItem(url: string, callback: Function, params?: HttpParams): Promise<any> {
		return this.httpService
			.delete({
				url: url.toLocaleLowerCase(),
				searchParams: params,
				callback: callback,
				isWebAPI: true
			});
	}

}
