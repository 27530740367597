
<form *ngIf="soilSampleForm" [formGroup]="soilSampleForm">
	<div class="bk-modal-section__new-soil-sample">
		<h4 class="bk-new-soil-sample__title">
			{{'New Soil Sample' | translate}}
		</h4>
		<mat-form-field appearance="fill"
			class='mat-form-field--one-line mat-form-field--short'>
			<mat-label>{{ 'Event Date' | translate }}</mat-label>
			<input matInput [matDatepicker]="picker"
				required
				formControlName="EventDate">
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
			<mat-error *ngIf="fs.EventDate.invalid && (fs.EventDate.dirty ||
				fs.EventDate.touched)">
				{{'Soil sample event date must be on or before
				fertilization event date' | translate }}
			</mat-error>
		</mat-form-field>

		<label class="bk-modal__label">
			{{'Soil Moisture' | translate }}
		</label>
		<mat-radio-group
			aria-label=""
			name="soilMoisture"
			formControlName="SoilMoistureId">

			<mat-radio-button [value]="1"
				name="soilMoisture">
				{{'Dry' | translate}}
			</mat-radio-button>
			<mat-radio-button class="mat-radio-button--left-margin"
				[value]="2"
				name="soilMoisture">
				{{'Moist' | translate}}
			</mat-radio-button>
		</mat-radio-group>

		<p>Soil sample depth defaults to 0 to 1 feet when you create
			an soil sample event using this interface.
		</p>

		<ng-container *ngIf="!soilSampleEventDateIsFuture">
			<mat-form-field appearance="fill"
				class="mat-form-field--one-line mat-form-field--short"
				hintLabel="{{'Strip Nitrate Value' | translate}}">
				<mat-label>
					{{'Sample Reading' | translate}}
				</mat-label>
				<input matInput
					type="number"
					step="0.01"
					required
					name="Nitrogen"
					formControlName="Nitrogen"/>
				<span matSuffix class="mat-form-field-suffix--padded">
					{{'ppm' | translate}}
				</span>
			</mat-form-field>
		</ng-container>

		<div class="bk-modal-section__footer">
			<button class="bk-button bk-button--medium bk-button--gray-outline bk-button--margin-right"
				(click)="cancelAddingSoilSample($event)">
				{{'Cancel' | translate }}
			</button>
			<button class="bk-button bk-button--medium"
				(click)="addSoilSample()"
				[disabled]="soilSampleForm.invalid">
				{{'Add' | translate}}
			</button>
		</div>
	</div>
</form>