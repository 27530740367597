<div class="bk-dialog bk-dialog__event">

    <button *ngIf="view !== eventViews.DISCARD_CHANGES &&
        view !== eventViews.DELETE_CONFIRMATION"
        type="button"
        class="bk-button bk-dialog__close"
        (click)="close()">

        &times;
    </button>

    <h4 mat-dialog-title>
        <ng-container *ngIf="!id">
            {{ 'Add' | translate }}
        </ng-container>

        <ng-container *ngIf="id && view !== eventViews.DELETE_CONFIRMATION">
            {{ 'Edit' | translate }}
        </ng-container>

        <ng-container *ngIf="id && view === eventViews.DELETE_CONFIRMATION">
            {{ 'Delete' | translate }}
        </ng-container>

        {{ 'Tissue Sample Event' | translate}}
    </h4>

    <div *ngIf="view === eventViews.FORM"
        mat-dialog-content
        [ngClass]="{ 'bk-cut-event--datepicker-open' : isEventDatePickerOpen }">
        <form [formGroup]="form">
            <mat-form-field appearance="fill"
                class='mat-form-field--one-line mat-form-field--short'>
                <mat-label>{{ 'Event Date' | translate }}</mat-label>
                <input matInput [matDatepicker]="picker"
                    required
                    [max]="maxDate"
                    formControlName="EventDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="f.EventDate.invalid && (f.EventDate.dirty ||
                    f.EventDate.touched)">
                    <div *ngIf="f.EventDate.errors.required">
                        {{ 'Event date is required' | translate }}
                    </div>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill"
                class="mat-form-field--one-line mat-form-field--short">
                <mat-label>
                    {{ 'Crop Stage' | translate }}
                </mat-label>
                <mat-select
                    formControlName="CropStageId">
                    <mat-option *ngFor="let cropStage of cropStages"
                        [value]="cropStage.Id">
                        {{cropStage.Name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf='locations !== null'>
                <mat-form-field appearance="fill"
                class="mat-form-field--one-line mat-form-field--short">
                    <mat-label>
                        {{ 'Part of Plant' | translate }}
                    </mat-label>
                    <mat-select
                        formControlName="LocationId">
                        <mat-option [value]=null selected>
                            {{'Please Select' | translate }}
                        </mat-option>
                        <mat-option *ngFor="let location of locations"
                            [value]="location.Id">
                            {{location.Name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf='f.LocationId.value === locationOtherId'
                    appearance="fill"
                    class='mat-form-field--one-line mat-form-field--short'>
                    <mat-label>
                        {{ 'Enter a custom sample location:' | translate }}
                    </mat-label>
                    <input matInput 
                        type="text"
                        step="0.01"
                        formControlName="LocationDetails"
                        name="LocationDetails"/>
                </mat-form-field>
            </ng-container>

            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ 'Macro Nutrients' | translate }}
                        </mat-panel-title>
                        <mat-panel-description></mat-panel-description>
                    </mat-expansion-panel-header>
                    <ng-container *ngFor="let nutrient of
                        f.Nutrients.get('macro').controls">
                        <div class="bk-modal__right-two-col">
                            <mat-form-field appearance="fill"
                                class="mat-form-field--one-line mat-form-field--w1">
                                <mat-label>
                                    {{ nutrient.get('Name').value | translate }}
                                </mat-label>
                                <input matInput
                                    type="number"
                                    step="0.01"
                                    [formControl]="nutrient.get('Value')"/>
                                <span matSuffix class="mat-form-field-suffix--padded">
                                    {{ (nutrient.get('MeasureUnitTypeId') ? nutrient.get('MeasureUnitTypeId').value
                                    : null) == measureUnitTypes.PPM ? 'ppm' : '%'}}
                                </span>
                            </mat-form-field> 
                        </div>
                    </ng-container>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ 'Micro Nutrients' | translate }}
                        </mat-panel-title>
                        <mat-panel-description></mat-panel-description>
                    </mat-expansion-panel-header>
                    <ng-container *ngFor="let nutrient of
                        f.Nutrients.get('micro').controls">
                        <div class="bk-modal__right-two-col">
                            <mat-form-field appearance="fill"
                                class="mat-form-field--one-line mat-form-field--w1">
                                <mat-label>
                                    {{ nutrient.get('Name').value | translate }}
                                </mat-label>
                                <input matInput
                                    type="number"
                                    step="0.01"
                                    [formControl]="nutrient.get('Value')"/>
                                <span matSuffix class="mat-form-field-suffix--padded">
                                    {{ getNutrientUnit(nutrient) }}
                                </span>
                            </mat-form-field> 
                        </div>
                    </ng-container>
                </mat-expansion-panel>
            </mat-accordion>

            <span *ngIf="id && lastUpdated"
                class='bk-modal__last-updated-meta bk-clearfix'>

                {{ 'Last updated by' | translate }} {{ lastUpdated.user }} {{ 'on' | translate }} {{ lastUpdated.timestamp}}
            </span>
        </form>
    </div>

    <div *ngIf="view === eventViews.DISCARD_CHANGES"
        mat-dialog-content>

        <div class="bk-dialog__paragraph">
            {{ 'The changes you made were not saved' | translate}}. {{'Discard changes' | translate}}?
        </div>
    </div>

    <div *ngIf="view === eventViews.DELETE_CONFIRMATION"
        mat-dialog-content>

        <div class="bk-dialog__paragraph">
            {{ 'Are you sure you want to delete this event' | translate}}?
        </div>
    </div>

    <div *ngIf="view === eventViews.FORM"
        mat-dialog-actions
        align="end">

        <button *ngIf="id"
            class="bk-button__delete" 
            (click)="onDelete($)">

            {{'Delete' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only bk-dialog__button--secondary"
            (click)="close()">

            {{ 'Cancel' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="save()"
            [disabled]="form.invalid">

            {{(isSaving ? 'Saving' : 'Save') | translate }}
            <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
        </button>
    </div>

    <div *ngIf="view === eventViews.DISCARD_CHANGES"
        mat-dialog-actions
        align="end">

        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only bk-dialog__button--secondary"
            (click)="revert()">
            {{ 'Keep Editing' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="close(true)">
            {{ 'Discard' | translate }}
        </button>
    </div>

    <div *ngIf="view === eventViews.DELETE_CONFIRMATION"
        mat-dialog-actions
        align="end" >

        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only bk-dialog__button--secondary"
            (click)="revert()">
            {{ 'Keep Editing' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="delete()">
            {{ 'Delete' | translate }}
        </button>
    </div>
</div>