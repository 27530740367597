<div class="bk-admin__page">
	<h1>CropManage Privacy Policy</h1>

	<p>UC ANR has a long-standing practice of keeping grower information confidential to the fullest extent
		possible under UC policy and California statute, thereby ensuring the growers' continued cooperation in our
		UC research projects. This is an important issue to researchers throughout the University of California.
	</p>

	<h2>What information do we collect?</h2>

	<p>We collect information from you when you register on our site, or enter information regarding your farming
		operation. Specifically this includes your soil tests, watering and fertilization schedules, as well as the
		coordinates of your fields, and information that you enter about your ranches. When you create a user account,
		we store your name and email address to uniquely identify you in the system. We do not share your email address
		with third parties.
	</p>

	<h2>Google User Data</h2>

	<p>We use your full name and Google email address provded by Google to create your CropManage account if you choose
		to register using the Google Identity Service. The email address is used to uniquely identify you in the system,
		and your full name is used to populate your profile and personalize your experience. 
	</p>

	<h2>What do we use your information for?</h2>

	<p>The information you provide will ONLY be used in the manner necessary to provide you information through this
		system and provide decision support on irrigation and fertilizer applications. Your field coordinates are
		required in order to provide accurate weather information and determining the soil type from a national soil
		database.</p>
	<p>To the fullest extent allowable under UC policy and California statute your information will not be sold,
		exchanged, transferred, or given to any non-UC entity for any reason whatsoever, individually or in aggregate.
	</p>

	<h2>How do we protect your information?</h2>

	<p>We implement a variety of security measures to maintain the safety of your personal information when you enter,
		submit, or access your personal information.</p>

	<p>We use a secure server. All supplied sensitive information is transmitted via Secure Socket Layer (SSL)
		technology and then encrypted into our Database to be only accessed by you, or UC personnel authorized with
		special access rights to our systems for development purposes.</p>

	<p>After deleting your account, your information will be removed completely, and unavailable to any entity including
		UC ANR.</p>

	<h2>Do we use cookies?</h2>

	<p>We use cookies to help us remember and process the data for your login information. No cookies will contain
		information related to your farming practices.</p>

	<h2>Do we disclose any information to outside parties?</h2>

	<p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.
		However, it is possible that we may have to release your information if required by law.</p>

	<h2>To remain completely anonymous:</h2>
	
	<p>Some users of this software may prefer to remain completely anonymous. In order to ensure your privacy, there are
		two steps to take:</p>
	<ul>
		<li>Use an anonymous email address when registering for this system.</li>
		<li>Do not enter the real name of your farm or fields. Use fictional names instead.</li>
	</ul>
</div>