<table class="bk-event__table bk-flowmeter">
	<thead>
		<tr>
			<td class="bk-event-table__header bk-flowmeter__header">{{'Date and Time' | translate }}</td>
			<td class="bk-event-table__header bk-flowmeter__header">{{'Average Flow Rate' | translate }} ({{'gpm' | translate }})</td>
			<td class="bk-event-table__header bk-flowmeter__header">{{'Cumulative Gallons' | translate }}</td>
			<td class="bk-event-table__header bk-flowmeter__header">{{'Applied Flowmeter Water' | translate }} ({{'in' | translate }})</td>
			<td class="bk-event-table__header bk-flowmeter__header">{{'Application Rate' | translate }} ({{'in/hr' | translate }})</td>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let data of flowmeterData">
			<td class="bk-event-table__cell bk-flowmeter__cell">{{data.DateAndTime}}</td>
			<td class="bk-event-table__cell bk-flowmeter__cell">{{data.AvgFlow || data.AvgFlow}}</td>
			<td class="bk-event-table__cell bk-flowmeter__cell">{{data.Gallons || data.Gallons}}</td>
			<td class="bk-event-table__cell bk-flowmeter__cell">{{data.Applied || data.Applied}}</td>
			<td class="bk-event-table__cell bk-flowmeter__cell">{{data.AppRate || data.AppRate}}</td>
		</tr>
	</tbody>
</table>