import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IMyDpOptions, IMyDateModel, MyDatePicker } from 'mydatepicker';

import { WeatherStationsService } from '../../models/weather-station-stats/service';
import { DateUtility } from '../../classes/dateUtility';
import { NotificationService } from '../../services/notification.service';
import { WeatherStationDialog } from './weather-station-dialog';
import { UpdateService } from '../../services/update.service';
import { WeatherStationStatsDialog } from './weather-station-stats-dialog';
import { DeactivateWeatherStationDialog } from './deactivate-weather-station-dialog';
import { ObjectUtility } from '../../classes/objectUtility';

import { INotificationParams, SuccessResponse } from '../../interfaces/interfaces';
import { IWeatherStationAdvanced } from './interfaces';
import { IWeatherStationRegion } from '../../models/region/interfaces';
import { eNotificationTypes } from '../../interfaces/constants';
import { WeatherStation } from './weatherStation';
import { NavigationExtras, Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	moduleId: module.id,
	selector: 'weather-stations',
	templateUrl: 'weather-stations.component.html'
})

export class WeatherStationsComponent implements OnInit, OnDestroy {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild('startDatePicker', { static: true }) startDatePicker: MyDatePicker;
	@ViewChild('endDatePicker', { static: true }) endDatePicker: MyDatePicker;

	private defaultStartdate: Date = new Date(2011, 0, 1);
	private defaultEndDate: Date = new Date();

	public dataSource: MatTableDataSource<IWeatherStationAdvanced>;
	public weatherStations: IWeatherStationAdvanced[] = new Array();
	public originalWeatherStations: IWeatherStationAdvanced[] = new Array();
	public regions: IWeatherStationRegion[] = new Array();
	public regionId: number = null;

	public displayedColumns: string[] = ['Name', 'NearestCity', 'County', 'RegionName',
		'ExternalId', 'MissingRecords', 'RanchCount', 'RecommendationCount', 'Action'];

	public startDateFilter: { jsdate: Date };
	public endDateFilter: { jsdate: Date } = { jsdate: this.defaultEndDate };
	public startDateFilterOptions: IMyDpOptions = {
		showClearDateBtn: false
	};
	public endDateFilterOptions: IMyDpOptions = {
		showClearDateBtn: false
	};
	public filterValue = '';

	public isDateRangeValid = {
		startDate: true as boolean,
		endDate: true as boolean
	}

	private _subscriptions$: Subject<boolean>;

	constructor(
		private _router: Router,
		private _tokenService: TokenService,
		private weatherStationsService: WeatherStationsService,
		private notificationService: NotificationService,
		private dialog: MatDialog,
		private updateService: UpdateService) {

		if (this._tokenService.isAuthenticated() === false) {
			this._router.navigate(['login']);
		}
	}

	ngOnInit(): void {
		let minDate: Date;
		let maxDate: Date;

		this._subscriptions$ = new Subject();

		this.defaultStartdate = DateUtility.subtractDays(this.defaultEndDate, 90);
		this.startDateFilter = { jsdate: this.defaultStartdate };

		this.weatherStationsService.listRegions()
			.then(response => {
				this.regions = response;
				this.updateService.setweatherStationRegionsList(this.regions);
			});

		this.getWeatherStations(this.defaultStartdate, this.defaultEndDate);

		minDate = DateUtility.subtractDays(this.defaultEndDate, 30);
		maxDate = DateUtility.addDays(this.defaultEndDate, 1);

		// filter options are meant to set min/max on the date UI
		this.startDateFilterOptions = this.setFilterMaxDate(this.startDateFilterOptions, maxDate);
		// updating filter options not working so don't set min
		// this.endDateFilterOptions = this.setFilterMinDate(this.endDateFilterOptions, minDate);
		this.endDateFilterOptions = this.setFilterMaxDate(this.endDateFilterOptions, maxDate);

		this.updateService.updatedWeatherStation$.pipe(takeUntil(this._subscriptions$)).subscribe(updatedWeatherStation => {
			for (let weatherStation of this.originalWeatherStations) {
				if (weatherStation.Id === updatedWeatherStation.Id) {
					WeatherStation.copyAdvanced(updatedWeatherStation, weatherStation);
				}
			}

			this.filterByRegion(this.regionId);
		});

		this.updateService.createdWeatherStation$.pipe(takeUntil(this._subscriptions$)).subscribe(createdWeatherStation => {
			if (!createdWeatherStation) {
				return;
			}

			let weatherStation: IWeatherStationAdvanced = {
				Id: createdWeatherStation.Id,
				Name: createdWeatherStation.Name,
				ExternalId: createdWeatherStation.External_Id,
				County: createdWeatherStation.County,
				NearestCity: createdWeatherStation.NearestCity,
				RegionId: createdWeatherStation.RegionId,
				RegionName: createdWeatherStation.RegionName,
				Coordinates: createdWeatherStation.Coordinates,
				UcIpmName: createdWeatherStation.UcIpmName,
				WeatherAPI_Id: createdWeatherStation.WeatherAPI_Id,
				RanchCount: 0,
				RecommendationCount: 0,
				Active: createdWeatherStation.Active,
				AllowToDeactivate: createdWeatherStation.AllowToDeactivate,
				API_Id: createdWeatherStation.API_Id
			};

			this.originalWeatherStations.push(weatherStation);
			this.filterByRegion(this.regionId);
		});

		this.updateService.deletedWeatherStation$.pipe(takeUntil(this._subscriptions$)).subscribe(deletedWeatherStation => {
			if (!deletedWeatherStation) {
				return;
			}

			this.originalWeatherStations = this.originalWeatherStations.filter(x => x.Id !== deletedWeatherStation.Id);
			this.filterByRegion(this.regionId);
		});

		this.updateService.deactivatedWeatherStation$.pipe(takeUntil(this._subscriptions$)).subscribe(id => {
			for (let weatherStation of this.originalWeatherStations) {
				if (weatherStation.Id === id) {
					weatherStation.Active = false;
				}
			}

			this.filterByRegion(this.regionId);
		});

		if (this.sort) {
			if (this.sort.sortChange) {
				this.sort.sortChange.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
					while (this.paginator.hasPreviousPage()) {
						this.paginator.previousPage();
					}
				});
			}

			this.sort.sort({ id: 'Name', start: 'asc', disableClear: false });
		}
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public search(query: string): void {
		if (!this.dataSource) {
			return;
		}

		if (!query) {
			query = ''; // send empty string to clear search filter
		}

		query = query.trim();
		query = query.toLowerCase();
		this.dataSource.filter = query;
	}

	public filterByRegion(regionId: number): void {
		if (regionId) {
			this.weatherStations = this.originalWeatherStations.filter(x => x.RegionId === regionId);
		} else {
			this.weatherStations = ObjectUtility.copyArray(this.originalWeatherStations);
		}

		this.setDataSource(this.weatherStations);
	}

	public startDateFilterChanged(date: IMyDateModel): void {
		let startDate: Date;
		let endDate: Date;
		let minDate: Date;

		// reset end date validation
		endDate = this.endDateFilter ? this.endDateFilter.jsdate : null;
		this.isDateRangeValid.endDate = endDate && endDate instanceof Date;

		if (!date || !date.jsdate) {
			this.isDateRangeValid.startDate = false;
			return;
		}

		startDate = date.jsdate;

		// update min date on end date picker (currently not working)
		minDate = DateUtility.subtractDays(startDate, 1);
		this.endDateFilterOptions = this.setFilterMinDate(this.endDateFilterOptions, minDate);
		this.endDatePicker.options = this.endDateFilterOptions;

		this.isDateRangeValid.startDate = this.isDateRangeValid.endDate ? startDate <= endDate : true;

		if (this.isDateRangeValid.startDate && this.isDateRangeValid.endDate) {
			this.getWeatherStations(startDate, endDate);
		}
	}

	public endDateFilterChanged(date: IMyDateModel): void {
		let startDate: Date;
		let endDate: Date;
		let maxDate: Date = new Date();

		// reset end date validation
		startDate = this.startDateFilter ? this.startDateFilter.jsdate : null;
		this.isDateRangeValid.startDate = startDate && startDate instanceof Date;

		if (!date || !date.jsdate) {
			this.isDateRangeValid.endDate = false;
			return;
		}

		endDate = date.jsdate;

		// update max date on start date picker (currently not working)
		if (endDate < maxDate) {
			maxDate = endDate;
		}
		maxDate = DateUtility.addDays(maxDate, 1);
		this.startDateFilterOptions = this.setFilterMaxDate(this.startDateFilterOptions, maxDate);
		this.startDatePicker.options = this.startDateFilterOptions;

		this.isDateRangeValid.endDate = this.isDateRangeValid.startDate ? endDate >= startDate : true;

		if (this.isDateRangeValid.startDate && this.isDateRangeValid.endDate) {
			this.getWeatherStations(startDate, endDate);
		}
	}

	public update(id: number): void {
		let notificationParams: INotificationParams;

		this.weatherStationsService.updateCIMISData(id)
			.then((response: SuccessResponse) => {
				if (response && response.Message) {
					notificationParams = {
						type: eNotificationTypes.UPDATE,
						message: 'Update process started'
					}
				} else {
					notificationParams = {
						type: eNotificationTypes.ERROR,
						message: 'An error occured while updating. Please try again'
					}
				}

				this.notificationService.generateNotifcation(notificationParams);
			});
	}

	public openWeatherStationStatsDialog(id: number): void {
		let data: { id: number, startDate: Date, endDate: Date } = {
			id: id,
			startDate: this.startDateFilter.jsdate,
			endDate: this.endDateFilter.jsdate
		};

		this.dialog.open(WeatherStationStatsDialog, {
			width: '1000px',
			data: data
		});
	}

	public activate(id: number): void {
		this.weatherStationsService.changeActiveStatus(id)
			.then(response => {
				if (!response || !response.Message) {
					return;
				}

				for (let weatherStation of this.weatherStations) {
					if (weatherStation.Id === id) {
						weatherStation.Active = true;
						weatherStation.AllowToDeactivate = true;
					}
				}
			});
	}

	public details(station: IWeatherStationAdvanced): void {
		let navigationExtras: NavigationExtras = {
			queryParams: {
				'stationId': parseInt(station.ExternalId, 10),
				'name': station.Name
			}
		}

		this._router.navigate(['MissingStationRecords'], navigationExtras);
	}

	public openDeactivateWeatherStationDialog(weatherStation: IWeatherStationAdvanced): void {
		this.dialog.open(DeactivateWeatherStationDialog, {
			width: '460px',
			data: weatherStation
		});
	}

	public openWeatherStationDialog(weatherStation?: IWeatherStationAdvanced): void {
		let data: { weatherStation: IWeatherStationAdvanced, canBeDeleted: boolean };

		data = weatherStation ? { weatherStation: weatherStation, canBeDeleted: weatherStation.RanchCount === 0 } : null;

		this.dialog.open(WeatherStationDialog, {
			width: '980px',
			disableClose: true,
			data: data
		});
	}

	private getWeatherStations(startDate: Date, endDate: Date): void {
		this.weatherStationsService.getWeatherStationAdvancedList(startDate, endDate)
			.then(stations => {
				if (!stations) {
					return;
				}

				this.originalWeatherStations = ObjectUtility.copyArray(stations);
				this.filterByRegion(this.regionId);
			});
	}

	private setFilterMaxDate(options: IMyDpOptions, maxDate: Date): IMyDpOptions {
		if (!options || !maxDate) {
			return;
		}

		options.disableSince = {
			year: maxDate.getFullYear(),
			month: maxDate.getMonth() + 1,
			day: maxDate.getDate()
		}

		return options;
	}

	private setFilterMinDate(options: IMyDpOptions, minDate: Date): IMyDpOptions {
		if (!options || !minDate) {
			return;
		}

		options.disableUntil = {
			year: minDate.getFullYear(),
			month: minDate.getMonth() + 1,
			day: minDate.getDate()
		}

		return options;
	}

	private setDataSource(weatherStations: IWeatherStationAdvanced[]): void {
		this.dataSource = new MatTableDataSource(weatherStations);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;

		this.dataSource.filterPredicate = (data: IWeatherStationAdvanced, filter: string) => {
			return data.Name.toLowerCase().includes(filter) ||
				data.County.toLowerCase().includes(filter) ||
				data.ExternalId.toLowerCase().includes(filter);
		};

		this.search(this.filterValue);
	}
}
