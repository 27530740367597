import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';

import { UpdateService } from '../../services/update.service';
import { IgnoreSchedulesService } from './service';
import { WeatherStationsService } from '../../models/weather-station-stats/service';
import { ObjectUtility } from '../../classes/objectUtility';

import { IWeatherStation } from '../weather-stations/interfaces';
import { iIgnoreSchedule } from './interfaces';
import { eDialogFormViews } from '../../interfaces/constants';
import { httpStatusCodes } from '../../interfaces/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	moduleId: module.id,
	selector: 'ignore-schedule-dialog',
	templateUrl: 'ignore-schedule-dialog.html'
})

export class IgnoreScheduleDialog implements OnInit, OnDestroy {

	public ignoreSchedule: iIgnoreSchedule;
	public weatherStations: IWeatherStation[] = new Array();
	public showErrors = false;
	public view: eDialogFormViews = eDialogFormViews.FORM;
	public ignoreScheduleViewsEnum = eDialogFormViews;
	public originalIgnoreSchedule: iIgnoreSchedule;
	public areIgnoreOptionsValid = true;

	public startDate: { jsdate: Date } = { jsdate: new Date() };
	public endDate: { jsdate: Date } = { jsdate: new Date() };
	public startDateOptions: IMyDpOptions = {
		showClearDateBtn: false
	};
	public endDateOptions: IMyDpOptions = {
		showClearDateBtn: false
	};
	public isDateRangeValid = {
		startDate: true as boolean,
		endDate: true as boolean
	}

	private _subscriptions$: Subject<boolean>;

	constructor(public dialogRef: MatDialogRef<IgnoreScheduleDialog>,
		@Inject(MAT_DIALOG_DATA) public data: iIgnoreSchedule,
		private ignoreSchedulesService: IgnoreSchedulesService,
		private weatherStationsService: WeatherStationsService,
		private updateService: UpdateService) {

		this._subscriptions$ = new Subject();

		this.weatherStationsService.list().then(response => {
			this.weatherStations = response;
		});

		this.updateService.weatherStationsList$
			.pipe(takeUntil(this._subscriptions$)).subscribe(weatherStations => {
				this.weatherStations = weatherStations
			});

		this.ignoreSchedule = data ? {
			Id: data.Id,
			StationName: data.StationName,
			StationId: data.StationId,
			StartDate: data.StartDate,
			EndDate: data.EndDate,
			IgnorePrecipitation: data.IgnorePrecipitation,
			IgnoreET: data.IgnoreET
		} : {
				StationId: null,
				StartDate: new Date(),
				EndDate: new Date(),
				IgnorePrecipitation: false,
				IgnoreET: false
			}

		this.startDate.jsdate = this.ignoreSchedule.StartDate;
		this.endDate.jsdate = this.ignoreSchedule.EndDate;

		this.originalIgnoreSchedule = ObjectUtility.copy(this.ignoreSchedule);

		this.dialogRef.afterOpen()
			.pipe(takeUntil(this._subscriptions$)).subscribe(event => {
			$('.bk-ignore-schedule-dialog').closest('.mat-dialog-container').css({ overflow: 'visible' });
		});

		this.updateService.closeModalSubscription
			.pipe(takeUntil(this._subscriptions$)).subscribe(event => this.dialogRef.close());
	}

	ngOnInit() {

	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public close(e?: MouseEvent): void {
		let element: Element;
		let classList: DOMTokenList;

		if (e) {
			element = e.target as Element;

			if (element) {
				classList = element.classList;

				if (classList) {
					if (classList.contains('bk-data-table__title--button-right') ||
						classList.contains('mat-button-wrapper') ||
						classList.contains('bk-button--medium') ||
						classList.contains('bk-data-table__button') ||
						classList.contains('headertodaybtn') ||
						classList.contains('bk-data-table__plus-icon') ||
						(element.closest('button') &&
							element.closest('button').classList &&
							element.closest('button').classList.contains('headertodaybtn'))
						) {

						return;
					}
				}
			}
		}

		if (this.view === eDialogFormViews.DELETECONFIRMATION) {
			return;
		}

		if (!this.ignoreSchedule || !this.originalIgnoreSchedule) {
			return;
		}

		if (this.view === eDialogFormViews.DISCARDCHANGES) {
			this.dialogRef.close();
			return;
		}

		if (ObjectUtility.isEqual(this.ignoreSchedule, this.originalIgnoreSchedule)) {
			this.dialogRef.close();
			return;
		}

		this.view = eDialogFormViews.DISCARDCHANGES;
	}

	public startDateChanged(date: IMyDateModel): void {
		let startDate: Date;
		let endDate: Date;

		// reset end date validation
		endDate = this.endDate ? this.endDate.jsdate : null;
		this.isDateRangeValid.endDate = endDate && endDate instanceof Date;

		if (!date || !date.jsdate) {
			this.isDateRangeValid.startDate = false;
			return;
		}

		startDate = date.jsdate;

		this.isDateRangeValid.startDate = this.isDateRangeValid.endDate ? startDate <= endDate : true;

		if (this.isDateRangeValid.startDate) {
			this.ignoreSchedule.StartDate = startDate;
		}
	}

	public endDateChanged(date: IMyDateModel): void {
		let startDate: Date;
		let endDate: Date;

		// reset start date validation
		startDate = this.startDate ? this.startDate.jsdate : null;
		this.isDateRangeValid.startDate = startDate && startDate instanceof Date;

		if (!date || !date.jsdate) {
			this.isDateRangeValid.endDate = false;
			return;
		}

		endDate = date.jsdate;

		this.isDateRangeValid.endDate = this.isDateRangeValid.startDate ? endDate >= startDate : true;

		if (this.isDateRangeValid.endDate) {
			this.ignoreSchedule.EndDate = endDate;
		}
	}

	public validateIgnoreOptions(): void {
		if (!this.ignoreSchedule) {
			return;
		}

		this.areIgnoreOptionsValid = this.ignoreSchedule.IgnoreET || this.ignoreSchedule.IgnorePrecipitation;
	}

	public add(): void {
		this.validateIgnoreOptions();
		this.showErrors = true;

		if (!this.ignoreSchedule.StationId || !this.isDateRangeValid.startDate || !this.isDateRangeValid.endDate || !this.areIgnoreOptionsValid) {
			return;
		}

		this.ignoreSchedulesService.create(this.ignoreSchedule)
			.then(response => {
				if (response) {
					response.StationName = this.weatherStations.filter(x => x.Id === response.StationId)[0].Name;
					this.updateService.createIgnoreSchedule(response);
				}

				this.dialogRef.close();
			});
	}

	public save(): void {
		this.showErrors = true;

		if (!this.ignoreSchedule.StationId || !this.isDateRangeValid.startDate || !this.isDateRangeValid.endDate || !this.areIgnoreOptionsValid) {
			return;
		}

		this.ignoreSchedulesService.update(this.ignoreSchedule)
			.then(() => {
				this.ignoreSchedule.StationName = this.weatherStations.filter(x => x.Id === this.ignoreSchedule.StationId)[0].Name;
				this.updateService.updateIgnoreSchedule(this.ignoreSchedule);

				this.dialogRef.close();
			});
	}

	public delete(): void {
		this.ignoreSchedulesService.delete(this.ignoreSchedule.Id)
			.then(response => {
				if (response) {
					this.updateService.deleteIgnoreSchedule(this.ignoreSchedule);
				}

				this.dialogRef.close();
			});
	}
}
