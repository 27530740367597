import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DateUtility } from '../../../classes/dateUtility';
import { IrrigationService } from '../../irrigation-event/service';
import { EvaporationRecordSimple, SimpleSingleCallIrrigationRecommendationModel } from '../../irrigation-event/interfaces';
import { IHighChartCustomSeries } from '../../available-water-chart/interfaces';
import * as Highcharts from 'highcharts';
import { HighChartsService } from '../../../classes/highCharts.service';
import { Router } from '@angular/router';
import { PersistentDatabase } from '../../../services/persistent-database';

export interface ETCalculatorResultsInputParams {
	data: SimpleSingleCallIrrigationRecommendationModel,
	CropTypeName: string,
	WeatherStationName: string,
	CommodityTypeId: number
}

export type ETCalculatorFields = 'AgeOfCrop' | 'CommodityTypeId' | 'CropTypeId' | 'WetDate'
	| 'HarvestDate' | 'Latitude' | 'Longitude' | 'WeatherStationId';

@Component({
	selector: 'et-calculator',
	templateUrl: 'et-calculator-results.component.html',
	styleUrls: [ 'et-calculator-results.component.scss']
})

export class ETCalculatorResultsComponent implements OnInit, OnDestroy {

	@ViewChild('chartContainer', { static: false }) private _chartContainer: ElementRef;

	public totals: {
		ETo: number,
		ETc: number,
		Rain: number,
		ETcRainDiff: number
	};

	public cropTypeName: string;
	public weatherStationName: string;
	public startDate: Date;
	public endDate: Date;

	private _data: SimpleSingleCallIrrigationRecommendationModel;
	private _evapRecords: EvaporationRecordSimple[];
	private _chartData: Highcharts.IndividualSeriesOptions[];
	private _subscriptions$: Subject<boolean>;

	constructor(
		private _irrigationService: IrrigationService,
		private _persistentDatabase: PersistentDatabase,
		private _router: Router,
	) {
		this.totals = {
			ETc: 0,
			ETo: 0,
			Rain: 0,
			ETcRainDiff: 0
		}
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();
		let params: ETCalculatorResultsInputParams;

		params = this._persistentDatabase.etCalculatorParams;

		if (params) {
			this._data = params.data;
			this.cropTypeName = params.CropTypeName;
			this.weatherStationName = params.WeatherStationName;
			this.startDate = this._data.WetDate;
			this.endDate = this._data.HarvestDate;
			this._calculate();
		}
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	private _calculate() {
		this._data.SoilProperties = {
			Sand: 0,
			Silt: 0
		};

		this._data.IrrigationEvents = [];
		this._data.EventDate = this._data.HarvestDate;

		this._irrigationService.getSeasonET(this._data).then((response) => {
			this._evapRecords = response;
			this._loadChartData();
		});
	}

	public goBack() {
		this._router.navigate(['et-calculator']);
	}

	private _loadChartData() {
		this._convertToGraphData(this._evapRecords);
		HighChartsService.drawGraph(this._chartContainer, this._chartData);
	}

	private _convertToGraphData(records: EvaporationRecordSimple[]) {
		let temp: {
			rain: IHighChartCustomSeries,
			et: IHighChartCustomSeries,
		};

		temp = {
			rain: {
				name: 'Rainfall',
				type: 'column',
				visible: false,
				data: [],
			},
			et: {
				name: 'ETc',
				type: 'line',
				visible: true,
				data: [],
				tooltip: {
					valueDecimals: 4
				}
			},
		}

		this._chartData = [];

		this.totals = {
			ETc: 0,
			ETo: 0,
			Rain: 0,
			ETcRainDiff: 0
		}

		if (!records || records.length === 0) {
			return;
		}

		for (let record of records) {
			temp.rain.data.push([DateUtility.DateToUTC(record.Date),
				record.Rain]);

			temp.et.data.push([DateUtility.DateToUTC(record.Date),
				record.ETc]);

			this.totals.ETc += record.ETc;
			this.totals.ETo += record.ETo;
			this.totals.Rain += record.Rain;
		}

		this.totals.ETcRainDiff = this.totals.ETc - this.totals.Rain;

		this._chartData.push(temp.rain);
		this._chartData.push(temp.et);
	}
}
