<div class="bk-dialog">

	<button type="button"
		class="bk-button bk-dialog__close"
		(click)="close()">

		&times;
	</button>

	<h4 mat-dialog-title>
		{{ 'Add' | translate }} {{'Fertilizer(s)' | translate }}
	</h4>

	<div mat-dialog-content>
		<div class="fertilizer-container bk-modal__left bk-modal__left-fertilizers">

			<div class="bk-modal__left-fertilizer-options block">

				<!-- radio options -->

				<mat-radio-group aria-label=""
					name="fertilizerAddChoice"
					[(ngModel)]="chooseFromList">

					<span *ngFor="let state of addStates"
					[class.bk-selected]="state.value === chooseFromList"
					class="bk-modal__label bk-modal__left-fertilizer-option">

						<mat-radio-button [value]="state.value"
						[disabled]="state.value && areThereFertilizers"
							name="fertilizerAddChoice">
							{{state.display}}
						</mat-radio-button>
					</span>
				</mat-radio-group>
			</div>
		</div>
		<ng-container *ngIf='fertilizers && chooseFromList'>
			<fertilizer-list [fertilizers]='fertilizers'>
			</fertilizer-list>
		</ng-container>
		<ng-container *ngIf='chooseFromList === false'>
			<fertilizer-edit [fertilizer]='fertilizer'
				(form)='updateFormState($event)'>
			</fertilizer-edit>
		</ng-container>
	</div>

	<div mat-dialog-actions align="end">

		<button class="bk-button bk-button--medium bk-button--text-only"
			(click)="close()">
			{{'Cancel' | translate }}
		</button>

		<button class="bk-button bk-button--medium"
			*ngIf="chooseFromList"
			[disabled]="areFertilizersSelected === false"
			(click)="addSelectedFertilizers()">
			{{'Add Selected Fertilizer(s)' | translate }}
		</button>

		<button [disabled]="isFormValid === false"
			class="bk-button bk-button--medium"
			*ngIf="!chooseFromList"
			(click)="create()">
			{{'Add Custom Fertilizer' | translate }}
		</button>
	</div>
</div>