import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { HttpService, HttpServicePostOptions, HttpServiceGetOptions } from '../../services/http.service';
import { DateUtility } from '../../classes/dateUtility';

import { RanchService } from '../ranch-settings/service';
import { IEventPostResponseJSON, IEventPostResponse } from '../../models/event/interfaces';
import { EventGroup } from '../../models/event/event';
import { TissueSampleEvent } from './tissueSampleEvent';
import { Internal, IncomingJSON, ITissueSampleEventService, TissueSampleEventUpdateParams } from './interfaces';
import { HttpParams } from '@angular/common/http';
import { ObjectUtility } from '../../classes/objectUtility';
import { EventResponse } from '../../models/event/eventResponse';


@Injectable()
export class TissueSampleEventService implements ITissueSampleEventService {

	private urls = {
		// save: '/v2/tissue-sample-events.json',
		update: (eventId: number) => `/v3/tissue-sample-events/${eventId}.json`,
		updateDateOnly: (eventId: number) => `/v3/tissue-sample-events/${eventId}/date-only.json`,
		create: (plantingId: number) => `/v3/plantings/${plantingId}/tissue-sample-events.json`,
		get: (id: number) => `/v2/tissue-sample-events/${id}.json`,
		delete: (id: number) => `/v2/tissue-sample-events/${id}.json`,
		locationList: '/v2/tissue-sample-locations.json',
		nutrientList: '/v2/tissue-sample-nutrients.json'
	}

	constructor(private httpService: HttpService,
		private ranchService: RanchService
	) { }

	public updateDateOnly(eventId: number, plantingId: number, date: Date): Promise<EventResponse> {
		let params: HttpParams;
		let options: HttpServicePostOptions;

		params = new HttpParams().set('eventDate', DateUtility.ConvertToUTC(date).toISOString());

		options = {
			url: this.urls.updateDateOnly(eventId),
			params: params,
			body: null,
			isWebAPI: true,

			callback: (response: IEventPostResponseJSON): EventResponse => {
				let result: EventResponse;

				result = new EventResponse(response);
				result.updateSucceedingEvents(plantingId, this.ranchService);

				return result;
			}
		}

		return this.httpService.put(options);
	}

	public create(plantingId: number, model: TissueSampleEventUpdateParams): Promise<EventResponse> {
		let options: HttpServicePostOptions;

		options = {
			url: this.urls.create(plantingId),
			body: model,
			isWebAPI: true,

			callback: (response: IEventPostResponseJSON): EventResponse => {
				let result: EventResponse;

				result = new EventResponse(response);
				result.updateSucceedingEvents(plantingId, this.ranchService);

				return result;
			}
		}

		return this.httpService.post(options);
	}

	public update(eventId: number, model: TissueSampleEventUpdateParams, plantingId: number): Promise<EventResponse> {
		let options: HttpServicePostOptions;

		options = {
			url: this.urls.update(eventId),
			body: model,
			isWebAPI: true,

			callback: (response: IEventPostResponseJSON): EventResponse => {
				let result: EventResponse;

				result = new EventResponse(response);
				result.updateSucceedingEvents(plantingId, this.ranchService);

				return result;
			}
		}

		return this.httpService.put(options);
	}

	public get(id: number): Promise<TissueSampleEvent> {
		let options: HttpServiceGetOptions;
		let params: HttpParams;

		params = new HttpParams().set('id', id.toString());

		options = {
			url: this.urls.get(id),
			searchParams: params,
			isWebAPI: true,

			callback: (event: IncomingJSON.ITissueSampleEventJSON) => {
				let tissueSampleEvent: TissueSampleEvent;

				if (!event) {
					return null;
				}

				tissueSampleEvent = new TissueSampleEvent();
				tissueSampleEvent.transmuteJSON(event);

				return tissueSampleEvent;
			}
		}

		return this.httpService.get(options);
	}

	public delete(id: number, plantingId: number): Promise<EventGroup[]> {
		let options: HttpServicePostOptions;

		let body: {
			plantingId: number,
		};

		if (!plantingId || !id) {
			return of(null).toPromise();
		}

		body = {
			plantingId: plantingId,
		}

		options = {
			body: body,
			url: this.urls.delete(id),
			isWebAPI: true,

			callback: (response: IEventPostResponseJSON): EventGroup[] => {
				let eventGroupsSucceeding: EventGroup[];

				EventGroup.updateEvents(response.EventGroupsSucceeding, plantingId,
					DateUtility.DotNetToDate(response.WetDate), this.ranchService);

				eventGroupsSucceeding = EventGroup.convertAll(response.EventGroupsSucceeding,
					DateUtility.DotNetToDate(response.WetDate));

				return eventGroupsSucceeding;
			}
		}

		return this.httpService.delete(options);
	}

	public locationList(): Promise<IncomingJSON.ITissueSampleLocation[]> {
		let options: HttpServiceGetOptions;

		options = {
			url: this.urls.locationList,
			useLocalStorage: true,
			isWebAPI: true,

			callback: (result: IncomingJSON.ITissueSampleLocation[]) => {
				return result;
			}
		}

		return this.httpService.get(options);
	}

	public nutrientList(): Promise<IncomingJSON.TissueSampleNutrient[]> {
		let options: HttpServiceGetOptions;

		options = {
			url: this.urls.nutrientList,
			useLocalStorage: true,
			isWebAPI: true,

			callback: (result: IncomingJSON.TissueSampleNutrient[]) => {
				return result;
			}
		}

		return this.httpService.get(options);
	}
}
