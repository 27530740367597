import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { CutEventService } from './service';
import { DateUtility } from '../../classes/dateUtility';
import { CutEvent, eCutEventViews } from './cutEvent';
import { RanchService } from '../ranch-settings/service';
import { UpdateService } from '../../services/update.service';

import { IMaturity } from '../../models/maturity/interfaces';
import { EventGroup } from '../../models/event/event';
import { FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventsType } from '../../interfaces/constants';
import * as moment from 'moment';
import { IPlanting } from '../planting-settings/interfaces';
import { FormValidation } from '../../classes/formValidation';
import { EventDialogParameters } from '../../classes/eventDialogUtility';
import { PlantingRecalculatorService } from '../planting-recalculate-modal/plantingRecalculatorService';

export type CutEventFields = 'Id' | 'EventDate' |
	'MaturityId' |'Yield';

@Component({
	moduleId: module.id,
	selector: 'cut-event',
	templateUrl: 'cut-event.component.html'
})

export class CutEventComponent implements OnInit, OnDestroy {

	public cutEventViews = eCutEventViews;
	public form: FormGroup;
	public germinationDate: Date;
	public id: number;
	public isFutureEvent: boolean = null; // determine if event is in the future,
		// which hides Yield and Crop Stage from UI
	public isSaving = false;
	public LastUpdatedUser: string;
	public maturities: IMaturity[];
	public maxDate: Date;
	public succeedingEventGroups: EventGroup[];
	public view: eCutEventViews = eCutEventViews.FORM;

	private _eventDateOriginal: Date;
	private _dateDefault: Date;
	private _harvestDate: Date;
	private _plantingId: number;
	private _planting: IPlanting;
	private _subscriptions$: Subject<boolean>;

	public get f(): { [key in CutEventFields]: AbstractControl } {
		return this.form.controls as { [key in CutEventFields]: AbstractControl };
	}

	constructor(
		private _cutEventService: CutEventService,

		@Inject(MAT_DIALOG_DATA) private _data: EventDialogParameters,

		private _dialogRef: MatDialogRef<CutEventComponent>,
		private _fb: FormBuilder,
		private _plantingRecalculatorService: PlantingRecalculatorService,
		private _ranchService: RanchService,
		private _updateService: UpdateService,
	) {
		if (this._data) {
			this.id = this._data.id;
			this.germinationDate = this._data.wetDate;
			this._plantingId = this._data.plantingId;
			this._dateDefault = this._data.dateDefault;
		}
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();
		this._planting = this._ranchService.plantings.getPlantingById(this._plantingId);

		if (this._planting) {
			this._harvestDate = this._planting.HarvestDate;
		} else {
			throw Error('no planting found');
		}

		this._initializeForm();
		// originalEvent needs to be assigned
		// before ajax call, or user will encounter
		// an error if he closes the popup before ajax finishes
		// this.event = new CutEvent(this.plantingId); // need to be set here -
		// otherwise template doesn't have
		// defensive code against this.event, which doesn't load till ajax comes back

		this._dialogRef.disableClose = true;

		this._dialogRef.backdropClick().pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.cancel();
		});

		if (this.id) {
			this._cutEventService.get(this.id, this._plantingId)
			.then((response: CutEvent) => {
				this._populateForm(response);
				this.LastUpdatedUser = response.LastUpdatedUser;
				this.form.markAsDirty();
				this._eventDateOriginal = response.EventDate;
			});
		} else {
			if (this._dateDefault) {
				this.f.EventDate.setValue(this._dateDefault);
			} else {
				this.f.EventDate.setValue(null);
			}

			// default to blank date here, unlike other events, because date change
			// is required to trigger historical UI.
			// for better consistency, we need to not attach workflow to cut events.
		}

		this._cutEventService.listMaturities()
			.then(response => this.maturities = response);
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public cancel(): void {
		if (this.form.touched) {
			this.view = eCutEventViews.DISCARD_CHANGES;
			return;
		}

		this.close();
	}

	public cancelDiscardingChanges(): void {
		this.view = eCutEventViews.FORM;
	}

	public close(): void {
		this._dialogRef.close();
		this._updateService.setEventDialogClosed();
	}

	public delete(): void {
		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this._cutEventService.delete(this.f.Id.value, this._plantingId).then(response => {
			this.isSaving = false;
			this._ranchService.plantings.deleteEventInPlantings(this.f.EventDate.value,
				this._plantingId, EventsType.CUTTING);

			this._updateService.setPlantingsUpdated(this._updateService.currentRanchId, new Date());

			this.succeedingEventGroups = response;

			if (this.succeedingEventGroups && this.succeedingEventGroups.length > 0) {
				this.view = eCutEventViews.SUCCEEDING_EVENTS;
			} else {
				this.close();
			}

			this._plantingRecalculatorService.recalculate(this._plantingId, true);
		});
	}

	public save(): void {
		if (this.form.invalid) {
			return;
		}

		if (this.isFutureEvent) {
			this.f.MaturityId.setValue(null);
			this.f.Yield.setValue(null);
		}

		if (this.f.Id.value) {
			this._update();
		} else {
			this._create();
		}
	}

	private _create(): void {
		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this._cutEventService.save(this._plantingId, this.f.EventDate.value,
			this.f.MaturityId.value, this.f.Yield.value, false).then((response) => {

			let eventGroup: EventGroup;

			this.isSaving = false;

			if (!response || !response.Events) {
				return;
			}

			eventGroup = response.Events[0];
			eventGroup.eventType = EventsType.CUTTING;

			this._ranchService.plantings.addEventToPlantings(eventGroup, this._plantingId);
			this._updateService.setPlantingsUpdated(this._updateService.currentRanchId, new Date());

			this.succeedingEventGroups = response.EventGroupsSucceeding;

			if (this.succeedingEventGroups && this.succeedingEventGroups.length > 0) {
				this.view = eCutEventViews.SUCCEEDING_EVENTS;
			} else {
				this.close();
			}

			this._plantingRecalculatorService.recalculate(this._plantingId, true);

			return;
		});
	}

	private _daysAfterGerminationValidator(date: Date): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let daysAfterGermination: number;

			daysAfterGermination = control.value ? DateUtility.daysBetweenDates(date, control.value)
				: null;

			valid = daysAfterGermination < 1 ? false : true;

			return valid ? null : {'daysAfterGerminationError': {value: control.value}};
		};
	}

	private _update(): void {
		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this._cutEventService.save(this._plantingId, this.f.EventDate.value,
			this.f.MaturityId.value, this.f.Yield.value, false, this.f.Id.value)
			.then((response) => {

			let eventGroup: EventGroup;

			this.isSaving = false;

			if (!response || !response.Events) {
				return;
			}

			eventGroup = response.Events[0];

			eventGroup.eventType = EventsType.CUTTING;
			eventGroup.originalEventDate = this._eventDateOriginal;

			this._ranchService.plantings.addEventToPlantings(eventGroup,
				this._plantingId, this.f.Id.value);
			this._updateService.setPlantingsUpdated(this._updateService.currentRanchId, new Date());

			this.succeedingEventGroups = response.EventGroupsSucceeding;

			if (this.succeedingEventGroups && this.succeedingEventGroups.length > 0) {
				this.view = eCutEventViews.SUCCEEDING_EVENTS;
			} else {
				this.close();
			}

			this._plantingRecalculatorService.recalculate(this._plantingId, true);

			return;
		});
	}

	private _initializeForm(): void {
		this.form = this._fb.group({
			Id: [null],
			EventDate: [null, [Validators.required,
				FormValidation.maxDateValidator(this._harvestDate),
				this._daysAfterGerminationValidator(this.germinationDate)]],
			MaturityId: [null],
			Yield: [null, Validators.min(0)]
		});

		this.maxDate = moment(this._harvestDate).subtract(1, 'days').toDate();

		this.f.EventDate.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.isFutureEvent = DateUtility.isFuture(this.f.EventDate.value);
		});
	}

	private _populateForm(model: CutEvent): void {
		this.f.Id.setValue(model.Id);
		this.f.EventDate.setValue(model.EventDate);
		this.f.MaturityId.setValue(model.MaturityId);
		this.f.Yield.setValue(model.Yield);
	}
}
