import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TranslateService } from './service';
import { ModalDirective } from 'ngx-bootstrap';

import { ILocalizationDictionary, ITranslation, eLanguageCodes, LanguageCode } from './interfaces';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { NotificationService } from '../../services/notification.service';
import { eNotificationTypes } from '../../interfaces/constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationComponent, DeleteConfirmationSettings } from '../shared/dialogs/delete-confirmation';
import { SharedUpdateService } from '../shared/dialogs/update.service';

@Component({
	moduleId: module.id,
	selector: 'localization',
	templateUrl: 'localization.component.html'
})

export class LocalizationComponent implements OnInit, OnDestroy {

	public searchQuery: string;
	public languages: LanguageCode[] = new Array();
	public translations: ITranslation[] = new Array(); // partially filtered set
	public translationsPermanent: ITranslation[]; // full set of results

	public addingSystemLabel = false;
	public editingSystemLabel = false;
	private _subscriptions$: Subject<boolean>;
	private _currentEntry: ITranslation;

	public newSystemLabel: ITranslation = {
		keyword: '',
		editing: false,
		translations: {}
	};

	constructor(
		private _dialog: MatDialog,
		private _router: Router,
		private _tokenService: TokenService,
		private _notificationService: NotificationService,
		private translateService: TranslateService,
		private _updateService: SharedUpdateService
		) {

		if (this._tokenService.isAuthenticated() === false) {
			this._router.navigate(['login']);
		}
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();

		this.translateService.loadLanguages()
			.then(res => {
				this.languages = res;
				this.prepNewSystemLabel(this.languages);
				this.getTranslations();
			});

		this._updateService.delete$.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this._delete(this._currentEntry);
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public applyFilter() {
		let result: ITranslation[];
		let query: string;

		if (!this.searchQuery) {
			query = '';
		} else {
			query = this.searchQuery.toLowerCase();
		}

		if (!this.translationsPermanent || this.translationsPermanent.length === 0) {
			return;
		}

		result = this.translationsPermanent.filter(x => x.keyword.toLowerCase().indexOf(query) !== -1
			|| (x.translations[eLanguageCodes.ENGLISH] && x.translations[eLanguageCodes.ENGLISH].word &&
				x.translations[eLanguageCodes.ENGLISH].word.toLowerCase().indexOf(query) !== -1));

		this.translations = result;
	}

	private getTranslations(override = false): void {
		this.translateService.loadTranslations(override)
			.then(res => {
				this.translations = res
				this.translationsPermanent = res;
				this.applyFilter();
			});
	}

	private prepNewSystemLabel(codes: LanguageCode[]): void {

		if (!this.newSystemLabel) {
			throw new Error('newSystemLabel is empty');
		}

		if (!this.newSystemLabel.translations) {
			throw new Error('newSystemLabel.translations is empty');
		}

		this.newSystemLabel.keyword = '';
		this.newSystemLabel.editing = false;

		if (!codes || codes.length === 0) {
			return;
		}

		for (let code of codes) {
			this.newSystemLabel.translations[code.Id] = {
				keyword: '',
				id: -1,
				languageCode: code.Id,
				word: '',
			}
		}
	}

	private cancelAddingSystemLabel(): void {
		this.addingSystemLabel = false;
		this.prepNewSystemLabel(this.languages);
	}

	/**
	 * Method for adding a new label. A different method is called when editing
	 */
	public addSystemLabel(): void {
		let newTranslations: ILocalizationDictionary[] = new Array();

		for (let language of this.languages) {
			if (this.newSystemLabel.translations[language.Id]) {
				newTranslations.push({
					languageCode: language.Id,
					word: this.newSystemLabel.translations[language.Id].word
				});
			}
		}
		this.translations.push(this.newSystemLabel);

		this.translateService.addTranslation(this.newSystemLabel.keyword, newTranslations)
			.then((errors) => {
				if (errors && errors.length > 0) {
					this._notificationService.generateNotifcation({
						type: eNotificationTypes.ERROR,
						message: errors
					});
				}

				this.getTranslations(true);
			});

		this.cancelAddingSystemLabel();
	}

	/**
	 * Method for editing system label
	 * @param entry
	 */
	public editSystemLabel(entry: ITranslation) {
		if (!entry) {
			return;
		}

		this.setNewSystemLabel(entry);

		if (!this.translations) {
			return;
		}

		for (let translation of this.translations) {

			if (!translation) {
				continue;
			}

			if (translation.keyword === entry.keyword) {
				translation.editing = true;
			}
		}

		this.editingSystemLabel = true;
	}

	/**
	 * clean label before editing
	 * @param entry
	 */
	private setNewSystemLabel(entry: ITranslation) {
		if (!this.newSystemLabel || !this.newSystemLabel.translations) {
			return;
		}

		if (!entry || !entry.translations) {
			return;
		}

		if (!this.languages) {
			return;
		}

		this.newSystemLabel.keyword = entry.keyword;

		for (let language of this.languages) {

			if (!language) {
				continue;
			}

			if (!this.newSystemLabel.translations[language.Id]) {
				continue;
			}

			if (!entry.translations[language.Id]) {
				continue;
			}

			this.newSystemLabel.translations[language.Id].keyword = entry.translations[language.Id].keyword;
			this.newSystemLabel.translations[language.Id].id = Number(entry.translations[language.Id].id);

			this.newSystemLabel.translations[language.Id].languageCode =
				Number(entry.translations[language.Id].languageCode);

			if (entry.translations[language.Id].word !== null) {
				this.newSystemLabel.translations[language.Id].word = entry.translations[language.Id].word;
			}
		}
	}

	private cancelEditingSystemLabel(entry: ITranslation) {
		for (let translation of this.translations) {
			if (translation.keyword === entry.keyword) {
				translation.editing = false;
			}
		}

		this.prepNewSystemLabel(this.languages);
		this.editingSystemLabel = false;
	}

	public onDelete(entry: ITranslation): void {
		let data: DeleteConfirmationSettings;

		data = {
			objectName: 'System Label',
			specificName: entry.keyword,
			additionalMessage: 'All translations associated with this label will also be deleted.'
		}

		this._currentEntry = entry;

		this._dialog.open(DeleteConfirmationComponent, {
			disableClose: true,
			data: data
		});
	}

	private _delete(entry: ITranslation): void {
		let ids: number[] = new Array();

		for (let language of this.languages) {
			if (!entry.translations[language.Id]) {
				continue;
			}

			ids.push(entry.translations[language.Id].id);
		}

		this.translateService.deleteTranslation(ids)
			.then(() => {
				this._updateService.deleteComplete();
				this.getTranslations(true);
			});
	}

	/**
	 * Method that fires when user clicks "Save" during editing
	 **/
	public saveSystemLabel(): void {
		let updateTranslations: ILocalizationDictionary[] = new Array();

		for (let language of this.languages) {
			updateTranslations.push({
				keyword: this.newSystemLabel.keyword,
				id: this.newSystemLabel.translations[language.Id].id,
				languageCode: this.newSystemLabel.translations[language.Id].languageCode,
				word: this.newSystemLabel.translations[language.Id].word
			})
		}
		this.translateService.saveTranslation(updateTranslations)
			.then(() => {
				this.cancelEditingSystemLabel(this.newSystemLabel);
				this.getTranslations(true);
			})
	}
}

