<div class="bk-dialog">

	<h4 mat-dialog-title>
		{{'Delete' | translate }} {{ objectName }}
	</h4>

	<div mat-dialog-content>
		<p>
			<span *ngIf="specificName">
				{{'Are you sure you want to delete' | translate }}: <strong>{{ specificName }}</strong>?
			</span>
			<span *ngIf="additionalMessage">
				{{ additionalMessage }} <!-- this text should be pre-translated-->
			</span>
		</p>
	</div>

	<div mat-dialog-actions align="end">

		<button class="bk-button bk-button--medium bk-button--text-only"
			(click)="close()">
			{{'Cancel' | translate }}
		</button>
		<button class="bk-button bk-button--medium"
			(click)="delete()">
			{{ (isBusy ? 'Deleting' : 'Delete') | translate }} {{ objectName | translate }}
            <img class='bk-button__loader' *ngIf='isBusy' src='/assets/images/loader-20px.gif'/>
		</button>
	</div>
</div>