import { IIrrigationEventEditResponse } from './interfaces';
import { IrrigationEventUpdateParams } from './irrigationEventUpdateParams';

export class IrrigationEventUpdateParamsInternal extends IrrigationEventUpdateParams {
	RecommendedAmount: number;
	RecommendedInterval: number;
	RecommendedTime: number;

	public constructor(e: IIrrigationEventEditResponse) {
		super(e);

		if (e.RecommendationSummary) {
			this.RecommendedAmount = e.RecommendationSummary.RecommendedIrrigationAmount;
			this.RecommendedInterval = e.RecommendationSummary.RecommendedIrrigationInterval;
			this.RecommendedTime = e.RecommendationSummary.RecommendedIrrigationTime;
		} else {
			this.RecommendedAmount = -1;
			this.RecommendedInterval = -1;
			this.RecommendedTime = -1;
		}
	}
}
