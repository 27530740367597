import { Component, Input } from '@angular/core';
import { FertilizationRecommendationComponent } from './fertilization-recommendation';
import { TranslateService } from '../../../localization/service';

@Component({
	moduleId: module.id,
	selector: 'fertilization-recommendation-summary',
	templateUrl: 'recommendation-summary.html'
})

export class FertilizationRecommendationSummaryComponent extends FertilizationRecommendationComponent {
	@Input()
	nInWater: number;

	constructor(
		private _translateService: TranslateService
	) {
		super(_translateService);
	}
}
