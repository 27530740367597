import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RanchFertilizerViewModel } from './interfaces';
import { FertilizerBaseDialog } from './fertilizer.dialog.base';
import { FertilizerModelService } from './service';
import { FormGroup } from '@angular/forms';
import { UpdateService } from '../../../../services/update.service';

@Component({
	moduleId: module.id,
	selector: 'fertilizer-edit-dialog',
	templateUrl: 'fertilizer.edit.dialog.html'
})

export class FertilizerEditDialog extends FertilizerBaseDialog implements OnInit {

	public fertilizer: RanchFertilizerViewModel;
	public isFormValid = true;
	public isTouched = false;
	public isSaving: boolean;

	public constructor(
		protected _dialog: MatDialog,
		private _dialogRef: MatDialogRef<FertilizerEditDialog>,
		@Inject(MAT_DIALOG_DATA) protected _data: { id: number, usage: number },
		private service: FertilizerModelService,
		private updateService: UpdateService
	) {
		super(_dialog, _data);
	}

	ngOnInit(): void {
		this.isSaving = false;
		this._getEditModel(this.id);
	}

	public close() {
		if (!this.isTouched) {
			this._dialogRef.close();
		} else {
			this._openKeepDiscardDialog();
		}
	}

	/**
	 * editRanchFertilzer
	 */
	public save(): void {
		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this.service.update(this.id, this.fertilizer)
		.then(() => {
			this.isSaving = false;
			this.updateService.setRanchSettingsUpdated('fertilizers');
			this._dialogRef.close();
		});
	}

	/**
	 * Prevent or allow user to save based on form validation.
	 * If user touched the form, ask for keep/discard instead of immediately closing the dialog
	 */
	public updateFormState(form: FormGroup): void {
		if (!form) {
			return;
		}

		this.isTouched = form.pristine ? false : true;
		this.isFormValid = form.invalid ? false : true;
	}

	private _getEditModel(id: number): void {
		this.service.getEditModel(id)
		.then(data => {

			if (!data) {
				return;
			}

			this.fertilizer = data;
		});
	}
}
