import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { IrrigationUpdateService } from './update.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IrrigationService } from './service';

@Component({
	moduleId: module.id,
	selector: 'irrigation-delete-confirmation',
	templateUrl: 'delete-confirmation.html',
})

export class IrrigationDeleteConfirmationDialog implements OnInit {

	public isSaving: boolean;
	private _id: number;

	constructor(
		private _dialogRef: MatDialogRef<IrrigationDeleteConfirmationDialog>,
		private _updateService: IrrigationUpdateService,
		private _modelService: IrrigationService,
		@Inject(MAT_DIALOG_DATA) private data: { id: number }
	) {

	}

	ngOnInit() {
		this.isSaving = false;

		if (this.data) {
			this._id = this.data.id;
		}
	}

	public cancel($event: MouseEvent): void {
		this._dialogRef.close();
		this._updateService.fireConfirmationClosed();
	}

	public delete(): void {
		this.isSaving = true;

		// we make an API call here instead of delegating it to main component
		// because we need to know when API is finished
		this._modelService.delete(this._id)
			.then(response => {
				this.isSaving = false;
				this._updateService.fireDeleted(response);
				this._dialogRef.close();
			});
	}
}
