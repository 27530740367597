import { ValidateService } from '../../services/validate.service';
import { CalculateService } from '../../services/calculate.service';

import { IRawFertilizationEvent, IFertilizationRecommendation, FertilizationEventEditViewModel, IFertilizationEvent }
	from './interfaces';

import { ICropStage } from '../../models/crop-stage/interfaces';
import { IFormSoilSample } from '../soil-sample-event/interfaces';
import { IFormFertilizer } from '../ranch-settings/modals/fertilizer/interfaces';
import { eFertilizerFormulationTypes } from '../../interfaces/constants';
import { DateUtility } from '../../classes/dateUtility';

export class FertilizationEvent implements IFertilizationEvent {

	public FertilizerApplied: number;
	public FertilizerName: string;
	public FormulationType: string;
	public Id: number;
	public LastUpdatedUser: string;
	public NApplied: number;
	public NPercentage: number;
	public IsFormulationTypeDry: boolean;
	public LbsPerGallon: number;
	public Recommendation: IFertilizationRecommendation;
	public SoilSampleEventDate: Date;
	public Metainfo: string;
	public DaysToNextFertilization: number;
	public FertilizationsPerMonth: number;
	public EventDate: Date;
	public NAppliedFromWater: number;
	public IrrigationMethodId: number;
	public IrrigationEventDate: Date;
	public IrrigationAmount: number;
	public UseWells: boolean;
	public PPMTotal: number;
	public IrrigationRecommendation: number;
	public ratio: number;
	public NutrientPercentages: number[];
	public YieldTarget?: number;
	public DateOnly?: boolean;

	// raw model properties from combined events list
	public CropStage: ICropStage;
	public SoilNitrate: number;
	public NUptake: number;
	public FormulationTypeId: number;

	// View properties
	public DisplayAmount: number;
	public DisplayFertilizationUnit: number;
	public DisplayLbsNPerAcre: number;

	// fertilization event form properties
	public CommodityTypeId: number;
	public CropStageId: number;
	public CropStages: ICropStage[];
	public Fertilizers: IFormFertilizer[];
	public SoilSamples: IFormSoilSample[];
	public FertilizerId: number;
	public startDate: string;
	public endDate: string;
	public FertilizationRecommendationLbsPerAcre: number;
	public NFertilizationRecommended: number;
	public lastIrrigationMethod: number;
	public managerNRecommendation: number;
	public ManagerFertilizerAmount: number;
	public ShowDetails: boolean; // virtual, used in showing calculation details in event table

	// temporary fix
	public EndDate?: string; // remove this later - doesn't exist in JSON stream
	public HarvestDate?: string; // remove this later
	public StartDate?: string; // remove this later
	public LastUpdated?: string; // last updated meta

	public static convertNToFertilizerAmount(lbsN: number, fe: IFertilizationEvent): number {
		let result: number;
		let calc: number;

		result = 0;

		if (!ValidateService.isValidNumber(lbsN)) {
			return null;
		}

		if (lbsN === -1) {
			return -1;
		}

		if (!ValidateService.isValidNumber(fe.NPercentage) || (fe.IsFormulationTypeDry === false
			&& !ValidateService.isValidNumber(fe.LbsPerGallon))) {
			return null;
		}

		if (fe.NPercentage === 0) {
			calc = 0;
		} else if (fe.IsFormulationTypeDry || fe.FormulationType === 'Dry') {
			calc = 100 / fe.NPercentage;
		} else if (fe.LbsPerGallon * fe.NPercentage / 100 === 0) {
			calc = 1;
		} else {
			calc = 1 / (fe.LbsPerGallon * fe.NPercentage / 100);
		}

		if (fe.NPercentage > 0) {
			result = lbsN * calc;
		}

		return result;
	}

	/**
	 * This method returns fertilizer unit instead of N
	 *
	 * @param event
	 * @param past
	 * @param calculateService
	 */
	public static getFertilizerUnit(event: IFertilizationEvent): number {

		if (!event || event.Recommendation == null) {
			return;
		}

		if (event.FertilizerApplied !== null) {
			return event.FertilizerApplied;
		}

		if (event.Recommendation.managerFertilizerAmount !== null) {
			return event.Recommendation.managerFertilizerAmount;
		}

		if (event.Recommendation.manager !== null) { // if we have N amount, we convert
			return FertilizationEvent.convertNToFertilizerAmount(event.Recommendation.manager, event);
		}

		if (event.Recommendation.cropManage !== null) {
			if (event.NPercentage > 0) {
				return FertilizationEvent.convertNToFertilizerAmount(event.Recommendation.cropManage, event);
			} else {
				return -1;
			}
		}

		return -1;
	}

	// for getting the lbs of a given nutrient. if fertilizer is dry, need lbsPerGallon
	public static getNutrientAmount(percentage: number, fertlizerAmount: number, lbsPerGallon = 1): number {
		let nutrientAmount: number = (percentage / 100) * fertlizerAmount * lbsPerGallon;

		if (!nutrientAmount) {
			return 0;
		}

		return ValidateService.truncateDecimals(nutrientAmount, 2);
	}

	/**
	 * Used to display nutrients for alfalfa, in event table
	 *
	 * @param fertilizationEvent
	 * @param fertilizerAmount
	 * @param nutrientIndex
	 */
	public static getFertilizerNutrientAmount(fertilizationEvent: FertilizationEvent,
		fertilizerAmount: number, nutrientIndex: number): number {

		let percentage: number;
		let formulationType: eFertilizerFormulationTypes;
		let lbsPerGallon: number;

		if (!fertilizationEvent) {
			throw new Error('Fertilization event doesn\'t exist');
		}

		if (!ValidateService.isValidNumber(nutrientIndex)) {
			throw new Error('NutrientIndex is not a number');
		}

		if (!fertilizationEvent.NutrientPercentages) {
			throw new Error('NurientPercentaages is empty');
		}

		percentage = fertilizationEvent.NutrientPercentages[nutrientIndex];
		formulationType = fertilizationEvent.FormulationTypeId;
		lbsPerGallon = fertilizationEvent.LbsPerGallon;

		if (!ValidateService.isValidNumber(percentage)) {
			throw new Error('percentage is not a valid number');
		}

		if (!ValidateService.isValidNumber(fertilizerAmount)) {
			return null;
		}

		if (formulationType === eFertilizerFormulationTypes.WET && ValidateService.isValidNumber(lbsPerGallon)) {
			return FertilizationEvent.getNutrientAmount(percentage, fertilizerAmount, lbsPerGallon);
		}

		return FertilizationEvent.getNutrientAmount(percentage, fertilizerAmount);
	}

	public static getNextFertilizationDate(startDate: Date, fertilizationsPerMonth: number, daysToNextFertilization: number): Date {
		let offset: number;
		let DAYS_IN_MONTH = 30;
		let result: Date;

		if (!startDate) {
			return null;
		}

		result = new Date(startDate);

		if (fertilizationsPerMonth > 0) {
			offset = Math.round(DAYS_IN_MONTH / fertilizationsPerMonth);
		} else if (daysToNextFertilization > 0) {
			offset = daysToNextFertilization;
		} else {
			return null;
		}

		result.setDate(result.getDate() + offset);

		return result;
	}

	public static convertAmounttoLbsN(fertilizerAmount: number, NPercentage: number,
		isFormulationTypeDry: boolean, lbsPerGallon: number): number {
		let lbsN: number;
		let calc: number;
		let inverse: number;

		lbsN = 0;

		if (!ValidateService.isValidNumber(fertilizerAmount)) {
			return null;
		}

		if (fertilizerAmount === -1) {
			return -1;
		}

		if (!ValidateService.isValidNumber(NPercentage) || (isFormulationTypeDry === false
			&& !ValidateService.isValidNumber(lbsPerGallon))) {
			throw new Error('toLbsN - some of the fertlizer values are not valid');
		}

		if (NPercentage === 0) {
			calc = 0;
			inverse = 0;
		} else {
			if (isFormulationTypeDry) {
				calc = 100 / NPercentage;
			} else if (lbsPerGallon * NPercentage / 100 === 0) {
				calc = 1;
			} else {
				calc = 1 / (lbsPerGallon * NPercentage / 100);
			}

			inverse = 1 / calc;
		}

		if (NPercentage > 0) {
			lbsN = fertilizerAmount * inverse;
		}

		return lbsN;
	}

	constructor(eventDate?: Date) {
		if (eventDate) {
			this.EventDate = eventDate;
		}
	}

	public convert(source: IRawFertilizationEvent, eventDate: Date): void {

		this.Metainfo = source.Metainfo;
		this.CropStage = source.CropStage;
		this.SoilNitrate = source.SoilNitrate;
		this.Id = source.Id;
		this.FertilizerApplied = source.FertilizerApplied;
		this.FertilizerName = source.FertilizerName; // bad construct
		this.LbsPerGallon = source.LbsPerGallon;
		this.NApplied = source.NApplied;
		this.NPercentage = source.NPercentage;
		this.NUptake = source.NUptake;
		this.FormulationType = source.FormulationType; // should be Formulation
		this.FormulationTypeId = source.FormulationTypeId; // should be formulation
		this.SoilSampleEventDate = DateUtility.DotNetToDate(source.SoilSampleEventDate); // doesn't belong in here
		this.NAppliedFromWater = source.NAppliedFromWater;
		this.Recommendation = source.Recommendation;
		this.LastUpdatedUser = source.LastUpdatedUser;
		this.EventDate = eventDate;
		this.IsFormulationTypeDry = this.FormulationType === 'Dry';
		this.NutrientPercentages = source.NutrientPercentages;

		this.DisplayAmount = FertilizationEvent.getFertilizerUnit(this);
		this.DisplayFertilizationUnit = this.DisplayAmount;
		this.DisplayLbsNPerAcre = this.convertFertilizerAmountToLbsN(this.DisplayAmount);
	}

	public convertFertilizerAmountToLbsN(fertilizerAmount: number): number {
		let lbsN: number;
		let calc: number;
		let inverse: number;

		lbsN = 0;

		if (!ValidateService.isValidNumber(fertilizerAmount)) {
			return null;
		}

		if (fertilizerAmount === -1) {
			return -1;
		}

		if (!ValidateService.isValidNumber(this.NPercentage) || (this.IsFormulationTypeDry === false
			&& !ValidateService.isValidNumber(this.LbsPerGallon))) {
			throw new Error('toLbsN - some of the fertlizer values are not valid');
		}

		if (this.NPercentage === 0) {
			calc = 0;
			inverse = 0;
		} else {
			if (this.IsFormulationTypeDry || this.FormulationType === 'Dry') {
				calc = 100 / this.NPercentage;
			} else if (this.LbsPerGallon * this.NPercentage / 100 === 0) {
				calc = 1;
			} else {
				calc = 1 / (this.LbsPerGallon * this.NPercentage / 100);
			}

			inverse = 1 / calc;
		}

		if (this.NPercentage > 0) {
			lbsN = fertilizerAmount * inverse;
		}

		return lbsN;
	}

	public parseForm(m: FertilizationEventEditViewModel): void {
		if (!m) {
			return;
		}

		this.CommodityTypeId = m.CommodityTypeId;
		this.CropStages = m.CropStages;
		this.Fertilizers = m.Fertilizers;

		this.SoilSamples = new Array();

		for (let soilSample of m.SoilSamples) {
			this.SoilSamples.push({
				Id: soilSample.Id,
				Date: DateUtility.DotNetToDate(soilSample.Date),
				Name: soilSample.Name
			});
		}

		this.DaysToNextFertilization = m.DaysToNextFertilization;
		this.startDate = m.startDate;
		this.endDate = m.endDate;
		this.LastUpdated = m.LastUpdated;

		this.EventDate = m.EventDate ?
			DateUtility.DotNetToDate(m.EventDate) : new Date();

		this.CropStageId = ValidateService.isValidNumber(m.CropStageId) ?
			m.CropStageId : 0;

		this.FertilizerId = ValidateService.isValidNumber(m.FertilizerId) ?
			m.FertilizerId : 0;

		this.FertilizationsPerMonth = ValidateService.isValidNumber(m.FertilizationsPerMonth) ?
			m.FertilizationsPerMonth : null;

		this.NPercentage = ValidateService.isValidNumber(m.NPercentage) ?
			m.NPercentage : null;

		this.LbsPerGallon = ValidateService.isValidNumber(m.LbsPerGallon) ?
			m.LbsPerGallon : null;

		this.IsFormulationTypeDry = m.IsFormulationTypeDry !== null && m.IsFormulationTypeDry !== undefined ?
			m.IsFormulationTypeDry : null;

		if (ValidateService.isValidNumber(m.NFertilizationRecommended)) {
			this.NFertilizationRecommended = m.NFertilizationRecommended;
			this.FertilizationRecommendationLbsPerAcre = FertilizationEvent.convertNToFertilizerAmount(this.NFertilizationRecommended, this);
		} else {
			this.NFertilizationRecommended = null;
			this.FertilizationRecommendationLbsPerAcre = null;
		}

		if (this.NPercentage === 0 && m.ManagerFertilizerAmount) {
			// case for 0N based fertilizer, where we still want to display manager amount
			this.managerNRecommendation = 0;
		} else if (ValidateService.isValidNumber(m.managerNRecommendation)) {
			this.managerNRecommendation = m.managerNRecommendation;
			this.ManagerFertilizerAmount = m.ManagerFertilizerAmount;
		} else {
			this.managerNRecommendation = null;
		}

		this.lastIrrigationMethod = ValidateService.isValidNumber(m.lastIrrigationMethod) ?
			m.lastIrrigationMethod : null;

		this.SoilSampleEventDate = m.SoilSampleEventDate ?
			DateUtility.DotNetToDate(m.SoilSampleEventDate) : null;



		this.NApplied = ValidateService.isValidNumber(m.NApplied) ?
			m.NApplied : null;

		this.FertilizerApplied = ValidateService.isValidNumber(m.FertilizerApplied) ?
			m.FertilizerApplied : null;

		// N Applied from water values
		this.IrrigationAmount = ValidateService.isValidNumber(m.IrrigationAmount) ?
			m.IrrigationAmount : null;

		this.IrrigationEventDate = m.IrrigationEventDate ?
			DateUtility.DotNetToDate(m.IrrigationEventDate) : null;

		this.IrrigationMethodId = ValidateService.isValidNumber(m.IrrigationMethodId) ?
			m.IrrigationMethodId : null;

		this.IrrigationRecommendation = ValidateService.isValidNumber(m.IrrigationRecommendation) ?
			m.IrrigationRecommendation : null;

		this.NAppliedFromWater = ValidateService.isValidNumber(m.NAppliedFromWater) ?
			m.NAppliedFromWater : null;

		this.ratio = ValidateService.isValidNumber(m.ratio) ?
			m.ratio : null;

		this.PPMTotal = ValidateService.isValidNumber(m.PPMTotal) ?
			m.PPMTotal : null;

		this.UseWells = m.UseWells;
		this.YieldTarget = m.YieldTarget;
	}
}
