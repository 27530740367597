import * as _ from 'underscore';
import * as jQuery from 'jquery';

export class ObjectUtility {

	public static copy(o: any): any {
		let copy: any = {};

		jQuery.extend(true, copy, o);
		return copy;
	}

	public static copyArray(objects: any[]): any[] {
		let result: any[] = new Array();

		if (!objects) {
			return;
		}

		for (let object of objects) {
			result.push(ObjectUtility.copy(object));
		}

		return result;
	}

	public static isEqual(objectA: object, objectB: object): boolean {
		return _.isEqual(objectA, objectB);
	}

	/**
     * Compare 2 objects, similar to _isEqual, except looser comparison. If differences exist,
     * display a console log, for debugging purposes
     *
     * @param obj1
     * @param obj2
     * @param parentKey
     */
	public static AreEqual(obj1: object, obj2: object, parentKey: string): boolean {
		let areEqual: boolean;

		areEqual = true;

		parentKey = parentKey || '';

		if (!obj1 || !obj2) {
			if (!obj1 && !obj2) {
				// both are equally empty, so they're.. equal
				return true;
			} else {
				// one of the objects is empty but not both, so not equal
				return false;
			}
		}

		_.each(_.keys(obj1), function (key) {
			if (_.isObject(_.property(key)(obj1))) {
				if (ObjectUtility.AreEqual(_.property(key)(obj1), _.property(key)(obj2), parentKey + key + '.') === false
					&& areEqual === true) {
					areEqual = false;
				}
			} else {
				if (!_.has(obj2, key) || !_.isEqual(_.property(key)(obj1), _.property(key)(obj2))) {
					areEqual = false;
				}
			}
		})

		return areEqual;
	}
}
