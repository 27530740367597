import { Injectable } from '@angular/core';


import 'file-saver';
import { of } from 'rxjs';

import { ModelService } from '../../services/model.service';
import { IIdNamePair } from '../../components/planting-settings/interfaces';
import { HttpParams } from '@angular/common/http';
import { ICropStageTableRow } from '../../modules/crop-stages/interfaces';
import { CMError, SuccessResponse } from '../../interfaces/interfaces';
import { ICropStageService } from './interfaces';

export class RanchFavoritesModel {
	public Id: number;
	public Ranch: RanchModel;
	public RanchId: number;
	public UserID: string;
}

export class RanchModel {
	public ActivePlantings: number;
	public CreatedByFullName: string;
	public Id: number;
	public Name: string;
	public RanchAccessState: number;
	public Ranch_External_GUID: string;
	public UserAccessState: number;
}

@Injectable()
export class CropStageService extends ModelService implements ICropStageService {

	private urls = {
		list: '/v2/crop-stages.json',
		delete: (cropStageId: number) => `/v2/crop-stages/${cropStageId}.json`,
		update: (cropStageId: number) => `/v2/crop-stages/${cropStageId}.json`,
		create: `/v2/crop-stages.json`
	};

	public update(model: ICropStageTableRow): Promise<CMError | SuccessResponse> {
		let body: {
			Name: string,
			CommodityTypes: number[]
		};

		body = {
			Name: model.Name,
			CommodityTypes: model.CommodityTypes
		}

		return this.put(this.urls.update(model.Id), (response: CMError | SuccessResponse):
		CMError | SuccessResponse => {

			return response;
		}, body, true)
	}

	public create(model: ICropStageTableRow): Promise<CMError | SuccessResponse> {
		let body: {
			Name: string,
			CommodityTypes: number[]
		};

		body = {
			Name: model.Name,
			CommodityTypes: model.CommodityTypes
		}

		return this.post(this.urls.create, (response: CMError | SuccessResponse):
		CMError | SuccessResponse => {

			return response;
		}, body, true)
	}

	public list(commodityTypeId: number): Promise<IIdNamePair[]> {

		let params: HttpParams;

		if (!commodityTypeId) {
			return of(null).toPromise();
		}

		params = new HttpParams().set('commodityTypeId', commodityTypeId.toString());

		return this.get(this.urls.list,
			(response: IIdNamePair[]) => {

			return response;
		}, params);
	}

	public listAdmin(): Promise<ICropStageTableRow[]> {

		return this.get(this.urls.list,
			(response: ICropStageTableRow[]): ICropStageTableRow[] => {

			for (let row of response) {
				row.CommodityTypeCount = row.CommodityTypes ? row.CommodityTypes.length : 0
			}

			return response;
		});
	}

	public delete(cropStageId: number): Promise<CMError | SuccessResponse> {

		return this.deleteItem(this.urls.delete(cropStageId),
			(response: CMError | SuccessResponse): CMError | SuccessResponse => {
			return response;
		});
	}
}
