import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CMError } from '../../interfaces/interfaces';
import { NotificationService } from '../../services/notification.service';
import { eNotificationTypes } from '../../interfaces/constants';
import { CropTypeService } from '../../models/crop-type/service';
import { CropTypeUpdateService } from './cropType.update.service';

@Component({
	moduleId: module.id,
	selector: 'ap-crop-type-delete-confirmation',
	templateUrl: 'delete-confirmation.html'
})

export class CropTypeDeleteConfirmationDialog implements OnInit {

	private _id: number;
	public cropTypeName: string;
	public isSaving = false;

	public constructor(
		private _dialog: MatDialog,
		private _dialogRef: MatDialogRef<CropTypeDeleteConfirmationDialog>,
		@Inject(MAT_DIALOG_DATA) private _data: { id: number, name: string },
		private _cropTypeService: CropTypeService,
		private _updateService: CropTypeUpdateService,
		private _notificationService: NotificationService
	) {

	}

	ngOnInit(): void {
		this.cropTypeName = this._data.name;
		this._id = this._data.id;
	}

	public delete(): void {
		this.isSaving = true;

		this._cropTypeService.delete(this._id)
		.then((response: CMError) => {
			this.isSaving = false;

			if (response && response.message) {
				this._notificationService.generateNotifcation({
					type: eNotificationTypes.ERROR,
					message: response.message
				});
			} else {
				this._notificationService.generateNotifcation({
					type: eNotificationTypes.UPDATE,
					message: 'Your crop type was successfully deleted.'
				});

				this._updateService.updateList(); // refresh parent view
			}

			this._dialog.closeAll();
		});
	}

	public close() {
		this._dialogRef.close();
	}
}
