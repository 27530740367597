import { ElementRef } from '@angular/core';
import * as Highcharts from 'highcharts';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);

import { IHighchartsXAxis } from '../interfaces/views/highCharts';
import highChartExportOptions from '../components/shared/highchartsExportOptions';

export class HighChartsService {
	public static drawGraph(chart: ElementRef, data: Highcharts.IndividualSeriesOptions[],
		yAxis?: Highcharts.AxisOptions[], height?: number ): void {

		let options: Highcharts.Options;
		let xAxis: IHighchartsXAxis;

		if (!chart) {
			return;
		}

		xAxis = {
			type: 'datetime',
			gridLineWidth: 1
		}

		options = {
			chart: {
			// type: 'line',
			},
			title: {
				text: null,
			},
			legend: {
				title: {
					text: null,
				}
			},
			tooltip: {
				backgroundColor: '#FFF',
				borderColor: '#FFF',
				valueDecimals: 2,
				valueSuffix: ' in.',
				// formatter: tooltipFormatter
			},
			xAxis: xAxis,
			yAxis: [{
				title: {
					text: 'Inches'
				}
			}],
			series: data,
			exporting: highChartExportOptions
		}

		if (height > 0) {
			options.chart.height = height;
		}

		if (yAxis) {
			options.yAxis = yAxis;
		}

		Highcharts.chart(chart.nativeElement, options);
	}
}
