import { Injectable } from '@angular/core';
import { ModelService } from '../../services/model.service';

@Injectable()
export class FeedbackService extends ModelService {

	private urls = {
		send: '/v2/feedback/send.json',
	};

	public send(description: string): Promise<void> {
		let params: {
			Description: string
		};

		params = {
			Description: description
		};

		return this.post(this.urls.send,
			(): void => {
		}, params);
	}
}
