<div class="bk-dialog bk-dialog__event">
	
	<button type="button"
		class="bk-dialog__close"
		(click)="close()">
		&times;
	</button>

	<h4 mat-dialog-title>
		Configuring Initial Saturation
	</h4>

	<div mat-dialog-content>
		<p>
			CropManage calculates soil water balance for a planting two months before the planting start date using
			weather data with an initial available water percentage at 75%. You can improve the accuracy of the results by providing
			your own soil moisture information measured before the planting start date.
		</p>
		<p>The soil moisture information you enter can be associated with rainfall, irrigation, or soil moisture measurements.
			
		</p>
		<ul>
			<li>
				<strong>Soil Moisture Measurement</strong>
				<br>
				If you taken a soil moisture measurement, enter 
				the date you took the measurement, and the percentage saturation.
			</li>
			<li>
				<strong>Rainfall</strong>
				<br>
				If it rained prior to the planting start date, enter the date it rained, and
				an estimate of percent saturation.
			</li>
			<li><strong>Irrigation</strong>
				<br>
				If you irrigated, enter the irrigation date. If you irrigated to saturation,
				for example, set the percent saturation to 100%.</li>
		</ul>
	</div>

	<div mat-dialog-actions align="end">

		<button (click)="close()" mat-button
			class="bk-button--medium bk-dialog__button--primary">
			{{ 'Close' | translate }}
		</button>
	</div>
</div>