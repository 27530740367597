<div class="bk-dialog bk-dialog__event">

    <button type="button"
        class="bk-dialog__close"
        (click)="cancel()">

        &times;
    </button>

    <h4 mat-dialog-title>
        <ng-container *ngIf="!id">
            {{ 'Add' | translate }}
        </ng-container>
        <ng-container *ngIf="id && view !== cutEventViews.DELETE_CONFIRMATION">
            {{ 'Edit' | translate }}
        </ng-container>
        <ng-container *ngIf="id && view === cutEventViews.DELETE_CONFIRMATION">
            {{ 'Delete' | translate }}
        </ng-container>
        {{ 'Cutting Event' | translate}}
    </h4>

    <div *ngIf="view === cutEventViews.FORM"
        mat-dialog-content>

        <form [formGroup]="form" class="bk-form">
            <mat-form-field appearance="fill"
                class="mat-form-field--short">
                <mat-label>{{'Event Date' | translate }}</mat-label>
                <input matInput [matDatepicker]="picker"
                    required
                    [max]="maxDate"
                    formControlName="EventDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="f.EventDate.invalid && (f.EventDate.dirty || f.EventDate.touched)">
                    <div *ngIf="f.EventDate.errors.required">
                        {{ 'Event date is required' | translate }}
                    </div>
                    <div *ngIf="f.EventDate.errors.daysAfterGerminationError">
                        {{ 'Event date cannot be on or earlier than germination date' | translate }} ({{ germinationDate | date:'shortDate' }})
                    </div>
                </mat-error>
            </mat-form-field>

            <ng-container *ngIf="isFutureEvent === false">
                <mat-form-field appearance="fill"
                    class="mat-form-field--short">
                    <mat-label>
                        {{ 'Crop Stage' | translate }}
                    </mat-label>
                    <mat-select formControlName="MaturityId">
                        <mat-option *ngFor="let maturity of maturities"
                            [value]="maturity.Id">
                            {{maturity.Name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill"
                    class="mat-form-field--short">
                    <mat-label>
                        {{'Cutting Yield' | translate }}
                    </mat-label>
                    <input matInput
                        type="number"
                        step="0.01"
                        name="Yield"
                        formControlName="Yield"/>
                    <span matSuffix class="mat-form-field-suffix--padded">
                        {{ 'tons' | translate }}
                    </span>
                </mat-form-field>
            </ng-container>
        </form>
        <span *ngIf='id > 0'
            class='bk-modal__last-updated-meta bk-clearfix'>
            {{ 'Last updated by' | translate }} {{LastUpdatedUser}}
        </span>
    </div>

    <div *ngIf="view === cutEventViews.DISCARD_CHANGES"
        mat-dialog-content>

        <div class="bk-dialog__paragraph">
            {{ 'The changes you made were not saved' | translate}}. {{'Discard changes' | translate}}?
        </div>
    </div>

    <div *ngIf="view === cutEventViews.DELETE_CONFIRMATION"
        mat-dialog-content>
        <div class="bk-dialog__paragraph">
            {{ 'Are you sure you want to delete this event' | translate}}?
        </div>
    </div>

    <div *ngIf="view === cutEventViews.FORM"
        mat-dialog-actions
        align="end">

        <button *ngIf="id"
            class="bk-button__delete"
            (click)="view = cutEventViews.DELETE_CONFIRMATION">
            {{'Delete' | translate }}
        </button>

        <button class="bk-button bk-button--medium bk-button--text-only
            bk-dialog__button--secondary"
            mat-button
            (click)="cancel()">
            {{ 'Cancel' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="save()"
            [disabled]="form.invalid">
            {{ (isSaving ? 'Saving' : 'Save') | translate }}
            <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
        </button>
    </div>

    <div *ngIf="view === cutEventViews.SUCCEEDING_EVENTS" mat-dialog-content>
        <div class="bk-modal__remove-text">
            {{'Your action changed recommendations for these irrigation event(s)' | translate }}:
        </div>
        <table class="bk-event-table__affected-events">
            <tr *ngFor="let succeedingEventGroup of succeedingEventGroups">
                <td class="bk-event-table__affected-events-cell--date">
                    {{succeedingEventGroup.EventDate | date:'mediumDate'}}
                </td>
                <td class="bk-event-table__affected-events-cell">
                    <div class="bk-planting__event-row__icon bk-button--blue-icon">
                        <svg-water></svg-water>
                    </div>
                    <span *ngIf="succeedingEventGroup && succeedingEventGroup.Irrigation">
                        {{succeedingEventGroup.Irrigation.IrrigationMethod}}
                    </span>
                </td>
            </tr>
        </table>
    </div>

    <div *ngIf="view === cutEventViews.DISCARD_CHANGES"
        mat-dialog-actions
        align="end">

        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only
            bk-dialog__button--secondary"
            (click)="cancelDiscardingChanges()">
            {{ 'Keep Editing' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="close(true)">
            {{ 'Discard' | translate }}
        </button>
    </div>

    <div *ngIf="view === cutEventViews.SUCCEEDING_EVENTS"
        mat-dialog-actions
        align="end">
        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary" 
            (click)="close(true)">
            {{'Close' | translate }}
        </button>
    </div>

    <div *ngIf="view === cutEventViews.DELETE_CONFIRMATION"
        mat-dialog-actions
        align="end" >

        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only bk-dialog__button--secondary"
            (click)="cancelDiscardingChanges()">
            {{ 'Cancel' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="delete()">
            {{ (isSaving ? 'Deleting' : 'Delete') | translate }}
            <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
        </button>
    </div>
</div>