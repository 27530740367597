import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { HttpService, HttpServicePostOptions, HttpServiceGetOptions } from '../../services/http.service';
import { DateUtility } from '../../classes/dateUtility';
import { SoilMoisture } from './soilMoisture';

import {
	ISoilMoistureFile, ISoilMoistureHeader, ISoilMoistureHeaderStrings,
	ISoilMoistureSeries, ISoilMoistureGraphSeries, ISoilMoistureTab
} from './soilMoisture.interface';

import { CMError, httpStatusCodes, SuccessResponse } from '../../interfaces/interfaces';
import { eSoilMoistureImportErrors } from '../../interfaces/constants';
import { SoilMoistureFileViewModel, ISoilMoistureHeaderViewModel } from './interfaces';
import { IFlowMeterPlantingListViewModel } from '../flowmeter-graph/interfaces';
import { SoilMoistureSeriesConverter } from './soilMoistureSeriesConverter';

@Injectable()
export class SoilMoistureService {

	private urls = {
		list: '/v2/soil-moisture-files.json',
		headers: {
			get: (plantingId: number) => `/v2/plantings/${plantingId}/soil-moisture-headers.json`,
			update: `/v2/soil-moisture-headers.json`,
		},
		file: {
			get: (plantingId: number) => `/v2/plantings/${plantingId}/soil-moisture-files.json`,
			link: `/v2/soil-moisture-files/link.json`,
			update: '/v2/soil-moisture-files.json',
			save: `/v2/soil-moisture-filename.json`,
		},
		data: {
			get: (plantingId: number) => `/v2/plantings/${plantingId}/soil-moisture-data.json`
		}
	}

	constructor(private httpService: HttpService) { }

	public list(): Promise<ISoilMoistureFile[]> {
		let options: HttpServiceGetOptions = {
			url: this.urls.list,
			callback: this._processSoilMoistureList,
			isWebAPI: true
		}

		return this.httpService.get(options);
	}

	public getHeaders(plantingId: number): Promise<ISoilMoistureHeader[]> {

		let options: HttpServiceGetOptions = {
			url: this.urls.headers.get(plantingId),
			isWebAPI: true,
			shouldBypassServerErrorAlert: true,
			callback: this.processSoilMoistureHeaders
		}

		return this.httpService.get(options);
	}

	public getFile(plantingId: number): Promise<ISoilMoistureFile> {

		let options: HttpServiceGetOptions = {
			url: this.urls.file.get(plantingId),
			isWebAPI: true,
		}

		return this.httpService.get(options);
	}

	public linkFile(plantingId: number, fileName: string, updateHeaders: boolean):
		Promise<ISoilMoistureHeader[] | CMError> {

		let options: HttpServicePostOptions;
		let body: SoilMoistureFileViewModel;

		if (fileName && typeof fileName === 'string') {
			fileName = fileName.trim();
		}

		body = {
			PlantingId: plantingId,
			Filename: fileName,
		}

		options = {
			body: body,
			url: this.urls.file.link,
			isWebAPI: true,
			shouldBypassServerErrorAlert: true,

			callback: (response: CMError | ISoilMoistureHeader[]) => {
				let error: CMError;

				error = response as CMError;

				if (error && error.code && error.code === httpStatusCodes.continue) {
					error.code = this.getImportError(error);
					return error;
				}

				return this.processSoilMoistureHeaders(response as ISoilMoistureHeader[]);
			}
		}

		return this.httpService.post(options);
	}

	public updateFile(plantingId: number, fileName: string): Promise<CMError> {
		let options: HttpServicePostOptions;
		let body: SoilMoistureFileViewModel;

		if (fileName && typeof fileName === 'string') {
			fileName = fileName.trim();
		}

		body = {
			PlantingId: plantingId,
			Filename: fileName
		}

		options = {
			body: body,
			url: this.urls.file.update,
			isWebAPI: true,
			shouldBypassServerErrorAlert: true,

			callback: (response: CMError) => {
				if (response && response.code === httpStatusCodes.continue) {
					response.code = this.getImportError(response);
					return response;
				}

				return null;
			}
		}

		return this.httpService.post(options);
	}

	public saveHeaders(plantingId: number, fileName: string, headers: ISoilMoistureHeader[]): Promise<void> {
		let promises: Promise<Object>[] = new Array();

		if (fileName && typeof fileName === 'string') {
			fileName = fileName.trim();
		}

		promises.push(this.saveFile(plantingId, fileName));
		promises.push(this.updateHeaders(plantingId, fileName, headers));

		return Promise.all(promises)
			.then(() => {
				return;
			});
	}

	public saveFile(plantingId: number, fileName: string): Promise<CMError> {
		let options: HttpServicePostOptions;
		let body: SoilMoistureFileViewModel;

		if (!plantingId || !fileName) {
			return of(null).toPromise();
		}

		body = {
			PlantingId: plantingId,
			Filename: fileName
		}

		options = {
			body: body,
			url: this.urls.file.save,
			isWebAPI: true
		}

		return this.httpService.put(options);
	}

	public updateHeaders(plantingId: number, fileName: string, headers: ISoilMoistureHeader[]): Promise<CMError | SuccessResponse> {
		let options: HttpServicePostOptions;
		let body: ISoilMoistureHeaderViewModel;
		let headerStrings: ISoilMoistureHeaderStrings;

		if (!plantingId || !headers) {
			return of(null).toPromise();
		}

		headerStrings = this.stringifyHeaders(headers);

		body = {
			PlantingId: plantingId,
			FileName: fileName,
			Name: headerStrings.nameString,
			Depth: headerStrings.depthString,
			Type: headerStrings.typeString,
		}

		options = {
			body: body,
			url: this.urls.headers.update,
			isWebAPI: true,

			callback: (response: SuccessResponse | CMError): SuccessResponse | CMError => {
				if (!response) {
					return null;
				}

				return response;
			}
		}

		return this.httpService.put(options);
	}

	public getData(plantingId: number): Promise<ISoilMoistureGraphSeries[]> {

		let options: HttpServiceGetOptions = {
			url: this.urls.data.get(plantingId),
			isWebAPI: true,
			shouldBypassServerErrorAlert: true,

			callback: (response: ISoilMoistureSeries[]) => {
				let converter: SoilMoistureSeriesConverter;

				converter = new SoilMoistureSeriesConverter(response);
				return converter.getGraphSeries();
			}
		}

		return this.httpService.get(options);
	}

	private _processSoilMoistureList(list: IFlowMeterPlantingListViewModel[]): ISoilMoistureFile[] {
		let soilMoistureFiles: ISoilMoistureFile[] = new Array();

		if (!list || !list.length) {
			return soilMoistureFiles;
		}

		for (let item of list) {
			let harvestDate: string = DateUtility.dotNetToMMDDYYYY(item.HarvestDate);
			let lastUpdateDate: string = DateUtility.dotNetToMMDDYYYY(item.LastUpdateDate);

			soilMoistureFiles.push({
				RanchName: item.RanchName,
				PlantingName: item.PlantingName,
				PlantingId: item.PlantingId,
				HarvestDate: harvestDate ? harvestDate : 'n/a',
				FileName: item.FileName,
				LastUpdateDate: lastUpdateDate ? lastUpdateDate : 'n/a',
			});
		}

		// sort by ranch initially
		soilMoistureFiles = soilMoistureFiles.sort((a: ISoilMoistureFile, b: ISoilMoistureFile) => {
			let ranchA = a.RanchName.toLowerCase();
			let ranchB = b.RanchName.toLocaleLowerCase();
			return (ranchA < ranchB) ? -1 : (ranchA > ranchB) ? 1 : 0;
		})

		return soilMoistureFiles;
	}

	private processSoilMoistureHeaders(headers: ISoilMoistureHeader[]): ISoilMoistureHeader[] {
		if (!headers || !headers.length) {
			return new Array();
		}

		for (let header of headers) {
			if (header.SensorName === 'record num' || (header.Depth === '0' &&
				header.DataType === '0') || header.DataType.toLowerCase().indexOf('volt') >= 0) {

				header.isDisplayed = false;
			} else {
				header.isDisplayed = true;
			}

			if (!header.SensorName || header.SensorName === '') {
				header.SensorName = null;
			}

			if (!header.Depth || header.Depth === '') {
				header.Depth = null;
			}

			if (!header.DataType || header.DataType === '') {
				header.DataType = null;
			}
		}

		return headers;
	}

	private stringifyHeaders(headers: ISoilMoistureHeader[]): ISoilMoistureHeaderStrings {
		let nameString = '';
		let depthString = '';
		let typeString = '';

		for (let header of headers) {
			if (nameString === '') {
				nameString = nameString + '"' + header.SensorName + '"';
			} else {
				nameString = nameString + ',"' + header.SensorName + '"';
			}

			if (depthString === '') {
				depthString = depthString + '"' + header.Depth + '"';
			} else {
				depthString = depthString + ',"' + header.Depth + '"';
			}

			if (typeString === '') {
				typeString = typeString + '"' + header.DataType + '"';
			} else {
				typeString = typeString + ',"' + header.DataType + '"';
			}
		}

		return {
			nameString: nameString,
			depthString: depthString,
			typeString: typeString
		} as ISoilMoistureHeaderStrings;
	}

	private getImportError(response: CMError): eSoilMoistureImportErrors {
		if (!response || !response.message) {
			return eSoilMoistureImportErrors.GENERIC;
		}

		if (response.message.toLowerCase().indexOf('invalid date') >= 0) {
			return eSoilMoistureImportErrors.DATE;
		} else if (response.message.toLowerCase().indexOf('number of columns') >= 0) {
			return eSoilMoistureImportErrors.COLUMNS;
		} else if (response.message.toLowerCase().indexOf('file could not be found') >= 0) {
			return eSoilMoistureImportErrors.NOTFOUND;
		} else if (response.message.toLowerCase().indexOf('content is empty') >= 0) {
			return eSoilMoistureImportErrors.EMPTY;
		} else {
			return eSoilMoistureImportErrors.GENERIC;
		}
	}
		}
