
import { OnInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CropTypeService } from '../../models/crop-type/service';
import { ICropTypeViewModel, IPerennialCanopy } from '../../models/crop-type/interfaces';
import { of, Subject } from 'rxjs';
import { ICommodityType } from '../../models/commodity-type/interfaces';
import { CommodityService } from '../../models/commodity-type/service';
import { DateUtility } from '../../classes/dateUtility';
import { eCalculators, eCommodityTypes, eNotificationTypes } from '../../interfaces/constants';
import { NotificationService } from '../../services/notification.service';
import { ISelectOptions } from '../../interfaces/abstract';
import { TokenService } from '../../services/token.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PerennialCanopyDialog } from './perennial-canopy';
import { CropTypeUpdateService } from './cropType.update.service';
import { CanopyDeleteConfirmationDialog } from './canopy-delete-confirmation';
import { FormGroup, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { CropTypeFormBuilder, CropTypeFormFields } from './cropTypeFormBuilder';
import { NCalculatorFields } from '../planting-settings/sections/nitrogen-calculator/n-calculator';
import { CropTypeViewModel } from '../../models/crop-type/cropTypeViewModel';
import { ICropSettingsForm } from '../planting-settings/planting-settings-form.interfaces';
import { CropTypeViewmodelConverter } from './cropTypeViewModelConverter';
import { FormValidation } from '../../classes/formValidation';

@Component({
	moduleId: module.id,
	selector: 'crop-type-detail',
	templateUrl: 'crop-type-detail.html',
	styleUrls: ['crop-type-detail.scss']
})

export class CropTypeDetailComponent implements OnInit, OnDestroy {

	public CALCULATOR = eCalculators;
	public commodityTypes: ICommodityType[];
	public COMMODITY_TYPES = eCommodityTypes;
	public dateUtility = DateUtility;
	public months = DateUtility.monthsAsStrings;
	public daysOfTheMonth: number[] = new Array();
	public perennialCanopies: IPerennialCanopy[];

	public hasChanges: boolean; // flag used to prevent user from navigating away
	private _subscriptions$: Subject<boolean>;
		// from this form if changes have been made to perennial tables
	private _cropTypeForm: CropTypeFormBuilder;

	public maxCropAges = [ // content of dropdown used to limit max age for a crop
		{ key: null, value: 'Select One'},
		{ key: 0, value: '1 year' },
		{ key: 1, value: '2 years' },
		{ key: 2, value: '3 years' },
		{ key: 3, value: '4 years' },
		{ key: 4, value: '5 years' },
		{ key: 5, value: '6 years' },
		{ key: 6, value: '7 years' },
		{ key: 7, value: '8 years' },
		{ key: 8, value: '9 years' },
		{ key: 9, value: '10 years' },
		{ key: 10, value: '11 years' },
		{ key: 11, value: '12 years' },
		{ key: 12, value: '13 years' },
		{ key: 13, value: '14 years' },
		{ key: 14, value: '15 years' },
	];

	public densityOptions: ISelectOptions[] = [
		{ key: 0, value: 'Sparse' },
		{ key: 1, value: 'Full' }
	];

	// private members

	private _cropTypeId: number;
	private _isClone: boolean;
	public form: FormGroup;

	constructor(
		private _router: Router,
		private _tokenService: TokenService,
		private _route: ActivatedRoute,
		private _navigationRouter: Router,
		private _cropTypeService: CropTypeService,
		private _commodityService: CommodityService,
		private _notificationService: NotificationService,
		private _dialog: MatDialog,
		private _updateService: CropTypeUpdateService,
		private _fb: FormBuilder
	) {
		if (this._tokenService.isAuthenticated() === false) {
			this._router.navigate(['login']);
		}

		this.hasChanges = false;
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();

		this._updateService.perennialCanopyCreated.pipe(takeUntil(this._subscriptions$)).subscribe((data) => {
			this._addPerennialCanopy(data);
			this.hasChanges = true;
		});

		this._updateService.perennialCanopyUpdated.pipe(takeUntil(this._subscriptions$)).subscribe((data) => {
			this._updatePerennialCanopy(data);
			this.hasChanges = true;
		});

		this._updateService.perennialCanopyDeleted.pipe(takeUntil(this._subscriptions$)).subscribe((data) => {
			this._deletePerennialCanopy(data);
			this.hasChanges = true;
		});

		this._route.queryParams.pipe(takeUntil(this._subscriptions$)).subscribe(params => {
			this._cropTypeId = params['id'];
			this._isClone = params['isClone'] === 'true' ? true : false;

			if (this._cropTypeId && !this._isClone) {
				this._getEditModel(this._cropTypeId).then((responseModel) => {
					this._commodityService.list().then((response) => {
						this.commodityTypes = response;

						if (responseModel) {
							this._initializeForm(responseModel);
						}
					});

					return;
				})
			} else if (this._cropTypeId && this._isClone) {
				this._getCloneModel(this._cropTypeId).then((responseModel) => {

					this._commodityService.list().then((response) => {
						this.commodityTypes = response;

						if (responseModel) {
							this._initializeForm(responseModel);
						}
					});

					this._cropTypeId = null;

					return;
				})
			} else {
				this._getCreateModel().then((responseModel) => {

					this._commodityService.list().then((response) => {
						this.commodityTypes = response;
						this._initializeForm(responseModel);
					});

					return;
				});
			}
		})
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public get f(): { [key in CropTypeFormFields]: AbstractControl } {
		return this.form.controls as { [key in CropTypeFormFields]: AbstractControl };
	}

	public get fnc(): { [key in NCalculatorFields]: AbstractControl } {
		return (this.form.get('NCalculator') as FormGroup).controls as { [key in NCalculatorFields]: AbstractControl };
	}

	private _initializeForm(ct: ICropTypeViewModel): void {

		this._cropTypeForm = new CropTypeFormBuilder(this._fb, this.commodityTypes,
			this._subscriptions$);
		this.form = this._cropTypeForm.initializeForm(ct);
		this._customizeValidation();
		this._bindValueChanges();
	}

	public rebuildForm(): void {
		let cache: CropTypeViewModel;

		this.form.updateValueAndValidity(); // make sure form values are fully updated
		cache = this.form.value as CropTypeViewModel;
		cache.TreeNutrientThresholds = this.fnc.TreeNutrientThresholds.value;
		this.form = this._cropTypeForm.initializeForm(cache);
		this._customizeValidation();
		this._bindValueChanges();
	}

	private _customizeValidation(): void {
		if (!this.f.IsTree.value) {
			FormValidation.setValidator(this.f.DaysToEndDate, [Validators.required,
				FormValidation.greaterThanValidator(0)]);
		}
	}

	private _bindValueChanges(): void {
		this.f.CommodityTypeId.valueChanges.subscribe(() => {
			this.rebuildForm();
		});

		this.f.IsNDependent.valueChanges.subscribe(() => {
			this.rebuildForm();
		});
	}

	private _sortPerennialCanopy(): void {
		if (!this.perennialCanopies) {
			return;
		}

		this.perennialCanopies.sort((a, b) => {
			let result: number;

			result = a.Age - b.Age;

			return result;
		})
	}

	private _deletePerennialCanopy(id: number): void {
		if (!this.perennialCanopies || this.perennialCanopies.length === 0) {
			return;
		}

		this.perennialCanopies = this.perennialCanopies.filter(x => x.Id !== id);
	}

	private _addPerennialCanopy(model: IPerennialCanopy): void {
		this.perennialCanopies.push(model);
		this._sortPerennialCanopy();
	}

	private _updatePerennialCanopy(model: IPerennialCanopy): void {
		let canopyOriginal: IPerennialCanopy;

		if (!this.perennialCanopies || this.perennialCanopies.length === 0) {
			return;
		}

		canopyOriginal = this.perennialCanopies.find(x => x.Id === model.Id);

		if (!canopyOriginal) {
			return;
		}

		canopyOriginal.Age = model.Age;
		canopyOriginal.CanopyA = model.CanopyA;
		canopyOriginal.CanopyB = model.CanopyB;
		canopyOriginal.CanopyE = model.CanopyE;
		canopyOriginal.CanopyF = model.CanopyF;
		canopyOriginal.CanopyGMax = model.CanopyGMax;
		canopyOriginal.CanopyMaxFraction = model.CanopyMaxFraction;

		this._sortPerennialCanopy();
	}

	public editPerennialCanopy(model: IPerennialCanopy): void {
		let data: {
			model: IPerennialCanopy,
			commodityTypeId: number,
			maxAge: number,
		};

		data = {
			model: model,
			commodityTypeId: this.f.CommodityTypeId.value,
			maxAge: this.f.MaxAge.value,
		};

		this._dialog.open(PerennialCanopyDialog, {
			disableClose: true,
			data: data,
			maxWidth: 'unset'
		});
	}

	public deletePerennialCanopy(id: number): void {
		this._dialog.open(CanopyDeleteConfirmationDialog, {
			disableClose: true,
			data: {
				Id: id,
			},
			maxWidth: 'unset'
		});
	}

	public createPerennialCanopy(): void {
		let data: {
			model: IPerennialCanopy,
			commodityTypeId: number,
			maxAge: number,
		}

		data = {
			model: {
				Id: null,
				Age: null,
				CropTypeId: this.f.Id.value,
				CanopyA: null,
				CanopyB: null,
				CanopyE: null,
				CanopyF: null,
				CanopyGMax: null,
				CanopyMaxFraction: null
			},
			commodityTypeId: this.f.CommodityTypeId.value,
			maxAge: this.f.MaxAge.value,
		}

		this._dialog.open(PerennialCanopyDialog, {
			disableClose: true,
			data: data,
			maxWidth: 'unset'
		});
	}

	/**
     * Get initial parameter defaults for creating a new crop type
     */
	private async _getCreateModel(): Promise<ICropTypeViewModel> {

		let response: ICropTypeViewModel = await this._cropTypeService.getCreateModel();

		this.perennialCanopies = response.PerennialCanopies;

		return response;
	}

	/**
     * get cropTypeViewModel from backend to edit
     */
	private async _getEditModel(id: number): Promise<ICropTypeViewModel> {
		let response: ICropTypeViewModel;

		if (!id) {
			return of(null).toPromise();
		}

		response = await this._cropTypeService.getEditModel(id);

		if (!response) {
			return null;
		}

		this.perennialCanopies = response.PerennialCanopies;
		this._sortPerennialCanopy();

		return response;
	}

	private async _getCloneModel(id: number): Promise<ICropTypeViewModel> {
		let response: ICropTypeViewModel;

		if (!id) {
			return of(null).toPromise();
		}

		response = await this._cropTypeService.getCloneModel(id);

		if (!response) {
			return null;
		}

		this.perennialCanopies = response.PerennialCanopies;
		response.Name = null;

		return response;
	}

	public update() {
		let model: ICropTypeViewModel;

		this.hasChanges = false;
		model = CropTypeViewmodelConverter.convert(this.form.value as ICropSettingsForm);
		model.PerennialCanopies = this.perennialCanopies;

		if (this._cropTypeId) {
			this._cropTypeService.update(model).then(() => {
				this._notifyAfterSave();
			});
		} else {
			this._cropTypeService.create(model).then(() => {
				this._notifyAfterSave();
			});
		}
	}

	private _notifyAfterSave(): void {
		this._notificationService.generateNotifcation({
			type: eNotificationTypes.UPDATE,
			message: 'Crop Type ' + this.f.Name.value
			+ ' was successfully saved'
		});

		this._navigationRouter.navigate(['CropTypes']);
	}

	/**
     * Back button => back to list view
     */
	public goBack() {
		this._navigationRouter.navigate(['CropTypes']);
	}
}
