export interface Position {
	coords: {
		latitude: number,
		longitude: number,
	},
	timestamp: number
}

export class GeoLocation {
	public static getCoordinates(): Promise<Position> {
		if (navigator.geolocation) {
			return new Promise(
				(resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject)
			);
		} else {
			return new Promise(
				resolve => resolve({
					coords: null,
					timestamp: null
				})
			)
		}
	}
}
