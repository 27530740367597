import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeleteConfirmationComponent, DeleteConfirmationSettings } from './delete-confirmation';
import { SharedKeepDiscardDialog } from './keep-discard-dialog';
import { SharedUpdateService } from './update.service';

@Component({
	moduleId: module.id,
	selector: 'base-dialog',
	template: ''
})

export class BaseDialog implements OnInit, OnDestroy {

	private _dialogHideClass = 'bk-dialog--hidden';
	protected _subscriptions$: Subject<boolean>;
	protected _deleteConfirmationData: DeleteConfirmationSettings;
	public form: FormGroup;

	constructor(
		private _dialog: MatDialog, // used to open other dialogs
		private _dialogRef: MatDialogRef<BaseDialog>,
		private _sharedUpdateService: SharedUpdateService) {
	}

	/**
	 * Call super.ngOnInit() in child ngOnInit() to trigger this
	 * it will not automatically be triggered
	 */
	ngOnInit(): void {
		this._bindKeepDiscardSubscriptions();

		// custom click outside handling
		this._dialogRef.backdropClick().pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.onClose();
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	/**
	 * Method that's triggered when user tries to close the parent dialog.
	 * If changes are detected, we ask the user to confirm discarding changes
	 */
	public onClose(): void {
		if (this.form && this.form.touched) {
			this._dialog.open(SharedKeepDiscardDialog, {
				width: '500px',
				disableClose: true,
			});

			this._hide();
		} else {
			this.close();
		}
	}

	/***
	 * Method visible in the template for opening
	 * the confirmation dialog
	 */
	public onDeleteConfirmationOpen(): void {

		if (!this._deleteConfirmationData) {
			throw new Error('delete confirmation data is not configured');
		}

		this._hide();

		this._dialog.open(DeleteConfirmationComponent, {
			disableClose: true,
			data: this._deleteConfirmationData
		});

		this._sharedUpdateService.delete$.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.delete();
		});

		this._sharedUpdateService.cancel$.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this._show();
		});
	}

	/***
	 * Close the dialog. Need to be accessible to child templates to avoid
	 * a compilation error
	 */
	public close(): void {
		this._dialogRef.close();
	}

	/***
	 * Delete implementation, should be specified by parent class
	 */
	protected delete(): void {} // override

	/***
	 * Sets up subscriptions for keep/discard events
	 */
	private _bindKeepDiscardSubscriptions(): void {
		this._sharedUpdateService.keep$.pipe(takeUntil(this._subscriptions$)).subscribe(() => {

			this._show();
		});

		this._sharedUpdateService.discard$.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.close();
		})
	}
	/***
	 * Hide the parent dialog while child dialogs are open
	 */
	private _hide(): void {
		this._dialogRef.addPanelClass(this._dialogHideClass);
	}

	/***
	 * Show the parent dialog, after closing the child dialog
	 */
	private _show(): void {
		this._dialogRef.removePanelClass(this._dialogHideClass);
	}
}
