import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedUpdateService } from '../shared/dialogs/update.service';

@Component({
	moduleId: module.id,
	selector: 'custom-saturation-help',
	templateUrl: 'custom-saturation-help.html',
	styleUrls: ['custom-saturation-help.scss']
})

export class CustomSaturationHelp implements OnInit, OnDestroy {
	private _subscriptions$: Subject<boolean>;

	constructor(
		private _dialogRef: MatDialogRef<CustomSaturationHelp>,
		private _sharedUpdateService: SharedUpdateService,
	) {
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();
		this._configureClickOutside(this._subscriptions$, this._dialogRef);
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public close(): void {
		this._sharedUpdateService.cancel();
		this._dialogRef.close();
	}

	private _configureClickOutside(s: Subject<boolean>, d: MatDialogRef<CustomSaturationHelp>): void {
		// custom click outside behavior
		d.disableClose = true;

		d.backdropClick().pipe(takeUntil(s)).subscribe(() => {
			d.close();
		});
	}
}
