<admin-nav></admin-nav>
<div class="bk-admin__page">

    <h5 class="bk-text--gray">{{'Ignore Schedules' | translate }}</h5>

    <div class="bk-data-table bk-data-table__ignore-schedules">
        <div class="bk-data-table__head">
            <div class="bk-data-table__title">
                {{ 'Weather Station Ignore Schedule' | translate}}
                <button class="bk-button bk-data-table__title--button-right" (click)="openDialog()">
                    <span class="bk-data-table__plus-icon">+</span> {{ 'Add' | translate}}
                </button>
            </div>

            <div class="bk-data-table__search">
                <label class="bk-data-table__search--label">
                    {{ 'Filter By' | translate }}
                    <input class="bk-data-table__search--input" placeholder="Station, Start Date, End Date" (keyup)="applyFilter($event.target.value)"/>
                </label>
            </div>
        </div>

        <div class="bk-data-table__content">
            <mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="StationName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Station' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.StationName}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="StartDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Start Date' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.StartDate | date: 'shortDate'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="EndDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'End Date' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.EndDate | date: 'shortDate'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="IgnoreET">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Ignore ET?' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.IgnoreET | yesNo}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="IgnorePrecipitation">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Ignore Precipitation?' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.IgnorePrecipitation | yesNo}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <mat-header-cell *matHeaderCellDef> {{ 'Action' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <button class="bk-button bk-data-table__button"
                            (click)="openDialog(row)">
                            {{'Edit' | translate}}
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div class="bk-data-table__empty" *ngIf="!isIgnoreSchedulesEmpty && dataSource && dataSource.filteredData && dataSource.filteredData.length === 0">
                {{ 'There are no Ignore Schedules matching' | translate }} <span class="bk-data-table__empty--filter">"{{dataSource.filter}}"</span>
            </div>

            <div class="bk-data-table__empty" *ngIf="isIgnoreSchedulesEmpty">
                {{ 'Currently there are no Ignore Schedules' | translate }}
            </div>

            <mat-paginator [pageSizeOptions]="[10, 25]"></mat-paginator>
        </div>

    </div>
</div>