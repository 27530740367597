import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../../../services/token.service';
import { Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-home',
	templateUrl: 'home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	constructor(
		private _tokenService: TokenService,
		private _router: Router,
		private _meta: Meta
	) {
		// On home page, redirect to the app dashboard if already logged in
		if (this._tokenService.isAuthenticated()) {
			this._router.navigate(['MyDashboard']);
		}

		this._meta.updateTag({ name: 'description',
			content: 'CropManage is a crop management web application developed ' +
			'by UCANR which help farmers manage irrigation and fertilization scheduling.'})
	}

	ngOnInit(): void {
	}
}
