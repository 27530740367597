import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { IFlowWateringViewModel } from '../interfaces';

@Component({
	moduleId: module.id,
	selector: 'flowmeter-details-table',
	templateUrl: 'main.html'
})

export class FlowmeterDetailsTableComponent implements OnInit, OnChanges {
	@Input()
	public flowmeterData: IFlowWateringViewModel[];

	constructor() { }

	ngOnInit(): void {

	}

	ngOnChanges(changes: SimpleChanges): void {
	}
}

