import { DateUtility } from '../../classes/dateUtility';
import { IIrrigationEventEditResponse } from './interfaces';

export class IrrigationEventUpdateParams {
	public EventDate?: Date;
	public IrrigationMethodId: number;
	public ManagerAmountRecommendation?: number;
	public ManagerAmountRecommendationHours?: number;
	public WaterApplied?: number;
	public WaterAppliedHours?: number;
	public CustomDeficit?: number;
	public IsCustomDeficitEnabled: boolean;

	public constructor(e: IIrrigationEventEditResponse) {
		if (!e) {
			return;
		}

		this.EventDate = DateUtility.ConvertToUTC(e.EventDate);
		this.IrrigationMethodId =  e.IrrigationMethodId;
		this.ManagerAmountRecommendation = e.ManagerAmountRecommendation;
		this.ManagerAmountRecommendationHours = e.ManagerAmountRecommendationHours;
		this.WaterApplied = e.WaterApplied;
		this.WaterAppliedHours = e.WaterAppliedHours;
		this.CustomDeficit = e.CustomDeficit;
		this.IsCustomDeficitEnabled = e.IsCustomDeficitEnabled;
	}

	public setDate(d: Date): void {
		this.EventDate = DateUtility.ConvertToUTC(d);
	}
}
