import { Component, Inject, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IAppliedWaterChartDataSet } from './interfaces';
import { DateUtility } from '../../classes/dateUtility';
import { HighChartsService } from '../../classes/highCharts.service';
import { PlantingService } from '../planting-settings/service';
import * as moment from 'moment';

@Component({
	moduleId: module.id,
	selector: 'applied-water-chart',
	templateUrl: 'applied-water-chart.html',
	styleUrls: [ 'applied-water-chart.scss']
})

export class AppliedWaterChartComponent implements OnInit, OnDestroy {

	@ViewChild('appliedWaterChartContainer', { static: true }) private _appliedWaterChartContainer: ElementRef;
	public chartHasData = true;
	private _chartData: Highcharts.IndividualSeriesOptions[];
	public plantingName: string;
	private _harvestDate: Date;
	public maxDate: Date;
	private _subscriptions$: Subject<boolean>;
	public isSaving: boolean;

	constructor(
		private _service: PlantingService,
		private _dialogRef: MatDialogRef<AppliedWaterChartComponent>,
		@Inject(MAT_DIALOG_DATA) private _data: { lotPlantingId: number, name: string, harvestDate: Date }
	) {
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();
		this._configureClickOutside(this._subscriptions$, this._dialogRef);

		if (!this._data) {
			throw new Error('data is empty');
		}

		this.plantingName = this._data.name;
		this._harvestDate = this._data.harvestDate;
		this._initializeForm();

		this._service.getAppliedWaterChart(this._data.lotPlantingId).then(response => {
			this._convertToGraphData(response);
			HighChartsService.drawGraph(this._appliedWaterChartContainer, this._chartData);
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public cancel(): void {
		this._dialogRef.close();
	}

	private _configureClickOutside(s: Subject<boolean>, d: MatDialogRef<AppliedWaterChartComponent>): void {
		// custom click outside behavior
		d.disableClose = true;

		d.backdropClick().pipe(takeUntil(s)).subscribe(() => {
			d.close();
		});
	}

	private _convertToGraphData(records: IAppliedWaterChartDataSet): void {

		let temp: {
			recommendations: Highcharts.IndividualSeriesOptions,
			waterApplied: Highcharts.IndividualSeriesOptions,
		};

		temp = {
			recommendations: {
				name: 'Recommended',
				visible: true,
				data: []
			},
			waterApplied: {
				name: 'Water Applied',
				visible: true,
				data: []
			}
		}

		this.chartHasData = true;

		if (records && records.Recommendations && records.Recommendations.length > 0) {
			for (let record of records.Recommendations) {
				let utc: number;

				utc = DateUtility.DateToUTC(DateUtility.DotNetToDate(record.EventDate));
				temp.recommendations.data.push([utc, record.Inches]);
			}
		} else {
			this.chartHasData = false;
		}

		if (records && records.WaterApplied && records.WaterApplied.length > 0) {
			for (let record of records.WaterApplied) {
				let utc: number;

				utc = DateUtility.DateToUTC(DateUtility.DotNetToDate(record.EventDate));
				temp.waterApplied.data.push([utc, record.Inches]);
			}
		}

		this._chartData = [];

		this._chartData.push(temp.recommendations);
		this._chartData.push(temp.waterApplied);
	}

	public onUpdate(): void {
		this.isSaving = true;

		this._service.getAppliedWaterChart(this._data.lotPlantingId).then(response => {
			this._convertToGraphData(response);
			HighChartsService.drawGraph(this._appliedWaterChartContainer, this._chartData);
			this.isSaving = false;
		});
	}

	private _initializeForm(): void {
		this.maxDate = moment(this._harvestDate).subtract(1, 'days').toDate();
	}
}
