import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { ValidateService } from '../../../services/validate.service';

@Component({
	moduleId: module.id,
	selector: 'slider-button',
	templateUrl: 'main.html'
})

export class SliderButtonComponent {

	@Input()
	public sliderValue: number;

	@Input()
	public sliderMin: number;

	@Input()
	public sliderMax: number;

	@Input()
	public sliderDecimals: number;

	@Input()
	public sliderDisabled: boolean;

	@Input()
	public step: number;

	@Output()
	public sliderValueChanged: EventEmitter<number> = new EventEmitter<number>();

	@Output()
	public onSliderMouseUp: EventEmitter<JQueryEventObject> = new EventEmitter<JQueryEventObject>();

	public isSliderVisible: boolean;

	private showingSlider: boolean;

	ngOnInit(): void {
		this.isSliderVisible = false;
		this.showingSlider = false;
	}

	public showSlider(): void {
		this.showingSlider = true;

		this.isSliderVisible = true;

		// $('.mat-slider-thumb').bind('mouseup', this.handleSliderMouseUp.bind(this));
	}

	public hideSlider(): void {
		if (!this.isSliderVisible) {
			return;
		}

		if (this.showingSlider) {
			this.showingSlider = false;
			return;
		}

		// $('.mat-slider-thumb').unbind('mouseup', this.handleSliderMouseUp);

		this.isSliderVisible = false;

		// this.onSliderMouseUp.emit(null);
	}

	/**
	 * Called by mat slider's input event emitter. Triggered when user touches the slider
	 *
	 * @param e
	 */
	public sliderChanged(e: number) {

		if (!e || !ValidateService.isValidNumber(e)) {
			return;
		}

		if (ValidateService.isValidNumber(this.sliderDecimals)) {
			e = ValidateService.roundToDecimalPlaces(e, this.sliderDecimals);
		}

		this.sliderValueChanged.emit(e);
	}

	public handleSliderMouseUp(eventObject: JQueryEventObject): void {
		eventObject.stopPropagation();

		if (!this.onSliderMouseUp) {
			return;
		}

		this.onSliderMouseUp.emit(eventObject);
	}
}
