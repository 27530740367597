import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MainMenuComponent } from '../main-menu/main-menu.component';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';

import { UserService } from '../../models/user/service';
import { PersistentDatabase } from '../../services/persistent-database';
import { TranslateService } from '../localization/service';
import { UpdateService } from '../../services/update.service';
import { ICurrentUser } from '../../models/user/interfaces';
import { LanguageCode } from '../localization/interfaces';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackComponent } from '../feedback/feedback';
import { SupportComponent } from '../support/support';

@Component({
	moduleId: module.id,
	selector: 'global-header',
	templateUrl: `global-header.component.html`,
	styleUrls: ['global-header.component.scss']
})

export class GlobalHeaderComponent implements OnInit, OnDestroy {
	@ViewChild(MainMenuComponent, { static: true }) public mainMenuComponent: MainMenuComponent;

	public isAuthenticated: boolean;
	public user: ICurrentUser;
	public languageCodes: LanguageCode[];
	public languageName: string; // name of active language
	private _subscriptions$: Subject<boolean>;

	constructor(
		public translateService: TranslateService,
		private _dialog: MatDialog,
		private _router: Router,
		private _location: Location,
		private _tokenService: TokenService,
		private _userService: UserService,
		private _persistentDatabase: PersistentDatabase,
		private _updateService: UpdateService
	) { }

	ngOnInit() {
		let publicUrls: string[];

		this._subscriptions$ = new Subject();
		publicUrls = ['', '/login'];

		this.isAuthenticated = this._tokenService.isAuthenticated();

		this._router.events
			.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			// if user is in public area, don't show app area navigation
			if (publicUrls.indexOf(this._location.path()) !== -1) {
				this.isAuthenticated = false;
			} else {
				this.isAuthenticated = this._tokenService.isAuthenticated();
			}
		});

		this._updateService.authToken$
			.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.isAuthenticated = this._tokenService.isAuthenticated();

			if (this.isAuthenticated) {
				this._getLocalization().then(res => {
					this._getCurrentUser(res);
				});

				this._persistentDatabase.isGlobalNotificationVisible = this._persistentDatabase.GlobalNotificationIsVisible();
			}
		});

		if (this.isAuthenticated === false) {
			return;
		}

		this._getLocalization().then(res => {
			this._getCurrentUser(res);
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	private _getLocalization(): Promise<LanguageCode[]> {
		return this.translateService.loadLanguages()
		.then(res => {
			this.languageCodes = res;
			this.translateService.loadTranslations();
			return res;
		});
	}

	public openMenu(): void {
		this.mainMenuComponent.open();
	}

	public onClickHomePage(): void {
		this._router.navigate(['']);
	}
	// Clicking on the header logo takes you back to the dashboard, for now, since there
	// until we have a better alternative
	public goToDashboard(): void {
		this._router.navigate(['MyDashboard']);
	}

	private _getCurrentUser(languageCodes: LanguageCode[]): void {
		this._userService.getCurrentUser()
		.then(res => {
			if (!res) {
				return;
			}

			if (!languageCodes || languageCodes.length === 0) {
				return;
			}

			this.user = res
			this._persistentDatabase.user = res;
			this.translateService.currentLanguageId = this.user.LanguageCodeId;
			this.languageName = languageCodes.find(x => x.Id === this.translateService.currentLanguageId).Language;
			this._updateService.setCurrentRanchUserAsReceived(res);
		});
	}

	public openHelpDocumentation(): void {
		window.open('https://help.cropmanage.ucanr.edu/tutorials/', '_blank');
	}

	public openFeedbackDialog(): void {

		this._dialog.open(FeedbackComponent, {
			disableClose: true,
		});
	}

	public setLanguage(id: number): void {
		this.translateService.currentLanguageId = id;
		this.languageName = this.languageCodes.find(x => x.Id === this.translateService.currentLanguageId).Language;
	}

	public openTechnicalSupport(): void {
		this._dialog.open(SupportComponent, {
			disableClose: true,
		});
	}
}
