import { ITextValue, CMError, SuccessResponse } from '../../interfaces/interfaces';
import { IIdNamePair } from '../../components/planting-settings/interfaces';
import { IChangePassword } from '../../components/profile/interfaces';

/**
 * Model structure from userDetailsController:GetCurrentUser()
 */
export interface ICurrentUser {
	FirstName: string,
	LastName: string,
	BETACommoditiesEnabled: boolean,
	LanguageCodeId: number,
	Roles: IIdNamePair[],
	Id: number
}

/*
 * The admin user role
 */
export const ADMIN_USER_ROLE = 1;

export interface IUserService {
	userIsAdmin(): Promise<boolean>,
	getCurrentUser(override: boolean): Promise<ICurrentUser>,
	updateUserDetails(userDetails: ICurrentUser): Promise<CMError>,
	changePassword(password: IChangePassword): Promise<CMError | SuccessResponse>,
	emailRanchOwner(email: string, subject: string, body: string): Promise<Object>,
}
