<div class="bk-profile__tab-container" id="changePassword">
    <h5 class="bk-text--gray">{{'Change Password' | translate }}</h5>

    <div *ngIf="savePasswordSuccess === true"
        class="bk-alert--success">
        <i class="fa fa-check"></i>
        {{'Your Password was successfully updated' | translate }}
        <span (click)="savePasswordSuccess = null" class="bk-alert__close">&times;</span>
    </div>
    <div *ngIf="savePasswordSuccess === false"
        class="bk-alert--failed">
        <i class="fa fa-times"></i>
        {{errorMessage}}
        <span (click)="savePasswordSuccess = null"
        class="bk-alert__close">&times;</span>
    </div>

    <form>
        <div class="bk-profile__form">
            <input class="bk-hidden" type="text" name="username" autocomplete="username" value="username"/><!-- optionally hidden username field, to suppress warning-->
            <div class="bk-modal__body bk-modal__body--white">
                <label class="bk-modal__label">
                    {{'Current Password' | translate }}
                </label>

                <input class="bk-modal__field bk-input--full"
                    type="password"
                    name="OldPassword"
                    autocomplete="current-password"
                    [ngClass]="{'bk-input-invalid' : !formValid.currentPassword}"
                    [(ngModel)]="changePasswordObject.OldPassword"
                    (ngModelChange)="validateCurrentPassword(changePasswordObject.OldPassword)"/>

                <div *ngIf="!formValid.currentPassword"
                    class="bk-invalid-text">
                    {{'The current password does not match our records' | translate }}
                </div>

                <label class="bk-modal__label">
                    {{'New Password' | translate }}
                </label>

                <input class="bk-modal__field bk-input--full"
                    type="password"
                    name="NewPassword"
                    autocomplete="new-password"
                    [ngClass]="{'bk-input-invalid' : !formValid.newPassword}"
                    [(ngModel)]="changePasswordObject.NewPassword"
                    (ngModelChange)="validateNewPassword(changePasswordObject.NewPassword)"/>
        
                <div *ngIf="!formValid.currentPassword"
                    class="bk-invalid-text">
                    {{'New password invalid' | translate }}
                </div>

                <label class="bk-modal__label">
                    {{'Confirm New Password' | translate }}
                </label>

                <input class="bk-modal__field bk-input--full"
                    type="password"
                    name="ConfirmPassword"
                    autocomplete="new-password"
                    [ngClass]="{'bk-input-invalid' : !formValid.confirmPassword}"
                    [(ngModel)]="changePasswordObject.ConfirmPassword"
                    (ngModelChange)="validateConfirmPassword(changePasswordObject.ConfirmPassword)">

                <div *ngIf="!formValid.confirmPassword"
                    class="bk-invalid-text">
                    {{'Passwords do not match' | translate }}
                </div>
            </div>
            <div class="modal-footer bk-modal__footer">
                <button [disabled]="changePasswordInvalid()"
                    class="bk-button bk-button--medium"
                    (click)="changePassword()">
                    {{'Change Password' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>