import { Injectable } from '@angular/core';
import { UpdateService } from '../../services/update.service';
import { HttpService } from '../../services/http.service';
import { IPermissionsService } from './interfaces';


import { IRanchPermissions, ISuccessResponse, IRanchFeatureEmptyPermissions,
	IRanchPermissionTypes, IRanchUserPermissionWithWebAPIViewModel } from './interfaces';

import { CMError } from '../../interfaces/interfaces';

@Injectable()
export class PermissionsService implements IPermissionsService {

	private ranchPermissionTypes: IRanchPermissionTypes[] = [
		{
			'PermissionId': 1,
			'PermissionName': 'No Access'
		},
		{
			'PermissionId': 2,
			'PermissionName': 'View Only'
		},
		{
			'PermissionId': 3,
			'PermissionName': 'Edit'
		}
	];

	private ranchFeatureDefaultPermissions: IRanchFeatureEmptyPermissions[] = [
		{
			'FeatureId': 1,
			'FeatureName': 'Ranch',
			'PermissionId': 2
		},
		{
			'FeatureId': 2,
			'FeatureName': 'Planting',
			'PermissionId': 3
		},
		{
			'FeatureId': 3,
			'FeatureName': 'Soil Sample',
			'PermissionId': 3
		},
		{
			'FeatureId': 4,
			'FeatureName': 'Fertilization',
			'PermissionId': 3
		},
		{
			'FeatureId': 5,
			'FeatureName': 'Irrigation',
			'PermissionId': 3
		},
		{
			'FeatureId': 6,
			'FeatureName': 'Data Export',
			'PermissionId': 1
		}
	];


	private ranchFeatureEmptyPermissions: IRanchFeatureEmptyPermissions[] = [
		{
			'FeatureId': 1,
			'FeatureName': 'Ranch',
			'PermissionId': null
		},
		{
			'FeatureId': 2,
			'FeatureName': 'Planting',
			'PermissionId': null
		},
		{
			'FeatureId': 3,
			'FeatureName': 'Soil Sample',
			'PermissionId': null
		},
		{
			'FeatureId': 4,
			'FeatureName': 'Fertilization',
			'PermissionId': null
		},
		{
			'FeatureId': 5,
			'FeatureName': 'Irrigation',
			'PermissionId': null
		},
		{
			'FeatureId': 6,
			'FeatureName': 'Data Export',
			'PermissionId': null
		}
	];

	private urls = {
		ranchPlantingPermissions: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/permissions.json`,
		ranchMemberPermissions: {
			show: (ranchGuid: string, userId: string) => `/v2/ranches/${ranchGuid}/users/${userId}/permissions.json`,
			showAdminOnly: (ranchGuid: string, userId: string) => `/v0/ranches/${ranchGuid}/users/${userId}/permissions.json`,
			update: (ranchGuid: string, userId: string) => `/v2/ranches/${ranchGuid}/users/${userId}/permissions.json`,
			updateAdminOnly: (ranchGuid: string, userId: string) => `/v0/ranches/${ranchGuid}/users/${userId}/permissions.json`,
		}
	};

	constructor(private updateService: UpdateService, private httpService: HttpService) { }

	public getRanchPlantingsPermissions(id: string): Promise<IRanchPermissions> {
		return this.httpService.get({
			url: this.urls.ranchPlantingPermissions(id),
			callback: function (json: IRanchPermissions) {
				return json;
			},
			isWebAPI: true
		});
	}

	public getRanchMemberPermissions(userId: string, ranchGuid?: string,
		shouldPermissionProtect = true): Promise<IRanchUserPermissionWithWebAPIViewModel | CMError> {
		ranchGuid = ranchGuid ? ranchGuid : this.updateService.currentRanchId;

		return this.httpService.get({
			url: shouldPermissionProtect ? this.urls.ranchMemberPermissions.show(ranchGuid, userId) :
				this.urls.ranchMemberPermissions.showAdminOnly(ranchGuid, userId),
			isWebAPI: true,

			callback: function (json: IRanchUserPermissionWithWebAPIViewModel) {
				if (json) {
					return Promise.resolve(json);
				} else {
					return {};
				}
			}
		});
	}

	public editRanchMemberPermissions(userId: string, permissions: IRanchFeatureEmptyPermissions[],
		ranchGuid?: string, adminOnly = false): Promise<ISuccessResponse> {

		ranchGuid = ranchGuid ? ranchGuid : this.updateService.currentRanchId;

		return this.httpService
			.put({
				url: adminOnly ?
					this.urls.ranchMemberPermissions.updateAdminOnly(ranchGuid, userId) :
					this.urls.ranchMemberPermissions.update(ranchGuid, userId),
				isWebAPI: true,
				body: { 'Site': permissions, 'WebAPIAccess': false },
				callback: (response: ISuccessResponse) => { return response }
			});
	}

	public getRanchPermissionTypes(): IRanchPermissionTypes[] {
		return this.ranchPermissionTypes;
	}

	public getRanchFeatureDefaultPermissions(): IRanchFeatureEmptyPermissions[] {
		return this.ranchFeatureDefaultPermissions;
	}

	public getRanchFeatureEmptyPermissions(): IRanchFeatureEmptyPermissions[] {
		return this.ranchFeatureEmptyPermissions;
	}
}
