<ng-container *ngIf="recommendationSummary">

	<!-- Parameters List Section -->
	<!-- totalCropNDemand (trees) -->
    <div class="bk-modal__dropdown-row"
        *ngIf="recommendationSummary.TotalCropNDemand !== undefined">
        <span class="bk-recommendation__label bk-bold">
            {{'Seasonal Crop N Demand' | translate }}
            <info-icon matTooltip="{{'TotalCropNDemand Text' | translate}}"></info-icon>
        </span>
        <span class="bk-recommendation__data">
            {{recommendationSummary.TotalCropNDemand | number: '1.2-2'}} {{'lbs' | translate }} N/{{'acre' | translate }}
        </span>
    </div>

	<!-- N Soil Ammendments (trees) -->
    <div class="bk-modal__dropdown-row"
        *ngIf="recommendationSummary.NSoilAmendments !== undefined">
        <span class="bk-recommendation__label bk-bold">
            {{'N Soil Amendments' | translate }}
            <mat-icon class="bk-info-icon"
                matTooltip="{{'Manure' | translate }}: {{recommendationSummary.ManureN}} lbs N/acre, {{'Compost' | translate}}: 
                {{recommendationSummary.CompostN}} lbs N/acre, {{'Cover Crop' | translate}}: 
                {{recommendationSummary.CoverCropN}} lbs N/acre"
                [matTooltipPosition]="'above'" >
                info_outline
            </mat-icon>
        </span>
        <span class="bk-recommendation__data">
            {{recommendationSummary.NSoilAmendments | number: '1.2-2'}} {{'lbs' | translate }} N/{{'acre' | translate }}
        </span>
    </div>

	<!-- Usen Efficiency (trees)-->
    <div class="bk-modal__dropdown-row"
        *ngIf="recommendationSummary.UseEfficiency !== undefined">
        <span class="bk-recommendation__label bk-bold">
            {{'Fertilizer N Use Efficiency' | translate }}
            <info-icon matTooltip="{{'Use Efficiency Text' | translate}}"></info-icon>
        </span>
        <span class="bk-recommendation__data">
            {{recommendationSummary.UseEfficiency | number: '1.2-2'}} %
        </span>
    </div>

	<!-- Future N Uptake -->
    <div class="bk-modal__dropdown-row"
        *ngIf="recommendationSummary.NUptakeDifference != null">
        <span class="bk-recommendation__label bk-bold">
            {{'Crop N Uptake' | translate }}
            <info-icon matTooltip="{{'Crop N Uptake Text' | translate}}"></info-icon>
        </span>
        <span class="bk-recommendation__data">
            {{recommendationSummary.NUptakeDifference | number: '1.2-2'}} {{'lbs' | translate }} N/{{'acre' | translate }}
        </span>
    </div>

	<!-- N Fertilizer Factor (strawberry) -->
    <div class="bk-modal__dropdown-row"
        *ngIf="recommendationSummary.NFertilizerFactor != null">
        <span class="bk-recommendation__label bk-bold">
            {{'N Fertilizer Factor' | translate }}
            <info-icon [title]="'N Fertilizer Factor Text'"></info-icon>
        </span>
        <span class="bk-recommendation__data">
            {{recommendationSummary.NFertilizerFactor | number: '1.2-2'}}
        </span>
    </div>

	<!-- Soil N-->
    <div class="bk-modal__dropdown-row"
        *ngIf="recommendationSummary.SoilN != null">
        <span class="bk-recommendation__label bk-bold">
            {{'Soil N' | translate }}
            <info-icon [title]="'Soil N Text'"></info-icon>
        </span>
        <span class="bk-recommendation__data">
            {{recommendationSummary.SoilN | number: '1.2-2'}} {{'lbs' | translate }} N/{{'acre' | translate }}
        </span>
        <span *ngIf="recommendationSummary.SoilNppm !== null"
            class="bk-recommendation__sub-data">
            ({{ recommendationSummary.SoilNppm | number: '1.2-2' }} ppm N)
        </span>
    </div>

	<!-- Soil N Threshold -->
    <div class="bk-modal__dropdown-row"
        *ngIf="recommendationSummary.SoilNThreshold != null">
        <span class="bk-recommendation__label bk-bold">
            {{'Soil N Threshold' | translate }}
            <info-icon [title]="'Soil N Threshold Text'"></info-icon>
        </span>
        <span class="bk-recommendation__data">
            {{recommendationSummary.SoilNThreshold | number: '1.2-2'}} {{'lbs' | translate }} N/{{'acre' | translate }}
        </span>
        <span *ngIf="recommendationSummary.SoilNThresholdppm !== null"
            class="bk-recommendation__sub-data">
            ({{ recommendationSummary.SoilNThresholdppm | number: '1.2-2' }} ppm N)
        </span>
    </div>

	<!-- Soil Density -->
    <div class="bk-modal__dropdown-row"
        *ngIf="recommendationSummary.Density > 0">
        <span class="bk-recommendation__label bk-bold">
            {{'Soil Bulk Density' | translate }}
            <info-icon [title]="'Soil Bulk Density Text'"></info-icon>
        </span>
        <span class="bk-recommendation__data">
            {{recommendationSummary.Density | number: '1.2-2'}} g/cc
        </span>
    </div>

	<!-- N Mineral Total -->
    <div class="bk-modal__dropdown-row"
        *ngIf="recommendationSummary.NMineralTotal != null">
        <span class="bk-recommendation__label bk-bold">
            {{'Total Mineralized N' | translate }}
            <info-icon [title]="'Total Mineralized N Text'"></info-icon>
        </span>
        <span class="bk-recommendation__data">
            {{recommendationSummary.NMineralTotal | number: '1.2-2'}} {{'lbs' | translate }} N/{{'acre' | translate }}
        </span>
    </div>

	<!-- Fertilizations per month -->
    <div class="bk-modal__dropdown-row"
        *ngIf="recommendationSummary.FertilizationsPerMonth != null">
        <span class="bk-recommendation__label bk-bold">
            {{'Fertilizations Per Month' | translate }}
            <info-icon [title]="'Fertilizations Per Month Text'"></info-icon>
        </span>
        <span class="bk-recommendation__data">
            {{recommendationSummary.FertilizationsPerMonth | number: '1.2-2'}}
        </span>
    </div>

	<!-- N in water -->
    <div class="bk-modal__dropdown-row bk-modal__dropdown-row--n-contribution"
        *ngIf="recommendationSummary.NAppliedFromWater > 0">
        <span class="bk-recommendation__label bk-bold">
            {{'Water N Contribution' | translate }}
            <info-icon [title]="'Water N Contribution Text'"></info-icon>
        </span>
        <span class="bk-recommendation__data">
            {{ recommendationSummary.NAppliedFromWater | number: '1.2-2'}} {{'lbs' | translate }} N/{{'acre' | translate }}
        </span>
        <span class="bk-recommendation__sub-data">
            ({{ nInWater }} 
            <ng-container *ngIf="isFormulationTypeDry">
                {{'lbs' | translate }}/{{'acre' | translate }}
            </ng-container>
            <ng-container *ngIf="!isFormulationTypeDry">
                {{'gal' | translate }}/{{'acre' | translate }}
            </ng-container>)
        </span>
    </div>

	<!-- Equations Section -->
    <div class="bk-modal__dropdown">
        {{equationDescriptions[0]}}
    </div>
    <div class="bk-modal__dropdown bk-bold">
        {{equations[0]}}
    </div>
    <div *ngIf="equationDescriptions[1] !== undefined"
        class="bk-modal__dropdown"
        [innerHTML]="equationDescriptions[1]">
    </div>
    <div *ngIf="equations[1] !== undefined" class="bk-modal__dropdown bk-bold">
        {{equations[1]}}
    </div>
    <div *ngIf="equationDescriptions[2] !== undefined"
        class="bk-modal__dropdown"
        [innerHTML]="equationDescriptions[2]">
    </div>
        
</ng-container>