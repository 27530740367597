<div class="bk-dialog">
	
	<h4 mat-dialog-title>
		{{ 'Send Feedback' | translate }}
	</h4>

	<div mat-dialog-content>
		<textarea
			class='bk-feedback__textarea'
			[(ngModel)]='description'
			placeholder="{{ 'Tell us what you think' | translate }}!">
		</textarea>
	</div>

	<div mat-dialog-actions align="end">

		<button (click)="cancel()" mat-button
			class="bk-button--medium bk-dialog__button--secondary bk-button--text-only">
			{{ 'Cancel' | translate }}
		</button>

		<button
			[disabled]="!description"
			(click)="send()"
			mat-button
			class="bk-button bk-button--medium bk-dialog__button--primary">
			{{ 'Send' | translate }}
		</button>
	</div>
</div>