import { DateUtility } from '../../classes/dateUtility';
import { ICropStage } from '../../models/crop-stage/interfaces';
import { Internal, IncomingJSON, eMeasureUnitTypes } from './interfaces';
import { IEventGroup } from '../../models/event/interfaces';
import { IIdNamePair } from '../../interfaces/abstract';
import { INutrient } from '../../models/nutrient/interfaces';

export enum eEventDialogViews {
	FORM = 1,
	DISCARD_CHANGES,
	DELETE_CONFIRMATION,
}

export class TissueSampleEvent implements Internal.ITissueSampleEvent {
	public Id: number;
	public PlantingId: number;
	public Metainfo: string;
	public EventDate: Date;
	public LastUpdatedUser: string;
	public LastUpdatedTimestamp: string;
	public CropStageId: number;
	public Location: IIdNamePair;
	public LocationId?: number;
	public LocationDetails?: string;
	public Notes: string;
	public CropStage: ICropStage;
	public Nutrients: INutrient[];
	public TissueSampleNutrients: IncomingJSON.TissueSampleNutrientJSON[];
	public NutrientDisplayText: string;

	public ShowDetails = false; // used to toggle hover panel on/off
	public DateOnly: boolean

	public static shouldShowWarningIcon(event: Internal.ITissueSampleEvent, eventGroup: IEventGroup): boolean {
		if (!eventGroup) {
			return null;
		}

		if (DateUtility.isTodayOrFuture(eventGroup.EventDate)) {
			return false;
		}

		if (!event.NutrientDisplayText) {

			return true;
		} else {
			return false;
		}
	}

	constructor() {
	}

	/**
     * Takes JSON data and copies into a class object
     * @param json
     */
	public transmuteJSON(json: IncomingJSON.ITissueSampleEventJSON): void {
		this.Id = json.Id;
		this.PlantingId = json.PlantingId;
		this.EventDate = DateUtility.DotNetToDate(json.EventDate);
		this.CropStageId = json.CropStageId;
		this.LocationId = json.LocationId;
		this.LocationDetails = json.LocationDetails;
		this.Notes = json.Notes;
		this.TissueSampleNutrients = json.TissueSampleNutrients;
		this.LastUpdatedUser = json.LastUpdatedUser;
		this.LastUpdatedTimestamp = json.LastUpdatedTimestamp;
	}

	public convertTissueSampleModel(json: IncomingJSON.ITissueSampleModel): void {
		let nutrients: INutrient[];
		let first: boolean;

		if (!json) {
			return;
		}

		this.Id = json.Id;
		this.Metainfo = json.Metainfo;
		this.CropStage = json.CropStage;
		this.Location = json.Location;

		this.Nutrients = new Array();
		this.NutrientDisplayText = '';

		first = true;

		if (!json.TissueSampleNutrients || json.TissueSampleNutrients.length === 0) {
			return;
		}

		for (let nutrient of json.TissueSampleNutrients) {
			if (nutrient.Value > 0) {

				this.Nutrients.push(nutrient);

				if (first) { // only display the first nutrient, due to lack of space
					this.NutrientDisplayText += nutrient.Value
						+ (nutrient.MeasurementUnitId === eMeasureUnitTypes.PPM ? ' ppm' : '%')
						+ ' ' + nutrient.Name;
				}

				first = false;
			}
		}
	}

	/**
     * Takes an empty class object and copy to another class object
     */
	public copy(event: TissueSampleEvent): void {
		this.Id = event.Id;
		this.Metainfo = event.Metainfo;
		this.PlantingId = event.Id;
		this.EventDate = event.EventDate;
		this.CropStageId = event.CropStageId;
	}

	public clear(): void {
		this.EventDate = null;
		this.CropStageId = null;
		this.Metainfo = null;
	}
}
