import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { UpdateService } from '../../services/update.service';
import { IgnoreSchedulesService } from './service';
import { IgnoreScheduleDialog } from './ignore-schedule-dialog';

import { iIgnoreSchedule } from './interfaces';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	moduleId: module.id,
	selector: 'ignore-schedules',
	templateUrl: 'ignore-schedules.component.html'
})

export class IgnoreSchedulesComponent implements OnInit, OnDestroy {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public displayedColumns = ['StationName', 'StartDate', 'EndDate', 'IgnoreET', 'IgnorePrecipitation', 'Action'];
	public dataSource: MatTableDataSource<iIgnoreSchedule>;
	public ignoreSchedules: iIgnoreSchedule[];
	public isIgnoreSchedulesEmpty = false;
	private _subscriptions$: Subject<boolean>;

	constructor(
		private _router: Router,
		private _tokenService: TokenService,
		private dialog: MatDialog,
		private updateService: UpdateService,
		private ignoreSchedulesService: IgnoreSchedulesService,
		private datePipe: DatePipe) {

		if (this._tokenService.isAuthenticated() === false) {
			this._router.navigate(['login']);
		}
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();

		this.ignoreSchedulesService.list()
			.then(response => {
				this.ignoreSchedules = response;
				this.setDataSource(this.ignoreSchedules);
			});

		this.sort.sortChange
			.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
				while (this.paginator.hasPreviousPage()) {
					this.paginator.previousPage();
				}
			}
		);

		this.sort.sort({ id: 'StartDate', start: 'desc', disableClear: false });

		this.updateService.createdIgnoreSchedule$
			.pipe(takeUntil(this._subscriptions$)).subscribe(ignoreSchedule => {
			if (!ignoreSchedule) {
				return;
			}

			this.ignoreSchedules.push(ignoreSchedule);
			this.setDataSource(this.ignoreSchedules);
		});

		this.updateService.updatedIgnoreSchedule$
			.pipe(takeUntil(this._subscriptions$)).subscribe(updatedIgnoreSchedule => {
			if (!updatedIgnoreSchedule) {
				return;
			}

			for (let ignoreSchedule of this.ignoreSchedules) {
				if (ignoreSchedule.Id === updatedIgnoreSchedule.Id) {
					ignoreSchedule.StationId = updatedIgnoreSchedule.StationId;
					ignoreSchedule.StationName = updatedIgnoreSchedule.StationName;
					ignoreSchedule.StartDate = updatedIgnoreSchedule.StartDate;
					ignoreSchedule.EndDate = updatedIgnoreSchedule.EndDate;
					ignoreSchedule.IgnoreET = updatedIgnoreSchedule.IgnoreET;
					ignoreSchedule.IgnorePrecipitation = updatedIgnoreSchedule.IgnorePrecipitation;
				}
			}

			this.setDataSource(this.ignoreSchedules);
		});

		this.updateService.deletedIgnoreSchedule$
			.pipe(takeUntil(this._subscriptions$)).subscribe(ignoreSchedule => {
			if (!ignoreSchedule) {
				return;
			}

			this.ignoreSchedules = this.ignoreSchedules.filter(x => x.Id !== ignoreSchedule.Id);
			this.setDataSource(this.ignoreSchedules);
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public applyFilter(filterValue: string): void {
		if (!this.dataSource) {
			return;
		}

		if (filterValue === null) {
			filterValue = '';
		}

		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	}

	public openDialog(ignoreShedule?: iIgnoreSchedule): void {
		this.dialog.open(IgnoreScheduleDialog, {
			width: '460px',
			data: ignoreShedule ? ignoreShedule : null,
			disableClose: true
		});
	}

	private setDataSource(ignoreSchedules: iIgnoreSchedule[]): void {
		if (!ignoreSchedules || !ignoreSchedules.length) {
			this.isIgnoreSchedulesEmpty = true;
		} else {
			this.isIgnoreSchedulesEmpty = false;
		}

		this.dataSource = new MatTableDataSource(ignoreSchedules);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
		this.dataSource.filterPredicate = (data: iIgnoreSchedule, filter: string) => {
			let stationNameFilter: boolean;

			stationNameFilter = data !== null && data.StationName !== null &&
				data.StationName.toLowerCase().includes(filter) ? true : false;

			return stationNameFilter || this.datePipe.transform(data.StartDate, 'shortDate').includes(filter)
				|| this.datePipe.transform(data.EndDate, 'shortDate').includes(filter);
		}
	}
}
