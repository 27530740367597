import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { Title } from '@angular/platform-browser';
import { CMError } from '../../../interfaces/interfaces';
import { ResetPasswordResponse } from '../../../services/interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-reset-password',
	templateUrl: 'reset-password.page.html'
})
export class ResetPasswordPage implements OnInit, OnDestroy {

	public email: string;
	public password: string;
	public confirmPassword: string;
	public userId: string;
	public code: string;
	private _subscriptions$: Subject<boolean>;

	constructor(
		private _loginService: LoginService,
		private _titleService: Title,
		private _route: ActivatedRoute,
		private _router: Router) { }

	ngOnInit(): void {
		this._subscriptions$ = new Subject();
		this._titleService.setTitle('CropManage: Reset Password');

		this._route.queryParams.pipe(takeUntil(this._subscriptions$)).subscribe((params) => {
			if (!params) {
				return;
			}

			this.userId = params['userId'];
			this.code = params['code'];
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public resetPassword(): void {
		this._loginService.resetPassword(this.email, this.password, this.confirmPassword,
		this.code).then((response) => {
			let error: CMError;
			let validResponse: ResetPasswordResponse;

			if (!response) {
				throw new Error('empty response');
			}

			error = response as CMError;
			validResponse = response as ResetPasswordResponse;

			if (error.message) {
				// display http error message
				return;
			}

			if (validResponse.ErrorMessages && validResponse.ErrorMessages.length > 0) {
				// display errors
			} else {
				this._router.navigate(['resetPasswordConfirmation']);
			}
		});
	}

	private validateForm(): void {
		// do complex validation here. Null validation can be done automatically
	}
}
