import { StrawberryFertilizationRecommendedSummaryViewModel } from '../../../../fertilization-event/interfaces';
import { TranslateService } from '../../../../localization/service';
import { IGeneratedEquation } from './lettuceEquationGenerator';
import { FertilizationRecommendationFields, StrawberryEquationGenerator } from './strawberryEquationGenerator';

export class TomatoEquationGenerator extends StrawberryEquationGenerator {
	public constructor(s: StrawberryFertilizationRecommendedSummaryViewModel,
		translateService: TranslateService) {
		super(s, translateService);
	}

	protected _getSoilNRatioGreaterEquation(result: IGeneratedEquation): IGeneratedEquation {
		result.equationDescriptions[0] = `${this._ts.translate('Fertilizer N Recommendation')} =
			(${this._ts.translate('Crop N Uptake')} / ${this._ts.translate('N Fertilizer Factor')}) -
			${this._ts.translate('Total Mineralized N')}`;

		this.equationResult = (this._s.NUptakeDifference / this._s.NFertilizerFactor) - this._s.NMineralTotal
			- this._s.NAppliedFromWater;

		result.equations[0] = `${this.equationResult.toFixed(2)} =
			(${this._s.NUptakeDifference.toFixed(2)} / ${this._s.NFertilizerFactor.toFixed(2)}) -
			${this._s.NMineralTotal.toFixed(2)}`;

		return result;
	}

	protected _getSoilNRatioLessThanEquation(result: IGeneratedEquation): IGeneratedEquation {
		result.equationDescriptions[0] = `${this._ts.translate('Fertilizer N Recommendation')} =
			(${this._ts.translate('Crop N Uptake')} * ${this._ts.translate('N Fertilizer Factor')}) -
			${this._ts.translate('Total Mineralized N')}`;

		this.equationResult = (this._s.NUptakeDifference * this._s.NFertilizerFactor) - this._s.NMineralTotal
			- this._s.NAppliedFromWater;

		result.equations[0] = `${this.equationResult.toFixed(2)} = (${this._s.NUptakeDifference.toFixed(2)}
		* ${this._s.NFertilizerFactor.toFixed(2)}) - ${this._s.NMineralTotal.toFixed(2)}`;

		return result;
	}

	protected _getNearHarvestEquation(result: IGeneratedEquation): IGeneratedEquation {
		result.equationDescriptions[0] = `${this._ts.translate('Fertilizer N Recommendation')} = ${this._ts.translate('Crop N Uptake')}`;
		this.equationResult = this._s.NUptakeDifference - this._s.NAppliedFromWater;
		result.equations[0] = `${this.equationResult.toFixed(2)} = this._s.NUptakeDifference.toFixed(2)`;

		return result;
	}

	protected _getPrimaryEquation(result: IGeneratedEquation): IGeneratedEquation {
		result.equationDescriptions[0] = `${this._ts.translate('Fertilizer N Recommendation')} =
			${this._ts.translate('Crop N Uptake')} - (${this._ts.translate('Soil N')} -
			${this._ts.translate('Soil N Threshold')}) - ${this._ts.translate('Total Mineralized N')}`;

		this.equationResult = this._s.NUptakeDifference - (this._s.SoilN
			- this._s.SoilNThreshold) - this._s.NMineralTotal - this._s.NAppliedFromWater;

		result.equations[0] = `${this.equationResult.toFixed(2)} =
			${this._s.NUptakeDifference.toFixed(2)} - (${this._s.SoilN.toFixed(2)}
			- ${this._s.SoilNThreshold.toFixed(2)}) - ${this._s.NMineralTotal.toFixed(2)}`;

		return result;
	}

	public getEquation(): IGeneratedEquation {
		let result: IGeneratedEquation = {
			equationDescriptions: [],
			equations: []
		};

		let f = FertilizationRecommendationFields;

		// need this check or switches might fail
		if (!this.isStrawberryRecommendationValid(this._s)) {
			throw new Error('tomato recommendation summary properties missing');
		}

		if ((this._s.NUptake / this._s.StrawberryFertilizationInterval) >= this._s.NUptakeThreshold) {

			if (this._s.SoilNRatio >= 0) {
				result = this._getSoilNRatioGreaterEquation(result);
			} else {
				result = this._getSoilNRatioLessThanEquation(result);
			}

			this._s.SoilN = null;
			this._s.SoilNThreshold = null;
			this._s.FertilizationsPerMonth = null;
		} else if (this.isNearHarvest(this._s)) {
			result = this._getNearHarvestEquation(result);
			this._s.SoilN = null;
			this._s.SoilNThreshold = null;
			this._s.NFertilizerFactor = null;
			this._s.NMineralTotal = null;
		} else {
			result = this._getPrimaryEquation(result);
			this._s.NFertilizerFactor = null;
		}

		if (this.equationResult < 0) {
			result = this.getNegativeMessage(result);
		} else if (this.isRecommendationClamped()) {
			result = this.getClamped(result);
		}

		result = this.addNinWater(result);

		return result;
	}
}
