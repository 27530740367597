import { Component, Input } from '@angular/core';
import { NavInterface } from '../../../interfaces/nav.interface';
import { AdminTabComponent } from '../../../components/admin/tab/main';

@Component({
	moduleId: module.id,
	selector: 'admin-nav',
	templateUrl: 'main.html'
})

/**
 * @Scope Scope was validated on 5/7/2020
 */
export class AdminNavComponent implements NavInterface {
	tabs: AdminTabComponent[] = [];

	@Input()
	title: string;

	/**
	 * @param tab
	 * @public This method is publicly visible since its called by other components
	 */
	public selectTab(tab: AdminTabComponent): void {
		this.tabs.map((t) => {
			t.selected = false;
		});

		tab.selected = true;
	}

	/**
	 * @param tab
	 * @public This method is publicly visible since its called by other components
	 */
	public addTab(tab: AdminTabComponent): void {
		if (!this.tabs.length) {
			tab.selected = true;
		}

		this.tabs.push(tab);
	}
}
