import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaspService } from '../casp-dialog/casp.service';
import { NotificationService } from '../../services/notification.service';
import { eNotificationTypes } from '../../interfaces/constants';
import { ProfileUpdateService } from './profile.update.service';

@Component({
	moduleId: module.id,
	selector: 'casp-confirmation-dialog',
	templateUrl: 'casp-disconnect-confirmation-dialog.html'
})

export class CaspDisconnectConfirmationDialog implements OnInit {

	constructor(
		public dialogRef: MatDialogRef<CaspDisconnectConfirmationDialog>,
		@Inject(MAT_DIALOG_DATA) public data: {},
		private _caspService: CaspService,
		private _updateService: ProfileUpdateService,
		private _notificationService: NotificationService) {
	}

	ngOnInit(): void {
	}

	public disconnect(): void {
		//
		this._caspService.disable().then(response => {
			this._notificationService.generateNotifcation({
				type: eNotificationTypes.UPDATE,
				message: 'Your account was successfully disconnected from CASP'
			});

			this.dialogRef.close();
			this._updateService.updateCaspState();
		});
	}

	public close(): void {
		this.dialogRef.close();
	}
}
