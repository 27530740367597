import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { CropTypeService } from '../../models/crop-type/service';
import { ICropTypeListItem } from '../../models/crop-type/interfaces';
import { ObjectUtility } from '../../classes/objectUtility';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { TokenService } from '../../services/token.service';
import {  Subject } from 'rxjs';
import { CropTypeUpdateService } from './cropType.update.service';
import { CropTypeDeleteConfirmationDialog } from './delete-confirmation';
import { takeUntil } from 'rxjs/operators';
import { PersistentDatabase } from '../../services/persistent-database';

export enum cropTypeViewTypes {
	CROP_TYPE = 0,
	PREVIOUS_CROP = 1
};

@Component({
	moduleId: module.id,
	selector: 'crop-types',
	templateUrl: 'crop-types.html',
	styleUrls: [ 'crop-types.scss']
})

export class CropTypeListComponent implements OnInit, OnDestroy {
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public dataSource: MatTableDataSource<ICropTypeListItem>;
	public results: ICropTypeListItem[] = new Array();
	public originalResults: ICropTypeListItem[] = new Array();

	public displayedColumns: string[] = ['Name', 'MaxUptake', 'MaxNRec', 'CommodityTypeName', 'Action'];

	public query = '';

	public viewTypes = cropTypeViewTypes;
	public viewType: cropTypeViewTypes = cropTypeViewTypes.CROP_TYPE;
	private _subscriptions$: Subject<boolean>;
	private _pageIndex = 0;

	constructor(
		private _persistentDatabase: PersistentDatabase,
		private _dialog: MatDialog,
		private _activatedRoute: ActivatedRoute,
		private _cropTypeService: CropTypeService,
		private _updateService: CropTypeUpdateService,
		private _tokenService: TokenService,
		private _route: ActivatedRoute,
		private _router: Router) {

		if (this._tokenService.isAuthenticated() === false) {
			this._router.navigate(['login']);
		}
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();

		if (this.sort) {
			if (this.sort.sortChange) {
				this.sort.sortChange.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
					if (!this.paginator) {
						return;
					}

					while (this.paginator.hasPreviousPage()) {
						this.paginator.previousPage();
					}
				});
			}
		}

		this._route.queryParams.pipe(takeUntil(this._subscriptions$)).subscribe(params => {
			if (params) {
				if (parseInt(params['viewType'], 10) === cropTypeViewTypes.PREVIOUS_CROP) {
					this.viewType = cropTypeViewTypes.PREVIOUS_CROP;
				} else {
					this.viewType = cropTypeViewTypes.CROP_TYPE;
				}
			}

			// get crop types

			this._list();
		});

		this._updateService.cropUpdated.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			// if user is edited or deleted, refresh the view
			this._list();
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public create(): void {
		if (this.viewType === cropTypeViewTypes.CROP_TYPE) {
			this._persistentDatabase.cropTypePageIndex = this.dataSource.paginator.pageIndex;
			this._router.navigate(['CropTypeDetails']);
		} else {
			this._persistentDatabase.cropTypePageIndex = this.dataSource.paginator.pageIndex;
			this._router.navigate(['PreviousCropDetails']);
		}
	}

	public delete(row: ICropTypeListItem): void {

		let data: {
			id: number,
			name: string
		};

		data = {
			id: row.Id,
			name: row.Name
		};

		this._dialog.open(CropTypeDeleteConfirmationDialog, {
			disableClose: true,
			data: data,
			maxWidth: 'unset'
		});
	}

	public edit(cropTypeId: number): void {
		let navigationExtras: NavigationExtras = {
			queryParams: {
				'id': cropTypeId,
			}
		}

		if (this.viewType === cropTypeViewTypes.CROP_TYPE) {
			this._persistentDatabase.cropTypePageIndex = this.dataSource.paginator.pageIndex;
			this._router.navigate(['CropTypeDetails'], navigationExtras);
		} else {
			this._persistentDatabase.cropTypePageIndex = this.dataSource.paginator.pageIndex;
			this._router.navigate(['PreviousCropDetails'], navigationExtras);
		}
	}

	public clone(cropTypeId: number): void {

		let navigationExtras: NavigationExtras = {
			queryParams: {
				'id': cropTypeId,
				'isClone': true
			}
		}

		this._router.navigate(['CropTypeDetails'], navigationExtras);
	}

	private _filterByPrevious(v: number): void {
		if (!this.originalResults) {
			return;
		}

		this.viewType = v;

		switch (v) {
			case 0: // crop types
				this.results = this.originalResults.filter(x => x.IsPreviousCrop === false);
				break;
			case 1: // Previous crop types
				this.results = this.originalResults.filter(x => x.IsPreviousCrop === true);
				break;
			case 2: // show all
				this.results = ObjectUtility.copyArray(this.originalResults);
		}

		this._setDataSource(this.results);
	}

	public search(query: string): void {
		if (!this.dataSource) {
			return;
		}

		if (query === null) {
			query = '';
		}

		query = query.trim();
		query = query.toLowerCase();
		this._persistentDatabase.cropTypeQuery = query;

		this.dataSource.filter = query;
	}

	private _list(): Promise<void> {
		return this._cropTypeService.getCropTypesAdmin(true).then((response) => {
			this.originalResults = response;
			this._filterByPrevious(this.viewType);
		});
	}

	private _setDataSource(results: ICropTypeListItem[]): void {
		this.dataSource = new MatTableDataSource(results);
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;

		if (this._persistentDatabase.cropTypeQuery) {
			this.dataSource.filter = this._persistentDatabase.cropTypeQuery;
			this.query = this._persistentDatabase.cropTypeQuery;
		}

		this._setPageIndex();
	}

	private _setPageIndex(): void {
		if (!this.dataSource || !this.dataSource.paginator ||
			!this._persistentDatabase || !this.paginator) {

			return;
		}

		if (this._persistentDatabase.cropTypePageIndex > 0 && this._persistentDatabase.cropTypePageIndex
			!== this.dataSource.paginator.pageIndex) {

			this.paginator.pageIndex = this._persistentDatabase.cropTypePageIndex;
			this._persistentDatabase.cropTypePageIndex = 0; // clear cache

			this.paginator.page.next({
				pageIndex: this._persistentDatabase.cropTypePageIndex,
				pageSize: this.paginator.pageSize,
				length: this.paginator.length
			});
		}
	}
}
