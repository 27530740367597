import { Component } from '@angular/core';

@Component({
	moduleId: module.id,
	selector: 'svg-water',
	template: `
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g id="Exports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Icon-Water">
                    <g>
                        <path d="M17,11 C17,13.762 14.762,16 12,16 C9.238,16 7,13.762 7,
                            11 C7,8.239 12,1 12,1 C12,1 17,8.239 17,11 Z"
                            id="Shape" stroke="#000000" stroke-linejoin="round">
                        </path>
                        <path d="M12,13 C10.711,13 9.667,11.955 9.667,10.667"
                            id="Shape" stroke="#000000" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                        <path d="M18.988,17.483 C17.252,18.723 14.764,19.5 12,
                            19.5 C9.221,19.5 6.72,18.715 4.982,17.463" id="Shape"
                            stroke="#000000" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                        <path d="M22.94,19.061 C20.363,21.158 16.419,22.5 12,
                            22.5 C7.551,22.5 3.584,21.141 1.008,19.018" id="Shape"
                            stroke="#000000" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                        <rect id="Rectangle-path" x="0" y="0" width="24" height="24"></rect>
                    </g>
                </g>
            </g>
        </svg>
    `,
})

export class SVGWaterComponent {

}
