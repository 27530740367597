import { DateUtility } from '../../classes/dateUtility';
import { EventUtility } from '../../models/event/eventUtility';
import { BOKA } from '../../classes/array';
import * as jQuery from 'jquery';

import { PlantingsArray } from '../../interfaces/interfaces';
import { IPlantingAdvancedEdit, IPlanting, ILotPlantingWithEventsJSON } from '../planting-settings/interfaces';
import { IEventGroup } from '../../models/event/interfaces';
import { ILotEdit } from '../ranch-settings/modals/lot/interfaces';
import { EventGroup } from '../../models/event/event';
import { Planting } from '../planting-settings/planting';
import { ICommodityType } from '../../models/commodity-type/interfaces';
import { EventsType } from '../../interfaces/constants';

/**
 * A set of plantings, used to maintain data on the client side
 */
export class Plantings implements PlantingsArray {

	public active: IPlanting[];
	public favorites: IPlanting[];
	public all: IPlanting[];

	public static idExistsInEvent(event: IEventGroup, id: number): boolean {

		if (!event) {
			return false;
		}

		if (!id) {
			return false;
		}

		if (event.Fertilization && event.Fertilization.length > 0) {
			for (let fertilizationEvent of event.Fertilization) {
				if (fertilizationEvent.Id === id) {
					return true;
				}
			}
		} else if (event.Irrigation && event.Irrigation.Id === id) {
			return true;
		} else if (event.SoilSamples) {
			for (let soilSample of event.SoilSamples) {
				if (soilSample.Id === id) {
					return true;
				}
			}
		} else if (event.CutEvent && event.CutEvent.Id === id) {
			return true;
		}

		return false;
	}

	constructor() { }

	// Update service mostly takes care of favoriting, except it doesn't update the favorites array,
	// which we do here
	public favorite(plantingId: number): void {

		let planting: IPlanting;

		planting = this.getPlantingById(plantingId);

		if (!planting) {
			return;
		}

		if (!this.favorites || this.favorites.filter(x => x.Id === plantingId)[0]) {
			// item exists in favorites
			return;
		}

		this.favorites.push(planting);
		this.sortPlantingsByName();
	}

	public unfavorite(plantingId: number) {

		this.favorites = this._removePlanting(this.favorites, plantingId);
		// removal shouldn't require resorting
	}

	public addEventToPlantings(event: EventGroup, plantingId: number, eventId?: number): void {
		this.addEvent(this.all, event, plantingId, eventId);
		this.addEvent(this.favorites, event, plantingId, eventId);
		this.addEvent(this.active, event, plantingId, eventId);
	}

	public addEventGroups(eventGroups: EventGroup[], plantingId: number): void {
		if (!eventGroups || eventGroups.length === 0) {
			return;
		}

		for (let eventGroup of eventGroups) {
			this.addEventToPlantings(eventGroup, plantingId);
		}
	}

	public replacePlantingEvents(events: EventGroup[], plantingId: number) {
		this.replaceEvents(this.all, events, plantingId);
		this.replaceEvents(this.favorites, events, plantingId);
		this.replaceEvents(this.active, events, plantingId);
	}

	/**
	 * Given an eventGroup, "update" current eventGroup with the new eventGroup. Used
	 * when updating an eventGroup for a single date.
	 * @param event
	 * @param plantingId
	 */
	public updatePlantingEvents(event: IEventGroup, plantingId: number): void {
		if (!event || EventUtility.hasNoEvents(event)) {
			return;
		}

		this.updateEvent(this.all, event, plantingId);
		this.updateEvent(this.favorites, event, plantingId);
		this.updateEvent(this.active, event, plantingId);
	}

	/**
	 * Batch update multiple event groups in a planting
	 * @param eventGroups
	 * @param plantingId
	 */
	public updateEventGroups(eventGroups: IEventGroup[], plantingId: number): void {
		if (!eventGroups || eventGroups.length === 0) {
			return;
		}

		for (let eventGroup of eventGroups) {
			this.updatePlantingEvents(eventGroup, plantingId);
		}
	}

	public deleteEventInPlantings(eventDate: Date, plantingId: number,
		eventType: EventsType, eventId: number = null, activeEvents: string = null): void {

		this.deleteEvent(this.all, eventDate, plantingId, eventType, eventId, activeEvents);
		this.deleteEvent(this.favorites, eventDate, plantingId, eventType, eventId, activeEvents);
		this.deleteEvent(this.active, eventDate, plantingId, eventType, eventId, activeEvents);
	}

	public add(data: ILotPlantingWithEventsJSON) {
		let planting: IPlanting;

		if (!data) {
			return;
		}

		planting = this.convertData(data);

		if (this.active && this.isActive(planting)) {
			this.active.push(planting);
		}

		if (this.all) {
			this.all.push(planting);
		}

		// we don't push new items to favorites, because it hasn't been favorited

		this.sortPlantingsByName();
	}

	/**
	 * Update all planting arrays with new planting data.
	 * @param planting updated planting
	 * @returns void
	 */
	public edit(planting: IPlanting): void {
		this._updatePlantings(this.active, planting);
		this._updatePlantings(this.all, planting);
		this._updatePlantings(this.favorites, planting);

		if (this.all) {
			this.active = this.getCurrentPlantings(this.all); // filter out past/future plantings from active plantings
		}

		this.sortPlantingsByName();
	}

	public delete(id: number) {

		this.active = this._removePlanting(this.active, id);
		this.favorites = this._removePlanting(this.favorites, id);
		this.all = this._removePlanting(this.all, id);

		// deleting an item shouldn't require resorting
	}

	/**
	 * Update lot name in plantings array
	 * @param lot
	 */
	public updateLotNamesInPlantings(lot: ILotEdit) {

		this.updateLotNames(this.active, lot);
		this.updateLotNames(this.favorites, lot);
		this.updateLotNames(this.all, lot);
	}

	/**
	 * Given All plantings, get "active" plantings using date.
	 */
	public getCurrentPlantings(plantings: IPlanting[]) { // filter out "not active" plantings for alfalfa...

		let firstOfYear: Date;

		firstOfYear = new Date(new Date().getFullYear(), 0, 1);

		return plantings.filter(x => x.HarvestDate >= firstOfYear);
	}

	/**
	 * Removes a planting from a planting array
	 * Returns a new plantings array without the specified planting
	 * @param ar plantings
	 * @param plantingId
	 * @returns new array
	 */
	private _removePlanting(ar: IPlanting[], plantingId: number): IPlanting[] {
		if (ar && ar.length !== undefined) {
			return ar.filter(x => x.Id !== plantingId);
		} else {
			return null;
		}
	}

	/**
	 * This method updates a planting array with new planting data. Use this
	 * method when updating a planting.
	 * @param ar planting array cache
	 * @param planting updated planting object
	 * @returns void
	 */
	private _updatePlantings(ar: IPlanting[], planting: IPlanting): void {
		let plantingTarget: IPlanting;

		if (!ar || ar.length === 0) {
			return;
		}

		if (!planting) {
			return;
		}

		plantingTarget = ar.filter(x => x.Id === planting.Id)[0];

		if (!plantingTarget) {
			return;
		}

		this._updateIPlanting(planting, plantingTarget);

		if (!this.isActive(plantingTarget)) {
			ar = this._removePlanting(ar, plantingTarget.Id);
		}
	}

	/**
	 * This method updates an IPlanting object with an IPlantingAdvancedEdit
	 * planting object.
	 * @param source IPlantingAdvancedEdit object
	 * @param target IPlanting object
	 * @returns void
	 */
	private _updateIPlanting(source: IPlanting,
		target: IPlanting): void {


		if (!source || !target) {
			throw new Error('source or target missing');
		}

		if (!target.RanchLot || !source.RanchLot) {
			throw new Error('RanchLot object is empty');
		}

		if (target.CropType && source.CropType) {
			target.CropType.Id = source.CropType.Id;
			target.CropType.Name = source.CropType.Name;
		}

		target.Name = source.Name;
		target.HarvestDate = source.HarvestDate;
		target.WetDate = source.WetDate;
		target.RanchLot.Id = source.RanchLot.Id;
		target.RanchLot.Name = source.RanchLot.Name;
		target.CommodityTypeId = source.CommodityTypeId;
		target.CommodityTypeName = source.CommodityTypeName;
		target.HasFlowMeterFileName = source.HasFlowMeterFileName;
		target.HasSoilMoistureFileName = source.HasSoilMoistureFileName;
		target.CommodityTypeName = source.CommodityTypeName;

		if (source.Events) {
			target.Events = source.Events;
		}
	}

	public convertData(data: ILotPlantingWithEventsJSON): IPlanting {

		let result: IPlanting;
		let wetDate: Date;

		if (!data) {
			return;
		}

		wetDate = DateUtility.DotNetToDate(data.WetDate);

		result = { // ceate a clean planting data object
			Id: data.Id,
			Name: data.Name,
			CommodityTypeId: data.CommodityTypeId,
			CommodityTypeName: data.CommodityTypeName,
			CommodityTypeCalculator: data.CommodityTypeCalculator,
			CropType: data.CropType,
			Events: EventGroup.convertAll(data.Events, wetDate),
			HarvestDate: DateUtility.DotNetToDate(data.HarvestDate),
			WetDate: wetDate,
			RanchLot: data.RanchLot,
			HasFlowMeterFileName: data.HasFlowMeterFileName,
			HasSoilMoistureFileName: data.HasSoilMoistureFileName
		};

		return result;
	}

	private sortPlantingsByName() {

		BOKA.Array.sortByName(this.active, 'Name');
		BOKA.Array.sortByName(this.favorites, 'Name');
		BOKA.Array.sortByName(this.all, 'Name');
	}

	private findEventInPlantingByDate(planting: IPlanting, eventDate: Date): EventGroup {
		let result: EventGroup;

		if (!planting || !planting.Events) {
			return null;
		}

		result = planting.Events.filter(x => DateUtility.areEqualDates(x.EventDate, eventDate))[0];

		return result;
	}

	/**
	 * Each eventGroup contains arrays of fertilization, soil sample, and tissue
	 * sample events.
	 *
	 * @param planting
	 * @param eventId
	 */
	private findEventById(planting: IPlanting, eventId: number): EventGroup {
		let result: EventGroup;

		if (!planting || !planting.Events || planting.Events.length === 0) {
			return null;
		}

		result = planting.Events.filter(x => (x.Irrigation && x.Irrigation.Id === eventId) ||
			(x.Fertilization && x.Fertilization.filter(f => f.Id === eventId).length > 0) ||
			(x.SoilSamples && x.SoilSamples.filter(s => s.Id === eventId).length > 0) ||
			(x.CutEvent && x.CutEvent.Id === eventId) ||
			(x.TissueSamples && x.TissueSamples.filter(t => t.Id === eventId).length > 0))[0];

		return result;
	}

	private removeEventFromPlanting(planting: IPlanting, eventDate: Date): void {
		if (!planting || !planting.Events) {
			return;
		}

		planting.Events = planting.Events.filter(x => DateUtility.areEqualDates(x.EventDate, eventDate) === false);
	}

	/**
	 *
	 * @param ar
	 * @param event
	 * @param plantingId
	 * @param eventType
	 * @param eventId - optional, used for deleting soil sample events, where there can be multiple
	 * events per date
	 */
	private deleteEvent(ar: IPlanting[], eventDate: Date, plantingId: number,
		eventType: EventsType, eventId?: number, activeEvents?: string): void {

		let plantingTarget: IPlanting;
		let eventTarget: IEventGroup;

		if (!ar || ar.length === 0) {
			return;
		}

		plantingTarget = ar.filter(x => x.Id === plantingId)[0];

		if (!plantingTarget) {
			return;
		}

		plantingTarget.ActiveEventDates = Planting.convertActiveEventString(activeEvents);

		plantingTarget.Counts = {
			upcomingEvents: Planting.getUpcomingEventCount(plantingTarget.ActiveEventDates)
		};

		if (!plantingTarget.Events) {
			return;
		}

		eventTarget = this.findEventInPlantingByDate(plantingTarget, eventDate);

		if (!eventTarget) {
			return;
		}

		switch (eventType) {
			case EventsType.FERTILIZER:
				if (!eventTarget.Fertilization || eventTarget.Fertilization.length === 0) {
					break;
				}

				if (!eventId) {
					break;
				}

				for (let index in eventTarget.Fertilization) {
					if (eventTarget.Fertilization[index].Id === eventId) {
						eventTarget.Fertilization.splice(parseInt(index, 10), 1);
					}
				}
				break;
			case EventsType.WATER:
				eventTarget.Irrigation = null;
				break;
			case EventsType.SOILSAMPLES:

				if (!eventTarget.SoilSamples) {
					break;
				}

				if (!eventId) {
					break;
				}

				for (let index in eventTarget.SoilSamples) {
					if (eventTarget.SoilSamples[index].Id === eventId) {
						eventTarget.SoilSamples.splice(parseInt(index, 10), 1);
					}
				}

				break;
			case EventsType.TISSUE_SAMPLE:

				if (!eventTarget.TissueSamples) {
					break;
				}

				if (!eventId) {
					break;
				}

				for (let index in eventTarget.TissueSamples) {
					if (eventTarget.TissueSamples[index].Id === eventId) {
						eventTarget.TissueSamples.splice(parseInt(index, 10), 1);
					}
				}
				break;
			case EventsType.CUTTING:
				eventTarget.CutEvent = null;
				break;
		}

		if (!eventTarget.Irrigation) {
			eventTarget.RainfallWeather = null;
		}

		this._updateSucceedingCutEvent(plantingTarget, eventTarget.EventDate);

		// If all "sub events" are empty, delete the event from the array

		if (!eventTarget.Fertilization && !eventTarget.Irrigation && !eventTarget.RainfallWeather &&
			(!eventTarget.SoilSamples || eventTarget.SoilSamples.length === 0) && !eventTarget.CutEvent) {

			this.removeEventFromPlanting(plantingTarget, eventDate);
		}
	}

	/**
	 * Update succeeding cut event's cut interval, if any
	 *
	 * if an event is deleted, we need to look for older cut events
	 *
	 * @param planting
	 * @param event
	 */
	private _updateSucceedingCutEvent(planting: IPlanting, eventDate: Date): void {
		let futureEvents, previousEvents: EventGroup[];
		let futureEvent: EventGroup;
		let previousEvent: EventGroup;

		if (!planting || !planting.Events) {
			return;
		}

		futureEvents = planting.Events.filter(x => x.CutEvent &&
			DateUtility.getDifference(eventDate, x.EventDate) < 0);

		if (!futureEvents || futureEvents.length === 0) {
			return;
		}

		if (futureEvents.length === 1) {
			futureEvent = futureEvents[0];
		} else {
			futureEvent = futureEvents.sort((a, b) => {
				let x, y:  number;

				x = a.EventDate.getTime();
				y = b.EventDate.getTime();

				if (x > y) {
					return 1;
				} else if (x < y) {
					return -1;
				} else {
					return 0
				}
			})[0];
		}

		previousEvents = planting.Events.filter(x => x.CutEvent && DateUtility.getDifference(futureEvent.EventDate, x.EventDate) > 0);

		if (!previousEvents || previousEvents.length === 0) {
			// does this need a wet date?
			return;
		}

		if (previousEvents.length > 1) {
			previousEvent = previousEvents.sort((a, b) => {
				let x, y:  number;

				x = a.EventDate.getTime();
				y = b.EventDate.getTime();

				if (x > y) {
					return -1;
				} else if (x < y) {
					return 1;
				} else {
					return 0
				}
			})[0];
		} else {
			previousEvent = previousEvents[0];
		}

		futureEvent.CutEvent.CutInterval = DateUtility.getDifferenceInDays(futureEvent.EventDate, previousEvent.EventDate);
	}

	private addEvent(ar: IPlanting[], event: EventGroup, plantingId: number, eventId?: number): void {
		let plantingTarget: IPlanting;
		let eventTarget: EventGroup;
		let eventOriginal: IEventGroup;

		if (!ar || ar === null || ar === undefined || ar.length === 0) {
			/* There are no plantings in this particular array,
               therefore an event cannot be added. */
			return;
		}

		plantingTarget = ar.filter(x => x.Id === plantingId)[0];

		if (!plantingTarget) {
			return;
		}

		if (!event) {
			return;
		}

		/** Update upcoming event counts in plantings view */
		plantingTarget.ActiveEventDates = Planting.convertActiveEventString(event.ActiveEvents);

		plantingTarget.Counts = {
			upcomingEvents: Planting.getUpcomingEventCount(plantingTarget.ActiveEventDates)
		};

		if (eventId) {
			eventOriginal = this.findEventById(plantingTarget, eventId);
		} else {
			eventOriginal = null;
		}

		/** If event is moved to a different date, we need to remove the event
		 * from the original eventGroup
		 */
		if (event.eventType && eventId && eventOriginal &&
			DateUtility.areEqualDates(event.EventDate, eventOriginal.EventDate) === false) {

			/* if date changed, remove the old event on former date */
			switch (event.eventType) {
				case EventsType.SOILSAMPLES:
					if (!eventId) {
						eventId = event.SoilSamples[0].Id;
					}

					eventOriginal.SoilSamples = eventOriginal.SoilSamples.filter(x => x.Id !== eventId);
					break;
				case EventsType.WATER:
					eventOriginal.Irrigation = null;
					break;
				case EventsType.FERTILIZER:
					if (!eventId) {
						eventId = event.Fertilization[0].Id;
					}

					eventOriginal.Fertilization = eventOriginal.Fertilization.filter(x => x.Id !== eventId);
					break;
				case EventsType.TISSUE_SAMPLE:
					if (!eventId) {
						eventId = event.SoilSamples[0].Id;
					}

					eventOriginal.TissueSamples = eventOriginal.TissueSamples.filter(x => x.Id !== eventId);
					break;
				case EventsType.CUTTING:
					eventOriginal.CutEvent = null;
					break;
			}

			/**
			 * If the resulting eventGroup is empty, remove the eventGroup from the events array
			 */
			if (EventUtility.hasNoEvents(eventOriginal)) {
				this.removeEventFromPlanting(plantingTarget, eventOriginal.EventDate);
			}
		}

		/**
		 * Find target eventGroup by date. In plantings view, there should be a
		 * single eventGroup for a single date
		 */
		eventTarget = this.findEventInPlantingByDate(plantingTarget, event.EventDate);

		if (eventTarget) { // update event
			eventTarget = jQuery.extend(true, eventTarget, event); // do a deep copy of data without messing with pointers
		} else { // add event
			plantingTarget.Events.push(event);
		}

		this._updateSucceedingCutEvent(plantingTarget, event.EventDate);
	}

	// given a list of events, replace a plantings current event "stream" with the new list of events
	// used primarily when recalculating plantings, which can impact multiple events
	private replaceEvents(plantings: IPlanting[], events: EventGroup[], plantingId: number): void {

		let plantingTarget: IPlanting;

		if (!plantings || plantings.length === 0) {
			// valid result - sometimes there isn't going to be a match
			return;
		}

		plantingTarget = plantings.filter(x => x.Id === plantingId)[0];

		if (!plantingTarget) {
			// no match found
			return;
		}

		if (!events) {
			return;
		}

		plantingTarget.Events = events;
	}

	private updateEvent(ar: IPlanting[], event: IEventGroup, plantingId: number): void {

		let plantingTarget: IPlanting;
		let eventTarget: IEventGroup;

		if (!ar || ar.length === 0) {
			/* There are no plantings in this particular array,
               therefore there are no events to be updated. */
			return;
		}

		if (!event) {
			return;
		}

		plantingTarget = ar.filter(x => x.Id === plantingId)[0];

		if (!plantingTarget) {
			return;
		}

		eventTarget = this.findEventInPlantingByDate(plantingTarget, event.EventDate);

		if (!eventTarget) {
			// valid, depending on which planting array we're looking at - some may not be loaded
			return;
		}

		eventTarget = jQuery.extend(true, eventTarget, event); // will this be enough? may need to copy over data
	}

	/**
	 * Given a lot, update lot name in planting array
	 * @param ar
	 * @param plantingId
	 * @param lot
	 */
	private updateLotNames(plantings: IPlanting[], lot: ILotEdit) {

		if (!plantings || plantings.length === 0) {
			// valid error - in some cases, favorites or all plantings won't exist
			return;
		}

		if (!lot) {
			return;
		}

		for (let planting of plantings) {

			if (!planting.RanchLot) {
				continue;
			}

			if (planting.RanchLot.Id === lot.Id) {
				planting.RanchLot.Name = lot.Name;
			}
		}
	}

	private isActive(planting: IPlanting): boolean {
		let now: number;
		let endDate: number;
		let firstOfYear: Date;

		if (!planting || !planting.WetDate || !planting.HarvestDate) {
			return;
		}

		firstOfYear = new Date(new Date().getFullYear(), 0, 1);
		now = firstOfYear.setHours(0, 0, 0, 0);
		endDate = new Date(planting.HarvestDate).setHours(0, 0, 0, 0);

		return now <= endDate;
	}

	public getPlantingById(plantingId: number): IPlanting {

		let result: IPlanting;

		if (!plantingId) {
			return null;
		}

		if (this.all && this.all.length > 0) {
			result = this.all.filter(x => x.Id === plantingId)[0];
		}

		// should only enter below if plantings.all has not been loaded
		// code is AJAX delay dependent

		if (!result && this.active) {
			result = this.active.filter(x => x.Id === plantingId)[0];
		}

		if (!result && this.favorites) {
			result = this.favorites.filter(x => x.Id === plantingId)[0];
		}

		if (!result) {
			return null;
		}

		return result;
	}
}
