import { DateUtility } from '../../classes/dateUtility';
import { ISoilLayer } from '../../models/soil-type/interfaces';
import { ValidateService } from '../../services/validate.service';
import { ICropSettingsForm, IPlantingSettingsForm } from './planting-settings-form.interfaces';
import { PlantingParamsInternal } from './plantingParam.interfaces';

export class PlantingSettingsFormConverter {

	/**
	 * Used by planting settings to convert the planting settings form to
	 * data
	 * @param planting planting form
	 * @param soilType soilType
	 */
	public static convert(planting: IPlantingSettingsForm, soilLayers: ISoilLayer[]): PlantingParamsInternal {

		let result: PlantingParamsInternal;
		let cropType: ICropSettingsForm;

		result = {
			Name: planting.Name,
			WetDate: DateUtility.ConvertToUTC(planting.WetDate),
			HarvestDate: DateUtility.ConvertToUTC(planting.HarvestDate),
			LotId: planting.LotId,
			Coordinates: planting.Coordinates,
			CropTypeModel: null,
			Acres: planting.Acres,
			SoilLayers: soilLayers,
			IrrigationSettings: {
				IrrigationMethods: [],
				ControlPoints: [],
				DeficitType: planting.DeficitType,
				ApplicationRates: {
					Drip: planting.DripApplicationRate,
					Sprinkler: planting.SprinklerApplicationRate,
					MicroSprinkler: planting.MicroSprinklerApplicationRate
				},
				DistributionUniformities: {
					Drip: planting.DripDistUniformPct,
					Sprinkler: planting.SprinklerDistUniformityPct,
					Flood: planting.FloodDistUniformityPct,
					MicroSprinkler: planting.MicroSprinklerDistUniformityPct,
					GerminationSprinkler: planting.GerminationSprinklerPct,
				},
				LeechingFactor: planting.LeechingFactorPct,
			},
			CalculatorSettings: planting.CalculatorSettings,
			AdvancedSettings: {
				ObstructionDepth: ValidateService.truncateDecimals(planting.ObstructionDepth, 1),
				PreviousCropDays: planting.CropSettings.PrevCropDays,
				PreviousCropTypeId: planting.CropSettings.PreviousCropTypeId
			},
			MacroTunnelSettings: {
				IsEnabled: planting.MacroTunnelSettings.isEnabled,
				IsFullSeason: planting.MacroTunnelSettings.isFullSeason,

				StartDate: planting.MacroTunnelSettings.startDate ?
					DateUtility.ConvertToUTC(planting.MacroTunnelSettings.startDate) : null,

				EndDate: planting.MacroTunnelSettings.endDate ?
					DateUtility.ConvertToUTC(planting.MacroTunnelSettings.endDate) : null
			},
			StressSettings: {
				IsEnabled: planting.CropSettings.IsStressEnabled,
				StartDate: DateUtility.ConvertToUTC(planting.CropSettings.StressStartDate),
				EndDate:  DateUtility.ConvertToUTC(planting.CropSettings.StressEndDate)
			},
			PerennialCropSettings: {
				CropAge: planting.AgeOfCrop,
				YieldTarget: planting.CropSettings.NCalculator.YieldTarget,
				YieldActual: planting.YieldActual,
				IsHighVigor: planting.IsHighVigor,
				IsCoverCropEnabled: planting.CropSettings.IsCoverCropEnabled,
				LeafSenesceDate: DateUtility.ConvertToUTC(planting.CropSettings.LeafSenesceDate)
			}
		}

		if (planting.IrrigationMethods && planting.IrrigationMethods.length > 0) {
			planting.IrrigationMethods.forEach((e) => {
				result.IrrigationSettings.IrrigationMethods.push(e.IrrigationMethodId);
			});
		}

		if (planting.ControlPoints && planting.ControlPoints.length > 0) {
			planting.ControlPoints.forEach((e) => {
				result.IrrigationSettings.ControlPoints.push(e);
			});
		}

		cropType = planting.CropSettings;

		result.CropTypeModel = {
			DefaultCropTypeId: cropType.DefaultCropTypeId,
			IrrigationSettings: {
				Canopy: {
					A: cropType.CanopyA,
					B: cropType.CanopyB,
					C: cropType.CanopyC,
					D: cropType.CanopyD,
					E: cropType.CanopyE,
					F: cropType.CanopyF,
					GMax: cropType.CanopyGMax,
					Intercept: cropType.CanopyIntercept,
					MaxFraction: cropType.CanopyMaxFraction,
					Min: cropType.CanopyMin,
					BasalKC: cropType.BasalKC,
					EarlyFruit: cropType.EarlyFruit,
					PerennialCanopies: cropType.PerennialCanopies
				},
				Sensitivity: cropType.CropSensitivity,
				StressLevel: {
					Start: cropType.WaterStressStart,
					End: cropType.WaterStressEnd
				},
				KcCover: {
					Quad: cropType.Kccov_quad,
					Slope: cropType.Kccov_slope,
					Intercept: cropType.Kccov_int,
					DensityFactor: cropType.CovDensityFactor
				},
				WetAreas: {
					Drip: cropType.WetAreaDrip,
					MicroSprinkler: cropType.WetAreaMicroSprinkler
				},
				TargetTensions: [
					cropType.TargetTension1, cropType.TargetTension2,
					cropType.TargetTension3, cropType.TargetTension4
				],
				SeasonalKCs: cropType.SeasonalKCs
			},
			FertilizationSettings: {
				NUptake: {
					FertA: cropType.FertA,
					FertB: cropType.FertB,
					FertYo: cropType.FertYo,
					MaxUptake: cropType.MaxUptake,
					NUptakeThreshold: cropType.NUptakeThreshold,
					UptakeFactor: cropType.UptakeFactor,
					NFertilizerAdjustment: cropType.NFertilizerAdjustment,
					UseEfficiency: cropType.FertilizerUseEfficiency,
					SummerLeafN: {
						Inadequate: cropType.InadequateSummerLeafN,
						Excessive: cropType.ExcessiveSummerLeafN
					},
					NCredits: {
						Manure: cropType.NCalculator.ManureN,
						Compost: cropType.NCalculator.CompostN,
						CoverCrop: cropType.NCalculator.CoverCropN
					},
					TreeCoefficients: {
						Intercept: cropType.TreeInterceptCoefficient,
						LeafN: cropType.TreeLeafNCoefficient,
						Ilr4: cropType.TreeIlr4Coefficient,
						Ilr8: cropType.TreeIlr8Coefficient,
						IlrProduct: cropType.TreeIlrProductCoefficient
					},
					TreeNutrientThresholds: cropType.NCalculator.TreeNutrientThresholds,
					VegetativeNDemands: cropType.VegetativeNDemands
				},
				Mineralization: {
					Rate: cropType.CropMineralizationRate,
					PrevCropK: cropType.PrevCropK
				},
				SoilN: {
					Base: cropType.SoilNThreshold,
					Mid: cropType.SoilNThresholdMid,
					Late: cropType.SoilNThresholdLate,
					Fraction: cropType.SoilNThresholdFraction,
					FractionMid: cropType.SoilNThresholdFractionMid
				},
				HarvestIntervalFraction: cropType.HarvestIntervalFraction,
				MaxNRec: cropType.MaxNRec,
				SoilNRatio: {
					A: cropType.SoilNRatioA,
					B: cropType.SoilNRatioB,
					C: cropType.SoilNRatioC
				},
				NFactor: {
					A: cropType.NFertilizerFactorA,
					B: cropType.NFertilizerFactorB,
					C: cropType.NFertilizerFactorC,
					D: cropType.NFertilizerFactorD
				},
				StrawberryFertilizationInterval: cropType.StrawberryFertilizationInterval
			},
			RootDepth: {
				GermDays: cropType.GermDays,
				MaxDays: cropType.MaxDays,
				Max: cropType.MaxRootDepth,
				Min: cropType.MinRootDepth,
				Threshold: cropType.RootDepthThreshold,
				PerennialRootDepths: cropType.PerennialRootDepths
			}
		}

		return result;
	}
}
