import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {

	public static set(name: string, value: string, expireDays: number, path = ''): void {
		let d: Date = new Date();
		d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
		let expires: string = 'expires=' + d.toUTCString();
		document.cookie = name + '=' + value + '; ' + expires + (path.length > 0 ? '; path=' + path : '');
	}

	public static get(name: string): string {
		let ca: Array<string> = document.cookie.split(';');
		let caLen: number = ca.length;
		let cookieName = name + '=';

		for (let i = 0; i < caLen; i += 1) {
			let c: string;

			c = ca[i];

			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}

			if (c.indexOf(cookieName) === 0) {
				return c.substring(cookieName.length, c.length);
			}
		}
		return null;
	}

	public static delete(name: string): void {
		this.set(name, '', -1);
	}

	public setCurrentRanchCookie(id: string): void {
		CookieService.set('CurrentRanch', id, 1);
	}

	public deleteCurrentRanchCookie(): void {
		CookieService.delete('CurrentRanch');
	}

}
