import { Subject, Observable } from 'rxjs';

export interface ILocalizationDictionary {
	keyword?: string;
	id?: number;
	languageCode: number;
	word: string
};

export interface ILocalizatinDictionaryJSON {
	Id: number,
	LanguageCode: number,
	Keyword: string,
	Word: string
}

export interface ITranslation {
	keyword: string,
	editing: boolean,
	translations: {
		[languageCode: number]: ILocalizationDictionary
	}
}

export enum eLanguageCodes {
	ENGLISH = 1,
	SPANISH,
}

export interface LanguageCode {
	Code: string,
	Id: number,
	Language: string
}

export interface ITranslationService {
	currentLanguageId: number;
	currentLanguage$: Observable<number>;

	setCurrentLanguage(language: number): void;
	loadLanguages(): Promise<LanguageCode[]>;
	loadTranslations(override: boolean): Promise<ITranslation[]>;
	translate(keyword: string): string;
	addTranslation(keyword: string, translations: { languageCode: number, word: string }[]): Promise<string>;
	saveTranslation(translations: ILocalizationDictionary[]): Promise<void>;
	getLanguages(): LanguageCode[];
	deleteTranslation(ids: number[]): Promise<void>;
}
