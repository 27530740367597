import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { HttpService } from '../../services/http.service';
import { ICurrentUser, ADMIN_USER_ROLE, IUserService } from './interfaces';
import { IChangePassword } from '../../components/profile/interfaces';
import { CMError, SuccessResponse } from '../../interfaces/interfaces';

@Injectable()
export class UserService implements IUserService {

	public urls = {
		userDetails: {
			getCurrentUser: '/v2/user-detail.json',
			updateUser: '/v2/user-detail.json',
			updatePassword: '/v2/user-detail/change-password.json',
			getBETACount: '/v2/ranches/commodity-types/beta-count.json'
		},
		account: {
			email: '/v2/users/email.json',
		}
	};

	private isAdmin: boolean;

	constructor(
		private httpService: HttpService) { }

	public userIsAdmin(): Promise<boolean> {
		if (this.isAdmin !== null && this.isAdmin !== undefined) {
			return of(this.isAdmin).toPromise();
		}

		return this.getCurrentUser().then((user: ICurrentUser) => {
			this.isAdmin = user && user.Roles && user.Roles[0] && user.Roles[0].Id === ADMIN_USER_ROLE;
			return this.isAdmin;
		});
	}

	public getBETACommodityTypeCount(): Promise<number> {
		return this.httpService.get({
			url: this.urls.userDetails.getBETACount,
			callback: (data: number): number => {
				return data;
			},
			isWebAPI: true
		});
	}

	public getCurrentUser(override = true): Promise<ICurrentUser> {

		return this.httpService.get({
			url: this.urls.userDetails.getCurrentUser,
			useLocalStorage: override ? false : true,
			callback: (data: ICurrentUser): ICurrentUser => {
				return data;
			},
			isWebAPI: true
		});
	}

	public updateUserDetails(userDetails: ICurrentUser): Promise<CMError> {
		return this.httpService.put({
			url: this.urls.userDetails.updateUser,

			callback: (data: CMError): CMError => {
				return data;
			},

			body: userDetails,
			isWebAPI: true
		});
	}

	public changePassword(password: IChangePassword): Promise<CMError | SuccessResponse> {
		return this.httpService.post({
			url: this.urls.userDetails.updatePassword,
			isWebAPI: true,
			shouldBypassServerErrorAlert: true,

			callback: (data: CMError | SuccessResponse): CMError | SuccessResponse => {
				return data;
			},

			body: password
		});
	}

	public emailRanchOwner(email: string, subject: string, body: string): Promise<Object> {
		let params: {
			Email: string,
			Subject: string,
			Body: string
		}

		params = {
			Email: email,
			Subject: subject,
			Body: body
		}

		return this.httpService.post({
			url: this.urls.account.email,
			isWebAPI: true,
			callback: (data: CMError): Object => {
				return data;
			},
			body: params
		});
	}
}

