import { ValidateService } from '../services/validate.service';

export class KeyboardUtility {
	public static preventNonNumbers(e: KeyboardEvent, allowDecimals = false): void {
		if (!e) {
			return;
		}

		if (allowDecimals && e.key === '.') {
			return;
		}

		if ((!ValidateService.isValidNumber(parseInt(e.key, 10)) && e.key !== 'Enter')
			|| e.key === '-' || e.key === 'e') {

			e.preventDefault();
		}
	}
}
