<div class="bk-dialog"
     [ngClass]="{'bk-dialog__small' : view === weatherStationViewsEnum.DISCARDCHANGES || view === weatherStationViewsEnum.DELETECONFIRMATION }">

    <button type="button"
        class="bk-button bk-dialog__close"
        (click)="close()">
        <span>&times;</span>
    </button>

    <h4 mat-dialog-title> {{ title }} </h4>

    <div *ngIf="weatherStation && view === weatherStationViewsEnum.FORM"
        mat-dialog-content 
        class="bk-dialog__split">

        <div class="bk-dialog__left bk-dialog__left--narrow">
            <form #weatherStationForm="ngForm">

                <label class="bk-dialog__form--label">
                    {{ 'Name' | translate }}
                </label>

                <input type="text"
                    class="bk-dialog__form--input"
                    [(ngModel)]="weatherStation.Name"
                    required
                    name="name"
                    #name="ngModel"
                    [ngClass]="{'bk-input-invalid' : (showErrors || name.dirty) && name.errors }"/>

                <div *ngIf="(showErrors || name.dirty) && name.errors"
                    class="bk-dialog__form--invalid-text" >
                    {{ 'Name' | translate }} {{ 'is required' | translate}}
                </div>

                <label class="bk-dialog__form--label">
                    {{ 'External ID' | translate }}
                </label>
                <p>
                    {{ 'External ID is the numerical code for the station. For example, 002 for Frezno.' | translate }}
                </p>

                <input type="number"
                    class="bk-dialog__form--input"
                    [(ngModel)]="weatherStation.External_Id"
                    required
                    name="externalId"
                    #externalId="ngModel"
                    [ngClass]="{'bk-input-invalid' : showErrors && !isCIMISCodeValid }"
                    (ngModelChange)="validateCIMISCode(weatherStation.External_Id)"/>

                <div *ngIf="showErrors && !isCIMISCodeValid"
                    class="bk-dialog__form--invalid-text" >
                    {{ 'External ID' | translate }} {{ 'must be a number with no more than 3 digits' | translate}}
                </div>

                <label class="bk-dialog__form--label">
                    {{ 'API ID' | translate }} (Optional)
                </label>
                <p>
                    {{ 'API ID is used for Hawaii weather stations that do not conform to CIMIS numerical IDs.' | translate}}
                </p>

                <input type="text"
                    class="bk-dialog__form--input"
                    [(ngModel)]="weatherStation.API_Id"
                    required
                    name="API_Id"
                    #externalId="ngModel"/>

                <label class="bk-dialog__form--label">
                    {{ 'County' | translate }}
                </label>

                <input type="text"
                    class="bk-dialog__form--input"
                    [(ngModel)]="weatherStation.County"
                    required
                    name="county"
                    #county="ngModel"
                    [ngClass]="{'bk-input-invalid' : (showErrors || county.dirty) && county.errors }"/>

                <div *ngIf="(showErrors || county.dirty) && county.errors"
                    class="bk-dialog__form--invalid-text" >
                    {{ 'County' | translate }} {{ 'is required' | translate}}
                </div>

                <label class="bk-dialog__form--label">
                    {{ 'Nearest City' | translate }}
                </label>

                <input type="text"
                    class="bk-dialog__form--input"
                    [(ngModel)]="weatherStation.NearestCity"
                    required
                    name="nearestCity"
                    #nearestCity="ngModel"
                    [ngClass]="{'bk-input-invalid' : (showErrors || nearestCity.dirty) && nearestCity.errors }"/>

                <div *ngIf="(showErrors || nearestCity.dirty) && nearestCity.errors"
                    class="bk-dialog__form--invalid-text" >
                    {{ 'Nearest City' | translate }} {{ 'is required' | translate}}
                </div>

                <label class="bk-dialog__form--label">
                    {{ 'Region' | translate }}
                </label>

                <select class="bk-dialog__form--input"
                    [(ngModel)]="weatherStation.RegionId"
                    required
                    name="regionId" 
                    #regionId="ngModel"
                    [ngClass]="{'bk-input-invalid' : (showErrors || regionId.dirty) && regionId.errors }">

                    <option *ngIf="!weatherStation.Id"
                        value="null"
                        disabled
                        selected>
                            {{'Select a Region' | translate }}
                        </option>

                    <option *ngFor="let region of regions"
                        [ngValue]="region.Id">
                        {{region.Name}}
                    </option>
                </select>

                <div *ngIf="(showErrors || regionId.dirty) && regionId.errors"
                    class="bk-dialog__form--invalid-text">
                    {{ 'Please select a' | translate }} {{ 'Region' | translate}}
                </div>

                <label class="bk-dialog__form--label">
                    {{ 'Coordinates' | translate }}
                </label>

                <input type="text"
                    class="bk-dialog__form--input"
                    [(ngModel)]="weatherStation.Coordinates"
                    (ngModelChange)="onCoordinatesTextChange(weatherStation.Coordinates)" 
                    required
                    name="coordinates"
                    #coordinates="ngModel"
                    [ngClass]="{'bk-input-invalid' : (showErrors || coordinates.dirty) && (coordinates.errors || !areCoordinatesValid)}"/>

                <div *ngIf="(showErrors || coordinates.dirty) && (coordinates.errors || !areCoordinatesValid)"
                    class="bk-dialog__form--invalid-text">
                    {{ 'Coordinates' | translate }} {{ 'must have valid latitude and longitude' | translate}}
                </div>

                <label class="bk-dialog__form--label">
                    {{ 'UC IPM Name' | translate }}
                </label>
                <p>
                    UC Davis IPM (Integrated Pest Management) Name. (Obsolete)
                </p>
                <input type="text" 
                    class="bk-dialog__form--input"
                    [(ngModel)]="weatherStation.UcIpmName"
                    [ngModelOptions]="{standalone: true}"/>

                <label class="bk-dialog__form--label">
                    {{ 'Weather API' | translate }}
                </label>

                <select class="bk-dialog__form--input"
                    [(ngModel)]="weatherStation.WeatherAPI_Id"
                    required
                    name="WeatherAPI_Id" 
                    #WeatherAPI_Id="ngModel"
                    [ngClass]="{'bk-input-invalid' : (showErrors || WeatherAPI_Id.dirty) && WeatherAPI_Id.errors }">

                    <option *ngIf="!weatherStation.Id"
                        value="null"
                        disabled
                        selected>
                            {{'Select API' | translate }}
                        </option>

                    <option *ngFor="let weatherAPI of weatherAPIs"
                        [ngValue]="weatherAPI.Id">
                        {{weatherAPI.Name}}
                    </option>
                </select>
            </form>
        </div>

        <div class="bk-dialog__right">
            <div class="bk-dialog__map--instructions">
                {{ 'Weather Station Popup Map Instructions Text' | translate }}
            </div>

            <div class="bk-dialog__map--legend">
                <img class="bk-dialog__map--legend-station-icon"
                    src="../assets/images/icons/icon-weather-station-marker-24x24.svg"> {{ 'Weather Station Location' | translate }}
            </div>
            
            <div class="bk-dialog__map--container">
                <agm-map [mapTypeId]="'hybrid'"
                    [latitude]="mapParams.lat"
                    [longitude]="mapParams.lng"
                    [zoom]="mapParams.zoom"
                    (mapClick)="onMapClick($event)"
                    [fullscreenControl]="true">

                    <agm-marker *ngFor="let marker of markers"
                        [iconUrl]="'../assets/images/icons/icon-weather-station-marker-56x56.svg'"  
                        [latitude]="marker.lat"
                        [longitude]="marker.lng">
                    </agm-marker>

                    <map-control></map-control>
                </agm-map>
            </div>
        </div>
    </div>

    <div *ngIf="view === weatherStationViewsEnum.DISCARDCHANGES"
        mat-dialog-content>

        <div class="bk-dialog__paragraph">
            {{ 'The changes you made were not saved' | translate}}. {{'Discard changes' | translate}}?
        </div>
    </div>

    <div *ngIf="view === weatherStationViewsEnum.DELETECONFIRMATION"
        mat-dialog-content>

        <div class="bk-dialog__paragraph">
            {{ 'Are you sure you want to delete the Weather Station' | translate}} {{weatherStation.Name | translate}}?
        </div>
    </div>

    <div *ngIf="view === weatherStationViewsEnum.FORM"
        mat-dialog-actions 
        align="end">

        <button *ngIf="weatherStation.Id && canBeDeleted"
            mat-button
            class="bk-button bk-button--medium bk-button--text-only
            bk-dialog__button--secondary bk-dialog__button--left" 
            (click)="switchView(weatherStationViewsEnum.DELETECONFIRMATION)">
            {{ 'Delete' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only
            bk-dialog__button--secondary"
            (click)="close()">
            {{ 'Cancel' | translate }}
        </button>

        <button *ngIf="!weatherStation.Id"
            mat-button 
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="create()">
            {{ 'Add' | translate }}
        </button>

        <button *ngIf="weatherStation.Id"
            mat-button 
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="save()">
            {{ 'Save' | translate }}
        </button>
    </div>

    <div *ngIf="view === weatherStationViewsEnum.DISCARDCHANGES"
        mat-dialog-actions
        align="end">

        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only bk-dialog__button--secondary"
            (click)="switchView(weatherStationViewsEnum.FORM)">
            {{ 'Keep Editing' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="close()">
            {{ 'Discard' | translate }}
        </button>
    </div>

    <div *ngIf="view === weatherStationViewsEnum.DELETECONFIRMATION"
        mat-dialog-actions
        align="end">

        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only bk-dialog__button--secondary"
            (click)="switchView(weatherStationViewsEnum.FORM)">
            {{ 'Cancel' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="remove()">
            {{ 'Delete Weather Station' | translate }}
        </button>
    </div>
</div>
