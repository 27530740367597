export interface ISortable {
	Name: string;
}

export module BOKA {
	export class Array {

		/**
		 * expected input structure: [{ Name: "joe", age :15}, { Name: "Sam", age: 20}]
		 * @param ar
		 * @param key
		 * @param callback
		 */
		public static sortByName(ar: ISortable[], key: string, callback?: Function): object[] {

			if (!ar || ar.length === 0) {
				return null;
			}

			ar.sort(function (a: ISortable, b: ISortable) {
				if (callback) {
					return callback(a.Name, b.Name);
				} else {
					return a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 0;
				}
			});

			return ar;
		}
	}
}
