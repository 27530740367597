<div class="bk-dialog bk-dialog__event">
	
	<button type="button"
		class="bk-dialog__close"
		(click)="cancel()">
		&times;
	</button>

	<h4 mat-dialog-title>
		{{ 'Applied Fertilization Chart' | translate }}
	</h4>
	<h5 class='bk-modal__subTitle'>
		{{ plantingName }}
	</h5>

	<div mat-dialog-content>
		<div class="bk-chart" #chartContainer></div>
	</div>

	<div mat-dialog-actions align="end">

		<button (click)="cancel()" mat-button
			class="bk-button bk-button--medium bk-dialog__button--primary">
			{{ 'Close' | translate }}
		</button>
	</div>
</div>