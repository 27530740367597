import { Injectable } from '@angular/core';
import { ValidateService } from './validate.service';

import { IrrigationEvent, IUnitRatios } from '../components/irrigation-event/irrigationEvent';

import { IIrrigationObject, IIrrigationEventEditResponse } from '../components/irrigation-event/interfaces';
import { IFertilizationEvent } from '../components/fertilization-event/interfaces';
import { IEventGroup } from '../models/event/interfaces';
import { IWell } from '../models/planting-well/well.interface';
import { ILotPlantingWell } from '../models/planting-well/interfaces';

export interface ISprinklerApplicationRateConstant {
	type: number,
	intercept: number,
	pressure: number,
	nozzle: number,
	pressNozzle: number,
	pressSqr: number
}

@Injectable()
export class CalculateService {

	/*
     * Fertilizer Calculations
     */

	private fertilizerCalc = 0;
	private fertilizerInverse = 0;
	readonly IRRIGATION_METHOD_GERMINATION_SPRINKLER = 'Germination Sprinkler';

	private sprinklerSpecs: ISprinklerApplicationRateConstant[] = [
		{
			type: 1, // rainbird
			intercept: -3.59634,
			pressure: 0.02712,
			nozzle: 39.84918,
			pressNozzle: 0.28509,
			pressSqr: -0.00037498
		},
		{
			type: 2, // weather Tec
			intercept: -1.527,
			pressure: -0.0124,
			nozzle: 21.297,
			pressNozzle: 0.498,
			pressSqr: -0.000163
		}
	];

	public static eventIsPast(event: IEventGroup): boolean {
		let a, now: number;

		if (!event || event == null || !event.EventDate || event.EventDate == null) {
			return;
		}

		a = new Date(event.EventDate).setHours(0, 0, 0, 0);
		now = new Date().setHours(0, 0, 0, 0);

		return a < now;
	}

	public static percentToDecimal(percent: number): number {
		if (percent === undefined || percent === null || isNaN(percent)) {
			return null;
		}

		return percent / 100;
	}

	constructor() {
	}

	/**
	 * duplicate code
	 * @param lbsN
	 * @param fertilizerObject
	 */
	public convertToFertilizerUnit(lbsN: number, fertilizationEvent: IFertilizationEvent): number {

		if (fertilizationEvent === null) {
			return null;
		}

		if (lbsN === -1) {
			return lbsN;
		}

		if (lbsN === null || lbsN === undefined) {
			return null;
		}

		let lbsPerAcre: number;

		if (lbsN) {
			this.setFertilizationCalculationSettings(fertilizationEvent);
			lbsPerAcre = 0;

			if (fertilizationEvent.NPercentage > 0) {
				lbsPerAcre = lbsN * this.fertilizerCalc;
			}
			return lbsPerAcre;
		} else {
			return 0;
		}
	}

	public fertilizerConvertToLbsN(lbsPerAcre: number, fertilizerObject: IFertilizationEvent): number {
		if (lbsPerAcre === -1) {
			return lbsPerAcre;
		}

		if (lbsPerAcre === null || lbsPerAcre === undefined) {
			return null;
		}

		let lbsN: number;

		if (lbsPerAcre) {
			this.setFertilizationCalculationSettings(fertilizerObject);
			lbsN = 0;

			if (fertilizerObject.NPercentage > 0) {
				lbsN = lbsPerAcre * this.fertilizerInverse;
			}
			return lbsN;
		} else {
			return 0;
		}
	}

	private setFertilizationCalculationSettings(fertilizerObject: IFertilizationEvent): void {
		if (fertilizerObject.NPercentage === 0) {
			this.fertilizerCalc = 0;
			this.fertilizerInverse = 0;
		} else {
			if (fertilizerObject.IsFormulationTypeDry || fertilizerObject.FormulationType === 'Dry') {
				if (fertilizerObject.NPercentage === 0) {
					this.fertilizerCalc = 100;
				} else {
					this.fertilizerCalc = 100 / fertilizerObject.NPercentage;
				}
			} else {
				if (fertilizerObject.LbsPerGallon * fertilizerObject.NPercentage / 100 === 0) {
					this.fertilizerCalc = 1;
				} else {
					this.fertilizerCalc = 1 / (fertilizerObject.LbsPerGallon * fertilizerObject.NPercentage / 100);
				}
			}
			this.fertilizerInverse = 1 / this.fertilizerCalc;
		}
	}

	public irrigationConvertToTime(inches: number, irrigation: IIrrigationEventEditResponse): number {
		let ratios: IUnitRatios;
		let hours: number;

		if (inches === -1) {
			return -1;
		}

		if (inches) {
			ratios = IrrigationEvent.getUnitConversionRatio(irrigation.IrrigationMethodId, irrigation.SprinklerApplicationRate,
				irrigation.DripApplicationRate, irrigation.MicroSprinklerApplicationRate);

			hours = inches * ratios.inverse;
			return hours;
		} else {
			return 0;
		}
	}

	public irrigationConvertToInches(hours: number, irrigation: IIrrigationObject): number {
		let ratios: IUnitRatios;
		let result: number;

		if (hours === -1) {
			return -1;
		}

		if (hours) {
			ratios = IrrigationEvent.getUnitConversionRatio(irrigation.IrrigationMethodId, irrigation.SprinklerApplicationRate,
				irrigation.DripApplicationRate, irrigation.MicroSprinklerApplicationRate);

			result = hours * ratios.ratio;

			return result;
		} else {
			return 0;
		}
	}

	private calculateFlowRate(nozzlePressure: number, nozzleDiameter: number,
		intercept: number, Cpressure: number, Cnozzle: number, Cpressnozzle: number, Cpresssqr: number): number {

		return intercept +
			(Cpressure * nozzlePressure) +
			(Cnozzle * nozzleDiameter) +
			(Cpressnozzle * nozzleDiameter * nozzlePressure) +
			(Cpresssqr * Math.pow(nozzlePressure, 2)); // return units: gallons/minute (gpm)
	}

	private sprinklerApplicationRate(flowrate: number, lateralPipeSpacing: number, sprinklerHeadSpacing: number): number {
		return (
			flowrate * // gallons/minute
			60 * // minutes/hour
			43560 // ft^2/acre
		) / (
				27154 * // gallons / inch*acre
				lateralPipeSpacing * // ft
				sprinklerHeadSpacing // ft
			)
			; // return units: inch/hour
	};

	public calculateSprinklerApplicationRate(sprinklerType: number, nozzleDiameter: number,
		nozzlePressure: number, lateralPipeSpacing: number, sprinklerHeadSpacing: number): number {

		let sprinkler: ISprinklerApplicationRateConstant;
		let flowRate: number;
		let result: number;

		sprinkler = this.sprinklerSpecs.find(x => x.type === sprinklerType);

		if (!sprinkler) {
			throw new Error('no sprinkler found');
		}

		flowRate = sprinkler.intercept +
			(sprinkler.pressure * nozzlePressure) +
			(sprinkler.nozzle * nozzleDiameter) +
			(sprinkler.pressNozzle * nozzleDiameter * nozzlePressure) +
			(sprinkler.pressSqr * Math.pow(nozzlePressure, 2)); // return units: gallons/minute (gpm)

		result = (flowRate * 60 * 43560) / (27154 * lateralPipeSpacing * sprinklerHeadSpacing);

		return result;
	}

	/*drip application rate calculations*/

	public calculateDripApplicationRate(bedWidth: number, dripLinesPerBed: number, tapeDischargeRate: number): number {
		// bedWidth => inches (ie. 64)
		// dripLinesPerBed => the number of drip lines per bed (ie. 2)
		// tapeDischargeRate => (gallons/minute) / 100 ft (ie. 0.45)

		let constant = 60 * 12 * 43560 / (100 * 27154); // ?
		return tapeDischargeRate * dripLinesPerBed * constant / bedWidth; // return type => inches/hour
	};

	public calculateTotalNUptake(expectedYield: number, uptakeFactor: number, uptakeConstant: number): number {
		if (!expectedYield) {
			return 0;
		} else if (!uptakeFactor) {
			return 0;
		} else if (!uptakeConstant) {
			return 0;
		}

		expectedYield = !isNaN(expectedYield) ? Number(expectedYield) : null;
		uptakeFactor = !isNaN(uptakeFactor) ? Number(uptakeFactor) : null;
		uptakeConstant = !isNaN(uptakeConstant) ? Number(uptakeConstant) : null;

		if (expectedYield && uptakeFactor && uptakeConstant) {
			return Math.round(uptakeFactor * expectedYield + uptakeConstant); // return type => int => lbs N/acre
		} else {
			return 0;
		}
	}

	/**
	 * Used for N Contribution from Water UI, calculate N Applied, given
	 * water applied and PPM
	 *
	 * @param waterApplied
	 * @param ppm
	 */
	public calculateNApplied(waterApplied: number, ppm: number): number {
		return waterApplied * ppm * 0.227;
	}

	public calculateAveragePPM(wells: IWell[] | ILotPlantingWell[]): number {

		let result = 0;

		if (!wells || wells.length === 0) {
			return 0;
		}

		for (let well of wells) {
			result += well.NitrogenPPM * well.Percentage / 100;
		}

		return result;
	}
}
