<admin-nav></admin-nav>
<div class="bk-soil-moisture__page--container">
    
    <h5 class="bk-text--gray">{{'Soil Sensor Data' | translate }}</h5>

    <div class="bk-data-table__update-message" [class.bk-data-table__update-message--error]="soilMoistureUpdateError" *ngIf="plantingBeingUpdated">
        <i class="fa fa-check bk-data-table__update-message--status-icon" *ngIf="!soilMoistureUpdateError"></i>
        <i class="fa fa-exclamation-circle bk-data-table__update-message--status-icon bk-data-table__update-message--status-icon-error" *ngIf="soilMoistureUpdateError"></i>

        <span *ngIf="!soilMoistureUpdateError">
            {{ 'Data import job started for' | translate }} "{{plantingBeingUpdated.name}}". {{ 'This may take several minutes to complete' | translate }}.
        </span>

        <span *ngIf="soilMoistureUpdateError === eSoilMoistureImportErrors.DATE">
            {{ 'Soil Sensor Data file for' | translate }} "{{plantingBeingUpdated.name}}" {{ 'contains a date format' | translate}}. 
            {{ 'We suggest you use the format YYYY-MM-DD' | translate }}.
        </span>

        <span *ngIf="soilMoistureUpdateError === eSoilMoistureImportErrors.COLUMNS">
            {{ 'Number of columns in Soil Sensor Data file for' | translate }} "{{plantingBeingUpdated.name}}" {{ 'does not match number of columns in the header file' | translate}}. 
            {{ 'Please verify the columns in both files match' | translate }}.
        </span>

        <span *ngIf="soilMoistureUpdateError === eSoilMoistureImportErrors.NOTFOUND">
            {{ 'Soil Sensor Data file for' | translate }} "{{plantingBeingUpdated.name}}" {{ 'could not be found' | translate}}. 
            {{ 'Please make sure the file exists on the server and try again' | translate }}.
        </span>

        <span *ngIf="soilMoistureUpdateError === eSoilMoistureImportErrors.EMPTY">
            {{ 'Content of Soil Sensor Data file for' | translate }} "{{plantingBeingUpdated.name}}" {{ 'is empty' | translate}}. 
            {{ 'Please verify Soil Sensor Data file contains entries' | translate }}.
        </span>

        <span *ngIf="soilMoistureUpdateError === eSoilMoistureImportErrors.GENERIC">
            {{ 'There was an error updating' | translate }} "{{plantingBeingUpdated.name}}" {{ 'Soil Sensor Data' | translate}}. 
            {{ 'Check the' | translate }} <a class="bk-data-table__update-message--link" href="javascript:void(0)"
                                             (click)="openSoilMoistureDialog(eSoilMoistureViews.SETTINGS, plantingBeingUpdated.id, plantingBeingUpdated.name, null, true)">
                {{ 'Planting Soil Sensor Data Settings' | translate}}
            </a>
        </span>

        <i class="fa fa-times-circle bk-data-table__update-message--close" (click)="clearSoilMoistureUpdateMessage()"></i>
    </div>

    <div class="bk-data-table">
        <div class="bk-data-table__head">
            <div class="bk-data-table__title">
                {{ 'Active Soil Sensor Data Files' | translate}}
            </div>
            <div class="bk-data-table__search">
                <label class="bk-data-table__search--label">
                    {{ 'Search Soil Sensor Data Files' | translate }}:
                    <input class="bk-data-table__search--input" (keyup)="applyFilter($event.target.value)" placeholder="Ranch Name, Planting Name, Date, File URL" />
                </label>
            </div>
        </div>

        <div class="bk-data-table__content">
            <mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="RanchName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Ranch' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.RanchName}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="PlantingName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Planting' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.PlantingName}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="HarvestDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Harvest Date' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.HarvestDate}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="FileName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'File URL' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.FileName}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="LastUpdateDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Last Updated' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.LastUpdateDate}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <mat-header-cell *matHeaderCellDef> {{ 'Actions' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="bk-data-table__cell-overflow"> 
                        <div class="bk-split-button__group">
                            <button type="button"
                                class="bk-button bk-split-button__button"                                         
                                (click)="openSoilMoistureDialog(eSoilMoistureViews.GRAPH, row.PlantingId, row.PlantingName, row.FileName, null)">View</button>
                            <div class="bk-split-button__group--dropdown" ngbDropdown role="group">
                                <button class="bk-button bk-split-button__dropdown-toggle"
                                    ngbDropdownToggle></button>
                                <div class="bk-split-button__dropdown-menu" ngbDropdownMenu>
                                    <button class="bk-button bk-split-button__dropdown-menu-item" 
                                        (click)="updateSoilMoistureData(row.PlantingId, row.PlantingName, row.FileName)">
                                        <i class="fa fa-arrow-circle-o-down"></i> {{ 'Update' | translate }}
                                    </button>
                                    <button class="bk-button bk-split-button__dropdown-menu-item" 
                                        (click)="openSoilMoistureDialog(eSoilMoistureViews.SETTINGS, row.PlantingId, row.PlantingName, row.FileName, null)">
                                        <i class="fa fa-cog"></i> {{ 'Settings' | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

            </mat-table>

            <div class="bk-data-table__empty" *ngIf="dataSource && dataSource.filteredData && dataSource.filteredData.length === 0"> 
                {{ 'There are no Active Soil Sensor Data Files matching' | translate }} <span class="bk-data-table__empty--filter">"{{dataSource.filter}}"</span>
            </div>

            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
        </div>
    </div>
</div>