<div class="block bk-modal__right bk-modal__right-fertilizers">
	<h4 *ngIf='fertilizers'
		class="bk-modal__right-header bk-modal__right-header--float-left">

		{{fertilizers.length}} {{'Fertilizers' | translate }}
	</h4>

	<mat-form-field appearance="fill" class="mat-form-field--short mat-form-field--right">
		<input matInput
			placeholder="{{'Filter By' | translate}}"
			type="text"
			name="searchQuery"
			[(ngModel)]="searchQuery"
			(ngModelChange)="filterFertilizers($event)"/>
		<mat-icon matSuffix>search</mat-icon>
	</mat-form-field>

	<div class='bk-scroll-area'>
		<table class="bk-modal__table bk-fixed-header-table">
			<thead>
				<tr>
					<th></th>
					<th>
						{{'Name' | translate }}<i class="fa fa-triangle-down"></i>
					</th>
					<th>
						{{'Formulation' | translate }}
					</th>
					<th>
						{{'Nitrogen' | translate }} %
					</th>
					<th>
						{{'Lbs/gal (liquid)' | translate }}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let fertilizer of filteredFertilizers"
					class="bk-modal__row" >
					<td>
						<input type="checkbox"
							name="fertilizer"
							value="fertilizer"
							[(ngModel)]="fertilizer.checked" />
					</td>
					<td>
						{{fertilizer.Name}}
					</td>
					<td>
						<span *ngIf="fertilizer.FormulationType">
							{{fertilizer.FormulationType.Name}}
						</span>
					</td>
					<td>
						{{fertilizer.PercentageN}}
					</td>
					<td>
						{{fertilizer.LbsPerGallon}}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>