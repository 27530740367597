import { NgModule, Injectable } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules, CanDeactivate } from '@angular/router';

import { AdminComponent } from './components/admin/main';
import { ProfileComponent } from './components/profile/main';
import { IgnoreSchedulesComponent } from './components/ignore-schedules/ignore-schedules.component';
import { WeatherStationsComponent } from './components/weather-stations/weather-stations.component';
import { LocalizationComponent } from './components/localization/localization.component';
import { SoilMoistureComponent } from './components/soil-moisture/soil-moisture.component';
import { CropTypeListComponent } from './components/crop-types/crop-types';
import { CropTypeDetailComponent } from './components/crop-types/crop-type-detail';
import { HomeComponent } from './components/public/home/home.component';
import { LoginComponent } from './components/public/login/login.component';
import { ForgetPasswordPage } from './components/public/forget-password/forget-password.page';
import { ForgetPasswordConfirmationPage } from './components/public/forget-password/forget-password-confirmation.page';
import { ResetPasswordPage } from './components/public/reset-password/reset-password-page';
import { ResetPasswordConfirmationPage } from './components/public/reset-password/reset-password-confirmation.page';
import { RegistrationPage } from './components/public/registeration/register.page';
import { MustConfirmEmailPage } from './components/public/registeration/must-confirmEmail.page';
import { ConfirmEmailPage } from './components/public/registeration/confirm-email.page';
import { PrivacyPolicyComponent } from './components/public/privacy-policy/privacy-policy';
import { loginTypes } from './services/constants';
import { PreviousCropDetailComponent } from './components/crop-types/previous-crop-detail';
import { PageNotFoundComponent } from './components/public/page-not-found/page-not-found';
import { MissingStationRecordsComponent } from './components/weather-stations/missing-station-records';
import { GlobalSettingsComponent } from './components/global-settings/global-settings.component';
import { ETCalculatorComponent } from './components/public/et-calculator/et-calculator.component';
import { ETCalculatorResultsComponent } from './components/public/et-calculator/et-calculator-results.component';

@Injectable()
export class ConfirmDeactivateGuard implements CanDeactivate<CropTypeDetailComponent> {

	canDeactivate(target: CropTypeDetailComponent) {
		if (target.hasChanges) {
			return window.confirm('You made changes to this form. Do you really want to navigate away from this page?');
		}
		return true;
	}

}
const routes: Routes = [
	{ path: '', component: HomeComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'et-calculator', component: ETCalculatorComponent },
	{ path: 'et-calculator-results', component: ETCalculatorResultsComponent },
	{ path: 'workshop', component: LoginComponent, data: { type: loginTypes.WORKSHOP }},
	// workshop only login URL - logging in through this path will associate the user with UCCE ranch
	{ path: 'privacyPolicy', component: PrivacyPolicyComponent },
	{ path: 'MyDashboard', loadChildren: './modules/dashboard.module#DashboardModule' },
	{ path: 'RanchHome', loadChildren: './modules/ranch-home.module#RanchHomeModule' },

	{ path: 'forgetPassword', component: ForgetPasswordPage },
	{ path: 'forgetPasswordConfirmation', component: ForgetPasswordConfirmationPage },
	{ path: 'resetPassword', component: ResetPasswordPage },
	{ path: 'resetPasswordConfirmation', component: ResetPasswordConfirmationPage },
	{ path: 'register', component: RegistrationPage },
	{ path: 'mustConfirmEmail', component: MustConfirmEmailPage },
	{ path: 'confirmEmail', component: ConfirmEmailPage },

	{ path: 'Administration', component: AdminComponent },
	{ path: 'Profile', component: ProfileComponent },
	{ path: 'IgnoreSchedules', component: IgnoreSchedulesComponent },
	{ path: 'WeatherStations', component: WeatherStationsComponent },
	{ path: 'CropTypes', component: CropTypeListComponent },
	{ path: 'GlobalSettings', component: GlobalSettingsComponent },
	{ path: 'CropTypeDetails', component: CropTypeDetailComponent, canDeactivate: [ConfirmDeactivateGuard]},
	{ path: 'MissingStationRecords', component: MissingStationRecordsComponent },
	{ path: 'PreviousCropDetails', component: PreviousCropDetailComponent },
	{ path: 'Localization', component: LocalizationComponent },
	{ path: 'Ranches', loadChildren: './modules/ranches/admin-ranches.module#AdminRanchesModule'},
	{ path: 'SoilSensor', component: SoilMoistureComponent },
	{ path: 'userManagement', loadChildren: './modules/user-management/user-management.module#UserManagementModule'},
	{ path: 'flowmeter', loadChildren: './modules/flowmeter/flowmeter.module#FlowmeterModule'},
	{ path: 'cropStages', loadChildren: './modules/crop-stages/crop-stages.module#CropStagesModule'},
	{ path: 'statistics', loadChildren: './modules/statistics/statistics.module#StatisticsModule'},
	{ path: 'WebAPIStatistics', loadChildren: './modules/web-api-stats/web-stats.module#WebAPIStatisticsModule'},
	{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
	providers: []
})

export class AppRoutingModule { }

