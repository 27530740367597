/**
* DB calls related to fertilizer table
*
**/

import { Injectable } from '@angular/core';

import { UpdateService } from '../../../../services/update.service';
import { HttpService } from '../../../../services/http.service';
import { RanchFertilizerViewModel, FertilizerViewModel, IFertilizer,
	IFertilizerModelService, FertilizerNutrientViewModel } from './interfaces';
import { Fertilizer } from './fertilizer.class';
import { IFertilizerProperties } from '../../../fertilization-event/interfaces';
import { ModelService } from '../../../../services/model.service';
import { ValidateService } from '../../../../services/validate.service';
import { IIdNamePair } from '../../../../interfaces/abstract';
import { CMError, SuccessResponse } from '../../../../interfaces/interfaces';

@Injectable()
export class FertilizerModelService extends ModelService implements IFertilizerModelService {

	private urls = {
		getProperties: (id: number) => `/v2/fertilizers/${id}/summary.json`,
		show: (id: number) => `/v2/fertilizers/${id}.json`,
		addBatch: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/fertilizers/batch.json`,
		createForm: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/fertilizers/create-form.json`,
		create: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/fertilizers.json`,
		delete: (ranchGuid: string, id: number) => `/v2/ranches/${ranchGuid}/fertilizers/${id}.json`,
		update: (id: number) => `/v2/fertilizers/${id}.json`,
		exists: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/fertilizers/exists.json`,
		listAvailable: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/fertilizers/available.json`,
		list: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/fertilizers.json`
	};

	constructor(
		protected updateService: UpdateService,
		protected validateService: ValidateService,
		protected httpService: HttpService) {

		super(httpService, validateService);
	}

	public view(fertilizerId: number): Promise<RanchFertilizerViewModel> {

		return this.httpService
			.get({
				url: this.urls.show(fertilizerId),
				callback: this.processViewFertilizerData,
				isWebAPI: true,
			});
	}

	public getEditModel(id: number): Promise<RanchFertilizerViewModel> {

		if (!id) {
			throw new Error('FertilizerModelService:ViewEdit - id is empty');
		}

		return this.httpService.get({
			url: this.urls.show(id),
			callback: this.processGetFertilizerData,
			isWebAPI: true
		});
	}

	public getCreateModel(): Promise<RanchFertilizerViewModel> {

		return this.httpService.get({
			url: this.urls.createForm(this.updateService.currentRanchId),
			callback: this.processGetFertilizerData,
			isWebAPI: true
		});
	}

	public add(fertilizer: RanchFertilizerViewModel): Promise<Object> {
		return this.httpService
			.post({
				url: this.urls.create(this.updateService.currentRanchId),
				body: fertilizer,
				isWebAPI: true
			});
	}

	public addBatch(fertilizers: number[]): Promise<void> {
		return this.httpService
			.post({
				url: this.urls.addBatch(this.updateService.currentRanchId),
				body: { FertilizerIds: fertilizers },
				isWebAPI: true
			});
	}

	public getByRanchId(ranchId: string): Promise<IFertilizer[]> {

		return this.httpService.get({
			url: this.urls.list(ranchId),
			isWebAPI: true,
			callback: function (json: IFertilizer[]) {

				if (!json) {
					return new Array();
				}

				return json;
			}
		});
	}

	public update(id: number, fertilizer: RanchFertilizerViewModel): Promise<IIdNamePair> {
		return this.httpService
			.put({
				url: this.urls.update(id),
				body: fertilizer,
				isWebAPI: true,

				callback: (response: IIdNamePair | CMError) => {
					let data: IIdNamePair;
					let error: CMError;

					if (!response) {
						return;
					}

					data = response as IIdNamePair;
					error = response as CMError;

					if (error && error.code) {
						console.warn(error.message); // display error message
						this.httpService.handleError(this.urls.update(id), error.message);
						return;
					}

					return response;
				}
			});
	}

	public delete(id: number): Promise<string> {
		return this.httpService
			.delete({
				url: this.urls.delete(this.updateService.currentRanchId, id),
				isWebAPI: true,
				shouldBypassServerErrorAlert: true,
				callback: (response: SuccessResponse | CMError): string  => {

					let error: CMError;

					error = response as CMError;

					if (error && error.code) {
						return error.message;
					}
				},
			});
	}

	public listAvailable(): Promise<IFertilizer[]> {

		return this.httpService.get({
			url: this.urls.listAvailable(this.updateService.currentRanchId),
			callback: this.processAvailableFertilizers,
			isWebAPI: true
		});

	}

	/**
	 * @param id
	 */
	public getProperties(id: number): Promise<IFertilizerProperties> {

		return this.get(this.urls.getProperties(id),
			(data: IFertilizerProperties): IFertilizerProperties => {
			return data;
		});
	}


	private processAvailableFertilizers(data: IFertilizer[]): IFertilizer[] {
		if (!data || data.length === 0) {
			return new Array();
		}

		return data;
	}

	private processGetFertilizerData(obj: FertilizerViewModel): RanchFertilizerViewModel {
		let fertilizerModel: RanchFertilizerViewModel;

		if (!obj || !obj.Model) {

			return null;
		}

		fertilizerModel = obj.Model;
		fertilizerModel.Nutrients = fertilizerModel.Nutrients.filter(() =>
			function (o: FertilizerNutrientViewModel) { // REFACTOR - replace numbers
				// 2) why are we even checking the ids? Nutrients is not typed
				let id = o.NutrientId;

				return id === 1 || id === 2 || id === 3 || id === 11 || id === 4 ||
					id === 5 || id === 6 || id === 8 || id === 7;
			});

		fertilizerModel.IsPrivate = true;

		return fertilizerModel;
	}

	private processViewFertilizerData(data: FertilizerViewModel): RanchFertilizerViewModel {
		let result: RanchFertilizerViewModel;

		if (!data) {
			throw new Error('FertilizerModelService: processViewFertilizerData() - data is empty');
		}

		result = data.Model;

		if (data.Nutrients) {

			result.Nutrients = Fertilizer.convertNutrient(data.Nutrients);
		}

		if (data.FormulationTypes) {
			result.FormulationType = data.FormulationTypes.filter(x => x.Id === result.FormulationTypeId)[0];
		} else { // avoid null reference in component template
			result.FormulationType = {
				Id: null,
				Name: null
			};
		}

		return result;
	}
}
