
import { OnInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { CropTypeService } from '../../models/crop-type/service';
import { ICropTypeViewModel } from '../../models/crop-type/interfaces';
import { FormComponent } from '../shared/form/form';
import { of, Subject } from 'rxjs';
import { ICommodityType } from '../../models/commodity-type/interfaces';
import { CommodityService } from '../../models/commodity-type/service';
import { eNotificationTypes } from '../../interfaces/constants';
import { NotificationService } from '../../services/notification.service';
import { TokenService } from '../../services/token.service';
import { cropTypeViewTypes } from './crop-types';
import { takeUntil } from 'rxjs/operators';

@Component({
	moduleId: module.id,
	selector: 'previous-crop-detail',
	templateUrl: 'previous-crop-detail.html'
})

export class PreviousCropDetailComponent extends FormComponent implements OnInit, OnDestroy {

	public commodityTypes: ICommodityType[];
	public cropType: ICropTypeViewModel;

	public formErrors = {
		'Name': '',
		'CommodityTypeId': '',

		'MaxNRec': '',
		'PrevCropK': '',
	};

	public validationMessages = {
		'Name': { 'required': 'Name is required.' },
		'CommodityTypeId': { 'required': 'Commodity type is required' },

		'MaxNRec': {
			'required': 'MaxNRec is required.',
			'minNumber': 'MaxNRec must be greater than 0.'
		},
		'PrevCropK': { 'required': 'PrevCropK is required' },
	};

	// private members

	private _cropTypeId: number;
	protected _subscriptions$: Subject<boolean>;

	constructor(
		private _router: Router,
		private _tokenService: TokenService,
		private _route: ActivatedRoute,
		private _navigationRouter: Router,
		private _cropTypeService: CropTypeService,
		private _commodityService: CommodityService,
		private _notificationService: NotificationService
	) {
		super();

		if (this._tokenService.isAuthenticated() === false) {
			this._router.navigate(['login']);
		}
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();

		this._route.queryParams.pipe(takeUntil(this._subscriptions$)).subscribe(params => {
			this._cropTypeId = params['id'];

			if (this._cropTypeId) {
				this.getEditModel(this._cropTypeId).then(() => {
					this._commodityService.list().then((response) => {
						this.commodityTypes = response;
						this.isDirtyOnLoad = true;
					});

					return;
				})
			} else {
				this.getCreateModel().then(() => {
					this._commodityService.list().then((response) => {
						this.commodityTypes = response;
					});

					return;
				});
			}
		})
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	/**
     * Get initial parameter defaults for creating a new crop type
     */
	private async getCreateModel(): Promise<void> {

		let response: ICropTypeViewModel = await this._cropTypeService.getCreateModel();
		this.cropType = response;
		this.cropType.IsPreviousCrop = true;
	}

	/**
     * get cropTypeViewModel from backend to edit
     */
	private async getEditModel(id: number): Promise<void> {
		let response: ICropTypeViewModel;

		if (!id) {
			return of(null).toPromise();
		}

		response = await this._cropTypeService.getEditModel(id);

		if (!response) {
			return null;
		}

		this.cropType = response;
	}

	public update() {
		if (this.cropType.Id) {
			this._cropTypeService.update(this.cropType).then(() => {
				this._notifyAfterSave();
			});
		} else {
			this._cropTypeService.create(this.cropType).then(() => {
				this._notifyAfterSave();
			});
		}
	}

	private _notifyAfterSave() {
		let navigationExtras: NavigationExtras = {
			queryParams: {
				'viewType': cropTypeViewTypes.PREVIOUS_CROP,
			}
		}

		this._notificationService.generateNotifcation({
			type: eNotificationTypes.UPDATE,
			message: 'Crop Type ' + this.cropType.Name + ' was successfully saved'
		});

		this._navigationRouter.navigate(['CropTypes'], navigationExtras);
	}

	/**
     * Back button => back to list view
     */
	public goBack() {
		let navigationExtras: NavigationExtras = {
			queryParams: {
				'viewType': cropTypeViewTypes.PREVIOUS_CROP,
			}
		}

		this._navigationRouter.navigate(['CropTypes'], navigationExtras);
	}
}
