import {
	IPlantingAdvancedEdit, IPlantingBasicInfoViewModel,
	IPlantingMetaDataViewModel, IPlantingSettingsViewModel, IPlantingCreateViewModel,
	IPlantingSettingsCalculatorSettings, IPlantingAdvancedViewModel, IPlantingSettingsViewModelJSON, IPlantingBasicInfoViewModelJSON, IPlanting
} from './interfaces';

import { ICropTypeViewModel, IVegetativeNDemand,
	IPerennialCanopy } from '../../models/crop-type/interfaces';
import { CombinedSoilProperties } from '../../models/soil-type/interfaces';
import { eCalculators } from '../../interfaces/constants';
import { DateUtility } from '../../classes/dateUtility';
import { Planting } from './planting';
import { ISelectOptions } from '../../interfaces/abstract';
import { TranslateService } from '../localization/service';
import { TranslatePipe } from '../../pipes/translate.pipe';

export class PlantingAdvancedEdit implements IPlantingAdvancedEdit {
	public Id: number;
	public PlantingBasicInfoViewModel: IPlantingBasicInfoViewModel;
	public Metadata: IPlantingMetaDataViewModel;
	public PlantingSettingsViewModel: IPlantingSettingsViewModel;
	public CropTypeViewModel: ICropTypeViewModel;
	public SoilProperties: CombinedSoilProperties;
	public calculator: eCalculators;
	public HasFlowMeterFileName: boolean;
	public CalculatorSettings?: IPlantingSettingsCalculatorSettings;

	public static getPerennialCanopy(age: number, perennialCanopies: IPerennialCanopy[]): IPerennialCanopy {
		let result: IPerennialCanopy;

		if (!perennialCanopies || perennialCanopies.length === 0) {

			return null;
		}

		if (age === null || age === undefined) { // 0 is valid
			return null;
		}

		if (age === 0) {
			result = perennialCanopies.find(x => x.Age === age);
		} else {
			let ar = perennialCanopies.filter(x => x.Age <= age).sort((a, b) => {
				return b.Age - a.Age;
			});

			result = ar[0];
		}

		return result;
	}

	public static generateAgeOfCropDropdown(maxAge: number, translateService: TranslateService): ISelectOptions[] {
		let result: ISelectOptions[];

		result = [];

		for (let i = 0; i <= maxAge; i++) {
			let option: ISelectOptions;

			option = {
				key: i,
				value: null
			};

			if (i === 0) {
				option.value = '1 ' + translateService.translate('year');
			} else if (i < maxAge) {
				option.value = (i + 1) + ' ' + translateService.translate('years');
			} else {
				option.value = (i + 1) + '+ ' + translateService.translate('years');
			}

			result.push(option);
		}

		return result;
	}

	public static getVegetativeNDemand(age: number, vegetativeDemands: IVegetativeNDemand[]): IVegetativeNDemand {
		let result: IVegetativeNDemand;

		if (age === null || age === undefined) { // 0 is valid
			return null;
		}

		if (!vegetativeDemands) {
			throw new Error('vegetativeDemands array is empty');
		}

		result = vegetativeDemands.filter(x => age + 1 >= x.YearMin
			&& age + 1 <= x.YearMax)[0];

		if (!result) {
			return null;
		} else {
			return result;
		}
	}


	public constructor() {
	}

	public initializeWithCreateViewModel(json: IPlantingCreateViewModel): void {

		if (!json) {
			return;
		}

		if (!this.PlantingBasicInfoViewModel) {
			this.PlantingBasicInfoViewModel = <IPlantingBasicInfoViewModel>{};
		}

		this.PlantingBasicInfoViewModel.PlantingName = '';
		this.PlantingBasicInfoViewModel.WetDate = null;
		this.PlantingBasicInfoViewModel.HarvestDate = null;
		this.PlantingBasicInfoViewModel.Year = json.Year;

		if (!this.PlantingSettingsViewModel) {
			this.PlantingSettingsViewModel = <IPlantingSettingsViewModel>{};
		}

		this.PlantingSettingsViewModel.Acres = 0;
		this.PlantingSettingsViewModel.DripApplicationRate = json.DripApplicationRate;
		this.PlantingSettingsViewModel.DripDistUniformPct = json.DripDistUniformPct;
		this.PlantingSettingsViewModel.DripFlowMeterArea = json.DripFlowMeterArea;
		this.PlantingSettingsViewModel.FlowMeterArea = json.FlowMeterArea;
		this.PlantingSettingsViewModel.FlowMeterThresholdSprinkler = json.FlowMeterThresholdSprinkler;
		this.PlantingSettingsViewModel.FlowMeterThresholdDrip = json.FlowMeterThresholdDrip;
		this.PlantingSettingsViewModel.GerminationSprinklerPct = json.GerminationSprinklerPct;
		this.PlantingSettingsViewModel.LeechingFactorPct = json.LeechingFactorPct;
		this.PlantingSettingsViewModel.SprinklerApplicationRate = json.SprinklerApplicationRate;
		this.PlantingSettingsViewModel.SprinklerDistUniformityPct = json.SprinklerDistUniformityPct;
		this.PlantingSettingsViewModel.MicroSprinklerApplicationRate = json.MicroSprinklerApplicationRate;
		this.PlantingSettingsViewModel.MicroSprinklerDistUniformityPct = json.MicroSprinklerDistUniformityPct;
		this.PlantingSettingsViewModel.FloodDistUniformityPct = json.FloodDistUniformityPct,
			this.PlantingSettingsViewModel.PrevCropDays = json.PreviousCropDayInterval;
		this.PlantingSettingsViewModel.IsCoverCropEnabled = true; // enable cover crop to enabled by default
		this.PlantingSettingsViewModel.IsSpatialEnabled = json.IsSpatialEnabled
		if (!this.Metadata) {
			this.Metadata = <IPlantingMetaDataViewModel>{};
		}

		this.Metadata.Lots = json.Lots;
		this.Metadata.CommodityTypes = json.CommodityTypes;
		this.Metadata.CropTypes = new Array();
		this.Metadata.RanchName = json.RanchName;
		this.Metadata.RanchGuid = json.RanchId;
		this.Metadata.PrevCropTypes = json.PrevCropTypes;

		if (!this.CropTypeViewModel) {
			this.CropTypeViewModel = <ICropTypeViewModel>{};
		}

		this.CalculatorSettings = {
			CompostN: {
				Id: null,
				LotPlantingId: null,
				Amount: null,
				N: null,
				ApplicationMethod: null
			},
			ManureN: {
				Id: null,
				LotPlantingId: null,
				TypePriorYear: null,
				RatePriorYear: null,
				TypeTwoYearsAgo: null,
				RateTwoYearsAgo: null,
			},
			MicroSprinklerSetting: {
				Id: null,
				LotPlantingId: null,
				InRowTreeSpacing: null,
				BetweenRowTreeSpacing: null,
				MicroSprinklersPerTree: null,
				UseFlowRate: null,
				FlowRate: null,
				MicroSprinklerId: null,
			},
			CoverCrop: {
				Id: null,
				LotPlantingId: null,
				LegumeCoverCropStand: null,
				IncorporationMethod: null
			},
			DripApplicationRate: {
				Id: null,
				LotPlantingId: null,
				BedWidth: null,
				DripLinesPerBed: null,
				TapeDischargeRate: null,
			},
			SprinklerApplicationRate: {
				Id: null,
				LotPlantingId: null,
				Type: null,
				NozzleDiameter: null,
				NozzlePressure: null,
				HeadSpacing: null,
				PipeSpacing: null
			},
		};
	}

	/**
     * Creates an empty shell, used for unit tests
     */
	public createBlank(): void {
		this.PlantingBasicInfoViewModel = <IPlantingBasicInfoViewModel>{};
		this.PlantingBasicInfoViewModel.PlantingName = '';
		this.PlantingBasicInfoViewModel.WetDate = new Date();
		this.PlantingBasicInfoViewModel.HarvestDate = new Date();
		this.PlantingBasicInfoViewModel.Year = null;

		this.PlantingSettingsViewModel = <IPlantingSettingsViewModel>{};

		this.PlantingSettingsViewModel.Acres = 0;
		this.PlantingSettingsViewModel.DripApplicationRate = 2;
		this.PlantingSettingsViewModel.DripDistUniformPct = 4;
		this.PlantingSettingsViewModel.DripFlowMeterArea = 4;
		this.PlantingSettingsViewModel.FlowMeterArea = 4;
		this.PlantingSettingsViewModel.FlowMeterThresholdSprinkler = 3;
		this.PlantingSettingsViewModel.FlowMeterThresholdDrip = 3;
		this.PlantingSettingsViewModel.GerminationSprinklerPct = 3;
		this.PlantingSettingsViewModel.LeechingFactorPct = 3;
		this.PlantingSettingsViewModel.SprinklerApplicationRate = 1;
		this.PlantingSettingsViewModel.SprinklerDistUniformityPct = 1;
		this.PlantingSettingsViewModel.MicroSprinklerApplicationRate = 1;
		this.PlantingSettingsViewModel.MicroSprinklerDistUniformityPct = 1;
		this.PlantingSettingsViewModel.PrevCropDays = 200;
		this.PlantingSettingsViewModel.IsCoverCropEnabled = true; // enable cover crop to enabled by default

		this.Metadata = <IPlantingMetaDataViewModel>{};

		this.Metadata.Lots = null;
		this.Metadata.CommodityTypes = null;
		this.Metadata.CropTypes = null;
		this.Metadata.RanchName = null;
		this.Metadata.RanchGuid = null;
		this.CropTypeViewModel = <ICropTypeViewModel>{};
	}

	public initializeWithPlantingAdvancedViewModel(data: IPlantingAdvancedViewModel) {
		let settingsModel: IPlantingSettingsViewModelJSON;
		let basicModel: IPlantingBasicInfoViewModelJSON;

		if (!data) {
			return null;
		}

		if (!data.PlantingSettingsViewModel || !data.PlantingBasicInfoViewModel ||
			!data.CropTypeViewModel || !data.Metadata) {
			throw new Error('getEditModel() - planting data is empty');
		}

		this.Id = data.Id;
		this.CalculatorSettings = data.CalculatorSettings;
		this.SoilProperties = data.SoilProperties;

		settingsModel = data.PlantingSettingsViewModel;
		basicModel = data.PlantingBasicInfoViewModel;

		this.PlantingSettingsViewModel = {
			FlowMeterFileName: settingsModel.FlowMeterFileName,
			SoilMoistureFileName: settingsModel.SoilMoistureFileName,
			ObstructionDepth: settingsModel.ObstructionDepth,
			Acres: settingsModel.Acres,
			Coordinates: settingsModel.Coordinates,
			PrevCropDays: settingsModel.PrevCropDays,
			DripApplicationRate: settingsModel.DripApplicationRate,
			DripDistUniformPct: settingsModel.DripDistUniformPct,
			FloodDistUniformityPct: settingsModel.FloodDistUniformityPct,
			DripFlowMeterArea: settingsModel.DripFlowMeterArea,
			IrrigationMethods: settingsModel.IrrigationMethods,
			DeficitType: settingsModel.DeficitType,
			ControlPoints: settingsModel.ControlPoints,
			LeechingFactorPct: settingsModel.LeechingFactorPct,
			GerminationSprinklerPct: settingsModel.GerminationSprinklerPct,
			MicroSprinklerFlowMeterArea: settingsModel.MicroSprinklerFlowMeterArea,
			FlowMeterArea: settingsModel.FlowMeterArea,
			SprinklerApplicationRate: settingsModel.SprinklerApplicationRate,
			SprinklerDistUniformityPct: settingsModel.SprinklerDistUniformityPct,
			MicroSprinklerDistUniformityPct: settingsModel.MicroSprinklerDistUniformityPct,
			MicroSprinklerApplicationRate: settingsModel.MicroSprinklerApplicationRate,
			PreviousCropTypeId: settingsModel.PreviousCropTypeId,
			FlowMeterThresholdSprinkler: settingsModel.FlowMeterThresholdSprinkler,
			FlowMeterThresholdMicroSprinkler: settingsModel.FlowMeterThresholdMicroSprinkler,
			FlowMeterThresholdDrip: settingsModel.FlowMeterThresholdDrip,
			IsCoverCropEnabled: settingsModel.IsCoverCropEnabled,
			YieldTarget: settingsModel.YieldTarget,
			YieldActual: settingsModel.YieldActual,
			CommodityTypeYieldUnitId: settingsModel.CommodityTypeYieldUnitId,
			IsHighVigor: settingsModel.IsHighVigor === true ? true : false, // high vigor defaults to hgih
			LeafSenesceDate: DateUtility.DotNetToDate(settingsModel.LeafSenesceDate),
			StressStartDate:  DateUtility.DotNetToDate(settingsModel.StressStartDate),
			StressEndDate: DateUtility.DotNetToDate(settingsModel.StressEndDate),
			IsMacroTunnelEnabled: settingsModel.IsMacroTunnelEnabled,
			IsMacroTunnelFullSeason: settingsModel.IsMacroTunnelFullSeason,
			MacroTunnelStartDate: DateUtility.DotNetToDate(settingsModel.MacroTunnelStartDate),
			MacroTunnelEndDate: DateUtility.DotNetToDate(settingsModel.MacroTunnelEndDate),
			IsStressEnabled: settingsModel.IsStressEnabled,
			IsSpatialEnabled: settingsModel.IsSpatialEnabled
		};

		this.PlantingBasicInfoViewModel = {
			PlantingName: basicModel.PlantingName,
			Year: null,
			HarvestDate: DateUtility.DotNetToDate(basicModel.HarvestDate),
			WetDate: DateUtility.DotNetToDate(basicModel.WetDate),
			LotId: basicModel.LotId,
			LotName: basicModel.LotName,
			CommodityTypeId: data.Metadata.CommodityTypeId,
			CropTypeId: basicModel.CropTypeId,
			AgeOfCrop: basicModel.AgeOfCrop
		}

		this.SoilProperties = data.SoilProperties;

		this.CropTypeViewModel = data.CropTypeViewModel;
		this.CropTypeViewModel.DaysToEndDate = (data.CropTypeViewModel.DaysToEndDate ? data.CropTypeViewModel.DaysToEndDate : 0);
		this.CropTypeViewModel.Name = data.Metadata.ActiveCropType ? data.Metadata.ActiveCropType.Name : null;
		this.CropTypeViewModel.CommodityTypeId = this.PlantingBasicInfoViewModel.CommodityTypeId;

		if (this.CropTypeViewModel.SeasonalKCs && this.CropTypeViewModel.SeasonalKCs.length) {
			this.CropTypeViewModel.SeasonalKCs = this.CropTypeViewModel.SeasonalKCs.filter(x => x.Month > 0 && x.Month < 13);
		}

		this.Metadata = data.Metadata;
		this.calculator = Planting.getCalculator(
			Planting.getCalculatorInterface(this.CropTypeViewModel.CommodityTypeId, this.Metadata.CommodityTypes));

		return this;
	}
}
