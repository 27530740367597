import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { Title } from '@angular/platform-browser';

@Component({
	selector: 'app-reset-password-confirmation',
	templateUrl: 'reset-password-confirmation.page.html'
})
export class ResetPasswordConfirmationPage implements OnInit {

	constructor(
		private _titleService: Title,
		private _router: Router) { }

	ngOnInit(): void {
		this._titleService.setTitle('CropManage - Reset Password Confirmation');
	}


}
