import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-privacy-policy',
	templateUrl: 'privacy-policy.html',
	styleUrls: [ 'privacy-policy.scss' ]
})
export class PrivacyPolicyComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
	}
}
