import { isNumber } from 'util';

export module BOKA {
	export class NumberUtil {
		public static convertToFloat(n: number, places: number): number {
			let tenFactor = 10;

			if (!n || !places) {
				return 0;
			}

			while (places > 1) {
				tenFactor = tenFactor * 10;
				places -= 1;
			}

			return Math.round(n * tenFactor + Number.EPSILON) / tenFactor;
		}

		public static roundToDecimalPlaces(data: number, places: number): number {
			let tenFactor = 10;

			if (!data || !places) {
				return 0;
			}

			while (places > 1) {
				tenFactor = tenFactor * 10;
				places -= 1;
			}

			return Math.round(data * tenFactor + Number.EPSILON) / tenFactor;
		}

		/**
		 * Truncate a number without rounding
		 *
		 * @param x
		 * @param places
		 */
		public static truncateDecimal(x: number, places = 6): number {
			let result: number;
			let num: string[];

			if (x === null || x === undefined) {
				return null;
			}

			num = x.toString().split('.');

			if (num.length === 2) { // contains decimals
				num[1] = num[1].substring(0, places);
				result = parseFloat(num[0] + '.' + num[1]);
				return result;
			} else {
				return x;
			}
		}
	}
}
