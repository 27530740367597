import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../services/notification.service';
import { eCommodityTypes} from '../../interfaces/constants';
import { CropTypeUpdateService } from './cropType.update.service';
import { IPerennialCanopy } from '../../models/crop-type/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISelectOptions } from '../../interfaces/abstract';
import { PlantingAdvancedEdit } from '../planting-settings/plantingAdvancedEdit';
import { FormComponent } from '../shared/form/form';
import { TranslateService } from '../localization/service';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
	moduleId: module.id,
	selector: 'ap-perennial-canopy',
	templateUrl: 'perennial-canopy.html'
})

export class PerennialCanopyDialog extends FormComponent implements OnInit, OnDestroy {

	public model: IPerennialCanopy;
	public commodityTypeId: number;
	protected _subscriptions$: Subject<boolean>;
	public COMMODITY_TYPES = eCommodityTypes;
	public ages: ISelectOptions[];

	public formErrors = {
		'Age': '',
	};

	public validationMessages = {
		'Age': {
			'required': 'Age is required.',
		},
	};

	public constructor(
		private _dialogRef: MatDialogRef<PerennialCanopyDialog>,
		@Inject(MAT_DIALOG_DATA) private _data: { model: IPerennialCanopy, commodityTypeId: number,
			maxAge: number},
		private _updateService: CropTypeUpdateService,
		private _notificationService: NotificationService,
		private _translateService: TranslateService
	) {
		super();

		this._subscriptions$ = new Subject();
		this._dialogRef.backdropClick().pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			// Close the dialog or show keep/discard
			this._dialogRef.close();
		});
	}

	ngOnInit(): void {
		if (!this._data) {
			throw new Error('data is empty');
		}

		this.model = this._data.model;
		this.commodityTypeId = this._data.commodityTypeId;
		this.ages = PlantingAdvancedEdit.generateAgeOfCropDropdown(this._data.maxAge, this._translateService);
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public save(): void {
		if (this.model.Id) {
			this._updateService.updatePerennialCanopy(this.model);
		} else {
			this._updateService.addPerennialCanopy(this.model);
		}

		this._dialogRef.close();
	}

	public close() {
		this._dialogRef.close();
	}
}
