<div class="bk-dialog bk-dialog__event">

    <button *ngIf="view === dialogViews.UPDATE"
        type="button"
        class="bk-button bk-dialog__close"
        (click)="close()">

        <span>&times;</span>
    </button>

    <h4 mat-dialog-title>
        <ng-container *ngIf="view === dialogViews.DELETE_CONFIRMATION">
            {{ 'Delete' | translate }}
        </ng-container>
        {{ commodityTypeName | translate }}
    </h4>

    <div mat-dialog-content>
        <ng-container *ngIf="view === dialogViews.UPDATE">
            <label>{{ 'Crop Types' | translate }} 
                <info-icon [title]="'Crop Types Planting Count Help Text'"></info-icon>
            </label>
            
            <ng-container *ngFor="let cropType of cropTypes">
                <div class="bk-modal__row bk-modal__row--no-border">
                    <input type="checkbox"
                    class="bk-modal__checkbox"
                    name="cropType"
                    value="cropType"
                    (change)="onCropTypeChange()"
                    [disabled]="cropType.PlantingsCount > 0"
                    [(ngModel)]="cropType.IsSelected" />
                
                    <span>
                        {{cropType.Name | translate }} 
                        <span *ngIf="cropType.PlantingsCount > 0">
                            ({{cropType.PlantingsCount}})
                        </span>
                    </span>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="view === dialogViews.DISCARD_CHANGES">
            <div class="bk-dialog__paragraph">
                {{ 'The changes you made were not saved' | translate}}. {{'Discard changes' | translate}}?
            </div>
        </ng-container>

        <ng-container *ngIf="view === dialogViews.DELETE_CONFIRMATION">
            <div class="bk-dialog__paragraph">
                {{ 'Are you sure you want to delete this commodity type' | translate}}?
            </div>
        </ng-container>
    </div>

    <div mat-dialog-actions align="end">

        <ng-container *ngIf="view === dialogViews.UPDATE">
            <button (click)="view = dialogViews.DELETE_CONFIRMATION"
                mat-button
                [disabled]="plantingsCount > 0"
                class="bk-button bk-button--medium bk-button--text-only
                bk-dialog__button--secondary bk-button--float-left">
                {{'Delete' | translate }}
                <i *ngIf="plantingsCount > 0"
                    matTooltip="{{'Cannot Delete Commodity Type Warning' | translate }}"
                    class="bk-recommendation__icon fa fa-info-circle">
                </i>
            </button>
    
            <button (click)="close()"
                mat-button
                class="bk-button bk-button--medium bk-button--text-only
                bk-dialog__button--secondary">
                {{ 'Cancel' | translate }}
            </button>
    
            <button (click)="save()"
                mat-button
                class="bk-button bk-button--medium bk-dialog__button--primary"
                [disabled]="isFormValid === false">
                {{ 'Save' | translate }}
            </button>
        </ng-container>

        <ng-container *ngIf="view === dialogViews.DISCARD_CHANGES">

            <button (click)="cancelConfirmation()"
                mat-button
                class="bk-button bk-button--medium bk-button--text-only
                bk-dialog__button--secondary">
                {{ 'Keep Editing' | translate }}
            </button>

            <button (click)="discard()"
                mat-button
                class="bk-button bk-button--medium bk-dialog__button--primary">
                {{ 'Discard' | translate }}
            </button>
        </ng-container>

        <ng-container *ngIf="view === dialogViews.DELETE_CONFIRMATION">
            <button (click)="cancelConfirmation()"
                mat-button
                class="bk-button bk-button--medium bk-button--text-only bk-dialog__button--secondary">
                {{ 'Cancel' | translate }}
            </button>

            <button (click)="delete()"
                mat-button
                class="bk-button bk-button--medium bk-dialog__button--primary">
                {{ 'Delete' | translate }}
            </button>
        </ng-container>
    </div>
</div>