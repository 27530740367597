<style>
    agm-map {
        height: 300px;
    }
</style>
<div class="bk-dialog bk-dialog__event">

    <!-- header -->
    <button type="button"
        class="bk-button bk-dialog__close"
        (click)="close()">

        <span>&times;</span>
    </button>

    <h4 *ngIf="!inEditMode"
        mat-dialog-title>

        {{'Create Ranch' | translate }}
    </h4>
    <h4 *ngIf="inEditMode"
        mat-dialog-title>

        {{'Edit' | translate }} {{'Ranch Information' | translate }}
    </h4>

    <!-- body -->

    <div mat-dialog-content>
        <form [formGroup]="form">
            <div class="bk-ranch-dialog-form">
                <div class="left-content-container bk-modal__left">

                    <div *ngIf="form">
                        <div *ngIf="inEditMode"
                            class="bk-form-group">
                            <label class="bk-modal__label">
                                {{'Ranch Owner' | translate }}
                            </label>
                            <div>
                                {{RanchOwnerFullName}}
                            </div>
                        </div>

                        <label class="bk-modal__label"
                            for="ranch-name">
                            {{'Ranch Name' | translate }}
                        </label>
                        <input class="bk-modal__field"
                            id="ranch-name"
                            type="text"
                            formControlName="Name">

                        <div *ngIf="f.Name.invalid && (f.Name.dirty || f.Name.touched)"
                            class="bk-error-text">
                            <div *ngIf="f.Name.errors.required">
                                {{'Ranch name cannot be empty' | translate }}
                            </div>
                        </div>

                        <label class="bk-modal__label"
                            for="coordinates">
                            {{'Coordinates' | translate }}
                        </label>

                        <input class="bk-modal__field bk-modal__field--ranch-coordinates"
                            id="coordinates"
                            type="text"
                            formControlName="Coordinates">

                        <div *ngIf="f.Coordinates.invalid && (f.Coordinates.dirty || f.Coordinates.touched)"
                            class="bk-error-text">
                            <span *ngIf="f.Coordinates.errors.coordinatesTooLong">
                                {{'coordinates should contain 6 or less decimals' | translate }}
                            </span>
                            <span *ngIf="f.Coordinates.errors.coordinatesInvalid">
                                {{'Invalid coordinates' | translate }}
                            </span>
                        </div>

                        <div *ngIf="coordinateExplanation"
                            class="bk-clearfix bk-location-warning">
						    <i class="fa fa-warning"></i>
						    {{ coordinateExplanation }}
                        </div>

                        <label class="bk-modal__label bk-modal__label--capitalize"
                            for="acres">
                            {{'acres' | translate }}
                        </label>

                        <input class="bk-modal__field"
                            id="acres"
                            type="number"
                            step="0.01"
                            formControlName="Acres">

                        <div *ngIf="f.Acres.invalid && (f.Acres.dirty || f.Acres.touched)"
                            class="bk-error-text">
                            <ng-container *ngIf="f.Acres.errors.required">
                                {{ 'Field is required' | translate }}
                            </ng-container>
                            <ng-container *ngIf="f.Acres.errors.min || f.Acres.errors.max">
                                {{'Not a valid number' | translate }}
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="bk-modal__right"
                    [class.bk-modal__shown]="!loadingData">

                    <div *ngIf="!inEditMode"
                        class="bk-modal__right-instructions">
                        {{'Ranch map instructions' | translate }}
                    </div>
                    <div class="bk-modal__right-instructions">
                        <div class="bk-marker bk-marker--ranch">
                            <svg-ranch-marker></svg-ranch-marker>
                        </div>
                        {{'Ranch' | translate }}
                    </div>
                    <div class="bk-modal__map-container map-container">
                        <agm-map (mapClick)="changeCoordinatesMap($event)"
                            [mapTypeId]="'hybrid'"
                            [fullscreenControl]="true">
                            <agm-marker *ngFor="let marker of markers"
                                [iconUrl]="'../assets/images/icons/map_marker-ranch.png'"
                                [latitude]="marker.lat"
                                [longitude]="marker.lng">
                            </agm-marker>
                            <map-control></map-control>
                        </agm-map>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <!-- footer -->

    <div mat-dialog-actions align="end">
        <button *ngIf="inEditMode && permissions && permissions.UserIsRanchOwner"
            class="bk-button bk-button--medium bk-button--text-only bk-button--float-left"  
            (click)="onDeleteConfirmationOpen()">
            {{'Delete' | translate }}
        </button>

        <button class="bk-button bk-button--medium bk-button--text-only"
            (click)="onClose()">
            {{'Cancel' | translate }}
        </button>

        <button *ngIf="!inEditMode"
            [disabled]="!form.valid"
            class="bk-button bk-button--medium" 
            (click)="save()">
            {{(isSaving ? 'Creating' : 'Create') | translate }} {{'Ranch' | translate }}
            <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
        </button>

        <button *ngIf="inEditMode"
            [disabled]="!form.valid"
            class="bk-button bk-button--medium"
            (click)="save()">
            {{ (isSaving ? 'Saving' : 'Save') | translate }}
            <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
        </button>
    </div>
</div>