import { Injectable } from '@angular/core';

import * as moment from 'moment';
import * as fileSaver from 'file-saver';
import { of } from 'rxjs';

import { UpdateService } from '../../services/update.service';
import { HttpService } from '../../services/http.service';

import { Plantings } from '../plantings/plantings';
import { CropType } from '../../models/crop-type/cropType';

import { IRanchesArray, CMError, SuccessResponse, CMErrors } from '../../interfaces/interfaces';
import { IPlanting, ILotPlantingFavoritesJSON, IPlantingsViewModel } from '../planting-settings/interfaces';
import { ILotEdit, IRanchLotModel, RanchLotJSON } from './modals/lot/interfaces';
import { IWell } from '../../models/planting-well/well.interface';
import { IRanch, IRanchBasicInfoViewModel, IRanchSettingsValidationStates, IRanchFavoritesPostResponse,
	ISubjectFavoriteResponse, IRanchLotEditJSON, IRanchLotJSONModel, IRanchUserListItem,
	ISuccessResponse,
	IUserSearchResult, IRanchService, IUserRanchesViewModel, IRanchFavoritesJSON, IRanchJSON,
	IRanchLotUpdateModel, IRanchWellViewModel, RanchExistsModel, RanchUpdateParams,
	RanchUpdateCIMISParams, RanchCreateResponse, RanchLotInternalUpdateModel } from './interfaces';

import { eCommodityTypes } from '../../interfaces/constants';
import { Planting } from '../planting-settings/planting';
import { IIdNamePair } from '../../interfaces/abstract';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TokenService } from '../../services/token.service';
import { Token } from '../../services/interface';
import { environment } from '../../../environments/environment';
import { ReleaseVersion } from '../../services/constants';
import { ISortable } from '../../classes/array';

export class RanchFavoritesModel {
	public Id: number;
	public Ranch: RanchModel;
	public RanchId: number;
	public UserID: string;
}

export class RanchModel {
	public ActivePlantings: number;
	public CreatedByFullName: string;
	public Id: number;
	public Name: string;
	public RanchAccessState: number;
	public Ranch_External_GUID: string;
	public UserAccessState: number;
}

@Injectable()
export class RanchService implements IRanchService {

	private urls = {
		Ranch: {
			create: `/v3/ranches.json`,
			get: (ranchGuid: string) => `/v2/ranches/${ranchGuid}.json`,
			basicFormAdminOnly: (ranchGuid: string) => `/v0/ranches/${ranchGuid}.json`,
			update: (ranchGuid: string) => `/v3/ranches/${ranchGuid}.json`,
			updateCIMISOptions: (ranchGuid: string) => `/v3/ranches/${ranchGuid}/cimis-options.json`,
			delete: (ranchGuid: string) => `/v2/ranches/${ranchGuid}.json`,
			excelOutput: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/excel.json`,
			excelYears: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/years.json`,
			missingSettings: (ranchGUID: string) => `/v2/ranches/${ranchGUID}/missing-settings.json`,
			exists: `/v2/ranches/exists.json`,

			user: {
				list: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/users.json`,
				search:  (ranchGuid: string) => `/v2/ranches/${ranchGuid}/users/search.json`,
				create: (ranchGuid: string, userId: string) => `/v2/ranches/${ranchGuid}/users/${userId}.json`,
				delete: (ranchGuid: string, userIdTarget: string) => `/v2/ranches/${ranchGuid}/users/${userIdTarget}.json`,
				deleteAdminOnly: (ranchGuid: string, userIdTarget: string) => `/v0/ranches/${ranchGuid}/users/${userIdTarget}.json`,
			},
			lots: {
				list: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/lots/extended.json`,
				createForm: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/lots/form.json`,
				create: (ranchGuid: string) => `/v0/ranches/${ranchGuid}/lots.json`,
				show: (lotId: number) => `/v2/lots/${lotId}.json`,
				update: (lotId: number) => `/v0/lots/${lotId}.json`,
				delete: (lotId: number) => `/v2/lots/${lotId}.json`,
			},
			well: {
				list: (id: string) => `/v2/ranches/${id}/ranch-wells.json`,
				update: (ranchGuid: string, wellId: number) => `/v2/ranches/${ranchGuid}/wells/${wellId}.json`,
				editForm: (wellId: number) => `/v2/wells/${wellId}.json`,
				create: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/wells.json`,
				delete: (ranchGuid: string, wellId: number) => `/v2/ranches/${ranchGuid}/wells/${wellId}.json`
			},
		},
		Dashboard: {
			ranchesForCurrentUser: '/v2/ranches.json',
			getPlantings: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/plantings.json`,
		},
		RanchFavorite: {
			list: '/v2/ranch-favorites.json',
			save: (ranchId: number) => `/v2/ranches/${ranchId}/favorites.json`,
			delete: (favoriteId: number) => `/v2/ranch-favorites/${favoriteId}.json`,
		},
		LotPlantingFavorites: {
			get: (ranchGUID: string) => `/v2/ranches/${ranchGUID}/plantings/favorites.json`,
			save: (lotPlantingId: number) => `/v2/plantings/${lotPlantingId}/favorites.json`,
			delete: (favoriteId: number) => `/v2/planting-favorites/${favoriteId}.json`,
		},
		RanchLot: {
			list: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/lots.json`,
			listMinimal: (ranchGuid: string) => `/v2/ranches/${ranchGuid}/lots/minimal.json`,
			isUsed: (ranchGuid: string, ranchLotId: number) => `/v2/ranches/${ranchGuid}/lots/${ranchLotId}/is-used.json`,
		},
	};

	public ranches: IRanchesArray = { // storing a cache of ranches at a global level to prevent resetting data
		myRanches: new Array() as IRanch[],
		favorites: new Array() as IRanch[],
		public: new Array() as IRanch[],
		loaded: {
			myRanches: false,
			favorites: false,
			public: false
		}
	};

	public wells: IWell[]; // global cache

	public plantings = new Plantings();

	public currentRanchLots: IRanchLotJSONModel[] = new Array();

	constructor(private updateService: UpdateService,
		private httpService: HttpService,
		private _tokenService: TokenService,
		private http: HttpClient) {
	}

	public getMyRanches(): Promise<IRanch[]> {
		let promises: Promise<IRanch[]>[] = new Array();

		if (this.ranches.myRanches.length > 0) {
			return of(this.ranches.myRanches).toPromise();
		}

		promises.push(this._getRanchesForCurrentUser());
		promises.push(this.getFavoriteRanches()); // need this to mark favorites

		return Promise.all(promises)
			.then((response) => this._combineWithFavoritesData(response[0], response[1]));

	}

	/**
	 * Clear ranches cache - called when logging out
	 */
	public clearRanchesCache(): void {
		this.ranches =  {
			myRanches: new Array(),
			favorites: new Array(),
			public: new Array(),
			loaded: {
				myRanches: false,
				favorites: false,
				public: false
			}
		};
	}

	private _getRanchesForCurrentUser(): Promise<IRanch[]> {

		if (this.ranches.loaded.myRanches === true) {
			return of(this.ranches.myRanches).toPromise();
		}

		return this.httpService.get({
			url: this.urls.Dashboard.ranchesForCurrentUser,
			searchParams: null,
			isWebAPI: true,

			callback: (json: IUserRanchesViewModel[]): IRanch[] => {
				this.ranches.loaded.myRanches = true;

				if (json) {
					return json;
				} else {
					return new Array();
				}
			},
		});
	}

	public sortRanchesByName(ar: ISortable[], callback?: Function) {

		ar.sort(function (a: ISortable, b: ISortable) {
			if (callback) {
				return callback(a.Name, b.Name);
			} else {
				return a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 0;
			}
		});

		return ar;
	}

	// Add ranch to the favorites ranch list, so we can avoid fetching data
	// @ranchFavorite - a translation of ranchFavorite JSON, received from /save call, containing ranch data
	public addRanchToFavoritesList(ranchFavorite: ISubjectFavoriteResponse): void {

		if (!ranchFavorite || !ranchFavorite.ranch) {
			return;
		}

		ranchFavorite.ranch.Favorite = true;
		ranchFavorite.ranch.FavoriteId = ranchFavorite.favoriteId;

		this.ranches.favorites.push(ranchFavorite.ranch);
		this.sortRanchesByName(this.ranches.favorites);
	}

	public getFavoriteRanches(override = false): Promise<IRanch[]> {

		if (this.ranches.loaded.favorites === true && override === false) {
			return of(this.ranches.favorites).toPromise();
			// return new Observable().toPromise().then(() => (this.ranches.favorites));
		}

		return this.httpService.get({
			url: this.urls.RanchFavorite.list,
			searchParams: null,
			isWebAPI: true,

			callback: (ranchFavorites: IRanchFavoritesJSON[]): IRanch[] => {
				let result: IRanch[];

				result = [];

				this.ranches.loaded.favorites = true;

				if (!ranchFavorites || ranchFavorites.length === 0) {
					return new Array();
				}

				for (let ranchFavorite of ranchFavorites) {
					let item: IRanch;

					item = {
						Id: ranchFavorite.RanchId,
						Name: ranchFavorite.Ranch.Name,
						Favorite: true,
						FavoriteId: ranchFavorite.Id,
						ActivePlantings: ranchFavorite.Ranch.ActivePlantings,
						CreatedByFullName: ranchFavorite.Ranch.CreatedByFullName,
						RanchAccessState: ranchFavorite.Ranch.RanchAccessState,
						Ranch_External_GUID: ranchFavorite.Ranch.Ranch_External_GUID,
						UserAccessState: ranchFavorite.Ranch.UserAccessState,
						RanchOwnerFullName: ranchFavorite.Ranch.RanchOwnerFullName
					};

					result.push(item);
				}

				this.ranches.favorites = result;

				return result;
			}
		});
	}

	public getMissingSettings(ranchGUID: string): Promise<IRanchSettingsValidationStates> {

		return this.httpService.get({
			url: this.urls.Ranch.missingSettings(ranchGUID),
			callback: (r: IRanchSettingsValidationStates) => {
				return r;
			},
			isWebAPI: true,
		})
	}

	public exists(ranchGUID: string): Promise<RanchExistsModel> {

		let params: HttpParams;

		params = new HttpParams().set('ranchGuid', ranchGUID);

		return this.httpService.get({
			url: this.urls.Ranch.exists,
			searchParams: params,
			callback: (r: RanchExistsModel): RanchExistsModel => {
				return r;
			},
			isWebAPI: true
		})
	}

	public getExcelYears(ranchGUID: string): Promise<number[]> {

		return this.httpService.get({
			url: this.urls.Ranch.excelYears(ranchGUID),
			isWebAPI: true,
			callback: (r: number[]) => {
				return r;
			}
		})
	}

	private _combineWithFavoritesData(ranches: IRanch[], favorites: IRanch[]): IRanch[] {

		if (!ranches || ranches.length === 0) {
			return new Array();
		}

		for (let ranch of ranches) {
			let favorite: IRanch;

			favorite = favorites.find(x => x.Id === ranch.Id);

			if (!favorite) {
				ranch.Favorite = false;
				ranch.FavoriteId = null;
			} else {
				ranch.Favorite = true;
				ranch.FavoriteId = favorite.FavoriteId;
			}
		}

		return ranches;
	}

	public addRanchToFavorites(ranchId: number): Promise<IRanchFavoritesPostResponse> {

		return this.httpService
			.post({
				url: this.urls.RanchFavorite.save(ranchId),
				body: null,
				isWebAPI: true
			});
	}

	public removeRanchFromFavorites(favoriteId: number): Promise<CMError> {
		return this.httpService
			.delete({
				url: this.urls.RanchFavorite.delete(favoriteId),
				isWebAPI: true,
			});
	}

	public getActivePlantings(id: string): Promise<IPlanting[]> {

		return this.httpService.get({
			url: this.urls.Dashboard.getPlantings(id),
			callback: (plantings: IPlantingsViewModel[]) => {
				if (!plantings || !plantings.length) {
					return new Array();
				}

				return this.processPlantings(plantings);
			},
			isWebAPI: true
		});
	}

	public getActivePlantingsWithFavorites(id: string, override = false): Promise<IPlanting[]> {

		let promises: Promise<IPlanting[]>[];

		if (this.plantings.active && this.plantings.active.length > 0 && override === false) {
			return of(this.plantings.active).toPromise();
		}

		promises = new Array();
		promises.push(this.getActivePlantings(id));
		promises.push(this.getFavoritePlantings(id, true));

		return Promise.all(promises)
			.then((response: IPlanting[][]) => this.combineWithFavoritePlantings(response[0], response[1]));
	}

	private processPlantings(plantings: IPlantingsViewModel[]): IPlanting[] {
		let result: IPlanting[];

		result = new Array();

		if (!plantings || plantings.length === 0) {
			return new Array();
		}

		for (let planting of plantings) {
			let item: IPlanting;

			try {
				item = Planting.convertPlantingsViewModel(planting);
			} catch (error) {
				// suppress errors
			}


			result.push(item);
		}

		return result;
	}

	public getAllPlantings(ranchGuid: string): Promise<IPlanting[]> {
		let params: HttpParams;

		params = new HttpParams().set('active', 'false');

		return this.httpService.get({
			url: this.urls.Dashboard.getPlantings(ranchGuid),
			searchParams: params,
			isWebAPI: true,
			callback: (data: IPlantingsViewModel[]) => {
				return this.processPlantings(data);
			}
		});
	}

	/**
     *
     * @param id
     * @param override
     */
	public getAllPlantingsWithFavorites(id: string, override = false): Promise<IPlanting[]> {

		let promises: Promise<IPlanting[]>[];

		if (this.plantings.all && this.plantings.all.length > 0 && override === false) {
			return of(this.plantings.all).toPromise();
		}

		promises = new Array();
		promises.push(this.getAllPlantings(id));
		promises.push(this.getFavoritePlantings(id, false));

		return Promise.all(promises)
			.then((response: IPlanting[][]) => this.combineWithFavoritePlantings(response[0], response[1]));
	}

	/*
     *
     * ++++++++++ FAVORITE PLANTINGS STUFF ++++++++++++
     *
     */

	public getFavoritePlantings(id: string, override?: boolean): Promise<IPlanting[]> {

		if (override === false) {
			if (this.plantings.favorites && this.plantings.favorites.length > 0) {
				return of(this.plantings.favorites).toPromise();
			}
		}

		return this.httpService.get({
			url: this.urls.LotPlantingFavorites.get(id),
			callback: (favorites: ILotPlantingFavoritesJSON[]): IPlanting[] => {
				let result: IPlanting[];

				result = new Array();

				for (let favorite of favorites) {
					let item: IPlanting;

					item = Planting.convertPlantingJSON(favorite.LotPlanting);
					item.Favorite = true;
					item.FavoriteId = favorite.Id;
					item.Id = favorite.LotPlantingId;

					result.push(item);
				}

				return result;
			},
			isWebAPI: true
		});
	}

	public findRanchByGuid(ranches: IRanch[], guid: string): IRanch {

		if (!ranches) {
			return null;
		}

		for (let ranch of ranches) {
			if (ranch.Ranch_External_GUID === guid) {
				return ranch;
			}
		}

		return null;
	}

	private combineWithFavoritePlantings(plantings: IPlanting[], favorites: IPlanting[]): IPlanting[] {

		if (!plantings) {
			return new Array();
		}

		for (let planting of plantings) {
			planting.Favorite = false;
			planting.FavoriteId = null;

			if (!favorites || favorites.length === 0) {
				continue;
			}

			for (let favorite of favorites) {
				if (favorite.Id === planting.Id) {
					planting.Favorite = true;
					planting.FavoriteId = favorite.FavoriteId;
					break;
				}
			}
		}
		return plantings;
	}

	public addPlantingToFavorties(id: number): Promise<ILotPlantingFavoritesJSON> {
		let params = {
			lotPlantingId: id
		}
		return this.httpService
			.post({
				url: this.urls.LotPlantingFavorites.save(id),
				body: params,
				isWebAPI: true
			});
	}

	public removePlantingFromFavorites(id: number): Promise<void> {

		return this.httpService
			.delete({
				url: this.urls.LotPlantingFavorites.delete(id),
				isWebAPI: true,
				callback: () => { return; }
			});
	}

	/**
	 * update currently active ranch's active planting count
	 */
	public updateActivePlantingCount(): void {
		let ranch: IRanch;

		if (!this.ranches || !this.ranches.myRanches || this.ranches.myRanches.length === 0) {
			return;
		}

		ranch = this.ranches.myRanches.find(x => x.Ranch_External_GUID ===
			this.updateService.currentRanchId);

		if (!ranch) {
			return;
		}

		ranch.ActivePlantings = this.plantings.active.length;
	}
	/*
     * +++ END FAVORITE PLANTINGS STUFF +++
     */


	public getCreateRanchData(): IRanchBasicInfoViewModel {
		let result: IRanchBasicInfoViewModel;

		result = {
			RanchName: null,
			Acres: null,
			Coordinates: null,
			RanchOwner: null,
			RanchOwnerFullName: null,
			RanchOwnerEmail: null,
			UseSpatialCIMIS: null,
			UseCIMISAverageMode: null,
			IsPrivate: true,
			SpatialETAvailable: true,
			WeatherAPI_Id: null
		};

		return result;
	}

	public createRanch(data: RanchUpdateParams): Promise<RanchCreateResponse> {
		return this.httpService
			.post({
				url: this.urls.Ranch.create,
				body: data,
				isWebAPI: true,
			});
	}

	public deleteRanch(ranchId: string): Promise<SuccessResponse> {
		if (!ranchId) {
			return of(null).toPromise();
		}

		return this.httpService
			.delete({
				url: this.urls.Ranch.delete(ranchId),
				isWebAPI: true,

				callback: (json: SuccessResponse): SuccessResponse => {
					if (!json) {
						return null;
					}

					this.removeFromRanches(ranchId);

					return json;
				}
			});
	}

	public removeFromRanches(ranchId: string): void {
		if (!this.ranches) {
			return;
		}

		if (this.ranches.myRanches && this.ranches.myRanches.length > 0) {
			this.ranches.myRanches = this.ranches.myRanches.filter(x => x.Ranch_External_GUID !== ranchId);
		}

		if (this.ranches.favorites && this.ranches.favorites.length > 0) {
			this.ranches.favorites = this.ranches.favorites.filter(x => x.Ranch_External_GUID !== ranchId);
		}

		if (this.ranches.public && this.ranches.public.length > 0) {
			this.ranches.public = this.ranches.public.filter(x => x.Ranch_External_GUID !== ranchId);
		}
	}

	/**
	 *
	 * @param ranchGuid
	 * @param shouldPermissionProtect This field is used in the admin section
	 * @returns
	 */
	public getRanchBasicInfo(ranchGuid?: string, shouldPermissionProtect = true): Promise<IRanchBasicInfoViewModel> {//

		ranchGuid = ranchGuid ? ranchGuid : this.updateService.currentRanchId;

		return this.httpService.get({
			url: shouldPermissionProtect ? this.urls.Ranch.get(ranchGuid) :
				this.urls.Ranch.basicFormAdminOnly(ranchGuid),
			isWebAPI: true,

			callback: function (json: IRanchBasicInfoViewModel) {
				if (json) {
					return json;
				} else {
					return {};
				}
			}
		});
	}

	public updateCIMISOptions(p: RanchUpdateCIMISParams): Promise<void> {
		return this.httpService
		.put({
			url: this.urls.Ranch.updateCIMISOptions(this.updateService.currentRanchId),
			body: p,
			isWebAPI: true
		});
	}

	public editRanchBasicInfo(basicInfo: RanchUpdateParams): Promise<IRanchJSON> {
		return this.httpService
			.put({
				url: this.urls.Ranch.update(this.updateService.currentRanchId),
				body: basicInfo,
				isWebAPI: true
			});
	}

	public getRanchUsers(ranchGuid?: string): Promise<IRanchUserListItem[]> {
		ranchGuid = ranchGuid ? ranchGuid : this.updateService.currentRanchId;

		return this.httpService.get({
			url: this.urls.Ranch.user.list(ranchGuid),
			isWebAPI: true,

			callback: function (json: { data: IRanchUserListItem[] }) {
				if (json) {
					return json;
				} else {
					return new Array();
				}
			}
		});
	}


	public getRanchLots(): Promise<IRanchLotModel[]> {

		return this.httpService.get({
			url: this.urls.Ranch.lots.list(this.updateService.currentRanchId),
			isWebAPI: true,

			callback: function (json: IRanchLotModel[]) {
				if (json) {
					return json;
				} else {
					return new Array();
				}
			}
		});
	}

	public getRanchWells(): Promise<IWell[]> {

		return this.httpService.get({
			url: this.urls.Ranch.well.list(this.updateService.currentRanchId),
			isWebAPI: true,

			callback: (json: IWell[]) => {
				if (json) {
					this.wells = json;
					return this.wells;
				} else {
					return new Array();
				}
			}
		});
	}

	/**
	 * This method returns all the users in the system instead of actually searching...
	 */
	public getAddRanchMembersList(ranchGuid?: string): Promise<IUserSearchResult[]> {
		ranchGuid = ranchGuid ? ranchGuid : this.updateService.currentRanchId;

		return this.httpService
			.get({
				url: this.urls.Ranch.user.search(ranchGuid),
				isWebAPI: true,
				callback: (response: IUserSearchResult[]) => {
					return response
				}
			});
	}

	public addRanchMember(id: string, ranchGuid?: string): Promise<void> {
		ranchGuid = ranchGuid ? ranchGuid : this.updateService.currentRanchId;

		return this.httpService
			.post({
				url: this.urls.Ranch.user.create(ranchGuid, id),
				isWebAPI: true,
				body: null
			});
	}

	public deleteRanchUser(userId: string, ranchGuid?: string, shouldPermissionProtect = true): Promise<ISuccessResponse | CMError> {
		ranchGuid = ranchGuid ? ranchGuid : this.updateService.currentRanchId;

		return this.httpService
			.delete({
				url: shouldPermissionProtect ? this.urls.Ranch.user.delete(ranchGuid, userId) :
					this.urls.Ranch.user.deleteAdminOnly(ranchGuid, userId),
				isWebAPI: true,
				callback: (response: ISuccessResponse | CMError) => { return response },
				shouldBypassServerErrorAlert: true
			});
	}

	public getAddRanchLotData(): Promise<ILotEdit> {

		return this.httpService.get({
			url: this.urls.Ranch.lots.createForm(this.updateService.currentRanchId),
			isWebAPI: true,
			callback: (json: IRanchLotEditJSON) => {
				let result: ILotEdit;

				if (!json) {
					return null;
				}

				result = {
					Name: null,
					Acres: 0,
					Coordinates: json.Coordinates,
					ObstructionDepth: null,
					SoilLayers: [
						{
							Depth: 1,
							Sand: null,
							Silt: null,
							Organic: null,
							Density: null,
							Tension: null,
							Mineralization: null,
						},
						{
							Depth: 2,
							Sand: null,
							Silt: null,
							Organic: null,
							Density: null,
							Tension: null,
							Mineralization: null,
						},
						{
							Depth: 3,
							Sand: null,
							Silt: null,
							Organic: null,
							Density: null,
							Tension: null,
							Mineralization: null,
						},
						{
							Depth: 4,
							Sand: null,
							Silt: null,
							Organic: null,
							Density: null,
							Tension: null,
							Mineralization: null,
						},
					]
				}

				return result;
			}
		});
	}

	public getEditRanchLotData(id: number): Promise<ILotEdit> {

		return this.httpService.get({
			url: this.urls.Ranch.lots.show(id),
			isWebAPI: true,
			callback: (json: IRanchLotEditJSON) => {
				let result: ILotEdit;

				if (!json) {
					return null;
				}
				let data = json;

				result = {
					Name: data.Model.RanchLotViewModel.Name,
					Acres: data.Model.RanchLotViewModel.Acres,
					Coordinates: data.Model.RanchLotViewModel.Coordinates,
					ObstructionDepth: data.Model.RanchLotViewModel.ObstructionDepth,
					SoilLayers: data.Model.SoilLayers,
					SoilWebProperties: data.SoilWebProperties
				};

				return result;
			}
		});
	}

	public addRanchLot(lot: ILotEdit): Promise<IRanchLotUpdateModel> {

		return this.httpService
			.post({
				url: this.urls.Ranch.lots.create(this.updateService.currentRanchId),
				body: lot,
				isWebAPI: true,
				callback: (response: IRanchLotUpdateModel | CMError): IRanchLotUpdateModel => {
					if (!response) {
						throw new Error('response is empty');
					}

					let error: CMError;

					error = response as CMError;

					if (error.message) {
						throw new Error(error.message);
					} else {
						return response as IRanchLotUpdateModel;
					}
				}
			});
	}

	public editRanchLot(lot: ILotEdit): Promise<IRanchLotUpdateModel> {

		let body: RanchLotInternalUpdateModel;

		body = {
			Name: lot.Name,
			Acres: lot.Acres,
			Coordinates: lot.Coordinates,
			ObstructionDepth: lot.ObstructionDepth,
			SoilLayers: lot.SoilLayers
		}

		if (!body.SoilLayers) {
			throw new Error('SoilLayers is null');
		}

		return this.httpService
			.put({
				url: this.urls.Ranch.lots.update(lot.Id),
				body: body,
				isWebAPI: true,

				callback: (response: IRanchLotUpdateModel | CMError): IRanchLotUpdateModel => {
					if (!response) {
						throw new Error('response is empty');
					}

					let error: CMError;

					error = response as CMError;

					if (error.message) {
						throw new Error(error.message);
					} else {
						return response as IRanchLotUpdateModel;
					}
				}
			});
	}

	public deleteRanchLot(lotId: number): Promise<Object> {
		return this.httpService
			.delete({
				url: this.urls.Ranch.lots.delete(lotId),
				isWebAPI: true,
				callback: (response: ISuccessResponse) => { return response }
			});
	}

	public getRanchWellEditData(id: number): Promise<IRanchWellViewModel> {

		return this.httpService.get({
			url: this.urls.Ranch.well.editForm(id),
			isWebAPI: true,

			callback: function (json: IRanchWellViewModel): IRanchWellViewModel {
				if (json) {
					return json;
				} else {
					return null;
				}
			}
		});
	}

	public addRanchWell(well: IRanchWellViewModel): Promise<void> {
		return this.httpService
			.post({
				url: this.urls.Ranch.well.create(this.updateService.currentRanchId),
				body: well,
				isWebAPI: true,
				callback: (): void => { return; }
			});
	}

	public editRanchWell(id: number, well: IRanchWellViewModel): Promise<void> {
		return this.httpService
			.put({
				url: this.urls.Ranch.well.update(this.updateService.currentRanchId, id),
				body: well,
				isWebAPI: true,
				callback: (): void => { return; }
			});
	}

	public deleteRanchWell(id: number): Promise<void> {
		return this.httpService
			.delete({
				url: this.urls.Ranch.well.delete(this.updateService.currentRanchId, id),
				isWebAPI: true,
				callback: (): void => { return; }
			});
	}

	public getBasicRanchLots(id: string): Promise<IRanchLotJSONModel[]> {

		return this.httpService.get({
			url: this.urls.RanchLot.list(id),
			callback: (json: IRanchLotJSONModel[]) => {
				if (json) {
					this.currentRanchLots = json;
					return json;
				} else {
					return {};
				}
			},
			isWebAPI: true
		});
	}

	public getLotsMinimal(ranchGuid: string, year?: number): Promise<IIdNamePair[]> {
		let params: HttpParams;

		params = new HttpParams();

		if (year) {
			params = new HttpParams().set('year', year.toString());
		}

		return this.httpService.get({
			url: this.urls.RanchLot.listMinimal(ranchGuid),
			searchParams: params,
			isWebAPI: true,
			callback: function (json: IIdNamePair[]) {
				if (json) {
					return json;
				} else {
					return Array();
				}
			}
		});
	}

	public isUsed(ranchLotId: number, ranchGuid: string): Promise<boolean> {

		if (!ranchLotId) {
			throw new Error('ranchLotId can\'t be empty');
		}

		return this.httpService.get({
			url: this.urls.RanchLot.isUsed(ranchGuid, ranchLotId),
			isWebAPI: true,

			callback: function (json: boolean) {
				return json;
			}
		});
	}

	public exportDataToExcel(name: string, year?: number, lotId?: number): Promise<boolean> {
		let headers: HttpHeaders;
		let token: Token;

		let body: {
			Year: number,
			LotId: number,
		};

		body = {
			Year: year,
			LotId: lotId,
		};

		token = this._tokenService.getToken();

		headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Accept': 'applicaton/zip',
			'Authorization': 'Bearer ' + token.token,
			'X-Client-Version': ReleaseVersion
		});

		return this.http
			.post(environment.APIEndpoint + this.urls.Ranch.excelOutput(this.updateService.currentRanchId),
				body,
				{
					headers: headers,
					responseType: 'blob'
				})
			.toPromise()
			.then(response => {
				return this._handleExportDataToExcelResponse(response, name);
			});
	}

	private _handleExportDataToExcelResponse(response: Blob, name: string): boolean {
		let fileBlob, blob: Blob;
		let now: moment.Moment;
		let filename: string;

		if (this.updateService.cancelRanchDataExport) {
			this.updateService.cancelRanchDataExport = false;
			return false;
		}

		fileBlob = response;

		blob = new Blob([fileBlob], {
			type: 'application/zip'
		})

		now = moment();

		filename = 'RanchExport- ' + name + ' ' + (now.month() + 1) + '_' +
			now.date() + '_' + now.year() + '_' + now.hour() + '_' + now.minute() + '.zip';

		fileSaver.saveAs(blob, filename);

		return true;
	}

	/*
     * Commodities available
     */

	public getIsNDependent(plantingId: number): boolean {
		let planting: IPlanting;

		planting = this.plantings.getPlantingById(plantingId);

		if (!planting) {
			return true; // default to true
		}

		if (planting.CropType) {
			return planting.CropType.IsNDependent;
		} else if (planting.IsNDependent === undefined) {
			throw new Error('planting.IsNDependent is undefined');
		} else {
			return planting.IsNDependent;
		}
	}

	public getCalculatorInterface(plantingId: number): string {
		let planting: IPlanting;

		planting = this.plantings.getPlantingById(plantingId);

		if (!planting) {
			return null;
		}

		return planting.CommodityTypeCalculator;
	}

	public getGerminationDate(plantingId: number): Date {
		let planting: IPlanting;

		planting = this.plantings.getPlantingById(plantingId);

		if (!planting) {
			return null;
		}

		return planting.WetDate;
	}

	public getHarvestDate(plantingId: number): Date {
		let planting: IPlanting;

		planting = this.plantings.getPlantingById(plantingId);

		if (!planting) {
			return null;
		}

		return planting.HarvestDate;
	}

	public isPlantingPerennial(plantingId: number): boolean {
		let planting: IPlanting;

		planting = this.plantings.getPlantingById(plantingId);

		if (!planting) {

			return;
		}

		return CropType.isPerennialPlanting(planting.CommodityTypeId);
	}

	public plantingHasFloodEvents(plantingId: number): boolean {
		let planting: IPlanting;
		let commodityType: eCommodityTypes;

		planting = this.plantings.getPlantingById(plantingId);

		if (!planting) {
			return null;
		}

		commodityType = planting.CommodityTypeId;

		return CropType.hasFloodEvents(commodityType);
	}
}
