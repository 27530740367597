import { Component } from '@angular/core';

import { FertilizationRecommendedSummaryViewModel, StrawberryFertilizationRecommendedSummaryViewModel, IFertilizationEvent,
	AlmondRecommendationSummaryModel,
	LettuceFertilizationRecommendedSummaryViewModel,
	TomatoFertilizationRecommendedSummaryViewModel} from '../../../fertilization-event/interfaces';

import { IGeneratedEquation, LettuceEquationGenerator } from './equation-generators/lettuceEquationGenerator';
import { StrawberryEquationGenerator } from './equation-generators/strawberryEquationGenerator';
import { TomatoEquationGenerator } from './equation-generators/tomatoEquationGenerator';
import { TreeEquationGenerator } from './equation-generators/treeEquationGenerator';
import { EquationGenerator } from './equation-generators/equationGenerator';
import { TranslateService } from '../../../localization/service';

@Component({
	moduleId: module.id,
	selector: 'fertilizer-recommendation',
	template: ``
})

export class FertilizationRecommendationComponent {
	/**
	 * Used to display detailed information inside the hover panel template
	 */
	public recommendationSummary: FertilizationRecommendedSummaryViewModel | StrawberryFertilizationRecommendedSummaryViewModel
		| AlmondRecommendationSummaryModel;

	public equationDescriptions: string[];
	public equations: string[];

	constructor(
		private _translateservice: TranslateService
	) {
		this.equationDescriptions = [];
		this.equations = [];
	}

	/**
     * Fertilization event popup calls this function directly. The component is instantiated as a child component
	 * using ViewChild
     *
     * This is a top level function.
     *
     * @param recommendationDetails
     */
	public render(recommendationDetails: FertilizationRecommendedSummaryViewModel |
		StrawberryFertilizationRecommendedSummaryViewModel
		| AlmondRecommendationSummaryModel): void {

		let lettuce: LettuceFertilizationRecommendedSummaryViewModel;
		let strawberry: StrawberryFertilizationRecommendedSummaryViewModel;
		let trees: AlmondRecommendationSummaryModel;
		let tomato: TomatoFertilizationRecommendedSummaryViewModel;
		let generator: EquationGenerator;
		let generatedEquations: IGeneratedEquation;

		if (!recommendationDetails) {
			throw new Error('recommendation details are missing');
		}

		lettuce = recommendationDetails as LettuceFertilizationRecommendedSummaryViewModel;
		strawberry = recommendationDetails as StrawberryFertilizationRecommendedSummaryViewModel;
		trees = recommendationDetails as AlmondRecommendationSummaryModel;
		tomato = recommendationDetails as TomatoFertilizationRecommendedSummaryViewModel;

		this.recommendationSummary = recommendationDetails;

		if (strawberry.StrawberryFertilizationInterval) {
			if (strawberry.FertilizationsPerMonth > 0) {
				generator = new StrawberryEquationGenerator(strawberry, this._translateservice);
			} else {
				generator = new TomatoEquationGenerator(tomato, this._translateservice);
			}
		} else if (trees.NSoilAmendments !== undefined) {
			generator = new TreeEquationGenerator(trees, this._translateservice);
		} else {
			generator = new LettuceEquationGenerator(lettuce, this._translateservice);
		}

		generatedEquations = generator.getEquation();

		this.equationDescriptions = generatedEquations.equationDescriptions;
		this.equations = generatedEquations.equations;
	}

	public clearFertilizerRecommendationSummary(): void {
		this.recommendationSummary = null;
		this.equationDescriptions = []
		this.equations = [];
	}
}
