import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorService } from '../services/error.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

	constructor(private _injector: Injector) { }

	handleError(error: Error) {
		let errorService: ErrorService;

		errorService = this._injector.get(ErrorService);
		errorService.handleError(error);
	}
}
