import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { Title } from '@angular/platform-browser';
import { CMError } from '../../../interfaces/interfaces';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-confirm-email',
	templateUrl: 'confirm-email.page.html'
})
export class ConfirmEmailPage implements OnInit, OnDestroy {

	public userId: string;
	public code: string;
	private _subscriptions$: Subject<boolean>;

	constructor(
		private _loginService: LoginService,
		private _titleService: Title,
		private _route: ActivatedRoute) { }

	ngOnInit(): void {
		this._titleService.setTitle('CropManage | Please Confirm Email');
		this._subscriptions$ = new Subject();

		this._route.queryParams.pipe(takeUntil(this._subscriptions$)).subscribe((params) => {
			if (!params) {
				return;
			}

			this.userId = params['userId'];
			this.code = params['code'];

			this._loginService.confirmEmail(this.userId, this.code).then((response) => {
				// if error, display error
				let error: CMError;

				error = response as CMError;

				if (error && error.message) {
					confirm(error.message);
					// display error to the user
				}
			});
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}
}
