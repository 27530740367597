import {
	IPlanting, IPlantingJSON,
	IPlantingsViewModel, IPlantingSettingsCalculatorSettings
} from './interfaces';
import { ICropType, ICropTypeViewModel } from '../../models/crop-type/interfaces';
import { IRanchLot } from '../ranch-settings/modals/lot/interfaces';
import { eCalculators } from '../../interfaces/constants';
import { EventGroup } from '../../models/event/event';
import { DateUtility } from '../../classes/dateUtility';
import { eCommodityTypeCalculators, ICommodityType } from '../../models/commodity-type/interfaces';
import { IRawEvent } from '../../models/event/interfaces';
import { PersistentDatabase } from '../../services/persistent-database';

export class Planting implements IPlanting {

	public Id: number;
	public Name: string;
	public CommodityTypeId: number;
	public CropType: ICropType;
	public Events: EventGroup[];
	public HarvestDate: Date;
	public WetDate: Date;
	public RanchLot: IRanchLot;
	public HasFlowMeterFileName: boolean;
	public HasSoilMoistureFileName: boolean;
	public CommodityTypeCalculator: string;

	// virtual

	public collapsed?: boolean;
	public displayMenu?: boolean; // RENAME
	public Favorite?: boolean;
	public FavoriteId?: number;

	/**
     * picks the "calculator" for fertilizer. Currently, the code is confusing
     * We should use calculator external ID here instead
     * or label "calculator" fertilization calculator
     *
     * @param calculatorInterface
     */
	public static getCalculator(calculatorInterface: string): number {

		let result: number;

		if (!calculatorInterface) {
			return null;
		}

		switch (calculatorInterface) {
			case eCommodityTypeCalculators.processingTomato:
				result = eCalculators.TOMATO;
				break;
			case eCommodityTypeCalculators.strawberry:
			case eCommodityTypeCalculators.caneberry:
				result = eCalculators.STRAWBERRY;
				break;
			case eCommodityTypeCalculators.almond:
				result = eCalculators.ALMOND;
				break;
			case eCommodityTypeCalculators.alfalfa:
				result = eCalculators.ALFALFA;
				break;
			case eCommodityTypeCalculators.pistachio:
				result = eCalculators.PISTACHIO;
				break;
			case eCommodityTypeCalculators.walnut:
				result = eCalculators.WALNUT;
				break;
			default:
				result = eCalculators.LETTUCE;
				break;
		}

		return result;
	}

	/**
	 * given a planting object, return a commodity type interface string
     * @param planting
     */
	public static getCalculatorInterface(commodityTypeId: number, commodityTypes: ICommodityType[]): string {
		let commodityType: ICommodityType;

		if (!commodityTypeId) {
			throw new Error('commodityTypeId is empty');
		}

		if (!commodityTypes) {
			throw new Error('commodity types array is empty');
		}

		commodityType = commodityTypes.find(x => x.Id === commodityTypeId);

		if (!commodityType) {
			throw new Error('commodity type not found in array');
		} else {
			return commodityType.CommodityTypeCalculator;
		}
	}

	public static convertPlantingsViewModel(planting: IPlantingsViewModel): IPlanting {
		let result: IPlanting;

		result = Planting.convert(planting);

		return result;
	}

	public static convertPlantingJSON(planting: IPlantingJSON): IPlanting {
		let result: IPlanting;

		result = Planting.convert(planting);

		result.CommodityTypeCalculator = planting.CommodityTypeCalculator;
		result.CropType = planting.CropType;

		if (planting.Events) {
			planting.Events = EventGroup.removeHiddenEvents(planting.Events);
			result.Events = EventGroup.convertAll(planting.Events, result.WetDate);
		}

		return result;
	}

	public static convert(planting: IPlantingsViewModel | IPlantingJSON): IPlanting {
		let result: IPlanting

		if (!planting) {
			return null;
		}

		result = {
			HarvestDate: DateUtility.DotNetToDate(planting.HarvestDate),
			WetDate: DateUtility.DotNetToDate(planting.WetDate),
			Id: planting.Id,
			Name: planting.Name,
			CommodityTypeId: planting.CommodityTypeId,
			CommodityTypeName: planting.CommodityTypeName,
			CropType: null,
			CommodityTypeCalculator: null,
			Events: new Array(),
			RanchLot: planting.RanchLot,
			HasFlowMeterFileName: planting.HasFlowMeterFileName,
			HasSoilMoistureFileName: planting.HasSoilMoistureFileName,
			Favorite: planting.Favorite != null ? planting.Favorite : false,
			FavoriteId: planting.FavoriteId != null ? planting.FavoriteId : null,
			IsNDependent: planting.IsNDependent,
			IsSlideInOpen: false,
			ActiveEvents: planting.ActiveEvents,
			ActiveEventDates: Planting.convertActiveEventString(planting.ActiveEvents),
		}

		result.Counts = {
			upcomingEvents: Planting.getUpcomingEventCount(result.ActiveEventDates)
		};

		result.WetDateString = DateUtility.formatDate(result.WetDate);
		result.HarvestDateString = DateUtility.formatDate(result.HarvestDate);

		return result;
	}

	public static getUpcomingEventCount(dates: Date[]): number {
		let result = 0;
		let today: Date = new Date(); // today

		if (!dates || dates.length === 0) {
			return 0;
		}

		for (let date of dates) {
			if (DateUtility.isTodayOrFuture(date)) {
				result++;
			}
		}

		return result;
	}

	public static convertActiveEventString(s: string): Date[] {
		let result: Date[];
		let strings: string[];

		if (!s) {
			return null;
		}

		result = new Array();

		strings = s.split(',');

		for (let str of strings) {
			result.push(new Date(str));
		}

		return result;
	}

	/**
	 * A method that calculates the default value for harvest date based
	 * on wet date
	 * @param daysToEndDate
	 * @param wetDate
	 * @param isPerennial
	 * @returns
	 */
	public static setHarvestDateFromDaysToEndDate(daysToEndDate: number,
		wetDate: Date, isPerennial: boolean): Date {

		// Setting new harvest date because wet date has changed and
		// daysToEndDate is greater than 0
		let result: Date;
		let wetDateYear: number;

		if (!daysToEndDate || daysToEndDate <= 0) {
			return null;
		}

		if (wetDate === null) {
			return null;
		}

		result = new Date(wetDate.getTime());
		wetDateYear = wetDate.getFullYear();
		result.setDate(result.getDate() + daysToEndDate);

		if (isPerennial && result.getFullYear() > wetDateYear) {
			// prevent harvest date to cross over into next year
			result = new Date(wetDateYear, 11, 31);
		}

		return new Date(result.getTime());
	}

	/**
     * Given percentage, harvest date, and wet date, calculate leaf senesce date
     * @param wetDate
     * @param harvestDate
     * @param percentage
     */
	public static convertPercentageIntoDate(wetDate: Date, harvestDate: Date,
		percentage: number): Date {

		let result: Date;
		let daysOfSeason: number;
		let offset: number;

		if (!wetDate) {
			return null;
		}

		result = new Date(wetDate.setHours(0, 0, 0, 0));
		daysOfSeason = DateUtility.daysBetweenDates(wetDate, harvestDate);
		offset = Math.floor(daysOfSeason * percentage);
		result.setDate(result.getDate() + offset);

		return result;
	}

	/**
     * Given leaf senesce date, wet date, and harvest date, recalculate percentage
     * @param wetDate
     * @param harvestDate
     * @param date
     */
	public static convertDateIntoPercentage(wetDate: Date, harvestDate: Date,
		date: Date): number {
		let result, daysOfSeason: number;

		daysOfSeason = DateUtility.daysBetweenDates(wetDate, harvestDate);

		result = DateUtility.daysBetweenDates(wetDate, date) /
			daysOfSeason

		result = parseFloat(result.toFixed(4));

		return result;
	}

	public static processAllEvents(response: { Events: IRawEvent[], WetDate: string }, persistentDatabase: PersistentDatabase): EventGroup[] {
		let result: EventGroup[];
		let wetDate: Date;

		if (!response || !response.Events) {
			return;
		}

		wetDate = DateUtility.DotNetToDate(response.WetDate);

		if (persistentDatabase) {
			// cache wet date to calculate on or before check on irrigation events
			persistentDatabase.wetDate = wetDate;
		}

		result = EventGroup.convertAll(response.Events, wetDate);
		return result;
	}

	public static prepareCalculatorSettings(settings: IPlantingSettingsCalculatorSettings):
		IPlantingSettingsCalculatorSettings {

		if (!settings) {
			return;
		}

		if (settings.DripApplicationRate) {
			settings.DripApplicationRate.RowSpacing = Math.round(settings.DripApplicationRate.RowSpacing);
			settings.DripApplicationRate.InRowTreeSpacing = Math.round(settings.DripApplicationRate.InRowTreeSpacing);
			settings.DripApplicationRate.TreesPerAcre = Math.round(settings.DripApplicationRate.TreesPerAcre);
		}

		if (settings.MicroSprinklerSetting) {
			settings.MicroSprinklerSetting.MicroSprinklersPerTree =
				Math.round(settings.MicroSprinklerSetting.MicroSprinklersPerTree);
		}

		return settings;
	}

	constructor() { }
}
