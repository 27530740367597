import { ISoilMoistureDropdownItem, ISoilMoistureHeader, ISoilMoistureTab } from './soilMoisture.interface';

export class SoilMoisture {
	public static readonly SoilMoistureTabs: ISoilMoistureTab[] = [
		{
			name: 'Tension',
			values: ['kPa', 'cBar'],
			disabled: true
		},
		{
			name: 'Volumetric Moisture',
			values: ['%'],
			disabled: true
		},
		{
			name: 'Salinity/Electrical Conductivity',
			values: ['EC dS/m'],
			disabled: true
		}
	]

	public readonly soilMoistureDepthDropdownItems: ISoilMoistureDropdownItem[] = [
		{
			name: '6 in.',
			value: '6 inches'
		},
		{
			name: '8 in.',
			value: '8 inches'
		},
		{
			name: '12 in.',
			value: '12 inches'
		},
		{
			name: '16 in.',
			value: '16 inches'
		},
		{
			name: '18 in.',
			value: '18 inches'
		},
		{
			name: '20 in.',
			value: '20 inches'
		},
		{
			name: '24 in.',
			value: '24 inches'
		},
		{
			name: '30 in.',
			value: '30 inches'
		},
		{
			name: '36 in.',
			value: '36 inches'
		},
		{
			name: '42 in.',
			value: '42 inches'
		},
		{
			name: '48 in.',
			value: '48 inches'
		}
	];

	public readonly SoilMoistureTypeDropdownItems: ISoilMoistureDropdownItem[] = [
		{
			name: 'Soil Moisture (%)',
			value: '%'
		},
		{
			name: 'Soil Tension (kPa)',
			value: 'kPa'
		},
		{
			name: 'Soil Tension (cBar)',
			value: 'cBar'
		},
		{
			name: 'Salinity/Electrical Conductivity (dS/m)',
			value: 'EC dS/m'
		}
	];

	public static areNewHeaders(headers: ISoilMoistureHeader[]): boolean {
		for (let header of headers) {
			if (!header.isDisplayed) {
				continue;
			}

			if (header.SensorName || header.Depth || header.DataType) {
				return false;
			}
		}

		return true;
	}

	public static getTabNameFromDataType(dataType: string): string {
		let result: string;

		result = this.SoilMoistureTabs.filter(x => x.values.indexOf(dataType) >= 0)[0].name;

		return result;
	}
}
