import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ICurrentRanchLots } from '../../interfaces/interfaces';
import { RanchService } from '../../components/ranch-settings/service';
import { IRanchLotJSONModel } from '../../components/ranch-settings/interfaces';
import { ILotEdit } from '../../components/ranch-settings/modals/lot/interfaces';

@Injectable()
export class LotSubscriptionService {
	private _currentRanchLotsSubject: Subject<ICurrentRanchLots>;
	public currentRanchLots$: Observable<ICurrentRanchLots>;

	constructor(
		private _ranchService: RanchService
	) {
		this._currentRanchLotsSubject = new Subject<ICurrentRanchLots>();
		this.currentRanchLots$ = this._currentRanchLotsSubject.asObservable();
	}

	public setCurrentRanchLots(ranchId: string, lotId: number, lot: ILotEdit) {

		this._ranchService.getBasicRanchLots(ranchId)
			.then((res: IRanchLotJSONModel[]) => {

				this._currentRanchLotsSubject.next({
					lots: res,
					lotId: lotId,
					lot: lot
				});
			});
	}
}
