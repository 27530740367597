<div class="bk-splash__wrapper">
	<div class="bk-splash-hero">
		<div class="bk-splash-section__content">
			<h2>Smarter Decisions. Better Yields.</h2>
			<div class="bk-splash-intro bk-splash-text">Based on years of in-depth research and field studies conducted by the
				University of California, CropManage provides real-time recommendations for the most efficient,
				effective, and sustainable irrigation and fertilization applications possible.</div>
				<a routerLink="/register" class="bk-splash-cta">Sign Up</a>
		</div>
	</div>

	<div class="bk-splash-section bk-clearfix">
		<div class="bk-splash-section__content bk-splash-section__content--benefits bk-clearfix">
			<div class="bk-splash-section__left bk-border-box">
				<h2 class="bk-benefits__header">Benefits to Growers</h2>
				<div class="bk-splash-section__text bk-splash-text">Based on a few simple inputs, CropManage can provide any level
					of irrigation and fertilization decision support in order to validate or improve your existing
					operation&apos; production&mdash;and increase your overall confidence.</div>
			</div>
			<div class="bk-splash-section__right bk-splash-section__benefits">
				<div class="bk-splash-section__benefit bk-border-box">
					<img alt="Water Reduction" src="../assets/images/icons/icon-water-reduction.png">
					<h3>20% to 40% Reduction in Water and Fertilizer With Same Yields</h3>
					<div class="bk-splash-text">CropManage is ground-truthed in more than 30 field
						trials and has produced consistent, or in many cases, improved crop yields.</div>
				</div>
				<div class="bk-splash-section__benefit bk-border-box">
					<img alt="Irrigation" src="../assets/images/icons/icon-irrigation.png" />
					<h3>Supports Irrigation AND Fertilization Recommendations</h3>
					<div class="bk-splash-text">CropManage combines irrigation and fertilization
						recommendations that, when used together, significantly improve yields while reducing costs.
					</div>
				</div>
				<div class="bk-splash-section__benefit bk-border-box">
					<img alt="Research" src="../assets/images/icons/icon-research.png" />
					<h3>Steeped in Deep Research</h3>
					<div class="bk-splash-text">CropManage is the result of years of ongoing,
						in-depth University of California agricultural research and crop modeling algorithms. </div>
				</div>
				<div class="bk-splash-section__benefit bk-border-box">
					<img alt="No Extra Equipment" src="../assets/images/icons/icon-no-extra-equipment.png" />
					<h3>No Extra Equipment Required</h3>
					<div class="bk-splash-text">CropManage allows growers to leverage their
						existing infrastructure and does not require operational changes or purchase/implementation
						of new equipment.</div>
				</div>
			</div>
		</div>
	</div>
	<div class="bk-splash-section bk-splash-section--dark bk-clearfix">
		<div class="bk-splash-section__content bk-splash-section__content--how bk-clearfix">
			<div class="bk-splash-section__left bk-splash-section__left--how bk-border-box">
				<h2>How It Works</h2>
				<div class="bk-splash-section__text bk-splash-text">
					CropManage combines a wide variety of data inputs including past and future weather,
					evapotranspiration, satellite imagery, soil physical and chemical properties (texture, bulk
					density, nutrients), irrigation system efficiency, and other related variables to generate
					accurate and timely irrigation and fertilization recommendations based on crop-specific models.
				</div>
				<div class="bk-splash-section__text bk-splash-text">
					CropManage can be used as a stand-alone, online decision support and operational task management
					tool, or can be integrated into other software applications via API connectivity. Furthermore,
					users can customize CropManage for the site-specific conditions of their farm or ranch, and the
					application can be integrated with infield soil moisture and flowmeter sensors.
				</div>
			</div>
			<div class="bk-splash-section__right bk-splash-section__right--how">
				<img class="bk-how-it-works__image" src="../assets/images/illustrations/how-it-works.png" />
			</div>
		</div>
	</div>
	<div class="bk-splash-section bk-clearfix">
		<div class="bk-splash-section__content bk-splash-section__content--testimonials bk-clearfix">
			<img class="bk-splash-testimonial-photo" src="../assets/images/photos/gabriel-youtsey.png" />
			<div class="bk-splash-testimonial-text">
				<span class="bk-splash-testimonial-text__quote"></span>I believe CropManage could change how we grow
				food in California and the world. It&apos;s a science-backed way to precisely manage and reduce
				water and fertilizer. It&apos;s a win for farmers, communities, and consumers. This is the
				University of California turning science into solutions at its finest.<span
					class="bk-splash-testimonial-text__quote--end"></span>
			</div>
			<div class="bk-splash-testimonial-meta-text">
				<div class="bk-splash-testimonial-name">
					Gabriel Youtsey
				</div>
				<div class="bk-splash-text">
					Chief Innovation Officer<br />
					University of California, Agriculture and Natural Resources
				</div>
			</div>
		</div>
	</div>
</div>