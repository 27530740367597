import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { RecaptchaKey } from '../../services/constants';
import { IRecaptchaResponse } from '../../services/interface';

declare global {
	interface Window {
		grecaptcha: {
			render(nativeElement: any, options: {
				sitekey: string,
				callback(token: string): void
			}): void
		},
		grecaptchaCallback(): void
	}
}

@Component({
	selector: 'app-recaptcha',
	templateUrl: 'recaptcha.component.html'
})
export class RecaptchaComponent implements OnInit {
	@ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

	@Output()
	public recaptchaResponse = new EventEmitter<IRecaptchaResponse>();

	public recaptchaKey: string;
	public isCaptchaValid = false;

	constructor(
		private _loginService: LoginService) { }

	ngOnInit(): void {
		this.recaptchaKey = RecaptchaKey;
		this.addRecaptchaScript();
	}

	private renderReCaptch() {
		window.grecaptcha.render(this.recaptchaElement.nativeElement, {
			'sitekey': this.recaptchaKey,
			'callback': (token: string) => {
				// contact server to validate response
				this._loginService.validateRecaptcha(token).then((response) => {

					let successResponse: IRecaptchaResponse;

					if (!response) {
						return;
					}

					successResponse = response as IRecaptchaResponse;

					this.recaptchaResponse.emit(successResponse);
				});
			}
		});
	}

	private addRecaptchaScript() {
		window.grecaptchaCallback = () => {
			this.renderReCaptch();
		}

		(function (d, s, id, obj) {
			let js, fjs = d.getElementsByTagName(s)[0];

			if (d.getElementById(id)) {
				obj.renderReCaptch(); return;
			}

			js = d.createElement(s) as HTMLScriptElement;
			js.id = id;

			js.src = 'https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit';
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'recaptcha-jssdk', this));
	}
}
