import { Component, EventEmitter, Output, ViewChild, DoCheck, ChangeDetectorRef, ElementRef, OnInit, Inject, Renderer2, OnDestroy }
	from '@angular/core';
import { of, Subject } from 'rxjs';
import * as jQuery from 'jquery';
import * as moment from 'moment';

import { PlantingService } from '../planting-settings/service';
import { SoilSampleEventService } from '../soil-sample-event/service';
import { RanchService } from '../ranch-settings/service';
import { UpdateService } from '../../services/update.service';
import { CalculateService } from '../../services/calculate.service';
import { FertilizationEventService } from './service';
import { IrrigationService } from '../irrigation-event/service';
import { FertilizationEventWellService } from '../../models/fertilization-event-well/service';

import { DateUtility } from '../../classes/dateUtility';
import { KeyboardUtility } from '../../classes/keyboardUtility';
import { FertilizationEvent } from './fertilizationEvent';

import { IEventPostResponseJSON } from '../../models/event/interfaces';
import { IFertilizationEvent, IFertilizerProperties, ISoilSampleEmitted } from './interfaces';
import { IWell } from '../../models/planting-well/well.interface';
import { IIrrigationMethod } from '../../models/irrigation-method/interfaces';
import { IIrrigationRecommendedNContribution, IIrrigationEventEditResponse, IIrrigationObject } from '../irrigation-event/interfaces';

import { eIrrigationMethods, eIrrigationUnit, eCommodityTypes,
	eFertilizerFormulationTypes,
	eNotificationTypes,
	EventsType} from '../../interfaces/constants';

import { IrrigationEvent } from '../irrigation-event/irrigationEvent';
import { EventGroup } from '../../models/event/event';
import { ICropStage } from '../../models/crop-stage/interfaces';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IFormSoilSample } from '../soil-sample-event/interfaces';
import { FertilizerModelService } from '../ranch-settings/modals/fertilizer/service';
import { CropType } from '../../models/crop-type/cropType';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../services/notification.service';
import { FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { IFormFertilizer } from '../ranch-settings/modals/fertilizer/interfaces';
import { BOKA } from '../../classes/number';
import { FormValidation } from '../../classes/formValidation';
import { BaseDialog } from '../shared/dialogs/base-dialog';
import { SharedUpdateService } from '../shared/dialogs/update.service';
import { FertilizationRecommendationSummaryComponent } from '../events/table/fertilization-hover-panel/recommendation-summary';

export type FertilizationDialogFields = 'Id' | 'EventDate' | 'FertilizerId' |
	'FertilizationsPerMonth' | 'DaysToNextFertilization' | 'CropStageId'
	| 'soilSampleEventId' | 'SoilSampleEventDate' | 'ManagerFertilizerAmount' |
	'managerNRecommendation' | 'FertilizerApplied'
	| 'NApplied' | 'FormulationTypeId' | 'IsFormulationTypeDry' | 'FertilizerName'
	| 'FertilizerOtherNutrients'
	| 'LbsPerGallon' | 'NAppliedFromWater' | 'IrrigationEventDate'
	| 'NPercentage' | 'IrrigationRecommendation';

export type SoilSampleFields = 'SoilMoistureId' | 'SampleDepth' | 'SampleTypeId' |
	'Nitrogen' | 'CropStageId' | 'EventDate';

export type NContributionFields = 'UseWells' | 'wells' | 'PPMTotal' | 'IrrigationAmount'
	| 'IrrigationMethodId' | 'irrigationAmountHours' | 'Ratio' | 'wells';

export enum FertilizationEventWarningTypes {
	NO_YIELD = 1,
	ZERO_LBS_PER_GALLON
};

@Component({
	moduleId: module.id,
	selector: 'fertilization-event',
	templateUrl: 'main.html',
	styleUrls: [ 'main.scss']
})

export class FertilizationEventDialog extends BaseDialog implements OnInit, OnDestroy {
	@ViewChild(FertilizationRecommendationSummaryComponent, { static: false })
		private recommendationSummary: FertilizationRecommendationSummaryComponent;

	@ViewChild('nContributionUI', { static: false }) nContributionUI: ElementRef;
	@ViewChild('nContributionLoader', { static: false }) nContributionLoader: ElementRef;
	@ViewChild('nContributionIrrigationLoader', { static: false }) nContributionIrrigationLoader: ElementRef;
	@ViewChild('nContributionIrrigation', { static: false }) nContributionIrrigation: ElementRef;

	@Output()
	closeModal: EventEmitter<string> = new EventEmitter<string>();

	private _dateDefault: Date;
	public fertilizerDetail: IFertilizerProperties;
	public isFuture = false;
	public isFertilizationsPerMonth: boolean;
	public eventId: number;
	public isTree: boolean;
	private YieldTarget: number;
	private plantingId: number = null;
	private _harvestDate: Date;
	public recommendationSummaryVisible = false;
	public firstStep = true; // used to display the dialog form in two steps during creation
	private _isAlive = true; // track component lifecycle
	public COMMODITY_TYPES = eCommodityTypes;
	public soilSamples: IFormSoilSample[];
	public cropStages: ICropStage[];
	public fertilizers: IFormFertilizer[];

	public NContribution = {
		UIVisible: false,
		calculatedPPM: 0,
		recommendationHours: null as number,
		nextFertilizationDate: null as Date,
	}

	public totalWellPercentage: number;

	public loading = {
		nContribution: false,
		irrigationRecommendation: false,
		isInitial: false
	}

	public irrigationMethods: IIrrigationMethod[] = null;
	public irrigationUnits = eIrrigationUnit;
	private initialDataPull = false;

	public readonly UNIT_LB_PER_ACRE = 'lbsNPerAcre';
	public readonly UNIT_GAL_PER_ACRE = 'fertilizerUnit';
	public readonly SOIL_SAMPLE_DROPDOWN_NEW = -1;
	public readonly NO_SOIL_SAMPLE = -2;
	public units: string = this.UNIT_GAL_PER_ACRE;
	public isSaving = false; // button loadeer

		// is in the future
	public isLoaded = false;
	public didUserMakeChanges = false;
	private _originalDate: Date;

	// used to restore values to the original values if user cancels out on changes
	private originalNContributionProperties: {
		NAppliedFromWater: number,
		IrrigationAmount: number,
		IrrigationMethodId: number,
		UseWells: boolean,
		PPMTotal: number,
		Ratio: number
	};

	public maxDate: Date;
	private _commodityTypeId: number;
	public FertilizationRecommendationLbsPerAcre: number;
	public NFertilizationRecommended: number;
	public lastUpdated: string;

	private currentWells: IWell[] = null;
	public irrigationUnit: eIrrigationUnit = eIrrigationUnit.HOURS;
	public isNDependent: boolean;
	public warnings: FertilizationEventWarningTypes[] = new Array();
		// these are warnings that do not invalidate the form, so we should manage them separately
	public warningTypes = FertilizationEventWarningTypes;
	protected _subscriptions$: Subject<boolean>;

	/*fertilization event modal validation*/

	public nContributionForm: FormGroup;

	private _isNContributionCached: boolean;

	public get f(): { [key in FertilizationDialogFields]: AbstractControl } {
		if (!this.form) {
			return null
		};

		return this.form.controls as { [key in FertilizationDialogFields]: AbstractControl };
	}

	public get fn(): { [key in NContributionFields]: AbstractControl } {
		if (!this.nContributionForm) {
			return null;
		}

		return this.nContributionForm.controls as { [key in NContributionFields]: AbstractControl };
	}

	constructor(
		private calculateService: CalculateService,
		private changeDetectorRef: ChangeDetectorRef,
		@Inject(MAT_DIALOG_DATA) public data: {
			plantingId: number, id?: number, dateDefault?: Date
		},
		private dialog: MatDialog,
		private dialogRef: MatDialogRef<FertilizationEventDialog>,
		private fertilizationEventService: FertilizationEventService,
		private fertilizationEventWellService: FertilizationEventWellService,
		private fertilizerService: FertilizerModelService,
		private _fb: FormBuilder,
		private irrigationEventService: IrrigationService,
		private _notificationService: NotificationService,
		private plantingService: PlantingService,
		private ranchService: RanchService,
		private render: Renderer2,
		private soilSampleEventService: SoilSampleEventService,
		private sharedUpdateService: SharedUpdateService,
		private updateService: UpdateService,
	) {
		super(dialog, dialogRef, sharedUpdateService);

		if (this.data) {
			this.eventId = this.data.id;
			this.plantingId = this.data.plantingId;
			this._dateDefault = this.data.dateDefault;
		}
	}

	ngOnInit(): void {
		this._clear();
		this._subscriptions$ = new Subject();

		this._deleteConfirmationData = { // set up default message
			objectName: 'Fertilization Event',
			specificName: null,
			additionalMessage: 'Are you sure you want to delete this event?'
		};

		this.isNDependent = this.ranchService.getIsNDependent(this.plantingId);

		if (this.eventId) {
			this.firstStep = false;
			this._getEditModel(this.eventId).then(() => {
				if (!this.form) {
					throw new Error('fertilization event dialog failed to load');
				}

				this.isLoaded = true;
				this.warnings = this._shouldShowWarning();
				this.isTree = CropType.isTree(this._commodityTypeId);
				this.isFuture = DateUtility.isFuture(this.f.EventDate.value);
			});
		} else {
			this.firstStep = true;
			this._getAddModel().then(() => {
				this.isLoaded = true;
				this.warnings = this._shouldShowWarning();
				this.isTree = CropType.isTree(this._commodityTypeId);
				this.isFuture = DateUtility.isFuture(this.f.EventDate.value);
			});
		}

		super.ngOnInit();
	}

	ngOnDestroy(): void {
		this._isAlive = false;

		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public calculateNContribution(updateNApplied = false): void {

		let ppm: number;
		let nApplied: number;

		if (this.form.invalid) {
			return;
		}

		if (this.fn.UseWells.value) {
			this.NContribution.calculatedPPM = this.calculateService.calculateAveragePPM(this.fn.wells.value);
			ppm = this.NContribution.calculatedPPM;
		} else {
			ppm = this.fn.PPMTotal.valid ? this.fn.PPMTotal.value : 0;
		}

		if (updateNApplied) {
			nApplied = this.calculateService.calculateNApplied(this.fn.IrrigationAmount.value, ppm);

			this.f.NAppliedFromWater.setValue(
				BOKA.NumberUtil.roundToDecimalPlaces(nApplied, 2)
			); // update NApplied

			this._updateRecommendation(); // delayed
		}

		// possibly ping for recommendation again here.. but that may be too intensive?
		// (which is where having this on a separate screen would help)
	}

	public cancelIncludingNContribution(e?: MouseEvent): void {
		if (e) {
			e.stopPropagation();
		}

		this._hideNContributionUI();

		this.NContribution.UIVisible = false;
		this.NContribution.calculatedPPM = 0;
		this.f.IrrigationRecommendation.setValue(null);
		this.NContribution.recommendationHours = null;
		this.fn.irrigationAmountHours.setValue(null, { emitEvent: false });
		this.irrigationUnit = eIrrigationUnit.HOURS;

		if (this.originalNContributionProperties) {
			this.f.NAppliedFromWater.setValue(this.originalNContributionProperties.NAppliedFromWater);

			this.fn.IrrigationAmount.setValue(this.originalNContributionProperties.IrrigationAmount,
				{ emitEvent: false });

			this.fn.IrrigationMethodId.setValue(this.originalNContributionProperties.IrrigationMethodId);
			this.fn.UseWells.setValue(this.originalNContributionProperties.UseWells);
			this.fn.PPMTotal.setValue(this.originalNContributionProperties.PPMTotal);
			this.fn.Ratio.setValue(this.originalNContributionProperties.Ratio);
		} else {
			this.f.NAppliedFromWater.setValue(null);
			this.fn.IrrigationAmount.setValue(null, { emitEvent: false });
		}

		this._updateRecommendation();
	}

	/**
	 * Called to clear N Contribution setting
	 * @param
	 */
	public clearNContribution(e?: MouseEvent): void {
		let event: IFertilizationEvent;

		if (e) {
			e.stopPropagation();
		}

		// make a call to the backend AND clear internal values
		// after clearing, user should see the Add N Contribution button

		if (this.form.invalid) {
			return;
		}

		// clear internal values

		this.NContribution.UIVisible = false;
		this.fn.UseWells.setValue(true);
		this.fn.PPMTotal.setValue(0);
		this.NContribution.calculatedPPM = 0;
		this.f.IrrigationRecommendation.setValue(null);
		this.NContribution.recommendationHours = null;
		this.fn.IrrigationAmount.setValue(null);
		this.fn.IrrigationMethodId.setValue(null);
		this.fn.Ratio.setValue(null);
		this.NContribution.nextFertilizationDate = null;

		// save N Contribution water values
		this.f.IrrigationEventDate.setValue(null);
		this.fn.IrrigationMethodId.setValue(null);
		this.f.NAppliedFromWater.setValue(null);
		this.fn.PPMTotal.setValue(null);

		this.fertilizationEventWellService.syncFertilizationEventWells(this.eventId);

		this.currentWells = null;
		this._isNContributionCached = false;
		this.irrigationUnit = eIrrigationUnit.HOURS;

		event = this.form.value as IFertilizationEvent;

		try {
			this.fertilizationEventService.update(this.eventId, event,
			this.plantingId)
			.then(() => {
				this.fertilizationEventService.getNRecommendation(this.plantingId, event)
				.then((res) => {

					if (!res) {
						return;
					}

					this.FertilizationRecommendationLbsPerAcre = BOKA.NumberUtil.
					convertToFloat(FertilizationEvent.convertNToFertilizerAmount(res.NFertilizationRecommended, event), 2);

					this.NFertilizationRecommended = BOKA.NumberUtil.convertToFloat(res.NFertilizationRecommended, 2);

					if (this.recommendationSummaryVisible) { // if recommendation summary is open, update that too
						this.f.Id.setValue(this.eventId);
						this._convertSoilSampleEventDate();

						this.fertilizationEventService.getRecommendationSummary(this.plantingId,
						event as IFertilizationEvent)
						.then(summary => {
							if (!this.recommendationSummary || !summary) {
								return
							}

							this.recommendationSummary.render(summary);
						});
					}
				});
			});
		} catch (e) {
			this._notificationService.generateNotifcation({
				type: eNotificationTypes.ERROR,
				message: e
			});
		}
	}

	public close(): void { // overrides parent definition
		this.updateService.setEventDialogClosed(); // reopen event table
		super.close();
	}

	public create(): void {
		let fe: IFertilizationEvent;

		if (!this.form || this.form.invalid) {
			return;
		}

		fe = this.form.value;

		if (this.f.NAppliedFromWater.value !== null) {
			// save irrigation event date if N applied from water is set.
			// otherwise don't commit to a date here
			fe.IrrigationEventDate = fe.EventDate;
		}

		fe.IrrigationMethodId = this.fn.IrrigationMethodId.value;
		fe.UseWells = this.fn.UseWells.value;
		fe.PPMTotal = this.fn.PPMTotal.value;

		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this.fertilizationEventService.create(this.plantingId, fe)
			.then((res: IEventPostResponseJSON) => {

				this.isSaving = false;

				if (!res || !res.Events || res.Events.length === 0) {
					return false;
				}

				this._updateEventsList(res);
				this.eventId = res.Id;
				this.updateService.setRanchSettingsUpdated('fertilizers');
				this.updateService.setEventDialogClosed(); // tell event table to re-display
				this.close();

				if (this.fn.wells && (this.fn.wells as FormArray).length) {
					this.fertilizationEventWellService.saveAllFertilizationWells(this.fn.wells.value as IWell[], this.eventId);
				}
			}
		);
	}

	protected delete(): void {

		if (this.form.invalid) {
			return;
		}

		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this.fertilizationEventService.delete(this.eventId)
			.then(res => {
				this.isSaving = false;

				if (!res) {
					this._notificationService.generateNotifcation({
						type: eNotificationTypes.ERROR,
						message: 'Fertilization event failed to delete. Reload the page and try again.'
					});

					return;
				}

				this.ranchService.plantings.deleteEventInPlantings(this.f.EventDate.value,
					this.plantingId,
					EventsType.FERTILIZER, this.eventId, res.ActiveEvents);

				this.updateService.setPlantingsUpdated(this.updateService.currentRanchId, new Date());
				this.close();
				this.sharedUpdateService.deleteComplete();
				this.updateService.setEventDialogClosed(); // tell event table to re-display
			});
	}

	/**
	 * If this.wells exist, show it.. else create this.wells array, then display
	 * @param e
	 */
	public displayNContributionUI(isVisible = true): void {
		this.loading.nContribution = isVisible;

		if (this.loading.nContribution) {
			this._showNContributionLoader();
			this.f.soilSampleEventId.disable({ emitEvent: false});
		}

		let promises = {
			recommendation: null as Promise<IIrrigationRecommendedNContribution>,
			wells: null as Promise<IWell[]>,
			irrigationMethods: null as Promise<IIrrigationMethod[]>
		};

		// if editing, check if following values exist and are valid
		if (this.originalNContributionProperties) {
			this.fn.IrrigationAmount.setValue(this.originalNContributionProperties.IrrigationAmount);
			this.fn.IrrigationMethodId.setValue(this.originalNContributionProperties.IrrigationMethodId);
			this.fn.UseWells.setValue(this.originalNContributionProperties.UseWells);
			this.fn.PPMTotal.setValue(this.originalNContributionProperties.PPMTotal);
			this.fn.Ratio.setValue(this.originalNContributionProperties.Ratio);
		}

		if (this.f.IrrigationRecommendation.value !== null &&
			this.fn.IrrigationMethodId.valid) { // display cached recommendation if possible
			// on fertilization edit, we construct N contribution object from data in the event
			// initially, C# sends ratio = 0
			promises.recommendation = of({
				irrigationAmountRecommended: this.f.IrrigationRecommendation.value,
				LastIrrigationMethod: this.fn.IrrigationMethodId.value,
				Success: true,
				ratio: this.fn.Ratio.value
			}).toPromise();

			this.NContribution.recommendationHours = this._getIrrigationInHours(
				this.f.IrrigationRecommendation.value, this.fn.Ratio.value,
				this.fn.IrrigationMethodId.value);
		} else {

			promises.recommendation = this.irrigationEventService.getNContributionRecommendation(this.plantingId,
				this.f.EventDate.value, this.fn.IrrigationMethodId.value, this.f.DaysToNextFertilization.value,
				this.f.FertilizationsPerMonth.value);
		}

		if (this.eventId) {
			promises.wells = this.fertilizationEventWellService.getFertilizationEventWells(this.eventId);
		} else {
			promises.wells = this.fertilizationEventWellService.listByPlantingId(this.plantingId);
		}

		promises.irrigationMethods = this.plantingService.
			getIrrigationMethods(this.plantingId);

		Promise.all([promises.recommendation, promises.wells, promises.irrigationMethods]).
			then(([recommendation, wells, irrigationMethods]) => {

				this.irrigationMethods = irrigationMethods;

				if (!recommendation) {
					throw new Error('Empty irrigation recommendation response from server');
				}

				if (this.f.NAppliedFromWater.value === null) {
					this.fn.IrrigationMethodId.setValue(recommendation.LastIrrigationMethod);
					this.fn.Ratio.setValue(recommendation.ratio);
				}

				if (this.fn.IrrigationMethodId.invalid) {
					switch (recommendation.LastIrrigationMethod) {
						case eIrrigationMethods.SPRINKLER:
						case eIrrigationMethods.DRIP:
						case eIrrigationMethods.GERMINATION_SPRINKLER:
							this.fn.IrrigationMethodId.setValue(recommendation.LastIrrigationMethod);
							break;
						default:
							this.fn.IrrigationMethodId.setValue(eIrrigationMethods.SPRINKLER);
							break;
					}
				}

				if (!wells || wells.length === 0) { // no wells are associated with this event - so create wells association
					this.fertilizationEventWellService.listByPlantingId(this.plantingId).then((wellsNew) => {
						// code block repeats twice - needed since the first if block is on a delay
						if (this.currentWells && this.currentWells.length) {
							this._initializeWells(this.currentWells);
						} else {
							this._initializeWells(wellsNew);
						}

						this.f.IrrigationRecommendation.setValue(recommendation.irrigationAmountRecommended);

						if (this.fn.IrrigationAmount.invalid) {
							this.fn.IrrigationAmount.setValue(recommendation.irrigationAmountRecommended);
						}

						this.fn.Ratio.setValue(recommendation.ratio);

						this.NContribution.recommendationHours =
							this._getIrrigationInHours(this.f.IrrigationRecommendation.value,
								this.fn.Ratio.value, this.fn.IrrigationMethodId.value);

						this.fn.irrigationAmountHours.setValue(
							this.fn.IrrigationAmount.valid ? this._getIrrigationInHours(
								this.fn.IrrigationAmount.value, this.fn.Ratio.value,
								this.fn.IrrigationMethodId.value) : null
						);

						this.NContribution.nextFertilizationDate = FertilizationEvent.getNextFertilizationDate(
							this.f.EventDate.value,
							this.f.FertilizationsPerMonth.value, this.f.DaysToNextFertilization.value);

						this.calculateNContribution(isVisible);
						this.NContribution.UIVisible = isVisible;

						this.loading.isInitial = false;

						if (this.loading.nContribution) {
							this._hideNContributionLoader();
							this.loading.nContribution = false;
							this.f.soilSampleEventId.enable({ emitEvent: false});
						}

						if (this.NContribution.UIVisible) {
							this._showNContributionUI();
							// Modal.resize();
						}
					});
				} else {
					if (this.currentWells && this.currentWells.length) {
						this._initializeWells(this.currentWells);
					} else {
						this._initializeWells(wells);
					}

					this.f.IrrigationRecommendation.setValue(recommendation.irrigationAmountRecommended,
						{ emitEvent: false});

					if (this.fn.IrrigationAmount.invalid) {
						this.fn.IrrigationAmount.setValue(recommendation.irrigationAmountRecommended,
						{ emitEvent: false });
					}

					this.fn.Ratio.setValue(recommendation.ratio);

					this.NContribution.recommendationHours = this._getIrrigationInHours(
						this.f.IrrigationRecommendation.value, this.fn.Ratio.value,
						this.fn.IrrigationMethodId.value);

					this.fn.irrigationAmountHours.setValue(this._getIrrigationInHours(
						this.fn.IrrigationAmount.value,
						this.fn.Ratio.value,
						this.fn.IrrigationMethodId.value),
						{ emitEvent: false });

					this.NContribution.nextFertilizationDate = FertilizationEvent.getNextFertilizationDate(
						this.f.EventDate.value,
						this.f.FertilizationsPerMonth.value,
						this.f.DaysToNextFertilization.value);

					this.calculateNContribution(isVisible);

					this.NContribution.UIVisible = isVisible;

					this.loading.isInitial = false;

					if (this.loading.nContribution) {
						this._hideNContributionLoader();
						this.loading.nContribution = false;
						this.f.soilSampleEventId.enable({ emitEvent: false});
					}

					if (this.NContribution.UIVisible) {
						this._showNContributionUI();
						// Modal.resize();
					}
				}
			});
	}

	public editNContribution(e?: MouseEvent): void {
		if (e) {
			e.stopPropagation();
		}

		if (!this._isNContributionCached) {
			this.originalNContributionProperties = {
				NAppliedFromWater: this.f.NAppliedFromWater.value,
				IrrigationAmount: this.fn.IrrigationAmount.value,
				IrrigationMethodId: this.fn.IrrigationMethodId.value,
				UseWells: this.fn.UseWells.value,
				PPMTotal: this.fn.PPMTotal.value,
				Ratio: this.fn.Ratio.value
			};
		}

		this.displayNContributionUI();
	}

	/**
     * converts manager and applied N values to fertilizer units
     * refresh validation
     */
	public getFertilizerUnits(): void {

		if (this.f.managerNRecommendation.value !== null) {
			this.f.ManagerFertilizerAmount.setValue(
				BOKA.NumberUtil.convertToFloat(FertilizationEvent.convertNToFertilizerAmount(
					this.f.managerNRecommendation.value, this.form.value as IFertilizationEvent), 2)
			);
		}

		if (this.f.NApplied.value !== null) {
			this.f.FertilizerApplied.setValue(
				BOKA.NumberUtil.convertToFloat(FertilizationEvent.convertNToFertilizerAmount(
					this.f.NApplied.value, this.form.value as IFertilizationEvent), 2)
			);
		}
	}

	public getLbsNPerAcre(): void {
		// this code is redundant - reads as if managerRec is validated twice, though that's not what's happening
		// validate method is bastardized
		// validation code should be in fertilizationEvent class.

		if (this.f.ManagerFertilizerAmount.value !== null) {
			this.f.managerNRecommendation.setValue(
				BOKA.NumberUtil.convertToFloat(FertilizationEvent.convertAmounttoLbsN(
					this.f.ManagerFertilizerAmount.value, this.f.NPercentage.value,
					this.f.IsFormulationTypeDry.value, this.f.LbsPerGallon.value
				), 2)
			);
		}

		if (this.f.FertilizerApplied.value !== null) {
			this.f.NApplied.setValue(
				BOKA.NumberUtil.convertToFloat(FertilizationEvent.convertAmounttoLbsN(
					this.f.FertilizerApplied.value,
					this.f.NPercentage.value,
					this.f.IsFormulationTypeDry.value, this.f.LbsPerGallon.value
				), 2)
			);
		}
	}

	public getNAppliedFromWaterInFertilizerUnits(): number {
		if (!this.f) {
			return null;
		}

		return BOKA.NumberUtil.roundToDecimalPlaces(FertilizationEvent.convertNToFertilizerAmount(
			this.f.NAppliedFromWater.value, this.form.value as IFertilizationEvent), 2);
	}

	public nextStep() {
		this.firstStep = false;
	}

	public onSoilSampleUpdated(data: ISoilSampleEmitted): void {
		this.f.soilSampleEventId.enable({emitEvent: false});
		this.f.EventDate.enable({emitEvent: false});
		this.f.CropStageId.enable({emitEvent: false});
		this.f.FertilizerId.enable({emitEvent: false});
		this.f.FertilizationsPerMonth.enable({emitEvent: false});
		this.f.DaysToNextFertilization.enable({emitEvent: false});

		this.f.soilSampleEventId.setValue(data.Id);
		this.f.SoilSampleEventDate.setValue(data.EventDate);

		if (data.Response) { // added
			this._refreshSoilSamples();
			this._updateEventsList(data.Response);
		}
	}

	public preventNonNumbers(e: KeyboardEvent): void {
		KeyboardUtility.preventNonNumbers(e, true);
	}

	public setIrrigationUnit(unit: eIrrigationUnit): void {
		this.irrigationUnit = unit;
	}

	public showRecommendationSummary(): void {
		if (!this.recommendationSummaryVisible) {
			this.fertilizationEventService.getRecommendationSummary(this.plantingId,
				this.form.value as IFertilizationEvent)
				.then(res => {
					if (!this._isAlive) {
						//
						return;
					}

					this.recommendationSummaryVisible = true;
					// force Angular to update view to make sure that recommendationSummary component is present in the DOM after flipping ngIf
					this.changeDetectorRef.detectChanges();

					if (!this.recommendationSummary) {
						return;
					}

					this.recommendationSummary.render(res);
					this._displayRecommendationSummary();
				})
		} else {
			this.recommendationSummary.clearFertilizerRecommendationSummary();
			this.recommendationSummaryVisible = false;
		}
	}

	public toggleToFertUnits(): void {
		this.units = 'fertilizerUnit'
	}

	public toggleToLbsNPerAcre(): void {
		this.units = 'lbsNPerAcre'
	}

	public update(): void {

		let fe: IFertilizationEvent;

		this._convertSoilSampleEventDate();

		fe = this.form.value as IFertilizationEvent;

		if (this.f.NAppliedFromWater.value !== null) {
			// save irrigation event date if N applied from water is set.
			// otherwise don't commit to a date here
			fe.IrrigationEventDate = fe.EventDate;
		}

		fe.IrrigationRecommendation = this.f.IrrigationRecommendation.value;
		fe.IrrigationMethodId = this.fn.IrrigationMethodId.value;
		fe.UseWells = this.fn.UseWells.value;
		fe.PPMTotal = this.fn.PPMTotal.value;

		if (this.fn.wells && (this.fn.wells as FormArray).length) {
			this.fertilizationEventWellService.saveAllFertilizationWells(this.fn.wells.value as IWell[],
				this.eventId);
		}

		if (this.isFuture) { // if event is in the future, applied value should be wiped
			// update applied values here so user isn't heavily penalized for toggling event date
			this.f.FertilizerApplied.setValue(null);
			this.f.NApplied.setValue(null);
		}

		if (this.form.invalid) {
			return;
		}

		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		try {
			this.fertilizationEventService.update(this.eventId, fe, this.plantingId)
			.then((res) => {
				this.isSaving = false;

				if (!res) {
					return false;
				}

				res.updateServices(EventsType.FERTILIZER, this._originalDate,
					this.plantingId, this.ranchService, this.updateService);

				this.updateService.setEventDialogClosed(); // tell event table to re-display
				this.close();
				this.updateService.setRanchSettingsUpdated('fertilizers');
			});
		} catch (e) {
			this.isSaving = false;

			this._notificationService.generateNotifcation({
				type: eNotificationTypes.ERROR,
				message: e
			});
		}
	}

	public updateRecommendationWithNContribution(e?: MouseEvent): void {
		if (e) {
			e.stopPropagation();
		}

		this._hideNContributionUI();

		this.NContribution.UIVisible = false;

		this._isNContributionCached = true;

		this.originalNContributionProperties = {
			NAppliedFromWater: this.f.NAppliedFromWater.value,
			IrrigationAmount: this.fn.IrrigationAmount.value,
			IrrigationMethodId: this.fn.IrrigationMethodId.value,
			UseWells: this.fn.UseWells.value,
			PPMTotal: this.fn.PPMTotal.value,
			Ratio: this.fn.Ratio.value
		};

		this.currentWells = this.fn.wells.value;
	}

	private _calculateTotalWellPercentage(): void {
		let result = 0;

		for (let well of ((this.fn.wells as FormArray).controls as FormGroup[])) {
			result += well.get('Percentage').value;
		}

		this.totalWellPercentage = result;
	}

	private _clear(): void {
		this.fertilizerDetail = null;
		this.isFuture = false;
		this.isFertilizationsPerMonth = false;
		this.recommendationSummaryVisible = false;

		this.NContribution = {
			UIVisible: false,
			calculatedPPM: 0,
			recommendationHours: null,
			nextFertilizationDate: null,
		}

		this.currentWells = null;
		this._isNContributionCached = false;
		this.irrigationUnit = eIrrigationUnit.HOURS;

		this.loading = {
			nContribution: false,
			irrigationRecommendation: false,
			isInitial: false
		};

		this.totalWellPercentage = null;
		this.units = this.UNIT_GAL_PER_ACRE;
		this.initialDataPull = false;
		this.isNDependent = null;
	}

	private _convertSoilSampleEventDate(): void {
		if (this.f.soilSampleEventId.value === this.NO_SOIL_SAMPLE ||
			this.f.soilSampleEventId.value === this.SOIL_SAMPLE_DROPDOWN_NEW) {

			this.f.SoilSampleEventDate.setValue(null);
		} else {
			let se: IFormSoilSample;

			if (!this.soilSamples || this.soilSamples.length === 0) {
				return;
			}

			se = this.soilSamples.filter(x => x.Id === this.f.soilSampleEventId.value)[0];

			if (!se) {
				return;
			}

			this.f.SoilSampleEventDate.setValue(se.Date);
		}
	}

	private _displayRecommendations(): void {
		jQuery('#fertilizationRecommendationsContainer').css({ 'display': 'initial' });
	}

	private _displayRecommendationSummary(): void {
		jQuery('#fertilizationRecommendationSummary').css({ 'display': 'initial' });
	}

	private _updateRecommendation(): void {

		if (this.form.invalid) {
			return;
		}

		this._convertSoilSampleEventDate();

		if (!this.isNDependent) {
			return;
		}

		this.fertilizationEventService.getNRecommendation(this.plantingId,
			this.form.value as IFertilizationEvent)
			.then((res) => {

				if (!res) {
					return;
				}

				if (this.f.NAppliedFromWater.value === null) {
					this.f.IrrigationEventDate.setValue(res.PreviousIrrigationEventDate);
					this.fn.Ratio.setValue(res.IrrigationUnitConversionRatio);
					this.fn.IrrigationMethodId.setValue(res.LastIrrigationMethodId);
				}

				this.FertilizationRecommendationLbsPerAcre =
					BOKA.NumberUtil.convertToFloat(FertilizationEvent.convertNToFertilizerAmount(
						res.NFertilizationRecommended, this.form.value as IFertilizationEvent), 2);

				// convert to string so don't inadvertently trigger event change warning
				this.NFertilizationRecommended = BOKA.NumberUtil.convertToFloat(res.NFertilizationRecommended, 2);

				if (!this.recommendationSummaryVisible) {
					return;
				}

				this.f.Id.setValue(this.eventId);
				this._convertSoilSampleEventDate();

				this.fertilizationEventService.getRecommendationSummary(this.plantingId,
					this.form.value as IFertilizationEvent)
					.then(summary => {
						if (!this.recommendationSummary) {
							return
						}

						this.recommendationSummary.render(summary);
					});
			});
	}

	private _getAddModel(): Promise<void> {
		return this.fertilizationEventService.getCreateForm(this.plantingId)
			.then((res) => {
				if (!res) {
					return of(null).toPromise();
				}

				this.YieldTarget = res.YieldTarget;
				this._commodityTypeId = res.CommodityTypeId;
				this.cropStages = res.CropStages;
				this.fertilizers = res.Fertilizers;
				this._harvestDate = DateUtility.DotNetToDate(res.endDate);

				if (this._dateDefault) {
					res.EventDate = this._dateDefault;
				} else {
					res.EventDate = null;
				}

				this.isFertilizationsPerMonth = this._isFertilizationsPerMonth(this._commodityTypeId);
				this._initializeForm(res);

				this.isLoaded = true;
				// Modal.resize();
			});
	}

	private _configureDeleteConfirmationText(): void {
		this._deleteConfirmationData = {
			objectName: 'Fertilization Event',
			specificName: null,
			additionalMessage: 'Are you sure you want to delete this fertilization event dated '
			+ (this.f.EventDate.value as Date).toDateString() + '?'
		};
	}

	/**
	 * Get Fertilization Event (calls /Edit)
	 * @param id
	 */
	private _getEditModel(id: number): Promise<void> {

		this.initialDataPull = true;

		return this.fertilizationEventService.getEditModel(id)
			.then((res: FertilizationEvent) => {

				if (!res) {
					this._notificationService.generateNotifcation({
						type: eNotificationTypes.ERROR,
						message: 'Fertilization event failed to load. Try again.'
					});

					return;
				}

				this._originalDate = res.EventDate;
				this.soilSamples = res.SoilSamples.filter(x => DateUtility.isOnOrBefore(x.Date, res.EventDate));
				this.cropStages = res.CropStages;
				this.fertilizers = res.Fertilizers;
				this.lastUpdated = res.LastUpdated;
				this._commodityTypeId = res.CommodityTypeId;
				this.NFertilizationRecommended = res.NFertilizationRecommended;
				this.FertilizationRecommendationLbsPerAcre = res.FertilizationRecommendationLbsPerAcre;
				this._harvestDate = DateUtility.DotNetToDate(res.endDate);
				res.Id = id;
				this.YieldTarget = res.YieldTarget;
				this.isFertilizationsPerMonth = this._isFertilizationsPerMonth(this._commodityTypeId);
				this._initializeForm(res);

				this._configureDeleteConfirmationText();

				if (this.f.IrrigationRecommendation.valid) { // used cached value if available
					this.NContribution.recommendationHours = this._getIrrigationInHours(
						this.f.IrrigationRecommendation.value, this.fn.Ratio.value,
						this.fn.IrrigationMethodId.value);
				}

				if (this.f.NAppliedFromWater.value === null) {
					this.NContribution.UIVisible = false;
				} else {
					this.loading.isInitial = true;
					this.displayNContributionUI(false);
				}

				this._getFertlizerProperties(this.f.FertilizerId.value)
					.then(() => {
						this.isLoaded = true;
					});
			})
	}

	private _isFertilizationsPerMonth(commodityTypeId: number): boolean {
		if (this._commodityTypeId === eCommodityTypes.STRAWBERRY ||
			this._commodityTypeId === eCommodityTypes.CANEBERRY) {

			return true;
		} else {
			return false;
		}
	}

	/**
     * This method is called after getting JSON from server, and converts N into fertilizer amount.
     * In case of alfalfa and 0N based fertilizers, we want to circumvent the conversion.
     *
     * @param id fertilizer ID
     */
	private _getFertlizerProperties(id: number): Promise<void> {

		return this.fertilizerService.getProperties(id)
		.then((res) => {
			if (!res) {
				return;
			}

			this.f.FertilizerName.setValue(res.Name);
			this.f.FertilizerOtherNutrients.setValue(res.OtherNutrients);
			this.f.IsFormulationTypeDry.setValue(res.IsFormulationTypeDry);
			this.f.NPercentage.setValue(res.NPercentage);
			this.f.LbsPerGallon.setValue(res.LbsPerGallon);

			this.warnings = this._shouldShowWarning();

			this._getLbsPerAcreUnits();
		});
	}

	private _getIrrigationInHours(inches: number, ratio: number, methodId: number): number {
		let irrigationEvent: IIrrigationEventEditResponse;

		if (!ratio) {
			// assume this.NContribution always exists
			return;
		}

		irrigationEvent = IrrigationEvent.getEmptyEditResponse();

		irrigationEvent.Ratio = ratio;

		irrigationEvent.IrrigationMethodId = methodId;
		irrigationEvent.SprinklerApplicationRate = ratio;
		irrigationEvent.DripApplicationRate = ratio;
		irrigationEvent.MicroSprinklerApplicationRate = ratio;

		return BOKA.NumberUtil.roundToDecimalPlaces(this.calculateService.
			irrigationConvertToTime(inches, irrigationEvent), 2);
	}

	private _getIrrigationInInches(hours: number, ratio: number, methodId: number): number {
		if (!ratio) {
			return;
		}

		let irrigationObject: IIrrigationObject = {
			IrrigationMethodId: methodId,
			SprinklerApplicationRate: ratio,
			DripApplicationRate: ratio,
			MicroSprinklerApplicationRate: ratio
		}

		return BOKA.NumberUtil.roundToDecimalPlaces(this.calculateService.
			irrigationConvertToInches(hours, irrigationObject), 4);
	}

	private _getLbsPerAcreUnits(): void {
		if (this.f.managerNRecommendation !== null && this.f.NPercentage.value !== 0
			&& !this.initialDataPull && this.f.ManagerFertilizerAmount.invalid) {

			this.f.ManagerFertilizerAmount.setValue(
				BOKA.NumberUtil.convertToFloat(FertilizationEvent.
					convertNToFertilizerAmount(this.f.managerNRecommendation.value,
					this.form.value as IFertilizationEvent), 2)
			);
		}

		this.FertilizationRecommendationLbsPerAcre = BOKA.NumberUtil.convertToFloat(FertilizationEvent.
			convertNToFertilizerAmount(this.NFertilizationRecommended as number,
				this.form.value as IFertilizationEvent), 2)

		if (this.f.NApplied.value === null) {
			this.initialDataPull = false;
			return;
		}

		if (this.initialDataPull && this.f.FertilizerApplied.valid) {
			this.initialDataPull = false;
			return;
		}

		this.f.FertilizerApplied.setValue(
			BOKA.NumberUtil.convertToFloat(FertilizationEvent.convertNToFertilizerAmount(
				this.f.NApplied.value, this.form.value as IFertilizationEvent), 2)
		);

		this.initialDataPull = false;
	}

	private _hideNContributionIrrigation(): void {
		if (this.nContributionIrrigation) {
			jQuery(this.nContributionIrrigation.nativeElement).slideUp('medium');
		}
	}

	private _hideNContributionIrrigationLoader(): void {
		if (this.nContributionLoader) {
			jQuery(this.nContributionIrrigationLoader.nativeElement).slideUp('medium');
		}
	}

	private _hideNContributionLoader(): void {
		if (this.nContributionLoader) {
			jQuery(this.nContributionLoader.nativeElement).slideUp('medium');
		}
	}

	private _hideNContributionUI(): void {
		if (this.nContributionUI) {
			jQuery(this.nContributionUI.nativeElement).slideUp('medium');
		}

	}

	/**
	 *
	 * @param m model
	 */
	private _initializeForm(m: FertilizationEvent): void {
		this.form = this._fb.group({
			Id: [m.Id],
			EventDate: [m.EventDate, [Validators.required,
				FormValidation.maxDateValidator(this._harvestDate)]],
			FertilizerId: [ m.FertilizerId,
				[Validators.required, Validators.min(1)]],

			FertilizationsPerMonth: [m.FertilizationsPerMonth, {
					validators: [ Validators.min(1), Validators.max(10), FormValidation.integerValidator]}],

			DaysToNextFertilization: [m.DaysToNextFertilization, {
				validators: [ Validators.min(1), FormValidation.integerValidator]} ],

			CropStageId: [m.CropStageId ? m.CropStageId : null], // optional
			soilSampleEventId: [-2, [Validators.required]],
			SoilSampleEventDate: [m.SoilSampleEventDate],
			ManagerFertilizerAmount: [m.ManagerFertilizerAmount],
			managerNRecommendation: [m.managerNRecommendation],
			FertilizerApplied: [m.FertilizerApplied],
			NApplied: [m.NApplied],
			LbsPerGallon: [m.LbsPerGallon],
			FertilizerName: [null],
			FertilizerOtherNutrients: [null],
			FormulationTypeId: [m.FormulationTypeId],
			IsFormulationTypeDry: [m.IsFormulationTypeDry],
			NAppliedFromWater: [m.NAppliedFromWater],
			NPercentage: [m.NPercentage],
			IrrigationRecommendation: [m.IrrigationRecommendation],
			IrrigationEventDate: [m.IrrigationEventDate],
		});

		this.maxDate = moment(this._harvestDate).subtract(1, 'days').toDate();

		if (this.eventId) {
			this.f.Id.setValidators(Validators.required);
		}


		if (this.isNDependent) {
			if (this.isFertilizationsPerMonth) {
				this.f.FertilizationsPerMonth.setValidators([Validators.required, Validators.min(0.0000001),
					Validators.max(10), FormValidation.integerValidator()]);

				this.f.DaysToNextFertilization.clearValidators();
				this.f.DaysToNextFertilization.updateValueAndValidity();
			} else {
				this.f.DaysToNextFertilization.setValidators([Validators.required, Validators.min(1),
					FormValidation.integerValidator()]);

				this.f.FertilizationsPerMonth.clearValidators();
				this.f.FertilizationsPerMonth.updateValueAndValidity();
			}
		} else {
			this.f.FertilizationsPerMonth.clearValidators();
			this.f.DaysToNextFertilization.clearValidators();
		}

		if (this.f.Id.value) {
			this.form.markAsDirty();
		}

		if (this.soilSamples) {
			let soilSample: IFormSoilSample;

			soilSample = this.soilSamples.filter(x =>
				DateUtility.areEqualDates(x.Date, this.f.SoilSampleEventDate.value))[0];

			if (soilSample) {
				this.f.soilSampleEventId.setValue(soilSample.Id, { emitEvent: false });
			}
		}

		this._valueChanges();
		this._initializeNContributionForm(m);
	}

	private _initializeNContributionForm(m: FertilizationEvent): void {
		this.nContributionForm = this._fb.group({
			IrrigationMethodId: [m.IrrigationMethodId ? m.IrrigationMethodId :
				m.lastIrrigationMethod ?
				m.lastIrrigationMethod : eIrrigationMethods.SPRINKLER,
				[Validators.required, Validators.min(1)]],
			UseWells: [m.UseWells !== null ? m.UseWells : true, Validators.required],
			wells: this._fb.array([]),
			PPMTotal: [m.PPMTotal ? m.PPMTotal : 0], // see validation in updateValidatorsOnUseWellChange
			IrrigationAmount: [m.IrrigationAmount,
				[Validators.min(0.00000001), Validators.required]],
			irrigationAmountHours: [this._getIrrigationInHours(
				m.IrrigationAmount, m.ratio, m.IrrigationMethodId),
				[Validators.min(0.00000001), Validators.required]],
			Ratio: [m.ratio ? m.ratio : 0, Validators.min(0)] // used to convert from inches to hours
		});

		this._updateValidatorsOnUseWellChange();
		this._onNContributionValueChanges();
	}

	private _initializeWells(wells: IWell[]): void {
		let total = 0;
		let temp: FormArray;

		if (!wells || wells.length === 0) {
			return;
		}

		temp = this._fb.array([]);

		for (let well of wells) {
			let g: FormGroup;

			g = this._fb.group({
				Id: [well.Id, Validators.required],
				WellId: [well.WellId, Validators.required],
				Percentage: [well.Percentage, Validators.required],
				Name: [well.Name, Validators.required],
				NitrogenPPM: [well.NitrogenPPM, Validators.required]
			});

			g.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
				this._calculateTotalWellPercentage();
			});

			temp.push(g);

			total += well.Percentage;
		}

		this.fn.wells = temp;
		this.totalWellPercentage = total;
	}

	private _onDateChanged(): void {
		if (this.NContribution.UIVisible === true) {
			this._updateNContributionRecommendation();
		}

		this._refreshSoilSamples().then(() => { // refresh soil sample list based on date
			this._updateRecommendation();
		});
	}

	private _onNContributionValueChanges(): void {
		this.fn.IrrigationMethodId.valueChanges.subscribe(() => {
			this._updateNContributionRecommendation();
		});

		this.fn.UseWells.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this._updateValidatorsOnUseWellChange();
		});

		this.fn.IrrigationAmount.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			let hours: number = this._getIrrigationInHours(
				this.fn.IrrigationAmount.value,
				this.fn.Ratio.value, this.fn.IrrigationMethodId.value);

			this.fn.irrigationAmountHours.setValue(hours, { emitEvent: false });
			this.calculateNContribution(true)
		});

		this.fn.irrigationAmountHours.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			let inches: number = this._getIrrigationInInches(
				this.fn.irrigationAmountHours.value,
				this.fn.Ratio.value, this.fn.IrrigationMethodId.value);

			this.fn.IrrigationAmount.setValue(inches, { emitEvent: false });

			this.calculateNContribution(true);
		});
	}

	private _refreshSoilSamples(): Promise<void> {
		return this.soilSampleEventService.getListForPlanting(this.plantingId)
			.then(res => {

				if (!res || res.length === 0) {
					// in some cases, there will be no soil samples available for
					// a fertilization event
					return;
				}

				this.soilSamples = res.filter(x => DateUtility.isOnOrBefore(x.Date,
					this.f.EventDate.value));

				for (let soilSample of this.soilSamples) {
					if (soilSample.Id === this.f.soilSampleEventId.value) {
						this._selectSoilSample();
					}
				}
			});
	}

	private _resetNConributionView(): void {
		if (!this.nContributionUI || !this.nContributionLoader || !this.nContributionIrrigationLoader
			|| !this.nContributionIrrigation) {
			return;
		}

		if (!this.NContribution.UIVisible) {
			if (this.loading.nContribution) {
				this.render.setStyle(this.nContributionLoader.nativeElement, 'display', 'block');
			}
		} else {
			this.render.setStyle(this.nContributionUI.nativeElement, 'display', 'block');

			if (this.loading.irrigationRecommendation) {
				this.render.setStyle(this.nContributionIrrigationLoader.nativeElement, 'display', 'block');
				this.render.setStyle(this.nContributionIrrigation.nativeElement, 'display', 'none');
			}
		}
	}

	// Triggers when user touches Soil Sample dropdown
	private _selectSoilSample(): void {

		if (this.f.soilSampleEventId.value === this.SOIL_SAMPLE_DROPDOWN_NEW) {
			if (this.NContribution.UIVisible) {
				this.cancelIncludingNContribution();
			}
		} else {
			this._updateRecommendation();
			// for iPad, need to manually force Angular to recheck in order to ensure recommendation area is rendered
			this.changeDetectorRef.detectChanges();
			this._displayRecommendations();
		}

		this._getLbsPerAcreUnits();
	}

	private _shouldShowWarning(): FertilizationEventWarningTypes[] {
		let result: number[] = new Array();

		if (!this.form) {
			return result;
		}

		if (!this.YieldTarget && this.isTree) {
			result.push(FertilizationEventWarningTypes.NO_YIELD);
		}

		if ((this.f.FormulationTypeId.value === eFertilizerFormulationTypes.WET
			|| this.f.IsFormulationTypeDry.value === false) &&
			this.f.LbsPerGallon.value === 0) {

			result.push(FertilizationEventWarningTypes.ZERO_LBS_PER_GALLON);
		}

		return result;
	}

	private _showNContributionIrrigation(): void {
		if (this.nContributionIrrigation) {
			jQuery(this.nContributionIrrigation.nativeElement).slideDown('medium');
		}
	}

	private _showNContributionIrrigationLoader(): void {
		if (this.nContributionLoader) {
			jQuery(this.nContributionIrrigationLoader.nativeElement).slideDown('medium');
		}
	}

	private _showNContributionLoader(): void {
		if (this.nContributionLoader) {
			jQuery(this.nContributionLoader.nativeElement).slideDown('medium');
		}
	}

	private _showNContributionUI(): void {
		if (this.nContributionUI) {
			jQuery(this.nContributionUI.nativeElement).slideDown('medium');
		}
	}

	private _totalPercentageMustEqualValue(): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let total = 0;

			for (let well of ((control as FormArray).controls as FormGroup[])) {
				total += well.get('Percentage').value;
			}

			if (total < 0) {
				return { 'totalPercentageTooLow': true };
			} else if (total > 100) {
				return { 'totalPercentageTooHigh': true }
			} else {
				return null;
			}
		};
	}

	private _updateEventsList(res: IEventPostResponseJSON): void {
		let eventGroup: EventGroup;

		if (!res || !res.Events || res.Events.length === 0) {
			return;
		}

		eventGroup = new EventGroup();
		eventGroup.convert(res.Events[0], DateUtility.DotNetToDate(res.WetDate));
		eventGroup.Id = res.Id;
		eventGroup.ActiveEvents = res.ActiveEvents;

		this.ranchService.plantings.addEventToPlantings(eventGroup,
			this.plantingId);

		// update event table and event snapshot...
		this.updateService.setPlantingsUpdated(this.updateService.currentRanchId, new Date());
	}

	/**
	 * Method is called when:
	 *
	 * 1) user changes the fertilization event date
	 * 2) user changes irrigation method (why? should only update unit conversion)
	 * 3) user updates fertilizations per month (why? how does that change the N
	 * contribution recommendation?)
	 *
	 * @param $methodId Can be either string or number, since it's coming from a dropdown
	 */
	private _updateNContributionRecommendation() {
		if (!this.NContribution.UIVisible) {
			return;
		}

		if (this.form.invalid) {
			return;
		}

		this.loading.irrigationRecommendation = true;

		this.NContribution.nextFertilizationDate = FertilizationEvent.getNextFertilizationDate(
			this.f.EventDate.value,
			this.f.FertilizationsPerMonth.value, this.f.DaysToNextFertilization.value);

		this.irrigationEventService.getNContributionRecommendation(this.plantingId,
			this.f.EventDate.value, this.fn.IrrigationMethodId.value,
			this.f.DaysToNextFertilization.value,
			this.f.FertilizationsPerMonth.value).then((response) => {

				if (!response) {
					throw new Error('response is empty');
				}

				this.fn.Ratio.setValue(response.ratio);
				this.f.IrrigationRecommendation.setValue(response.irrigationAmountRecommended);

				this.NContribution.recommendationHours = this._getIrrigationInHours(
					this.f.IrrigationRecommendation.value, this.fn.Ratio.value,
					this.fn.IrrigationMethodId.value);

				this.fn.IrrigationAmount.setValue(this.f.IrrigationRecommendation.value);

				this.calculateNContribution();
				this.loading.irrigationRecommendation = false;
			});
	}

	private _updateValidatorsOnUseWellChange(): void {
		if (this.fn.UseWells.value === false) {
			this.fn.PPMTotal.setValidators([
				Validators.required,
				Validators.min(0)
			]);

			this.fn.wells.setValidators(this._totalPercentageMustEqualValue);
		} else {
			this.fn.PPMTotal.setValidators([
				Validators.min(0)
			]);

			this.fn.wells.setValidators([
				Validators.required,
				this._totalPercentageMustEqualValue
			]);
		}
	}

	private _valueChanges(): void {
		this.f.EventDate.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.isFuture = DateUtility.isFuture(this.f.EventDate.value);
			this._onDateChanged();
		});

		this.f.FertilizerId.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.fertilizerDetail = null;

			if (this.f.FertilizerId.valid) {
				this._getFertlizerProperties(this.f.FertilizerId.value).then(() => {
					this._updateRecommendation();
				});
			}
		});

		this.f.FertilizationsPerMonth.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.form.updateValueAndValidity({emitEvent: false, onlySelf: true});

			if (this.f.FertilizationsPerMonth.valid) {
				this._updateRecommendation();
				this._updateNContributionRecommendation();
			}
		});

		this.f.DaysToNextFertilization.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {

			this.form.updateValueAndValidity({emitEvent: false, onlySelf: true});

			if (this.f.DaysToNextFertilization.valid) {
				this._updateRecommendation();
				this._updateNContributionRecommendation();
			}
		});

		this.f.soilSampleEventId.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			if (this.f.soilSampleEventId.value === this.SOIL_SAMPLE_DROPDOWN_NEW) {
				this.f.EventDate.disable({emitEvent: false});
				this.f.soilSampleEventId.disable({emitEvent: false});
				this.f.CropStageId.disable({emitEvent: false});
				this.f.FertilizationsPerMonth.disable({emitEvent: false});
				this.f.DaysToNextFertilization.disable({emitEvent: false});
				this.f.FertilizerId.disable({emitEvent: false })
			} else {

			}

			this._selectSoilSample();
		});
	}
}
