<admin-nav></admin-nav>
<div class="bk-admin__page">

	<h1>
		{{ stationName }} ({{ stationId }})
	</h1>

	<h5 class="bk-text--gray">
		{{'ET Data in the last 180 days' | translate }}
	</h5>

	<div class="bk-chart" #chartContainer></div>

    <h5 class="bk-text--gray">
		{{'Missing Records for Station' | translate }} {{ stationId }} {{ stationName }}
	</h5>

    <div class="bk-data-table bk-data-table__weather-stations">

        <div class="bk-data-table__content">
            <mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="Date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Date' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.Date | date:'M/d/yyyy'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Status' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.Status}} </mat-cell>
				</ng-container>
				
				<!--
				<ng-container matColumnDef="Action">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Status' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="bk-data-table__cell-overflow">
                        
						<button type="button"
							class="bk-button bk-split-button__button"
							(click)="rescan(row.Date)">
							{{ 'Rescan' | translate }}
						</button>
                    </mat-cell>
				</ng-container>
				-->

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

            </mat-table>

            <mat-paginator [pageSizeOptions]="[10, 25]"></mat-paginator>
        </div>
    </div>
</div>