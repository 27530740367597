import { Injectable } from '@angular/core';

import { HttpService, HttpServiceGetOptions } from '../../services/http.service';
import { IHistoricalETService, IHistoricalETViewModel, HistoricalETViewModel } from './interfaces';
import { HttpParams } from '@angular/common/http';
import { DateUtility } from '../../classes/dateUtility';


@Injectable()
export class HistoricalETService implements IHistoricalETService {

	private urls = {
		get: (stationId: number) => `/v2/historical-et/${stationId}.json`
	}

	constructor(private httpService: HttpService,
	) { }

	public async get(stationId: number, start: Date, end: Date): Promise<IHistoricalETViewModel[]> {

		let options: HttpServiceGetOptions;
		let params: HttpParams;
		let result: IHistoricalETViewModel[];

		params = new HttpParams()
			.set('start', DateUtility.ConvertToUTC(start).toISOString())
			.set('end', DateUtility.ConvertToUTC(end).toISOString());

		options = {
			url: this.urls.get(stationId),
			searchParams: params,
			isWebAPI: true,

			callback: (response: HistoricalETViewModel[]): IHistoricalETViewModel[] => {
				let r: IHistoricalETViewModel[];

				r = [];

				for (let item of response) {
					r.push({
						Date: DateUtility.DotNetToDate(item.Date),
						ET: item.ET,
					});
				}

				return r;
			}
		}

		result = await this.httpService.get(options);

		return result;
	}
}
