import { Component, OnInit, OnDestroy, AfterViewInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { TokenService } from '../../../services/token.service';
import { UpdateService } from '../../../services/update.service';
import { loginTypes } from '../../../services/constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainMenuUpdateService } from '../../main-menu/main-menu-update.service';
import { LoginCredentials } from '../../../services/interface';
import { environment } from '../../../../environments/environment';

/**
 * google identity object is globally accessible
 */
declare var google: GoogleIdentity;

export interface GoogleIdentity {
	accounts: {
		id: {
			initialize: (p: IdConfiguration) => void,
			prompt: () => void,
			renderButton: (targetHTML: HTMLElement, buttonConfig: GsiButtonConfiguration) => void,
			disableAutoSelect: () => void
		}
	}
}

/**
 * Google Identity button render options
 */
export interface GsiButtonConfiguration {
	type: string, // "standard" | "icon"
	theme: string, // "outline" | "filled_blue" | "filled_black"
	size: string, // "large" | "medium" | "small"
	text: string, // "signin_with" | "signup_with" | "continue_with" | "signin"
	shape?: string, // "rectangular" | "pill" | "circle" | "square"
	logo_alignment?: string, // "left" | "center"
	width: string,
	locale?: string, // e.g. "zh_CN"
}

/**
 * https://developers.google.com/identity/gsi/web/reference/js-reference
 * Google Identity Id configuration
 */
export interface IdConfiguration {
	client_id: string,
	auto_select?: boolean, // enables automatic selection
	callback: (googleUser: CredentialResponse) => any,
	login_url?: string,
	native_callback?: () => any,
	cancel_on_tap_outside?: boolean,
	prompt_parent_id?: string,
	nonce?: string,
	context?: string, // the title and words in the One Tap prompt
	state_cookie_domain?: string,
	ux_mode?: string // "popup" or "redirect"
	allowed_parent_origin?: string | string[],
	intermediate_iframe_close_callback?: () => any,
	itp_support?: boolean,
}

export interface CredentialResponse {
	clientId: string,
	credential: string,
	select_by: string,
}

@Component({
	selector: 'app-login',
	templateUrl: 'login.component.html',
	styleUrls: [ 'login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

	public isProcessing = false;
	public isWorkshop = false;
	public passwordIsHidden = true;

	public loginCredentials: LoginCredentials;

	public loginFailed = false;
	private _subscriptions$: Subject<boolean>;
	private _isGoogleLoaded = false;

	constructor(
		private _router: Router,
		private _route: ActivatedRoute,
		private _loginService: LoginService,
		private _updateService: UpdateService,
		private ngZone: NgZone,
		private _mainMenuUpdateService: MainMenuUpdateService,
		private _tokenService: TokenService) { }

	ngOnInit(): void {
		this._subscriptions$ = new Subject();

		this._route.data.pipe(takeUntil(this._subscriptions$)).subscribe(params => {
			if (params && params['type'] === loginTypes.WORKSHOP) {
				this.isWorkshop = true;
			} else {
				this.isWorkshop = false;
			}
		});

		this.loginCredentials = {
			username: '',
			password: ''
		}
	}

	ngAfterViewInit(): void {
		window.onload = () => {
			// this._initializeGoogleIdentity();
		};

		// this._initializeGoogleIdentity();
	}

	private _initializeGoogleIdentity(): void {
		if (this._isGoogleLoaded) {
			return;
		}

		if (!google) {
			return;
		}

		this._isGoogleLoaded = true;

		google.accounts.id.initialize({
			client_id: environment.GoogleClientId,
			callback: (response) => this.ngZone.run(() => {
				this.loginCredentials.GoogleJWT = response.credential;
				this.login();
			})
		});

		google.accounts.id.prompt(); // show one-click prompt

		google.accounts.id.renderButton(
			document.getElementById('googleSignInBtn'),
			{ type: 'standard', text: 'signin_with', theme: 'outline',
			size: 'medium', width: '320'}
		);
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public login(): void {
		this.isProcessing = true;

		this._loginService.login(this.loginCredentials)
			.then(response => {

				if (!response || !response.token || !response.expires) {
					this.loginFailed = true;
					this.isProcessing = false;
					return;
				}

				this.loginFailed = false;
				this._tokenService.setToken(response);
				this._updateService.updateAuthToken();
				this._mainMenuUpdateService.fireAuthStatusChanged();

				if (this.isWorkshop) {
					this._loginService.addToUCCE().then(() => {
						this.isProcessing = false;
						this.goToRanches();
					})
				} else {
					this.isProcessing = false;
					this.goToRanches();
				}
			});
	}

	private goToRanches() {
		this._router.navigate(['MyDashboard']);
	}
}
