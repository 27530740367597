<admin-nav></admin-nav>
<div class="bk-admin__tab-container">
    <h5 class="bk-text--gray">{{'Localization' | translate }}</h5>

	<div class="bk-nav__button-container bk-flex">

		<div class="bk-flex-container">
            <button *ngIf="!addingSystemLabel && !editingSystemLabel"
                (click)="addingSystemLabel = true"
                class="bk-button bk-nav__button bk-button--small">

				<span class="bk-nav__button-icon">+</span>
				<span class="bk-nav__button-text">{{'Add' | translate }} {{'System Label' | translate }}</span>
			</button>

            <button *ngIf="addingSystemLabel || editingSystemLabel"
                class="bk-button bk-nav__button bk-button--small" disabled>
				<span class="bk-nav__button-icon">+</span>
				<span class="bk-nav__button-text">{{'Add' | translate }} {{'System Label' | translate }}</span>
			</button>
		</div>
		<label class="bk-data-table__search--label">
			Search: <input class="bk-data-table__search--input" [(ngModel)]="searchQuery"
				(ngModelChange)="applyFilter()" type="text" value="" />
		</label>
	</div>

	<!-- table START -->

    <table class="bk-admin__table">
        <thead>
            <tr>
                <th width="30%">{{'System Label' | translate }}</th>
				<th width="20%">{{'Action' | translate}}</th>
                <th *ngFor="let language of languages">{{language.Language}}</th>
            </tr>
        </thead>
        <tbody>
            <tr class="bk-admin__table__row" *ngIf="addingSystemLabel">
                <td>
                    <input type="text" [(ngModel)]="newSystemLabel.keyword" />
				</td>
				<td>

                    <button class="bk-button bk-admin__button bk-button--text-only"
                        (click)="cancelAddingSystemLabel()">
						{{'Cancel' | translate }}
					</button>

                    <button class="bk-button bk-admin__button"
                        (click)="addSystemLabel()">
						{{'Save' | translate }}
					</button>
                </td>
                <td *ngFor="let language of languages">
                    <ng-container *ngIf="newSystemLabel.translations[language.Id]">
                        <input type="text" [(ngModel)]="newSystemLabel.translations[language.Id].word" />
                    </ng-container>
                </td>
            </tr>
            <ng-container *ngFor="let entry of translations">

                <tr class="bk-admin__table__row" *ngIf="!entry.editing">
                    <td>
                        <span>{{entry.keyword}}</span>
					</td>
					<td>

                        <button class="bk-button bk-admin__button bk-button--text-only"
                            (click)="onDelete(entry)"
							[disabled]="addingSystemLabel || editingSystemLabel">
							{{'Delete' | translate }}
						</button>

                        <button class="bk-button bk-admin__button"
                            (click)="editSystemLabel(entry)"
							[disabled]="addingSystemLabel || editingSystemLabel">
							{{'Edit' | translate }}
						</button>
                    </td>
                    <td *ngFor="let language of languages">
                        <span *ngIf="entry.translations[language.Id]">
							{{entry.translations[language.Id].word}}
						</span>
                    </td>
                </tr>

                <tr class="bk-admin__table__row" *ngIf="entry.editing">
                    <td>
                        <input type="text" [(ngModel)]="newSystemLabel.keyword" />
					</td>
					<td>

                        <button class="bk-button bk-admin__button bk-button--text-only"
                            (click)="cancelEditingSystemLabel(newSystemLabel)">
							{{'Cancel' | translate }}
						</button>

                        <button class="bk-button bk-admin__button"
                            (click)="saveSystemLabel()">
							{{'Save' | translate }}
						</button>
                    </td>
					<td *ngFor="let language of languages">
						<ng-container *ngIf="newSystemLabel.translations[language.Id]">
							<input type="text" [(ngModel)]="newSystemLabel.translations[language.Id].word" />
						</ng-container>
					</td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
