<admin-nav></admin-nav>

<div *ngIf='cropType'
	class="bk-admin__page">
	
	<div class="bk-form__header">
		<h5 class="bk-text--gray">
			{{ 'Edit' | translate}} {{ 'Previous Crop' | translate}}
		</h5>
	</div>

	<form #f="ngForm">
		<div class="bk-form__wrapper" >

			<label class="bk-modal__label">
				{{'Name' | translate }}
			</label>
			<input class="bk-modal__field bk-form__input-text--wide"
				type="text"
				required
				name="Name"
				[(ngModel)]="cropType.Name"/>

			<label class="bk-modal__label">
				{{'Commodity Type' | translate }}
			</label>
			<select class="bk-modal__field"
				name="CommodityTypeId"
				required
				[(ngModel)]="cropType.CommodityTypeId">

				<option [ngValue]="0"
					selected>
					{{'Select One' | translate }}
				</option>
				<option *ngFor="let commodityType of commodityTypes"
					[ngValue]="commodityType.Id">
					{{commodityType.Name}}
				</option>
			</select>

			<label class="bk-modal__label">
				{{'Crop Mineralization Rate' | translate }}
			</label>
			<input class="bk-modal__field bk-form__input-text--float"
				type="number"
				name="CropMineralizationRate"
				required
				[(ngModel)]="cropType.CropMineralizationRate" />

			<label class="bk-modal__label">
				{{'Previous Crop K' | translate }}
			</label>
			<input class="bk-modal__field bk-form__input-text--float"
				type="number"
				name="PrevCropK"
				required
				[(ngModel)]="cropType.PrevCropK" />
		</div>
		<!-- FOOTER -->

		<div class="bk-form__footer modal-footer bk-modal__footer">
			<button class="bk-button bk-button--medium bk-button--text-only"
				(click)="goBack()">
				{{ 'Cancel' | translate }}
			</button>

			<button
				class="bk-button"
				[disabled]='!f.form.valid'
				(click)="update()">
				{{ 'Save' | translate }}
			</button>
		</div>
	</form>
</div>