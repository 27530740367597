<div class="bk-login__container">
	<form [formGroup]="form"
		class="form-horizontal">

		<div class="bk-modal bk-modal--register">
			<div class="bk-modal__header">
				<h4 class="bk-modal__header-title">Create a New Account</h4>
			</div>
			<div class="bk-modal__body">
				<div class="bk-login__new-form">
					<div *ngIf="errorMessages"
						class="validation-summary-valid bk-login__register--errors">
						<ul>
							<li *ngFor="let error of errorMessages">
								{{ error }}
							</li>
						</ul>
					</div>
					<table cellspacing="0" class="bk-table-for-mat-form">
						<tr>
							<td>
								<mat-form-field>
									<mat-label>First Name</mat-label>
									<input matInput
										name="FirstName"
										formControlName="FirstName"
										placeholder=""
										tabindex="1"
										value="">
									<mat-error *ngIf="f.FirstName.invalid && (f.FirstName.dirty || f.FirstName.touched)">
										{{ 'First name is required' }}
									</mat-error>
								</mat-form-field>
							</td>
							<td>
								<mat-form-field>
									<mat-label>Last Name</mat-label>
									<input matInput
										name="LastName"
										formControlName="LastName"
										placeholder=""
										tabindex="2"
										value="">
									<mat-error *ngIf="f.LastName.invalid && (f.LastName.dirty || f.LastName.touched)">
										{{ 'Last name is required' }}
									</mat-error>
								</mat-form-field>
							</td>
						</tr>
					</table>
					
					<mat-form-field>
						<mat-label>Email</mat-label>
						<input matInput
							name="Email"
							formControlName="Email"
							placeholder=""
							type="Email"
							tabindex="3"
							value="">
						<mat-error *ngIf="f.Email.invalid && (f.Email.dirty || f.Email.touched)">
							<div *ngIf="f.Email.errors.required">
								{{ 'Email is required' }}
							</div>
							<div *ngIf="f.Email.errors.noPlusSign">
								{{ 'Email cannot contain a plus sign.' }}
							</div>
						</mat-error>
					</mat-form-field>

					<div class="bk-clearfix"></div>
					<mat-form-field>
						<mat-label>Password</mat-label>
						<input matInput
							name="Password"
							formControlName="Password"
							tabindex="4"
							[type]="passwordIsHidden ? 'password' : 'text'"
							autocomplete="new-password">

							<button mat-icon-button matSuffix (click)="passwordIsHidden = !passwordIsHidden">
								<mat-icon>
									{{passwordIsHidden ? 'visibility_off' : 'visibility'}}
								</mat-icon>
							</button>
						<mat-error *ngIf="f.Password.invalid && (f.Password.dirty
						|| f.Password.touched)">
							{{ getPasswordErrorMessage(f.Password) }}
						</mat-error>
					</mat-form-field>
					
					<mat-form-field>
						<mat-label>Confirm password</mat-label>
						<input matInput
							name="ConfirmPassword"
							formControlName="ConfirmPassword"
							tabindex="5"
							[type]="passwordIsHidden ? 'password' : 'text'"
							autocomplete="new-password">
							<button mat-icon-button matSuffix (click)="passwordIsHidden = !passwordIsHidden">
								<mat-icon>
									{{passwordIsHidden ? 'visibility_off' : 'visibility'}}
								</mat-icon>
							</button>
						<mat-error *ngIf="f.ConfirmPassword.invalid && (f.ConfirmPassword.dirty ||
							f.ConfirmPassword.touched)">
							{{ getPasswordErrorMessage(f.ConfirmPassword) }}
						</mat-error>
					</mat-form-field>
		
					<div class="bk-login__form-group">
						<button  mat-button
							class="bk-button bk-button--medium bk-dialog__button--primary"
							(click)="register()"> <!-- we're actually updating an existing data here -->
							{{(isSaving ? 'Creating' : 'Create') | translate }} {{ 'Account' | translate }}
							<img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
						</button>
					</div>
					<!--
					<div class="bk-identity-signin-divider">
						<hr>
						<span>Or</span>
						<hr>
					</div>
					<div id="googleSignInBtn"></div>
					 -->
					<br>
					<div class="bk-login__form-group bk-login__register-text">
						If you already have an account, please <a routerLink="/login">Sign in</a>
					</div>
					
				</div>
			</div>
		</div>
	</form>
</div>