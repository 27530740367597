import * as Highcharts from 'highcharts';
require('highcharts/modules/exporting')(Highcharts);

import { DateUtility } from '../../classes/dateUtility';

import { IHighchartsLine, IHighchartsXAxis } from '../../interfaces/views/highCharts';
import { IWeatherStationGraphRecord, IWeatherStationStatsGraphData } from './interfaces';
import highChartExportOptions from '../shared/highchartsExportOptions';

export class WeatherStationStatsCharts {

	public static convertData(data: IWeatherStationGraphRecord[]): IWeatherStationStatsGraphData {
		let graphData: IWeatherStationStatsGraphData;
		let ranches: IHighchartsLine;
		let recommendations: IHighchartsLine;
		let date: Date;
		let utc: number;

		if (!data) {
			return;
		}

		ranches = {
			name: 'Ranches',
			data: new Array()
		}

		recommendations = {
			name: 'Recommendations',
			data: new Array()
		}

		data = data.sort((a, b) => {
			return DateUtility.DotNetToDate(a.EventDate).valueOf() - DateUtility.DotNetToDate(b.EventDate).valueOf();
		});

		for (let graphRecord of data) {
			date = DateUtility.DotNetToDate(graphRecord.EventDate);
			utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes());

			ranches.data.push(new Array(utc, graphRecord.RanchCount));
			recommendations.data.push(new Array(utc, graphRecord.RecommendationCount));
		}

		graphData = {
			ranches: ranches,
			recommendations: recommendations
		}

		return graphData;
	}

	public static generateHighchart(chartElement: HTMLElement, params: { graphData: IWeatherStationStatsGraphData, daily: boolean }): void {
		let ranchesColor = '#0090FF';
		let recommendationsColor = '#E94957';

		let options: Highcharts.Options;
		let data: IHighchartsLine[];
		let xAxis: IHighchartsXAxis;
		let tooltipFormatter: () => string | boolean;

		if (!params || !params.graphData) {
			return;
		}

		xAxis = {
			type: 'datetime',
			gridLineWidth: 1
		}

		if (params.daily) {
			xAxis.dateTimeLabelFormats = {
				day: '%b %e \'%y',
				week: '%b %e \'%y',
				month: '%b %Y',
				year: '%b %Y'
			};

			tooltipFormatter = function () {
				let name: string = this.series.name;
				let utc: number = this.x;
				let months: string[] = DateUtility.monthsAsStrings;
				let element: string;
				let date: Date;

				date = new Date(0);
				date.setUTCMilliseconds(utc);

				element = '<span class="bk-highcharts__tooltip-text">' + months[date.getMonth()] + ' ' + date.getDate() + ' ' +
					date.getFullYear() + ' - ' + this.y + ' ' + name + '</span>';

				return element;
			};
		} else {
			xAxis.labels = {
				format: '{value: %b %Y}'
			}

			tooltipFormatter = function () {
				let name: string = this.series.name;
				let utc: number = this.x;
				let months: string[] = DateUtility.monthsAsStrings;
				let element: string;
				let date: Date;

				date = new Date(0);
				date.setUTCMilliseconds(utc);

				element = '<span class="bk-highcharts__tooltip-text">' + months[date.getMonth()] + ' ' +
					date.getFullYear() + ' - ' + this.y + ' ' + name + '</span>';

				return element;
			}
		}

		params.graphData.ranches.color = ranchesColor;
		params.graphData.ranches.marker = {
			symbol: 'circle'
		};

		params.graphData.recommendations.yAxis = 1;
		params.graphData.recommendations.color = recommendationsColor;
		params.graphData.recommendations.marker = {
			symbol: 'square'
		};

		data = new Array(params.graphData.ranches, params.graphData.recommendations);

		options = {
			chart: {
				type: 'line',
				width: 900,
				height: 250
			},
			title: {
				text: null
			},
			tooltip: {
				backgroundColor: '#FFF',
				borderColor: '#FFF',
				formatter: tooltipFormatter
			},
			xAxis: xAxis,
			yAxis: [{
				title: {
					text: 'Ranches'
				},
				allowDecimals: false,
				labels: {
					style: {
						color: ranchesColor
					}
				},
				min: 0
			},
			{
				title: {
					text: 'Recommendations'
				},
				allowDecimals: false,
				opposite: true,
				labels: {
					style: {
						color: recommendationsColor
					}
				},
				min: 0
			}],
			series: data,
			exporting: highChartExportOptions,
			plotOptions: {
				series: {
					events: {
						legendItemClick: function () {
							return false;
						}
					}
				}
			}
		}

		Highcharts.chart(chartElement, options);
	}
}
