import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { HttpService, HttpServicePostOptions, HttpServiceGetOptions } from '../../services/http.service';
import { DateUtility } from '../../classes/dateUtility';
import { CutEvent, ICutEventRecency } from './cutEvent';

import { IMaturity } from '../../models/maturity/interfaces';
import { RanchService } from '../ranch-settings/service';
import { IEventPostResponseJSON, IEventPostResponse } from '../../models/event/interfaces';
import { EventGroup } from '../../models/event/event';
import { UpdateService } from '../../services/update.service';
import { ICutEventJSON } from './cut-event.interfaces';
import { ICutEvenService } from './interfaces';
import { EventResponse } from '../../models/event/eventResponse';

@Injectable()
export class CutEventService implements ICutEvenService {

	private _urls = {
		save: '/v2/cut-events.json',
		get: (id: number) => `/v2/cut-events/${id}.json`,
		delete: (id: number) => `/v2/cut-events/${id}.json`,
		maturities: {
			list: '/v2/maturities.json'
		},
	}

	private _maturities: IMaturity[];

	constructor(private _httpService: HttpService,
		private _updateService: UpdateService,
		private _ranchService: RanchService
	) { }

	public save(plantingId: number, eventDate: Date, maturityId: number = null,
		cutYield: number = null, isBookmark: boolean = null, id: number = null, dateOnly = false):
		Promise<EventResponse> {

		let options: HttpServicePostOptions;

		let body: {
			plantingId: number,
			eventDate: Date,
			maturityId: number,
			yield: number,
			IsBookmark: boolean,
			id: number,
			DateOnly: boolean
		};

		if (!plantingId || !eventDate) {
			return of(null).toPromise();
		}

		body = {
			plantingId: plantingId,
			eventDate: DateUtility.ConvertToUTC(eventDate),
			maturityId: maturityId,
			yield: cutYield,
			IsBookmark: isBookmark,
			id: id,
			DateOnly: dateOnly
		}

		options = {
			body: body,
			url: this._urls.save,
			isWebAPI: true,

			callback: (response: IEventPostResponseJSON): EventResponse => {
				let result: EventResponse;

				result = new EventResponse(response);
				result.updateSucceedingEvents(plantingId, this._ranchService);

				return result;
			}
		}

		return this._httpService.post(options);
	}

	public listMaturities(): Promise<IMaturity[]> {
		let options: HttpServiceGetOptions;

		if (this._maturities) {
			return of(this._maturities).toPromise();
		}

		options = {
			url: this._urls.maturities.list,
			useLocalStorage: true,
			callback: (response: IMaturity[] ) => {
				this._maturities = response;

				return this._maturities;
			},
			isWebAPI: true
		}

		return this._httpService.get(options);
	}

	public get(id: number, plantingId: number): Promise<CutEvent> {
		let options: HttpServiceGetOptions;

		options = {
			url: this._urls.get(id),
			isWebAPI: true,

			callback: (event: ICutEventJSON) => {
				let cutEvent: CutEvent;

				if (!event) {
					return null;
				}

				cutEvent = new CutEvent(plantingId);
				cutEvent.transmuteJSON(event);

				return cutEvent;
			}
		}

		return this._httpService.get(options);
	}

	public delete(id: number, plantingId: number): Promise<EventGroup[]> {
		let options: HttpServicePostOptions;

		if (!plantingId || !id) {
			return of(null).toPromise();
		}

		options = {
			body: null,
			url: this._urls.delete(id),
			isWebAPI: true,

			callback: (response: IEventPostResponseJSON): EventGroup[] => {
				let eventGroupsSucceeding: EventGroup[];

				EventGroup.updateEvents(response.EventGroupsSucceeding, plantingId,
					DateUtility.DotNetToDate(response.WetDate), this._ranchService);

				eventGroupsSucceeding = EventGroup.convertAll(response.EventGroupsSucceeding,
					DateUtility.DotNetToDate(response.WetDate));

				return eventGroupsSucceeding;
			}
		}

		return this._httpService.delete(options);
	}
}
