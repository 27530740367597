<div class="bk-dialog">

	<button type="button"
		class="bk-button bk-dialog__close"
		(click)="close()">

		&times;
	</button>

	<h4 mat-dialog-title
		*ngIf='fertilizer'>
		{{'Edit' | translate }} {{ fertilizer.Name }}
	</h4>

	<div mat-dialog-content
		*ngIf='fertilizer'>
		<fertilizer-edit [fertilizer]='fertilizer'
			(form)='updateFormState($event)'>
		</fertilizer-edit>
	</div>

	<div mat-dialog-actions align="end">

		<button *ngIf='!usage' 
			class="bk-button bk-button--medium bk-dialog__button--secondary
			bk-button--text-only bk-button--float-left"
			(click)="delete()">
			{{'Remove Fertilizer' | translate }}
		</button>

		<button class="bk-button bk-button--medium bk-button--text-only"
			(click)="close()">
			{{'Cancel' | translate }}
		</button>

		<button matt-button
			[disabled]="isFormValid === false"
			class="bk-button bk-button--medium bk-dialog__button--primary"
			(click)="save()">
			{{'Save Changes' | translate }}
		</button>
	</div>
</div>