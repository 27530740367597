<div class="bk-dialog bk-dialog__event">

    <form #f="ngForm">

        <button *ngIf="view === dialogViews.CREATE"
            (click)="close()" 
            type="button"
            class="bk-button bk-dialog__close">

            <span>&times;</span>
        </button>

        <h4 mat-dialog-title>
            {{ 'Add' | translate }} {{ 'Commodity' | translate}}
        </h4>

        <div mat-dialog-content>
            <ng-container *ngIf="view === dialogViews.CREATE">
                <!-- Commodity Types dropdown -->
                <mat-form-field appearance="fill"
                    class="mat-form-field--one-line mat-form-field--short">
                    <mat-label>
                        {{ 'Commodity' | translate }}
                    </mat-label>
                    <mat-select (ngModelChange)="onCommodityTypeSelected($event)" 
                        required
                        name="commodityTypeId"
                        [(ngModel)]="commodityTypeId">
                        <mat-select-trigger *ngIf="selectedCommodityType">
                            {{ selectedCommodityType.Name | translate }} <mat-icon class="cm-mat-select-trigger__mat-icon"
                            *ngIf="!selectedCommodityType.IsActive">construction</mat-icon>
                        </mat-select-trigger>
                        <mat-option [value]="null">
                            {{'Select One' | translate }}
                        </mat-option>
                        <mat-option *ngFor="let commodityType of commodityTypes"
                            [value]="commodityType.Id">
                            {{commodityType.Name | translate }} <mat-icon *ngIf="!commodityType.IsActive">construction</mat-icon>
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- Crop Types checkbox group -->

                <ng-container *ngIf="cropTypes && cropTypes.length > 0">
                    <label>
                        {{ 'Crop Types' | translate }} 
                        <info-icon [title]="'Crop Types Planting Count Help Text'"></info-icon>
                    </label>
        
                    <ng-container *ngFor="let cropType of cropTypes">
                        <div class="bk-modal__row bk-modal__row--no-border">
                            <input type="checkbox"
                            class="bk-modal__checkbox"
                            name="cropType"
                            value="cropType"
                            (change)="onCropTypeChange()"
                            [disabled]="cropType.PlantingsCount > 0"
                            [(ngModel)]="cropType.IsSelected" />
                        
                            <span>
                                {{cropType.Name}} 
                                <span *ngIf="cropType.PlantingsCount > 0">
                                    ({{cropType.PlantingsCount}})
                                </span>
                            </span>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="view === dialogViews.DISCARD_CHANGES">
                <div class="bk-dialog__paragraph">
                    {{ 'The changes you made were not saved' | translate}}. 
                    {{'Discard changes' | translate}}?
                </div>
            </ng-container>
        </div>

        <div mat-dialog-actions align="end">

            <ng-container *ngIf="view === dialogViews.CREATE">
                <button mat-button
                    class="bk-button bk-button--medium bk-button--text-only 
                        bk-dialog__button--secondary"
                    (click)="close()">
                    {{ 'Cancel' | translate }}
                </button>

                <button mat-button
                    class="bk-button bk-button--medium bk-dialog__button--primary"
                    (click)="save()"
                    [disabled]='!f.form.valid || !isFormValid'>
                    {{ 'Save' | translate }}
                </button>
            </ng-container>

            <ng-container *ngIf="view === dialogViews.DISCARD_CHANGES">
                <button mat-button
                    class="bk-button bk-button--medium bk-button--text-only
                        bk-dialog__button--secondary"
                    (click)="cancelConfirmation()">
                    {{ 'Keep Editing' | translate }}
                </button>

                <button mat-button
                    class="bk-button bk-button--medium bk-dialog__button--primary"
                    (click)="discard()">
                    {{ 'Discard' | translate }}
                </button>
            </ng-container>
        </div>
    </form>
</div>