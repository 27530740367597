import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../models/user/service';
import { UpdateService } from '../../services/update.service';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackComponent } from '../feedback/feedback';
import { TokenService } from '../../services/token.service';
import { RanchService } from '../ranch-settings/service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICurrentUser } from '../../models/user/interfaces';
import { MainMenuUpdateService } from './main-menu-update.service';
import { GoogleIdentity } from '../public/login/login.component';
import { DONATE_URL } from '../../services/constants';

declare var google: GoogleIdentity;

@Component({
	moduleId: module.id,
	selector: 'main-menu',
	templateUrl: 'main-menu.component.html',
	styleUrls: ['main-menu.component.scss']
})

export class MainMenuComponent implements OnInit, OnDestroy {
	public visible = false;
	public user: ICurrentUser;
	public donationLink = DONATE_URL;

	public currentPage = 'MyDashboard';
	private _subscriptions$: Subject<boolean>;

	constructor(private router: Router,
		private tokenService: TokenService,
		private _userService: UserService,
		private _dialog: MatDialog,
		private _ranchService: RanchService,
		private _mainMenuUpdateService: MainMenuUpdateService,
		private _updateService: UpdateService) { }

	ngOnInit() {
		this._subscriptions$ = new Subject();

		this._updateService.currentRanchUserReceived$
			.pipe(takeUntil(this._subscriptions$)).subscribe((res) => {

			this.user = res;
		});

		this._updateService.currentPage$
			.pipe(takeUntil(this._subscriptions$)).subscribe((page: string) => {

			this.currentPage = page;
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	open(): void {
		this.visible = true;
	}

	close(): void {
		this.visible = false;
	}

	navigateToMyRanches(): void {
		this.close();
		this.router.navigate(['MyDashboard']);
	}

	navigateToAdministration(): void {
		this.close();
		this.router.navigate(['Administration']);
	}

	navigateToProfile(): void {
		this.close();
		this.router.navigate(['Profile']);
	}

	logout(): void {
		this.close();
		this._ranchService.clearRanchesCache();
		sessionStorage.removeItem(this._userService.urls.userDetails.getCurrentUser.toLowerCase());
		this.tokenService.logOff();
		this._updateService.updateAuthToken(); // update top nav
		this._mainMenuUpdateService.fireAuthStatusChanged();

		/**
		if (google) {
			google.accounts.id.disableAutoSelect();
		}
		**/

		this.router.navigate(['/login']);
	}

	public clickOutside(e: MouseEvent): void {
		let classList: DOMTokenList;

		if (!e || !e.target) {
			return;
		}

		classList = (e != null && e.target != null) ? (e.target as HTMLElement).classList : null;

		if (classList.contains('bk-header__menu-icon') || classList.contains('fa-bars')) {
			return;
		}

		if (this.visible) {
			this.visible = false;
		}
	}

	public openFeedbackDialog(): void {
		this.visible = false; // hide main menu

		this._dialog.open(FeedbackComponent, {
			disableClose: true,
		});
	}
}
