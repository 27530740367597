<div class="bk-dialog bk-dialog__event">
	<h4 mat-dialog-title>
        {{'Recommendations Changed' | translate}}
    </h4>

	<div mat-dialog-content>
		<div class="bk-modal__remove-text">
            {{'Action has changed the recommendation for the following event(s)' | translate }}:
        </div>
        <table class="bk-event-table__affected-events">
            <tr *ngFor="let event of effectedEvents">
                <td class="bk-event-table__affected-events-cell--date">
                    {{event.EventDate | date:'mediumDate'}}
                </td>
                <td class="bk-event-table__affected-events-cell">
                    <div class="bk-planting__event-row__icon bk-button--blue-icon">
                        <svg-water></svg-water>
                    </div>
                    <span *ngIf="event && event.Irrigation">
                        {{event.Irrigation.IrrigationMethod}}
                    </span>
                </td>
            </tr>
        </table>
	</div>

	<div mat-dialog-actions align="end">

		<button class="bk-button bk-button--medium"
            (click)="close($event)">
            {{ 'Continue' | translate}}
        </button>
	</div>
</div>
