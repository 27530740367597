import { AlmondRecommendationSummaryModel } from '../../../../fertilization-event/interfaces';
import { TranslateService } from '../../../../localization/service';
import { EquationGenerator } from './equationGenerator';
import { IGeneratedEquation } from './lettuceEquationGenerator';

export class TreeEquationGenerator extends EquationGenerator {
	protected _s: AlmondRecommendationSummaryModel;

	public constructor(s: AlmondRecommendationSummaryModel,
		translateService: TranslateService) {
		super(s, translateService);
		this._s = s;
	}

	protected _getPrimaryEquation(result: IGeneratedEquation): IGeneratedEquation {
		result.equationDescriptions[1] = `${this._ts.translate('Recommendation')} =
			${this._ts.translate('Crop N Uptake')} - ${this._ts.translate('Total Mineralized N')}`;

		this.equationResult = this._s.NUptakeDifference - this._s.NMineralTotal
			- this._s.NAppliedFromWater;

		result.equations[1] = `${this.equationResult.toFixed(2)} = ${this._s.NUptakeDifference.toFixed(2)} -
				${this._s.NMineralTotal.toFixed(2)}`;

		return result;
	}

	public getEquation(): IGeneratedEquation {
		let result: IGeneratedEquation = {
			equationDescriptions: [],
			equations: []
		};

		result.equationDescriptions[0] = `${this._ts.translate('Seasonal N Recommendation')} =
			(${this._ts.translate('Seasonal Crop N Demand')} - ${this._ts.translate('N Soil Amendments')}) * 100 /
			${this._ts.translate('Fertilizer N Use Efficiency')}`;

		result.equations[0] = `${this._s.MaxUptake.toFixed(2)} = (${this._s.TotalCropNDemand.toFixed(2)} -
            ${this._s.NSoilAmendments.toFixed(2)}) * 100 / ${this._s.UseEfficiency.toFixed(2)}`;

		if (this._s.MaxUptake < 0) {
			result.equations[1] = this._ts.translate('Negative Season N Rec Warning Text') + '.';
			return result;
		}

		result = this._getPrimaryEquation(result);

		if (this.equationResult < 0) {
			this.getNegativeMessage(result, 2);
		} else if (this.isRecommendationClamped()) {
			result = this.getClamped(result, 2);
		}

		result = this.addNinWater(result, 1);

		return result;
	}
}
