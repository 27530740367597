import { RanchFertilizerViewModel, NutrientTotalValidationErrors } from './interfaces';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FertilizerDeleteDialog } from './fertilizer.delete.dialog';
import { Inject } from '@angular/core';
import { Fertilizer } from './fertilizer.class';
import { KeepDiscardDialog } from './keep-discard.dialog';

export class FertilizerBaseDialog {

	protected id: number;
	public usage: number;
	public fertilizer: RanchFertilizerViewModel;

	public nutrientTotalPercentage: {
		total: number,
		validationError: NutrientTotalValidationErrors
	};

	public validationErrors = NutrientTotalValidationErrors;

	constructor(
		protected _dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) protected _data: { id: number, usage: number }
	) {
		if (this._data) { // if adding fertilizers or creating a new one, id will not exist
			this.id = this._data.id;
			this.usage = this._data.usage;
		}

		this.nutrientTotalPercentage = {
			total: 0,
			validationError: null
		};
	}

	protected _openKeepDiscardDialog(): void {
		this._dialog.open(KeepDiscardDialog, {
			width: '690px',
			disableClose: true
		});
	}

	public delete(): void {

		if (!this.fertilizer) {
			throw new Error('fertilizer data is empty');
		}

		if (!this._dialog) {
			throw new Error('FertilizerBaseDialog:delete() - this.dialog is empty');
		}

		this._dialog.open(FertilizerDeleteDialog, {
			width: '690px',
			disableClose: true,
			data: {
				id: this.id,
				name: this.fertilizer.Name
			}
		});
	}

	protected _calculateTotalPercentage(): void {
		this.nutrientTotalPercentage.total = Fertilizer.calculateTotalPercentage(this.fertilizer);

		this.nutrientTotalPercentage.validationError =
			Fertilizer.validateNutrientTotal(this.nutrientTotalPercentage.total);
	}
}
