<div class="bk-nav">
    <div class="bk-nav__title-container">
        <h4 class="bk-nav__title">{{'Profile' | translate }}</h4>
    </div>
    <ul class="bk-nav__items">
        <li [class.bk-nav__item--selected]="tab.selected" class="bk-nav__item" *ngFor="let tab of tabs" (click)="selectTab(tab)">
            {{tab.tabTitle | translate}}
        </li>
    </ul>
</div>
<ng-content></ng-content>
