import { Input, OnChanges, Directive, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

@Directive({
	selector: '[maxNumber]',
	providers: [{ provide: NG_VALIDATORS, useExisting: MaxNumberDirective, multi: true }]
})
export class MaxNumberDirective implements Validator, OnChanges {
	@Input() maxNumber: number;
	private valFn = Validators.nullValidator;

	ngOnChanges(changes: SimpleChanges): void {
		const change = changes['maxNumber'];

		if (change) {
			const n: number = change.currentValue;
			this.valFn = maxNumberValidator(n);
		} else {
			this.valFn = Validators.nullValidator;
		}
	}

	validate(control: AbstractControl): { [key: string]: ValidationErrors } {
		return this.valFn(control);
	}
}

export function maxNumberValidator(n: number): ValidatorFn {
	return (control: AbstractControl): { [key: string]: any } => {
		const a = control.value;
		const no = a > n;
		return no ? { 'maxNumber': { a } } : null;
	};
}
