<div class="main-menu-container bk-menu__container" [hidden]="!visible">
    <div class="bk-menu" (clickOutside)="clickOutside($event)">
        <div class="bk-menu__header">
            <span class="bk-menu__close bk-clickable" (click)="close()">&times;</span>
            <div class="bk-menu__header__logo"></div>
        </div>
        <ul class="bk-menu__list">
            <li class="bk-menu__item" [ngClass]="{ 'bk-menu__item--selected': currentPage == 'MyDashboard' }" (click)="navigateToMyRanches()">
                <span class="bk-menu__item-icon">
                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="Exports" stroke="none" stroke-width="1.5" fill="none" fill-rule="evenodd">
                            <g id="Icon-Ranch">
                                <g>
                                    <path d="M0.5,22.56 L23.5,22.56" id="Shape" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <polyline id="Shape" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" points="2.5 10.56 2.5 22.56 21.5 22.56 21.5 10.56"></polyline>
                                    <polygon id="Shape" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" points="23.5 8.64 12 0.48 0.5 8.64 0.5 12 12.03 3.84 23.5 12"></polygon>
                                    <rect id="Rectangle-path" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" x="6.5" y="12.96" width="11" height="9.6"></rect>
                                    <path d="M6.5,12.96 L17.5,22.56" id="Shape" stroke="#000000" stroke-linejoin="round"></path>
                                    <path d="M17.5,12.96 L6.5,22.56" id="Shape" stroke="#000000" stroke-linejoin="round"></path>
                                    <ellipse id="Oval" stroke="#000000" stroke-linejoin="round" cx="12" cy="8.64" rx="2" ry="1.92"></ellipse>
                                    <rect id="Rectangle-path" x="0" y="0" width="24" height="23.04"></rect>
                                </g>
                            </g>
                        </g>
                    </svg>
                </span>
                {{'My Ranches' | translate }}
            </li>
            <li *ngIf="user && user.Roles && user.Roles[0] && user.Roles[0].Id == 1" class="bk-menu__item" [ngClass]="{ 'bk-menu__item--selected': currentPage == 'Administration' }" (click)="navigateToAdministration()">
                <mat-icon fontSet="material-icons-outlined">admin_panel_settings</mat-icon>
                <span>{{'Admin' | translate }}</span>
            </li>
            <li class="bk-menu__item" [ngClass]="{ 'bk-menu__item--selected': currentPage == 'Profile' }" (click)="navigateToProfile()">
                <mat-icon fontSet="material-icons-outlined">person_outline</mat-icon>
            {{'Profile' | translate }}
            </li>
            <li class="bk-menu__divider"></li>
            <li class="bk-menu__item" (click)="logout()">
                <mat-icon fontSet="material-icons-outlined">logout</mat-icon>
                {{'Log Out' | translate }}
            </li>
            <li>
                <a class="bk-menu__item" href="http://help.cropmanage.ucanr.edu/tutorials/" target="_blank">
                    <mat-icon fontSet="material-icons-outlined">help_outline</mat-icon>
                    {{'Help' | translate }}
                </a>
            </li>
            <li>
                <a class="bk-menu__item" href="https://discord.gg/NxFruVBtDW" target="_blank">
                    <mat-icon fontSet="material-icons-outlined">group</mat-icon>
                    {{'Join Our Discord' | translate }}
                </a>
            </li>
            <li>
                <a class="bk-menu__item" href="{{ donationLink }}" target="_blank">
                    <mat-icon fontSet="material-icons-outlined">volunteer_activism</mat-icon>{{'Donate' | translate }}
                </a>
            </li>
            <li class="bk-menu__item" (click)='openFeedbackDialog()'>
                <mat-icon fontSet="material-icons-outlined">feedback</mat-icon>{{'Send Feedback' | translate }}
            </li>
        </ul>
    </div>
</div>