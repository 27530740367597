import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { INotificationParams } from '../../../interfaces/interfaces';
import { eNotificationTypes } from '../../../interfaces/constants';

@Component({
	moduleId: module.id,
	selector: 'notification',
	templateUrl: 'main.html'
})

export class NotificationComponent {

	public notificationTypeEnum = eNotificationTypes;
	public message: string;
	public type: eNotificationTypes;

	constructor(private snackBarRef: MatSnackBarRef<NotificationComponent>,
		@Inject(MAT_SNACK_BAR_DATA) public data: INotificationParams) {

		if (!data) {
			return;
		}

		this.message = data.message;
		this.type = data.type;
	}

	public close(): void {
		this.snackBarRef.dismiss();
	}
}
