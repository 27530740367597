import { Component, ViewChild, OnInit, Inject, OnDestroy } from '@angular/core';
import { RanchService } from '../../service';
import { UpdateService } from '../../../../services/update.service';
import { AgmMap } from '@agm/core';
import { MapControlDirective } from '../../../../directives/map-control.directive';
import { Router, NavigationExtras } from '@angular/router';
import * as _ from 'underscore';

import { IRanchPermissions, IRanch } from '../../interfaces';
import { eModalFormViews } from '../../../../interfaces/constants';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeoLocation } from '../../../../classes/geolocation.class';
import { ICoordinates, IAgmMapClick, IReactiveFormComponent } from '../../../shared/interfaces';
import { FormBuilder, FormGroup, AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { FormValidation } from '../../../../classes/formValidation';
import { BOKA } from '../../../../classes/number';
import { Coordinates } from '../../../../classes/Coordinates';
import { BaseDialog } from '../../../shared/dialogs/base-dialog';
import { SharedUpdateService } from '../../../shared/dialogs/update.service';
import { PlantingService } from '../../../planting-settings/service';
import { CMError } from '../../../../interfaces/interfaces';

@Component({
	moduleId: module.id,
	selector: 'ranch-modal',
	templateUrl: 'main.html',
	styleUrls: ['main.scss']
})

export class RanchDialog extends BaseDialog implements OnInit, OnDestroy, IReactiveFormComponent {

	@ViewChild(AgmMap, { static: true }) private map: AgmMap;
	@ViewChild(MapControlDirective, { static: true }) private mapControl: MapControlDirective;

	public coordinateExplanation: string;
	public isSaving = false;
	public inEditMode = false;
	public markers: ICoordinates[] = new Array();

	// centered on western united states by default
	private readonly defaultMapParams = {
		lat: 37.195 as number,
		lng: -116.486 as number,
		zoom: 5 as number
	}

	private lat: number = this.defaultMapParams.lat;
	private lng: number = this.defaultMapParams.lng;
	private zoom: number = this.defaultMapParams.zoom;
	protected _subscriptions$: Subject<boolean>;

	public loadingData = true;

	public view: eModalFormViews = eModalFormViews.FORM;
	public modalFormViews = eModalFormViews;
	public permissions: IRanchPermissions;

	public RanchOwnerFullName: string;
	public form: FormGroup;
	public fields = {
		Coordinates: 'Coordinates',
		Name: 'Name',
		Acres: 'Acres'
	}

	public get f(): { [key: string]: AbstractControl } {
		if (!this.form) {
			return null;
		} else {
			return this.form.controls;
		}
	} //

	constructor(
		private _fb: FormBuilder,
		private dialog: MatDialog,
		private dialogref: MatDialogRef<RanchDialog>,
		@Inject(MAT_DIALOG_DATA) private _data: { Id: string},
		private ranchService: RanchService,
		private _plantingService: PlantingService,
		private updateService: UpdateService,
		private sharedUpdateService: SharedUpdateService,
		private router: Router) {

		super(dialog, dialogref, sharedUpdateService);

		this._subscriptions$ = new Subject();
		this.dialogref.disableClose = true;
	}

	ngOnInit(): void {
		this.permissions = this.updateService.currentPermissions;
		this._configureDeleteConfirmationText();
		super.ngOnInit();

		this.updateService.RanchPlantingsPermissions$.pipe(takeUntil(this._subscriptions$)).subscribe(permissions => {
			this.permissions = permissions;
		});

		this._initializeForm();

		if (!this._data || !this._data.Id) {
			// center map to the user's location

			if (this.updateService.currentLocation.lat && !isNaN(this.updateService.currentLocation.lat)
				&& this.updateService.currentLocation.lng && !isNaN(this.updateService.currentLocation.lng)) {

				this.lat = this.updateService.currentLocation.lat;
				this.lng = this.updateService.currentLocation.lng;
				this.zoom = 10;
				this.inEditMode = false;
				this.loadingData = false;
				this._resetMap();
			} else {
				GeoLocation.getCoordinates().then(position => {
					if (position.coords.latitude && position.coords.longitude) {
						this.updateService.setCurrentLocation(Number(position.coords.latitude), Number(position.coords.longitude));
						this.lat = this.updateService.currentLocation.lat;
						this.lng = this.updateService.currentLocation.lng;
						this.zoom = 10;
						this.inEditMode = false;
						this.loadingData = false;
						this._resetMap();
					}
				}).catch((reason: any) => {
					// we need to catch the error triggered by
					// a user not sharing his/her location
				});
			}
		} else {
			this.inEditMode = true;
			this.form.markAsDirty();
			this._getEditRanchData();
		}
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public changeCoordinatesMap(event: IAgmMapClick): void {
		let lat = BOKA.NumberUtil.truncateDecimal(event.coords.lat);
		let lng = BOKA.NumberUtil.truncateDecimal(event.coords.lng);
		this.form.get(this.fields.Coordinates).setValue(lat + ',' + lng);
		this.form.get(this.fields.Coordinates).markAsTouched();
		this._placeMarker(lat, lng);
	}

	public delete(): void {
		this.ranchService.deleteRanch(this.updateService.currentRanchId)
			.then(() => {
				this.sharedUpdateService.deleteComplete();
				this.close();
				this.router.navigate(['MyDashboard']);
			});
	}

	protected preventClose(): boolean {
		return this.loadingData;
	}

	public save() {
		if (this.inEditMode) {
			this._edit();
		} else {
			this._create();
		}
	}

	private _changeCoordinatesText(coordinates: string): void {

		let coords: Coordinates;

		coords = new Coordinates(coordinates);

		if (coords === null) {
			return;
		}

		this.lat = coords.lat;
		this.lng = coords.lng;

		this._placeMarker(coords.lat, coords.lng);
	}

	private _clear() {
		this.inEditMode = false;
		this.lat = this.defaultMapParams.lat;
		this.lng = this.defaultMapParams.lng;
		this.zoom = this.defaultMapParams.zoom;
		this.markers = new Array();
		this.loadingData = true;
		this.view = eModalFormViews.FORM;
	}

	private _configureDeleteConfirmationText(): void {
		this._deleteConfirmationData = {
			objectName: 'Ranch',
			specificName: null,
			additionalMessage: 'Are you sure you want to delete this ranch?'
		};
	}

	private _create(): void {

		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this.ranchService.createRanch(this.form.value)
		.then((res) => {

			let ranch: IRanch;
			// need to update the backend data when new ranch is created - add to my ranches
			this.isSaving = false;

			if (!res) {
				return;
			}

			ranch = {
				ActivePlantings: 0,
				CreatedByFullName: null,
				Favorite: false,
				FavoriteId: 0,
				Id: res.Id,
				Name: this.form.get(this.fields.Name).value,
				RanchAccessState: null,
				RanchId: res.Guid,
				Ranch_External_GUID: res.Guid,
				UserAccessState: null,
				WeatherAPI_Id: res.WeatherAPI_Id
			};

			if (!this.ranchService.ranches.myRanches) {
				this.ranchService.ranches.myRanches = new Array();
			}

			this.ranchService.ranches.myRanches.push(ranch);
			this.ranchService.sortRanchesByName(this.ranchService.ranches.myRanches);

			this.updateService.setUpdatedRanches('ranch created');
			this.ranchService.currentRanchLots = new Array(); // empty cache from previous ranch
			this.dialogref.close();

			let navigationExtras: NavigationExtras = {
				queryParams: {
					'id': res.Guid,
					'name': this.form.get(this.fields.Name).value
				}
			}
			this.updateService.showRanchSettings = true;
			this.router.navigate(['RanchHome'], navigationExtras);
			this._clear();
		});
	}

	private _edit(): void {

		// Update the ranches list when ranch is updated - ideally we just copy over data, and ideally we only have
		// one data storage here to check, not two

		let ranch: IRanch;
		let ranchFavorite: IRanch;

		// update ranch data in the my ranches and favorites cache
		ranch = this.ranchService.findRanchByGuid(this.ranchService.ranches.myRanches,
			this.updateService.currentRanchId);

		if (ranch !== null) {
			ranch.Name = this.form.get(this.fields.Name).value;
			ranch.Coordinates = this.form.get(this.fields.Coordinates).value;
			ranch.Acres = this.form.get(this.fields.Acres).value;
		} else {
			ranch = {
				Name: this.form.get(this.fields.Name).value,
				Coordinates: this.form.get(this.fields.Coordinates).value,
				Acres: this.form.get(this.fields.Acres).value,
				Id: null,
			}
		}

		ranchFavorite = this.ranchService.findRanchByGuid(this.ranchService.ranches.favorites,
			this.updateService.currentRanchId);

		if (ranchFavorite !== null) {
			ranchFavorite.Name = this.form.get(this.fields.Name).value;
			ranchFavorite.Coordinates = this.form.get(this.fields.Coordinates).value;
			ranchFavorite.Acres = this.form.get(this.fields.Acres).value;
		}

		if (this.isSaving) {
			return;
		}

		this.isSaving = true;

		this.ranchService.editRanchBasicInfo(this.form.value)
		.then(() => {
			this.ranchService.getRanchBasicInfo().then((res) => {
				ranch.WeatherAPI_Id = res.WeatherAPI_Id;
				this.isSaving = false;
				this.updateService.setUpdatedRanches('ranch edited');
				this.updateService.setUpdatedRanch(ranch);
				this.dialogref.close();
				this._clear();
			});
		});
	}

	private _getEditRanchData(): void {
		this.ranchService.getRanchBasicInfo()
			.then((res) => {
				let coords: Coordinates;

				if (!res) {
					return;
				}

				if (!this.form) {
					throw new Error('form is empty');
				}

				coords = new Coordinates(res.Coordinates);
				this.form.get(this.fields.Name).setValue(res.RanchName);
				this.form.get(this.fields.Coordinates).setValue(coords.toString());
				this.form.get(this.fields.Acres).setValue(res.Acres);
				this.RanchOwnerFullName = res.RanchOwnerFullName;

				this.loadingData = false;
				this.zoom = 10;
				this._resetMap();
			});
	}

	private _initializeForm(): void {
		this.form = this._fb.group({
			Coordinates: [null, { validators: [Validators.required,
				FormValidation.CoordinateStringIsValid()], updateOn: 'blur'}],
			Name: [null, Validators.required],
			Acres: [null, [Validators.required, Validators.min(0.000001), Validators.max(2147483647)]]
		});

		this._bindValueChanges();
	}

	private _bindValueChanges(): void {
		this.form.get('Coordinates').valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			if (!this.form.get(this.fields.Coordinates).valid) {
				return;
			}

			this._inSupportedArea(this._plantingService, false).then((resp) => {
				this.coordinateExplanation = resp;
			});
		});
	}

	private _inSupportedArea(service: PlantingService, isSpatialEnabled: boolean): Promise<string>  {
		let coordinates: Coordinates;

		if (!this.lat || !this.lng) {
			return of(null).toPromise();
		}

		coordinates = new Coordinates(this.form.get('Coordinates').value);

		return service.isLocationValid(coordinates.lat, coordinates.lng, isSpatialEnabled).then((resp) => {
			if (!resp) {
				// on initial form load, coordinate values may be blank
				// throw new Error('coordinates area check returned no response');
				return null;
			}

			if ((resp as CMError).message) {
				return null;
			} else {
				return resp as string;
			}
		});
	}

	private _placeMarker(lat: number, lng: number) {
		this.markers = new Array();

		this.markers.push({
			lat: lat,
			lng: lng
		});
	}

	private _recenterMap(lat: number, lng: number, zoom: number): void {
		if (!lat || !lng) {
			throw new Error('lat/lng is empty');
		}

		this.mapControl.centerMap(lat, lng);
		this.mapControl.setZoom(zoom);
	}

	private _resetMap(): void {
		if (!this.map) {
			return;
		}

		if (!this.form) {
			throw new Error('form is empty');
		}

		let __this = this;

		setTimeout(() => {
			this.map.triggerResize().then(() => {
				if (this.inEditMode) {
					this._changeCoordinatesText(this.form.get(this.fields.Coordinates).value);
					this._recenterMap(this.lat, this.lng, this.zoom);
				} else {
					this._recenterMap(this.lat, this.lng, this.zoom);
				}
			});
		}, 200);
	}
}
