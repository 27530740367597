import { Injectable } from '@angular/core';
import { HttpServicePostOptions } from '../../services/http.service';

import { ModelService } from '../../services/model.service';

import { ILotPlantingWell, IPlantingWellService } from './interfaces';
import { HttpParams } from '@angular/common/http';
import { CMError } from '../../interfaces/interfaces';

@Injectable()
export class PlantingWellService extends ModelService implements IPlantingWellService {

	private urls = {
		create: (plantingId: number) => `/v2/plantings/${plantingId}/wells/generate.json`,
		list: (plantingId: number) => `/v2/plantings/${plantingId}/wells.json`,
		saveAll: '/v2/plantings/wells/batch.json',
		Sync: (plantingId: number) => `/v2/plantings/${plantingId}/wells/sync.json`
	};

	/**
	 * Call to sync planting wells with ranch wells
	 * needed when an "inactive" planting turns active (by moving harvestDate to future) after well has been added
	 * @param plantingId
	 */
	public syncPlantingWells(plantingId: number): void {
		let options: HttpServicePostOptions;

		options = {
			body: null,
			url: this.urls.Sync(plantingId),
			isWebAPI: true,

			callback: (response: CMError) => {
				if (!response) {
					return null;
				} else {
					return response;
				}
			}
		}

		this.httpService.post(options);
	}

	public createLotPlantingWells(plantingId: number): Promise<ILotPlantingWell[]> {
		let options: HttpServicePostOptions;

		options = {
			body: null,
			url: this.urls.create(plantingId),
			isWebAPI: true,
			callback: (response: ILotPlantingWell[]) => {
				if (!response) {
					return null;
				} else {
					return response;
				}
			}
		}

		return this.httpService.post(options);
	};

	/**
	 * Get wells associated with a fertilization event
	 * @param eventId
	 */
	public getLotPlantingtWells(plantingId: number): Promise<ILotPlantingWell[]> {
		let params: HttpParams;

		return this.get(this.urls.list(plantingId), (response: ILotPlantingWell[]) => {
			if (!response) {
				return null;
			} else {
				return response;
			}
		}, params);
	}

	// Save all wells
	public saveAllLotPlantingWells(wells: ILotPlantingWell[]): Promise<CMError> {
		let options: HttpServicePostOptions;
		let body: { Wells: ILotPlantingWell[] }

		body = {
			Wells: wells
		};

		options = {
			body: body,
			url: this.urls.saveAll,
			isWebAPI: true,

			callback: (response: CMError) => {
				if (!response) {
					return null;
				} else {
					return response;
				}
			}
		}

		return this.httpService.post(options);
	}
}
