import { ICropStage } from '../../models/crop-stage/interfaces';
import { IIdNamePair } from '../planting-settings/interfaces';
import { NutrientJSONModel } from '../ranch-settings/modals/fertilizer/interfaces';
import { IEventPostResponse } from '../../models/event/interfaces';
import { TissueSampleEvent } from './tissueSampleEvent';
import { EventGroup } from '../../models/event/event';
import { INutrient } from '../../models/nutrient/interfaces';
import { EventResponse } from '../../models/event/eventResponse';

/**
 * Namespace used for managing JSON that comes in from C#
 */
export namespace IncomingJSON {

	export interface TissueSampleNutrient {
		Id: number,
		EventId: number,
		NutrientId: number,
		MeasurementUnitId: number,
		Value?: number,
		TissueSampleEvent?: any, // this comes in as null
		Nutrient: Nutrient,
	}

	export interface Nutrient {
		Id: number,
		Name: string,
		Name_Alt: string,
		NutrientTypeId: number,
		MeasureUnitTypeId: number,
		TissueMeasureUnitTypeId: number,
		Symbol: string
	}
	/**
     *
     */
	export interface TissueSampleEventJSONModel {
		PlantingId: number,
		LastUpdated?: string,
		LastUpdatedUser: string,
		LastUpdatedTimestamp: string,
		CropStageId?: number,
		LocationId?: number,
		LocationDetails?: string,
		Notes?: string,
		TissueSampleNutrients: TissueSampleNutrientJSON[],
		// inherited from EventJSON
		Id: number,
		// EventDate specified by child
		LotPlantingId?: number,
		CreatedDate?: string,
		LastUpdateDate?: string,
		UpdatedBy?: string,
	}

	export interface TissueSampleNutrientJSON {
		Id: number,
		EventId: number,
		NutrientId?: number,
		Nutrient: NutrientJSONModel,
		MeasurementUnitId?: number,
		Value?: number
	}

	/**
     * incoming JSON, where date is a string
	 * Transmitted by edit controller
     */
	export interface ITissueSampleEventJSON extends TissueSampleEventJSONModel {
		EventDate: string
	}

	/**
     * Data structure Comes from TissueSampleModel in EventService: EventsListForPlanting()
     */
	export interface ITissueSampleModel {
		Metainfo: string,
		Id: number,
		CropStage: { Id: number, Name: string },
		Location: { Id: number, Name: string },
		LastUpdatedUser: string,
		TissueSampleNutrients: EventSnapshotEventSnapshotTissueSampleNutrient[]
	}

	export interface EventSnapshotEventSnapshotTissueSampleNutrient {
		Symbol: string;
		Name: string;
		Value: number;
		MeasurementUnitId: number
	}

	export interface ITissueSampleLocation extends IIdNamePair {
		ExternalID: number
	}
}

export namespace Internal {
	export interface ITissueSampleEvent extends IncomingJSON.TissueSampleEventJSONModel { // REFACTOR - remove - obsolete
		Metainfo: string;
		EventDate: Date;
		CropStage: ICropStage;
		NutrientDisplayText?: string;
	}
}

export const TISSUE_SAMPLE_LOCATION_OTHER = 4;

export enum eNutrientTypes {
	PRIMARY = 1,
	SECONDARY,
	MICRO,
	GENERAL
}

/**
 * Nutrient measure unit types
 */
export enum eMeasureUnitTypes {
	PPM = 1,
	PERCENT
}

/**
 * C# model used to post to server
 */
export interface TissueSampleEventUpdateParams {
	EventDate: Date,
	LocationId?: number,
	LocationDetails: string,
	CropStageId?: number,
	Notes?: string,
	TissueSampleNutrients: TissueSampleNutrientParams[]
}

/**
 * C# model used to post to server
 */
export interface TissueSampleNutrientParams {
	Id: number,
	NutrientId: number,
	MeasurementUnitId: number,
	Value: number
}

export interface ITissueSampleEventService {
	create(plantingId: number, model: TissueSampleEventUpdateParams): Promise<EventResponse>,
	delete(id: number, plantingId: number): Promise<EventGroup[]>,
	get(id: number): Promise<TissueSampleEvent>,
	locationList(): Promise<IncomingJSON.ITissueSampleLocation[]>,
	nutrientList(): Promise<IncomingJSON.TissueSampleNutrient[]>,
	update(eventId: number, model: TissueSampleEventUpdateParams, plantingId: number): Promise<EventResponse>,
	updateDateOnly(eventId: number, plantingId: number, date: Date): Promise<EventResponse>,
}
