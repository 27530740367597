import { ICropType, ICropTypeViewModel } from '../../models/crop-type/interfaces';
import { ICropSettingsForm } from '../planting-settings/planting-settings-form.interfaces';

export class CropTypeViewmodelConverter {
	public static convert(cropTypeForm: ICropSettingsForm): ICropTypeViewModel {
		let result: ICropTypeViewModel;

		result = cropTypeForm;
		result.CompostN = cropTypeForm.NCalculator.CompostN;
		result.CoverCropN = cropTypeForm.NCalculator.CoverCropN;
		result.ManureN = cropTypeForm.NCalculator.ManureN;
		result.TreeNutrientThresholds = cropTypeForm.NCalculator.TreeNutrientThresholds;

		return result;
	}
}
