<div class="bk-dialog">

	<button type="button"
        class="bk-button bk-dialog__close"
        (click)="cancel()">

        &times;
	</button>

	<h4 mat-dialog-title>
		{{ 'Email User'  | translate }}
	</h4>

	<div mat-dialog-content>
		<div class="bk-form-group">
			<label class="bk-modal__label"
				for="cc">
				{{'To' | translate}}
			</label>
			<input disabled
				class="bk-modal__field bk-input--full"
				id="cc"
				type="text"
				[(ngModel)]="email.recipientName">
		</div>
		<div class="bk-form-group">
			<label class="bk-modal__label"
				for="subject">
				{{'Subject' | translate}}
			</label>
			<input class="bk-modal__field bk-input--full"
				id="subject"
				type="text"
				[(ngModel)]="email.subject">
		</div>
		<div class="bk-form-group">
			<label class="bk-modal__label"
				for="body">
				{{'Message' | translate}}
			</label>
			<textarea class="bk-modal__field bk-input--full"
				id="body"
				rows="6"
				[(ngModel)]="email.body" >
			</textarea>
		</div>
	</div>

	<div mat-dialog-actions align="end">

		<button (click)="cancel()" mat-button
			class="bk-button bk-button--medium bk-dialog__button--secondary bk-button--text-only">
			{{ 'Cancel' | translate }}
		</button>

		<button
			[disabled]="isValid() === false"
			(click)="send()"
			mat-button
			class="bk-button bk-button--medium bk-dialog__button--primary">
			{{ 'Send' | translate }}
		</button>
	</div>
</div>