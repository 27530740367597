import { Component, OnInit, Inject } from '@angular/core';
import { EventGroup } from '../../models/event/event';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	moduleId: module.id,
	selector: 'cut-event-recalculate-warning',
	templateUrl: 'recalculate-warning.html'
})

export class CutEventRecalculateWarningComponent implements OnInit {

	public events: EventGroup[];

	constructor(public dialogRef: MatDialogRef<CutEventRecalculateWarningComponent>,
		@Inject(MAT_DIALOG_DATA) public data: {
			succeedingEvents: EventGroup[]
		}) {
	}

	ngOnInit() {
		this.events = this.data.succeedingEvents;
	}

	public close(): void {
		this.dialogRef.close();
	}
}
