<div class="bk-dialog bk-dialog__deactivate">
    <button type="button"
        class="bk-button bk-dialog__close"
        (click)="close()">
        <span>&times;</span>
    </button>

    <h1 mat-dialog-title *ngIf="weatherStation">
        {{ 'Deactivate' | translate}} {{ 'Weather Station' | translate }}
    </h1>

    <div mat-dialog-content>
        <p>Are you sure you want to deactivate <b>{{ weatherStation.Name }}</b>?</p>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only
            bk-dialog__button--secondary"
            (click)="close()">
            {{ 'Cancel' | translate }}
        </button>
        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="deactivate()">
            {{ 'Deactivate' | translate }}
        </button>
    </div>
</div>
