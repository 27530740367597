<div class="bk-dialog">
	<form #f="ngForm">
		<h4 mat-dialog-title>
			{{'Perennial Canopy Parameters' | translate }}
		</h4>

		<div mat-dialog-content>
			
			<ul class="bk-form__side-by-side">
				<li>
					<label class="bk-modal__label">
						{{'Crop Age' | translate }}
					</label>
					<select class="bk-modal__field"
						name="Age"
						required
						[(ngModel)]="model.Age">
			
						<option [ngValue]="null"
							selected>
							{{'Select One' | translate }}
						</option>
						<option *ngFor="let age of ages"
							[ngValue]="age.key">
							{{age.value}}
						</option>
					</select>
				</li>
			</ul>

			<ul class="bk-form__side-by-side">
				<li>
					<label class="bk-modal__label">
						{{'Canopy A' | translate }}
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="CanopyA"
						required
						[(ngModel)]="model.CanopyA" />
				</li>
				<li>
					<label class="bk-modal__label">
						{{'Canopy B' | translate }}
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="CanopyB"
						required
						[(ngModel)]="model.CanopyB" />
				</li>
				<li>
					<ng-container *ngIf="commodityTypeId !== COMMODITY_TYPES.CANEBERRY">
						<label class="bk-modal__label">
							{{'Canopy E' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="CanopyE"
							required
							[(ngModel)]="model.CanopyE" />
					</ng-container>
				</li>
				<li>
					<ng-container *ngIf="commodityTypeId !== COMMODITY_TYPES.CANEBERRY">
						<label class="bk-modal__label">
							{{'Canopy F' | translate }}
						</label>
						<input class="bk-modal__field bk-form__input-text--float"
							type="number"
							name="CanopyF"
							required
							[(ngModel)]="model.CanopyF" />
					</ng-container>
				</li>
			</ul>
			<ul class="bk-form__side-by-side">
				<li>
					<label class="bk-modal__label">
						{{'Canopy Max' | translate }}
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="CanopyGMax"
						required
						[(ngModel)]="model.CanopyGMax" />
				</li>
				<li>
					<label class="bk-modal__label">
						{{'Canopy Max Fraction' | translate }}
					</label>
					<input class="bk-modal__field bk-form__input-text--float"
						type="number"
						name="CanopyMaxFraction"
						required
						[(ngModel)]="model.CanopyMaxFraction" />
				</li>
			</ul>
		</div>

		<div mat-dialog-actions align="end">

			<button class="bk-button bk-button--medium bk-button--text-only"
				(click)="close()">
				{{'Cancel' | translate }}
			</button>
			<button class="bk-button bk-button--medium"
				[disabled]='!f.form.valid'
				(click)="save()">
				{{'Save' | translate }}
			</button>
		</div>
	</form>
</div>
