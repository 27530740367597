import { Component, OnInit, Input } from '@angular/core';
import { IFertilizer } from '../interfaces';

@Component({
	moduleId: module.id,
	selector: 'fertilizer-list',
	templateUrl: 'fertilizer-list.html',
	styleUrls: [ 'fertilizer-list.scss' ]
})

export class FertilizerListComponent implements OnInit {

	@Input()
	public fertilizers: IFertilizer[];

	public filteredFertilizers: IFertilizer[];
	public searchQuery: string; // rename

	constructor() {
	}

	ngOnInit(): void {
		this.filteredFertilizers = this.fertilizers;
	}

	public filterFertilizers(query: string): void {
		this.searchQuery = query;

		this.filteredFertilizers = this.fertilizers.filter(fertilizer =>
			fertilizer.Name.toLowerCase().includes(this.searchQuery.toLocaleLowerCase()));
	}

	public toggleSelectedFertilizer(fertilizer: IFertilizer): void {
		if (!fertilizer) {
			return;
		}

		if (fertilizer.checked === undefined || fertilizer.checked === false) {
			// assume this is getting checked.
			fertilizer.checked = true;
		} else {
			fertilizer.checked = false;
		}
	}
}
