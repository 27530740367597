import { Component, OnInit, Input, Inject } from '@angular/core';
import { EventGroup } from '../../models/event/event';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IEventGroup } from '../../models/event/interfaces';
import { IrrigationUpdateService } from './update.service';

@Component({
	moduleId: module.id,
	selector: 'irrigation-event-recalculate-warning',
	templateUrl: 'recalculate-warning.html'
})

export class IrrigationEventRecalculateWarningDialog implements OnInit {

	public effectedEvents: IEventGroup[];

	constructor(
		private _irrigationUpdateService: IrrigationUpdateService,
		private _dialogRef: MatDialogRef<IrrigationEventRecalculateWarningDialog>,
		@Inject(MAT_DIALOG_DATA) private _data: { effectedEvents: IEventGroup[] }
	) { }

	ngOnInit() {
		if (this._data) {
			this.effectedEvents = this._data.effectedEvents;
		}
	}

	public close($event: MouseEvent): void {
		this._dialogRef.close();
		this._irrigationUpdateService.fireRecalculateWarningClosed($event);
	}
}
