import { ICommodityType } from './interfaces';

export class CommodityType {
	public static filterAvailable(commodityTypes: ICommodityType[]) {
		let result: ICommodityType[];

		if (!commodityTypes) {
			return null;
		}

		result = commodityTypes.filter(x => x.IsAssociatedWithRanch === false);

		return result;
	}
}
