import { IEventPostResponseJSON, IEventPostResponse, IRawEvent } from './interfaces';
import { DateUtility } from '../../classes/dateUtility';
import { EventGroup } from './event';
import { RanchService } from '../../components/ranch-settings/service';
import { UpdateService } from '../../services/update.service';
import { EventsType } from '../../interfaces/constants';

export class EventResponse implements IEventPostResponse {

	public ActiveEvents: string;
	public EventGroupsSucceeding: EventGroup[];
	public Events: EventGroup[];
	public Id: number;
	public SucceedingEventGroupsRaw: IRawEvent[];
	public WetDate: Date;

	constructor(response: IEventPostResponseJSON) {
		let eventGroup: EventGroup;

		if (!response || !response.Events) {
			return;
		}

		this.WetDate = DateUtility.DotNetToDate(response.WetDate);

		eventGroup = new EventGroup();
		eventGroup.convert(response.Events[0], this.WetDate);

		this.SucceedingEventGroupsRaw = response.EventGroupsSucceeding;

		this.Id = response.Id;
		this.Events = EventGroup.convertAll(response.Events, this.WetDate);
		this.EventGroupsSucceeding = EventGroup.convertAll(response.EventGroupsSucceeding, this.WetDate);
		this.ActiveEvents = response.ActiveEvents;
	}

	public getEventGroup(eventType: EventsType, originalDate: Date): EventGroup {
		let eventGroup: EventGroup;

		if (!this.Events || this.Events.length === 0) {
			return null;
		}

		eventGroup = this.Events[0];
		eventGroup.ActiveEvents = this.ActiveEvents;
		eventGroup.Id = this.Id;
		eventGroup.eventType = eventType;
		eventGroup.originalEventDate = originalDate;

		return eventGroup;
	}

	public updateServices(eventType: EventsType, originalDate: Date,
		plantingId: number,
		ranchService: RanchService, updateService: UpdateService): void {

		let eventGroup: EventGroup;

		eventGroup = this.getEventGroup(eventType, originalDate);

		ranchService.plantings.addEventToPlantings(eventGroup,
			plantingId, this.Id);

		updateService.setPlantingsUpdated(updateService.currentRanchId, new Date());
		updateService.setEventUpdated(this.Id, eventGroup); // update icon
	}

	public updateSucceedingEvents(plantingId: number, ranchService: RanchService): void {
		EventGroup.updateEvents(this.SucceedingEventGroupsRaw, plantingId,
			this.WetDate, ranchService);
	}
}
