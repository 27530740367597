import { Directive } from '@angular/core';
import { GoogleMapsAPIWrapper, LatLngLiteral, LatLngBounds } from '@agm/core';
import { GoogleMap } from '@agm/core/services/google-maps-types';

@Directive({
	selector: 'map-control'
})

export class MapControlDirective {

	constructor(private wrapper: GoogleMapsAPIWrapper) { }

	public centerMap(lat: number, lng: number): Promise<void> {
		let latLng = <LatLngLiteral>{ lat: lat, lng: lng };

		return this.wrapper.setCenter(latLng)
			.then(res => res);
	}

	public setZoom(zoom: number): Promise<void> {
		return this.wrapper.setZoom(zoom)
			.then(res => res);
	}

	public getBounds(): Promise<LatLngBounds> {
		return this.wrapper.getBounds()
			.then(res => res);
	}

	public getZoom(): Promise<Number> {
		return this.wrapper.getZoom();
	}
}
