import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpService, HttpServicePostOptions, HttpServiceGetOptions } from '../../services/http.service';

import { CMError, SuccessResponse } from '../../interfaces/interfaces';
import { iIgnoreSchedule, iUnprocessedIgnoreSchedule } from './interfaces';
import { IgnoreSchedule } from './ingore-schedule';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class IgnoreSchedulesService {

	private urls = {
		list: '/v2/ignore-schedules.json',
		create: '/v2/ignore-schedules.json',
		update: '/v2/ignore-schedules.json',
		delete: (id: number) => `/v2/ignore-schedules/${id}.json`
	}

	constructor(private httpService: HttpService) { }

	public list(): Promise<iIgnoreSchedule[]> {
		let options: HttpServiceGetOptions = {
			url: this.urls.list,
			isWebAPI: true,

			callback: (response: iUnprocessedIgnoreSchedule[]): iIgnoreSchedule[] => {
				return IgnoreSchedule.convertList(response);
			}
		}

		return this.httpService.get(options);
	}

	public create(ignoreSchedule: iIgnoreSchedule): Promise<iIgnoreSchedule> {
		let options: HttpServicePostOptions;

		if (!ignoreSchedule) {
			return of(null).toPromise();
		}

		options = {
			body: ignoreSchedule,
			url: this.urls.create,
			isWebAPI: true,

			callback: (response: iUnprocessedIgnoreSchedule): iIgnoreSchedule => {
				return IgnoreSchedule.convertItem(response);
			}
		}

		return this.httpService.post(options);
	}

	public update(ignoreSchedule: iIgnoreSchedule): Promise<void> {
		let options: HttpServicePostOptions;

		if (!ignoreSchedule) {
			return of(null).toPromise();
		}

		options = {
			body: ignoreSchedule,
			url: this.urls.update,
			isWebAPI: true
		}

		return this.httpService.put(options);
	}

	public delete(id: number): Promise<SuccessResponse> {

		let options: HttpServiceGetOptions = {
			url: this.urls.delete(id),
			isWebAPI: true
		}

		return this.httpService.delete(options);
	}
}
