import { ICropStage, IFormCropStage } from '../../models/crop-stage/interfaces';
import { INutrient, IFormNutrient } from '../../models/nutrient/interfaces';
import { IRawSoilSample, ISoilSampleEventForm, IFormNitrogen, ISoilSampleSimple, SoilSampleJSONModel } from './interfaces';
import { DateUtility } from '../../classes/dateUtility';
import { ISelectOptions } from '../../interfaces/abstract';
import { eNutrientTypes } from '../tissue-sample-event/interfaces';

export class SoilSampleEvent {
	public CropStage: ICropStage;
	public Depth: number;
	public Id: number;
	public LastUpdatedUser: string;
	public Nutrients: INutrient[];
	public SampleType: string;

	public SoilMineralNLbsAcre: number;
	public SoilNitrateNPPM: number;
	public Metainfo: string;

	// raw model properties from combined events list
	public ShowDetails: boolean;

	// soil sample event form properties
	public EventDate: Date;
	public CropStageId: number;
	public CropStageName: string;
	public CropStages: IFormCropStage[];
	public SoilMoistureId: number;
	public MoistureTypes: ISelectOptions[];
	public OtherNutrients: IFormNutrient[];
	public SampleDepth: number;
	public SampleTypeId: number;
	public SampleTypes: ISelectOptions[];
	public Nitrogen: IFormNitrogen;
	public StartDate: string;
	public EndDate: string;

	public HarvestDate?: string; // remove these later
	public endDate?: string;
	public startDate?: string;
	public DateOnly?: boolean;

	/**
	 * Inserts N-Nitrate into Nutrients array, for proper sorting in event table hover panel
	 * @param soilSampleEvent
	 */
	public static insertNitrateNIntoNutrients(soilSampleEvent: SoilSampleEvent): INutrient[] {
		let nitrateN: INutrient;

		if (soilSampleEvent.SoilNitrateNPPM === 0) {
			// if N value is 0, don't insert
			return soilSampleEvent.Nutrients;
		}

		nitrateN = {
			Nutrient: 'Nitrate-N',
			Value: soilSampleEvent.SoilNitrateNPPM
		};

		soilSampleEvent.Nutrients.push(nitrateN);

		return soilSampleEvent.Nutrients;
	}

	public static extractFromReactiveForm(m: ISoilSampleSimple): ISoilSampleEventForm {
		let formNutrients: IFormNutrient[] = new Array();

		formNutrients.push({
			Id: 1,
			NutrientTypeId: 1,
			Name: 'Nitrogen',
			value: m.Nitrogen,
			NutrientId: 1
		});

		return {
			CropStageId: m.CropStageId,
			EventDate: m.EventDate,
			Nutrients: formNutrients,
			SampleDepth: m.SampleDepth,
			SampleTypeId: m.SampleTypeId,
			SoilMoistureId: m.SoilMoistureId,
			StartDate: null,
			EndDate: null,
			Nitrogen: {
				Id: 1,
				NutrientId: 1,
				Value: m.Nitrogen
			},
		};
	}

	constructor(eventDate?: Date) {
		if (eventDate) {
			this.EventDate = eventDate;
		}
	}

	// used when converting data for events popup
	public convert(rawSoilSample: IRawSoilSample): void {
		this.Depth = rawSoilSample.Depth;
		this.Id = rawSoilSample.Id;
		this.Metainfo = rawSoilSample.Metainfo;
		this.CropStage = rawSoilSample.CropStage;
		this.SampleType = rawSoilSample.SampleType;
		this.SampleTypeId = rawSoilSample.SampleTypeId;
		this.Nutrients = rawSoilSample.Nutrients;
		this.SoilNitrateNPPM = rawSoilSample.SoilNitrateNPPM;
		this.SoilMineralNLbsAcre = rawSoilSample.SoilMineralNLbsAcre;
		this.LastUpdatedUser = rawSoilSample.LastUpdatedUser;
		this.ShowDetails = false;
	}

	public convertSoilSampleJSONModel(d: SoilSampleJSONModel): void {
		if (!d) {
			return;
		}

		if (!d.EventDate) {
			this.EventDate = new Date();
		} else {
			this.EventDate = DateUtility.DotNetToDate(d.EventDate);
		}

		this.CropStageId = d.CropStageId;
		this.CropStages = [];
		this.MoistureTypes = [];
		this.SampleTypes = [];

		if (d.CropStages && d.CropStages.length > 0) {
			for (let cropStage of d.CropStages) {
				this.CropStages.push({
					Text: cropStage.Text,
					Value: Number(cropStage.Value)
				});
			}
		}

		if (d.MoistureTypes && d.MoistureTypes.length > 0) {
			for (let type of d.MoistureTypes) {
				this.MoistureTypes.push({
					key: type.Key,
					value: type.Value
				});
			}
		}

		if (d.SampleTypes && d.SampleTypes.length > 0) {
			for (let type of d.SampleTypes) {
				this.SampleTypes.push({
					key: type.Key,
					value: type.Value
				})
			}
		}

		this.SoilMoistureId = d.SoilMoistureId;
		this.SampleDepth = d.SampleDepth;
		this.SampleTypeId = d.SampleTypeId;
		this.StartDate = d.StartDate;
		this.EndDate = d.EndDate;
		this.LastUpdatedUser = d.LastUpdated;
		this.OtherNutrients = new Array();

		this.Nitrogen = {
			Id: 1,
			NutrientId: 1,
			Value: 0
		}

		for (let nutrient of d.Nutrients) {
			if (nutrient.Name === 'Nitrogen') {
				this.Nitrogen.Id = nutrient.Id;
				this.Nitrogen.NutrientId = nutrient.Id;
				this.Nitrogen.Value = nutrient.value;
			} else {
				this.OtherNutrients.push({
					Id: nutrient.Id,
					Name: nutrient.Name,
					value: nutrient.value,
					NutrientId: nutrient.Id,
					NutrientTypeId: nutrient.NutrientTypeId,
					MeasurementUnit: nutrient.MeasurementUnit,
					MeasureUnitTypeId: nutrient.MeasureUnitTypeId
				});
			}
		}
	}

	public getForm(): ISoilSampleEventForm {
		let formNutrients: IFormNutrient[] = new Array();

		if (this.Nitrogen) {
			formNutrients.push({
				Id: this.Nitrogen.Id,
				NutrientTypeId: this.Nitrogen.NutrientId,
				Name: 'Nitrogen',
				value: this.Nitrogen.Value,
				NutrientId: this.Nitrogen.NutrientId
			});
		}

		if (this.OtherNutrients && this.OtherNutrients.length > 0) {
			for (let nutrient of this.OtherNutrients) {
				formNutrients.push({
					Id: nutrient.Id,
					NutrientTypeId: nutrient.NutrientTypeId,
					Name: nutrient.Name,
					value: nutrient.value,
					NutrientId: nutrient.NutrientId
				})
			}
		}

		return {
			CropStageId: this.CropStageId,
			CropStages: this.CropStages,
			EventDate: this.EventDate,
			MoistureTypes: this.MoistureTypes,
			Nutrients: formNutrients,
			SampleDepth: this.SampleDepth,
			SampleTypeId: this.SampleTypeId,
			SampleTypes: this.SampleTypes,
			SoilMoistureId: this.SoilMoistureId,
			StartDate: this.StartDate,
			EndDate: this.EndDate,
			Nitrogen: this.Nitrogen,
			OtherNutrients: this.OtherNutrients,
			DateOnly: this.DateOnly
		}
	}
}
