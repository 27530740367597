import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { IEventPostResponseJSON } from '../../models/event/interfaces';
import { IRawSoilSample } from '../soil-sample-event/interfaces';
import { SoilSampleEventService } from '../soil-sample-event/service';
import * as moment from 'moment';
import { DateUtility } from '../../classes/dateUtility';
import { eSampleTypeIds } from '../../interfaces/constants';
import { eSoilMoistures, eSoilSampleDepths } from '../soil-sample-event/main';
import { takeUntil } from 'rxjs/operators';
import { ISoilSampleEmitted } from './interfaces';
import { Subject } from 'rxjs';

export type SoilSampleFields = 'SoilMoistureId' | 'SampleDepth' | 'SampleTypeId' |
	'Nitrogen' | 'CropStageId' | 'EventDate';

@Component({
	moduleId: module.id,
	selector: 'soil-sample-ui',
	templateUrl: 'soil-sample.html',
	styleUrls: [ 'soil-sample.scss' ]
})

export class FertilizationSoilSampleComponent implements OnInit, OnDestroy {

	public soilSampleEventDateIsFuture: boolean; // true if soil sample event date
	public soilSampleForm: FormGroup;

	private _subscriptions$: Subject<boolean>;

	private readonly NO_SOIL_SAMPLE = -2;

	@Input() public eventDate: Date;
	@Input() public cropStageId: number;
	@Input() public plantingId: number;

	@Output() public onSoilSampleAdded: EventEmitter<ISoilSampleEmitted> = new EventEmitter<ISoilSampleEmitted>();

	public get fs(): { [key in SoilSampleFields]: AbstractControl } {
		if (!this.soilSampleForm) {
			return null;
		}

		return this.soilSampleForm.controls as { [key in SoilSampleFields]: AbstractControl };
	}

	constructor(
		private _fb: FormBuilder,
		private soilSampleEventService: SoilSampleEventService
	) {
	}

	ngOnInit(): void {
		this._subscriptions$ = new Subject();
		this._initializeSoilSampleForm(this.eventDate, this.cropStageId);
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public addSoilSample(): void {

		this.soilSampleEventService.createForFertilizationEvent(this.plantingId, this.soilSampleForm.value)
		.then((response: IEventPostResponseJSON) => {
			if (!response) {
				return;
			}

			if (response.Events && response.Events.length > 0 && response.Events[0]) {
				let firstSample: IRawSoilSample;

				firstSample = this._findFirstSoilSampleFromList(response.Events[0].SoilSamples);

				this.onSoilSampleAdded.emit({
					Id: firstSample.Id,
					EventDate: this.fs.EventDate.value,
					Response: response
				});
			}
		});
	}

	public cancelAddingSoilSample(e: MouseEvent): void {
		if (!this.fs) {
			return;
		}

		this.onSoilSampleAdded.emit({
			Id: this.NO_SOIL_SAMPLE,
			EventDate: new Date(),
			Response: null
		});

		// this.fs.SampleDepth.setValue(1);
		// this.fs.SoilMoistureId.setValue(eSoilMoistures.MOIST);
		// this.fs.Nitrogen.setValue(null);
		// this.soilSampleForm.markAsUntouched();

		if (e !== null) {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	private _findFirstSoilSampleFromList(soilSamples: IRawSoilSample[]): IRawSoilSample {

		let sortedSamples: IRawSoilSample[];

		if (!soilSamples || soilSamples.length === 0) {
			return null;
		}

		// sort soil samples by Id
		sortedSamples = soilSamples.sort((a, b): number => {
			if (a.Id < b.Id) {
				return -1;
			} else if (a.Id > b.Id) {
				return 1;
			} else {
				return 0;
			}
		})

		return sortedSamples[0];
	}

	private _nitrogenRequiredIfNotFuture(eventDateIsFuture: boolean): void {
		if (eventDateIsFuture) {
			this.fs.Nitrogen.clearValidators();
			this.fs.Nitrogen.updateValueAndValidity();
		} else {
			this.fs.Nitrogen.setValidators(Validators.required);
		}
	}

	private _initializeSoilSampleForm(eventDate: Date, cropStageId: number): void {
		this.soilSampleForm = this._fb.group({
			EventDate: [eventDate, [Validators.required,
				this._soilSampleDateValidator(eventDate)]],
			SampleTypeId: [eSampleTypeIds.QUICK_NITRATE_STRIP, Validators.required],
			SampleDepth: [eSoilSampleDepths.FEET_ONE, Validators.required],
			Nitrogen: [null],
			SoilMoistureId: [eSoilMoistures.MOIST, Validators.required],
			CropStageId: [cropStageId]
		});

		this.soilSampleEventDateIsFuture = DateUtility.isFuture(this.fs.EventDate.value);
		this._nitrogenRequiredIfNotFuture(this.soilSampleEventDateIsFuture);

		this.fs.EventDate.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.soilSampleEventDateIsFuture = DateUtility.isFuture(this.fs.EventDate.value);
			this._nitrogenRequiredIfNotFuture(this.soilSampleEventDateIsFuture);
		});
	}

	private _soilSampleDateValidator(eventDate: Date): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;

			if (!eventDate) {
				valid = true;
			} else {
				valid = control.value ? moment(eventDate).isSameOrAfter(control.value) : false;
			}

			return valid ? null : {'dateOnOrAfterFertilizationEventDate': {value: control.value}};
		};
	}
}
