import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ProfileUpdateService {
	private _caspUpdated: Subject<null> = new Subject<null>();
	public caspUpdated = this._caspUpdated.asObservable();

	public updateCaspState(): void {
		this._caspUpdated.next();
	}
}
