import { Component, OnInit } from '@angular/core';
import { UpdateService } from '../../services/update.service';
import { ValidateService } from '../../services/validate.service';
import { UserService } from '../../models/user/service';
import { IChangePassword } from './interfaces';
import { httpStatusCodes, CMError, SuccessResponse } from '../../interfaces/interfaces';

@Component({
	moduleId: module.id,
	selector: 'change-password',
	templateUrl: 'change-password.html',
	styleUrls: [ 'profile.scss']
})

export class ChangePasswordComponent implements OnInit {

	public savePasswordSuccess: boolean = null;
	public errorMessage: string;

	public changePasswordObject: IChangePassword = {
		OldPassword: '',
		NewPassword: '',
		ConfirmPassword: '',
	};

	public formValid = {
		currentPassword: true,
		newPassword: true,
		confirmPassword: true
	}

	private formStarted = {
		currentPassword: false,
		newPasssword: false,
		confirmPassword: false
	}

	constructor(private updateService: UpdateService, private validateService: ValidateService, private userService: UserService) { }

	ngOnInit(): void {

	}

	/*user information validation*/

	public validateCurrentPassword(currentPassword: string) {
		this.formStarted.currentPassword = true;
		this.formValid.currentPassword = this.validateService.validateNotEmptyString(currentPassword);
	}

	public validateNewPassword(newPassword: string) {
		this.formStarted.newPasssword = true;
		this.formValid.newPassword = this.validateService.validateNotEmptyString(newPassword);
	}

	public validateConfirmPassword(confirmPassword: string) {
		this.formStarted.confirmPassword = true;

		this.formValid.confirmPassword = this.validateService.validateNotEmptyString(confirmPassword)
			&& this.changePasswordObject.NewPassword === confirmPassword;
	}

	public changePasswordInvalid(): boolean {
		for (let key in this.formStarted) {
			if (!this.formStarted[key]) {
				return true;
			}
		}
		for (let key in this.formValid) {
			if (!this.formValid[key]) {
				return true;
			}
		}
		return false;
	}

	public changePassword(): void {
		this.userService.changePassword(this.changePasswordObject)
			.then((res: CMError | SuccessResponse) => {
				if (!res) {
					return;
				}

				let success: SuccessResponse;
				let error: CMError;

				success = res as SuccessResponse;
				error = res as CMError;

				this.savePasswordSuccess = success.Message ? true : false;

				if (this.savePasswordSuccess === false) {
					this.errorMessage = error.message;
				}
			});
	}

	public submit(): void {
		if (this.changePasswordInvalid()) {
			return;
		}
		this.changePassword();
	}
}
