<div class="block bk-modal__right bk-modal__right-fertilizers">
	<div class="bk-modal__right-fertilizers__form">
		<form #f="ngForm" *ngIf="fertilizer">
			<div class="bk-modal__right-two-col bk-modal__right-two-col--left">
				<mat-form-field appearance="fill" class="mat-form-field--short">
					<mat-label>
						{{'Fertilizer Name' | translate }}
					</mat-label>
					<input matInput
						type="text"
						required
						name="Name"
						[(ngModel)]="fertilizer.Name"/>
					<mat-error *ngIf="formErrors.Name">
						{{ formErrors.Name | translate }}
					</mat-error>
				</mat-form-field>

				<mat-form-field appearance="fill"
					class="mat-form-field--one-line mat-form-field--short">
					<mat-label>
						{{'Formulation' | translate }}
					</mat-label>
					<mat-select required
						name='formulationTypeId'
						[(ngModel)]="fertilizer.FormulationTypeId">
						<mat-option [value]="1">
							{{'Dry' | translate }}
						</mat-option>
						<mat-option [value]="2">
							{{'Wet' | translate }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<ng-container *ngIf="fertilizer.FormulationTypeId === formulationTypes.WET">
					<mat-form-field appearance="fill" class="mat-form-field--short">
						<input matInput
							type="number"
							step="0.01"
							required
							name='PoundsPerGallon'
							[(ngModel)]="fertilizer.PoundsPerGallon"/>
						<span matSuffix class="mat-form-field-suffix--padded">
							{{'lbs' | translate}}/{{'gal' | translate}}
						</span>
						<mat-error *ngIf="formErrors.PoundsPerGallon">
							{{ formErrors.PoundsPerGallon | translate }}
						</mat-error>
					</mat-form-field>
				</ng-container>
			</div>

			<div class="bk-modal__section">
				<h4 class="bk-modal__section-title">
					{{'Nutrient' | translate }}
				</h4>

				<div class="bk-modal__nutrients-group">
					<div class="bk-model__nutrients-item"
						*ngFor="let nutrient of fertilizer.Nutrients">

						<mat-form-field appearance="fill" class="mat-form-field--three-wide">
							<mat-label>
								{{nutrient.Name}}
							</mat-label>

							<input matInput
								type="number"
								step="0.01"
								size="2"
								[minNumber]="0"
								[maxNumber]="100"
								required
								name="Nutrient{{nutrient.NutrientId}}"
								[(ngModel)]="nutrient.Percentage"/>
							<span matSuffix class="mat-form-field-suffix--padded">
								%
							</span>
						</mat-form-field>
					</div>
				</div>

				<h4 [class.bk-text--error]="nutrientTotalPercentage.validationError !== null"
					class="bk-clearfix">
					{{'Total Nutrients' | translate }}: {{nutrientTotalPercentage.total}}%
				</h4>

				<div *ngIf="nutrientTotalPercentage.validationError === validationErrors.TOTAL_TOO_BIG"
					class="bk-invalid-text--nutrient bk-invalid">
					{{'Total cannot exceed 100%' | translate }}
				</div>
				<div *ngIf="nutrientTotalPercentage.validationError === validationErrors.TOTAL_NOT_GREATER_THAN_ZERO"
					class="bk-invalid-text--nutrient bk-invalid">
					{{'Total must be greater than 0%' | translate }}
				</div>
			</div>
		</form>
	</div>
</div>