<admin-nav></admin-nav>
<div class="bk-admin__page">

    <div class="bk-tab-headers">
        <h5 class="bk-text--gray bk-tab-headers__tab"
            [ngClass]="{'bk-tab-headers__tab--selected': viewType === viewTypes.CROP_TYPE }"
            [routerLink]="['/CropTypes']" [queryParams]="{viewType:viewTypes.CROP_TYPE}">
            {{'Crop Types' | translate }}
        </h5>
        <h5 class="bk-text--gray bk-tab-headers__tab"
            [ngClass]="{'bk-tab-headers__tab--selected': viewType === viewTypes.PREVIOUS_CROP }"
            [routerLink]="['/CropTypes']" [queryParams]="{viewType:viewTypes.PREVIOUS_CROP}">
            {{'Previous Crop' | translate }}
        </h5>
    </div>

    <div class="bk-data-table bk-data-table__weather-stations">
        <div class="bk-data-table__head">
            <div class="bk-data-table__title">
                &nbsp;
                <button class="bk-button bk-data-table__title--button-right"
                    (click)="create()">
                    <span class="bk-data-table__plus-icon">+</span> 
                    {{ (viewType === viewTypes.CROP_TYPE ? 'Add Crop Type' : 'Add Previous Crop') | translate}}
                </button>

            </div>
            <div class="bk-data-table__search">
                <label class="bk-data-table__search--label">
                    {{ 'Filter By' | translate }}
                    <input class="bk-data-table__search--input"
                        (keyup)="search($event.target.value)"
                        placeholder="Name"
                        [(ngModel)]="query"/>
                </label>
            </div>
        </div>

        <div class="bk-data-table__content">
            <mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="Name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'Name' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.Name}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="MaxUptake">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'N Uptake' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.MaxUptake}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="MaxNRec">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'Max N Recommendation' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.MaxNRec}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="CommodityTypeName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'Commodity' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            {{row.CommodityTypeName}}
                        </mat-cell>
                    </ng-container>

                <ng-container matColumnDef="Action">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'Actions' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row"
                        class="bk-data-table__cell-overflow">
                        
                        <button type="button"
                            class="bk-button bk-admin__button"
                            (click)="edit(row.Id)">
                            {{ 'Edit' | translate }}
                        </button>
                        <button type="button"
                            *ngIf="viewType === viewTypes.CROP_TYPE"
                            class="bk-button bk-admin__button"
                            (click)="clone(row.Id)">
                            {{ 'Clone' | translate }}
                        </button>

                        <button type="button"
                            class="bk-button bk-admin__button"
                            (click)="delete(row)">
                            {{ 'Delete' | translate }}
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

            </mat-table>

            <div class="bk-data-table__empty"
                *ngIf="results.length && dataSource && dataSource.filteredData
                    && dataSource.filteredData.length === 0">
                {{ 'There are no results matching' | translate }} 
                <span class="bk-data-table__empty--filter">"{{dataSource.filter}}"</span>
            </div>

            <mat-paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
        </div>
    </div>
</div>