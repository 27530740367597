import * as Highcharts from 'highcharts';
require('highcharts/modules/exporting')(Highcharts);

import { ISoilMoistureSeries, ISoilMoistureHeader } from './soilMoisture.interface';
import { IHighchartsData } from '../../interfaces/views/highCharts';
import { IRange } from '../../interfaces/interfaces';

import { DateUtility } from '../../classes/dateUtility';
import highChartExportOptions from '../shared/highchartsExportOptions';

export class SoilSeriesCharts {

	public isLegendActive: Map<string, boolean>; // maintains visibility of series

	public static areNewHeaders(headers: ISoilMoistureHeader[]): boolean {
		for (let header of headers) {
			if (!header.isDisplayed) {
				continue;
			}

			if (header.SensorName || header.Depth || header.DataType) {
				return false;
			}
		}

		return true;
	}

	constructor() {
		this.isLegendActive = new Map<string, boolean>();
	}

	/**
     *
     * @param data
     * @param graphRange
     * @param dayRange
     * @param index legend index, to show active or inactive
     */
	public convertData(data: ISoilMoistureSeries, dayRange: number): IHighchartsData {

		let date: Date;
		let year: number;
		let month: number;
		let day: number;
		let hours: number;
		let minutes: number;
		let utc: number;
		let value: number;
		// highcharts data points are a 2 or 3 element array
		let point: number[];
		let points: number[][] = new Array();
		let timeRange: number;
		let mostRecentDate: number;
		let result: IHighchartsData;

		if (!data || !data.Header || !data.Dates || data.Dates.length === 0
			|| !data.Values || data.Values.length === 0) {
			return;
		}

		if (this.isLegendActive.get(data.Header.SensorName) === undefined) {
			this.isLegendActive.set(data.Header.SensorName, true);
		}

		result = {
			name: data.Header.SensorName,
			visible: this.isLegendActive.get(data.Header.SensorName),
			data: []
		};

		date = DateUtility.DotNetToDate(data.Dates[data.Dates.length - 1]);
		year = date.getFullYear();
		month = date.getMonth();
		day = date.getDate();
		hours = date.getHours();
		minutes = date.getMinutes();

		mostRecentDate = Date.UTC(year, month, day, hours, minutes);
		timeRange = dayRange * 24 * 60 * 60 * 1000;

		for (let i = 0; i < data.Dates.length; i++) {
			date = DateUtility.DotNetToDate(data.Dates[i]);

			year = date.getFullYear();
			month = date.getMonth();
			day = date.getDate();
			hours = date.getHours();
			minutes = date.getMinutes();

			utc = Date.UTC(year, month, day, hours, minutes);
			value = Number(data.Values[i]);

			if (timeRange === 0
				|| utc > mostRecentDate - timeRange) {
				point = new Array(utc, value);
				points.push(point);
			}
		}

		result.data = points;

		return result;
	}

	public generateHighchart(chartElement: HTMLElement, params: { data: IHighchartsData[], unit: string }): void {
		let options: Highcharts.Options;
		let range: IRange;
		let chart: Highcharts.ChartObject;
		let __this = this;

		if (!params || !params.data) {
			return;
		}

		options = {
			chart: {
				type: 'spline',
				width: 950,
				zoomType: 'y'
			},
			title: {
				text: null
			},
			legend: {
				title: {
					text: 'Sensor'
				}
			},
			xAxis: {
				type: 'datetime',
				dateTimeLabelFormats: {
					// hour: '%H:%M %b %e',
					day: '%b %e %Y',
					week: '%b %e %Y',
					month: '%b %Y'
				},
				title: {
					text: 'Date'
				}
			},
			yAxis: {
				title: {
					text: params.unit
				},
			},
			tooltip: {
				crosshairs: true,
				shared: true,
				dateTimeLabelFormats: {
					// hour: '%b %e %Y',
					hour: '%H:%M %b %e %Y',
					day: '%b %e %Y',
					week: '%b %e %Y',
					month: '%b %Y'
				}
			},
			plotOptions: {
				series: {
					events: {
						legendItemClick: function () {
							__this.isLegendActive.set(params.data[this.index].name,
								this.visible ? false : true);
						}
					}
				}
			},
			series: params.data,
			exporting: highChartExportOptions
		}

		chart = Highcharts.chart(chartElement, options);
	}
}
