import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { WeatherStationsService } from '../../models/weather-station-stats/service';
import { UpdateService } from '../../services/update.service';
import { IWeatherStationAdvanced } from './interfaces';
import { httpStatusCodes } from '../../interfaces/interfaces';

@Component({
	moduleId: module.id,
	selector: 'deactivate-weather-station-dialog',
	templateUrl: 'deactivate-weather-station-dialog.html'
})

export class DeactivateWeatherStationDialog {

	public weatherStation: IWeatherStationAdvanced;

	constructor(public dialogRef: MatDialogRef<DeactivateWeatherStationDialog>,
		@Inject(MAT_DIALOG_DATA) public data: IWeatherStationAdvanced,
		private weatherStationsService: WeatherStationsService,
		private updateService: UpdateService) {

		this.weatherStation = data;
	}

	public close(): void {
		this.dialogRef.close();
	}

	public deactivate(): void {
		this.weatherStationsService.changeActiveStatus(this.weatherStation.Id)
			.then(response => {
				if (response) {
					this.updateService.deactivateWeatherStation(this.weatherStation.Id);
				}

				this.dialogRef.close();
			});
	}
}
