import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { DateUtility } from '../../classes/dateUtility';
import { FertilizationEvent } from './fertilizationEvent';

import { ModelService } from '../../services/model.service';

import { FertilizationEventEditViewModel, IFertilizationEvent, IFertilizationEventGetRecommendationModel,
	IFertilizationEventGetRecommendationModelJSON,
	FertilizationRecommendedSummaryViewModel,
	AlmondRecommendationSummaryModel,
	StrawberryFertilizationRecommendedSummaryViewModel,
	IFertilizationEventSeasonalNUptakeJSON,
	IFertilizationEventGetSeasonalNUptakeModel,
	IFertilizationEventService} from './interfaces';

import { IEventPostResponseJSON } from '../../models/event/interfaces';
import { HttpParams } from '@angular/common/http';
import { CMError } from '../../interfaces/interfaces';
import { EventResponse } from '../../models/event/eventResponse';

@Injectable()
export class FertilizationEventService extends ModelService implements IFertilizationEventService {

	private _urls = {
		createForm: (plantingId: number) => `/v2/plantings/${plantingId}/fertilization-events/form.json`,
		create: (plantingId: number) => `/v0/plantings/${plantingId}/fertilization-events.json`,
		fertilizationEvent: (id: number) => `/v2/fertilization-events/${id}.json`,
		recommendation: {
			summary: (plantingId: number) =>  `/v2/plantings/${plantingId}/fertilization-events/recommendation-summary.json`,
			amount: (plantingId: number) =>  `/v3/plantings/${plantingId}/fertilization-recommendation.json`,
			// amount: (id: number) => `/v2/fertilization-events/${id}/recommendation.json`,
			seasonalUptake: `/v2/fertilization-events/seasonal-n-uptake.json` // almond specific
		}
	};

	public getCreateForm(plantingId: number): Promise<FertilizationEvent> {

		if (!plantingId) {
			return of(null).toPromise();
		}

		return this.get(this._urls.createForm(plantingId),
			(data: FertilizationEventEditViewModel): FertilizationEvent => {
				let fertilizationEvent: FertilizationEvent;

				fertilizationEvent = new FertilizationEvent();
				fertilizationEvent.parseForm(data);

				return fertilizationEvent;
			}
		);
	}

	public getRecommendationSummary(plantingId: number, fertilizationEvent: IFertilizationEvent):
		Promise<FertilizationRecommendedSummaryViewModel
		| AlmondRecommendationSummaryModel | StrawberryFertilizationRecommendedSummaryViewModel> {

		let params: {
			DaystoNextFertilization: number,
			FertilizationsPerMonth: number,
			SoilSampleEventDate: Date,
			EventDate: Date,
			NAppliedFromWater: number,
			EventId?: number,
		};

		if (!fertilizationEvent) {
			return of(null).toPromise();
		}

		params = {
			DaystoNextFertilization: fertilizationEvent.DaysToNextFertilization,
			FertilizationsPerMonth: fertilizationEvent.FertilizationsPerMonth,
			SoilSampleEventDate: fertilizationEvent.SoilSampleEventDate,
			EventDate: DateUtility.ConvertToUTC(fertilizationEvent.EventDate),
			NAppliedFromWater: fertilizationEvent.NAppliedFromWater,
			EventId: fertilizationEvent.Id,
		};

		return this.post(this._urls.recommendation.summary(plantingId), (data: FertilizationRecommendedSummaryViewModel
			| AlmondRecommendationSummaryModel | StrawberryFertilizationRecommendedSummaryViewModel | CMError):
				FertilizationRecommendedSummaryViewModel | AlmondRecommendationSummaryModel
				| StrawberryFertilizationRecommendedSummaryViewModel | CMError => {

			let error: CMError;

			if (!data) {
				return null;
			}

			error = data as CMError;

			if (error.message) {
				return null;
			}

			return data;
		}, params);
	}

	public getEditModel(id: number): Promise<FertilizationEvent> {

		return this.get(this._urls.fertilizationEvent(id), (data: FertilizationEventEditViewModel): FertilizationEvent => {
			let fertilizationEvent: FertilizationEvent;

			fertilizationEvent = new FertilizationEvent();
			fertilizationEvent.parseForm(data);

			return fertilizationEvent;
		});
	}

	public create(plantingId: number, event: IFertilizationEvent): Promise<IEventPostResponseJSON> {
		let fe: IFertilizationEvent;

		let params: {
			FertilizationEvent: IFertilizationEvent,
			ManagerNRecommendation: number,
			ManagerFertilizationAmount: number,
			PlantingId: number,
		};

		fe = jQuery.extend(true, {}, event); // avoid modifying the original object, since it can be reused in
		// cases where the popup doesn't close on save, e.g. when clicking "clear" in N Contribution Water

		fe.EventDate = DateUtility.ConvertToUTC(fe.EventDate);
		fe.IrrigationEventDate = DateUtility.ConvertToUTC(fe.IrrigationEventDate);
		fe.SoilSampleEventDate = DateUtility.ConvertToUTC(fe.SoilSampleEventDate);

		params = {
			FertilizationEvent: fe,
			ManagerNRecommendation: event.managerNRecommendation,
			ManagerFertilizationAmount: event.ManagerFertilizerAmount,
			PlantingId: plantingId,
		}

		return this.post(this._urls.create(plantingId), (data: IEventPostResponseJSON): IEventPostResponseJSON => {
				return data;
			}, params
		);
	}

	/**
	 * Gets seasonal N uptake calculated values for almonds in planting settings
	 * @param params
	 */
	public getSeasonalNUptake(params: IFertilizationEventGetSeasonalNUptakeModel):
		Promise<IFertilizationEventSeasonalNUptakeJSON> {

		return this.post(this._urls.recommendation.seasonalUptake, (data: IFertilizationEventSeasonalNUptakeJSON):
			IFertilizationEventSeasonalNUptakeJSON => {

			return data;
			}, params
		);
	}

	public update(id: number, event: IFertilizationEvent, plantingId: number): Promise<EventResponse> {

		let fe: IFertilizationEvent;

		let params: {
			FertilizationEvent: IFertilizationEvent,
			ManagerNRecommendation: number,
			ManagerFertilizationAmount: number,
			PlantingId: number,
		}

		if (!event) {
			return;
		}

		fe = jQuery.extend(true, {}, event); // avoid modifying the original object, since it can be reused in
		// cases where the popup doesn't close on save, e.g. when clicking "clear" in N Contribution Water

		params = {
			FertilizationEvent: fe,
			ManagerNRecommendation: event.managerNRecommendation,
			ManagerFertilizationAmount: event.ManagerFertilizerAmount,
			PlantingId: plantingId,
		}

		params.FertilizationEvent.EventDate = DateUtility.ConvertToUTC(fe.EventDate);
		params.FertilizationEvent.IrrigationEventDate = DateUtility.ConvertToUTC(fe.IrrigationEventDate);
		params.FertilizationEvent.SoilSampleEventDate = DateUtility.ConvertToUTC(fe.SoilSampleEventDate);

		return this.put(this._urls.fertilizationEvent(id), (data: IEventPostResponseJSON | CMError ):
			EventResponse => {

			let error: CMError;
			let result: EventResponse;

			if (!data) {
				return null;
			}

			error = data as CMError;
			result = new EventResponse(data as IEventPostResponseJSON);

			if (error.message) {
				return null;
			}

			return result;

		}, params);
	}

	public delete(id: number): Promise<IEventPostResponseJSON> {

		return this.deleteItem(this._urls.fertilizationEvent(id), (data: IEventPostResponseJSON): IEventPostResponseJSON => {
			return data;
		});
	}

	public getNRecommendation(plantingId: number, event: IFertilizationEvent):
		Promise<IFertilizationEventGetRecommendationModel> {
		let params: HttpParams;

		if (!event) {
			return of(null).toPromise();
		}

		params = new HttpParams();

		if (event.SoilSampleEventDate) {
			params = params.set('soilSampleEventDate', DateUtility.ConvertToUTC(event.SoilSampleEventDate).toISOString());
		}

		if (event.DaysToNextFertilization || event.DaysToNextFertilization === 0) {
			params = params.set('daysToNextFertilization', event.DaysToNextFertilization.toString());
		}

		if (event.FertilizationsPerMonth || event.FertilizationsPerMonth === 0) {
			params = params.set('fertilizationsPerMonth', event.FertilizationsPerMonth.toString());
		}

		if (event.NAppliedFromWater || event.NAppliedFromWater === 0) {
			params = params.set('nInWater', event.NAppliedFromWater.toString());
		}

		if (event.EventDate) {
			params = params.set('eventDate', DateUtility.ConvertToUTC(event.EventDate).toISOString());
		}

		if (event.Id) {
			params = params.set('eventId', event.Id.toString());
		}

		return this.get(
			this._urls.recommendation.amount(plantingId),
			(r: IFertilizationEventGetRecommendationModelJSON): IFertilizationEventGetRecommendationModel => {
				let result: IFertilizationEventGetRecommendationModel;

				result = {
					PreviousIrrigationEventDate: DateUtility.DotNetToDate(r.PreviousIrrigationEventDate),
					NFertilizationRecommended: r.NFertilizationRecommended,
					Success: r.Success,
					LastIrrigationMethodId: r.LastIrrigationMethodId,
					IrrigationUnitConversionRatio: r.IrrigationUnitConversionRatio
				};

				if (event.NAppliedFromWater === null) {
					event.ratio = r.IrrigationUnitConversionRatio;
					event.IrrigationMethodId = r.LastIrrigationMethodId;
				}

				return result;
			},
			params
		);
	}
}
