import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { UpdateService } from '../../../../services/update.service';
import { AgmMap, LatLngBounds } from '@agm/core';


import { MapControlDirective } from '../../../../directives/map-control.directive';
import { IMarker } from '../../interfaces';
import { WeatherStationsService } from '../../../../models/weather-station-stats/service';
import { IMapIconSize, IMapIcon } from './interfaces';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteConfirmationComponent, DeleteConfirmationSettings } from '../../../shared/dialogs/delete-confirmation';
import { SharedUpdateService } from '../../../shared/dialogs/update.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
	moduleId: module.id,
	selector: 'ranch-weather-station-edit',
	templateUrl: 'edit.html',
	styleUrls: ['edit.scss']
})

/**
 * Refactoring notes:
 *
 * - cleaned up public/private (8/9/2019)
 */
export class WeatherStationEditComponent implements OnInit {
	private _icons: {
		selected: IMapIcon,
		unselected: IMapIcon
	};

	private readonly _iconSizes: {
		regular: IMapIconSize,
		small: IMapIconSize
	}

	@ViewChild(AgmMap, { static: false }) private _map: AgmMap;
	@ViewChild(MapControlDirective, { static: false }) private _mapControl: MapControlDirective;
	private readonly _mininumZoomLevel: number = 7;
	private _zoom = 10;

	public loadingData = true;
	public ranchLocation: IMarker;

	public station: IMarker;
	public zoomOutWarning = false;
	private _subscriptions$: Subject<boolean>;
	private _dialogHideClass = 'bk-dialog--hidden';

	constructor(
		@Inject(MAT_DIALOG_DATA) private _data: { id: number},
		private _dialogRef: MatDialogRef<WeatherStationEditComponent>,
		private _dialog: MatDialog,
		private _sharedUpdateService: SharedUpdateService,
		private _updateService: UpdateService,
		private _weatherStationService: WeatherStationsService) {

		this._iconSizes = {
			regular: {
				height: 50,
				width: 80
			},
			small: {
				height: 10,
				width: 10
			}
		};

		this._icons = {
			selected: {
				url: '../assets/images/icons/map_marker-weather_station-selected.png',
				scaledSize: this._iconSizes.regular
			},
			unselected: {
				url: '../assets/images/icons/map_marker-weather_station.png',
				scaledSize: this._iconSizes.regular
			}
		}
	}

	public ngOnInit(): void {
		this._subscriptions$ = new Subject();
		this.ranchLocation = null;
		this.station = null;
		this._icons.selected.scaledSize = this._iconSizes.regular;
		this._icons.unselected.scaledSize = this._iconSizes.regular;
		this.zoomOutWarning = false;
		this.loadingData = true;

		if (!this._data || !this._data.id) {
			throw new Error('weather station dialog: station ID missing');
		}

		this._getEditWeatherStationData(this._data.id);
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public close(): void {
		this._dialogRef.close();
	}

	public getStationId(stationId: string): string {
		if (this._zoom < this._mininumZoomLevel) {
			return null;
		}

		return stationId;
	}

	public getStationMarker(): IMapIcon {
		if (this._zoom < this._mininumZoomLevel) {
			this._icons.selected.url = '../assets/images/icons/map_marker-weather_station-selected-small.png';
			this._icons.unselected.url = '../assets/images/icons/map_marker-weather_station-small.png';
		} else {
			this._icons.selected.url = '../assets/images/icons/map_marker-weather_station-selected.png';
			this._icons.unselected.url = '../assets/images/icons/map_marker-weather_station.png';
		}

		return this._icons.selected;
	}

	public onDelete(): void {
		let data: DeleteConfirmationSettings;

		data = {
			objectName: 'Weather Station',
			specificName: this.station.Name,
			additionalMessage: 'This will remove this weather station from this ranch.'
		}

		this.hide();
		this._dialog.open(DeleteConfirmationComponent, {
			disableClose: true,
			data: data
		});

		this._sharedUpdateService.delete$.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.delete();
		});

		this._sharedUpdateService.cancel$.pipe(takeUntil(this._subscriptions$)).subscribe(() => {
			this.show();
		});
	}


	public delete(): void {
		this._dialog.closeAll(); // close the delete confirmation dialog

		if (this.station) {
			this._weatherStationService.removeFromRanch(this.station.Id)
				.then(() => {
					this._sharedUpdateService.deleteComplete();
					this._updateService.setRanchSettingsUpdated('weatherStations');
					this.close();
				});
		}
	}

	public hide(): void {
		this._dialogRef.addPanelClass(this._dialogHideClass);
	}

	public show(): void {
		this._dialogRef.removePanelClass(this._dialogHideClass);
	}

	private _getEditWeatherStationData(id: number) {
		this._weatherStationService.getDetails(id)
			.then((res) => {
				if (res) {
					this.station = res.Stations[0]
					this.ranchLocation = res.RanchLocation
				}

				this.loadingData = false;
				this._resetMap();
			});
	}

	private _recenterMap(lat: number, lng: number, zoom: number): Promise<void> {
		let promises: Promise<void>[] = new Array();

		promises.push(this._mapControl.centerMap(lat, lng));
		promises.push(this._mapControl.setZoom(zoom));

		return Promise.all(promises).then(() => {
			this._setInitialBounds();
		});
	}

	private _resetMap(): void {
		if (!this._map) {
			return;
		}
		let __this = this;
		setTimeout(function () {
			__this._map.triggerResize().then(() => {
				if (__this.ranchLocation) {
					__this._recenterMap(__this.ranchLocation.Latitude,
						__this.ranchLocation.Longitude, __this._zoom);
				}
			});
		}, 100);
	}

	private _setInitialBounds(): Promise<LatLngBounds> {
		return this._mapControl.getBounds();
	}
}
