import { iUnprocessedIgnoreSchedule, iIgnoreSchedule } from "./interfaces";
import { DateUtility } from "../../classes/dateUtility";

export class IgnoreSchedule {
    public static convertList(unprocessedIgnoreSchedules: iUnprocessedIgnoreSchedule[]): iIgnoreSchedule[] {
        let ignoreSchedules: iIgnoreSchedule[] = new Array();
        let ignoreSchedule: iIgnoreSchedule;

        if (!unprocessedIgnoreSchedules) {
            return ignoreSchedules
        }

        for (let unprocessedIgnoreSchedule of unprocessedIgnoreSchedules) {
            ignoreSchedule = IgnoreSchedule.convertItem(unprocessedIgnoreSchedule);

            if (ignoreSchedule) {
                ignoreSchedules.push(ignoreSchedule);
            } 
        }

        return ignoreSchedules;
    }

    public static convertItem(unprocessedIgnoreSchedule: iUnprocessedIgnoreSchedule): iIgnoreSchedule {
        let ignoreSchedule: iIgnoreSchedule;

        if (!unprocessedIgnoreSchedule) {
            return null;
        }

        ignoreSchedule = {
            StationId: unprocessedIgnoreSchedule.StationId,
            StartDate: DateUtility.DotNetToDate(unprocessedIgnoreSchedule.StartDate),
            EndDate: DateUtility.DotNetToDate(unprocessedIgnoreSchedule.EndDate),
            IgnorePrecipitation: unprocessedIgnoreSchedule.IgnorePrecipitation,
            IgnoreET: unprocessedIgnoreSchedule.IgnoreET,
            StationName: unprocessedIgnoreSchedule.StationName,
            Id: unprocessedIgnoreSchedule.Id
        }

        return ignoreSchedule;
    }
}