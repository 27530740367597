import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { IMicroSprinklerJSON, IMicroSprinkler, IMicroSprinklerService } from './interfaces';

@Injectable()
export class MicroSprinklerService implements IMicroSprinklerService {

	private urls = {
		list: '/v2/microsprinklers.json'
	}

	public static translatePressure(microSprinklers: IMicroSprinklerJSON[]): IMicroSprinkler[] {
		let result: IMicroSprinkler[];

		result = new Array();

		if (!microSprinklers || microSprinklers.length === 0) {
			return null;
		}

		for (let microSprinkler of microSprinklers) {
			let item: IMicroSprinkler;

			item = {
				Id: microSprinkler.Id,
				OrificeDiameter: microSprinkler.OrificeDiameter,
				Pressure: {
					MicroSprinklerId: microSprinkler.Id,
					Id: null,
					Name: microSprinkler.Pressure
				},
				ApplicationRate: microSprinkler.ApplicationRate,
				Color: microSprinkler.Color,
				// Model: microSprinkler.Model,
				Manufacturer: microSprinkler.Manufacturer,
				Series: microSprinkler.Series
			}

			result.push(item);
		}

		return result;
	}

	public static calculate(flowRate: number, inRowTreeSpacing: number,
		betweenRowTreeSpacing: number, microSprinklersPerTree: number): number {

		let result: number;

		result = flowRate * microSprinklersPerTree * 43560 /
			(inRowTreeSpacing * betweenRowTreeSpacing * 27154);

		return result;
	}

	constructor(private httpService: HttpService) { }

	public list(): Promise<IMicroSprinkler[]> {

		return this.httpService.get({
			url: this.urls.list,
			useLocalStorage: true,
			isWebAPI: true,

			callback: (response: IMicroSprinklerJSON[]) => {
				let result: IMicroSprinkler[];

				result = MicroSprinklerService.translatePressure(response);
				return result;
			}
		});
	}
}
