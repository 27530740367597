import { FertilizerNutrientViewModel, NutrientJSONModel, RanchFertilizerViewModel, NutrientTotalValidationErrors } from './interfaces';

export class Fertilizer {
	public static convertNutrient(nutrients: NutrientJSONModel[]): FertilizerNutrientViewModel[] {
		let result: FertilizerNutrientViewModel[];

		result = new Array();

		if (!nutrients || nutrients.length === 0) {
			return new Array();
		}

		for (let nutrient of nutrients) {
			let item: FertilizerNutrientViewModel;

			item = {
				Name: nutrient.Name,
				NutrientId: nutrient.Id,
				Invalid: false,
				Percentage: nutrient.Percentage
			};

			result.push(item);
		}

		return result;
	}

	/**
	 * Used in fertilizer dialog to display and validate nutrient total percentage
	 */
	public static calculateTotalPercentage(fertilizer: RanchFertilizerViewModel): number {

		let result: number;

		result = 0;

		for (let nutrient of fertilizer.Nutrients) {
			result += nutrient.Percentage;
		}

		return result;
	}

	public static validateNutrientTotal(total: number): NutrientTotalValidationErrors {
		if (total > 100) {
			return NutrientTotalValidationErrors.TOTAL_TOO_BIG;
		} else if (total <= 0) {
			return NutrientTotalValidationErrors.TOTAL_NOT_GREATER_THAN_ZERO;
		} else {
			return null;
		}
	}
}
