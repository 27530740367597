import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../services/notification.service';
import { eNotificationTypes } from '../../interfaces/constants';
import { FeedbackService } from '../feedback/service';

@Component({
	moduleId: module.id,
	selector: 'support',
	templateUrl: 'support.html'
})

export class SupportComponent implements OnInit {

	public description: string;

	constructor(
		public dialogRef: MatDialogRef<SupportComponent>,
		public notificationService: NotificationService,
		public service: FeedbackService
	) {
		// disable click outside
		this.dialogRef.disableClose = true;
	}

	ngOnInit(): void {
	}

	public send(): void {

		// validate ET value before posting...

		if (!this.description) {
			return;
		}

		this.service.send(this.description).then(() => {
			this.dialogRef.close();

			this.notificationService.generateNotifcation({
				type: eNotificationTypes.UPDATE,
				message: 'Your support request was sent successfully.'
			});

		});
	}

	public cancel(): void {
		this.dialogRef.close();
	}
}
