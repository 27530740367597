<admin-nav></admin-nav>
<div class="bk-admin__page">
	<div class="bk-form__header">
		<h5 class="bk-text--gray">
			Global Settings
		</h5>
	</div>
	<form *ngIf="form" [formGroup]="form" class="bk-form">
		<mat-form-field appearance="fill"
			class="mat-form-field--short">
			<mat-label>
				REW Control
			</mat-label>
			<input matInput
				class="mat-input-element--text-align-right"
				type="number"
				step="0.01"
				name="REWControl"
				formControlName="REWControl"/>
		</mat-form-field>

		<mat-form-field appearance="fill"
			class="mat-form-field--short">
			<mat-label>
				TEW Control
			</mat-label>
			<input matInput
				class="mat-input-element--text-align-right"
				type="number"
				step="0.01"
				name="TEWControl"
				formControlName="TEWControl"/>
		</mat-form-field>
		<button mat-raised-button color="primary"
			(click)="save()">Save</button>
	</form>
</div>
