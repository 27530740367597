import { CMError, httpStatusCodes } from '../../interfaces/interfaces';
import { SoilMoisture } from './soilMoisture';
import { ISoilMoistureGraphSeries, ISoilMoistureSeries, ISoilMoistureTab } from './soilMoisture.interface';

export class SoilMoistureSeriesConverter {
	private _seriesList: ISoilMoistureSeries[];

	public constructor(data: ISoilMoistureSeries[] | CMError) {
		let error: CMError;

		error = data as CMError;

		// there is an error with the headers
		if (error && error.code && error.code === httpStatusCodes.continue) {
			return;
		}

		this._seriesList = data as ISoilMoistureSeries[];
	}

	public getGraphSeries(): ISoilMoistureGraphSeries[] {
		let graphSeriesList: ISoilMoistureGraphSeries[] = new Array();
		let graphSeries: ISoilMoistureGraphSeries;
		let tabName: string;

		if (!this._seriesList || this._seriesList.length === 0) {
			return null;
		}

		for (let series of this._seriesList) {
			if (!series.Header) {
				continue;
			}

			tabName = SoilMoisture.getTabNameFromDataType(series.Header.DataType);

			if (!tabName) {
				continue;
			}

			graphSeries = graphSeriesList.filter(x => x.tabName === tabName)[0];

			if (graphSeries) {
				graphSeries.seriesList.push(series);
			} else {
				graphSeriesList.push({
					tabName: tabName,
					seriesList: new Array(series)
				});
			}
		}

		return graphSeriesList;
	}
}
