import { ViewChild, OnDestroy } from '@angular/core';
import { NgForm, AbstractControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class FormComponent implements OnDestroy {

	public f: NgForm;
	@ViewChild('f', { static: false}) currentForm: NgForm;

	public isDirtyOnLoad = false; // set this to true when editing a form instead of populating for the first time
	public formErrors: any;
	public validationMessages: any;
	protected _subscriptions$: Subject<boolean>;

	constructor() {
		this._subscriptions$ = new Subject();
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	ngAfterViewChecked() {
		this.formChanged();
	}

	formChanged() {

		if (this.currentForm === this.f) {
			return;
		}

		this.f = this.currentForm;

		if (this.f) {
			this.f.valueChanges.pipe(takeUntil(this._subscriptions$)).subscribe(data => this.onValueChanged(data));
		}
	}

	onValueChanged(data?: any) {

		if (!this.f) {
			return;
		}

		const form: FormGroup = this.f.form;

		Object.keys(this.formErrors).forEach(field => {
			// clear previous error message (if any)

			this.formErrors[field] = '';
			const control: AbstractControl = form.get(field);

			if (!control) {
				// do nothing
			} else if ((this.isDirtyOnLoad === false && control.dirty && !control.valid) ||
				(this.isDirtyOnLoad === true && !control.valid)) {

				const messages = this.validationMessages[field];

				Object.keys(control.errors).forEach(key => {
					this.formErrors[field] += messages[key] + ' ';
				});
			}
		});
	}

	onSubmit() { // display erros on submit
		if (!this.f) {
			return;
		}

		const form = this.f.form;

		Object.keys(this.formErrors).forEach(field => {
			// clear previous error message (if any)
			this.formErrors[field] = '';
			const control = form.get(field);

			if (control && !control.valid) {
				control.markAsTouched();
				const messages = this.validationMessages[field];

				Object.keys(control.errors).forEach(key => {
					this.formErrors[field] += messages[key] + ' ';
				});
			}
		});
	}
}
