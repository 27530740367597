import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { IPerennialCanopy } from '../../models/crop-type/interfaces';

@Injectable()
export class CropTypeUpdateService {
	private _cropUpdated: Subject<null> = new Subject<null>();
	public cropUpdated = this._cropUpdated.asObservable();

	private _perennialCanopyCreated: Subject<IPerennialCanopy> = new Subject<IPerennialCanopy>();
	public perennialCanopyCreated: Observable<IPerennialCanopy> = this._perennialCanopyCreated.asObservable();

	private _perennialCanopyUpdated: Subject<IPerennialCanopy> = new Subject<IPerennialCanopy>();
	public perennialCanopyUpdated: Observable<IPerennialCanopy> = this._perennialCanopyUpdated.asObservable();

	private _perennialCanopyDeleted: Subject<number> = new Subject<number>();
	public perennialCanopyDeleted: Observable<number> = this._perennialCanopyDeleted.asObservable();

	/**
	 * Triggers when a user is edited or deleted
	 * @param userId
	 */
	public updateList(): void {
		this._cropUpdated.next();
	}

	public addPerennialCanopy(model: IPerennialCanopy): void {
		this._perennialCanopyCreated.next(model);
	}

	public updatePerennialCanopy(model: IPerennialCanopy): void {
		this._perennialCanopyUpdated.next(model);
	}

	public deletePerennialCanopy(id: number): void {
		this._perennialCanopyDeleted.next(id);
	}
}
