import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../components/localization/service';

@Pipe({
	name: 'translate',
	pure: false
})

export class TranslatePipe implements PipeTransform {

	constructor(private translateService: TranslateService) { }

	transform(value: string): string {
		if (!value) {
			return;
		}

		return this.translateService.translate(value);
	}
}
