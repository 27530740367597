import { BOKA } from '../../../../../classes/number';
import { FertilizationRecommendedSummaryViewModel, IVegetableFertilization } from '../../../../fertilization-event/interfaces';
import { TranslateService } from '../../../../localization/service';
import { IGeneratedEquation } from './lettuceEquationGenerator';

export abstract class EquationGenerator {
	protected _s: FertilizationRecommendedSummaryViewModel;
	protected _ts: TranslateService;
	protected equationResult: number;

	public constructor(s: FertilizationRecommendedSummaryViewModel,
		translateService: TranslateService) {
		this._s = s;
		this._ts = translateService;
	}

	public abstract getEquation(): IGeneratedEquation;

	protected isRecommendationClamped(): boolean {
		if (BOKA.NumberUtil.roundToDecimalPlaces(this._s.Recommendation, 5) <
			BOKA.NumberUtil.roundToDecimalPlaces(this.equationResult, 5)) {

			return true;
		} else {
			return false;
		}
	}

	protected getNegativeMessage(result: IGeneratedEquation, index = 1): IGeneratedEquation {
		result.equationDescriptions[index] =
			`${this._ts.translate('Below Zero Fert Rec Warning')}.`

		return result;
	}

	protected getClamped(result: IGeneratedEquation, index = 1): IGeneratedEquation {
		if (!result) {
			throw new Error('no generated equation found');
		}

		result.equationDescriptions[index] = `${this._ts.translate('The recommendation is too large and is clamped to')} <em><strong>
			${this._s.Recommendation.toFixed(2)}</strong></em>. ${this._ts.translate('Adjust Max N Suggestion')}.`;

		return result;
	}

	protected addNinWater(result: IGeneratedEquation, index = 0): IGeneratedEquation {
		if (this._s.NAppliedFromWater > 0) {
			result.equationDescriptions[index] += ' - ' + this._ts.translate('Water N Contribution');
			result.equations[index] += ` - ${this._s.NAppliedFromWater.toFixed(2)}`;
		}
		return result;
	}

	protected isNearHarvest(s: IVegetableFertilization): boolean {
		return (s.SoilNppm < s.SoilNThresholdppm && s.FractionalInterval > s.HarvestIntervalFraction) ? true : false;
	}
}
