import { IEventGroup, IRawEvent, IEventPostResponseJSON } from '../../models/event/interfaces';
import { IIrrigationMethod } from '../../models/irrigation-method/interfaces';
import { EventResponse } from '../../models/event/eventResponse';
import { IrrigationEventUpdateParams } from './irrigationEventUpdateParams';

export interface EvaporationRecordSimple {
	Date: Date,
	ETo: number,
	ETc: number,
	Rain: number,
	Applied?: number,
}

export interface EvaporationRecordJSON {
	Date: string,
	ETo: number,
	ETe?: number,
	ETcAdjusted: number,
	Ke?: number,
	Kc: number,
	KcFinal: number,
	Ks: number,
	Rain: number,
	Applied?: number,
	IrrigationMethodId?: number,
	IrrigationFactor?: number,
}

export interface IrrigationRecommendationSimpleModel {
	EventDate: Date;
	RecommendedIrrigationAmount: number,
	AvailableWater: number,
	AverageCropET: number,
	AverageRainfall: number,
	SumET: number,
	CropCoefficientSum: number,
	LeechingFactor?: number,
	Distribution: number,
	TotalPrecipitation: number,
	CIMISRainfall: number,
	DaysSinceLastIrrigation: number,
	BaseIrrigationAmount: number,
	AverageCropCoefficient: number,
	LastIrrigationEventDate: number,
	CropSensitivity?: number
}

/**
 * Similar to SingleCallIrrigationRecommendationModel but customized to send
 * the minimimum amount of data to server
 */
export interface SimpleSingleCallIrrigationRecommendationModel {
	EventDate: Date,
	CropTypeId: number,
	WetDate: Date,
	HarvestDate: Date,
	WeatherStationId: number,
	AgeOfCrop: number,
	Latitude: number,
	Longitude: number,
	SoilProperties: SingleCallSoilTypeModel,
	IrrigationEvents: SingleCallIrrigationEventModel[]
}

export interface SingleCallIrrigationEventModel {
	EventDate: Date,
	MethodId: number,
	Applied: number
}

export interface SingleCallSoilTypeModel {
	Sand: number,
	Silt: number
}

/**
 * Mirror of IrrigationRecommendationSummaryModel
 */
export interface IIrrigationRecommendationSummaryModel {
	RecommendedIrrigationInterval: number,
	AvailableWater: number,
	AverageCropET: number,
	AverageRainfall?: number,
	BaseIrrigationAmount: number,
	AverageCropCoefficient: number,
	DaysSinceLastIrrigation: number,
	Distribution: number,
	RecommendedIrrigationAmount: number,
	LeechingFactor: number,
	TotalPrecipitation: number,
	CIMISRainfall: string,
	SumET?: number,
	applicationRate?: number,
	CropCoefficientSum?: number,
	CropSensitivity?: number,
	RecommendedIrrigationTime?: number,
	ETEvents?: IETEvent[],
	ValidRainEventsCount?: number,
	WetArea?: number,
	LastIrrigationEventDate: Date,
	HasRecentCutEvents?: boolean,
	HasRecentIrrigationEvent?: boolean,
	IsImpactedByMacroTunnel?: boolean,
}

export interface IIrrigationRecommendationSummaryModelJSON {
	RecommendedIrrigationInterval: number,
	AvailableWater: number,
	AverageCropET: number,
	AverageRainfall?: number,
	BaseIrrigationAmount: number,
	AverageCropCoefficient: number,
	DaysSinceLastIrrigation: number,
	Distribution: number,
	RecommendedIrrigationAmount: number,
	LeechingFactor: number,
	TotalPrecipitation: number,
	CIMISRainfall: string,
	SumET?: number,
	applicationRate?: number,
	CropCoefficientSum?: number,
	CropSensitivity?: number,
	RecommendedIrrigationTime?: number,
	ETEvents?: IETEventJSON[],
	WetArea?: number,
	LastIrrigationEventDate: string,
	HasRecentCutEvents?: boolean,
	HasRecentIrrigationEvent?: boolean,
	IsImpactedByMacroTunnel?: boolean,
	ValidRainEventsCount?: number,
}

export interface IETEventJSON {
	ET: number,
	EventDate: string,
	LastUpdateDate: string,
	Source: string
}

export interface IETEvent {
	ET: number,
	EventDate: Date,
	LastUpdateDate: Date,
	Source: string
}

/**
* Response object from irrigationRecommendedNContribution call
**/
export interface IIrrigationRecommendedNContribution {
	irrigationAmountRecommended: number,
	LastIrrigationMethod: number,
	Success: boolean,
	ratio: number,
	PreviousIrrigationEventDate?: Date
}

export interface IIrrigationRecommendedNContributionJSON {
	irrigationAmountRecommended: number,
	LastIrrigationMethod: number,
	Success: boolean,
	ratio: number,
	PreviousIrrigationEventDate: string
}

export interface IRawIrrigationEvent {
	Metainfo: string,
	Id: number,
	IrrigationMethod: string,
	IrrigationMethodId: number,
	EventDate: string,
	Ratio?: number,
	WaterApplied?: number,
	Rainfall?: number,
	Interval: number,
	IsOnWetDate: boolean,
	RecommendationEventId: number,

	RecommendedIrrigationInterval?: number,
	RecommendedIrrigationAmount: number,
	RecommendedIrrigationTime: number,
	ManagerAmountRecommendation?: number,
	PlantingId: number,

	SprinklerApplicationRate?: number,
	DripApplicationRate?: number,
	MicroSprinklerApplicationRate?: number,

	LastUpdatedUser: string,
	HasFlowMeterData: boolean,
	FlowMeterGallons: number,
	FlowMeterArea: number,

	WetDate?: string,
	HasRecentCutEvent?: boolean,
	HasRecentIrrigationEvent?: boolean,
	ContributingRainfall?: number,
	CustomDeficit?: number
}

export interface IIrrigationEvent {
	Id: number,
	IrrigationMethod: string, // virtual
	IrrigationMethodId: number,
	LastUpdatedUser: string,
	ManagerAmountRecommendation: number,
	PlantingId: number,
	Ratio: number,
	RecommendedIrrigationAmount: number,
	RecommendedIrrigationInterval: number,
	RecommendationEventId?: number,
	WaterApplied: number,
	IsRainfall: boolean,
	Metainfo: string,
	HasRecentIrrigationEvent: boolean,
	HasRecentCutEvent: boolean,

	EventDate: string, // virtual
	DisplayAmount?: number, // virtual,

	// raw model properties from combined events list
	Rainfall?: number,
	Interval?: number,
	IsOnWetDate?: boolean,

	RecommendedIrrigationTime?: number,
	SprinklerApplicationRate?: number
	DripApplicationRate?: number,
	MicroSprinklerApplicationRate?: number,
	HasFlowMeterData?: boolean,

	// Below are required for user icon component.
	// There USED TO BE both flowMeterGallons and FlowMeterGallons (but been unified in the backend)
	// because one appears attached to plantings and other
	// is in all events popup.

	HasFlowmeterData?: boolean,
	FlowMeterGallons?: number,
	FlowMeterArea?: number,

	FlowMeterData?: { // virtual - used to figure out which flowmeter area to display, based on irrigation method
		DripArea: number,
		SprinklerArea: number,
		CalculatedArea: number
	}

	// View properties
	RecommendationInches?: number,
	RecommendationHours?: number,
	AppliedInches?: number,
	AppliedHours?: number,
	DisplayInches?: number,
	DisplayHours?: number,
	ShowDetails?: boolean,

	// form only properties
	PlantingStartDate?: string,
	PlantingEndDate?: string,
	CustomDeficit?: number,
}

export interface IIrrigationEventGetRecency {
	RecentIrrigationEventExists: boolean,
	RecentCutEventExists: boolean
}

export interface IIrrigationEventForm {
	Id: number,
	EventDate: Date,
	IrrigationMethodId: number,
	PlantingStartDate?: string,
	PlantingEndDate?: string,
	TensionFt1?: number,
	TensionFt2?: number,
	TensionFt3?: number,
	TensionFt4?: number,
	WetArea?: number
}

/** Base model should be the same as IrrigationEventEditJSON C# */
export interface IIrrigationEventEditBase {
	CIMISPrecipitation: string,
	DripApplicationRate: number,
	MicroSprinklerApplicationRate: number,
	SprinklerApplicationRate: number,
	DripFlowMeterArea: number;
	FlowMeterArea: number,
	MicroSprinklerFlowMeterArea: number,
	FlowMeterGallons: number,
	HasFlowMeterData: boolean,
	Id: number,
	IrrigationMethodId: number,
	IrrigationMethods: IIrrigationMethod[],
	ManagerAmountRecommendation: number,
	Rainfall: number,
	Ratio: number,
	RecentIrrigationEventExists: boolean,
	RecentCutEventExists: boolean,
	RecommendedHours?: number,
	RecommendedInterval: number,
	WaterApplied: number,
	isLastUpdatedUserCropManage: boolean,
	recalculateFlag: boolean,
	recalculateReason: boolean,
	newRecommendationAmount: number,
	newRecommendationTime: number,
}

export interface IIrrigationEventEditJSON {
	CIMISPrecipitation: string,
	DripApplicationRate: number,
	MicroSprinklerApplicationRate: number,
	SprinklerApplicationRate: number,
	DripFlowMeterArea: number;
	FlowMeterArea: number,
	MicroSprinklerFlowMeterArea: number,
	FlowMeterGallons: number,
	HasFlowMeterData: boolean,
	Id: number,
	IrrigationMethodId: number,
	IrrigationMethods: IIrrigationMethod[],
	ManagerAmountRecommendation: number,
	ManagerAmountRecommendationHours: number,
	Rainfall: number,
	Ratio: number,
	RecentIrrigationEventExists: boolean,
	RecentCutEventExists: boolean,
	RecommendedHours?: number,
	RecommendedInterval: number,
	WaterApplied: number,
	WaterAppliedHours: number,
	isLastUpdatedUserCropManage: boolean,
	recalculateFlag: boolean,
	recalculateReason: boolean,
	newRecommendationTime: number,
	EndDate: string,
	EventDate: string,
	InitialDate: string,
	LastIrrigationEventDate: string,
	PlantingEndDate: string,
	PlantingStartDate: string,
	StartDate: string,
	RecommendedIrrigationAmount: string,
	newRecommendationAmount: number,
	RecommendationSummary: IIrrigationRecommendationSummaryModelJSON,
	TensionFt1: number,
	TensionFt2: number,
	TensionFt3: number,
	TensionFt4: number,
	LastUpdated?: string, // last updated meta
	CustomDeficit?: number,
	AverageDeficit: number,
	DaysSinceLastIrrigation: number,
	IsCustomDeficitEnabled: boolean,
}

export interface IIrrigationEventEditResponse extends IIrrigationEventEditBase {
	EndDate: Date,
	EventDate: Date,
	InitialDate: Date,
	LastIrrigationEventDate: Date,
	PlantingEndDate: Date,
	PlantingStartDate: Date,
	StartDate: Date,
	RecommendedIrrigationAmount: number,
	RecommendationSummary: IIrrigationRecommendationSummaryModel,
	//
	HarvestDate?: Date, // temporary fix
	endDate?: Date, // temporary fix
	startDate?: string, // temporary fix

	newRecommendationAmount: number,
	newRecommendationTime: number,
	FlowMeterWaterApplied: number,
	// virtual
	FlowMeterCalculatedArea: number,
	LastUpdated?: string,
	WaterAppliedHours?: number,
	ManagerAmountRecommendationHours?: number,

	// virtual
	TensionFt1?: number,
	TensionFt2?: number,
	TensionFt3?: number,
	TensionFt4?: number,
	DateOnly?: boolean,
	CustomDeficit?: number,
	AverageDeficit: number,
	DaysSinceLastIrrigation: number,
	IsCustomDeficitEnabled: boolean
}

export interface IRainfallWeather {
	Metainfo: string,
	Id: number,
	Rainfall: number
}

export interface IIrrigationObject {
	SprinklerApplicationRate: number,
	DripApplicationRate: number,
	MicroSprinklerApplicationRate: number,
	IrrigationMethodId: number,
}

/**
 * C# model
 */


/**
export interface IrrigationEventUpdateParams {
	EventDate?: Date,
	IrrigationMethodId: number,
	ManagerAmountRecommendation?: number,
	ManagerAmountRecommendationHours?: number,
	WaterApplied?: number,
	WaterAppliedHours?: number
}
**/

/**
 * This is identical to IrrigationEventCreateViewModel in C# which
 * is used to create irrigation events
 */
export interface IrrigationEventCreateViewModel {
	EventDate?: Date,
	IrrigationMethodId: number,
	TensionFt1?: number,
	TensionFt2?: number,
	TensionFt3?: number,
	TensionFt4?: number,
	HasRecentIrrigationEvent?: boolean,
	HasRecentCutEvent?: boolean,
	ManagerAmountRecommendation?: number,
	ManagerAmountRecommendationHours?: number,
	WaterApplied?: number,
	WaterAppliedHours?: number
}

export interface IIrrigationEventEditViewModel {
	EventDate?: Date,
	IrrigationMethodId: number,
	TensionFt1?: number,
	TensionFt2?: number,
	TensionFt3?: number,
	TensionFt4?: number,
	ManagerAmountRecommendation?: number,
	ManagerAmountRecommendationHours?: number,
	RecommendedAmount: number,
	RecommendedInterval: number,
	RecommendedTime: number,
	Rainfall: number,
	WaterApplied?: number,
	WaterAppliedHours?: number,
	DateOnly?: boolean
}

export interface IIrrigationIntervalRecommendation {
	interval: number,
	date: Date
}

/**
 * Counterpart to IrrigationRecommendationModel in C#
 */
export interface IIrrigationRecommendationModel {
	PlantingId: number,
	EventDate: Date,
	StartDate?: Date,
	IrrigationMethodId: number,
	WetArea?: number
}

export interface IAdditionalWaterModel {
	AverageReferenceET: number,
	ReferenceET: number,
	AvailableSoilMoisture: number,
	CanopyCover: number,
	AvgCanopy: number,
	CropCoefficient: number,
	TotalCropET: number,
	CropStage: string,
	CummulativeWaterAppliedAndRainfall: number,
	EventDate: string,
	IsOnWetDate: boolean,
	Id: number,
	IrrigationInterval: string,
	IrrigationMethod: string,
	Rainfall: number,
	ContributingRainfall: number,
	Ratio: number,
	WaterApplied: number,
	PlantingId: number
}

export interface IAverageDeficit {
	Deficit: number,
	Days: number
}

export const IRRIGATION_INTERVAL_MAX = 15;

export interface IIrrigationEventService {
	irrigationMethods: IIrrigationMethod[],
	getCreateModel(plantingId: number): Promise<IIrrigationEventEditResponse>,
	getIrrigationIntervalSummary(id: number): Promise<IIrrigationRecommendationSummaryModel>,
	getEditModel(id: number): Promise<IIrrigationEventEditResponse>,
	countDuplicatesOnDate(plantingId: number, date: Date): Promise<number>,
	create(plantingId: number, event: IrrigationEventUpdateParams): Promise<IEventPostResponseJSON>,
	getAverageDeficit(plantingId: number, targetDate: Date, eventId: number): Promise<IAverageDeficit>,
	getRecency(id: number, eventDate: Date, plantingId: number):
		Promise<{ RecentIrrigationEventExists: boolean }>,

	getRecommendationSummary(plantingId: number, eventDate: Date, irrigationMethodId: number,
		eventId: number, CustomDeficit: number):
		Promise<IIrrigationRecommendationSummaryModel>

	update(event: IIrrigationEventEditResponse): Promise<EventResponse>,
	delete(id: number): Promise<IEventPostResponseJSON>,
	getAdditionalWaterData(id: number): Promise<IAdditionalWaterModel[]>,

	getNContributionRecommendation(fertilizationEventId: number, startDate: Date, irrigationMethodId: number,
		daysToNextFertilization: number, fertilizationsPerMonth: number):
		Promise<IIrrigationRecommendedNContribution>

}
