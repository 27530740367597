import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { ConvertDotNetDate, IntegerIfZero, PluralPipe, NAIfEmpty, LocaleMonth } from '../../pipes/pipes.misc';
import { ModalModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OrderBy } from '../../pipes/order-by.pipe';
import { RanchWeatherStationModalComponent } from '../../components/ranch-settings/modals/weather-station/main';
import { AgmCoreModule } from '@agm/core';
import { MapControlDirective } from '../../directives/map-control.directive';
import { ModalLoaderComponent } from '../../components/shared/modal-loader/main';
import { CimisOptionsModalComponent } from '../../components/ranch-settings/modals/cimis-options/main';
import { SVGWaterComponent } from '../../components/events/buttons/svg/water';
import { SVGFertilizerComponent } from '../../components/events/buttons/svg/fertilizer';
import { SVGSoilSampleComponent } from '../../components/events/buttons/svg/soil-sample';
import { SVGTissueSampleComponent } from '../../components/events/buttons/svg/tissue-sample';
import { UserIconComponent } from '../../components/shared/user-icon/main';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPopperModule } from 'ngx-popper';
import { GreaterThanNumDirective } from '../../directives/greaterThanNumber';
import { MaxNumberDirective } from '../../directives/maxNumber';
import { MinNumberDirective } from '../../directives/minNumber';
import { SoilPropertiesTableComponent } from '../../components/soil-properties-table/soil-properties-table.component';
import { InfoIconComponent } from '../../components/shared/info-icon/main';
import { AdminNavComponent } from '../../components/admin/nav/main';
import { RouterModule } from '@angular/router';
import { MyDatePickerModule } from 'mydatepicker';
import { FertilizationHoverColComponent } from '../../components/events/table/fertilization-hover-panel/fertilization-hover-col';
import { MatDialogModule } from '@angular/material/dialog';
import { ClickOutsideDirective } from '../../directives/clickOutsideDirective';
import { FlowmeterChartDialog } from '../../components/flowmeter-graph/main';
import { FlowmeterSummaryTableComponent } from '../../components/flowmeter-graph/summary-table/main';
import { FlowmeterDetailsTableComponent } from '../../components/flowmeter-graph/details-table/main';
import { RanchDialog } from '../../components/ranch-settings/modals/ranch/main';
import { MatRadioModule } from '@angular/material/radio';
import { AdminTabComponent } from '../../components/admin/tab/main';
import { ReactiveFormComponent } from '../../components/shared/reactive-form/reactive-form-component';
import { FlowmeterSettingsComponent } from '../../components/flowmeter-graph/flowmeter-settings';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SVGRanchLocationComponent } from '../../components/ranch-settings/modals/weather-station/icons/ranch-location';
import { SVGStationLocationComponent } from '../../components/ranch-settings/modals/weather-station/icons/station-location';
import { MatSelectModule } from '@angular/material/select';
import { WeatherStationEditComponent } from '../../components/ranch-settings/modals/weather-station/edit';
import { SVGRanchMarkerComponent } from '../../components/events/buttons/svg/ranch-marker';
import { SVGLotFilledMarkerComponent } from '../../components/events/buttons/svg/lot-filled';
import { SVGPlantingIconComponent } from '../../components/events/buttons/svg/planting';
import { FertilizationRecommendationComponent } from '../../components/events/table/fertilization-hover-panel/fertilization-recommendation';
import { FertilizationRecommendationSummaryComponent } from
	'../../components/events/table/fertilization-hover-panel/recommendation-summary';
import { FertilizationHoverPanelComponent } from
	'../../components/events/table/fertilization-hover-panel/fertilization-recommendation-hover-panel';
import { MatButtonModule } from '@angular/material/button';
import { SVGRainfallComponent } from '../../components/events/buttons/svg/rainfall';
import { SVGSickleComponent } from '../../components/events/buttons/svg/sickle';
import { SVGStageBasedComponent } from '../../components/events/buttons/svg/stage-based';
import { SVGPointToPointComponent } from '../../components/events/buttons/svg/point-to-point';

/**
 * Shared module should be as lean as possible - otherwise it weighs down lazy loading
 */
@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		ModalModule, // modal and forms is required by soil moisture data modal
			// component...only because the dialog is embedded in a component and not a dialog
		FormsModule,
		AgmCoreModule, // required by weather station popup.. should be able to get rid of this...
		MatIconModule,
		MatTooltipModule,
		MatInputModule,
		MatDatepickerModule,
		MatRadioModule,
		MatSelectModule,
		NgxPopperModule,
		RouterModule,
		MyDatePickerModule,
		MatDialogModule,
		MatButtonModule,
	],
	declarations: [
		TranslatePipe,
		OrderBy,
		ConvertDotNetDate,
		LocaleMonth,
		IntegerIfZero,
		GreaterThanNumDirective,
		MaxNumberDirective,
		MinNumberDirective,
		PluralPipe,
		NAIfEmpty,
		ClickOutsideDirective,
		SoilPropertiesTableComponent,
		RanchWeatherStationModalComponent,
		WeatherStationEditComponent,
		SVGRanchLocationComponent,
		SVGStationLocationComponent,
		CimisOptionsModalComponent,
		ModalLoaderComponent, // possibly shared
		UserIconComponent,
		MapControlDirective,
		SVGWaterComponent,
		SVGRainfallComponent,
		SVGSickleComponent,
		SVGFertilizerComponent,
		SVGSoilSampleComponent,
		SVGStageBasedComponent,
		SVGPointToPointComponent,
		SVGTissueSampleComponent,
		SVGRanchMarkerComponent,
		SVGLotFilledMarkerComponent,
		SVGPlantingIconComponent,
		InfoIconComponent,
		ReactiveFormComponent,
		AdminNavComponent, // should be moved, into an admin module
		AdminTabComponent,
		// CutEventComponent,
		FertilizationRecommendationComponent,
		FertilizationRecommendationSummaryComponent,
		FertilizationHoverPanelComponent,
		FertilizationHoverColComponent,
		FlowmeterChartDialog,
		FlowmeterSettingsComponent,
		FlowmeterSummaryTableComponent,
		FlowmeterDetailsTableComponent,
		RanchDialog,
	],
	exports: [
		TranslatePipe,
		OrderBy,
		ConvertDotNetDate,
		LocaleMonth,
		IntegerIfZero,
		MapControlDirective,
		GreaterThanNumDirective,
		MinNumberDirective,
		MaxNumberDirective,
		PluralPipe,
		NAIfEmpty,
		ClickOutsideDirective,
		SoilPropertiesTableComponent,
		RanchWeatherStationModalComponent,
		CimisOptionsModalComponent,
		SVGWaterComponent,
		SVGSickleComponent,
		SVGPointToPointComponent,
		SVGRainfallComponent,
		SVGStageBasedComponent,
		SVGFertilizerComponent,
		SVGSoilSampleComponent,
		SVGTissueSampleComponent,
		SVGRanchMarkerComponent,
		SVGLotFilledMarkerComponent,
		SVGPlantingIconComponent,
		ModalLoaderComponent,
		UserIconComponent,
		ModalModule,
		InfoIconComponent,
		ModalModule,
		AgmCoreModule,
		MatIconModule,
		MatTooltipModule,
		NgxPopperModule,
		AdminNavComponent,
		AdminTabComponent,
		MyDatePickerModule,
		FertilizationRecommendationComponent,
		FertilizationHoverPanelComponent,
		FertilizationRecommendationSummaryComponent,
		FertilizationHoverColComponent,
		FlowmeterChartDialog,
		FlowmeterSummaryTableComponent,
		FlowmeterDetailsTableComponent,
		MatRadioModule
	],
	entryComponents: [
		FlowmeterChartDialog,
		RanchDialog,
		FlowmeterSettingsComponent,
		RanchWeatherStationModalComponent,
		CimisOptionsModalComponent,
		WeatherStationEditComponent
	]
})

export class SharedModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: SharedModule,
			providers: [
			]
		};
	}
}
