import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CaspService } from './casp.service';
import { CASP_HOMEPAGE_URL, CASPNotNowCookieName} from '../../services/constants';
import { CookieService } from '../../services/cookie.service';
import { environment } from '../../../environments/environment';

@Component({
	moduleId: module.id,
	selector: 'casp-dialog',
	templateUrl: 'casp-dialog.html',
	styleUrls: [ 'casp.scss']
})

export class CaspDialogComponent implements OnInit {

	private _temporaryKey: string;
	public url = CASP_HOMEPAGE_URL;
	public dontAskMeAgain: boolean;
	private _cookieName = CASPNotNowCookieName;

	constructor(
		private _dialogRef: MatDialogRef<CaspDialogComponent>,
		private _service: CaspService
	) {
		// disable click outside
		this._dialogRef.disableClose = true;
	}

	ngOnInit(): void {
		this._preparePopup();
		this.dontAskMeAgain = this._isCookieSet();
	}

	private _preparePopup(): void {
		this._service.temporaryKey().then(response => {
			const popupWidth = 600;
			const popupHeight = 800;

			let windowWidth, windowHeight, left, top: number;
			let button: JQuery;

			if (!response) {
				throw Error('Temporary key call failed');
			}

			this._temporaryKey = response;

			// We use JQuery to append a button to dom since Angular doesn't allow onclick
			// and onclick is needed to open the window without being blocked

			windowWidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ?
				document.documentElement.clientWidth : screen.width;

			windowHeight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ?
				document.documentElement.clientHeight : screen.height;

			left = (windowWidth - popupWidth) / 2;
			top = (windowHeight - popupHeight) / 2;

			button = jQuery(`<button mat-button class="bk-button bk-button--medium bk-dialog__button--primary"
				onclick="window.open('${environment.CASP_LOGIN_URL}?key=${this._temporaryKey}',
				'asdas', 'toolbars=0,width=${popupWidth},height=${popupHeight},top=${top},left=${left},scrollbars=0,resizable=1');">
				Connect with CASP</button>`);

			jQuery('.dialog-actions').append(button);

			jQuery('.bk-dialog__button--primary').click(() => {
				// close this window once the popup window opens
				this._dialogRef.close();
			});
		});
	}

	private _isCookieSet(): boolean {
		let cookie: string;

		cookie = CookieService.get(this._cookieName);

		if (cookie) {
			return true;
		} else {
			// if cookie is set, then it was dismissed.. so its not visible
			return false;
		}
	}

	public notNow(): void {
		this._service.setCookie();
		this._dialogRef.close();
	}
}
