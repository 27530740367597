import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IEventPostResponse, IEventPostResponseJSON } from '../../models/event/interfaces';

@Injectable()
export class MainMenuUpdateService {

	private _userAuthStatusChanged: Subject<null> = new Subject<null>();
	public userAuthStatusChanged$ = this._userAuthStatusChanged.asObservable();

	public fireAuthStatusChanged() {
		this._userAuthStatusChanged.next();
	}
}
