<admin-nav></admin-nav>
<div class="bk-admin__page">

    <h5 class="bk-text--gray">{{'Weather Stations' | translate }}</h5>

    <div class="bk-data-table bk-data-table__weather-stations">
        <div class="bk-data-table__head">
            <div class="bk-data-table__title">
                {{ 'CIMIS Weather Stations' | translate}}
                <button class="bk-button bk-data-table__title--button-right" (click)="openWeatherStationDialog()">
                    <span class="bk-data-table__plus-icon">+</span> {{ 'Add' | translate}}
                </button>
            </div>
            <div class="bk-data-table__search">
                <div class="bk-data-table__date-range">
                    <label>
						{{ 'Show Data From' | translate }}
					</label>
                    <my-date-picker  #startDatePicker [(ngModel)]="startDateFilter" [options]="startDateFilterOptions" (dateChanged)="startDateFilterChanged($event)"
                                    [ngClass]="{'bk-input-invalid--datepicker' : !isDateRangeValid.startDate}"></my-date-picker>
                    <label>
						{{ 'to' | translate}}
					</label>
                    <my-date-picker #endDatePicker [(ngModel)]="endDateFilter" [options]="endDateFilterOptions" (dateChanged)="endDateFilterChanged($event)"
                                    [ngClass]="{'bk-input-invalid--datepicker' : !isDateRangeValid.endDate}"></my-date-picker>
                </div>
                <label class="bk-data-table__search--label">
                    {{ 'Filter By' | translate }}
                    <input class="bk-data-table__search--input" (keyup)="search($event.target.value)" placeholder="Station, Nearest City, County, Code" [(ngModel)]="filterValue"/>
                </label>
                <select class="bk-data-table__search--select" [(ngModel)]="regionId" (ngModelChange)="filterByRegion(regionId)">
                    <option [ngValue]="null">
						{{ 'All Regions' | translate}}
					</option>
                    <option *ngFor="let region of regions" [ngValue]="region.Id">{{region.Name}}</option>
                </select>
            </div>
        </div>

        <div class="bk-data-table__content">
            <mat-table [dataSource]="dataSource" matSort>

                <ng-container matColumnDef="Name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Station' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.Name}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="NearestCity">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Nearest City' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.NearestCity}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="County">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'County' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.County}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="RegionName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Region' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.RegionName}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="ExternalId">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'External ID' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.ExternalId}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="MissingRecords">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Missing Records' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        {{row.MissingRecords}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="RanchCount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header start="desc"> {{ 'Ranches' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.RanchCount}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="RecommendationCount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header start="desc"> {{ 'Recommendations' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{row.RecommendationCount}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <mat-header-cell *matHeaderCellDef> {{ 'Actions' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let row" class="bk-data-table__cell-overflow">
                        
                        <div class="bk-split-button__group">
                            <button type="button"
                                class="bk-button bk-split-button__button"
                                (click)="openWeatherStationStatsDialog(row.Id)"
                                *ngIf="row.Active">
                                {{ 'View' | translate }}
                            </button>
                            <button type="button"
                                class="bk-button bk-split-button__button"
                                (click)="activate(row.Id)"
                                *ngIf="!row.Active">
                                {{ 'Activate' | translate }}
                            </button>
                            <div class="bk-split-button__group--dropdown" ngbDropdown role="group">
                                <button class="bk-button bk-split-button__dropdown-toggle"
                                    ngbDropdownToggle>
                                </button>
                                <div class="bk-split-button__dropdown-menu" ngbDropdownMenu>
                                    <button class="bk-button bk-split-button__dropdown-menu-item"
                                        (click)="openWeatherStationDialog(row)">
                                        {{ 'Edit' | translate }}
                                    </button>
                                    <button class="bk-button bk-split-button__dropdown-menu-item"
                                        (click)="update(row.Id)"
                                        *ngIf="row.Active">
                                        {{ 'Update' | translate }}
                                    </button>
                                    <button class="bk-button bk-split-button__dropdown-menu-item"
                                        *ngIf="row.Active" 
                                        (click)="openDeactivateWeatherStationDialog(row)">
                                        {{ 'Deactivate' | translate }}
                                        <i class='fa fa-warning' *ngIf="row.AllowToDeactivate === false"
                                            title='This weather station is associated with at least one ranch'>
                                        </i>
                                    </button>
                                    <button class="bk-button bk-split-button__dropdown-menu-item"
                                        (click)="details(row)">
                                        {{ 'Details' | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>


                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

            </mat-table>

            <div class="bk-data-table__empty" *ngIf="weatherStations.length && dataSource && dataSource.filteredData && dataSource.filteredData.length === 0">
                {{ 'There are no Weather Stations matching' | translate }} <span class="bk-data-table__empty--filter">"{{dataSource.filter}}"</span>
            </div>

            <div class="bk-data-table__empty" *ngIf="originalWeatherStations.length && !weatherStations.length">
                {{ 'There are no Weather Stations in the specified Region' | translate }}
            </div>

            <div class="bk-data-table__empty" *ngIf="!originalWeatherStations.length">
                {{ 'Currently there are no Weather Stations available' | translate }}
            </div>

            <mat-paginator [pageSizeOptions]="[10, 25]"></mat-paginator>
        </div>
    </div>
</div>