<div class="bk-dialog">
    <button type="button"
        class="bk-button bk-dialog__close"
        (click)="close()">

        <span>&times;</span>
    </button>

    <h4 mat-dialog-title>
         {{ 'Soil Sensor Data' | translate }} - {{ plantingName }}
    </h4>

    <div *ngIf="view === eSoilMoistureViews.GRAPH"
        mat-dialog-content
        [class.bk-soil-moisture-dialog__graph-content]="view === eSoilMoistureViews.GRAPH">
        
        <div class="bk-soil-moisture-dialog__header--tabs">
            <div class="bk-soil-moisture-dialog__tabs">
                <div *ngFor="let tab of tabs"
                    (click)="selectTab(tab.name)"
                    class="bk-soil-moisture-dialog__tab"
                    [class.bk-soil-moisture-dialog__tab--selected]="selectedTab === tab.name"
                    [class.bk-soil-moisture-dialog__tab--disabled]="tab.disabled">

                    {{ tab.name | translate}}
                </div>
            </div>
        </div>

        <div *ngIf="soilSeries"
             class="bk-soil-moisture-dialog__charts-container">
            <div class="bk-soil-moisture-dialog__charts-head">
                <div class="bk-soil-moisture-dialog__depth-options">
                    <div class="bk-soil-moisture-dialog__depth-options--title">
                        {{ 'Soil Depth' | translate }}:
                    </div>

                    <label *ngFor="let depthOption of depthOptions"
                           class="bk-soil-moisture-dialog__depth-option">

                        <input type="checkbox"
                               [(ngModel)]="depthOption.isDisplayed"
                               (change)="filterDepths()" /> 
                        {{ depthOption.name }}<!-- not translating this because it requires a costly code change-->
                    </label>
                </div>
                <div class="bk-soil-moisture-dialog__time-options">
                    <div class="bk-soil-moisture-dialog__time-options--title">
                        {{ 'Timespan' | translate }}:
                    </div>
                    <button class="bk-button bk-button--medium bk-dialog__button--time-option"
                            (click)="updateTimeRange(30)"
                            [class.bk-button--gray]="soilTimeRange !== 30">
                        1{{ 'M' | translate }}
                    </button>
                    <button class="bk-button bk-button--medium bk-dialog__button--time-option"
                            (click)="updateTimeRange(90)"
                            [class.bk-button--gray]="soilTimeRange !== 90">
                        3{{ 'M' | translate }}
                    </button>
                    <button class="bk-button bk-button--medium bk-dialog__button--time-option"
                            (click)="updateTimeRange(180)"
                            [class.bk-button--gray]="soilTimeRange !== 180">
                        6{{ 'M' | translate }}
                    </button>
                    <button class="bk-button bk-button--medium bk-dialog__button--time-option"
                            (click)="updateTimeRange(365)"
                            [class.bk-button--gray]="soilTimeRange !== 365">
                        1{{ 'Y' | translate }}
                    </button>
                    <button class="bk-button bk-button--medium bk-dialog__button--time-option"
                            (click)="updateTimeRange(0)"
                            [class.bk-button--gray]="soilTimeRange !== 0">
                        {{ 'All' | translate }}
                    </button>
                </div>
            </div>

            <ng-container *ngIf="selectedDepth !== null">
                
                <!-- highcharts container -->

                <div *ngFor="let series of soilSeries"
                     #chartContainer
                     class="bk-soil-moisture-dialog__chart">
                </div>
            </ng-container>
        </div>
    </div>

    <div *ngIf="view === eSoilMoistureViews.SETTINGS"
        mat-dialog-content>

        <form #soilMoistureForm="ngForm">

            <div class="bk-soil-moisture-dialog__header--large">
                <i class="fa fa-cog"></i> 
                {{ 'Soil Sensor Data Settings' | translate }}
            </div>

            <div class="bk-soil-moisture-dialog__file-name--container">
                <div class="bk-soil-moisture-dialog__file-name">
                    <label class="bk-dialog__label">
                        {{'Soil Sensor Data File Name' | translate}}
                    </label>
                    <input class="bk-dialog__field bk-soil-moisture-dialog__file-name--input"
                        [(ngModel)]="fileName"
                        name="file"
                        #file="ngModel"
                        required
                        [ngClass]="{'bk-input-invalid' : (file && file.invalid && file.dirty) || soilMoistureLinkError}"
                        (ngModelChange)="onSoilMoistureFileNameChange()" />

                    <div *ngIf="file && file.invalid && file.dirty"
                        class="bk-soil-moisture-dialog__file-name--error">
                        {{ 'The Soil Sensor Data File Name can not be empty' | translate }}.
                    </div>

                    <div *ngIf="soilMoistureLinkError === eSoilMoistureImportErrors.DATE"
                        class="bk-soil-moisture-dialog__file-name--error">
                        {{ 'Soil Sensor Data file contains a bad date format' | translate}}.
                        {{ 'We suggest you use the format YYYY-MM-DD' | translate }}.
                    </div>

                    <div *ngIf="soilMoistureLinkError === eSoilMoistureImportErrors.COLUMNS"
                        class="bk-soil-moisture-dialog__file-name--error">
                        {{ 'Number of columns in Soil Sensor Data file does not match number of columns in the header file' | translate}}.
                        {{ 'Please verify the columns in both files match' | translate }}.
                    </div>

                    <div *ngIf="soilMoistureLinkError === eSoilMoistureImportErrors.NOTFOUND"
                        class="bk-soil-moisture-dialog__file-name--error" >
                        {{ 'Soil Sensor Data file could not be found' | translate}}.
                        {{ 'Please make sure the file exists on the server and try again' | translate }}.
                    </div>

                    <div *ngIf="soilMoistureLinkError === eSoilMoistureImportErrors.EMPTY"
                        class="bk-soil-moisture-dialog__file-name--error">
                        {{ 'Content of Soil Sensor Data file is empty' | translate}}.
                        {{ 'Please verify Soil Sensor Data file contains entries' | translate }}.
                    </div>

                    <div *ngIf="soilMoistureLinkError === eSoilMoistureImportErrors.GENERIC"
                        class="bk-soil-moisture-dialog__file-name--error">
                        {{ 'This URL does not link to a valid data file' | translate }}. 
                        {{ 'Please try again' | translate }}.
                    </div>

                    <div class="bk-soil-moisture-dialog__file-name--help">
                        {{ 'Enter the Soil Sensor Data URL or File Name' | translate }}. 
                        {{ 'If unsure, please leave blank' | translate }}.
                    </div>
                </div>
                <div class="bk-soil-moisture-dialog__file-name--button">
                    <button (click)="link()"
                        class="bk-button"
                        [disabled]="(file && file.invalid && file.dirty) || fileNameIsUnchanged">
                        <i class="fa fa-link"></i> {{'Link file' | translate}}
                    </button>
                </div>
            </div>

            <div class="bk-soil-moisture-dialog__sub-header">
                {{ 'Data Mapping' | translate}}
            </div>

            <div class="bk-soil-moisture-dialog__paragraph">
                {{ 'CropManage can display many types of Soil Sensor Data files' | translate }}.
                {{ 'To ensure your data is displayed correctly' | translate}}, 
                <span class="bk-soil-moisture-dialog__paragraph--alt">
                    {{ 'please ensure that CropManage accurately detected your data type' | translate }}.
                </span>
            </div>

            <div *ngIf="headersValid.sensorName && headersValid.sensorDepth && headersValid.sensorType"
                class="bk-soil-moisture-dialog__sensor-header" >
                {{ 'Detected Sensors' | translate}}
            </div>

            <ng-container *ngIf="!headersValid.sensorName || !headersValid.sensorDepth || !headersValid.sensorType">
                <div class="bk-soil-moisture-dialog__sensor-header bk-soil-moisture-dialog__sensor-header--error">
                    {{ 'Detected Sensors Errors' | translate}}
                </div>
                <div *ngIf="!headersValid.sensorName"
                    class="bk-soil-moisture-dialog__sensor-error">
                    {{ 'Sensor Name field(s) cannot be empty' | translate }}
                </div>
                <div *ngIf="!headersValid.sensorDepth"
                    class="bk-soil-moisture-dialog__sensor-error">
                    {{ 'Sensor Depth must be set for all sensors' | translate }}
                </div>
                <div *ngIf="!headersValid.sensorType"
                    class="bk-soil-moisture-dialog__sensor-error">
                    {{ 'Sensor Type must be set for all sensors' | translate }}
                </div>
            </ng-container>

            <table class="bk-soil-moisture-dialog__headers-table">
                <tr class="bk-soil-moisture-dialog__headers-table--head">
                    <th class="bk-soil-moisture-dialog__headers-table--name-column">
                        {{ 'Sensor Name' | translate }}
                    </th>
                    <th class="bk-soil-moisture-dialog__headers-table--depth-column">
                        {{ 'Sensor Depth' | translate }}
                    </th>
                    <th class="bk-soil-moisture-dialog__headers-table--type-column">
                        {{ 'Sensor Type' | translate }}
                    </th>
                </tr>
                <ng-container *ngFor="let header of headers; let i = index">
                    <tr *ngIf="header.isDisplayed && !(file && file.invalid && file.dirty)"
                        class="bk-soil-moisture-dialog__headers-table--row">
                        <td>
                            <input class="bk-soil-moisture-dialog__headers-table--name"
                                [(ngModel)]="header.SensorName" 
                                [ngClass]="{'bk-input-invalid' : !isNameValid(header.SensorName) }"
                                [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="validateHeaders()"/>
                        </td>
                        <td>
                            <select class="bk-soil-moisture-dialog__headers-table--depth"
                                [(ngModel)]="header.Depth"
                                [ngClass]="{'bk-input-invalid' : !isDepthValid(header.Depth) }"
                                [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="validateHeaders()" >

                                <option *ngFor="let dropdownItem of depthDropdownItems"
                                    [ngValue]="dropdownItem.value">
                                    {{ dropdownItem.name }}
                                </option>
                            </select>
                        </td>
                        <td>
                            <select class="bk-soil-moisture-dialog__headers-table--type"
                                [(ngModel)]="header.DataType"
                                [ngClass]="{'bk-input-invalid' : !isTypeValid(header.DataType) }"
                                [ngModelOptions]="{standalone: true}"
                                (ngModelChange)="validateHeaders()" >

                                <option *ngFor="let dropdownItem of typeDropdownItems"
                                    [ngValue]="dropdownItem.value">
                                    {{ dropdownItem.name }}
                                </option>
                            </select>
                        </td>
                    </tr>
                </ng-container>
            </table>

            <div *ngIf="noHeadersVisible || (file && file.invalid && file.dirty)"
                class="bk-soil-moisture-dialog__empty">
                {{ 'Please link a Soil Sensor Data File to display sensors' | translate}}
            </div>

        </form>
    </div>

    <div *ngIf="view === eSoilMoistureViews.CONFIRMATION"
        mat-dialog-content>
        <div class="bk-dialog__close-confirmation">
            {{'The changes you made were not saved' | translate}}. {{'Discard changes' | translate}}?
        </div>
    </div>

    <div *ngIf="view === eSoilMoistureViews.GRAPH"
        mat-dialog-actions
        align="end">

        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only
            bk-dialog__button--secondary"
            (click)="switchView(eSoilMoistureViews.SETTINGS)">
            <i class="fa fa-cog bk-soil-moisture-dialog__settings-icon"></i> 
            {{ 'Settings' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="close()">
            {{ 'Close' | translate }}
        </button>
    </div>

    <div *ngIf="view === eSoilMoistureViews.SETTINGS"
        mat-dialog-actions
        align="end" >

        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only bk-dialog__button--secondary"
            (click)="close()">
            {{ 'Cancel' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            [disabled]="!isFormValid()"
            (click)="save()">
            {{ 'Save' | translate }}
        </button>
    </div>

    <div *ngIf="view === eSoilMoistureViews.CONFIRMATION"
        mat-dialog-actions
        align="end">

        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only bk-dialog__button--secondary"
            (click)="cancelClose()">
            {{ 'Keep Editing' | translate }}
        </button>

        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="close()">
            {{ 'Discard' | translate }}
        </button>
    </div>
</div>
