<div class="bk-nav">
    <div class="bk-nav__title-container">
        <h4 class="bk-nav__title">{{'Administration' | translate }}</h4>
    </div>
    <ul class="bk-nav__items">

		<li class="bk-nav__item">
			<a routerLink="/cropStages">
				Crop Stages
			</a>
		</li>

		<li class="bk-nav__item">
			<a routerLink="/CropTypes" [routerLinkActive]="'bk-nav__item--selected'">
				Crop Types
			</a>
		</li>
		<!--
		<li class="bk-nav__item">
			<a href="/Admin/FertilizerType" target="_blank">
				Fertilizer Types
			</a>
		</li>
		-->
		<li class="bk-nav__item">
			<a routerLink="/flowmeter">
				Flow Meter Data
			</a>
		</li>
		<li class="bk-nav__item">
			<a routerLink="/GlobalSettings">
				Global Settings
			</a>
		</li>
		<li class="bk-nav__item">
			<a routerLink="/IgnoreSchedules" [routerLinkActive]="'bk-nav__item--selected'">
				Ignore schedules
			</a>
		</li>
		<li class="bk-nav__item">
			<a routerLink="/Localization" [routerLinkActive]="'bk-nav__item--selected'">
				Localization
			</a>
		</li>
		<li class="bk-nav__item">
			<a routerLink="/Ranches" [routerLinkActive]="'bk-nav__item--selected'">
				Ranches
			</a>
		</li>
		<li class="bk-nav__item">
			<a routerLink="/SoilSensor" [routerLinkActive]="'bk-nav__item--selected'">
				Soil Sensors
			</a>
		</li>
		<!--
		<li class="bk-nav__item">
			<a href="/Admin/SoilType" target="_blank">
				Soil Types
			</a>
		</li>
		-->
		<li class="bk-nav__item">
			<a routerLink="/statistics">
				Statistics
			</a>
		</li>
		<li class="bk-nav__item">
			<a routerLink="/WebAPIStatistics">
				Web API Statistics
			</a>
		</li>
		<li class="bk-nav__item">
			<a routerLink="/userManagement">
				User Management
			</a>
		</li>
		<li class="bk-nav__item">
			<a routerLink="/WeatherStations" [routerLinkActive]="'bk-nav__item--selected'">
				Weather Stations
			</a>
		</li>
        <li [class.bk-nav__item--selected]="tab.selected" class="bk-nav__item" *ngFor="let tab of tabs" (click)="selectTab(tab)">
            {{tab.tabTitle}}
        </li>
    </ul>
</div>
<ng-content></ng-content>
