import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { INotificationParams } from '../interfaces/interfaces';
import { NotificationComponent } from '../components/shared/notification/main';

@Injectable()
export class NotificationService {

	constructor(private snackBar: MatSnackBar) { }

	public generateNotifcation(params: INotificationParams): void {

		if (!params) {
			return;
		}

		if (params.message) {
			// capitalize first letter of message for better presentation
			params.message = params.message.charAt(0).toUpperCase() + params.message.substr(1);
		}

		this.snackBar.openFromComponent(NotificationComponent, {
			duration: 10000,
			panelClass: 'bk-notification',
			data: params
		})
	}
}
