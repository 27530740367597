import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { IIrrigationEventEditResponse, IrrigationEventCreateViewModel } from '../interfaces';
import { IRRIGATION_RECENCY_DAYS } from '../../../interfaces/constants';
import { IPlanting } from '../../planting-settings/interfaces';
import { IrrigationService } from '../service';
import { EventGroup } from '../../../models/event/event';
import { RanchService } from '../../ranch-settings/service';
import { UpdateService } from '../../../services/update.service';
import { Subject } from 'rxjs';
import { DateUtility } from '../../../classes/dateUtility';
import { FormGroup, AbstractControl, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { IrrigationEventFields } from '../main';
import { IIrrigationMethod } from '../../../models/irrigation-method/interfaces';
import { IrrigationEventUpdateParams } from '../irrigationEventUpdateParams';
import { PlantingRecalculatorService } from '../../planting-recalculate-modal/plantingRecalculatorService';

export type HistoricalIrrigationEventFields = 'EventDate' | 'IrrigationMethodId';

@Component({
	moduleId: module.id,
	selector: 'historical-ui',
	templateUrl: 'historical-ui.html',
	styleUrls: ['historical-ui.scss']
})

export class HistoricalUIComponent implements OnInit {

	public form: FormGroup;

	@Input()
	public historicalEventSaved$: Subject<void>;

	@Output()
	public isHistoricalIrrigationDatePickerOpen: EventEmitter<boolean> = new EventEmitter();

	@Input()
	public irrigationMethods: IIrrigationMethod[];

	@Input()
	public parentForm: FormGroup;

	@Input()
	public plantingId: number;

	@Input()
	public wetDate: Date;

	private _isSaving: boolean;

	public get f(): { [key in HistoricalIrrigationEventFields]: AbstractControl } {
		return this.form.controls as { [key in HistoricalIrrigationEventFields]: AbstractControl };
	}

	public get pf(): { [key in IrrigationEventFields]: AbstractControl } {
		return this.parentForm.controls as { [key in IrrigationEventFields]: AbstractControl };
	}

	constructor(
		private _fb: FormBuilder,
		private irrigationService: IrrigationService,
		private ranchService: RanchService,
		private _plantingRecalculateService: PlantingRecalculatorService,
		private updateService: UpdateService) { }

	ngOnInit() {
		// default historical date is one day before event date, so its close to the event
		// date, and valid

		if (!this.pf || this.pf.EventDate.invalid) {
			throw new Error('this.event.EventDate is invalid');
		}

		this._initializeForm(this.pf.EventDate.value);
		this._isSaving = false;
	}

	public getMinDateBeforeIrrigationEvent(): Date {
		return DateUtility.addDays(this.pf.EventDate.value, -1 * IRRIGATION_RECENCY_DAYS);
	}

	public onCalendarToggle(event: number): void {
		this.isHistoricalIrrigationDatePickerOpen.emit(event === 1);
	}

	/**
	 * Public, called by parent template
	 */
	public save() {
		let irrigationEvent: IrrigationEventUpdateParams;

		if (this.form.invalid) {
			return;
		}

		if (!this.plantingId) {
			throw new Error('planting ID is not set');
		}

		if (!this.wetDate) {
			throw new Error('wetDate is not set');
		}

		irrigationEvent = new IrrigationEventUpdateParams(null);
		irrigationEvent.setDate(this.f.EventDate.value);
		irrigationEvent.IrrigationMethodId = this.f.IrrigationMethodId.value;

		if (this._isSaving) { // prevent double submit
			return;
		}

		this._isSaving = true;

		this.irrigationService.create(this.plantingId, irrigationEvent)
			.then(res => {
				let eventGroup: EventGroup;

				this._isSaving = false;

				if (!res || !res.Events || !res.Events[0].Irrigation) {
					return false;
				}

				EventGroup.updateEvents(res.EventGroupsSucceeding, this.plantingId,
					this.wetDate, this.ranchService);

				eventGroup = new EventGroup();
				eventGroup.convert(res.Events[0], this.wetDate);

				eventGroup.Id = res.Id;

				this.ranchService.plantings.addEventToPlantings(eventGroup, this.plantingId);
				this.updateService.setPlantingsUpdated(this.updateService.currentRanchId, new Date());
				// update event snapshot
				this.historicalEventSaved$.next();
				this._plantingRecalculateService.recalculate(this.plantingId, true);
			});
	}

	/**
	 * Validates whether EventDate is within IRRIGATION_RECENCY_DAYS
	 * @param eventDate
	 */
	private _eventDateValidator(eventDate: Date): ValidatorFn {
		return (control: AbstractControl): {[key: string]: any} | null => {
			let valid: boolean;
			let daysUntilEventDate: number;

			daysUntilEventDate = DateUtility.daysBetweenDates(control.value, eventDate);

			if (daysUntilEventDate > IRRIGATION_RECENCY_DAYS) {
				return {'dateTooEarly': {value: control.value}};
			} else if (daysUntilEventDate < 1) {
				return {'dateTooLate': {value: control.value}};
			} else {
				return null;
			}
		};
	}

	private _initializeForm(eventDate: Date): void {
		let d: Date;

		d = new Date(eventDate);
		d.setDate(d.getDate() - 1); // default to day before parent event date, since
		// we're creating a previous irrigation event

		this.form = this._fb.group({
			EventDate: [d, [Validators.required, this._eventDateValidator(eventDate)]],
			IrrigationMethodId: [null, Validators.required],
		});
	}
}
