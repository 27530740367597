import { IIdNamePair } from '../../../../interfaces/abstract';
import { IFertilizerProperties } from '../../../fertilization-event/interfaces';

export interface IFormFertilizer {
	Id: number,
	Name: String
}

/**
 * References: Ranch setting: fertilizer list,
 * 	listAvailable (OOTB fertilizers not associated with a ranch)
 */
export interface IFertilizer {
	Description: string,
	Editable: boolean,
	FormulationType: { Id: number, Name: string },
	Id: number,
	IsPrivate: boolean,
	LbsPerGallon: number,
	Name: string,
	OwnerRanchId: number,
	PercentageN: number,
	PoundsPerGallons: number,
	Usage: number, // number of events associated with this fertilizer
	Nutrients: string[],
	// virtual
	checked?: boolean,
}

/**
 * Copy of C# version of the same name
 */
export interface RanchFertilizerViewModel {
	Name: string,
	FormulationTypeId: number,
	PoundsPerGallon: number,
	IsPrivate: boolean,
	OwnerRanchId: boolean,
	Nutrients: FertilizerNutrientViewModel[]

	// virtual

	FormulationType: IIdNamePair
}

export interface FertilizerViewModel {
	PageTitle: string,
	WetFormulationTypeId: number,
	Model: RanchFertilizerViewModel,
	FormulationTypes: IIdNamePair[],
	Nutrients: NutrientJSONModel[]
}

export interface FertilizerNutrientViewModel {
	Name: string,
	NutrientId: number,
	Percentage: number,

	// virtual

	Invalid: boolean
}

export interface NutrientJSONModel {
	Id: number,
	Name: string,
	Name_Alt: string,
	NutrientTypeId: number,
	Symbol: string,
	MeasureUnitTypeId: number,
	TissueMeasureUnitTypeId?: number,
	MeasurementUnit: IIdNamePair,
	Percentage: number,
	value?: number
}

/** percentage validation errors */
export enum NutrientTotalValidationErrors {
	TOTAL_TOO_BIG = 1,
	TOTAL_NOT_GREATER_THAN_ZERO
}

export interface IFertilizerModelService {
	view(fertilizerId: number): Promise<RanchFertilizerViewModel>,
	getEditModel(id: number): Promise<RanchFertilizerViewModel>,
	getCreateModel(): Promise<RanchFertilizerViewModel>,
	add(fertilizer: RanchFertilizerViewModel): Promise<Object>,
	addBatch(fertilizers: number[]): Promise<void>,
	getByRanchId(ranchId: string): Promise<IFertilizer[]>,
	update(id: number, fertilizer: RanchFertilizerViewModel): Promise<IIdNamePair>,
	delete(id: number): Promise<string>,
	listAvailable(): Promise<IFertilizer[]>,
	getProperties(id: number): Promise<IFertilizerProperties>,
}
