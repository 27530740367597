import { Injectable } from '@angular/core';
import { CMErrors, SuccessResponse } from '../../interfaces/interfaces';
import { HttpService, HttpServicePostOptions, HttpServiceGetOptions } from '../../services/http.service';
import { GlobalSettings, IGlobalSettingsService } from './interfaces';

@Injectable()
export class GlobalSettingsService implements IGlobalSettingsService {

	private _urls = {
		get: '/v2/global-settings.json',
		update: '/v2/global-settings.json'
	}

	constructor(private _httpService: HttpService,
	) { }

	public update(model: GlobalSettings): Promise<void> {

		let options: HttpServicePostOptions;

		options = {
			body: model,
			url: this._urls.update,
			isWebAPI: true,

			callback: (response: SuccessResponse | CMErrors): void => {
				let successResponse = response as SuccessResponse;
				let errors = response as CMErrors;

				if (successResponse.Message) {
					return;
				} else if (errors.errors) {
					///
				}

				return;
			}
		}

		return this._httpService.put(options);
	}

	public get(): Promise<GlobalSettings> {
		let options: HttpServiceGetOptions;

		options = {
			url: this._urls.get,
			isWebAPI: true,

			callback: (response: GlobalSettings): GlobalSettings => {
				return response;
			}
		}

		return this._httpService.get(options);
	}
}
