import { Component, Input } from '@angular/core';

@Component({
	moduleId: module.id,
	selector: 'info-icon',
	templateUrl: 'main.html'
})

export class InfoIconComponent {
	@Input()
	public title: string;
}
