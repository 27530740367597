import { Component } from '@angular/core';

@Component({
	moduleId: module.id,
	selector: 'svg-ranch-location',
	template: `
		<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1"
			xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
			<defs></defs>
			<g id="Exports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<g id="icon-ranch-filled" fill-rule="nonzero" fill="#000000">
					<g id="Icon-Ranch-FILLED">
						<path d="M23.797,8.598 L12.297,0.098 C12.12,-0.033 11.878,
						-0.033 11.702,0.098 L0.202,8.598 C0.075,8.692 0,8.842 0,
						9 L0,12.5 C0,12.688 0.106,12.861 0.274,12.945 C0.346,12.982 0.423,
						13 0.5,13 C0.605,13 0.709,12.967 0.797,12.902 L12.029,4.621 L23.202,
						12.902 C23.354,13.013 23.555,13.031 23.724,12.947 C23.894,
						12.861 24,12.689 24,12.5 L24,9 C24,8.842 23.925,8.692 23.797,
						8.598 Z" id="Shape"></path>
						<path d="M23.5,23 L22,23 L22,13.256 L12.027,5.865 L2,
						13.258 L2,23 L0.5,23 C0.224,23 0,23.224 0,23.5 C0,
						23.776 0.224,24 0.5,24 L7.707,24 L12,19.707 L16.293,
						24 L23.5,24 C23.776,24 24,23.776 24,23.5 C24,23.224 23.776,
						23 23.5,23 Z M12,8 C13.378,8 14.5,9.121 14.5,10.5 C14.5,
						11.879 13.378,13 12,13 C10.622,13 9.5,11.879 9.5,10.5 C9.5,
						9.121 10.622,8 12,8 Z M7,23.293 L7,14.707 L11.293,19 L7,
						23.293 Z M7.707,14 L16.293,14 L12,18.293 L7.707,14 Z M17,
						23.293 L12.707,19 L17,14.707 L17,23.293 Z" id="Shape"></path>
					</g>
				</g>
			</g>
		</svg>
    `,
})

export class SVGRanchLocationComponent { }
