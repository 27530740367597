<div class="bk-dialog">

	<h4 mat-dialog-title>
		{{'Delete' | translate }} {{'Crop Type' | translate }}
	</h4>

	<div mat-dialog-content>
		<div class="bk-modal__remove-text">
			{{'Are you sure you want to delete' | translate }} <strong>{{ cropTypeName }}</strong>?
		</div>
	</div>

	<div mat-dialog-actions align="end">

		<button class="bk-button bk-button--medium bk-button--text-only"
			(click)="close()">
			{{'Cancel' | translate }}
		</button>
		<button class="bk-button bk-button--medium"
			(click)="delete()">
			{{(isSaving ? 'Deleting' : 'Delete') | translate }} {{'Crop Type' | translate }}
             <img class='bk-button__loader' *ngIf='isSaving' src='/assets/images/loader-20px.gif'/>
		</button>
	</div>
</div>