<div class="bk-dialog">

	<h4 mat-dialog-title>
		{{'Remove' | translate }} {{'Fertilizer' | translate }}
	</h4>

	<div mat-dialog-content>
		<div class="bk-modal__remove-text">
			{{'Remove Fertilizer Text 1' | translate }}: <strong>{{ fertilizer.name }}</strong>?
		</div>
		<div class="bk-modal__remove-text">
			{{'Remove Fertilizer Text 2' | translate }}
		</div>
	</div>

	<div mat-dialog-actions align="end">

		<button class="bk-button bk-button--medium bk-button--text-only"
			(click)="close()">
			{{'Cancel' | translate }}
		</button>
		<button class="bk-button bk-button--medium"
			(click)="remove()">
			{{'Remove Fertilizer' | translate }}
		</button>
	</div>
</div>