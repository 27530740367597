<div class="bk-profile__tab-container" id="userInformation">
    <h5 class="bk-text--gray">{{'User Information' | translate }}</h5>

    <div *ngIf="saveProfileSuccess" class="bk-alert--success"><i class="fa fa-check"></i>{{'Your Profile was successfully updated' | translate }}<span (click)="saveProfileSuccess=false" class="bk-alert__close">&times;</span></div>

    <form>
        <div class="bk-profile__form" *ngIf="userInfo">
            <div class="bk-modal__body bk-modal__body--white">
                <label class="bk-modal__label">
                    {{'First Name' | translate }}
                </label>

                <input class="bk-modal__field bk-input--full"
                    type="text"
                    name="FirstName"
                    [ngClass]="{'bk-input-invalid' : !formValid.firstName}"
                    [(ngModel)]="userInfo.FirstName"
                    (ngModelChange)="validateFirstName(userInfo.FirstName)"/>

                <div *ngIf="!formValid.firstName"
                    class="bk-invalid-text">
                    {{'First Name cannot be empty' | translate }}
                </div>

                <label class="bk-modal__label">
                    {{'Last Name' | translate }}
                </label>

                <input class="bk-modal__field bk-input--full"
                    type="text"
                    name="LastName"
                    [ngClass]="{'bk-input-invalid' : !formValid.lastName}"
                    [(ngModel)]="userInfo.LastName"
                    (ngModelChange)="validateLastName(userInfo.LastName)"/>

                <div *ngIf="!formValid.lastName"
                    class="bk-invalid-text">
                    {{'Last Name cannot be empty' | translate }}
                </div>

                <label class="bk-modal__label">
                    {{'Preferred Language' | translate }}
                </label>

                <select class="bk-input--full bk-select--medium bk-modal__field"
                    name="LanguageCodeId"
                    [(ngModel)]="userInfo.LanguageCodeId">
                    <option *ngFor="let language of languages" [ngValue]="language.Id">{{language.Language}}</option>
                </select>

                <div class="bk-modal__row bk-modal__row--no-border">
                    <input type="checkbox"
                    class="bk-modal__checkbox"
                    name="BETACommoditiesEnabled"
                    value="userInfo.BETACommoditiesEnabled"
                    [disabled]="betaCommodityTypeCount > 0 && userInfo.BETACommoditiesEnabled"
                    [(ngModel)]="userInfo.BETACommoditiesEnabled"/>
                
                    <span class="bk-modal__checkbox__label">
                        <strong>{{ 'Enable BETA Commodities' | translate }} </strong> - {{ 'BETA Commodities Help Text' | translate }}
                    </span>
                </div>

                <a class="bk-clickable"
                    *ngIf="isCASPEnabled" 
                    (click)="disconnectFromCASP()">
                    {{ 'Disconnect your Account from CASP' | translate }}
                </a>
            </div>
            <div class="modal-footer bk-modal__footer">
                <button [disabled]="userInfoInvalid()"
                    class="bk-button bk-button--medium"
                    (click)="updateUserDetails()">
                    {{'Save' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>