import * as moment from 'moment';

export class DateUtility {

	public static monthsAsStrings: string[] = ['January', 'February', 'March', 'April',
		'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	public static languageCodes: string[] = ['en-us', 'es'];

	/**
	 * We're checking for both string formats, even though we're moving to Web API
	 * because the dates in unit test (which there are hundreds) are still in
	 * the old format
	 *
	 * @param s either Web API format e.g. "2019-02-19T00:00:01" or
	 * MVC format \/Date(123423232)\/
	 */
	public static DotNetToDate(s: string): Date {
		let result: Date;
		let n: number;

		if (!s) {
			return null;
		}

		if (typeof s !== 'string') {
			return s;
		}

		if (s.includes('\/Date(') === false) {
			let a: string[] = s.split(/[^0-9]/); // fix for safari which
				// generates an incorrect date for new Date('2020-01-01T00:00:00')
			return new Date (Number(a[0]), Number(a[1]) - 1, Number(a[2]), Number(a[3]), Number(a[4]), Number(a[5]));
		}

		n = parseInt(s.substr(6), 10);

		if (n === -62135568000000) {
			return null; // C# null date, in year 0001
		}

		result = new Date(n);

		return result;
	}

	public static DateToUTC(d: Date): number {
		let year, month, day, hours, minutes: number, result;

		if (!d) {
			return null;
		}

		year = d.getFullYear();
		month = d.getMonth();
		day = d.getDate();
		hours = d.getHours();
		minutes = d.getMinutes();

		result = Date.UTC(year, month, day, hours, minutes);

		return result;
	}

	public static GetLastDayOfYear(d: Date): Date {
		let result: Date;

		result = new Date(d.getFullYear(), 11, 31);

		return result;
	}

	public static GetFirstDayOfYear(d: Date): Date {
		let result: Date;

		result = new Date(d.getFullYear(), 0, 1);

		return result;
	}



	/**
	 * This method preserves the date's year, month, date, and generates
	 * a UTC date that can be sent back to server
	 *
	 * @param d local date
	 */
	public static ConvertToUTC(d: Date): Date {
		let month, year, day: number;
		let result: Date;

		if (!d) {
			return null;
		}

		if (typeof d.getFullYear !== 'function') {
			throw 'not a date';
		}

		year = d.getFullYear();
		month = d.getMonth();
		day = d.getDate();

		result = new Date(Date.UTC(year, month, day, 0, 0));

		return result;
	}

	public static areSameYear(x: Date, y: Date): boolean {
		let years: number[];

		if (x === null || x === undefined || y === null || y === undefined) {
			return null;
		}

		years = [x.getFullYear(), y.getFullYear()];

		if (years[0] === years[1]) {
			return true;
		} else {
			return false;
		}
	}

	public static yyyymmdd(date: Date): string {

		if (!date) {
			return null;
		}

		let yyyy: string = date.getFullYear().toString();
		let mm: string = (date.getMonth() + 1).toString(); // getMonth() is zero-based
		let dd: string = date.getDate().toString();

		return yyyy + (mm[1] ? mm : '0' + mm[0]) + (dd[1] ? dd : '0' + dd[0]); // padding
	}

	/**
	 * What's the point of this method? why don't we just call moment(x)?
	 *
	 * @param x
	 */
	public static convertToMoment(x: string | Date): moment.Moment {
		let eventDateArr: string[];

		if (typeof x !== 'string') { // this assumes x is a date. what if its a number?
			return moment(x);
		}

		eventDateArr = x.split('/');

		if (eventDateArr.length < 3) {
			return moment(x);
		}

		return moment(x, 'MM-DD-YYYY');
	}

	public static dotNetToMMDDYYYY(dotNetDate: string): string {
		let date: Date;
		let month: number;
		let day: number;
		let year: number;

		if (!dotNetDate || typeof dotNetDate !== 'string') {
			return null;
		}

		date = DateUtility.DotNetToDate(dotNetDate);

		if (!date || Object.prototype.toString.call(date) !== '[object Date]' || isNaN(date.getTime())) {
			return null;
		}

		month = date.getMonth() + 1;
		day = date.getDate();
		year = date.getFullYear();

		return month + '/' + day + '/' + year;
	}

	/** the method names should be the same, with overrides */
	public static isSameDate(a: moment.Moment, b: moment.Moment) {
		return a.year() === b.year() && a.month() === b.month() && a.date() === b.date();
	}

	/**
	 * JS version of isSameDate - ignore hours, minutes, seconds
	 * @param a
	 * @param b
	 */
	public static areEqualDates(a: Date, b: Date): boolean {
		if (!a || !b) {
			return null;
		}

		return a.toLocaleDateString('en-US') === b.toLocaleDateString('en-US')
	}

	public static getmmddyyyyString(date: string | Date): string {
		let __this = this;

		if (!date) {
			return;
		}

		if (Object.prototype.toString.call(date) !== '[object Date]') {
			date = __this.DotNetToDate(date as string);
		}

		return moment(date).format('M/D/YYYY');
	}

	public static isMMDDYYYYString(dateString: string): boolean {
		let dateArr: string[];

		if (!dateString || typeof dateString !== 'string') {
			return false;
		}

		dateArr = dateString.split('/');

		if (dateArr.length !== 3) {
			return false;
		}

		for (let str in dateArr) {
			if (isNaN(Number(str))) {
				return false;
			}
		}

		return true;
	}

	public static MMDDYYYYToDate(mmddyyyy: string): Date {
		let tempMoment: moment.Moment;

		if (!mmddyyyy) {
			return;
		}


		tempMoment = moment(mmddyyyy, 'M/D/YYYY');

		return tempMoment.toDate();
	}

	public static addDays(date: Date, days: number): Date {
		let newDate: Date;

		if (!date || days === null) {
			return;
		}

		newDate = new Date(date);
		newDate.setDate(newDate.getDate() + days);

		return newDate;
	}

	public static subtractDays(date: Date, days: number): Date {
		let newDate: Date;

		if (!date || days === null) {
			return;
		}

		newDate = new Date(date);
		newDate.setDate(newDate.getDate() - days);

		return newDate;
	}

	/**
     * if first is later than second, this will return a negative number of days
     * @param first
     * @param second
     */
	public static daysBetweenDates(first: Date, second: Date): number {
		let a: moment.Moment;
		let b: moment.Moment;

		if (!first) {
			return null;
		}

		if (!second) {
			return null;
		}

		if (first.getFullYear() === 1 || second.getFullYear() === 1) {
			return -1;
		}

		a = moment([first.getFullYear(), first.getMonth(), first.getDate()]);
		b = moment([second.getFullYear(), second.getMonth(), second.getDate()]);

		// days from A until B is B.diff(A, 'days');
		return b.diff(a, 'days');
	}

	// assumes string formate as 'MMDDYYYY'
	public static daysBetweenDateStrings(first: string, second: string): number {
		let firstAsMoment: moment.Moment = DateUtility.convertToMoment(first);
		let secondAsMoment: moment.Moment = DateUtility.convertToMoment(second);

		return secondAsMoment.diff(firstAsMoment, 'days');
	}

	// currently not working, timeboxed and will save for later
	public static getLocalizedMonths(languageId: number): string[] {
		let currentMoment: moment.Moment = moment();
		let languageCode: string;
		let months: string[] = new Array();

		if (!languageId) {
			return months;
		}

		languageCode = DateUtility.languageCodes[languageId];

		if (!languageCode) {
			return months;
		}

		for (let i = 0; i < 12; i++) {
			months.push(currentMoment.locale(languageCode).month(i).format('MMMM'));
		}

		return months;
	}

	public static getDotNetAcceptableDateParameter(date: Date): string {
		return moment(date).format('YYYY-MM-DDTHH:mm:ss');
	}

	/**
     * Used for cut events.. determines if a cut event date is future vs today/past
     * in the latter case, yield value is required
     * @param date
     */
	public static isFuture(date: Date): boolean {
		let today: Date = new Date();
		let targetDate: moment.Moment;
		let difference: number;

		if (!date) {
			return false;
		}

		targetDate = moment([date.getFullYear(), date.getMonth(), date.getDate()]);
		difference = moment([today.getFullYear(), today.getMonth(), today.getDate()]).diff(targetDate, 'days');

		if (difference < 0) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * returns true if future or today
	 *
	 * difference is difference between date and today, in day
	 *
	 * @param date
	 */
	public static isTodayOrFuture(date: Date): boolean {
		let today: Date = new Date();
		let targetDate: moment.Moment;
		let difference: number;

		if (!date) {
			return;
		}

		targetDate = moment([date.getFullYear(), date.getMonth(), date.getDate()]);
		difference = moment([today.getFullYear(), today.getMonth(), today.getDate()]).diff(targetDate, 'days');

		if (difference <= 0) {
			return true;
		} else {
			return false;
		}
	}

	public static isToday(date: Date): boolean {
		let today: Date;

		today = new Date();

		return date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
	}

	public static datesAreSame(first: Date, second: Date): boolean {
		if (!first || !second) {
			return null;
		}

		return first.getFullYear() === second.getFullYear() &&
			first.getMonth() === second.getMonth() &&
			first.getDate() === second.getDate();
	}

	/**
	 * Get the difference between two dates. if the result is positive, first date
	 * is greater than the second date. If the result is negative, second date is greater
	 * than the first date.
	 *
	 * @param first
	 * @param second
	 */
	public static getDifference(first: Date, second: Date): number {
		return first.getTime() - second.getTime();
	}

	public static getDifferenceInDays(first: Date, second: Date): number {
		let difference = this.getDifference(first, second);

		return Math.abs(Math.floor(difference / (1000 * 60 * 60 * 24)));
	}

	/**
	 * Used to count number of weeks a day falls on a calendar view
	 * @param date
	 * @param firstDateOfCalendar
	 */
	public static getWeekOfMonth(date: Date, firstDateOfCalendar: Date): number {
		let result: number;

		result = Math.floor(Math.abs(moment(date).diff(moment(firstDateOfCalendar), 'days')) / 7);

		return result;
	}

	/**
	 *
	 * @param d
	 * @param target
	 * @returns
	 */
	public static isOnOrBefore(d: Date, target: Date): boolean {
		if (!d || !target) {
			return;
		}

		if (d.getFullYear() > target.getFullYear()) {
			return false;
		}

		if (d.getFullYear() < target.getFullYear()) {
			return true;
		}

		if (d.getMonth() > target.getMonth()) {
			return false;
		}

		if (d.getMonth() < target.getMonth()) {
			return true;
		}

		if (d.getDate() <= target.getDate()) {
			return true;
		} else {
			return false;
		}
	}

	public static IsDate(d: Date | string): boolean {
		if (Object.prototype.toString.call(d) === '[object Date]') {
			// it is a date
			if (isNaN((d as Date).getTime())) {  // d.valueOf() could also work
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}

	public static formatDate(date: Date): string {
		if (!date) {
			return null;
		}

		return date.getDate() + ' ' + date.toLocaleString('en-us', { month: 'short' }) + ' ' + date.getFullYear();
	}

	/**
	 * Given month, return an array containing numbers that represent possible dates
	 * in the month
	 * @param month zero indexed, so January is 0
	 */
	public static getDaysOfTheMonth(month: number): number[] {
		let knuckleDays: number[]; // days that have 31 days, counted using knuckles
		let maxDays: number; // maximum number of days in a month
		let result: number[];

		if (month === null || month === undefined) {
			return new Array();
		}

		knuckleDays = [1, 3, 5, 7, 8, 10, 12];
		result = new Array();

		month += 1; // month is zero indexed

		if (month === 2) {
			maxDays = 29;
		} else if (knuckleDays.indexOf(month) === -1) {
			maxDays = 30;
		} else {
			maxDays = 31;
		}

		for (let i = 1; i <= maxDays; i++) {
			result.push(i);
		}

		return result;
	}
}
