<div class="bk-dialog bk-ignore-schedule-dialog" (clickOutside)="close($event)">
    <button type="button" class="bk-dialog__close" (click)="close()">
        <span>&times;</span>
    </button>

    <h4 mat-dialog-title *ngIf="!ignoreSchedule.Id" > {{ 'Add Weather Station Ignore Schedule' | translate }} </h4>
    <h4 mat-dialog-title *ngIf="ignoreSchedule.Id && view !== ignoreScheduleViewsEnum.DELETECONFIRMATION"> {{ 'Edit Weather Station Ignore Schedule' | translate }} </h4>
    <h4 mat-dialog-title *ngIf="ignoreSchedule.Id && view === ignoreScheduleViewsEnum.DELETECONFIRMATION"> {{ 'Delete Weather Station Ignore Schedule' | translate }} </h4>

    <div mat-dialog-content *ngIf="weatherStations && view === ignoreScheduleViewsEnum.FORM">
        <form #ignoreScheduleForm="ngForm">
            <label class="bk-dialog__form--label">{{ 'Weather Station' | translate }}</label>
            <select class="bk-dialog__form--input" [(ngModel)]="ignoreSchedule.StationId" required name="stationId"
                    #stationId="ngModel" [ngClass]="{'bk-input-invalid' : (showErrors || stationId.dirty) && stationId.errors }">
                <option *ngFor="let weatherStation of weatherStations" [ngValue]="weatherStation.Id">{{weatherStation.Name}}</option>
            </select>
            <div class="bk-dialog__form--invalid-text bk-dialog__form--invalid-text-station-select" *ngIf="(showErrors || stationId.dirty) && stationId.errors">
                {{ 'Please select a' | translate }} {{ 'Weather Station' | translate}}
            </div>

            <div class="bk-ignore-schedule-dialog__date-range">
                <div class="bk-ignore-schedule-dialog__date-picker">
                    <label class="bk-ignore-schedule-dialog__date-label">{{'Start Date' | translate}}</label>
                    <my-date-picker #startDatePicker [(ngModel)]="startDate" [options]="startDateOptions" (dateChanged)="startDateChanged($event)"
                                    [ngClass]="{'bk-input-invalid--datepicker' : !isDateRangeValid.startDate}" [ngModelOptions]="{standalone: true}">
                    </my-date-picker>
                    <div class="bk-dialog__form--invalid-text" *ngIf="!isDateRangeValid.startDate">
                        {{ 'Start Date' | translate }} {{ 'must be a date that is less than or equal to the End Date' | translate}}
                    </div>
                </div>

                <div class="bk-ignore-schedule-dialog__date-range--text">{{ 'to' | translate}}</div>
            
                <div class="bk-ignore-schedule-dialog__date-picker">
                    <label class="bk-ignore-schedule-dialog__date-label">{{'End Date' | translate}}</label>
                    <my-date-picker #endDatePicker [(ngModel)]="endDate" [options]="endDateOptions" (dateChanged)="endDateChanged($event)"
                                    [ngClass]="{'bk-input-invalid--datepicker' : !isDateRangeValid.endDate}" [ngModelOptions]="{standalone: true}">
                    </my-date-picker>
                    <div class="bk-dialog__form--invalid-text" *ngIf="!isDateRangeValid.endDate">
                        {{ 'End Date' | translate }} {{ 'must be a date that is greater than or equal to the Start Date' | translate}}
                    </div>
                </div>
            </div>

            <div class="bk-dialog__checkbox-options">
                <input type="checkbox" class="bk-dialog__checkbox" [(ngModel)]="ignoreSchedule.IgnoreET" name="IgnoreET"
                    #IgnoreET="ngModel" (ngModelChange)="validateIgnoreOptions()" 
                    [ngClass]="{'bk-input-invalid--checkbox' : (showErrors || IgnoreET.dirty || IgnorePrecipitation.dirty) && !areIgnoreOptionsValid}"/>
                <label class="bk-dialog__checkbox--label">
                    {{ 'Ignore ET' | translate}}
                </label>

                <input type="checkbox" class="bk-dialog__checkbox" [(ngModel)]="ignoreSchedule.IgnorePrecipitation" name="IgnorePrecipitation"
                    #IgnorePrecipitation="ngModel" (ngModelChange)="validateIgnoreOptions()" 
                    [ngClass]="{'bk-input-invalid--checkbox' : (showErrors || IgnoreET.dirty || IgnorePrecipitation.dirty) && !areIgnoreOptionsValid }"/>
                <label class="bk-dialog__checkbox--label">
                    {{ 'Ignore Precipitation' | translate}}
                </label>
            </div>
            <div class="bk-dialog__form--invalid-text" *ngIf="(showErrors || IgnoreET.dirty || IgnorePrecipitation.dirty) && !areIgnoreOptionsValid">
                {{ 'Either ET or Precipitation checkbox must be checked' | translate}}
            </div>
        </form>
    </div>

    <div mat-dialog-content *ngIf="view === ignoreScheduleViewsEnum.DISCARDCHANGES">
        <div class="bk-dialog__paragraph">
            {{ 'The changes you made were not saved' | translate}}. {{'Discard changes' | translate}}?
        </div>
    </div>

    <div mat-dialog-content *ngIf="view === ignoreScheduleViewsEnum.DELETECONFIRMATION">
        <div class="bk-dialog__paragraph">
            {{ 'Are you sure you want to delete this Ignore Schedule' | translate}}?
        </div>
    </div>

    <div mat-dialog-actions align="end" *ngIf="view === ignoreScheduleViewsEnum.FORM">
        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only
            bk-dialog__button--secondary bk-dialog__button--left"
            *ngIf="ignoreSchedule.Id" (click)="view = ignoreScheduleViewsEnum.DELETECONFIRMATION">
            {{ 'Delete' | translate }}
        </button>
        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only
            bk-dialog__button--secondary" (click)="close()">
            {{ 'Cancel' | translate }}
        </button>
        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            *ngIf="!ignoreSchedule.Id" (click)="add()">
            {{ 'Add' | translate }}
        </button>
        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            *ngIf="ignoreSchedule.Id" (click)="save()">
            {{ 'Save' | translate }}
        </button>
    </div>

    <div mat-dialog-actions align="end" *ngIf="view === ignoreScheduleViewsEnum.DISCARDCHANGES">
        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only
            bk-dialog__button--secondary" (click)="view = ignoreScheduleViewsEnum.FORM">
            {{ 'Keep Editing' | translate }}
        </button>
        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="close()">
            {{ 'Discard' | translate }}
        </button>
    </div>

    <div mat-dialog-actions align="end" *ngIf="view === ignoreScheduleViewsEnum.DELETECONFIRMATION">
        <button mat-button
            class="bk-button bk-button--medium bk-button--text-only
            bk-dialog__button--secondary" (click)="view = ignoreScheduleViewsEnum.FORM">
            {{ 'Cancel' | translate }}
        </button>
        <button mat-button
            class="bk-button bk-button--medium bk-dialog__button--primary"
            (click)="delete()">
            {{ 'Delete Ignore Schedule' | translate }}
        </button>
    </div>
</div>