import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CropTypeUpdateService } from './cropType.update.service';
import { IPerennialCanopy } from '../../models/crop-type/interfaces';

@Component({
	moduleId: module.id,
	selector: 'ap-canopy-delete-confirmation',
	templateUrl: 'canopy-delete-confirmation.html'
})

export class CanopyDeleteConfirmationDialog implements OnInit {

	public constructor(
		private _dialogRef: MatDialogRef<CanopyDeleteConfirmationDialog>,
		@Inject(MAT_DIALOG_DATA) private _data: { Id: number },
		private _updateService: CropTypeUpdateService,
	) {

	}

	ngOnInit(): void {
	}

	public delete(): void {
		this._updateService.deletePerennialCanopy(this._data.Id);
		this._dialogRef.close();
	}

	public close() {
		this._dialogRef.close();
	}
}
