import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-must-confirm-email',
	templateUrl: 'must-confirm-email.page.html'
})
export class MustConfirmEmailPage implements OnInit, OnDestroy {

	public email: string;
	public isResendCompleted: boolean; // this flag is set to true after user clicks "resend confirmation"

	private _queryKeys = {
		email: 'email'
	};

	private _subscriptions$: Subject<boolean>;

	constructor(
		private _loginService: LoginService,
		private _titleService: Title,
		private _route: ActivatedRoute) { }

	ngOnInit(): void {
		this.isResendCompleted = false;
		this._subscriptions$ = new Subject();
		this._titleService.setTitle('CropManage | Please Confirm Email');

		this._route.queryParams.pipe(takeUntil(this._subscriptions$)).subscribe((params) => {
			if (!params) {
				return;
			}

			if (params[this._queryKeys.email]) {
				this.email = params[this._queryKeys.email];
			}
		});
	}

	ngOnDestroy(): void {
		if (!this._subscriptions$) {
			return;
		}

		this._subscriptions$.next(true);
		this._subscriptions$.complete();
	}

	public resendConfirmation(): void {
		this._loginService.resendConfirmation(this.email).then((response) => {
			// tell user that the confirmation was sent...
			// the page does NOT refresh
			this.isResendCompleted = true;
		});
	}
}
