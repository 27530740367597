import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UpdateService } from '../../services/update.service';
import { CookieService } from '../../services/cookie.service';
import { TokenService } from '../../services/token.service';


@Component({
	moduleId: module.id,
	selector: 'admin',
	templateUrl: 'main.html'
})

export class AdminComponent implements OnInit {
	constructor(
		private _updateService: UpdateService,
		private _cookieService: CookieService,
		private _tokenService: TokenService,
		private _router: Router) {

		if (this._tokenService.isAuthenticated() === false) {
			this._router.navigate(['login']);
		}
	}

	ngOnInit(): void {
		this._updateService.setCurrentRanchId(null);
		this._updateService.setRanchPlantingsPermissions(null);
		this._cookieService.deleteCurrentRanchCookie();
		this._updateService.setCurrentPage('Localization');
		this._updateService.clearRanchSettings();
		this._router.navigate(['Localization']);
	}
}
